import React, {useEffect, useState} from 'react'
import Modal from 'react-modal'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {ICreateArea} from '../../../interfaces/ICreateArea'
import {putSistemAreaOrg} from '../../../services/AreaService'
import {putSistemArea} from '../../../services/OrganizacionService'
import TablaAreasMat from '../../MaterialTable/TablaAreasMat'

Modal.setAppElement('#root')

interface IModalArea {
  openModal: boolean
  OrgArea?: any
}

export const ModalArea: React.FC<IModalArea> = ({openModal, OrgArea}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [dataOrg, setDataOrg] = useState<ICreateArea>()
  const [apiData, setApiData] = useState<any>({})

  useEffect(() => {
    openModal && setIsOpen(!isOpen)
    openModal && setDataOrg(OrgArea)

    return () => {
      setIsOpen(!isOpen)
    }
  }, [openModal])

  const handleEstado = () => {
    Swal.fire({
      title:
        'Esta por modificar el estado del Area, si realiza esta operación, todos los usuarios vinculados, no podran acceder.',
      text: '¿Desea continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar!',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        putSistemArea(dataOrg?._idOrg || '', !dataOrg?.activo, dataOrg?.nombreArea || '').then(
          (res: any) => {
            if (res.data.ok) {
              putSistemAreaOrg(dataOrg?.nombreArea || '', !dataOrg?.activo).then((res: any) => {
                if (res.data.ok) {
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Los cambios se guardaron correctamente!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Ooops, verificar conexion!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                }
              })
            }
          }
        )
      }
    })
  }

  useEffect(() => {
    let datosApi: any = {
      _idOrg: dataOrg?._idOrg,
      _idArea: dataOrg?._idArea,
      _idSubArea: dataOrg?._idSubArea,
      _id: dataOrg?._id,
      nombreArea: dataOrg?.nombreArea,
    }
    setApiData(datosApi)
  }, [dataOrg])

  function toggleModal() {
    setIsOpen(!isOpen)
  }

  const closeModal = (e: any) => {}

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={500}
        className='modalCalendario'
        overlayClassName='modalCalendario-fondo'
      >
        <div className='px-2 pt-2'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 uppercase'>
                        {dataOrg?.nombreArea}
                      </a>

                      {dataOrg?.activo ? (
                        <a
                          className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_upgrade_plan'
                        >
                          Activo
                        </a>
                      ) : (
                        <a
                          className='btn btn-sm btn-light-danger fw-bolder ms-2 fs-8 py-1 px-3'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_upgrade_plan'
                        >
                          Desactivado
                        </a>
                      )}
                    </div>

                    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 capitalize'
                      >
                        <KTSVG
                          path='/media/icons/duotune/communication/com006.svg'
                          className='svg-icon-4 me-1'
                        />
                        {dataOrg?.datosTitular.nombre}
                        {dataOrg?.datosTitular.apellido}
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 capitalize'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen018.svg'
                          className='svg-icon-4 me-1'
                        />
                        {dataOrg?.direccionArea.localidad}
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                      >
                        <KTSVG
                          path='/media/icons/duotune/communication/com011.svg'
                          className='svg-icon-4 me-1'
                        />
                        {dataOrg?.datosContacto.email}
                      </a>
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-wrap flex-stack'>
                  <div className='d-flex flex-column flex-grow-1 pe-8'>
                    <div className='d-flex flex-wrap'>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen001.svg'
                            className='svg-icon-3 svg-icon-primary me-2'
                          />
                          <div className='fs-2 fw-bolder'>{dataOrg?.subAreas?.length}</div>
                        </div>

                        <div className='fw-bold fs-6 text-gray-400'>Areas</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <KTSVG
                            path='/media/icons/duotune/communication/com006.svg'
                            className='svg-icon-3 svg-icon-primary me-2'
                          />
                          <div className='fs-2 fw-bolder'>{dataOrg?.usuariosTotal}</div>
                        </div>

                        <div className='fw-bold fs-6 text-gray-400'>Usuarios</div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <KTSVG
                            path='/media/icons/duotune/communication/com006.svg'
                            className='svg-icon-3 svg-icon-success me-2'
                          />
                          <div className='fs-2 fw-bolder'>{dataOrg?.usuariosActivos}</div>
                        </div>

                        <div className='fw-bold fs-6 text-gray-400'>Usuarios Activos</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-wrap flex-stack'>
                  <a
                    onClick={toggleModal}
                    className='btn btn-sm btn-light-info me-3'
                    id='kt_user_follow_button'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr012.svg'
                      className='svg-icon-3 d-none'
                    />
                    <span className='indicator-label'>Volver</span>
                  </a>
                  <Link
                    to={{pathname: 'dependencia', state: dataOrg}}
                    className='btn btn-sm btn-light-primary me-3'
                  >
                    Agregar Area
                  </Link>

                  <div className='me-0'>
                    {dataOrg?.activo ? (
                      <button
                        onClick={() => handleEstado()}
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-danger'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                      >
                        <i className='bi bi-radioactive fs-3'></i>
                      </button>
                    ) : (
                      <button
                        onClick={() => handleEstado()}
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-success'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                      >
                        <i className='bi bi-radioactive fs-3'></i>
                      </button>
                    )}
                  </div>
                </div>
                <div className='d-flex flex-wrap flex-stack'>
                  <TablaAreasMat subArea={dataOrg?.subAreas!} dataApi={apiData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
