import { ErrorMessage, Field, Formik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import TextErrorFormik from '../../../../../../components/Formik/TextErrorFormik'
import GeoLoc_Service from '../../../../../../services/gral/GeoLoc_Service'

import { getLocProv, getProvincias } from '../../../../../../services/gral/proLocService'
import { useSelector } from 'react-redux'
import { ISTATE } from '../../../../../../../setup/redux/interface/reduxState.interface'
import MapaGoogle from '../../../components/MapaGoogle/MapaGoogle'
import { IEventos } from '../../CrearEventoPage/Steps/EventoData'
type Props = {
  valido?: boolean
  data: IEventos
}

interface IOptions
  extends Array<{
    value: string
    label: string
  }> { }

const UbicacionEvento: FC<Props> = ({ data, valido }) => {
  const [localizado, setlocalizado] = useState(false)
  const [dirLatLon, setDirLatLon] = useState<any>({
    lat: data ? data.geom.latitud : -38.9512797,
    lon: data ? data.geom.longitud : -68.0592025,
  })
  const [loading, setLoading] = useState(false)
  const eventosStore: any = useSelector((state: ISTATE) => state.eventos)
  const { acciones, formulario } = eventosStore
  const estado = formulario.estado
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [provincias, setProvincias] = useState([])
  const [localidades, setLocalidades] = useState([])
  const [habLocalidad, setHabLocalidad] = useState(true)
  const [activarBoton, setActivarBoton] = useState(false)
  const [buscar, setBuscar] = useState(0)

  const provOptions: IOptions = []
  let locOptions: IOptions = []

  const buscarGeoDir = (dir: any, buscador: any) => {
    GeoLoc_Service.buscarDireccion(dir).then((res: any) => {
      //console.log('res.data.data.lenght', res.data.data)
      if (res.data.data.length !== 0) {
        let datos = res.data.data[0]
        //console.log('===========datos', datos)
        data.pais = 'Argentina'
        if (buscador === 'Lugar') {
          data.provincia = datos.administrativeLevels.level1long
          data.localidad = datos.administrativeLevels.level1short
          data.pais = datos.country
        }
        let lat = datos.latitude
        let lon = datos.longitude
        /*  data.calle = datos.streetName
         data.numero = datos.streetNumber */
        data.geom = {
          latitud: lat,
          longitud: lon,
        }

        setDirLatLon({ lat, lon })
      } else {
        ////////console.log("NO SE ENCONTRO LA DIRECCION/LUGAR BUSCADO");
      }
    })
  }
  useEffect(() => {
    if (acciones.guardar) {

      getProvincias().then((res: any) => {
        setProvincias(res.data.data)
      })
    }
  }, [acciones.guardar])

  /* data.provincia = 'Neuquén' */

  useEffect(() => { }, [localizado, dirLatLon])

  useEffect(() => {
    if (acciones.guardar) {
      if (data.provincia !== '') {
        //////console.log('data.provincia', data.provincia)
        cargarLocalidades(data.provincia)
        locOptions = []
        setHabLocalidad(false)
      }
    }
  }, [data.provincia])
  //////console.log('-Localidades :', localidades)

  useEffect(() => {

  }, [data.geom.latitud, data.geom.longitud])

  const cargarLocalidades = (prov: any) => {
    //////console.log('prov', prov)
    getLocProv(prov).then((res: any) => {
      //////console.log('LocProv', res.data.data)
      setLocalidades(res.data.data)
      setHabLocalidad(true)
    })
  }
  if (acciones.guardar) {
    for (let p = 0; p < localidades.length; p++) {
      let loc: any = localidades[p]
      locOptions.push({ value: p.toString(), label: loc.nombre })
    }
    locOptions.sort(function compare(a: any, b: any) {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      // a debe ser igual b
      return 0
    })
    for (let p = 0; p < provincias.length; p++) {
      let pro: any = provincias[p]
      provOptions.push({ value: pro.id, label: pro.nombre })
    }
    provOptions.sort(function compare(a: any, b: any) {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      // a debe ser igual b
      return 0
    })
  }

  return (
    <>
      {((acciones.editar || estado === 'cancelado' || estado === 'realizado' || estado === 'en curso' || estado === 'planificado') && !acciones.guardar) && (
        <div className='w-100'>
          <div className='pb-10 pb-lg-15'>
            <h3 className='fw-bolder text-dark'>Ubicación del Evento</h3>
            <div className='col-11 mb-5 fv-row'>
              <label className='form-label mb-3'>Lugar donde se realizará el Evento</label>
              <Field
                type='text'
                disabled={true}
                className='form-control form-control-sm form-control-solid uppercase'
                name='lugar'
                placeholder='Complete el Lugar del evento'
                value={data.lugar}
              />
              <ErrorMessage name='lugar' component={TextErrorFormik} />
            </div>
            <div className='row'>
              <h3 className='fw-bolder text-dark'>Dirección del Evento</h3>
              <div className='col-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Provincia</label>
                <Field
                  type='text'
                  disabled={true}
                  name='localidad'
                  className='form-control form-control-sm form-control-solid uppercase'
                  value={data.provincia}
                ></Field>
                <ErrorMessage name='provincia' component={TextErrorFormik} />
              </div>

              <div className='col-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Localidad</label>
                <Field
                  type='text'
                  disabled={true}
                  className='form-control form-control-sm form-control-solid uppercase'
                  name='localidad'
                  value={data.localidad}
                ></Field>
                <ErrorMessage name='localidad' component={TextErrorFormik} />
              </div>

              <div className='col-4 mb-5'>
                <label className='form-label mb-3'>Calle</label>
                <Field
                  type='text'
                  disabled={true}
                  className='form-control form-control-sm form-control-solid'
                  name='calle'
                  value={data.calle}
                />
                <ErrorMessage name='calle' component={TextErrorFormik} />
              </div>
              <div className='col-2 mb-5'>
                <label className='form-label mb-3'>Número</label>
                <Field
                  type='text'
                  disabled={true}
                  className='form-control form-control-sm form-control-solid'
                  name='numero'
                  value={data.numero}
                />
                <ErrorMessage name='numero' component={TextErrorFormik} />
              </div>
              <div className='col-2 mb-5'>
                <label className='form-label mb-3'>Latitud</label>
                <Field
                  type='text'
                  disabled={true}
                  className='form-control form-control-sm form-control-solid'
                  name='geom.latitud'
                  value={data.geom.latitud}
                />
                <ErrorMessage name='geom.latitud' component={TextErrorFormik} />
              </div>
              <div className='col-2 mb-5'>
                <label className='form-label mb-3'>Longitud</label>
                <Field
                  type='text'
                  disabled={true}
                  className='form-control form-control-sm form-control-solid'
                  name='geom.longitud'
                  value={data.geom.longitud}
                />
                <ErrorMessage name='geom.longitud' component={TextErrorFormik} />
              </div>
            </div>
          </div>
          <div className='mb-5 fv-row align-items-center '>
            <div className='googlemap-container'>
              <MapaGoogle />
            </div>
          </div>
        </div>
      )}
      {acciones.guardar && (
        <div className='w-100'>
          <div className='pb-10 pb-lg-15'>
            <h3 className='fw-bolder text-dark'>Ubicación del Evento</h3>

            <div className='col-11 mb-5 fv-row'>
              <label className='form-label mb-3'>Lugar donde se realizará el Evento</label>
              <Field
                type='text'
                className='form-control form-control-sm form-control-solid'
                name='lugar'
                placeholder='Complete el Lugar del evento'
                value={data.lugar}
              />
              <ErrorMessage name='lugar' component={TextErrorFormik} />
            </div>
            <div className='row'>
              <h3 className='fw-bolder text-dark'>Dirección del Evento</h3>
              <div className='col-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Provincia</label>
                <Field
                  as='select'
                  className='form-select form-select-solid capital'
                  name='provincia'
                  defaultValue={data.provincia}
                >
                  <option value=''>{data.provincia}</option>
                  {provOptions?.map((option: any) => {
                    return (
                      <option key={option?.value} value={option?.label}>
                        {option?.label}
                      </option>
                    )
                  })}
                </Field>

                <ErrorMessage name='provincia' component={TextErrorFormik} />
              </div>

              <div className='col-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Localidad</label>
                <Field
                  as='select'
                  /* disabled={habLocalidad} */
                  name='localidad'
                  className='form-select form-select-solid capital'
                  defaultValue={data.localidad}
                >
                  <option value=''>{data.localidad}</option>
                  {locOptions?.map((option: any) => {
                    return (
                      <option key={option?.value} value={option?.label}>
                        {option?.label}
                      </option>
                    )
                  })}
                </Field>
                <ErrorMessage name='localidad' component={TextErrorFormik} />
              </div>

              <div className='col-4 mb-5'>
                <label className='form-label mb-3'>Calle</label>
                <Field
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  name='calle'
                  placeholder='Complete la Calle del evento'
                  value={data.calle}
                />
                <ErrorMessage name='calle' component={TextErrorFormik} />
              </div>
              <div className='col-2 mb-5'>
                <label className='form-label mb-3'>Número</label>
                <Field
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  name='numero'
                  placeholder='Complete Nro'
                  value={data.numero}
                />
                <ErrorMessage name='numero' component={TextErrorFormik} />
              </div>

              <div className='col-1 mt-9'>
                <button
                  type='button'
                  /* disabled={habilitar} */
                  className='btn btn-lg btn-primary me-3'
                  onClick={() => {
                    let dire =
                      data.calle +
                      ' ' +
                      data.numero +
                      ', ' +
                      data.localidad +
                      ', ' +
                      data.provincia
                    buscarGeoDir(dire, 'Argentina')
                    let busqueda = buscar + 1
                    setBuscar(busqueda)
                  }}
                >
                  <span className='indicator-label'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen004.svg'
                      className='svg-icon-3 ms-0 me-0'
                    />
                  </span>
                </button>
              </div>
              <div className='col-2 mb-5'>
                <label className='form-label mb-3'>Latitud</label>
                <Field
                  type='text'
                  disabled={true}
                  className='form-control form-control-sm form-control-solid'
                  name='geom.latitud'
                  value={data.geom.latitud}
                />
                <ErrorMessage name='provincia' component={TextErrorFormik} />
              </div>
              <div className='col-2 mb-5'>
                <label className='form-label mb-3'>Longitud</label>
                <Field
                  type='text'
                  disabled={true}
                  className='form-control form-control-sm form-control-solid'
                  name='geom.longitud'
                  value={data.geom.longitud}
                />
                <ErrorMessage name='provincia' component={TextErrorFormik} />
              </div>
            </div>
          </div>
          <div className='mb-5 fv-row align-items-center '>
            <div className='googlemap-container'>
              <MapaGoogle />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export { UbicacionEvento }
