/** React */
import React from 'react'
/** Formik */
import {Field} from 'formik'
/** Material */
import {Checkbox} from '@mui/material'
import {useLocation} from 'react-router'

type Props = {
  handleChange: any
  handleBlur: any
  btnEdit?: any
}

export const Documentacion: React.FC<Props> = ({handleChange, handleBlur, btnEdit}) => {
  const location: any = useLocation()
  return (
    <>
      <div className='row alineacion_inputs'>

        <div className='col-lg-3'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='documentacion.certificadoMedico'
            name='documentacion.certificadoMedico'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Certificado Medico</label>
        </div>
        <div className='col-lg-3'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='documentacion.estudiosComplem'
            name='documentacion.estudiosComplem'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Estudios Complementarios</label>
        </div>
        <div className='col-lg-3'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='documentacion.partidaNac'
            name='documentacion.partidaNac'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Partida Nacimiento</label>
        </div>
        <div className='col-lg-3'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='documentacion.extravioCud'
            name='documentacion.extravioCud'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Extravio Cud</label>
        </div>
      </div>
      <div className='row alineacion_inputs'>
        <div className='col-lg-3'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='documentacion.historiaClinica'
            name='documentacion.historiaClinica'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Historia Clinica</label>
        </div>
        <div className='col-lg-3'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='documentacion.copiaDni'
            name='documentacion.copiaDni'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Copia DNI</label>
        </div>
        <div className='col-lg-3'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='documentacion.supervivencia'
            name='documentacion.supervivencia'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Supervivencia</label>
        </div>
        <div className='col-lg-3'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='documentacion.informes'
            name='documentacion.informes'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Informes</label>
        </div>
      </div>
      <div className='row alineacion_inputs'>
        <div className='col-lg-3'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='documentacion.planillaMedica'
            name='documentacion.planillaMedica'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Planilla Medica</label>
        </div>
        <div className='col-lg-3'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='documentacion.copiaOSocial'
            name='documentacion.copiaOSocial'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Copia Obra Social</label>
        </div>
        <div className='col-lg-3'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='documentacion.reciboPension'
            name='documentacion.reciboPension'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Recibo Pensión</label>
        </div>
        <div className='col-lg-3'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='documentacion.curatela'
            name='documentacion.curatela'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Curatela / Apoyo juridico</label>
        </div>
      </div>
      
      <div className='row alineacion_inputs'>
        <div className='col-lg-3'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='documentacion.cudAnterior'
            name='documentacion.cudAnterior'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Cud Anterior</label>
        </div>
      

      {location.state.datosTutor?.nombre && location.state.datosTutor.nombre ? (
      
          <div className='col-lg-3'>
            <Field
              type='checkbox'
              as={Checkbox}
              id='documentacion.copiaDniTutor'
              name='documentacion.copiaDniTutor'
              className=''
              disabled={!btnEdit}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label className=''>Copia DNI Tutor</label>
          </div>
      
      ) : null}
      </div>
    </>
  )
}
export default Documentacion
