import moment from 'moment'
import React, { useRef, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useReactToPrint } from 'react-to-print'
import { string } from 'yup'
import { KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { ExportPDFLegajo } from '../Exports/ExportPDFLegajo'
type props = {
  dataTramite?: any
  show: boolean
  handleCloseModal: () => void
}

type MyProps = {
  data: any
  ref: any
}

class PDF extends React.Component<MyProps> {
  render() {
    const data = this.props.data[0]
    return (
      <>
        <div className='print-container pt-3' style={{ marginBottom: '0', padding: '0', marginTop: '0' }}>
          <div className="centrado"
          // style={{
          //   width: '95%',
          //   height:'100%',
          //   marginTop: '0px',
          //   marginLeft: '25px',
          //   textAlign: 'right',
          // }}
          >
            <p><img
              className='elemento_centro membrete'
              src={toAbsoluteUrl('/media/svg/files/membrete.png')}
              alt='Logo'
            />
            </p>


          </div>
          <div
            style={{
              width: '95%',
              marginTop: '0px',
              marginLeft: '25px',
              textAlign: 'right',
            }}
          >
            <br></br>
            <br></br>

            <div className='tamano_footer' >
              Original - Generado: {moment(new Date()).format('DD/MM/YYYY HH:mm')} hs
            </div>

          </div>
          <ExportPDFLegajo key={''} data={data} />
          <div className='tamano_footer centrado'>
          <br></br><br></br>
            Subsecretaria de Discapacidad - Ministerio de Niñez, Adolecencia, Juventud y Ciudadanía | Gobierno de la Provincia del Neuquén.
          </div>

          <div>        
        

            <div className='centrado'>
            <p>  <img
                className='elemento_centro membrete'
                src={toAbsoluteUrl('/media/svg/files/membrete.png')}
                alt='Logo'
              />
              </p>
              
            </div>

            <div
            style={{
              width: '95%',
              marginTop: '0px',
              marginLeft: '25px',
              textAlign: 'right',
            }}
          >


<p></p>
<p></p>            <br></br>
            <br></br>
            <div className='tamano_footer' >
              Duplicado - Generado: {moment(new Date()).format('DD/MM/YYYY HH:mm')} hs
            </div>
          </div>
          <ExportPDFLegajo key={''} data={data} />
          <div className='tamano_footer centrado' >
            <br></br><br></br>
            Subsecretaria de Discapacidad - Ministerio de Niñez, Adolecencia, Juventud y Ciudadanía | Gobierno de la Provincia del Neuquén.

            </div>
            </div>
            </div>



      </>
    )
  }
}




export const ModaleEntrega: React.FC<props> = ({ show, dataTramite, handleCloseModal }) => {
  let componentRef: any = useRef()
  const handelprint = useReactToPrint({
    content: () => componentRef.current,
  })
  return (
    <Modal
      fullscreen={true}
      className='modal fade config-modal xl'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <div
            className='col'
            style={{
              marginLeft: '50px',
            }}
          >

            <button className='btn btn-primary btn-sm' onFocus={handelprint}>
              Descargar / Imprimir
            </button>
            <h5 className='modal-title centrado'>Previsualización de documentación entregada.</h5>
          </div>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleCloseModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <PDF ref={componentRef} data={dataTramite} />
        </div>
        {/* <div className='modal-footer'>
        <Footer ref={componentRef} data={dataTramite} />
        </div> */}
      </div>

    </Modal>
  )
}

