import * as Yup from 'yup'

export const validateSchemaDatosPersonales = Yup.object({
  junta: Yup.string().required('El campo es requerido'),

  datosPersonales: Yup.object({
    dni: Yup.string()
      .min(7, 'Caracteres minimos: 7')
      .max(8, 'Caracteres maximos: 8')
      .required('El campo es requerido'),
    nombre: Yup.string().min(1).required('El campo es requerido'),
    apellido: Yup.string().required('El campo es requerido'),
    domicilio: Yup.string().required('El campo es requerido'),
    localidad: Yup.string().required('El campo es requerido'),
    fechaNacimiento: Yup.string().required('El campo es requerido'),
    nacionalidad: Yup.string().required('El campo es requerido'),
    sexo: Yup.string().required('El campo es requerido'),
    mail: Yup.string().email('Completar el formato'),
    telefono: Yup.string().required('El campo es requerido'),
    tipoDoc: Yup.string().required('El campo es requerido'),
    cuit: Yup.string().required('El campo es requerido'),
    codigoPostal: Yup.string().required('El campo es requerido'),
    departamento: Yup.string().required('El campo es requerido'),
    tipoResidencia: Yup.string().required('El campo es requerido'),

    fechaVencimientoResidencia: Yup.string().when('tipoResidencia', {
      is: (tipoResidencia: any) => tipoResidencia !== 'permanente',
      then: Yup.string().required('Ingrese Fecha de inicio'),
    }),

    estadoCivil: Yup.string().min(1).required('El campo es requerido'),
    tipoNacionalidad: Yup.string().min(1).required('El campo es requerido'),
    vehiculoLey: Yup.string().required('El campo es requerido'),
    fechaVehiculo: Yup.string().when('vehiculoLey', {
      is: 'si',
      then: Yup.string().required('Ingrese Fecha de inicio'),
    }),
    derechoAsignacionesF: Yup.string().required('El campo es requerido'),

    simboloInternacional: Yup.string().required('El campo es requerido'),
  }),
})

export const validateSchemaDatosTutor = Yup.object().shape({
  datosTutor: Yup.object().shape({
    dni: Yup.string()
      .min(7, 'Caracteres minimos: 7')
      .max(8, 'Caracteres maximos: 8')
      .required('El campo es requerido'),
    nombre: Yup.string().required('El campo es requerido'),
    tipoPariente: Yup.string().required('El campo es requerido'),
    apellido: Yup.string().required('El campo es requerido'),
    domicilio: Yup.string().required('El campo es requerido'),
    localidad: Yup.string().required('El campo es requerido'),
    fechaNacimiento: Yup.string().required('El campo es requerido'),
    sexo: Yup.string().required('El campo es requerido'),
    mail: Yup.string().email('Completar el formato'),
    nacionalidad: Yup.string().required('El campo es requerido'),
    telefono: Yup.string().required('El campo es requerido'),
    codigoPostal: Yup.string().required('El campo es requerido'),
    tipoDoc: Yup.string().required('El campo es requerido'),
    tipoTutor: Yup.string().when('tipoPariente', {
      is: 'tutor designado judicialmente',
      then: Yup.string().required('El campo es requerido'),
    }),
    fechaDesignacionTutor: Yup.string().when('tipoPariente', {
      is: 'tutor designado judicialmente',
      then: Yup.string().required('El campo es requerido'),
    }),
    juzgado: Yup.string().when('tipoPariente', {
      is: 'tutor designado judicialmente',
      then: Yup.string().required('El campo es requerido'),
    }),
    secretaria: Yup.string().when('tipoPariente', {
      is: 'tutor designado judicialmente',
      then: Yup.string().required('El campo es requerido'),
    }),
    dptoJudicial: Yup.string().when('tipoPariente', {
      is: 'tutor designado judicialmente',
      then: Yup.string().required('El campo es requerido'),
    }),
    fiscalia: Yup.string().when('tipoPariente', {
      is: 'tutor designado judicialmente',
      then: Yup.string().required('El campo es requerido'),
    }),
    defensoria: Yup.string().when('tipoPariente', {
      is: 'tutor designado judicialmente',
      then: Yup.string().required('El campo es requerido'),
    }),
    anios: Yup.string(),
    interesadoApoyoJudicial: Yup.string().when('anios', {
      is: 'false',
      then: Yup.string().required('El campo es requerido'),
    }),

    interesadoApoyoExtraJudicial: Yup.string().when('anios', {
      is: 'false',
      then: Yup.string().required('El campo es requerido'),
    }),
    curadorDesignadoJudicial: Yup.string().when('anios', {
      is: 'false',
      then: Yup.string().required('El campo es requerido'),
    }),
  }),
})

export interface Itramites extends Document {
  _id?: String
  fecha: String
  dni: string

  datosPersonales: {
    tipoDoc: string
    dni: string
    cuit: string
    apellido: string
    nombre: string
    domicilio: string
    codigoPostal: string
    localidad: string
    provincia: string
    departamento: string
    tipoResidencia: string
    fechaVencimientoResidencia: string
    telefono: string
    mail: string
    sexo: string
    estadoCivil: string
    fechaNacimiento: string
    nacionalidad: string
    tipoNacionalidad: string
    fechaVehiculo: string
    vehiculoLey: string
    simboloInternacional: string
    derechoAsignacionesF: string
  }
  activo: Boolean
  estado: String
  metodo: String
  usuarioCreacion: String
  fechaCreacion: String
  usuarioModificacion: String
  fechaModificacion: String
  junta: string

  datosTutor?: {
    dni: string
    nombre: string
    apellido: string
    domicilio: string
    localidad: string
    fechaNacimiento: string
    sexo: string
    mail: String
    nacionalidad: string
    telefono: string
    codigoPostal: string
    tipoDoc: string
    tipoTutor: string
    fechaDesignacionTutor: string
    juzgado: string
    secretaria: string
    dptoJudicial: string
    fiscalia: string
    defensoria: string
    interesadoApoyoJudicial: string
    interesadoApoyoExtraJudicial: string
    curadorDesignadoJudicial: string
    tipoPariente: string
  }

  alertas?: {
    cambioMayor: boolean
  }
}
export interface Itramitess extends Array<Itramites> {}
