import MenuPrincipalTelefonia from '../components/TelefoniaAdmin/MenuPrincipalTelefonia'
import PageTitleTelefonia from '../components/TelefoniaAdmin/PageTitleTelefonia'
import { useSelector } from 'react-redux'
import { ISTATE } from '../../../../../setup/redux/interface/reduxState.interface'
import TablasDatos from '../components/TelefoniaAdmin/TablasDatosT'
import { useEffect, useState } from 'react'

import { ITablaDatosT } from '../Interfaces/TablaDatosT-interface'

type Props = {
  className: string
  mPrincipal?: []
}

export const Telefonia = () => {
  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)
  const { perfil } = dashState
  const [selectMenu, setSelectMenu] = useState<ITablaDatosT>()

  const selectData = (opcion: string, tablaDatos: ITablaDatosT) => {
    setSelectMenu(tablaDatos)
  }

  useEffect(() => {
    ////console.log(selectMenu)
  }, [selectMenu])
  return (
    <div className='card-title fw-bolder text-white'>
      {/*   <HeaderSisAdmin /> */}
      <PageTitleTelefonia breadcrumbs={[]}>Administración</PageTitleTelefonia>
      <div className='row gy-5 gx-xl-12'>
        <div className='col-xxl-3 col-xl-3 col-md-3'>
          {/* Menu de Opciones */}
          <MenuPrincipalTelefonia
            className='card-xxl-stretch mb-xl-3'
            mPrincipal={perfil[1].menu}
            selectMenu={selectData}
          />
        </div>
        {/* Tabla de datos de Lineas Activas */}
        <div className='col-xl-9 col-md-9'>
          <TablasDatos
            className={'col-xl-9 col-md-9'}
            tituloMenu={selectMenu?.tituloMenu}
            tablaDatos={selectMenu?.tablaDatos}
            headColumns={selectMenu?.headColumns}
          />
        </div>
      </div>
    </div>
  )
}
