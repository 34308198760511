import { toAbsoluteUrl } from '../../../helpers'

export function FallbackView() {
  return (
    <div className='splash-screen'>
      <img className='splash-screen' src={toAbsoluteUrl('/media/logos/logo-cargando.svg')} alt='Start logo' />
      <span>Cargando ...</span>
    </div>
  )
}
