/** React */
import React from 'react'
/** Material */
import {Button} from '@mui/material'

type Props = {
  btnEdit?: any
  handleBtn?: any
}

export const ButtonOrig: React.FC<Props> = ({btnEdit, handleBtn}) => {
  return (
    <div className='row card_align5'>
      <div className='col-lg-8'>
        <Button className='btn btn-primary' onClick={handleBtn}>
          {btnEdit === false ? 'Habilitar Edición' : 'Desabilitar Edición'}
        </Button>
        &nbsp;&nbsp;
        {btnEdit === true ? (<Button type='submit' className='btn btn-success' disabled={false}>
            Guardar
          </Button>
        ) : ( '' )}
      </div>
    </div>
  )
}
