import { FormikValues, useFormikContext } from "formik";
import FormikControl from "../../../../../../../components/Formik/FormikControl"
import { FormControlLabel, Checkbox } from "@mui/material";
//import { FormGroup } from "react-bootstrap-v5";
import { FormGroup} from "@mui/material";

const situacionOptions = [
  {
    label: "Jubilado/a del Sistema Contributivo",
    value: "jubilado"
  },
  {
    label: "Pensionado/a del Sistema Contributivo",
    value: "pensionado"
  },
  {
    label: "Pensión No Contributiva / por invalidez",
    value: "pensionInvalidez"
  },
  {
    label: "Pensión No Contributiva / graciable",
    value: "pensionGraciable"
  },
  {
    label: "Pensión Universal para el adulto mayor",
    value: "pensionUniversal"
  },
  {
    label: "Pensión No Contributiva / ex combatientes",
    value: "pensionExCombatientes"
  },
  {
    label: "Pensión No Contributiva / madre de 7 hijos",
    value: "pensionMadre"
  },
  {
    label: "Otra",
    value: "otra"
  },
  {
    label: "Sin beneficio",
    value: "sinBeneficio"
  },
]

const SituacionPrevisional = () => {
  const formik = useFormikContext<FormikValues>();
  return (
    <div className="card card-custom card-strech mt-10">
      <div className="card-header shadow bg-body rounded">
        <div className="card-title">
          <h1>Situación Previsional</h1>

        </div>

      </div>
      <div className="card-body">
        <div className="row justify-center">
          <div className="col-3">
            <FormGroup>
              {situacionOptions.map((item) => (
                <FormControlLabel
                  key={item.value}
                  control={
                    <Checkbox
                      checked={formik.values.situacionPrevisional.includes(item.value)}
                      onChange={formik.handleChange}
                      name="situacionPrevisional"
                      value={item.value} />}
                  label={item.label}
                />
              ))}
              <div>


              </div>
            </FormGroup>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SituacionPrevisional