import React, {FC, useEffect, useState} from 'react'
import {Field, ErrorMessage} from 'formik'
import TextErrorFormik from '../../../../../../../components/Formik/TextErrorFormik'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {useDispatch, useSelector} from 'react-redux'
import {ISTATE} from '../../../../../../../../setup/redux/interface/reduxState.interface'
import Agenda_Service from '../../../../../Agenda/Redux/services/Agenda_Service'
import {areasCargar} from '../../../../../Agenda/Redux/actions/areasActions'
import {cargarSelAreas} from '../../../../../Agenda/Redux/actions/menuActions'

type props = {
  valor: any
}
const Step1: FC<props> = ({valor}) => {
  const dispatch = useDispatch()

  //array tematicas harcodeadas
  const tematicasArray = [
    {value: 'Ministerio', label: 'Ministerio'},
    {
      value: 'Subsecretaría de Niñez y Adolescencia',
      label: 'Subsecretaría de Niñez y Adolescencia',
    },
    {value: 'Subsecretaría de Juventud', label: 'Subsecretaría de Juventud'},
    {value: 'Subsecretaría de Discapacidad', label: 'Subsecretaría de Discapacidad'},
    {
      value: 'Subsecretaría de Ciudades Saludables y Prevención de Consumos Problemáticos',
      label: 'Subsecretaría de Ciudades Saludables y Prevención de Consumos Problemáticos',
    },
    {
      value: 'Subsecretaría de Acceso a la Justicia',
      label: 'Subsecretaría de Acceso a la Justicia',
    },
    {
      value: 'Dirección Provincial de Innovación y Tecnología',
      label: 'Dirección Provincial de Innovación y Tecnología',
    },
    {
      value: 'Cambio Climático',
      label: 'Cambio Climático',
    },
    {
      value: 'Territorio',
      label: 'Territorio',
    },
    {
      value: 'Organizaciones',
      label: 'Organizaciones',
    },
    {
      value: 'Cooperación',
      label: 'Cooperación',
    },
    {
      value: 'Participación Ciudadana',
      label: 'Participación Ciudadana',
    },
    {
      value: 'Empleabilidad',
      label: 'Empleabilidad',
    },
    {
      value: 'Comunicación',
      label: 'Comunicación',
    },
    {
      value: 'Sensibilización',
      label: 'Sensibilización',
    },
    {
      value: 'Construcción Ciudadana',
      label: 'Construcción Ciudadana',
    },
    {
      value: 'Apps',
      label: 'Apps',
    },
    {
      value: 'Descargas',
      label: 'Descargas',
    },
    {
      value: 'Inscripciones',
      label: 'Inscripciones',
    },
    {
      value: 'Certificados',
      label: 'Certificados',
    },
  ]

  const animatedComponents = makeAnimated()
  const areasState: any = useSelector((state: ISTATE) => state.areas)
  const {areas} = areasState
  const AreaArray = areas.map(function (nombre: any) {
    return nombre.detalleSistema.nombreCorto
  })
  let AreaArrayNombres: any = []
  for (let element of AreaArray) {
    let arrAr = {
      value: element,
      label: element,
    }
    AreaArrayNombres.push(arrAr)
  }

  useEffect(() => {
    Agenda_Service.consultaDependencias().then((res: any) => {
      let areasMenu = res.data.data
      areasMenu.sort(function compare(a: any, b: any) {
        if (a.categoriaArea < b.categoriaArea) {
          return -1
        }
        if (a.categoriaArea > b.categoriaArea) {
          return 1
        }
        return 0
      })
      let areasTemp: any = []
      for (let area = 0; area < areasMenu.length; area++) {
        const a = areasMenu[area]
        {
          areasTemp.push({
            nombreCorto: a.detalleSistema.nombreCorto,
            tooltip: `${a.tipoArea} de ${a.nombreArea}`,
            colorFondo: a.detalleSistema.colorFondo,
            id: a._id,
            selec: true,
          })
        }
      }
      dispatch(areasCargar(areasMenu))
      dispatch(cargarSelAreas(areasTemp))
    })
  }, [])
//disable prev fechas
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};

  return (
    <div className='pt-15 w-100'>
      <div className='pb-4 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Crear Noticia
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>
        <div className='text-gray-400 fw-bold fs-6'>Complete los siguientes campos.</div>
        <hr className='text-gray-500' />
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='pb-6 col-lg-6'>
            <label className='form-label fw-bolder text-dark fs-6'>Título de noticia</label>
            <Field
              type='text'
              name='titulo'
              placeholder='Ingrese un título..'
              className='form-control form-control-solid'
            />
            <ErrorMessage name='titulo' component={TextErrorFormik} />
          </div>
          <div className='col-lg-6'>
            <label className='form-label fw-bolder text-dark fs-6'>Área</label>
            <Select
              className='form-label form-control-solid'
              name='nombreArea'
              isMulti={false}
              options={AreaArrayNombres}
              closeMenuOnSelect={true}
              placeholder={'Sin Area'}
              onChange={(e: any) => {
                valor.values.nombreArea = e.value
              }}
            ></Select>
          </div>
        </div>
      </div>
      <div className='pb-3 fv-row'>
        <div className='row'>
          <div className='col-lg-6'>
            <label className='form-label fw-bolder text-dark fs-6'>Autor</label>
          
            <Field name='datos_autor' type='text'  
            placeholder='Nombre de autor'
            className='form-control form-control-solid'
            />
              
            <ErrorMessage name='datos_autor' component={TextErrorFormik} />
          </div>
          <div className='col-lg-6'>
            <label className='form-label fw-bolder text-dark fs-6'>Fecha Publicación</label>
            <Field
              name='fecha_publicacion'
              type='date'
              className='form-control form-control-solid'
              min={disablePastDate()}
            />
          </div>
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-12'>
            <label className='form-label fw-bolder text-dark fs-6'>Temáticas</label>
            <Select
              className='form-label form-control-solid'
              name='tematicas'
              isMulti
              options={tematicasArray}
              components={animatedComponents}
              closeMenuOnSelect={true}
              placeholder={'Sin Tematica'}
              onChange={(e: any) => {
                let arrayTematicas: any = []
                for (let tem of e) {
                  arrayTematicas.push(tem.value)
                  valor.values.tematicas = arrayTematicas
                }
              }}
            ></Select>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
function setValue(newValue: any) {
  throw new Error('Function not implemented.')
}
