import React, {ChangeEvent, useEffect, useState} from 'react'
import {Idatos} from '../../Interfaces/Datos-interface'

export const CalendarioTurnos: React.FC<Idatos> = ({className, tituloMenu}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h5 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-5 mb-1 capital'>{tituloMenu}</span>
        </h5>
      </div>
      <div className='card-header border-0 pt-1'>
        <h3 className='card-title align-items-start'>
          <span className='card-label fw-bolder fs-2 mb-1 capital'></span>
        </h3>
        <div className='card-toolbar'></div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'></div>
      </div>
      {/* end::Table container */}
    </div>
  )
}
