import {Field, Formik} from 'formik'
import React, {useState} from 'react'

import Swal from 'sweetalert2'
import {initPeriodosUP, validationDocSchema} from './UpFilesHelpers'
import Spinner from '../../../../../components/spinner/Spinner'
import {saveDocEmp, uploadFileEmp} from '../../services/EJ.Empleado.Services'
import {useHistory} from 'react-router'
import moment from 'moment'
interface Iprops {
  emprendedor?: any
  empleado?: any
  closeModal(): any
}

const ESTADOS = ['pendiente', 'rechazado'];

export const UpFiles: React.FC<Iprops> = ({emprendedor, empleado, closeModal}) => {
  const history = useHistory()
  //const [anio, setAnio] = useState<any>()
  const [meses, setMeses] = useState<any>()

  const [loading, setLoading] = useState(false)

  const filtYearAct = (year: string) => {
    setMeses(empleado.anio.find((elemt: any) => elemt.anio === year)?.meses)
  }

  /*   const filtDocMes = (mes: string) => {
    setMeses(meses.find((elemt: any) => elemt.mes === mes)?.documentos)
  } */

  const validarPdf = async (data: any, idInput: string) => {
    if (data) {
      if (data.size > 2000000) {
        await Swal.fire({
          icon: 'info',
          title: 'El tamaño del archivo excede el limite permitido, 2 MB.',
          timer: 3000,
        })
        ;(document.getElementById(idInput) as HTMLInputElement)!.value = ''
        return false
      }
      let ext = data.name.split('.')
      if (ext[1] === 'pdf') {
        return true
      } else {
        await Swal.fire({
          icon: 'info',
          title: 'Solo se aceptan archivos PDF.',
          timer: 3000,
        })
        ;(document.getElementById(idInput) as HTMLInputElement)!.value = ''
        return false
      }
    } else {
      ;(document.getElementById(idInput) as HTMLInputElement)!.value = ''
      return false
    }
  }

  const guardar = (value: any) => {
    let documentos: any = []
    setLoading(true)
    uploadFileEmp(value.reciboSueldo, value.cerCap, value, empleado.documento)
      .then((res: any) => {
        if (res.data.ok) {
          for (let doc of res.data.fileSaves) {
            let fileName = Object.keys(doc)[0]
            documentos.push({
              tipo_doc: fileName,
              id_db_fileEj: doc[fileName],
              estado: 'pendiente',
              fecha_carga: moment().format('DD/MM/YYYY'),
              fecha_modf: '',
            })
          }
          saveDocEmp(documentos, empleado.documento, value.mes, value.anio)
            .then(async (res) => {
              if (res.data.ok) {
                await Swal.fire({
                  icon: 'success',
                  title: 'Documentación cargada con exito.',
                  text: `El mes de ${value.mes}, correspondiente al periodo ${value.periodo}, se encuentra pendiente de verificación.`,
                  timer: 3000,
                })
                setLoading(false)
                history.push({pathname: '/ej/misempleados'})
                closeModal()
              }
            })
            .catch((err) => console.log(err))
        }
      })
      .catch(async (err) => {
        await Swal.fire({
          icon: 'info',
          title: 'Es necesario cargar al menos un archivo.!',
          timer: 3000,
        })
      })
    setTimeout(() => {}, 2000)
  }
  //console.log(empleado)
  return (
    <div id='kt_account_profile_details'>
      <Formik
        validationSchema={validationDocSchema}
        initialValues={initPeriodosUP}
        onSubmit={guardar}
        autocomplete='true'
        enableReinitialize
      >
        {(formik) => {
          //console.log(formik)
          return (
            <form className='form' noValidate>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Periodo</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <Field
                      as='select'
                      name='anio'
                      id='anio'
                      label=''
                      className='form-select form-select-solid capital'
                      onChange={(e: any) => {
                        formik.setFieldValue('anio', e.target.value)
                        filtYearAct(e.target.value)
                      }}
                    >
                      <option value=''>seleccione una opción</option>
                      {empleado.anio.map((anio: any) => {
                        return (
                          anio.estado === 'pendiente' && (
                            <option value={anio.anio}>{anio.anio}</option>
                          )
                        )
                      })}
                    </Field>
                  </div>
                </div>
                {meses && (
                  <>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required'>Mes</span>
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <Field
                          as='select'
                          name='mes'
                          id='mes'
                          label=''
                          className='form-select form-select-solid capital'
                        >
                          <option value=''>seleccione una opción</option>
                          {meses.map((item: any) => {
                            return (
                              ESTADOS.includes(item.estado) && (
                                <option value={item.mes}>{item.mes}</option>
                              )
                            )
                          })}
                        </Field>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                        Recibo de sueldo
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <input
                          accept='.pdf'
                          type='file'
                          id='reciboSueldo'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          name='reciboSueldo'
                          onChange={async (e: any) => {
                            if (e.target.files) {
                              ;(await validarPdf(e.target.files[0], 'reciboSueldo')) &&
                                formik.setFieldValue('reciboSueldo', e.target.files[0])
                            }
                          }}
                        />
                      </div>
                    </div>

                    {!empleado.descuentos.cert_capacitacion && (
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                          Certificado de capacitación segun de las entidades habilitadas en el
                          Artículo 9º inciso 3.
                        </label>

                        <div className='col-lg-8 fv-row'>
                          <input
                            type='file'
                            accept='.pdf'
                            id='cerCap'
                            name='cerCap'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            onChange={async (e: any) => {
                              if (e.target.files) {
                                ;(await validarPdf(e.target.files[0], 'cerCap')) &&
                                  formik.setFieldValue('cerCap', e.target.files[0])
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                {loading && <Spinner />}
                {formik.isValid && (
                  <button
                    type='button'
                    onClick={() => {
                      guardar(formik.values)
                    }}
                    className='btn btn-lg btn-primary me-3'
                  >
                    Guardar
                  </button>
                )}
              </div>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}
