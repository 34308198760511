import { FormikValues, useFormikContext } from "formik";
import FormikControl from "../../../../../../../components/Formik/FormikControl"
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const coberturaOptions = [
  {
    label: "Obra Social",
    value: "obraSocial"
  },
  {
    label: "Prepaga",
    value: "prepaga"
  },
  {
    label: "PAMI",
    value: "pami"
  },
  {
    label: "Publicación",
    value: "publicacion"
  },
  {
    label: "Otra",
    value: "otra"
  },
]

const CoberturaSalud = () => {
  const formik = useFormikContext<FormikValues>();

  const handleCheckboxChange = (value: any) => {
    const { coberturaSalud } = formik.values;
    const updatedOptions = coberturaSalud.includes(value)
      ? coberturaSalud.filter((option: any) => option !== value)
      : [...coberturaSalud, value];

    formik.setFieldValue("coberturaSalud", updatedOptions);
  };
  return (
    <>
      <div className="card card-custom card-strech mt-10">
        <div className="card-header shadow bg-body rounded">
          <div className="card-title">
            <h1>Cobertura de Salud</h1>

          </div>

        </div>
        <div className="card-body">

          <div>
            <FormGroup>
              {coberturaOptions.map((item) => (
                <FormControlLabel
                  key={item.value}
                  control={
                    <Checkbox
                      checked={formik.values.coberturaSalud.includes(item.value)}
                      onChange={formik.handleChange}
                      name="coberturaSalud"
                      value={item.value} />}
                  label={item.label}
                />
              ))}
              <div>
                <FormikControl
                  control='input'
                  name="nombreCobertura"
                  label="Nombre cobertura salud"
                />
              </div>
            </FormGroup>
          </div>
        </div>
      </div>
    </>
  )
}

export default CoberturaSalud;