/** React */
import { Link, useLocation } from 'react-router-dom'
/** Material & Sass */
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp'
import { Button } from '@mui/material'

import { useEffect, useState } from 'react'
/** Moment */
import '../../../../../../src/_metronic/assets/sass/cud.scss'
import moment from 'moment'
import Swal from 'sweetalert2'
import { ModalDetalles } from '../components/Modal/ModalDetalles'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import InfoIcon from '@mui/icons-material/Info';
moment.locale('es-mx')

export const FormCud = () => {
  const location: any = useLocation()
  const [show, setShow] = useState(false)
  const [data, setData] = useState<any>([])
  const handleCloseModal = () => setShow(false)


  useEffect(() => {
    let isMounted = true
    location.state.activado ? setData(location.state.obj) : setData(location.state)
    return () => {
      isMounted = false
    }
  }, [location])

  const badgeCorlor = (estado: string): string => {
    switch (estado) {
      case 'pendiente':
        return 'badge bg-info '
      case 'aprobado':
        return 'badge badge-light-success'
      case 'rechazado':
        return 'badge badge-light-danger'
      case 'archivado':
        return 'badge badge-secondary'
      default:
        return ''
    }
  }
  const detalle = (problemas: any) => {
    let variable
    switch (problemas) {
      case true:
        variable = '✔'
        break
      case false:
        variable = 'X'
        break
      default:
        break
    }

    return variable


  }
  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <Link
          to={{ pathname: '/cud/Legajos', state: { activado: true, obj: data } }}
          className='alineacionb'
        >
          <ArrowBackSharpIcon />
        </Link>

        <Link
          to={{ pathname: '/cud/editCud/', state: { tipo: 'new', obj: data } }}
          className='alineacionb'
        >
          <Button className='btn btn-sm btn-light-primary menu-btn-cud'>Nuevo</Button>
          &nbsp;&nbsp;
        </Link>
      </div>
      <br></br>
      <table className='table align-middle gs-0 gy-4'>
        <thead className='border-tabla-right'>
          <tr className='fw-bolder bg-light'>
            <th className='min-w-4px texto_center'></th>
            <th className='min-w-50px texto_center'>Estado tramite</th>
            <th className='min-w-100px texto_center'>Nro de CUD</th>
            <th className='min-w-100px texto_center'>Caducidad del Certificado</th>
            <th className='min-w-50px texto_center'>Observación</th>

            <th className='min-w-8px texto_center'>Opciones</th>
          </tr>
        </thead>
        <tbody>
          {data.datosCud?.map((value: any, index: any) => {
            return (
              <tr key={index}>
                <td></td>
                <td className=' table_cap texto_center '>
                  <span className={`${badgeCorlor(value.estado)}`}> {value.estado}</span>
                </td>

                <td className='table_cap texto_center'>
                
                {value.infoCertificado.nroCud === '' ? ('-------'):(
                  <b>{value.infoCertificado.nroCud}</b>
          )}
                </td>
                <td className='table_cap texto_center'>
                {value.infoCertificado.nroCud === '' ? ('-------'):(moment(value.infoCertificado.fechaVencimiento).format('l'))}
                </td>
                <td className='table_cap texto_center'>{value.observacion}</td>

                <td className='table_cap texto_center'>
                  <button className='btn btn-primary btn-sm' onClick={() => { setShow(true) }}>
                  <InfoIcon/>
                  </button>

                  <Link
                    to={{
                      pathname: '/cud/editCud/',
                      state: { tipo: 'edit', data: value, obj: data },
                    }}
                  >
                    &nbsp;
                    <button className='btn btn-warning btn-sm'><ModeEditIcon/></button>
                  </Link>
                </td>
                <ModalDetalles
                               show={show}
                               datos={value}
                               handleCloseModal={handleCloseModal}
                             />

              </tr>

            )

          })}

        </tbody>

      </table>

      <hr />
    </div>


  )

}
