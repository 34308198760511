import Swal from 'sweetalert2'
import toast from 'react-hot-toast'

export const CreateModal = () => {
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'Creación Exitosa',
    showConfirmButton: false,
    timer: 1500,
  })
}



export const CreateModal2 = (history: any) => {
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'Datos Actualizados',
    showConfirmButton: false,
    timer: 1500,
  })
  const location = {
    pathname: '/cud/Legajos',
    state: null,
  }
  history.push(location)
}

export const CreateTramite = () => {
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'Tramite Iniciado.',
    showConfirmButton: true,
  })
  window.history.back()
}

export const RejectTramite = () => {
  Swal.fire({
    position: 'center',
    icon: 'error',
    title: 'Error...! ',
    showConfirmButton: true,
  })
  window.history.back()
}

export const EditTramite = (history: any, values: any) => {
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'Tramite Editado.',
    showConfirmButton: true,
  })

  const location = {
    pathname: '/cud/MesaEntrada',
    state: null,
/*     state: {
      dni: values.dni,
      activado: true,
    }, */
  }
  //window.history.back();
  history.push(location) 
}

export const notify = () => {
  toast.success('Datos Guardados!', {duration: 2500})
}
