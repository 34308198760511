/** React */
import { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
/**  Icons Material UI */
import InfoIcon from '@mui/icons-material/Info'
import { Box, Button, Collapse, styled } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
/** Interfaces */
import { Iadmision, Iadmisiones } from '../../Interfaces/Iadmision'
/** Moment */
import moment from 'moment'
/** Helper */
import { BootstrapTooltip } from '../../helpers/bootstrapToolTip'

import { Pagination } from '../../helpers/pagination'

/* 
import {ModalPrevPDF} from '../Modal/ModalPrevPDF' */
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { ModaleEntrega } from '../Modal/ModaleEntrega'
import { useReactToPrint } from 'react-to-print'

moment.locale('es-mx')

type Props = {
  admision: Iadmisiones
  vista: boolean
  activePage: any
  totalPages: any
  setActivePage: any
  activando?: any
}
export const DataAdmision: React.FC<Props> = ({
  admision,
  vista,
  activePage,
  totalPages,
  setActivePage,
  activando,
}) => {
  const locationBtnPrincipal: any = useLocation()

  //PDF
  const [show, setShow] = useState<boolean>(false)
  const handleCloseModal = () => setShow(false)

  const [badgeColor, setbadgeColor] = useState('badge bg-info text-dark')
  const [admisiones, setAdmisiones] = useState<Iadmisiones>([])
  const [dataSeleccionada, setDataSeleccionada] = useState<any>([])
  const [dataCud, setDataCud] = useState<any>({})
  let componentRef: any = useRef()


  let history = useHistory()

  useEffect(() => {
    dataSeleccionada.length > 1 && setDataSeleccionada([])
    setAdmisiones(admision)
  }, [admision, dataSeleccionada])

  const mfuncion = (admision: any) => {
    history.push({ pathname: '/dav/admisiones', state: null })
    activando = false
    setDataSeleccionada((data: any) => [...data, admision])
    window.location.reload()
  }

  const badgeCorlor = (estado: string): string => {
    switch (estado) {
      case 'pendiente':
        return 'badge bg-info '
      case 'aprobado':
        return 'badge badge-light-success'
      case 'rechazado':
        return 'badge badge-light-danger'
      case 'archivado':
        return 'badge badge-secondary'
      default:
        return ''
    }
  }
  useEffect(() => {
    let isMounted = true
    locationBtnPrincipal.state?.activado
      ? setDataSeleccionada([locationBtnPrincipal.state.obj])
      : setDataSeleccionada([])
    return () => {
      isMounted = false
    }
  }, [locationBtnPrincipal])

  /* useEffect(() => {
    console.log(dataSeleccionada);
    let ultimo = dataSeleccionada[0]?.datosCud[dataSeleccionada[0].datosCud.length - 1]
    const ultimo = 
    setbadgeColor(badgeCorlor(ultimo?.estado))

    setDataCud(ultimo)
  }, [dataSeleccionada])
 */
  return (
    <>
      <table className='table align-middle gs-0 gy-4'>
        {dataSeleccionada.length === 0 ? (
          <thead className='border-tabla-right'>
            <tr className='fw-bolder bg-light'>
              <th className='min-w-4px'></th>
              <th className='min-w-50px'>Dni</th>
              <th className='min-w-100px'>Apellido</th>
              <th className='min-w-100px'>Nombre</th>
              <th className='min-w-100px'>Localidad</th>
              <th className='min-w-100px'>Genero</th>
              <th className='min-w-25px'>Acciones</th>
              <th className='min-w-8px'></th>
            </tr>
          </thead>
        ) : (
          <thead className='border-tabla-right'>
          <tr>
            <th colSpan={11}></th>
          </tr>
        </thead>
        )}
        <tbody className='border-tabla-top'>
          {dataSeleccionada.length === 0
            ? admisiones?.map((admItem: Iadmision, index: any) => {
              return (
                <tr className='table_font_normal' key={index}>
                  <td></td>
                  <td>{admItem.datosPersonales.dni}</td>

                  <td>
                    {admItem.datosPersonales.apellido.replace(/\b\w/g, (l) => l.toUpperCase())}
                  </td>
                  <td>
                    {admItem.datosPersonales.nombre.replace(/\b\w/g, (l) => l.toUpperCase())}{' '}
                  </td>
                  <td>
                    {admItem.datosPersonales.localidad.replace(/\b\w/g, (l) => l.toUpperCase())}
                  </td>
                  <td>{admItem.datosPersonales.sexo.replace(/\b\w/g, (l) => l.toUpperCase())}</td>
                  {activando !== true ? (
                    <td>
                      <BootstrapTooltip title='Detalles' arrow followCursor placement='top'>
                        <button
                          className='btn btn-icon btn-bg-primary btn-color-light btn-active-light btn-active-color-primary btn-sm me-1'
                          onClick={() => setDataSeleccionada((data: any) => [...data, admItem])}
                        >
                          <InfoIcon />
                        </button>
                      </BootstrapTooltip>
                    </td>
                  ) : (
                    setDataSeleccionada((data: any) => [...data, admItem])
                  )}
                </tr>
              )
            })
            : dataSeleccionada?.map((admItem: Iadmision, index: any) => {
              return (
                <>
                  <Box sx={{ margin: 1 }} className='no-margin-top'>
                    <div className='mt-5 fv-row'>
                      <label className='label_grid sub-text-datos fw-bolder'>
                        DATOS PERSONALES SOLICITANTE
                      </label>
                      <br></br>
                      <br></br>
                      <div className='row'>
                        <div className='col-lg-3'>
                          <label className=''>Dni:</label>
                          <label className='form-label m-0 table_cap '>
                            &nbsp;{admItem?.datosPersonales.dni}
                          </label>
                        </div>
                        <div className='col-lg-3'>
                          <label className=''>Apellido:</label>
                          <label className='form-label m-0 table_cap '>
                            &nbsp;{admItem?.datosPersonales.apellido}
                          </label>
                        </div>
                        <div className='col-lg-3'>
                          <label className=''>Nombre:</label>
                          <label className='form-label m-0 table_cap'>
                            &nbsp;{admItem.datosPersonales.nombre}
                          </label>
                        </div>
                        <div className='col-lg-3'>
                          <label className=''>Fecha nacimiento:</label>
                          <label className='form-label m-0 '>
                            &nbsp;
                            {admItem.datosPersonales.fechaNacimiento} (

                          </label>
                        </div>
                      </div>
                      <br></br>
                      <div className='row'>
                        <div className='col-lg-3'>
                          <label className=''>Domicilio:</label>
                          <label className='form-label m-0 table_cap '>
                            &nbsp;{admItem.datosPersonales.domicilio}
                          </label>
                        </div>
                        <div className='col-lg-3'>
                          <label className=''>Localidad:</label>
                          <label className='form-label m-0 table_cap '>
                            &nbsp;{admItem.datosPersonales.localidad}
                          </label>
                        </div>
                        <div className='col-lg-3'>
                          <label className=''>Telefono:</label>
                          <label className='form-label m-0 table_cap'>
                            &nbsp;{admItem.datosPersonales.telefono}
                          </label>
                        </div>
                        <div className='col-lg-3'>
                          <label className=''>Email:</label>
                          <label className='form-label m-0 '>
                            &nbsp;{admItem.datosPersonales.mail}
                          </label>
                        </div>
                      </div>
{/*                       {admItem.datosTutor && admItem.datosTutor?.dni !== '' ? (
                        <>
                          <hr></hr>
                          <label className='label_grid sub-text-datos fw-bolder'>
                            DATOS PERSONALES TUTOR
                          </label>
                          <br></br>
                          <br></br>
                          <div className='row'>
                            <div className='col-lg-3'>
                              <label className=''>Dni:</label>
                              <label className='form-label m-0 table_cap '>
                                &nbsp;{admItem.datosTutor.dni}
                              </label>
                            </div>
                            <div className='col-lg-3'>
                              <label className=''>Apellido:</label>
                              <label className='form-label m-0 table_cap '>
                                &nbsp;{admItem.datosTutor.apellido}
                              </label>
                            </div>
                            <div className='col-lg-3'>
                              <label className=''>Nombre:</label>
                              <label className='form-label m-0 table_cap '>
                                &nbsp;{admItem.datosTutor.nombre}
                              </label>
                            </div>
                            <div className='col-lg-3'>
                              <label className=''>Fecha N:</label>
                              <label className='form-label m-0 table_cap '>
                                &nbsp;
                                {admItem.datosTutor.fechaNacimiento}
                              </label>
                            </div>
                          </div>
                          <br></br>
                          <div className='row'>
                            <div className='col-lg-3'>
                              <label className=''>Domicilio:</label>
                              <label className='form-label m-0 table_cap '>
                                &nbsp;{admItem.datosTutor.domicilio}
                              </label>
                            </div>
                            <div className='col-lg-3'>
                              <label className=''>Localidad:</label>
                              <label className='form-label m-0 table_cap '>
                                &nbsp;{admItem.datosTutor.localidad}
                              </label>
                            </div>

                            <div className='col-lg-3'>
                              <label className=''>Telefono:</label>
                              <label className='form-label m-0 table_cap '>
                                &nbsp;{admItem.datosTutor.telefono}
                              </label>
                            </div>
                            <div className='col-lg-3'>
                              <label className=''>Email:</label>
                              <label className='form-label m-0  '>
                                &nbsp;{admItem.datosTutor.mail}
                              </label>
                            </div>
                          </div>
                        </>
                      ) : null} */}
                      <hr></hr>
                      
                        <>
                          <Link to={{ pathname: 'EditarTramite/', state: admItem }}>
                            <button
                              className='btn btn-primary btn-sm'
                              title='Editar Tramite'
                            >
                              EDITAR
                            </button>
                          </Link>
                          &nbsp;&nbsp;&nbsp;
                          {/*  <Link to={{pathname: 'NuevoLegajo/', state: admItem}}> */}
                          <button
                            className='btn btn-primary btn-sm'
                            title='Crear Legajo'

                          >
                            CREAR LEGAJO
                          </button>
                          {/* </Link> */}
                        </>
                      
                    </div>
                  </Box>
                  {/*                  <tr className='table_font_normal' key={index}>
                    <td></td>
                    <td>{admItem.datosPersonales.dni}</td>
                    <td>
                      {admItem.datosPersonales.apellido.replace(/\b\w/g, (l) => l.toUpperCase())}
                    </td>
                    <td>
                      {admItem.datosPersonales.nombre.replace(/\b\w/g, (l) => l.toUpperCase())}
                    </td>
                    <td>
                      {admItem.datosPersonales.localidad.replace(/\b\w/g, (l) => l.toUpperCase())}
                    </td>
                    <td>{admItem.datosPersonales.sexo.replace(/\b\w/g, (l) => l.toUpperCase())}</td>

                    <td>
                      <div>
                        {!activando ? (
                          <BootstrapTooltip title='Cerrar' arrow followCursor placement='top'>
                            <button
                              className='btn btn-icon btn-bg-primary btn-color-light btn-active-light btn-active-color-primary btn-sm me-1'
                              onClick={() => setDataSeleccionada((data: any) => [...data, admItem])}
                            >
                              <CancelIcon />
                            </button>
                          </BootstrapTooltip>
                        ) : (
                          <BootstrapTooltip title='Cerrar' arrow followCursor placement='top'>
                            <button
                              className='btn btn-icon btn-bg-primary btn-color-light btn-active-light btn-active-color-primary btn-sm me-1'
                              onClick={() => {
                                mfuncion(admItem)
                              }}
                            >
                              <CancelIcon />
                            </button>
                          </BootstrapTooltip>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: 0, paddingTop: 5 }} colSpan={9}>
                      <Collapse in={true} timeout='auto' unmountOnExit> */}
                  {/*                         <Box sx={{ margin: 2 }} className='no-margin-top'>
                          <div className='mt-5 fv-row'>
                            <div className='row'>
                              <div className='col-lg-3'>
                                <label className=''>Admision:</label>
                                <label className='form-label m-0'>
                                  &nbsp;
                                  {admItem.legajo}
                                </label>
                              </div>
                              <div className='col-lg-3'>
                                <label className=''>Observaciones:</label>
                                <label className='form-label m-0'>
                                  &nbsp;
                                  {admItem.observaciones
                                    ? admItem.observaciones.replace(/\b\w/g, (l) => l.toUpperCase())
                                    : '-'}
                                </label>
                              </div>
                            </div>
                            <br />
                            <br /> <br />
                          </div>
                          <span>Último Tramite Vigente</span>
                          <br />
                          <hr />
                          // Inicia la tabla para el mostrar el ultimo certificado!
                          {dataCud && (
                            <table className='table align-middle gs-0 gy-4'>
                              <thead className='border-tabla-right'>
                                <tr className='fw-bolder bg-light'>
                                  <th className='min-w-4px texto_center'></th>
                                  <th className='min-w-50px texto_center'>Estado</th>
                                  <th className='min-w-50px texto_center'>Fecha de Inicio</th>
                                  {dataCud.estado === 'aprobado' && (
                                    <th className='min-w-50px texto_center'>Fecha Aprobación</th>
                                  )}
                                  {dataCud.estado === 'aprobado' && (
                                    <th className='min-w-100px texto_center'>Nro de CUD</th>
                                  )}
                                  {dataCud.estado === 'aprobado' && (
                                    <th className='min-w-100px texto_center'>
                                      Caducidad del Certificado
                                    </th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                <tr key={'1'}>
                                  <td></td>
                                  <td className='table_cap texto_center'>
                                    <span className={`${badgeColor}`}>{dataCud.estado}</span>
                                  </td>
                                  <td className='table_cap texto_center'>
                                    {dataCud.fechaInicio?.includes('-') ?
                                      moment(dataCud?.fechaInicio).format('DD/MM/YYYY') :
                                      dataCud.fechaInicio
                                    }
                                  </td>
                                  {dataCud.estado === 'aprobado' && (
                                    <td className='table_cap texto_center'>
                                      {dataCud.infoCertificado?.fechaEntregaCert.includes('-') ?
                                        moment(dataCud.infoCertificado?.fechaEntregaCert).format('DD/MM/YYYY') :
                                        dataCud.infoCertificado?.fechaEntregaCert
                                      }
                                    </td>
                                  )}
                                  {dataCud.estado === 'aprobado' && (
                                    <td className='table_cap texto_center'>
                                      <b>{dataCud.infoCertificado.nroCud}</b>
                                    </td>
                                  )}
                                  {dataCud.estado === 'aprobado' && (
                                    <td className='table_cap texto_center'>
                                      {moment(dataCud.infoCertificado.fechaVencimiento).format(
                                        'l'
                                      )}
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                              <></>
                            </table>
                          )}
                          //Fin la tabla para el mostrar el ultimo certificado!
                          <br />
                          <br />
                          <br />
                          <hr />
                          <div>
                            <Link to={{ pathname: 'edit$admision/', state: admItem }}>
                              <Button className='btn btn-primary btn-sm'>$admision</Button>
                              &nbsp;&nbsp;
                            </Link>
                            <Link to={{ pathname: 'editDoc/', state: admItem }}>
                              <Button className='btn btn-primary btn-sm'>Documentacion</Button>
                            </Link>
                            &nbsp;&nbsp;
                            <Link to={{ pathname: 'data/', state: admItem }}>
                              <Button className='btn btn-primary btn-sm '>Datos Cud</Button>
                            </Link>
                            &nbsp;&nbsp;
                            <button
                              className='btn btn-primary btn-sm float-end'
                              onClick={() => setShow(true)}
                              // onClick={handelprint}
                              name='Descargar PDF'
                            >
                              Doc. Presentada &nbsp;&nbsp;
                              <PictureAsPdfIcon />
                            </button>
                          </div>
                        </Box> */}
                  {/*                       </Collapse>
                    </td>
                  </tr> */}
                </>
              )
            })}
        </tbody>
      </table>

      {vista === true ? null : dataSeleccionada.length === 0 ? (
        <Pagination setActivePage={setActivePage} activePage={activePage} totalPages={totalPages} />
      ) : null}

      <ModaleEntrega
        show={show}
        dataTramite={dataSeleccionada}
        handleCloseModal={handleCloseModal}
      />
    </>
  )
}
export default DataAdmision
