import { Form, Formik, FormikValues } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'

import Step2MenusModulo from '../components/ModulosPerfil/StepsModulos/Step2MenusModulo'
import { useEffect, useRef, useState } from 'react'
import { ICreateModulo } from '../interfaces/ICreateModulo'
import {
  moduleInit,
  validModSchema,
} from '../components/ModulosPerfil/StepsModulos/CreateModuleHelper'
import Step1DetallesModulo from '../components/ModulosPerfil/StepsModulos/Step1DetallesModulo'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components/_StepperComponent'
import { Link, useHistory, useLocation } from 'react-router-dom'

import Swal from 'sweetalert2'
import { callApi } from '../util/Modulos/callApi'

const StepHorizontalModulos = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(validModSchema[0])
  const [initValues, setInitValues] = useState<ICreateModulo>(moduleInit)
  const [volver, setVolver] = useState(true)
  const [selectValue, setSelectValue] = useState<ICreateModulo>()
  const [btnUpdate, setBtnUpdate] = useState<boolean>(false)
  const item: any = useLocation()
  let history = useHistory()
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    stepper.current?.currentStepIndex === 1 && setInitValues(moduleInit)
    setVolver(true)
    setBtnUpdate(false)
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(validModSchema[stepper.current.currentStepIndex - 1])
  }
  const submitStep = async (values: ICreateModulo, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    setCurrentSchema(validModSchema[stepper.current.currentStepIndex])
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      await callApi(btnUpdate, values).then((resp: any) => {
        // ////console.log(resp)
        if (resp.data.ok) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'El formulario, fue cargado con exito',
            showConfirmButton: false,
            timer: 3500,
          })
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'Verificar los datos ingresados',
            icon: 'error',
            confirmButtonText: 'Volver',
          })
        }
      })
      stepper.current.goto(1)
      actions.resetForm()
      history.push({ pathname: '/admin/servicios' })
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
    item.state && setSelectValue(item.state)
    item.state && setBtnUpdate(true)
  }, [stepperRef, item])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Información del modulo</h3>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Menús del modulo</h3>
            </div>
          </div>

          <Formik
            validationSchema={currentSchema}
            initialValues={selectValue || initValues}
            onSubmit={submitStep}
            enableReinitialize
          >
            {(formik) => {
              //////console.log(formik)
              return (
                <Form
                  className='mx-auto mw-1000px w-100 pt-10 pb-10'
                  noValidate
                  id='kt_create_account_form'
                >
                  <div className='current' data-kt-stepper-element='content'>
                    <Step1DetallesModulo update={btnUpdate} />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step2MenusModulo update={btnUpdate} />
                  </div>

                  <div className='d-flex flex-stack pt-15'>
                    {volver ? (
                      <div className='mr-2'>
                        <Link to={'servicios'}>
                          <button type='button' className='btn btn-lg btn-light-primary me-3'>
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr063.svg'
                              className='svg-icon-4 me-1'
                            />
                            Volver
                          </button>
                        </Link>
                      </div>
                    ) : (
                      <div className='mr-2'>
                        <button
                          onClick={prevStep}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr063.svg'
                            className='svg-icon-4 me-1'
                          />
                          Volver
                        </button>
                      </div>
                    )}

                    <div>
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary me-3'
                        disabled={!formik.isValid || formik.isSubmitting}
                        onClick={() => setVolver(false)}
                      >
                        {btnUpdate ? (
                          <span className='indicator-label'>
                            {stepper.current?.currentStepIndex! < stepper.current?.totatStepsNumber!
                              ? 'Continuar'
                              : 'Actualizar'}

                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        ) : (
                          <span className='indicator-label'>
                            {stepper.current?.currentStepIndex! < stepper.current?.totatStepsNumber!
                              ? 'Continuar'
                              : 'Guardar'}

                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default StepHorizontalModulos
