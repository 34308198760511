import React, {Suspense} from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ISTATE} from '../../setup/redux/interface/reduxState.interface'
import {FallbackView} from '../../_metronic/partials'
import AgendaRoute from '../modules/apps/Agenda/AgendaRoute'
import CivisRouter from '../modules/apps/Civis/ConsultaWsRouter'
import LevantatutiRouter from '../modules/apps/Levantatuti/LevantatutiRouter'
import SisAdminRoute from '../modules/apps/Sis-admin/SisAdminRoute'
import {AbmTelefoniaRoute} from '../modules/apps/abmTelefonia/AbmTelefoniaRoute'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import BensRouter from '../modules/apps/bens/BensRouter'
import {CudRoute} from '../modules/apps/Cud/CudRoute'
import {EmpleoJovenRouter} from '../modules/apps/EmpleoJoven/EmpleoJovenRouter'
import { DavRoute } from '../modules/apps/Dav/DavRoute'

export function PrivateRoutes() {
  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)

  let {perfil} = dashState

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route
          path='/abmtelefonia'
          render={() => {
            const menu: any = perfil?.find(
              (value: any) => value.nombre === 'Administración de Telefonía'
            )
            return menu ? <AbmTelefoniaRoute /> : <Redirect exact from='/' to='/dashboard' />
          }}
        ></Route>
        <Route
          path='/agenda'
          render={() => {
            const menu: any = perfil?.find((value: any) => value.nombre === 'agenda')
            return menu ? <AgendaRoute /> : <Redirect exact from='/' to='/dashboard' />
          }}
        />

        <Route
          path='/admin'
          render={() => {
            const menu: any = perfil?.find((value: any) => value.nombre === 'administracion')
            return menu ? <SisAdminRoute /> : <Redirect exact from='/' to='/dashboard' />
          }}
        ></Route>
        <Route
          path='/bens'
          render={() => {
            const menu: any = perfil?.find((value: any) => value.nombre === 'Bens')
            return menu ? <BensRouter /> : <Redirect exact from='/' to='/dashboard' />
          }}
        ></Route>
        <Route
          path='/civis'
          render={() => {
            const menu: any = perfil?.find((value: any) => value.nombre === 'Civis')
            return menu ? <CivisRouter /> : <Redirect exact from='/' to='/dashboard' />
          }}
        ></Route>
        <Route
          path='/cud'
          render={() => {
            const menu: any = perfil?.find((value: any) => value.nombre === 'cud')
            return menu ? <CudRoute /> : <Redirect exact from='/' to='/dashboard' />
          }}
        ></Route>
               { <Route
          path='/dav'
          render={() => {
            const menu: any = perfil?.find((value: any) => value.nombre === 'cud')
            return menu ? <DavRoute /> : <Redirect exact from='/' to='/dashboard' />
          }}
        ></Route>}
        <Route
          path='/noticia'
          render={() => {
            const menu: any = perfil?.find((value: any) => value.nombre === 'Noticias')
            return menu ? <LevantatutiRouter /> : <Redirect exact from='/' to='/dashboard' />
          }}
        ></Route>
        <Route
          path='/ej'
          render={() => {
            const menu: any = perfil?.find((value: any) => value.nombre === 'Empleo Joven')
            return menu ? <EmpleoJovenRouter /> : <Redirect exact from='/' to='/dashboard' />
          }}
        ></Route>

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
