import {useRouteMatch, Switch, Route, Redirect} from 'react-router-dom'
import {NuevoCargo} from './pages/NuevoCargo'
import {NuevoEquipo} from './pages/NuevoEquipo'
import {Telefonia} from './pages/telefonia'

export const AbmTelefoniaRoute = () => {
  const {url} = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${url}/telefonia`} component={Telefonia} />
      <Route exact path={`${url}/NuevoCargo`} component={NuevoCargo} />
      <Route exact path={`${url}/NuevoEquipo`} component={NuevoEquipo} />
      <Route path='*'>
        <Redirect to='/page404' />
      </Route>
    </Switch>
  )
}
