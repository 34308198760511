import {request} from '../../../../../setup/axios/axios.util'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const URL = `${API_URL}/modules/abm-telefonia`

export const getAllTelefonia = () => {
  return request().get(`${URL}/listarlineas`)
}

export const saveEquipo = (datos: any) => {
  let data = {
    data: {},
  }
  data.data = datos
  return request().post(`${URL}/agregarequipo2`, data)
}

export const saveCargo = (datos: any) => {
  let data = {
    data: {},
  }
  data.data = datos
  return request().post(`${URL}/nuevocargo`, datos)
}

export const subirarchivo = (datos: any) => {
  return request().post(`${URL}/subirarchivo`, datos)
}
