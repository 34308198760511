import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {forgotPasw, requestPassword} from '../Services/AuthService'
import Spinner from '../../../components/spinner/Spinner'

const initialValues = {
  usuario: '',
}

const forgotPasswordSchema = Yup.object().shape({
  usuario: Yup.string()
    .matches(/^[0-9]+$/, 'Solo puede ingresar números')
    .min(7, 'Minimo 7 caracteres')
    .max(11, 'Maximo 11 caracteres')
    .required('El  usuario es requerido'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [showButtons, setShowButtons] = useState(true);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [msg, setMsg] = useState<String>('')
  const history = useHistory()
  let ruta: any = {
    protocol: window.location.protocol,
    host: window.location.host,
    pathname: 'reset-password',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)

      setTimeout(() => {
        forgotPasw(values.usuario, ruta)
          .then((data: any) => {
            if (data.data.message === 'Error') {
              setHasErrors(true)
              setLoading(false)
              setMsg(data.data.data.msg)
              retunLogin()
            } else {
              setHasErrors(false)
              setShowButtons(false)
              setLoading(false)
              retunLogin()
            }
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus(
              ' Lo sentimos, parece que se han detectado algunos errores, inténtalo de nuevo.'
            )
            retunLogin()
          })
      }, 2000)
    },
  })
  function retunLogin() {
    setTimeout(() => {
      history.push('auth/login');
    }, 4000)
  }

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>¿Olvido su contraseña ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Ingrese su usuario.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {msg}
              {/*  Lo sentimos, parece que se han detectado algunos errores, inténtalo de nuevo. */}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>
              Se envio un correo asociado a su cuenta, con información. Por favor verifique su
              correo electrónico.
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Usuario</label>
          <input
            type='text'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('usuario')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.usuario && formik.errors.usuario},
              {
                'is-valid': formik.touched.usuario && !formik.errors.usuario,
              }
            )}
          />
          {formik.touched.usuario && formik.errors.usuario && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.usuario}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        { showButtons && <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
          {!loading && <span className='indicator-label'>Enviar</span>}
          {loading && <span className='indicator-label'>Enviando...</span>}
          </button>
            {loading && (
              <>
                <Spinner />
                <span className='indicator-progress'>
                  Por favor espere...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </>
            )}
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancelar
            </button>
          </Link>{' '}
        </div>}
        {/* end::Form group */}
      </form>
    </>
  )
}
