import React from 'react'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Inoticia} from '../interface/Inoticia'
import { patchBajarNoticia, patchPublicar} from '../services/Noticias-Services'
import {color} from '@mui/system'
import {styled} from '@mui/material'
import { useSelector } from 'react-redux'
import { ISTATE } from '../../../../../setup/redux/interface/reduxState.interface'

type buttonState = {
  objet?: Inoticia
}
const Publicar = (noticia: string) => {
  const pub = {
    _id: noticia,
    mod: 'modpublibtn',
    publicacion: true,
    publicacionView: 'Publicada',
  }
  patchPublicar(pub)
}
const BajaNoticia = (noticia: string) => {
  const baja = {
    _id: noticia,
    mod: 'modbaja',
    publicacion: false,
    publicacionView:'No Publicada'
  }
  patchBajarNoticia(baja)
}


const BootstrapTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: `${color}`,
    color: `#919A9B`,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${color}`,
    color: `#FFFFFF`,
    boxShadow: theme.shadows[2],
    fontSize: 11,
    fontWeightBold: 700,
    disabled: false,
  },
}))
export const ButtonActions: React.FC<buttonState> = ({objet}) => {
  const AlertaPublicar = () => {
    if(objet?.publicacionView == 'Incompleta'){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'La noticia esta incompleta no se puede publicar',
        showConfirmButton: false,
        timer: 1500
      })
    }else{Swal.fire({
      title: '¿Desea publicar la noticia antes de tiempo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '!Si, publicar noticia!',
    }).then((result) => {
    
      if (result.isConfirmed) {
        Publicar(objet!._id!)
        window.location.reload()
      }
    })}
    
  }
  const AlertaNoPublicar = () => {
    Swal.fire({
      title: '¿Desea dar de baja la noticia?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, dar baja a noticia!',
    }).then((result) => {
      if (result.isConfirmed) {
        BajaNoticia(objet!._id!)
        window.location.reload()
      }
    })
  }
  const perfilState: any = useSelector((state: ISTATE) => state.dashBoard)

  let Role: any = []
  let array = perfilState.perfil
  for (const menu of array) {
    Role.push(menu)
  }

  const FiltRole: any = Role.filter(function (nombre: {nombre: string}) {
    return nombre.nombre === 'Noticias'
  })

  const FiltMenu = FiltRole[0].menu
  
  const FiltPermisoCrear: any = FiltMenu.filter(function (name: {name: string}) {
    return name.name === 'crear noticia'
  })

  return (
    <div>
      {/*Boton Editar */}
      {FiltPermisoCrear[0].roleUs.editor ? (objet!.publicacion ? null: (<BootstrapTooltip title='Editar Noticia' arrow followCursor placement='top'>
        <Link to={{pathname: 'EditarNoticia/', state: {objet}}}>
          <button className='btn btn-icon btn-bg-primary btn-color-light btn-active-light btn-active-color-primary btn-sm me-1'>
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </button>
        </Link>
      </BootstrapTooltip>)): null}

      {/* Bajar Noticia // Publicar Noticia */}
      <BootstrapTooltip title='Publicar/Bajar, Noticia' arrow followCursor placement='top'>
        {objet!.publicacion ? (
          <button
            onClick={() => AlertaNoPublicar()}
            className='btn btn-icon btn-bg-danger btn-color-light btn-active-light btn-active-color-danger btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='svg-icon-3' />
          </button>
        ) : (
          <button
            onClick={() => AlertaPublicar()}
            className='btn btn-icon btn-bg-success btn-color-light btn-active-light btn-active-color-success btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr016.svg' className='svg-icon-3' />
          </button>
        )}
      </BootstrapTooltip>

      {/* Imagenes */}
      <BootstrapTooltip title='Subir Imagenes' arrow followCursor placement='top'>
        <Link to={{pathname: 'editImagen/', state: {objet}}}>
          <button className='btn btn-icon btn-bg-info btn-color-light btn-active-light btn-active-color-info btn-sm me-1'>
            <KTSVG path='/media/icons/duotune/files/fil009.svg' className='svg-icon-3' />
          </button>
        </Link>
      </BootstrapTooltip>
    </div>
  )
}
