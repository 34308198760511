import { useEffect, useState } from 'react'
/** React */
/** Material */
import { Radio } from '@mui/material'
/** Formik */
import { ErrorMessage, Field } from 'formik'
import FormikControl from '../../../../../components/Formik/FormikControl'
/** Services */
import { localidadesNqn } from '../../../../../services/gral/proLocService'
import { changedateformat } from '../../helpers/fechaUtil'

type Props = {
  handleChange: any
  handleBlur: any
  btnEdit?: any
  formikValues?: any
  apellido?:any
  nombre?:any
}

export const DatosPersonales: React.FC<Props> = ({
  handleChange,
  handleBlur,
  btnEdit,
  formikValues,
}) => {
  const [localidad, setLocalidad] = useState<any>([])

  useEffect(() => {
    localidadesNqn().then((res: any) => {
      setLocalidad(res.data.data)
    })
  }, [])

  return (
    <>
      <div className='row alineacion_inputs'>
        <div className='col-lg-2'>
          <FormikControl
            control='input'
            type='text'
            id='datosPersonales.dni'
            name='datosPersonales.dni'
            label='Dni'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className='col-lg-2'>
          <label className='form-label fw-bolder text-dark fs-6'>Tipo Doc</label>
          <Field
            as='select'
            id='datosPersonales.tipoDoc'
            name='datosPersonales.tipoDoc'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
          >
            <option value=''>Seleccione</option>
            <option value='DNI F'>DNI F</option>
            <option value='DNI M'>DNI M</option>
            <option value='DNI X'>DNI X</option>
            <option value='LC'>LC</option>
            <option value='PAS'>PAS</option>
            <option value='LE'>LE</option>
            <option value='CI'>CI</option>
          </Field>
          <ErrorMessage name='datosPersonales.tipoDoc' component='span' className='error' />
        </div>
        <div className='col-lg-3'>
          <label className='form-label fw-bolder text-dark fs-6'>Apellido/s</label>
          <Field
            control='input'
            type='text'
            id='datosPersonales.apellido'
            name='datosPersonales.apellido'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name='datosPersonales.apellido' component='span' className='error' />
        </div>
        <div className='col-lg-3'>
          <label className='form-label fw-bolder text-dark fs-6'>Nombre/s</label>
          <Field
            control='input'
            type='text'
            id='datosPersonales.nombre'
            name='datosPersonales.nombre'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name='datosPersonales.nombre' component='span' className='error' />
        </div>
        <div className='col-lg-2'>
          <label className='form-label fw-bolder text-dark fs-6'>Género</label>
          <Field
            as='select'
            id='datosPersonales.sexo'
            name='datosPersonales.sexo'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
          >
            <option value=''>Seleccione</option>
            <option value='m'>Masculino</option>
            <option value='f'>Femenino</option>
            <option value='t'>Transexual</option>
            <option value='o'>Otro</option>
          </Field>
          <ErrorMessage name='datosPersonales.sexo' component='span' className='error' />
        </div>
      </div>
      <br /> <br />
      <div className='row alineacion_inputs'>
        <div className='col-lg-2'>
          <label className='form-label fw-bolder text-dark fs-6'>Fecha de Nacimiento.</label>

          <Field
            type='date'
            id='datosPersonales.fechaNacimiento'
            name='datosPersonales.fechaNacimiento'
            value={changedateformat(formikValues.datosPersonales.fechaNacimiento)}
            className='form-control form-control-solid capital'
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!btnEdit}
          />
        </div>
        <div className='col-lg-2'>
          <FormikControl
            control='input'
            type='text'
            id='datosPersonales.cuit'
            name='datosPersonales.cuit'
            label='Cuit'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className='col-lg-3'>
          <label className='form-label fw-bolder text-dark fs-6'>Localidad</label>
          <Field
            as='select'
            id='datosPersonales.localidad'
            name='datosPersonales.localidad'
            className='form-control form-control-solid capital'
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!btnEdit}
            required={true}
          >
            <option value=''>Seleccionar</option>
            {localidad.map((value: any, index: any) => {
              return (
                <option key={index} value={value.nombre.toLowerCase()}>
                  {value.nombre}
                </option>
              )
            })}
          </Field>
          <ErrorMessage name='datosPersonales.localidad' component='span' className='error' />
        </div>
        <div className='col-lg-3'>
          <label className='form-label fw-bolder text-dark fs-6'>Departamento</label>
          <Field
            as='input'
            id='datosPersonales.departamento'
            name='datosPersonales.departamento'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
          />
          <ErrorMessage name='datosPersonales.departamento' component='span' className='error' />
        </div>
        <div className='col-lg-2'>
          <label className='form-label fw-bolder text-dark fs-6'>Código Postal</label>
          <Field
            as='input'
            id='datosPersonales.codigoPostal'
            name='datosPersonales.codigoPostal'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
          />
          <ErrorMessage name='datosPersonales.codigoPostal' component='span' className='error' />
        </div>
      </div>
      <br /> <br />
      <div className='row alineacion_inputs'>
        <div className='col-lg-4'>
          <label className='form-label fw-bolder text-dark fs-6'>Domicilio</label>
          <Field
            control='input'
            type='text'
            id='datosPersonales.domicilio'
            name='datosPersonales.domicilio'
            disabled={!btnEdit}
            className='form-control form-control-solid capital'
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name='datosPersonales.domicilio' component='span' className='error' />
        </div>
        <div className='col-lg-2'>
          <label className='form-label fw-bolder text-dark fs-6'>Estado Civil</label>
          <Field
            as='select'
            id='datosPersonales.estadoCivil'
            name='datosPersonales.estadoCivil'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
          >
            <option value=''>Seleccione Uno</option>
            <option value='soltero/a'>Soltero/a</option>
            <option value='casado/a'>Casado/a</option>
            <option value='separado/a'>Separado/a</option>
            <option value='divorciado/a'>Divorciado/a</option>
            <option value='viudo/a'>Viudo/a</option>
            <option value='union civil / convivencial'>union civil / convivencial</option>
          </Field>
          <ErrorMessage name='datosPersonales.estadoCivil' component='span' className='error' />
        </div>
        <div className='col-lg-3'>
          <FormikControl
            control='input'
            type='text'
            id='datosPersonales.mail'
            name='datosPersonales.mail'
            label='Email'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className='col-lg-3'>
          <FormikControl
            control='input'
            type='text'
            id='datosPersonales.telefono'
            name='datosPersonales.telefono'
            label='Telefono'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <br /> <br />
      <div className='row alineacion_inputs'>
        <div className='col-lg-3'>
          <label className='form-label fw-bolder text-dark fs-6'>Nacionalidad</label>
          <Field
            as='input'
            id='datosPersonales.nacionalidad'
            name='datosPersonales.nacionalidad'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
          />
          <ErrorMessage name='datosPersonales.nacionalidad' component='span' className='error' />
        </div>
        <div className='col-lg-3 mt-8'>
          <label className=''>Nativo</label>{' '}
          <Field
            type='radio'
            as={Radio}
            id='datosPersonales.tipoNacionalidad'
            name='datosPersonales.tipoNacionalidad'
            value='nativo'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Naturalizado</label>{' '}
          <Field
            type='radio'
            as={Radio}
            id='datosPersonales.tipoNacionalidad'
            name='datosPersonales.tipoNacionalidad'
            value='naturalizado'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className='col-lg-3'>
          <label className='form-label fw-bolder text-dark fs-6'>Tipo de Residencia</label>
          <Field
            as='select'
            id='datosPersonales.tipoResidencia'
            name='datosPersonales.tipoResidencia'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
          >
            <option value=''>Seleccione</option>
            <option value='transitoria'>Transitoria</option>
            <option value='temporaria'>Temporaria</option>
            <option value='permanente'>Permanente</option>
            <option value='precaria'>Precaria</option>
          </Field>
          <ErrorMessage name='datosPersonales.tipoResidencia' component='span' className='error' />
        </div>
        <div className='col-lg-3'>
          <label className='form-label fw-bolder text-dark fs-6'>F. Vencimiento Residencia</label>
          <Field
            control='input'
            type='date'
            id='datosPersonales.fechaVencimientoResidencia'
            name='datosPersonales.fechaVencimientoResidencia'
            value={changedateformat(formikValues.datosPersonales.fechaVencimientoResidencia)}
            className='form-control form-control-solid '
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage
            name='datosPersonales.fechaVencimientoResidencia'
            component='span'
            className='error'
          />
        </div>
      </div>
      <br /> <br /> <br /> <br />
      <div className='row alineacion_inputs'>
        <label className='form-label fw-bolder text-dark fs-6'>
          Adquirio Vehiculo a traves de la Ley 19279
        </label>
        <div className='col-lg-4'>
          <Field
            type='radio'
            as={Radio}
            id='datosPersonales.vehiculoLey'
            name='datosPersonales.vehiculoLey'
            value='si'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>SI</label>
        </div>
        <div className='col-lg-4'>
          <Field
            type='radio'
            as={Radio}
            id='datosPersonales.vehiculoLey'
            name='datosPersonales.vehiculoLey'
            value='no'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>NO</label>
        </div>
        <div className='col-lg-2'>
          <label className='form-label fw-bolder text-dark fs-6'>Fecha</label>
          <Field
            control='input'
            type='date'
            id='datosPersonales.fechaVehiculo'
            name='datosPersonales.fechaVehiculo'
            value={changedateformat(formikValues.datosPersonales.fechaVehiculo)}
            className='form-control form-control-solid '
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name='datosPersonales.fechaVehiculo' component='span' className='error' />
        </div>
      </div>
      <br /> <br />
      <div className='row alineacion_inputs'>
        <label className='form-label fw-bolder text-dark fs-6'>
          Posee Simbolo Internacional de acceso
        </label>
        <div className='col-lg-4'>
          <Field
            type='radio'
            as={Radio}
            id='datosPersonales.simboloInternacional'
            name='datosPersonales.simboloInternacional'
            value='si'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>SI</label>
        </div>
        <div className='col-lg-4'>
          <Field
            type='radio'
            as={Radio}
            id='datosPersonales.simboloInternacional'
            name='datosPersonales.simboloInternacional'
            value='no'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>NO</label>
        </div>
      </div>
      <br /> <br />
      <div className='row alineacion_inputs'>
        <label className='form-label fw-bolder text-dark fs-6'>
          En caso de cumplir con todos los requisitos exigidos por el ordenamiento legal de
          asignaciones familiares: ¿La persona con discapacidad esta interesada en percibir
          asignaciones familiares viculadas a la discapacidad a las que pudiese tener derecho?
        </label>
        <div className='col-lg-4'>
          <br />
          <Field
            type='radio'
            as={Radio}
            id='datosPersonales.derechoAsignacionesF'
            name='datosPersonales.derechoAsignacionesF'
            value='si'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>SI</label>
        </div>
        <div className='col-lg-4'>
          <br />
          <Field
            type='radio'
            as={Radio}
            id='datosPersonales.derechoAsignacionesF'
            name='datosPersonales.derechoAsignacionesF'
            value='no'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>NO</label>
        </div>
      </div>
      <br /> <br /> <br /> <br />
    </>
  )
}
export default DatosPersonales
