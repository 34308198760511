import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
import {KTSVG} from '../../../../../_metronic/helpers'
import {IEmpleados} from '../interfaces/IEmpleados'
import {getAllEmpleadosOneEmpresa} from '../services/EJ.Empleado.Services'
import {ModalPeriodos} from './ModalPeriodos'
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'

const TablaMisEmpleados = () => {
  const authState: any = useSelector((state: ISTATE) => state.auth)
  let {usuario} = authState

  const [loading, setLoading] = useState<any>(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [titulo, setTitulo] = useState<string>('')
  const [empeleados, setEmpleados] = useState<IEmpleados[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selecEmp, setSelecEmp] = useState<IEmpleados>()
  const fecha = new Date()
  const hoy = fecha.toLocaleDateString()

  const tooltip = (titulo: string) => {
    return (
      <Tooltip id='tooltip'>
        <strong>{titulo}</strong>
      </Tooltip>
    )
  }

  let totalMisEmpleados = 0
  const siguientePag = () => {
    totalMisEmpleados > currentPage + 5 && setCurrentPage(currentPage + 5)
  }
  const anteriorPag = () => {
    currentPage > 0 && setCurrentPage(currentPage - 5)
  }
  const closeModal = () => {
    setOpenModal(!openModal)
  }
  useEffect(() => {
    let isMounted = true
    getAllEmpleadosOneEmpresa(usuario.usuario).then((res: any) => {
      if (isMounted) {
        res.data.ok && setEmpleados(res.data.empleados)
      }
    })
    return () => {
      isMounted = false
    }
  }, [openModal])

  return (
    <div className='mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <div className='containerTableTitle'>
          <h3 className='headerTableLefet'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              Empleados Registrados al: {hoy}: {empeleados.length}
            </span>
          </h3>
          <Link to={'formEmpleado'} className='btn btn-sm btn-light-primary'>
            <KTSVG
              path='/media/icons/duotune/general/gen035.svg'
              className='svg-icon-5 svg-icon-gray-500 me-1'
            />
            Cargar Empleado.
          </Link>
        </div>

        {/* begin::Body */}
        <div className='card-body py-3 border-0 pt-5'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  {/* <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-13-check'
                      />
                    </div>
                  </th> */}
                  <th className='min-w-150px'>DNI</th>
                  <th className='min-w-140px'>Apellido y Nombre</th>
                  <th className='min-w-120px'>F.Inscripción </th>
                  <th className='min-w-120px'>Localidad</th>
                  <th className='min-w-120px'>Estado</th>
                  <th className='min-w-100px text-end'>Opciones</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {empeleados.map((empleado: IEmpleados, index: any) => {
                  return (
                    <>
                      <tr key={index}>
                        {/* <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-13-check'
                              type='checkbox'
                              value='1'
                              onClick={() => {
                                console.log(selecEmp)
                                setSelecEmp(!selecEmp)
                              }}
                            />
                          </div>
                        </td> */}
                        <td>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6 '>
                            {empleado.documento}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6 capital'
                          >
                            {empleado.datosPersonales.apellido}
                          </a>
                          <span className='text-muted fw-bold text-muted d-block fs-7'>
                            {empleado.datosPersonales.nombres}
                          </span>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                          >
                            {empleado.fechaCarga}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6 capital'
                          >
                            {empleado.datosPersonales.ciudad}
                          </a>
                        </td>

                        <td>
                          {empleado.estado === 'pendiente' && (
                            <span className='badge badge-light-primary capital'>
                              {empleado.estado}
                            </span>
                          )}
                          {empleado.estado === 'validado' && (
                            <span className='badge badge-light-success capital'>
                              {empleado.estado}
                            </span>
                          )}
                        </td>
                        <td className='text-end'>
                          {empleado.estado === 'validado' && (
                            <OverlayTrigger
                              placement='top'
                              overlay={tooltip('Ver descuentos, accedidos por el empleado.')}
                            >
                              <div
                                className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                                onClick={() => {
                                  setOpenModal(true)
                                  setSelecEmp(empleado)
                                  setTitulo('beneficio accedido')
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/finance/fin007.svg'
                                  className='svg-icon-3'
                                />
                              </div>
                            </OverlayTrigger>
                          )}

                          {empleado.anio!.length > 0 && (
                            <OverlayTrigger
                              placement='top'
                              overlay={tooltip('Ver estado de la documentación.')}
                            >
                              <div
                                className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1'
                                onClick={() => {
                                  setOpenModal(true)
                                  setSelecEmp(empleado)
                                  setTitulo('periodos cargados')
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen004.svg'
                                  className='svg-icon-3 '
                                />
                              </div>
                            </OverlayTrigger>
                          )}
                          {empleado.estado === 'validado' && (
                            <OverlayTrigger
                              placement='top'
                              overlay={tooltip('Presentar documentación.')}
                            >
                              <div
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={() => {
                                  setOpenModal(true)
                                  setSelecEmp(empleado)
                                  setTitulo('cargar documentación')
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen014.svg'
                                  className='svg-icon-3'
                                />
                              </div>
                            </OverlayTrigger>
                          )}
                          {/*  {empleado.estado === 'validado' && (
                            <OverlayTrigger placement='top' overlay={tooltip('Cargas Sociales.')}>
                              <div
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={() => {
                                  setOpenModal(true)
                                  setSelecEmp(empleado)
                                  setTitulo('Cargas Sociales')
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen014.svg'
                                  className='svg-icon-3'
                                />
                              </div>
                            </OverlayTrigger>
                          )} */}
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
            {/* begin::BTN Paginacion */}
            <ul className='pagination'>
              {currentPage === 0 ? (
                <li className='page-item disabled'>
                  <a type='button' className='btn btn-sm btn-light-info' onClick={anteriorPag}>
                    Anterior
                  </a>
                </li>
              ) : (
                <li className='page-item '>
                  <a type='button' className='btn btn-sm btn-light-info' onClick={anteriorPag}>
                    Anterior
                  </a>
                </li>
              )}

              <li className='page-item'>
                <a type='button' className='btn btn-sm btn-light-info' onClick={siguientePag}>
                  Siguiente
                </a>
              </li>
            </ul>
          </div>
          <ModalPeriodos
            openModal={openModal}
            closeModal={closeModal}
            docPeriodos={selecEmp}
            titulo={titulo}
          />
          {/* end::Table container */}
        </div>
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default TablaMisEmpleados
