import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import Spinner from '../../../../components/spinner/Spinner'
import { eliminarEmpresa, getAllEmpresas } from '../services/EJ.Services'
import { Link } from 'react-router-dom'
import { IEmpresa } from '../interfaces/Iempresas'
import Swal from 'sweetalert2'
import BotonEliminarMod from '../components/Modal/BotonEliminarMod'
import useAreaUser from '../../../../Hooks/useAreaUser'

const TablaEmpresas = () => {
  const [loading, setLoading] = useState<any>(false)
  const [empresas, setEmpresas] = useState<IEmpresa[]>([])
  const [totalEmpresas, setTotalEmpresas] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState(5)
  const fecha = new Date()
  const hoy = fecha.toLocaleDateString()

  const {areaUsr} = useAreaUser();

  const siguientePag = () => {
    totalEmpresas > currentPage && setCurrentPage(currentPage + 5)
    totalEmpresas > currentPage && setPage(page + 1)
  }
  const anteriorPag = () => {
    currentPage > 0 && setCurrentPage(currentPage - 5)
    currentPage > 0 && setPage(page - 1)
  }
  useEffect(() => {
    let isMounted: boolean = true

    setLoading(true)
    if (empresas.length < 5) {
      getAllEmpresas(currentPage, page).then((res: any) => {
        //    console.log(res)
        if (isMounted) {
          setEmpresas(res.data.data)

          setTotalEmpresas(res.data.totalEmpresas)
        }
      })
    }
    else {
      getAllEmpresas(currentPage, page).then((res: any) => {
        //    console.log(res)
        if (isMounted) {
          setEmpresas(res.data.data)

          setTotalEmpresas(res.data.totalEmpresas)
        }
      })
    }
    return () => {
      isMounted = false
      setLoading(false)
    }
  }, [currentPage, empresas.length]);



  const onDelete = async (index: number, id: any) => {
    const result = await eliminarEmpresa(id);
    if (result) {
      const empresasCopy = [...empresas];
      empresasCopy.splice(index, 1);
      setEmpresas(empresasCopy);
      console.log(empresasCopy);
      Swal.fire('Eliminación correcta!', '', 'success')
    }

  }

  return (
    <div className='mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Empresas Inscriptas al: {hoy}</span>
        </h3>

        {/* begin::Body */}
        <div className='card-body py-3 border-0 pt-5'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-13-check'
                      />
                    </div>
                  </th>
                  <th className='min-w-150px'>CUIT/CUIL</th>
                  <th className='min-w-140px'>Nombre</th>
                  <th className='min-w-120px'>F.Inscripción </th>
                  <th className='min-w-120px'>Localidad</th>
                  <th className='min-w-120px'>Estado</th>
                  <th className='min-w-100px text-end'>Acciones</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {empresas.map((empresa: any, index) => {
                  return (
                    <tr key={index}>
                      <td key={index}>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-13-check'
                            type='checkbox'
                            value='1'
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bolder text-hover-primary fs-6'>
                          {empresa.cuit}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          {empresa.razon_social}
                        </div>
                        {/* <span className='text-muted fw-bold text-muted d-block fs-7'>Code: PH</span> */}
                      </td>
                      <td>
                        <div className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          {empresa.fecha_alta}
                        </div>
                        {/* <span className='text-muted fw-bold text-muted d-block fs-7'>
                          Code: Paid
                        </span> */}
                      </td>
                      <td>
                        <div className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          {empresa.localidad}
                        </div>
                        {/* <span className='text-muted fw-bold text-muted d-block fs-7'>
                          Web, UI/UX Design
                        </span> */}
                      </td>

                      <td>
                        {empresa.estado && (
                          <span className='badge badge-light-success capital'>Aprobado</span>
                        )}
                        {!empresa.estado && (
                          <span className='badge badge-light-danger capital'>Pendiente</span>
                        )}
                        {/*  {empresa.estado === 'pendiente' && (
                          <span className='badge badge-light-primary capital'>
                            {empresa.estado}
                          </span>
                        )} */}
                      </td>
                      <td className='text-end'>
                        {/* <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen019.svg'
                        className='svg-icon-3'
                      />
                    </a> */}

                        <Link
                          to={{
                            pathname: 'misdatos',

                            state: { empresa, us: 'gob', tipoUsuario: 'Empleo Joven' },
                          }}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          data-bs-toggle='tooltip'
                          data-bs-trigger='hover'
                          data-bs-dismiss-='click'
                          title='Editar'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />

                        </Link>

                        {/*  <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a> */}
                        {areaUsr === 'juventud' &&
                          <BotonEliminarMod
                            onDelete={onDelete}
                            index={index}
                            id={empresa._id}
                            texto={empresa.razon_social}
                          />
                        }

                        {/*                         <span

                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          data-bs-toggle='tooltip'
                          data-bs-trigger='hover'
                          data-bs-dismiss-='click'
                          title='Eliminar'
                          onClick={() => Swal.fire({
                            title: 'Confirma eliminación?',
                            showDenyButton: true,
                            confirmButtonText: 'Confirmar',
                            denyButtonText: 'Cancelar'
                          }).then((result) => {
                            if (result.isConfirmed) {
                              Swal.fire('Eliminación correcta!', '', 'success')
                            }
                          })}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen040.svg'
                            className='svg-icon-3 svg-icon-danger' 
                          />
                        </span> */}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
            {/* BTN Paginacion */}
            <ul className='pagination'>
              {currentPage === 0 ? (
                <li className='page-item disabled'>
                  <a type='button' className='btn btn-sm btn-light-info' onClick={anteriorPag}>
                    Anterior
                  </a>
                </li>
              ) : (
                <li className='page-item '>
                  <a type='button' className='btn btn-sm btn-light-info' onClick={anteriorPag}>
                    Anterior
                  </a>
                </li>
              )}

              <li className='page-item'>
                <a type='button' className='btn btn-sm btn-light-info' onClick={siguientePag}>
                  Siguiente
                </a>
              </li>
            </ul>
          </div>
          {/* end::Table container */}
        </div>
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default TablaEmpresas
