import moment from 'moment'

let hoy1: any = moment().format('DD/MM/YYYY')
let hoy2 = moment(hoy1, 'DD/MM/YYYY')

export const verificarEdad = (fechaNacimento: string) =>
  new Promise((resolve, reject) => {
    let nacimiento = moment(fechaNacimento, 'DD/MM/YYYY')
    let anios = hoy2.diff(nacimiento, 'year')

    if (anios >= 18 && anios <= 35) {
      resolve({edad: 'ok', anios})
    } else {
      reject('Artículo 3º: Jóvenes desde los 18 hasta los 35 años.')
    }
  })

export const verificarLocalidad = (provincia: string) =>
  new Promise((resolve, reject) => {
    if (provincia === 'neuquen' || provincia === 'NEUQUEN') {
      resolve(true)
    } else {
      reject('Artículo 3º: Jóvenes, con residencia en la provincia.')
    }
  })
