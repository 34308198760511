import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {Settings} from '../components/usuarios/accounts/components/settings/Settings'
import {ICreateUsr} from '../interfaces/ICreateUsr'
import {getOneUsuarios} from '../services/UsuarioService'

const UsuarioAccountPage: React.FC = () => {
  const location: any = useLocation()

  const [usr, setUsr] = useState<ICreateUsr>()
  const [op, setOp] = useState<string>('')

  useEffect(() => {
    if (location.state.op === 'BtnPerfil') {
      getOneUsuarios(location.state.usuario).then((res: any) => {
        const {data} = res.data
        setOp(location.state.op)
        setUsr(data)
      })
    } else {
      setUsr(location.state)
    }

    return () => {}
  }, [location])

  return (
    <>
      {/* <AccountHeader /> */}

      {usr !== undefined && <Settings usuario={usr} op={op} />}
    </>
  )
}

export default UsuarioAccountPage
