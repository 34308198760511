import {ICreateModulos} from '../../interfaces/ICreateModulo'
const setOpcion = (obj: any): boolean => {
  let opc = Object.values(obj)
  let found = opc.find((ele) => ele === true)
  let exist: boolean = false
  found ? (exist = true) : (exist = false)
  return exist
}
const VerificaMenuSelec = (perfiles: ICreateModulos) => {
  let setPerfil: any = []

  for (let perfil of perfiles) {
    let setMenu: any = []
    for (let pmenu of perfil.menu) {
      let data = setOpcion(pmenu.roleUs)
      data && setMenu.push(pmenu)
    }
    perfil.menu = setMenu
    setPerfil.push(perfil)
  }
  return setPerfil
}

export default VerificaMenuSelec
