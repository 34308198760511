import { Page, PDFViewer } from '@react-pdf/renderer'
import { useLocation } from 'react-router'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useRef } from 'react'
import React from 'react'
import { VerExportacion } from './Exportar/VerExportacion'
import moment from 'moment';
import { PageTitle } from '../../../../../_metronic/layout/core';

import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import '../components/Botones/BotonAgenda.scss'
import { KTSVG } from '../../../../../_metronic/helpers/components/KTSVG';
import './Exportar/Exportar.scss'

type Props = {
  imagen?: string
  pag_inicio: string
  nombre: string
  colorFondo: string
  colorDisable: string
  sizeBoton?: string
  sizeIcon?: string
  habilitado: boolean
  data?: any
  onClickEvento: any
}
const ButtonMarginClass = 'ms-1 ms-lg-3'


const BotonImprimir: React.FC<Props> = ({ imagen, pag_inicio, nombre, colorFondo, colorDisable, sizeBoton, sizeIcon, habilitado, data, onClickEvento }) => {
  const dispatch = useDispatch()
  let color: any = ''
  let ButtonIconSizeClass = (sizeIcon !== undefined ? sizeIcon : 'svg-icon-1')
  let ButtonHeightClass = (sizeBoton !== undefined ? sizeBoton : 'w-50px h-40px w-md-60px h-md-50px')

  const LightTooltip = styled(({ className, ...props }: TooltipProps) =>
    (<Tooltip {...props} classes={{ popper: className }} placement="top" />))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: `${color}`,
        color: `#FFFFFF`,
        boxShadow: theme.shadows[1],
        fontSize: 11,
        fontWeightBold: 700,
        disabled: false
      },
    }));
  return (
    <>
      <LightTooltip key={'TooltipExportacion'} className='negrita' children={
        <div className={clsx('d-flex align-items-center', ButtonMarginClass,)}>
          <button
            className={clsx(
              `btn btn-icon  ${colorFondo} btn-custom ${colorDisable}`,
              ButtonHeightClass,
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            onClick={() => { onClickEvento() }}

          >
            <div className="row d-flex">
              <KTSVG path={`/media/icons/duotune${imagen}`} className={ButtonIconSizeClass} />
              <label className='fs-8'>{nombre}</label>
            </div>

          </button>

        </div>


      } title={<Typography color="inherit" style={{ fontWeight: 600 }}>{nombre}</Typography>} ></LightTooltip>

    </>
  )
}
class PDF extends React.Component {
  render() {
    return (
      <>

        <div className="print-container pt-3" style={{ margin: "0", padding: "0" }}>
          Generado: {moment(new Date).format("DD/MM/YYYY HH:mm")}
          <VerExportacion key={'VerExportacion'} />
        </div>
      </>
    )
  }
}

export const ExportarEventos = () => {
  let componentRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const marginTop = "10px"
  const marginRight = "10px"
  const marginBottom = "10px"
  const marginLeft = "10px"
  const getPageMargins = () => {
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
  };

  return (
    <div key={'Exportar'} className={`card`} >
      <PageTitle breadcrumbs={[]}>Exportar Eventos</PageTitle>
      <div className='card-header align-items-center border-1 bg-light-gray-500'>
        <h3 className='card-title align-items-start flex-column'>

        </h3>

        <BotonImprimir key={'BotonImprimir'} pag_inicio={''} habilitado={true} onClickEvento={handlePrint} nombre={'Imprimir PDF'} imagen='/arrows/arr078.svg' colorFondo='btn-active-light-danger' colorDisable={''} />
      </div>
      <style >{getPageMargins}
      </style>

      <PDF ref={componentRef} />
    </div >
  )
}
