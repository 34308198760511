import { ITablaDatos } from '../interfaces/TablaDatos'
import { getUsuarios } from '../services/UsuarioService'
import { tablaDatosAction } from '../redux/TablaDatosAction'
import { useDispatch } from 'react-redux'
import { getAllOrg } from '../services/OrganizacionService'
import { getAllModulos } from '../services/ModuleService'

type Props = {
  className: string
  mPrincipal?: []
}

let tablaDatos = {} as ITablaDatos

const MenuPrincial: React.FC<Props> = ({ className, mPrincipal }) => {
  const dispatch = useDispatch()
  const handelChange = async (menu: string) => {
    switch (menu) {
      case 'usuarios':
        getUsuarios().then((resp: any) => {
          if (resp.data.ok) {
            //////console.log(resp.data)
            tablaDatos = {
              className: 'card-xxl-stretch text-white mb-5 mb-xl-8',
              tablaDatos: resp.data.data,
              tituloMenu: 'Administración de usuarios',
              botonPrincipal: 'Crear Usuario',
              link: 'usuario',
              linkEdit: '/usuario/settings',
              headColumns: {
                col1: 'Usuario',
                col2: 'Organización',
                col3: 'Estado',
                col4: 'Opciones',
              },
            }
            dispatch(tablaDatosAction(tablaDatos))
          }
        })

        break

      case 'organismos':
        getAllOrg().then((resp: any) => {
          if (resp.data.ok) {
            tablaDatos = {
              className: 'card-xxl-stretch text-white mb-5 mb-xl-8',
              tablaDatos: resp.data.data,
              tituloMenu: 'Administración de Organismos',
              botonPrincipal: 'Crear Organismo',
              link: '/admin/organismos',
              linkEdit: '/admin/organismos/settings',
              headColumns: {
                col1: 'Nombre',
                col2: 'Responsable',
                col3: 'Estado',
                col4: 'Opciones',
              },
            }
            dispatch(tablaDatosAction(tablaDatos))
          }
        })

        break
      case 'modulos':
        getAllModulos().then((resp: any) => {
          if (resp.data.ok) {
            tablaDatos = {
              className: 'card-xxl-stretch text-white mb-5 mb-xl-8',
              tablaDatos: resp.data.data,
              tituloMenu: 'Administración de Modulos del Sistema',
              botonPrincipal: 'Crear Modulo',
              link: '/admin/modulos',
              linkEdit: '/admin/modulos',
              headColumns: {
                col1: 'Nombre',
                col2: 'Menus',
                col3: 'Estado',
                col4: 'Opciones',
              },
            }
            dispatch(tablaDatosAction(tablaDatos))
          }
        })

        break
      default:
        break
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-white'>Menú de Opciones</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {/* begin::Item */}
        {mPrincipal?.map((item: any, index) => {
          return (
            <div className='d-flex align-items-center mb-8' key={index}>
              {/* begin::Bullet */}
              <span className='bullet bullet-vertical h-40px bg-success'></span>
              {/* end::Bullet */}

              {/* begin::Description */}
              <div className='flex-grow-1 mx-5'>
                <button
                  className='btn btn-xl btn-light-primary capital'
                  onClick={() => handelChange(item.name)}
                >
                  {item.name}
                </button>
              </div>

              {/* end::Description */}
            </div>
          )
        })}

        {/* end:Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { MenuPrincial }
