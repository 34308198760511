import React, {useEffect, useState} from 'react'
import {Box, Collapse} from '@mui/material'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {ButtonActionBens} from '../../ButtonActionBens'
import {ModalBens} from '../../Modal/ModalBens'
import {useHistory, useLocation} from 'react-router'
type props = {
  data: any
  className: string
  headColumns?: {
    col1?: string
    col2?: string
    col3?: string
    col4?: string
    col5?: string
    col6?: string
  }
  tituloMenu?: string
}
export const TablaID: React.FC<props> = ({data, className, headColumns, tituloMenu}) => {
  let urlData = useLocation()

  const [open] = useState(true)
  const [selectItem, setSelectItem] = useState<any>('')
  const [activePage, setActivePage] = useState(1)

  const rowsPerPage = 5
  const count = data.length

  const totalPages = Math.ceil(count / rowsPerPage)
  const calculatedRows = data.slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)
  const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1
  const end = activePage === totalPages ? count : beginning + rowsPerPage - 1

  const [modalState, setModalState] = useState(false)

  useEffect(() => {
    data.length === 1 && setSelectItem(data[0])
  }, [data])

  const handleCloseModal = () => setModalState(false)

  const handelSelecBens = (e: any) => {
    setSelectItem(e)
  }

  return (
    <div className={`card ${className}`}>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <div className=''>
                  <div className='row pb-0'>
                    {tituloMenu === 'descargahacienda' ? null : (
                      <div className='col-lg-1'>
                        <th>
                          <div className='d-flex justify-content-start flex-column'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              value='1'
                              data-kt-check='true'
                              data-kt-check-target='.widget-9-check'
                            />
                          </div>
                        </th>
                      </div>
                    )}
                    <div className='col-1'>
                      <th className='fw-bolder text-dark'>{headColumns?.col2}</th>
                    </div>
                    <div className='col-2'>
                      <th className='fw-bolder text-dark'>{headColumns?.col3}</th>
                    </div>
                    <div className='col-2'>
                      <th className='fw-bolder text-dark'>{headColumns?.col4}</th>
                    </div>
                    <div className='col-2'>
                      <th className='fw-bolder text-dark'>{headColumns?.col5}</th>
                    </div>
                    <div className='col-3 justify-content-end '>
                      {selectItem.length === 0 ? (
                        <th className='fw-bolder text-dark'> Estado</th>
                      ) : (
                        <th className='fw-bolder text-dark'>Acciones</th>
                      )}
                    </div>
                  </div>
                </div>
              </tr>
            </thead>
            {selectItem ? (
              <tbody>
                <>
                  <tr>
                    <div className='row py-0'>
                      <div className='col-1 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              data-kt-check='true'
                              checked
                              data-kt-check-target='.widget-9-check'
                              onChange={(e) => {
                                handelSelecBens('')
                              }}
                            />
                          </div>
                        </td>
                      </div>
                      <div className='col-1 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <a className='text-dark text-hover-primary fs-6'>
                              {selectItem.id_tramite}
                            </a>
                          </div>
                        </td>
                      </div>
                      <div className='col-2 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <a className='text-dark text-hover-primary fs-6'>{selectItem.dni}</a>
                          </div>
                        </td>
                      </div>
                      <div className='col-2 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <a className='text-dark text-hover-primary fs-6'>
                              {selectItem.apellido}
                            </a>
                          </div>
                        </td>
                      </div>
                      <div className='col-2 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <a className='text-dark text-hover-primary fs-6'>{selectItem.nombre}</a>
                          </div>
                        </td>
                      </div>
                      <div className='col-3 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-end flex-column'>
                            <ButtonActionBens
                              objet={selectItem}
                              tituloMenu={tituloMenu}
                              setModalState={setModalState}
                              tituloTabla='ID'
                            />
                          </div>
                        </td>
                      </div>
                    </div>
                  </tr>
                  <tr>
                    <td style={{paddingBottom: 0, paddingTop: 5}}>
                      <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box sx={{margin: 1}} className='no-margin-top'>
                          <span className='fw-bolder fs-3 mb-5'>Datos Inscripcion:</span>
                          <div className='mt-5 fv-row'>
                            <div className='row'>
                              <div className='col-lg-4'>
                                <label className='form-label m-0'>
                                  Telefono:&nbsp;&nbsp;{selectItem.telefono}
                                </label>
                              </div>
                              <div className='col-lg-8'>
                                <label className='form-label m-0'>
                                  Empresa:&nbsp;&nbsp;{selectItem.transporte}
                                </label>
                              </div>
                            </div>
                            <hr />
                            <div className='row'>
                              <div className='col-lg-12'>
                                <label className='form-label m-0'>
                                  Domicilio Legal:&nbsp;&nbsp;{selectItem.domicilio}
                                </label>
                              </div>
                            </div>
                            <hr />
                            <div className='row'>
                              <div className='col-lg-12'>
                                <label className='form-label m-0'>
                                  Domicilio Actual:&nbsp;&nbsp;{selectItem.domicilioActual}
                                </label>
                              </div>
                            </div>
                            <hr />
                            <div className='row'>
                              <div className='col-lg-12'>
                                <label className='form-label m-0'>
                                  Correo:&nbsp;&nbsp;{selectItem.correo}
                                </label>
                              </div>
                            </div>
                            <hr />
                            <div className='row'>
                              <div className='col-lg-12 mb-1'>
                                <label className='form-label m-0'>
                                  Fecha Inscripcion:&nbsp;&nbsp;{selectItem.fecha_inscripcion}
                                </label>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </Collapse>
                    </td>
                  </tr>
                </>
              </tbody>
            ) : (
              <tbody>
                {calculatedRows?.map((value: any) => (
                  <tr key={value._id}>
                    <div className='row py-0'>
                      <div className='col-1 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              data-kt-check='true'
                              data-kt-check-target='.widget-9-check'
                              onChange={(e) => {
                                handelSelecBens(value)
                              }}
                            />
                          </div>
                        </td>
                      </div>
                      <div className='col-1 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <a className='text-dark text-hover-primary fs-6'>{value.id_tramite}</a>
                          </div>
                        </td>
                      </div>
                      <div className='col-2 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <a className='text-dark text-hover-primary fs-6'>{value.dni}</a>
                          </div>
                        </td>
                      </div>
                      <div className='col-2 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <a className='text-dark text-hover-primary fs-6'>{value.apellido}</a>
                          </div>
                        </td>
                      </div>
                      <div className='col-2 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <a className='text-dark text-hover-primary fs-6'>{value.nombre}</a>
                          </div>
                        </td>
                      </div>
                      <div className='col-3 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            {value.estado === 'validado' && (
                              <a className='badge badge-light-primary'>{value.estado}</a>
                            )}
                            {value.estado === 'aprobado' && (
                              <a className='badge badge-light-success'>{value.estado}</a>
                            )}
                            {value.estado === 'pendiente' && (
                              <a className='badge badge-light-warning'>{value.estado}</a>
                            )}
                            {value.estado === 'rechazado' && (
                              <a className='badge badge-light-danger'>{value.estado}</a>
                            )}
                            {value.estado === 'transferido' && (
                              <a className='badge badge-light-info'>{value.estado}</a>
                            )}
                          </div>
                        </td>
                      </div>
                    </div>
                  </tr>
                ))}
              </tbody>
            )}
          </table>

          {/* begin::Table head */}
        </div>
        {selectItem ? null : (
          <div className='row pag-cen-tabla'>
            <div className='mt-5 col-2'></div>
            <ul className='pagination mt-3 col-8'>
              <li className='page-item disabled'>
                <button
                  className='btn btn-sm btn-light-primary'
                  disabled={activePage === 1}
                  onClick={() => setActivePage(1)}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-3' />{' '}
                  Primero
                </button>
              </li>
              <li className='page-item '>
                <button
                  className='btn btn-sm btn-light-primary'
                  disabled={activePage === 1}
                  onClick={() => setActivePage(activePage - 1)}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr022.svg' className='svg-icon-3' />
                  Anterior
                </button>
              </li>
              <li className='page-item '>
                <button
                  className='btn btn-sm btn-light-primary'
                  disabled={activePage === totalPages}
                  onClick={() => setActivePage(activePage + 1)}
                >
                  Siguiente{' '}
                  <KTSVG path='/media/icons/duotune/arrows/arr071.svg' className='svg-icon-3' />
                </button>
              </li>

              <button
                className='btn btn-sm btn-light-primary'
                disabled={activePage === totalPages}
                onClick={() => setActivePage(totalPages)}
              >
                Último{' '}
                <KTSVG path='/media/icons/duotune/arrows/arr080.svg' className='svg-icon-3' />
              </button>
            </ul>
            <div className='mt-5 col-2'>
              <span>Página {activePage} </span>
              de <span>{totalPages}</span>
            </div>
          </div>
        )}
      </div>
      <ModalBens
        data={selectItem}
        show={modalState}
        handleCloseModal={handleCloseModal}
        tituloMenu={tituloMenu}
      />
    </div>
  )
}
