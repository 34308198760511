/** React */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
/** Formik */
import { ErrorMessage, Field, Form, Formik } from 'formik'
import FormikControl from '../../../../components/Formik/FormikControl'
/** Material - Sass - Swal */
import { Checkbox, styled } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'

import '../../../../../../src/_metronic/assets/sass/cud.scss'
/** Services  */
import { getLegajoDni, getTramiteDni, postTramite } from '../services/cud-service'

/** Helepers & Tables */
import Datos_Personales from '../components/Tablas_Tramites/Datos_Personales'
import { initTramitesN } from '../helpers/inits'
/** Hooks Swal */
import { CreateTramite, RejectTramite } from '../hooks/useModal'
/** Moment */
import moment from 'moment'
import { ISTATE } from '../../../../../setup/redux/interface/reduxState.interface'
/** Tables */
import Swal from 'sweetalert2'
import Datos_Tutor from '../components/Tablas_Tramites/Datos_Tutor'
// /Tooltop Imports/
import CancelIcon from '@mui/icons-material/Cancel'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { color } from '@mui/system'
import TextErrorFormik from '../../../../components/Formik/TextErrorFormik'
import { wsPersona } from '../../../../components/wsPersona'
import { validateSchemaDatosPersonales, validateSchemaDatosTutor } from '../Interfaces/Itramite'

import { ModalBusqueda } from '../components/Modal/ModalBusqueda'

export const Tramites = () => {
  const [buscador, setBuscador] = useState<any>()
  const [buscadort, setBuscadort] = useState<any>()
  //is checke is falseq
  const [isChecked, setIsChecked] = useState(false)
  //
  const [formsecu, setFormsecu] = useState<any>(initTramitesN)
  const [formCompleto, setFormCompleto] = useState<any>(initTramitesN)
  const [confirmar, setConfirmar] = useState(false)
  const [opcionConfirma, setOpcionConfirma] = useState(true)
  const [disableConfirma, setDisableConfirma] = useState(false)
  const [btnDisable, setBtnDisable] = useState(true)
  const [confirmart, setConfirmart] = useState(false)
  const [opcionConfirmat, setOpcionConfirmat] = useState(true)
  const [disableConfirmat, setDisableConfirmat] = useState(false)

  const [disableBuscat, setDisableBuscat] = useState(false)
  const [btnActualizar, setBtnActualizar] = useState(false)
  const [btnBuscarOtra, setBbuscarOtra] = useState(false)
  const [btnConfirmar, setBtnConfirmar] = useState(false)
  const [btnEditar, setBtnEditar] = useState(false)
  const [disableTextC, setDisableTextC] = useState(true)
  const [disableTextP, setDisableTextP] = useState(false)
  const [formatoDisable, setFormatoDisable] = useState('card bg-ligth border mb-6 card_align5')
  const [formatoOpacity, setFormatoOpacity] = useState('')
  const [btnActualizart, setBtnActualizart] = useState(false)
  const [btnBuscarOtrat, setBbuscarOtrat] = useState(false)
  const [btnConfirmart, setBtnConfirmart] = useState(false)
  const [btnEditart, setBtnEditart] = useState(false)
  const [disableTextCt, setDisableTextCt] = useState(true)
  const [disableTextPt, setDisableTextPt] = useState(false)
  const [btnSwitch, setBtnSwitch] = useState(false)
  const [miHidden, setMiHidden] = useState(true)
  const [formatoDisablet, setFormatoDisablet] = useState('card bg-ligth border mb-6 card_align5')
  const [formatoOpacityt, setFormatoOpacityt] = useState('')
  const [alertaMayor, setAlertaMayor] = useState(false)
  const [valueVehiculo, setValueVehiculo] = useState('')
  const [valueSimbolo, setValueSimbolo] = useState('')
  const [valueDerecho, setValueDerecho] = useState('')

  const [spinnerb, setSpinnerb] = useState(false)
  const [spinnerbt, setSpinnerbt] = useState(false)
  const [show, setShow] = useState(false)
  const [msjes, setMsjes] = useState('')
  const [footerModal, setFooterModal] = useState(false)

  const handleCloseModal = () => setShow(false)
  const dashState: any = useSelector((state: ISTATE) => state.auth)
  let history = useHistory()
  let nacimiento: any
  let hoy1: any = moment().format('DD/MM/YYYY')
  let hoy2 = moment(hoy1, 'DD/MM/YYYY')
  let anios: any
  let boton: boolean = true
  let botonMenor: boolean = false
  let botonMayor: boolean = false
  let boton2: boolean = true

  const miCheck = (values: any) => {
    setIsChecked(!isChecked)
  }
  const msjModal = (estado: any) => {
    switch (estado) {
      case 0:
        setShow(true)
        setFooterModal(false)
        setMsjes('CONECTANDO a los Servicios de integrabilidad de OPTIC')
        setTimeout(() => {
          setShow(false)
        }, 2000)

        break

      case 1:
        setShow(true)
        setFooterModal(true)
        setMsjes(
          'El servicio de Integrabilidad de OPTIC: FUERA DE SERVICIO. La búsqueda de información se hará en un PADRON actualizado de la Provincia del Neuquén.'
        )
        setTimeout(() => {
          setShow(false)
        }, 4000)
    }
  }

  const checkNeuquen: any = (dpersonal: any) => {
    if (dpersonal.toLowerCase() !== 'neuquen') {
      return Swal.fire({
        title: 'Atención!',
        text: 'La persona solicitada NO tiene residencia en la Provincia del Neuquén. Por favor verificar los datos en DNI si desea continuar.',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Continuar',
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          return true
        }
        return false
      })
    } else {
      return true
    }
  }

  /*Tooltip */
  const BootstrapTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} arrow classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
      backgroundColor: `${color}`,
      color: `#919A9B`,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${color}`,
      color: `#FFFFFF`,
      boxShadow: theme.shadows[2],
      fontSize: 11,
      fontWeightBold: 700,
      disabled: false,
    },
  }))

  const eliminaTutor: any = () => {
    setFormsecu({...formsecu, datosTutor: initTramitesN.datosTutor})
    setIsChecked(false)
  }

  const mostrar = (
    dni_buscado: string,
    genero_buscado: string,
    tipod: string,
    tipoNacion: string
  ) => {
    getTramiteDni(dni_buscado).then((res: any) => {
      if (res.data.ok) {
        Swal.fire({
          title: 'Atención!',
          text: 'Ya existe un trámite en curso con ese DNI (Sin legajo aún)',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ver Trámite',
          cancelButtonText: 'Cerrar',
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(res.data.data);
            const location = {
              pathname: '/cud/MesaEntrada',
              state: {
                dni: res.data.data.dni,
                activado: true,
                obj: res.data.data,
              },
            }
            history.push(location)
          }
        })
        setSpinnerb(false)
      } else {
        getLegajoDni(dni_buscado).then(async (resp: any) => {
          if (resp.data.ok) {
            Swal.fire({
              title: 'Atención!',
              text: 'Ya existe un LEGAJO con ese DNI.',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ver Legajo',
              cancelButtonText: 'Cerrar',
            }).then((result) => {
              if (result.isConfirmed) {
                const locationl = {
                  pathname: '/cud/Legajos',
                  state: {
                    dni: resp.data.mi_legajo.dni,
                    activado: true,
                    obj: resp.data.mi_legajo,
                  },
                }
                history.push(locationl)
              }
            })
            setSpinnerb(false)
          } else {
            setSpinnerb(true)

            let data = await wsPersona(dni_buscado, genero_buscado, tipod, tipoNacion)

            if (data === 'Verificar los datos ingresados') {
              if (tipoNacion === 'extranjero') {
                // setTimeout(() => { setShow(false) }, 2000)

                Swal.fire({
                  title: 'No existen datos de la persona extranjera solicitada',
                  text: 'Por favor ingrese los datos manualmente',
                  icon: 'warning',
                })
                setDisableTextC(false)
                setDisableTextP(true)
                setBtnActualizar(true)
                setBbuscarOtra(true)
                setBtnConfirmar(false)
                setFormatoDisable('card bg-ligth border mb-6 card_align5')
                setFormatoOpacity('')
                setDisableConfirma(false)
              } else {
                setSpinnerb(false)
                Swal.fire({
                  title: 'Problemas al buscar la información',
                  text: 'Verificar los datos ingresados',
                  icon: 'warning',
                })
              }
              setSpinnerb(false)
            } else {
              let provCheck: any = await checkNeuquen(data.datapersonal.provincia)

              if (provCheck) {
                if (data.datapersonal.cuit === '') {
                  msjModal(1)
                } else {
                  msjModal(0)
                }

                setTimeout(() => {
                  setFormsecu({...formsecu, datosPersonales: data.datapersonal})
                }, 2000)
                setDisableTextC(false)
                setDisableTextP(true)
                setBtnActualizar(true)
                setBbuscarOtra(true)
                setBtnConfirmar(false)
                setFormatoDisable('card bg-ligth border mb-6 card_align5')
                setFormatoOpacity('')
                setDisableConfirma(false)
                setSpinnerb(false)
              }
            }
          }
        })
      }
    })
  }

  const mostrar2 = async (
    dni_tutor: string,
    genero_tutor: string,
    tipod_tutor: any,
    values: any
  ) => {
    setSpinnerbt(true)
    let dataTutor = await wsPersona(dni_tutor, genero_tutor, tipod_tutor)

    if (dataTutor != 'Verificar los datos ingresados') {
      if (values.datosPersonales.dni === dataTutor.datapersonal.dni) {
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: 'El Tutor no puede ser la misma persona que el la solicitante',
        })

        setSpinnerbt(false)
        setTimeout(() => {
          setShow(false)
        }, 2000)
      } else {
        if (dataTutor === 'Verificar los datos ingresados') {
          Swal.fire({
            title: 'Problemas al buscar la información',
            text: 'Verificar los datos ingresados',
            icon: 'warning',
          })
          setSpinnerbt(false)
          setTimeout(() => {
            setShow(false)
          }, 2000)
        } else {
          if (dataTutor.datapersonal.cuit === '') {
            msjModal(1)
          } else {
            msjModal(0)
          }
          // setDisableBuscat(true)
          setDisableTextCt(false)
          //setDisableTextPt(true)
          //setBtnActualizart(true)
          //setBbuscarOtrat(true)
          setBtnConfirmart(false)
          setBtnEditart(false)
          setFormatoDisablet('card bg-ligth border mb-6 card_align5')
          setFormatoOpacityt('')
          //setDisableConfirmat(false)
          setTimeout(() => {
            setShow(false)
          }, 2000)

          setTimeout(() => {
            setFormsecu({
              ...formsecu,
              datosTutor: dataTutor.datapersonal,
              // datosPersonales: values.datosPersonales,
            })
          }, 2000)

          setSpinnerbt(false)
        }
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'El Tutor no puede ser la misma persona que el la solicitante',
      })
      setSpinnerbt(false)
      setTimeout(() => {
        setShow(false)
      }, 1500)
    }
  }
  function changedateformat(val: any) {
    let myArray = val.split('-')

    let year = myArray[0]
    let month = myArray[1]
    let day = myArray[2]

    let formatteddate = day + '/' + month + '/' + year

    return formatteddate
  }
  const guardarTramiteNew = async (values: any) => {
    values.usuarioCreacion = dashState.usuario.nombreCompleto
    values.fechaCreacion = moment().format('DD/MM/YYYY')
    values.metodo = 'Local'
    values.estado = 'activo'
    values.nacionalidad = 'Argentina'
    values.alertas.alertaMayor = false

    postTramite(values).then((res: any) => {
      if (res.data.ok) {
        CreateTramite()
      } else {
        RejectTramite()
      }
    })
  }

  useEffect(() => {}, [spinnerb])
  useEffect(() => {}, [])
  useEffect(() => {}, [show])

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <h5 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-5 mb-1 capital'>Nuevo Trámite</span>
        </h5>
        <div className='card-toolbar'>
          <BootstrapTooltip title='Cancelar y Volver' arrow followCursor placement='top'>
            <Link to={{pathname: '/cud/MesaEntrada', state: null}} className='alineacionb'>
              <CancelIcon className='error' />
            </Link>
          </BootstrapTooltip>{' '}
        </div>
      </div>

      <div className='card-body py-1'>
        <div className='table-responsive'>
          <Formik
            initialValues={formsecu}
            validateOnMount={true}
            validationSchema={validateSchemaDatosPersonales}
            onSubmit={guardarTramiteNew}
            enableReinitialize
          >
            {(formik) => {
              formik.values.datosPersonales.fechaNacimiento.includes('-') &&
                (formik.values.datosPersonales.fechaNacimiento = changedateformat(
                  formik.values.datosPersonales.fechaNacimiento
                ))

              nacimiento = moment(formik.values.datosPersonales.fechaNacimiento, 'DD/MM/YYYY')
              anios = hoy2.diff(nacimiento, 'year')

              formik.values.datosPersonales.dni !== '' &&
              formik.values.datosPersonales.sexo !== '' &&
              formik.values.datosPersonales.tipoDoc !== '' &&
              formik.values.datosPersonales.tipoNacionalidad !== ''
                ? (boton = false)
                : (boton = true)

              return (
                <div>
                  <Form>
                    <div className=''>
                      <div className='col-8 text-gray-700 fw-bold mt-2 fs-6 '>Datos Personales</div>

                      <div className={formatoDisable}>
                        <div className='row'>
                          <div className='col-lg-2'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              Tipo Documento
                            </label>
                            <Field
                              as='select'
                              id='datosPersonales.tipoDoc'
                              name='datosPersonales.tipoDoc'
                              className='form-control form-control-solid capital'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option value=''>Seleccione</option>
                              <option value='DNI F'>DNI F</option>
                              <option value='DNI M'>DNI M</option>
                              <option value='DNI X'>DNI X</option>
                              <option value='LC'>LC</option>
                              <option value='PAS'>PAS</option>
                              <option value='LE'>LE</option>
                              <option value='CI'>CI</option>
                            </Field>

                            {formik.errors.tipoDoc ? <div>{formik.errors.tipoDoc}</div> : null}

                            <ErrorMessage
                              className='error'
                              component={TextErrorFormik}
                              name='datosPersonales.tipoDoc'
                            />
                          </div>

                          <div className='col-lg-2'>
                            <label className='form-label fw-bolder text-dark fs-6'>Documento</label>

                            <Field
                              control='input'
                              type='number'
                              placeholder='Ingrese documento...'
                              id='datosPersonales.dni'
                              name='datosPersonales.dni'
                              className='form-control form-control-solid capital'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />

                            {formik.errors.dni ? <div>{formik.errors.dni}</div> : null}

                            <ErrorMessage
                              className='error'
                              component={TextErrorFormik}
                              name='datosPersonales.dni'
                            />
                          </div>

                          <div className='col-lg-2'>
                            <label className='form-label fw-bolder text-dark fs-6'>Género</label>

                            <Field
                              as='select'
                              id='datosPersonales.sexo'
                              name='datosPersonales.sexo'
                              className='form-control form-control-solid capital'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option value=''>Seleccione</option>
                              <option value='m'>Masculino</option>
                              <option value='f'>Femenino</option>
                              <option value='x'>Otro</option>
                            </Field>

                            {formik.errors.sexo ? <div>{formik.errors.sexo}</div> : null}

                            <ErrorMessage
                              className='error'
                              component={TextErrorFormik}
                              name='datosPersonales.sexo'
                            />
                          </div>
                          <div className='col-lg-2'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              Tipo Nacionalidad
                            </label>

                            <Field
                              as='select'
                              id='datosPersonales.tipoNacionalidad'
                              name='datosPersonales.tipoNacionalidad'
                              className='form-control form-control-solid capital'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option value=''>Seleccione</option>
                              <option value='nativo'>nativo</option>
                              <option value='naturalizado'>naturalizado</option>
                              <option value='extranjero'>extranjero</option>
                            </Field>

                            {formik.errors.tipoNacionalidad ? (
                              <div>{formik.errors.tipoNacionalidad}</div>
                            ) : null}

                            <ErrorMessage
                              className='error'
                              component={TextErrorFormik}
                              name='datosPersonales.tipoNacionalidad'
                            />
                          </div>

                          <div className='col-lg-2'>
                            <button
                              type='button'
                              disabled={boton}
                              className='btn btn-primary btn-sm btn-tramite'
                              onClick={(e: any) => {
                                mostrar(
                                  formik.values.datosPersonales.dni,
                                  formik.values.datosPersonales.sexo,
                                  formik.values.datosPersonales.tipoDoc,
                                  formik.values.datosPersonales.tipoNacionalidad
                                )
                              }}
                            >
                              {spinnerb === true ? (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                  <SearchIcon />
                                  Buscando...
                                  {/* <span className='spinner-border spinner-border-sm align-middle ms-2'></span> */}
                                </span>
                              ) : (
                                <>
                                  <SearchIcon />
                                  Buscar
                                </>
                              )}
                            </button>
                          </div>
                          <div className='col-lg-2 tamano_footer'>
                            <b>Fuente de Información</b>
                            <br></br>
                            {formik.values.datosPersonales.apellido !== ''
                              ? formik.values.datosPersonales.cuit !== ''
                                ? 'Servicio de Integrabilidad de OPTIC'
                                : 'Padron Provincia del Neuquén'
                              : '-----'}
                          </div>

                          {
                            <Datos_Personales
                              handleChange={formik.handleChange}
                              handleBlur={formik.handleBlur}
                              formsecu={formsecu}
                              setFormsecu={setFormsecu}
                              datosPersonales={buscador}
                              formik={formik}
                              valueVehiculo={valueVehiculo}
                              valueSimbolo={valueSimbolo}
                              valueDerecho={valueDerecho}
                              setConfirmar={setConfirmar}
                              formatoDisable={formatoDisable}
                              setFormatoDisable={setFormatoDisable}
                              opcionConfirma={opcionConfirma}
                              setOpcionConfirma={setOpcionConfirma}
                              disableConfirma={disableConfirma}
                              setDisableConfirma={setDisableConfirma}
                              formatoOpacity={formatoOpacity}
                              setFormatoOpacity={setFormatoOpacity}
                              btnConfirmar={btnConfirmar}
                              setBtnConfirmar={setBtnConfirmar}
                              btnEditar={btnEditar}
                              setBtnEditar={setBtnEditar}
                              disableTextC={disableTextC}
                              setDisableTextC={setDisableTextC}
                              btnDisable={btnDisable}
                              setBtnDisable={setBtnDisable}
                              btnSwitch={btnSwitch}
                              setBtnSwitch={setBtnSwitch}
                              miHidden={miHidden}
                              alertaMayor={alertaMayor}
                              setAlertaMayor={setAlertaMayor}
                            />
                          }
                        </div>
                      </div>
                      {anios <= 16 ? (
                        <h5 className=''>
                          La persona solicitante menor de 16 años debe agregar un TUTOR.
                        </h5>
                      ) : null}
                      {disableConfirma ? (
                        <label className='check_label_posicion2 check_tutor2'>AGREGAR TUTOR</label>
                      ) : null}

                      {disableConfirma ? (
                        <Field
                          type='checkbox'
                          as={Checkbox}
                          id='tutor'
                          name='tutor'
                          className='check_label_posicion2 check_tutor2'
                          onChange={formik.handleChange}
                          onClick={() => {
                            miCheck(formik.values)
                          }}
                          onBlur={formik.handleBlur}
                          checked={isChecked}
                        />
                      ) : null}
                    </div>

                    {formik.validateOnMount && formik.isValid && anios <= 16
                      ? ((botonMayor = true), (botonMenor = true))
                      : ((botonMayor = false), (botonMenor = false))}

                    <div className='row'>
                      <div className='col-lg-6'>
                        <button
                          type='submit'
                          disabled={botonMayor}
                          hidden={isChecked}
                          className='btn btn-primary btn-sm'
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              )
            }}
          </Formik>

          <Formik
            initialValues={formsecu}
            validationSchema={validateSchemaDatosTutor}
            validateOnMount={true}
            onSubmit={guardarTramiteNew}
            enableReinitialize
          >
            {(formik) => {
              formik.values.datosTutor.fechaNacimiento.includes('-') &&
                (formik.values.datosTutor.fechaNacimiento = changedateformat(
                  formik.values.datosTutor.fechaNacimiento
                ))
              return (
                <div>
                  <Form>
                    <div className=''>
                      {isChecked ? (
                        <>
                          <div className='{formatoDisablet} col-8 text-gray-700 fw-bold mt-2 fs-6'>
                            Datos Tutor
                          </div>

                          <div className={formatoDisablet}>
                            <div className='row'>
                              <div className='col-lg-2'>
                                <label className='form-label fw-bolder text-dark fs-6'>
                                  Tipo Doc
                                </label>
                                <Field
                                  as='select'
                                  id='datosTutor.tipoDoc'
                                  name='datosTutor.tipoDoc'
                                  className='form-control form-control-solid capital'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                >
                                  <option value=''>Seleccione</option>
                                  <option value='DNI F'>DNI F</option>
                                  <option value='DNI M'>DNI M</option>
                                  <option value='DNI X'>DNI X</option>
                                  <option value='LC'>LC</option>
                                  <option value='PAS'>PAS</option>
                                  <option value='LE'>LE</option>
                                  <option value='CI'>CI</option>
                                </Field>
                                {formik.errors.tipoDoc ? <div>{formik.errors.tipoDoc}</div> : null}

                                <ErrorMessage
                                  className='error'
                                  component={TextErrorFormik}
                                  name='datosTutor.tipoDoc'
                                />
                              </div>

                              <div className='col-lg-2'>
                                <label className='form-label fw-bolder text-dark fs-6'>DNI</label>

                                <Field
                                  control='input'
                                  type='number'
                                  id='datosTutor.dni'
                                  name='datosTutor.dni'
                                  placeholder='Ingrese DNI Tutor...'
                                  className='form-control form-control-solid capital'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.errors.dni ? <div>{formik.errors.dni}</div> : null}

                                <ErrorMessage
                                  className='error'
                                  component={TextErrorFormik}
                                  name='datosTutor.dni'
                                />
                              </div>
                              <div className='col-lg-2'>
                                <label className='form-label fw-bolder text-dark fs-6'>
                                  Género
                                </label>
                                <Field
                                  as='select'
                                  id='datosTutor.sexo'
                                  name='datosTutor.sexo'
                                  className='form-control form-control-solid capital'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                >
                                  <option value=''>Seleccione</option>
                                  <option value='m'>Masculino</option>
                                  <option value='f'>Femenino</option>
                                  <option value='x'>Otro</option>
                                </Field>
                                {formik.errors.sexo ? <div>{formik.errors.sexo}</div> : null}

                                <ErrorMessage
                                  className='error'
                                  component={TextErrorFormik}
                                  name='datosTutor.sexo'
                                />
                              </div>

                              {formik.values.datosTutor.dni !== '' &&
                              formik.values.datosTutor.sexo !== '' &&
                              formik.values.datosTutor.tipoDoc !== ''
                                ? (boton2 = false)
                                : (boton2 = true)}

                              <div className='col-lg-2'>
                                <button
                                  type='button'
                                  className='btn btn-primary btn-sm btn-tramite'
                                  // hidden={disableBuscat}
                                  disabled={boton2}
                                  onClick={(e: any) => {
                                    mostrar2(
                                      formik.values.datosTutor.dni,
                                      formik.values.datosTutor.sexo,
                                      formik.values.datosTutor.tipoDoc,
                                      formik.values
                                    )
                                  }}
                                >
                                  {spinnerbt === true ? (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                      <SearchIcon />
                                      Buscando...
                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                  ) : (
                                    <>
                                      <SearchIcon />
                                      Buscar
                                    </>
                                  )}
                                </button>
                              </div>
                              <div className='col-lg-2 tamano_footer'>
                                <b>Fuente de Información</b>
                                <br></br>
                                {formik.values.datosTutor.apellido !== ''
                                  ? formik.values.datosTutor.cuit !== ''
                                    ? 'Servicio de Integrabilidad de OPTIC'
                                    : 'Padron Provincia del Neuquén'
                                  : '-----'}
                              </div>

                              <div className='col-lg-2'>
                                <button
                                  type='reset'
                                  className='btn btn-danger btn-sm btn-tramite btn-salir'
                                  onClick={() => {
                                    eliminaTutor()
                                  }}
                                >
                                  Eliminar Tutor
                                </button>
                              </div>
                            </div>
                            <Datos_Tutor
                              handleChange={formik.handleChange}
                              handleBlur={formik.handleBlur}
                              datosTutor={buscadort}
                              formik={formik}
                              formSecu={formsecu}
                              setFormSecu={setFormsecu}
                              Check={isChecked}
                              menor={botonMenor}
                              confirmart={confirmart}
                              setConfirmart={setConfirmart}
                              setFormatoDisablet={setFormatoDisablet}
                              opcionConfirmat={opcionConfirmat}
                              setOpcionConfirmat={setOpcionConfirmat}
                              setDisableConfirmat={setDisableConfirmat}
                              formatoOpacityt={formatoOpacityt}
                              setFormatoOpacityt={setFormatoOpacityt}
                              btnConfirmart={btnConfirmart}
                              setBtnConfirmart={setBtnConfirmart}
                              btnEditart={btnEditart}
                              setBtnEditart={setBtnEditart}
                              disableTextCt={disableTextCt}
                              setDisableTextCt={setDisableTextCt}
                              btnSwitch={btnSwitch}
                              setBtnSwitch={setBtnSwitch}
                              anios={anios}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <button
                          type='submit'
                          hidden={!isChecked}
                          className='btn btn-primary btn-sm'
                          disabled={btnSwitch}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                    <FormikControl
                      control='input'
                      type='hidden'
                      id='fecha'
                      name='fecha'
                      className='input_hidden'
                      value={moment().format('DD/MM/YYYY')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <Field
                      type='checkbox'
                      id='activo'
                      name='activo'
                      value='true'
                      className='input_hidden'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />

                    <Field
                      type='text'
                      id='metodo'
                      name='metodo'
                      value='local'
                      className='input_hidden'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <ModalBusqueda
                      msjes={msjes}
                      show={show}
                      footer={footerModal}
                      handleCloseModal={handleCloseModal}
                    />
                  </Form>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}
export default Tramites