import {request} from '../../../../../setup/axios/axios.util'
import {IEmpleados} from '../interfaces/IEmpleados'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const URL = `${API_URL}/modules/ej`
//TODO:EndPoint para los datos analiticos del Menu headers
export const analitcaMenuHeaderEmpresa = (cuit: string) => {
  return request().get(`${URL}/analiticaEmp?cuit=${cuit}`)
}
export const analitcaAllEmpleados = () => {
  return request().get(`${URL}/analiticaAllEmp`)
}

//TODO:End Point para buscar solo los empleados de una empresa
export const getAllEmpleadosOneEmpresa = (cuit: string) => {
  return request().get(`${URL}/empleadosEmp?cuit=${cuit}`)
}
export const getEmpleadosOneEmpresa = (cuit: string, documento: string) => {
  return request().get(`${URL}/empleadoByEmpresa?cuit=${cuit}&documento=${documento}`)
}

//TODO:End Point obtener todos los empleados registrados

export const getAllEmpleados = (cuit: string) => {
  return request().get(`${URL}/empleados`)
}

//TODO:Cargar un nuevo Empleado
export const altaEmpleado = (empleado: IEmpleados) => {
  let data = {
    data: empleado,
  }

  return request().post(`${URL}/newEmp`, data)
}
//TODO:SubirDoc

export const uploadFileEmp = (
  reciboSueldo: any,

  cerCapc: any,
  formulario: any,
  empleado: string
) => {
  let formData = new FormData()
  formData.append('reciboSueldo', reciboSueldo)
  formData.append('cerCapc', cerCapc)
  formData.append('mes', formulario.mes)
  formData.append('empleado', empleado)

  return request().post(`${URL}/upfileEmp`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const upLoadFileFormEmpleado = (
  empleado: string,
  cuit: string,
  altaAfip: any,
  progSocial: any,
  histAnses: any,
  ddjjEmp: any
) => {
  let formData = new FormData()
  formData.append('empleado', empleado)
  formData.append('empresa', cuit)
  formData.append('altaAfip', altaAfip)
  formData.append('progSocial', progSocial)
  formData.append('histAnses', histAnses)
  formData.append('ddjjEmp', ddjjEmp)

  return request().post(`${URL}/up3files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

//TODO:guardar info Doc en el empleado
export const saveDocEmp = (documentos: [], documento: string, mes: string, anio: string) => {
  let data: {} = {
    documento,
    mes,
    dataDoc: documentos,
    anio,
  }
  return request().post(`${URL}/saveDocEmp`, data)
}

//TODO VER DOC X MES

export const getPdfDoc = (cuit: string, dni: string, mes: string, doc: string) => {
  let data = {
    empleado: dni,
    cuit: cuit,
    archivo: doc,
    mes: mes,
  }
  return request().post(`${URL}/getPdfDoc`, data, {responseType: 'blob'})
}

//TODO:EndPoint para enviar certificados aprobados por Cpyme

export const postCertCpyme = (archivo: any, tipoArchivo: string, cuit: string) => {
  let formData = new FormData()
  formData.append(`${tipoArchivo}`, archivo)
  formData.append('tipoArchivo', tipoArchivo)
  formData.append('cuit', cuit)

  return request().post(`${URL}/certCpyme`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

//TODO: EndPoint para agregar los datos del cert en el anio y mes de la empresa

export const addCertPyme = (
  cuit: string,
  anio: string,
  mes: string,
  formData: any,
  documentos: any
) => {
  let data: any = {
    cuit,
    anio,
    mes,
    importe: formData[0],
    fecha_alta: formData[1],
    resolucion: formData[2],
    documentos,
  }
  return request().post(`${URL}/addCertCpyme`, data)
}
