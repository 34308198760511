import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { PageTitle } from '../../../../../../_metronic/layout/core/PageData'
import moment from 'moment'
import 'moment/locale/es'
import { evSetFormulario } from '../../Redux/actions/eventos'
import { ISTATE } from '../../../../../../setup/redux/interface/reduxState.interface'
import { DatosEvento } from './components/DatosEvento'
import { DuracionEvento } from './components/DuracionEvento'
import { UbicacionEvento } from './components/UbicacionEvento'
import { UsuariosEvento } from './components/UsuariosEvento'
import { ConclusionEvento } from './components/ConclusiónEvento'
import HeaderVerEditarEvento from '../../components/Headers/HeaderVerEditarEvento'
import { eventoSchemas, IEventos } from '../CrearEventoPage/Steps/EventoData'
moment.locale('es')

const initHoraDia = {
  lunIni: '',
  lunFin: '',
  marIni: '',
  marFin: '',
  mieIni: '',
  mieFin: '',
  jueIni: '',
  jueFin: '',
  vieIni: '',
  vieFin: '',
  sabIni: '',
  sabFin: '',
  domIni: '',
  domFin: '',
}
const initHabilitar = {
  lun: false,
  mar: false,
  mie: false,
  jue: false,
  vie: false,
  sab: false,
  dom: false,
}
const initCheck = {
  lun: false,
  mar: false,
  mie: false,
  jue: false,
  vie: false,
  sab: false,
  dom: false,
}

const EventosPage: React.FC = () => {
  const dispatch = useDispatch()
  const location: any = useLocation()
  let { eventoSel } = location.state
  const estado = useEffect
  let { titulo } = location.state
  const [currentSchema, setCurrentSchema] = useState(eventoSchemas[0])
  const eventosStore: any = useSelector((state: ISTATE) => state.eventos)
  const { eventoSeleccionado, formulario } = eventosStore
  let eventoTemp: any
  const [valido, setValido] = useState(false)
  const [evento, setEvento] = useState<IEventos>(eventoSel)

  ////console.log('EventosPage evento de formulario ------------------->', eventoSeleccionado)



  useEffect(() => {
    //////console.log('acciones', valido)
  }, [valido])

  const submitForm = async (values: IEventos, actions: FormikValues) => { }

  return (
    <div className={'card'}>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <HeaderVerEditarEvento titulo={titulo} data={valido} evento={eventoSel} valido={valido} />

      <div className='card-body pt-3'>
        <div className='w-100'>
          <Formik
            validationSchema={currentSchema}
            initialValues={formulario}
            onSubmit={submitForm}
            enableReinitialize
          >
            {(formik) => {
              estado(() => {
                //console.log('formulario', formulario)
                if (formik.isValid) {
                  setValido(true)
                  //console.log('formik.isValid', formik.isValid)
                } else {
                  //console.log('formik.isValid', formik.isValid)
                  setValido(false)
                }
                //  ////console.log(dataFc)
                setEvento(formik.values)
                ////console.log('Velores del Formulario: ', formik)
                dispatch(evSetFormulario(formik.values))
              }, [formik.isValid, formik.values])

              return (
                <Form
                  className='mx-auto mw-1000px w-100 pt-8 pb-2'
                  noValidate
                  id='kt_create_account_form'
                >
                  <div className='card'>
                    <DatosEvento />
                  </div>
                  <div className='card'>
                    <DuracionEvento data={formik.values} />
                  </div>
                  <div className='card'>
                    <UsuariosEvento data={formik.values} />
                  </div>
                  <div className='card'>
                    <UbicacionEvento data={formik.values} />
                  </div>
                  <div className='card'>
                    <ConclusionEvento data={formik.values} />
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default EventosPage
