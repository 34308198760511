import React, {useEffect, useState} from 'react'
import {Field, Form, Formik} from 'formik'
import Spinner from 'react-bootstrap-v5/lib/esm/Spinner'
import * as Yup from 'yup'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useSelector} from 'react-redux'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
import {getOneEmprendedor, getOneEmpresa} from '../services/EJ.Services'
import {IEmpresa} from '../interfaces/Iempresas'
import {IEmprendedor} from '../interfaces/Iemprendedor'
import {CardMisCert} from '../components/CardMisCert/CardMisCert'

export const MisCertificados = () => {
  const [loadSpinner, setLoadSpinner] = useState<Boolean>(false)

  const [miEmpresa, setMiEmpresa] = useState<IEmpresa>()
  const [mostrarCard, setMostrarCard] = useState<boolean>(false)
  const [documentos, setDocumentos] = useState<any>()
  const [certCpyme, setCertCpyme] = useState<any>()
  const [miEmprendimiento, setMiEmprendimiento] = useState<IEmprendedor>()

  const [meses, setMeses] = useState<any>()

  const consultaValidate = Yup.object().shape({
    anio: Yup.string().required('El campo es requerido'),
    mes: Yup.string().required('El campo es requerido'),
  })

  const dashState: any = useSelector((state: ISTATE) => state.auth)
  let {usuario} = dashState

  const filtYearAct = (year: string) => {
    let mesesFilt: any = miEmpresa!.anio!.find((elemt: any) => elemt.anio === year)?.meses
    setMeses(mesesFilt.filter((elemt: any) => elemt.estado === 'aprobado'))
  }
  const filtDocMesSelect = (mes: string) => {
    setTimeout(() => {
      let documentos = meses.filter((data: any) => data.mes === mes)
      // console.log(documentos)
      setCertCpyme(
        documentos[0].documentos.filter((element: any) => element.estado === 'aprobado_cpyme')
      )

      setDocumentos(documentos)
      setLoadSpinner(false)
    }, 200)
  }

  useEffect(() => {
    let isMounted = true

    usuario.organizacion.datosOrganismo.nombre === 'empresas nqn' &&
      getOneEmpresa(usuario.usuario).then((res: any) => {
        if (isMounted) {
          //console.log(res.data.data)
          res.data.ok && setMiEmpresa(res.data.data)
          setMostrarCard(true)
          //  setTipoUsr('empresas')
        }
      })
    usuario.organizacion.datosOrganismo.nombre === 'emprendedor nqn' &&
      getOneEmprendedor(usuario.usuario).then((res: any) => {
        if (isMounted) {
          res.data.ok && setMiEmprendimiento(res.data.data)
          setMostrarCard(true)
          //setTipoUsr('emprendedores')
        }
      })
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      {mostrarCard && (
        <>
          <div className='mb-5 mb-xl-8'>
            <div className='card-header border-0 pt-5'>
              <div className='containerTableTitle'>
                <h3 className='headerTableLefet'>
                  <span className='card-label fw-bolder fs-3 mb-1'>
                    Seleccione el periodo y el mes aprobado por Centro Pyme.
                  </span>
                </h3>
              </div>

              <br />
              <Formik
                initialValues={{
                  anio: '',
                  mes: '',
                }}
                validationSchema={consultaValidate}
                onSubmit={(values: any) => {
                  setLoadSpinner(true)

                  setTimeout(() => {}, 2000)
                }}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div className='row justify-content-md-center'>
                        <div className='col-2'>
                          <Field
                            as='select'
                            id='anio'
                            name='anio'
                            className='form-select form-select-solid capital'
                            onChange={(e: any) => {
                              formik.setFieldValue('anio', e.target.value)
                              filtYearAct(e.target.value)
                            }}
                          >
                            <option value=''>Seleccione el periodo</option>
                            {miEmpresa!.anio!.map((item: any) => {
                              return <option value={item.anio}>{item.anio}</option>
                            })}
                            {formik.errors.anio && formik.touched.anio ? (
                              <div>{formik.errors.anio}</div>
                            ) : null}
                          </Field>
                        </div>

                        {meses && (
                          <div className='col-2'>
                            <Field
                              as='select'
                              id='mes'
                              name='mes'
                              className='form-select form-select-solid capital'
                              onChange={(e: any) => {
                                formik.setFieldValue('mes', e.target.value)
                                filtDocMesSelect(e.target.value)
                                setLoadSpinner(true)
                              }}
                            >
                              <option value=''>Seleccione el Mes</option>
                              {meses.map((item: any) => {
                                return <option value={item.mes}>{item.mes}</option>
                              })}
                              {formik.errors.mes && formik.touched.mes ? (
                                <div>{formik.errors.mes}</div>
                              ) : null}
                            </Field>
                          </div>
                        )}
                      </div>
                      <div className='row justify-content-md-center '>
                        {loadSpinner && (
                          <>
                            <Spinner
                              className='spinner-border m-5'
                              animation='border'
                              role='status'
                              variant='primary'
                            >
                              <span className='visually-hidden'>Loading...</span>
                            </Spinner>
                          </>
                        )}
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
          <br />
          {documentos && (
            <div className='mb-5 mb-xl-8'>
              <div className='card-header border-0 pt-5'>
                <div className='containerTableTitle'>
                  <h3 className='headerTableLefet'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Certificado aprobado.</span>
                  </h3>
                </div>
                <br />

                <div className='row justify-content-md-center'>
                  {documentos?.map((doc: any, index: any) => {
                    // console.log(doc)
                    if (doc.estado === 'aprobado')
                      return (
                        <div key={index} className='col-3 col-sm-3 col-xl'>
                          <CardMisCert
                            estado={doc.estado}
                            importe={doc.certCPyme.importe}
                            fecha_alta={doc.certCPyme.fecha_alta}
                            resolucion={doc.certCPyme.resolucion}
                            downloadPdf={certCpyme}
                          />
                        </div>
                      )
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}
