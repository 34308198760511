import axios from 'axios'
import React from 'react'
import Swal from 'sweetalert2'
import { KTSVG, toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
import { ITablaDatosT } from '../../../Interfaces/TablaDatosT-interface'
import { subirarchivo } from '../../../services/telefonia-service'

export const TablaConsumo: React.FC<ITablaDatosT> = ({
  className,
  tituloMenu,
  botonPrincipal,
  tablaDatos,
  headColumns,
}) => {
  const mi_formData = new FormData()

  const filtrarArchivo = (e: any) => {
    const mi_archivo = e.target.files[0]
    ////console.log(mi_archivo)
    if (e.target && e.target.files[0]) {
      mi_formData.append('file', mi_archivo)
    }
  }

  const SubmitFileData = () => {
    subirarchivo(mi_formData).then((resp: any) => {
      if (resp.data.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Archivo subido correctamente',
          showConfirmButton: false,
          timer: 1500,
        })
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Pasaron cosas...',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    })
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Consumos</span>
        </h3>
        <p></p>

        <div className='card-toolbar'></div>
        <div className='input_carga'>
          <label>
            <input
              className='btn btn-light'
              type='file'
              name='file'
              onChange={(e) => filtrarArchivo(e)}
            />
          </label>
          &nbsp;&nbsp;&nbsp;
          <input
            className='btn btn-primary'
            type='submit'
            onClick={SubmitFileData}
            value='Importar'
          />
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {/* <div>
          <label>
            <input type='file' name='file' onChange={(e) => filtrarArchivo(e)} />
          </label>
          <input type='submit' onClick={SubmitFileData} value='Importar' />
        </div> */}

        <div className='table-responsive'>
          {/* begin::Table */}

          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='min-w-10px'></th>
                <th className='min-w-125px'>Ciclo</th>
                <th className='min-w-125px'>Cant Lineas</th>
                <th className='min-w-200px'>Monto</th>
                <th className='min-w-20px'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <div className='d-flex align-items-center'></div>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}
