import React, {FC} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
interface Iprops {
  total?: number
  apTotal?: number
  rechTotal?: number
  pendTotal?: number
}
export const TotalEmpleadosGral: FC<Iprops> = ({total, apTotal, rechTotal, pendTotal}) => {
  return (
    <>
      <div className='d-flex flex-wrap flex-stack'>
        <div className='d-flex flex-column flex-grow-1 pe-8'>
          <div className='d-flex flex-wrap'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr066.svg'
                  className='svg-icon-3 svg-icon-primary me-2'
                />
                <div className='fs-2 fw-bolder'>{total}</div>
              </div>

              <div className='fw-bold fs-6 text-gray-400'>Empleados Inscriptos</div>
            </div>

            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr066.svg'
                  className='svg-icon-3 svg-icon-success me-2'
                />
                <div className='fs-2 fw-bolder'>{apTotal}</div>
              </div>

              <div className='fw-bold fs-6 text-gray-400'>Empleados Validados</div>
            </div>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 svg-icon-warning me-2'
                />
                <div className='fs-2 fw-bolder'>{pendTotal}</div>
              </div>

              <div className='fw-bold fs-6 text-gray-400'>Empleados Pendientes</div>
            </div>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-3 svg-icon-danger me-2'
                />
                <div className='fs-2 fw-bolder'>{rechTotal}</div>
              </div>

              <div className='fw-bold fs-6 text-gray-400'>Empleados Baja</div>
            </div>
          </div>
        </div>

        {/*  <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
              <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                <span className='fw-bolder fs-6'>50%</span>
              </div>
              <div className='h-5px mx-3 w-100 bg-light mb-3'>
                <div
                  className='bg-success rounded h-5px'
                  role='progressbar'
                  style={{width: '50%'}}
                ></div>
              </div>
            </div> */}
      </div>
    </>
  )
}
