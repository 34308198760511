import React, { ReactNode } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers/components/KTSVG';
import { HeaderIndicador } from '../Menu/Indicadores';

type Props = {
    title: string
    subtitle: string
    indicatorTitles: HeaderIndicador[]
    className: string
    children: ReactNode
}
const HeaderCudIndicadores: React.FC<Props> = ({ title, subtitle, indicatorTitles, className, children }) => {
    return (
        <>
            <div className={`card ${className}`}>
                {/* begin::Beader */}
                <div className='card-header border-0 py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>

                        <span className='text-muted fw-bold fs-7'>
                            {subtitle}
                        </span>
                    </h3>
                </div>
                {/* end::Header */}
                {/* begin::Stats */}
                <div className='card-px pt-0 pb-10 flex-grow-1'>
                    {/* begin::Row */}
                    <div className='row g-0 mt-5 mb-10'>
                        {/* begin::Col */}
                        <div className='col'>
                            <div className='d-flex align-items-center me-2'>
                                {/* begin::Symbol */}
                                <div className='symbol symbol-50px me-3'>
                                    <div className='symbol-label bg-light-info'>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen002.svg'
                                            className='svg-icon-1 svg-icon-info'
                                        />
                                    </div>
                                </div>
                                {/* end::Symbol */}

                                {/* begin::Title */}
                                <div>
                                    <div className='fs-4 text-dark fw-bolder'>{indicatorTitles[0].valor}</div>
                                    <div className='fs-7 text-muted fw-bold'>{indicatorTitles[0].title}</div>
                                </div>
                                {/* end::Title */}
                            </div>
                        </div>
                        {/* end::Col */}

                        {/* begin::Col */}
                        <div className='col'>
                            <div className='d-flex align-items-center me-2'>
                                {/* begin::Symbol */}
                                <div className='symbol symbol-50px me-3'>
                                    <div className='symbol-label  bg-light-success'>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen012.svg'
                                            className='svg-icon-1 svg-icon-success'
                                        />
                                    </div>
                                </div>
                                {/* end::Symbol */}

                                {/* begin::Title */}
                                <div>
                                    <div className='fs-4 text-dark fw-bolder'>{indicatorTitles[1].valor}</div>
                                    <div className='fs-7 text-muted fw-bold'>{indicatorTitles[1].title}</div>
                                </div>
                                {/* end::Title */}
                            </div>
                        </div>
                        {/* end::Col */}
                        {/* begin::Col */}
                        <div className='col'>
                            <div className='d-flex align-items-center me-2'>
                                {/* begin::Symbol */}
                                <div className='symbol symbol-50px me-3'>
                                    <div className='symbol-label  bg-light-danger'>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen017.svg'
                                            className='svg-icon-1 svg-icon-danger'
                                        />
                                    </div>
                                </div>
                                {/* end::Symbol */}

                                {/* begin::Title */}
                                <div>
                                    <div className='fs-4 text-dark fw-bolder'>{indicatorTitles[2].valor}</div>
                                    <div className='fs-7 text-muted fw-bold'>{indicatorTitles[2].title}</div>
                                </div>
                                {/* end::Title */}
                            </div>
                        </div>
                        {/* end::Col */}
                        {/* begin::Col */}
                        {indicatorTitles[3] && <div className='col'>
                            <div className='d-flex align-items-center me-2'>
                                {/* begin::Symbol */}
                                <div className='symbol symbol-50px me-3'>
                                    <div className='symbol-label  bg-light-danger'>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen017.svg'
                                            className='svg-icon-1 svg-icon-danger'
                                        />
                                    </div>
                                </div>
                                {/* end::Symbol */}

                                {/* begin::Title */}
                                <div>
                                    <div className='fs-4 text-dark fw-bolder'>{indicatorTitles[3].valor}</div>
                                    <div className='fs-7 text-muted fw-bold'>{indicatorTitles[3].title}</div>
                                </div>
                                {/* end::Title */}
                            </div>
                        </div>
                        }
                        {/* end::Col */}
                    </div>
                    {children}
                    {/* end::Row */}
                </div>
                {/* end::Stats */}
            </div>
        </>
    )
}

export default HeaderCudIndicadores