import { request } from '../../../setup/axios/axios.util'

const API_URL = process.env.REACT_APP_API_URL || 'api'
export const libraries = String(['visualization']);
export const buscarDireccion = (dir: any) => {
  let data = { data: dir }
  const URL = `${API_URL}/core/geoloc`
  //  //console.log('data', data)
  return request().post(URL, data)
}
export default { buscarDireccion }
