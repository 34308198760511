/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ResetPaswoord} from './components/ResetPaswoord'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { Footer } from '../../../_metronic/layout/components/Footer'
import { WrapperAuth } from './components/WrapperAuth'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
        <WrapperAuth>
          <Switch>
            <Route path='/auth/login' component={Login} />
            <Route path='/auth/reset-password/:usuario/:resetKey' component={ResetPaswoord} />
            {/* <Route exact path='/auth/reset-password' component={ResetPaswoord} /> */}
            {/*  <Route path='/auth/registration' component={ResetPaswoord} /> */}
            <Route path='/auth/forgot-password' component={ForgotPassword} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </WrapperAuth>
  )
}
