import React, { useState } from 'react';
import { Idatos } from '../../Interfaces/Datos-interface';
import EncuestaStepper, { FormStep } from './EncuestaStepper';
import { DatosPersonales } from '../Forms/EncuestaCud/stepsEncuesta/DatosPersonales_1';
import { DatosHabitacionales } from '../Forms/EncuestaCud/stepsEncuesta/DatosHabitacionales_2';
import DatosEducacion from '../Forms/EncuestaCud/stepsEncuesta/DatosEducacion_3';
import { ActividadLaboral } from '../Forms/EncuestaCud/stepsEncuesta/ActividadLaboral_4';
import SituacionPrevisional from '../Forms/EncuestaCud/stepsEncuesta/SituacionPrevisional_5';
import CoberturaSalud from '../Forms/EncuestaCud/stepsEncuesta/CoberturaSalud_6';
import { ActividadLaboralSchema, CoberturaSaludSchema, DatosPersonalesSchema, EducacionSchema, IdatosPersonales, SituacionHabitacionalSchema, SituacionPrevisionalSchema } from '../../Interfaces/IdatosPerEncuesta';
import { FormikValues } from 'formik';
import Swal from 'sweetalert2';
import { getLegajoDni, saveDatosEncuesta } from '../../services/cud-service';
//import { jsonTestEncuesta } from '../../jsonTestEncuesta';
import { adapterDatosEncuesta } from '../../helpers/adapterEncuestaCud';

export const Encuesta: React.FC<Idatos> = () => {

  const [datosPersonales, setDatosPersonales] = useState<IdatosPersonales>({
    apellido: "",
    nombre: "",
  });

  const handleSubmit = async (values: FormikValues) => {
    const dataConverted = adapterDatosEncuesta(values);
    try {
      const response = await saveDatosEncuesta(dataConverted);
      console.log(response);
      if (response.status === 200) {
        Swal.fire(
          'Encuesta Completada',
          'Los datos fueron guardados en la base de datos correctamente',
          'success'
        )
      }

    }
    catch (err: any) {
      Swal.fire('Error', 'Ocurrió un error al procesar los datos: ' + err.response.data.error, 'error');
    }

  }

  const handleSubmitDatosPersonales = async (values: FormikValues) => {
    console.log(values);
    const { dni, sexo } = values;
    const dataSearched = await getLegajoDni(dni);
    if (dataSearched) {
      console.log("Datos recuperados correctamente: ", dataSearched.data);
      return;
      //setDatosPersonales({...datosPersonales, nombre: dataSearched.nombre})
    }
    else {
      console.log("Datos no recuperados");
      return false;
    }

  }
  const ESTADO_INICIAL = {
    tipo: '',
    estado: '',
  };

  return (
    <>
      <EncuestaStepper
        initialValues={{
          dni: '',
          sexo: '',
          apellido: '',
          nombre: '',
          domicilioReal: '',
          codigoPostal: '',
          telefono: '',
          localidad: '',
          provincia: '',
          departamento: '',
          tipoVivienda: '',
          tipoColectivo: [],
          especificar: "",
          vivienda: {

          },
          nivelEducativo: "",
          concurrio: false,
          concurre: false,
          inicial: false,
          primaria: false,
          secundaria: false,
          superior: false,
          especial: false,
          trayectoria: {
            inicialList: [],
            primariaList: [],
            secundariaList: [],
            superiorList: []
          },
          inclusionEducativa: false,
          inclusionEducActual: false,
          condicion: "",
          categoria: "",
          otroTrabajo: "",
          aportes: "",
          situacionPrevisional: [],
          coberturaSalud: [],
          nombreCobertura: "Instituto",
        }}
        onSubmit={handleSubmit}
        className={''}
      >
        <FormStep
          stepName='datosPersonales'
          validationSchema={DatosPersonalesSchema}
          onSubmit={handleSubmitDatosPersonales}
        >
          <DatosPersonales apellido={datosPersonales.apellido} nombre={datosPersonales.nombre} />

        </FormStep>

        <FormStep
          stepName='datosHabitacionales'
          validationSchema={SituacionHabitacionalSchema}
        >
          <DatosHabitacionales />
        </FormStep>
        <FormStep
          stepName='datosEducacion'
          validationSchema={EducacionSchema}
        >
          <DatosEducacion />
        </FormStep>
        <FormStep
          stepName='actividadLaboral'
          validationSchema={ActividadLaboralSchema}

        >
          <ActividadLaboral />
        </FormStep>

        <FormStep
          stepName='actividadLaboral'
          validationSchema={SituacionPrevisionalSchema}

        >
          <SituacionPrevisional />
        </FormStep>
        <FormStep
          stepName='coberturaSalud'
          validationSchema={CoberturaSaludSchema}
        >
          <CoberturaSalud />
        </FormStep>
      </EncuestaStepper >
    </>
  )
}
