import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
//import {Link} from 'react-router-dom'
import { ModalPeriodos } from './ModalPeriodos'
import { IEmpleados } from '../interfaces/IEmpleados'
import { ModEstadoOneEmpleado, getAllEmpresasOk, getEmpleadosByCuit } from '../services/AdminEmpleados'
import { OverlayTrigger, Tooltip } from 'react-bootstrap-v5'
import moment from 'moment'
import useAreaUser from '../../../../Hooks/useAreaUser'
import BotonEliminarMod from '../components/Modal/BotonEliminarMod'
import { eliminarEmpleado } from '../services/EJ.Services'
import Swal from 'sweetalert2'

const TablaEmpleados = () => {
  let totalEmPleados = 0
  const [loading, setLoading] = useState<any>(false)
  const [empresas, setEmpresas] = useState<[]>([])
  const [empleados, setEmpleados] = useState<IEmpleados[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [selecEmp, setSelecEmp] = useState<IEmpleados>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [titulo, setTitulo] = useState<string>('')
  const [cuit, setCuit] = useState<string>('');
  const fecha = new Date()
  const hoy = fecha.toLocaleDateString()
  const siguientePag = () => {
    totalEmPleados > currentPage + 5 && setCurrentPage(currentPage + 5)
  }
  const anteriorPag = () => {
    currentPage > 0 && setCurrentPage(currentPage - 5)
  }

  const closeModal = () => {
    setOpenModal(!openModal)
  }

  const filtrarEmpleado = (cuit: string) => {
    getEmpleadosByCuit(cuit).then((res: any) => {
      setEmpleados(res.data.data)
    })
    setCuit(cuit);
  }

  const { areaUsr } = useAreaUser();

  useEffect(() => {
    /* !openModal && setEmpresas([])
    !openModal && setEmpleados([]) */
    let isMounted = true
    getAllEmpresasOk().then((res: any) => {
      if (isMounted) {
        res.data.ok && setEmpresas(res.data.data)
      }
    })
    return () => {
      isMounted = false
    }
  }, [openModal])

  const tooltip = (titulo: string) => {
    return (
      <Tooltip id='tooltip'>
        <strong>{titulo}</strong>
      </Tooltip>
    )
  }

  const onDelete = async (index: number, id: any) => {
    const empleado = empleados.at(index)?.documento || '';
    const result = await ModEstadoOneEmpleado(cuit, empleado, 'borrar');
    if (result) {
      Swal.fire('Eliminación correcta!', '', 'success')
    }

  }

  return ( 
    <div className='mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <div className='containerTableTitle'>
          <h3 className='headerTableLefet'>
            <span className='card-label fw-bolder fs-3 mb-1'>Empleados Registrados al: {hoy}</span>
          </h3>

          <div className='col-lg-4 fv-row'>
            <select
              className='form-select form-select-solid capital'
              onChange={(e) => {
                filtrarEmpleado(e.target.value)
              }}
            >
              <option value=''>seleccione una empresa </option>
              {empresas.map((emp: any) => {
                return <option value={emp.cuit}>{emp.razon_social.toUpperCase()}</option>
              })}
            </select>
          </div>
        </div>

        <br />
        {/* begin::Body */}
        <div className='card-body py-3 border-0 pt-5'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  {/* <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-13-check'
                      />
                    </div>
                  </th> */}
                  <th className='min-w-150px'>DNI</th>
                  <th className='min-w-140px'>Apellido y Nombre</th>
                  <th className='min-w-120px'>F.Alta Afip </th>
                  <th className='min-w-120px'>Localidad</th>
                  <th className='min-w-120px'>Estado</th>
                  <th className='min-w-100px text-end'>Opciones</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {empleados.map((empleado: IEmpleados, index: any) => {
                  return (
                    <>
                      <tr key={index}>
                        {/* <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-13-check'
                              type='checkbox'
                              value='1'
                              onClick={() => {
                                console.log(selecEmp)
                                setSelecEmp(!selecEmp)
                              }}
                            />
                          </div>
                        </td> */}
                        <td>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6 '>
                            {empleado.documento}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6 capital'
                          >
                            {empleado.datosPersonales.apellido}
                          </a>
                          <span className='text-muted fw-bold text-muted d-block fs-7'>
                            {empleado.datosPersonales.nombres}
                          </span>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                          >
                            {moment.utc(empleado.datosLaborales.fechaAlta).format('DD/MM//YYYY')}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6 capital'
                          >
                            {empleado.datosPersonales.ciudad}
                          </a>
                        </td>

                        <td>
                          {empleado.estado === 'pendiente' && (
                            <span className='badge badge-light-primary capital'>
                              {empleado.estado}
                            </span>
                          )}
                          {empleado.estado === 'validado' && (
                            <span className='badge badge-light-success capital'>
                              {empleado.estado}
                            </span>
                          )}
                        </td>
                        <td className='text-end'>
                          {/*  <OverlayTrigger
                            placement='top'
                            overlay={tooltip('Cambiar el estado del empleado.')}
                          >
                            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen055.svg'
                                className='svg-icon-3'
                              />
                            </div>
                          </OverlayTrigger> */}
                          <OverlayTrigger
                            placement='top'
                            overlay={tooltip('Ver documentación inicial.')}
                          >
                            <div
                              className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                              onClick={() => {
                                setOpenModal(true)
                                setSelecEmp(empleado)
                                setTitulo('doc inicial')
                              }}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                fill='currentColor'
                                className='bi bi-eye'
                                viewBox='0 0 16 16'
                              >
                                <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z' />
                                <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z' />
                              </svg>
                            </div>
                          </OverlayTrigger>
                          <OverlayTrigger placement='top' overlay={tooltip('Ver Descuentos')}>
                            <div
                              className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                              onClick={() => {
                                setOpenModal(true)
                                setSelecEmp(empleado)
                                setTitulo('beneficio accedido')
                              }}
                            >
                              <KTSVG
                                path='/media/icons/duotune/finance/fin007.svg'
                                className='svg-icon-3'
                              />
                            </div>
                          </OverlayTrigger>
                          {empleado.anio!.length > 0 && (
                            <OverlayTrigger
                              placement='top'
                              overlay={tooltip('Ver documentación mensual.')}
                            >
                              <div
                                className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1'
                                onClick={() => {
                                  setOpenModal(true)
                                  setSelecEmp(empleado)
                                  setTitulo('periodos cargados')
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen014.svg'
                                  className='svg-icon-3 '
                                />
                              </div>
                            </OverlayTrigger>
                          )}
                          {/*  <div
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={() => {
                              setOpenModal(true)
                              setSelecEmp(empleado)
                              setTitulo('cargar documentación')
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen014.svg'
                              className='svg-icon-3'
                            />
                          </div> */}
                          {areaUsr === 'juventud' &&
                            <BotonEliminarMod
                              onDelete={onDelete}
                              index={index}
                              id={empleado._id}
                              texto={`${empleado.datosPersonales.apellido} ${empleado.datosPersonales.nombres}`}
                            />
                          }
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
            {/* begin::BTN Paginacion */}
            {/*  <ul className='pagination'>
              {currentPage === 0 ? (
                <li className='page-item disabled'>
                  <a type='button' className='btn btn-sm btn-light-info' onClick={anteriorPag}>
                    Anterior
                  </a>
                </li>
              ) : (
                <li className='page-item '>
                  <a type='button' className='btn btn-sm btn-light-info' onClick={anteriorPag}>
                    Anterior
                  </a>
                </li>
              )}

              <li className='page-item'>
                <a type='button' className='btn btn-sm btn-light-info' onClick={siguientePag}>
                  Siguiente
                </a>
              </li>
            </ul> */}
          </div>
          <ModalPeriodos
            openModal={openModal}
            closeModal={closeModal}
            docPeriodos={selecEmp}
            titulo={titulo}
          />
          {/* end::Table container */}
        </div>
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default TablaEmpleados
