/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

import {Field} from 'formik'

type Props = {
  foto?: string
}
const Step2: FC<Props> = ({foto}) => {
  return (
    <div className='w-100'>
      <div className='row '>
        <div className='col-lg-10'>
          <div className=' pb-8'>
            <h2 className='fw-bolder text-dark'>Datos personales</h2>
            <div className='text-gray-400 fw-bold fs-6'>Datos suministrados por renaper.</div>
          </div>
        </div>
        <div className='col-lg-2 '>
          <div className='symbol symbol-100px symbol-lg-60px symbol-fixed position-left'>
            <img src={foto} />{' '}
            <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
          </div>
        </div>
      </div>
      <div className='fv-row align-items-center'>
        <div className='row '>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder  fs-6'>Nombres</label>
            <Field
              type='text'
              name='datosPersonales.nombres'
              className='form-control form-control-solid capitalize'
              disabled={true}
            ></Field>
          </div>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder  fs-6'>Apellido</label>
            <Field
              type='text'
              name='datosPersonales.apellido'
              className='form-control form-control-solid capitalize'
              disabled={true}
            ></Field>
          </div>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder  fs-6'>Número de documento</label>
            <Field
              type='text'
              name='datosPersonales.dni'
              className='form-control form-control-solid'
              disabled={true}
            ></Field>
          </div>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder  fs-6'>Número de cuil</label>
            <Field
              type='text'
              name='datosPersonales.cuil'
              className='form-control form-control-solid'
              disabled={true}
            ></Field>
          </div>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder  fs-6'>Fecha de nacimiento</label>
            <Field
              type='text'
              name='datosPersonales.fechaNacimiento'
              className='form-control form-control-solid'
              disabled={true}
            ></Field>
          </div>
        </div>

        <hr />

        <div className='row '>
          <div className='col-lg-6'>
            <label className='form-label fw-bolder  fs-6'>Provincia</label>
            <Field
              type='text'
              name='datosPersonales.provincia'
              className='form-control form-control-solid capitalize'
              disabled={true}
            ></Field>
          </div>
          <div className='col-lg-6'>
            <label className='form-label fw-bolder  fs-6'>Localidad</label>
            <Field
              type='text'
              name='datosPersonales.ciudad'
              className='form-control form-control-solid capitalize'
              disabled={true}
            ></Field>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step2}
