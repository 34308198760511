import React, {useState} from 'react'
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip'
import {color} from '@mui/system'
import {styled} from '@mui/material'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Link, useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  aprobarBensHacienda,
  patchAprobarBens,
  rechazarBensHacienda,
  transferirBensHacienda,
} from '../services/Bens-Services'
type buttonState = {
  objet?: any
  setModalState: any
  tituloMenu?: string
  tituloTabla?: string
}
//Tooltip config
const BootstrapTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: `${color}`,
    color: `#919A9B`,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${color}`,
    color: `#FFFFFF`,
    boxShadow: theme.shadows[2],
    fontSize: 11,
    fontWeightBold: 700,
    disabled: false,
  },
}))

export const ButtonActionBens: React.FC<buttonState> = ({
  objet,
  tituloMenu,
  tituloTabla,
  setModalState,
}) => {
  let history = useHistory()

  const tokenBens: any = sessionStorage.getItem('BensToken')
  //Alert aprobar bens
  const AlertAprobarBens = () => {
    Swal.fire({
      title: '¿Aprobar la Inscripcion?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Aprobar!',
    }).then((result) => {
      if (result.isConfirmed) {
        AprobarBens(objet!.dni, objet.id_tramite.toString())
      }
    })
  }

  const AlertRechazarBens = () => {
    Swal.fire({
      title: '¿Rechazar la Inscripcion?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Rechazar!',
    }).then((result) => {
      if (result.isConfirmed) {
        RechazarBens(objet!.dni, objet.id_tramite.toString())
      }
    })
  }

  const AlertTransferirBens = () => {
    Swal.fire({
      title: '¿Transferir la Inscripcion?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Transferir!',
    }).then((result) => {
      if (result.isConfirmed) {
        transferirBeng(objet!.dni, objet.id_tramite.toString())
      }
    })
  }

  //Send Inscripcion Api
  const AprobarBens = (bens: string, id_tramite: string) => {
    const sendInsBens = {
      dni: bens,
      mod: 'modAprobBens',
      estado: 'validado',
    }
    aprobarBensHacienda(id_tramite.toString(), tokenBens).then(async (res: any) => {
      if (!res.data.json.error) {
        patchAprobarBens(sendInsBens).then(async (res: any) => {
          if (res.data.ok === true) {
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'El tramite fue validado',
              showConfirmButton: false,
              timer: 3500,
            })
            history.push({pathname: '/bens/bens'})
          } else {
            await Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Problemas al guardar la Info, en el servidor local',
              showConfirmButton: false,
              timer: 3500,
            })
            history.push({pathname: '/bens/bens'})
          }
        })
      } else {
        await Swal.fire({
          position: 'center',
          icon: 'error',
          title: `${res.data.json.errmsj}`,
          showConfirmButton: false,
          timer: 2500,
        })
        history.push({pathname: '/bens/bens'})
      }
    })
  }
  const RechazarBens = (bens: string, id_tramite: string) => {
    const sendInsBens = {
      dni: bens,
      mod: 'modRechazo',
      estado: 'rechazado',
    }
    rechazarBensHacienda(id_tramite.toString(), tokenBens).then(async (res: any) => {
      if (res.data.ok === true) {
        patchAprobarBens(sendInsBens).then(async (res: any) => {
          if (res.data.ok === true) {
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'El tramite fue rechazado',
              showConfirmButton: false,
              timer: 3500,
            })
            history.push({pathname: '/bens/bens'})
          } else {
            await Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Problemas al guardar la Info, en el servidor local',
              showConfirmButton: false,
              timer: 3500,
            })
            history.push({pathname: '/bens/bens'})
          }
        })
      } else {
        await Swal.fire({
          position: 'center',
          icon: 'error',
          title: `${res.data.json.errmsj}`,
          showConfirmButton: false,
          timer: 3500,
        })
        history.push({pathname: '/bens/bens'})
      }
    })
  }

  const transferirBeng = (bens?: string, id_tramite?: string) => {
    const sendInsBens = {
      dni: bens,
      mod: 'modTransferido',
      estado: 'transferido',
    }
    transferirBensHacienda(objet.id_tramite.toString(), tokenBens).then(async (res: any) => {
      if (res.data.ok === true) {
        patchAprobarBens(sendInsBens).then(async (res: any) => {
          if (res.data.ok === true) {
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'El tramite fue transferido',
              showConfirmButton: false,
              timer: 3500,
            })
            history.push({pathname: '/bens/bens'})
          } else {
            await Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Problemas al guardar la Info, en el servidor local',
              showConfirmButton: false,
              timer: 3500,
            })
            history.push({pathname: '/bens/bens'})
          }
        })
      } else {
        await Swal.fire({
          position: 'center',
          icon: 'error',
          title: `${res.data.json.errmsj}`,
          showConfirmButton: false,
          timer: 3500,
        })
        history.push({pathname: '/bens/bens'})
      }
    })
  }

  return (
    <div>
      {tituloMenu === 'Aprobados' ? null : (
        <BootstrapTooltip title='Movimientos' arrow followCursor placement='top'>
          <button
            className='btn btn-icon btn-bg-info btn-color-light btn-active-light btn-active-color-info btn-sm me-1'
            onClick={() => setModalState(true)}
          >
            {objet.movimientos.length === 0 ? (
              <KTSVG path='/media/icons/duotune/communication/com009.svg' className='svg-icon-3' />
            ) : (
              <span>{objet.movimientos.length}</span>
            )}
          </button>
        </BootstrapTooltip>
      )}
      <BootstrapTooltip title='Ver' arrow followCursor placement='top'>
        <Link to={{pathname: 'EditarInscripcion/', state: {objet, tituloMenu, tituloTabla}}}>
          <button className='btn btn-icon btn-bg-success btn-color-light btn-active-light btn-active-color-success btn-sm me-1'>
            <KTSVG path='/media/icons/duotune/general/gen045.svg' className='svg-icon-3' />
          </button>
        </Link>
      </BootstrapTooltip>

      <BootstrapTooltip title='Rechazar' arrow followCursor placement='top'>
        <button
          onClick={() => AlertRechazarBens()}
          className='btn btn-icon btn-bg-danger btn-color-light btn-active-light btn-active-color-danger btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/files/fil007.svg' className='svg-icon-3' />
        </button>
      </BootstrapTooltip>
      <BootstrapTooltip title='Transferir' arrow followCursor placement='top'>
        <button
          onClick={() => AlertTransferirBens()}
          className='btn btn-icon btn-bg-warning btn-color-light btn-active-light btn-active-color-warning btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-3' />
        </button>
      </BootstrapTooltip>

      {tituloMenu === 'Aprobados' ? null : (
        <BootstrapTooltip title='Validar' arrow followCursor placement='top'>
          <button
            onClick={() => AlertAprobarBens()}
            className='btn btn-icon btn-bg-primary btn-color-light btn-active-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/files/fil008.svg' className='svg-icon-3' />
          </button>
        </BootstrapTooltip>
      )}
    </div>
  )
}
