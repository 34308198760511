/** React */
import React, {useEffect, useState} from 'react'
/** Formik */
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {localidadesNqn} from '../../../../../services/gral/proLocService'
import TextErrorFormik from '../../../../../components/Formik/TextErrorFormik'
import Button from '@mui/material/Button'
import {FormControlLabel, Radio, RadioGroup} from '@mui/material'
import {initTramitesN} from '../../helpers/inits'
import moment from 'moment'

type Props = {
  handleChange: any
  handleBlur: any
  datosTutor?: any
  existeTutor?: any
  formik?: any
  formSecu?: any
  setFormSecu?: any
  Check?: any
  tramite?: any
  menor?: any
  valueInteresadoApoyoJudicial?: any
  valueInteresadoApoyoExtraJudicial?: any
  valueCuradorDesignadoJudicial?: any
  confirmart?: any
  setConfirmart?: any
  setFormatoDisablet?: any
  opcionConfirmat?: any
  setOpcionConfirmat?: any
  setDisableConfirmat?: any
  formatoOpacityt?: any
  setFormatoOpacityt?: any
  Disabled20?: any
  setDisabled20?: any
  btnConfirmart?: any
  setBtnConfirmart?: any
  btnEditart?: any
  setBtnEditart?: any
  disableTextCt?: any
  setDisableTextCt?: any
  btnSwitch?: any
  setBtnSwitch?: any
  anios?: any
}
export const Datos_Tutor: React.FC<Props> = ({
  handleChange,
  handleBlur,
  datosTutor,
  formik,
  setFormSecu,
  formSecu,
  menor,
  valueInteresadoApoyoJudicial,
  valueInteresadoApoyoExtraJudicial,
  valueCuradorDesignadoJudicial,
  setFormatoDisablet,
  opcionConfirmat,
  setOpcionConfirmat,
  setDisableConfirmat,
  formatoOpacityt,
  setFormatoOpacityt,
  btnConfirmart,
  setBtnConfirmart,
  btnEditart,
  setBtnEditart,
  disableTextCt,
  setDisableTextCt,
  btnSwitch,
  setBtnSwitch,
  anios,
}) => {
  const [localidad, setLocalidad] = useState<any>([])
  const [fechaNow, setFechaNow] = useState(moment().format('DD/MM/YYYY'))

  useEffect(() => {
    localidadesNqn().then((res: any) => {
      setLocalidad(res.data.data)
    })
  }, [])

  const confirmat = (opcion: any) => {
    setFormSecu({
      ...formSecu,
      datosPersonales: formik.values.datosPersonales,
      datosTutor: formik.values.datosTutor,
    })

    if (opcion === 'true') {
      setFormatoDisablet('card bg-ligth border mb-6 card_align5 disabled_formato')
      setFormatoOpacityt('disabled_opacity')
      setDisableConfirmat(true)
      setBtnConfirmart(true)
      setBtnEditart(true)
      setDisableTextCt(true)
      setBtnSwitch(false)
    } else {
      setFormatoDisablet('card bg-ligth border mb-6 card_align5')
      setFormatoOpacityt('')
      setOpcionConfirmat(true)
      setBtnConfirmart(false)
      setBtnEditart(false)
      setDisableTextCt(false)
      setBtnSwitch(true)
    }
  }
  function changedateformat(val: any) {
    if (val) {

      let myArray = val.split('/')
  
      let year = myArray[0]
      let month = myArray[1]
      let day = myArray[2]
  
      let formatteddate = day + '-' + month + '-' + year
  
      return formatteddate
    }
  }
  return (
    <>
      <div className={formatoOpacityt}>
        <br></br>
        <br></br>
        <div className='row'>
          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>Tipo Tutor</label>

            <Field
              as='select'
              id='datosTutor.tipoPariente'
              name='datosTutor.tipoPariente'
              className='form-control form-control-solid capital'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={disableTextCt}
            >
              <option value=''>Seleccione</option>
              <option value='madre'>madre</option>
              <option value='padre'>padre</option>
              <option value='tutor designado judicialmente'>tutor designado judicialmente</option>
            </Field>
            {formik.errors?.tipoPariente && formik.touched.apellido ? (
              <div>{formik.errors.tipoPariente}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosTutor.tipoPariente'
            />
          </div>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Apellido</label>

            <Field
              control='input'
              type='text'
              placeholder='Ingrese Apellido'
              id='datosTutor.apellido'
              name='datosTutor.apellido'
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextCt}
            />
            {formik.errors.apellido && formik.touched.apellido ? (
              <div>{formik.errors.apellido}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosTutor.apellido'
            />
          </div>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Nombres</label>

            <Field
              control='input'
              type='text'
              placeholder='Ingrese Nombres'
              id='datosTutor.nombre'
              name='datosTutor.nombre'
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextCt}
            />
            {formik.errors.nombre && formik.touched.nombre ? (
              <div>{formik.errors.nombre}</div>
            ) : null}

            <ErrorMessage className='error' component={TextErrorFormik} name='datosTutor.nombre' />
          </div>

          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>Fecha Nacimiento</label>

            <Field
              control='input'
              type='date'
              id='datosTutor.fechaNacimiento'
              name='datosTutor.fechaNacimiento'
              value={changedateformat(formik.values.datosTutor?.fechaNacimiento)}
              className='form-control form-control-solid '
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextCt}
              max={fechaNow}
            />
            {formik.errors.fechaNacimiento && formik.touched.fechaNacimiento ? (
              <div>{formik.errors.fechaNacimiento}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosTutor.fechaNacimiento'
            />
          </div>
        </div>
        <br></br>
        <br></br>
        <div className='row '>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Domicilio</label>

            <Field
              control='input'
              placeholder='Ingrese Domicilio'
              type='text'
              id='datosTutor.domicilio'
              name='datosTutor.domicilio'
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextCt}
            />
            {formik.errors.domicilio && formik.touched.domicilio ? (
              <div>{formik.errors.domicilio}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosTutor.domicilio'
            />
          </div>

          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Localidad</label>
            <Field
              as='select'
              id='datosTutor.localidad'
              name='datosTutor.localidad'
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextCt}
            >
              <option value=''>Seleccionar</option>
              {localidad.map((value: any, index: any) => {
                return (
                  <option key={index} value={value.nombre.toLowerCase()}>
                    {value.nombre}
                  </option>
                )
              })}{' '}
            </Field>

            {formik.errors.localidad && formik.touched.localidad ? (
              <div>{formik.errors.localidad}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosTutor.localidad'
            />
          </div>
          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>Codigo Postal</label>

            <Field
              control='input'
              type='text'
              placeholder='Ingrese Cod Postal'
              id='datosTutor.codigoPostal'
              name='datosTutor.codigoPostal'
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextCt}
            />
            {formik.errors.codigoPostal && formik.touched.codigoPostal ? (
              <div>{formik.errors.codigoPostal}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosTutor.codigoPostal'
            />
          </div>
          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>Nacionalidad</label>
            <Field
              control='input'
              type='text'
              placeholder='Ingrese Nacionalidad'
              id='datosTutor.nacionalidad'
              name='datosTutor.nacionalidad'
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextCt}
            />

            {formik.errors.nacionalidad && formik.touched.nacionalidad ? (
              <div>{formik.errors.nacionalidad}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosTutor.nacionalidad'
            />
          </div>
        </div>
        <br></br>
        <br></br>
        <div className='row'>
          <br></br>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Telefono</label>

            <Field
              control='input'
              type='text'
              placeholder='Ingrese Telefono'
              id='datosTutor.telefono'
              name='datosTutor.telefono'
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextCt}
            />
            {formik.errors.telefono && formik.touched.telefono ? (
              <div>{formik.errors.telefono}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosTutor.telefono'
            />
          </div>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Email</label>

            <Field
              control='input'
              placeholder='Ingrese Correo'
              type='text'
              id='datosTutor.mail'
              name='datosTutor.mail'
              className='form-control form-control-solid '
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextCt}
            />
            {formik.errors.mail && formik.touched.mail ? <div>{formik.errors.mail}</div> : null}

            <ErrorMessage className='error' component={TextErrorFormik} name='datosTutor.mail' />
          </div>
        </div>
        <br></br>
        <br></br>

        {anios >= 16 ? (
          <>
            <div className='row'>
              <div className='col-lg-8'>
                <label className='form-label fw-bolder text-dark fs-6'>
                  Interesado/a con apoyo designado juducialmente (Art.43 Código Civil y Comercial de
                  la Nación)
                </label>
              </div>
              <div className='col-lg-2'>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='datosTutor.interesadoApoyoJudicial'
                  id='datosTutor.interesadoApoyoJudicial'
                  defaultValue={valueInteresadoApoyoJudicial}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={disableTextCt}
                        value={'si'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label='Si'
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={disableTextCt}
                        value={'no'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label='No'
                  />
                </RadioGroup>

                {formik.errors.interesadoApoyoJudicial && formik.touched.interesadoApoyoJudicial ? (
                  <div>{formik.errors.interesadoApoyoJudicial}</div>
                ) : null}

                <ErrorMessage
                  className='error'
                  component={TextErrorFormik}
                  name='datosTutor.interesadoApoyoJudicial'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-8'>
                <label className='form-label fw-bolder text-dark fs-6'>
                  Interesado/a con apoyo extrajudicial (Art.103 in fine Código Civil y Comercial de
                  la Nación)
                </label>
              </div>
              <div className='col-lg-2'>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='datosTutor.interesadoApoyoExtraJudicial'
                  id='datosTutor.interesadoApoyoExtraJudicial'
                  defaultValue={valueInteresadoApoyoExtraJudicial}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={disableTextCt}
                        value={'si'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label='Si'
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={disableTextCt}
                        value={'no'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label='No'
                  />
                </RadioGroup>

                {formik.errors.interesadoApoyoExtraJudicial &&
                formik.touched.interesadoApoyoExtraJudicial ? (
                  <div>{formik.errors.interesadoApoyoExtraJudicial}</div>
                ) : null}

                <ErrorMessage
                  className='error'
                  component={TextErrorFormik}
                  name='datosTutor.interesadoApoyoExtraJudicial'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-8'>
                <label className='form-label fw-bolder text-dark fs-6'>
                  Curador/a designado judicialmente (Art.32 Código Civil y Comercial de la Nación)
                </label>
              </div>
              <div className='col-lg-2'>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='datosTutor.curadorDesignadoJudicial'
                  id='datosTutor.curadorDesignadoJudicial'
                  defaultValue={valueCuradorDesignadoJudicial}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={disableTextCt}
                        value={'si'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label='Si'
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={disableTextCt}
                        value={'no'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label='No'
                  />
                </RadioGroup>

                {formik.errors.curadorDesignadoJudicial &&
                formik.touched.curadorDesignadoJudicial ? (
                  <div>{formik.errors.curadorDesignadoJudicial}</div>
                ) : null}

                <ErrorMessage
                  className='error'
                  component={TextErrorFormik}
                  name='datosTutor.curadorDesignadoJudicial'
                />
              </div>
            </div>

            <h5 hidden={true}>
              {menor === false
                ? (formik.values.datosTutor.anios = 'false')
                : (formik.values.datosTutor.anios = 'true')}
            </h5>
            <Field
              as='select'
              id='datosTutor.anios'
              name='datosTutor.anios'
              onChange={handleChange}
              onBlur={handleBlur}
              hidden={true}
              disabled={disableTextCt}
            >
              <option value=''></option>
              <option value='false'>false</option>
              <option value='true'>true</option>
            </Field>
          </>
        ) : null}
        {formik.values?.datosTutor?.tipoPariente === 'tutor designado judicialmente' ? (
          <div>
            <br></br>
            <hr></hr>
            <h4 className='error'>Completar en caso de Tutor, Guardador, Curador o Apoyo</h4>
            <br></br>
            <div className='row'>
              <div className='col-lg-2'>
                <label className='form-label fw-bolder text-dark fs-6'>Tipo Tutor</label>

                <Field
                  as='select'
                  id='datosTutor.tipoTutor'
                  name='datosTutor.tipoTutor'
                  className='form-control form-control-solid capital'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={disableTextCt}
                >
                  <option value=''>Seleccione</option>
                  <option value='provisorio'>provisorio</option>
                  <option value='definitivo'>definitivo</option>
                </Field>
                {formik.errors?.tipoTutor && formik.touched?.tipoTutor ? (
                  <div>{formik.errors?.tipoTutor}</div>
                ) : null}

                <ErrorMessage
                  className='error'
                  component={TextErrorFormik}
                  name='datosTutor.tipoTutor'
                />
              </div>
              <div className='col-lg-2'>
                <label className='form-label fw-bolder text-dark fs-6'>Fecha Designacion</label>
                <Field
                  control='input'
                  type='date'
                  id='datosTutor.fechaDesignacionTutor'
                  name='datosTutor.fechaDesignacionTutor'
                  className='form-control form-control-solid '
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={disableTextCt}
                  max={fechaNow}
                />
                {formik.errors.fechaDesignacionTutor && formik.touched.fechaDesignacionTutor ? (
                  <div>{formik.errors.fechaDesignacionTutor}</div>
                ) : null}

                <ErrorMessage
                  className='error'
                  component={TextErrorFormik}
                  name='datosTutor.fechaDesignacionTutor'
                />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder text-dark fs-6'>Juzgado</label>
                <Field
                  control='input'
                  type='text'
                  id='datosTutor.juzgado'
                  name='datosTutor.juzgado'
                  className='form-control form-control-solid '
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={disableTextCt}
                />
                {formik.errors.juzgado && formik.touched.juzgado ? (
                  <div>{formik.errors.juzgado}</div>
                ) : null}

                <ErrorMessage
                  className='error'
                  component={TextErrorFormik}
                  name='datosTutor.juzgado'
                />
              </div>

              <div className='col-lg-2'>
                <label className='form-label fw-bolder text-dark fs-6'>Secretaria</label>
                <Field
                  control='input'
                  type='text'
                  id='datosTutor.secretaria'
                  name='datosTutor.secretaria'
                  className='form-control form-control-solid '
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={disableTextCt}
                />
                {formik.errors.secretaria && formik.touched.secretaria ? (
                  <div>{formik.errors.secretaria}</div>
                ) : null}

                <ErrorMessage
                  className='error'
                  component={TextErrorFormik}
                  name='datosTutor.secretaria'
                />
              </div>
              <div className='col-lg-2'>
                <label className='form-label fw-bolder text-dark fs-6'>Dpto. Judicial</label>
                <Field
                  control='input'
                  type='text'
                  id='datosTutor.dptoJudicial'
                  name='datosTutor.dptoJudicial'
                  className='form-control form-control-solid '
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={disableTextCt}
                />
                {formik.errors.dptoJudicial && formik.touched.dptoJudicial ? (
                  <div>{formik.errors.dptoJudicial}</div>
                ) : null}

                <ErrorMessage
                  className='error'
                  component={TextErrorFormik}
                  name='datosTutor.dptoJudicial'
                />

                <br></br>
                <br></br>
              </div>
            </div>

            <div className='row'>
              <br></br>

              <div className='col-lg-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Fiscalia</label>
                <Field
                  control='input'
                  type='text'
                  id='datosTutor.fiscalia'
                  name='datosTutor.fiscalia'
                  className='form-control form-control-solid '
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={disableTextCt}
                />
                {formik.errors.fiscalia && formik.touched.fiscalia ? (
                  <div>{formik.errors.fiscalia}</div>
                ) : null}

                <ErrorMessage
                  className='error'
                  component={TextErrorFormik}
                  name='datosTutor.fiscalia'
                />
              </div>
              <div className='col-lg-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Defensoría</label>
                <Field
                  control='input'
                  type='text'
                  id='datosTutor.defensoria'
                  name='datosTutor.defensoria'
                  className='form-control form-control-solid '
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={disableTextCt}
                />
                {formik.errors.defensoria && formik.touched.defensoria ? (
                  <div>{formik.errors.defensoria}</div>
                ) : null}

                <ErrorMessage
                  className='error'
                  component={TextErrorFormik}
                  name='datosTutor.defensoria'
                />
              </div>
            </div>
          </div>
        ) : null
        /* (
          ((formik.values.datosTutor.tipoTutor = ''),
          (formik.values.datosTutor.fechaDesignacionTutor = ''),
          (formik.values.datosTutor.secretaria = ''),
          (formik.values.datosTutor.dptoJudicial = ''),
          (formik.values.datosTutor.fiscalia = ''),
          (formik.values.datosTutor.defensoria = ''),
          (formik.values.datosTutor.juzgado = ''))
        ) */
        }
      </div>
      <div className='col-lg-12'>
        <br></br>

        {formik.validateOnMount === true && formik.isValid === true && opcionConfirmat === true ? (
          <>
            <div className='col-lg-12'>
              <button
                hidden={btnConfirmart}
                type='button'
                className='btn btn-warning btn-sm btn-salir'
                onClick={() => {
                  confirmat('true')
                }}
              >
                Confirmar
              </button>
            </div>

            <div className='col-lg-12'>
              <button
                type='button'
                hidden={!btnEditart}
                className='btn btn-warning btn-sm btn-salir'
                onClick={() => {
                  confirmat('false')
                }}
                disabled={btnSwitch}
              >
                Editar
              </button>
            </div>
          </>
        ) : (
          <>
            <div className='col-lg-12'>
              <button
                type='button'
                hidden={!btnEditart}
                className='btn btn-warning btn-sm btn-salir'
                onClick={() => {
                  confirmat('false')
                }}
                disabled={btnSwitch}
              >
                Editar
              </button>
            </div>

            <div className='col-lg-12'>
              <button
                hidden={!btnConfirmart}
                type='button'
                className='btn btn-warning btn-sm btn-salir'
                onClick={() => {
                  confirmat('true')
                }}
              >
                Confirmar
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Datos_Tutor
