import React from 'react'
import DateView from 'react-datepicker'
import {Field, ErrorMessage} from 'formik'
import TextErrorFormik from './TextErrorFormik'
import 'react-datepicker/dist/react-datepicker.css'
import {registerLocale} from 'react-datepicker'
import es from 'date-fns/locale/es'
registerLocale('es', es)

const DatePickerFormik: React.FC<{}> = (props: any) => {
  const {label, name, minDate, maxDate, placeHolder} = props

  return (
    <div>
      <label className='form-label fw-bolder text-dark fs-6'>{label}</label>
      <Field name={name}>
        {(form: any) => {
          const {setFieldValue} = form.form
          const selected = form.field.value

          return (
            <DateView
              id={name}
              dateFormat='dd/MM/yyyy'
              selected={selected}
              onChange={(date) => {
                setFieldValue(name, date)
              }}
              minDate={minDate}
              maxDate={maxDate}
              locale='es'
              className='form-control form-control-solid'
              placeholderText={placeHolder}
            />
          )
        }}
      </Field>

      <ErrorMessage name={name} component={TextErrorFormik} />
    </div>
  )
}

export default DatePickerFormik
