import {IconButton} from '@mui/material'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {ICreateUsr} from '../../../../../../interfaces/ICreateUsr'
import {TablaPeril} from './TablaPeril'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

interface props {
  idUsuario?: string
  usuario?: ICreateUsr
}

const Notifications: React.FC<props> = ({idUsuario, usuario}) => {
  /*  const [data, setData] = useState<INotifications>(notifications)

  const updateData = (fieldsToUpdate: Partial<INotifications>) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  } */
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const click = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_notifications'
        aria-expanded='true'
        aria-controls='kt_account_notifications'
      >
        <div className='card-title m-0'>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => {
              setOpen(!open)
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <h3 className='fw-bolder m-0'>Modulos y Perfil</h3>
        </div>
      </div>

      <div id='kt_account_notifications' className='collapse show'>
        <form className='form'>
          <div className='card-body border-top px-9 pt-3 pb-4'>
            <div className='table-responsive'>
              <TablaPeril perfil={usuario?.perfilUs!}></TablaPeril>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link
              to={{
                pathname: 'account/perfil',
                state: {idUsuario: idUsuario, perfilUs: usuario?.perfilUs},
              }}
            >
              {' '}
              <button className='btn btn-white btn-active-light-primary me-2'>Editar Perfil</button>
            </Link>

            {/*  <button type='button' onClick={click} className='btn btn-primary'>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button> */}
          </div>
        </form>
      </div>
    </div>
  )
}

export {Notifications}
