/* eslint-disable jsx-a11y/anchor-is-valid */

import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import {login} from '../Services/AuthService'
import * as Yup from 'yup'
import clsx from 'clsx'
import jwt_decode from 'jwt-decode'
import {loginAction} from '../../../../setup/redux/ReduxActions/AuthActions'
import {usrAction} from '../models/AuthModel'

import {DashAction} from '../../../../setup/redux/ReduxActions/DashActions'
import Agenda_Service, {actualizarEstados} from '../../apps/Agenda/Redux/services/Agenda_Service'
import {IEventos} from '../../apps/Agenda/pages/CrearEventoPage/Steps/EventoData'
import {setEvFull, setEvPub} from '../../apps/Agenda/Redux/actions/eventos'
import {Link} from 'react-router-dom'

const loginSchema = Yup.object().shape({
  usuario: Yup.string()
    .min(7, 'Minimo 7 caracteres')
    .max(11, 'Maximo 11 caracteres')
    .required('Usuario es requirido'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  usuario: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const dispatch = useDispatch()
  const [errorLogin, setErrorlogin] = useState<boolean>(false)
  const [msgApi, setMsgApi] = useState<string>('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (loading) {
    }
  }, [loading])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.usuario, values.password)
          .then((res: any) => {
            if (res.data.ok) {
              setLoading(false)
              // //console.log(res.data)
              const {foto, token, modOk} = res.data
              sessionStorage.setItem('token', token)
              const usData: any = jwt_decode(token)
              //  //console.log('usData', usData)
              const usuario: usrAction = {
                id: usData.id,
                email: usData.email,
                lastLogin: usData.lastLogin,
                exp: usData.exp,
                iat: usData.iat,
                nombreCompleto: usData.nombres + ' ' + usData.apellido,
                organizacion: usData.areaResponsable,
                nombres: usData.nombres,
                apellido: usData.apellido,
                usuario: values.usuario,
                primerLogin: usData.primerLogin,
              }
              //console.log(usData);
              dispatch(loginAction(token, foto, usuario))
              const isLogged: boolean = true
              const primerLogin: boolean = usData.primerLogin
              // console.log('Primer login: ', primerLogin)
              dispatch(DashAction(usData.nombres, usData.perfil, isLogged, primerLogin, modOk))
              let temp: IEventos[] = []
              Agenda_Service.buscarEventos().then((res: any) => {
                let hoy = new Date()
                let eventos = res.data
                for (let d = 0; d < eventos.length; d++) {
                  if (eventos[d].estado !== 'cancelado' || eventos[d].estado === '') {
                    if (hoy < new Date(eventos[d].start)) {
                      eventos[d].estado = 'planificado'
                    }
                    if (hoy > new Date(eventos[d].end)) {
                      eventos[d].estado = 'realizado'
                    }
                    if (hoy >= new Date(eventos[d].start) && hoy <= new Date(eventos[d].end)) {
                      eventos[d].estado = 'en curso'
                    }
                  } else {
                    eventos[d].estado = 'cancelado'
                  }
                }
                actualizarEstados(eventos, 'upd').then((res: any) => {
                  if (res.data.ok) {
                    //console.log(`Evento actualizado correctamente`)
                  }
                })
                dispatch(setEvFull(eventos))
                //console.log('usData.areaResponsable', usData.areaResponsable)
                let areaPUsuario = usData.areaResponsable.datosOrganismo.areaPrincipal.idAreaP
                for (let ev = 0; ev < eventos.length; ev++) {
                  const e: IEventos = eventos[ev]
                  if (e.idArea !== areaPUsuario && e.tipo === 'privado') {
                  } else {
                    temp.push(e)
                  }
                }

                dispatch(setEvPub(temp))
              })
            } else {
              setLoading(false)
              setSubmitting(false)
              setErrorlogin(true)
              setMsgApi(res.data.msg)
            }
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Iniciar Sesión</h1>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Usuario</label>
        <input
          placeholder='Usuario'
          {...formik.getFieldProps('usuario')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.usuario && formik.errors.usuario},
            {
              'is-valid': formik.touched.usuario && !formik.errors.usuario,
            }
          )}
          type='text'
          name='usuario'
          autoComplete='off'
        />
        {formik.touched.usuario && formik.errors.usuario && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.usuario}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Contraseña</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Recuperar contraseña.
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        {errorLogin && (
          <div className='alert alert-danger d-flex align-items-center' role='alert'>
            <svg
              xmlns='https://www.w3.org/2000/svg'
              width='24'
              height='24'
              fill='currentColor'
              className='bi bi-exclamation-triangle-fill flex-shrink-0 me-2'
              viewBox='0 0 16 16'
              role='img'
              aria-label='Warning:'
            >
              <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z' />
            </svg>
            <div>{msgApi}</div>
          </div>
        )}
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-secondary w-100 mb-5 text-white'
          disabled={formik.isSubmitting || !formik.isValid}
          onClick={() => setErrorlogin(false)}
        >
          {!loading && <span className='indicator-label'>Continuar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Por favor espere...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {/* begin::Separator */}
        {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}
        {/* end::Separator */}
        {/* begin::Google link */}
        {/*  <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
        {/* end::Google link */}
        {/* begin::Google link */}
        {/*  <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
        {/* end::Google link */}
        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  )
}
