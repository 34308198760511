import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ISTATE } from '../../../../../../setup/redux/interface/reduxState.interface'
import { KTSVG } from '../../../../../../_metronic/helpers/components/KTSVG'
import { seleccionMenu } from '../../Redux/actions/menuActions'
import { uiCloseModal } from '../../Redux/actions/ui'
import './BotonAgenda.scss'

type Props = {
  imagen?: string
  pag_inicio: string
  nombre?: string
  colorFondo?: string
  colorDisable?: string
  sizeBoton?: string
  sizeIcon?: string
  habilitado: boolean
  data: boolean
}
const ButtonMarginClass = 'ms-1 ms-lg-3'


const BotonVolver: React.FC<Props> = ({ imagen, pag_inicio, nombre, colorFondo, colorDisable, sizeBoton, sizeIcon, habilitado, data }) => {
  const dispatch = useDispatch()
  const selStore: any = useSelector((state: ISTATE) => state.menuBoton)
  const { seleccion } = selStore
  let color: any = ''
  let ButtonIconSizeClass = (sizeIcon !== undefined ? sizeIcon : 'svg-icon-1')
  let ButtonHeightClass = (sizeBoton !== undefined ? sizeBoton : 'w-50px h-40px w-md-60px h-md-50px')




  const resetMenu = () => {
    let temp: any = []
    seleccion.map((sel: any) => {
      sel.selec = true
      temp.push(sel)
    })
    dispatch(seleccionMenu(temp))
    dispatch(uiCloseModal())
  }

  const LightTooltip = styled(({ className, ...props }: TooltipProps) =>
    (<Tooltip {...props} classes={{ popper: className }} placement="top" />))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: `${color}`,
        color: `#FFFFFF`,
        boxShadow: theme.shadows[1],
        fontSize: 11,
        fontWeightBold: 700,
        disabled: false
      },
    }));
  return (
    <>
      {habilitado ? (
        <LightTooltip className='negrita' children={

          <div className={clsx('d-flex align-items-center', ButtonMarginClass,)}>
            <button
              disabled={habilitado}
              className={clsx(
                `btn btn-icon  ${colorFondo} btn-custom ${colorDisable}`,
                ButtonHeightClass,
              )}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
              onClick={() => { resetMenu() }}

            >
              <div className="row d-flex">
                <KTSVG path={`/media/icons/duotune${imagen}`} className={ButtonIconSizeClass} />
                <label className='fs-8'>{nombre}</label>
              </div>

            </button>

          </div>


        } title={<Typography color="inherit" style={{ fontWeight: 600 }}>{nombre}</Typography>} ></LightTooltip>
      ) : (

        <Link to={pag_inicio}>
          <LightTooltip className='negrita' children={

            <div className={clsx('d-flex align-items-center', ButtonMarginClass,)}>
              <button
                disabled={habilitado}
                className={clsx(
                  `btn btn-icon  ${colorFondo} btn-custom ${colorDisable}`,
                  ButtonHeightClass,
                )}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
                onClick={() => { resetMenu() }}

              >
                <div className="row d-flex">
                  <KTSVG path={`/media/icons/duotune${imagen}`} className={ButtonIconSizeClass} />
                  <label className='fs-8'>{nombre}</label>
                </div>

              </button>

            </div>


          } title={<Typography color="inherit" style={{ fontWeight: 600 }}>{nombre}</Typography>} ></LightTooltip>


        </Link>)}
    </>
  )
}
export { BotonVolver }
