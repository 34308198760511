/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import { useSelector } from 'react-redux'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'
import { ISTATE } from '../../../../../../../../setup/redux/interface/reduxState.interface'
import { getUsuarios } from '../../../../../Sis-admin/services/UsuarioService'
import { consultaDestinatarios } from '../../../../Redux/services/Agenda_Service'
import TextErrorFormik from '../../../../../../../components/Formik/TextErrorFormik'

type Props = {
  data: any
}
const Step3: FC<Props> = ({ data }) => {
  const dataUsStore: any = useSelector((state: ISTATE) => state.auth)
  const { usuario } = dataUsStore
  const dataAreaStore: any = useSelector((state: ISTATE) => state.areas)
  const { areas } = dataAreaStore
  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)
  const { perfil } = dashState
  const [usuariosTodos, setUsuariosTodos] = useState([])
  const [usuariosSelect, setUsuariosSelect] = useState([])
  const [destinatarioTodos, setDestinatarioTodos] = useState([])
  const [destinatarioSelect, setDestinatarioSelect] = useState([
    { destinatario: '', idDestinatario: '' },
  ])
  const [sinDestinatarioSelect, setSinDestinatarioSelect] = useState([])
  let [check, setCheck] = useState(true)
  let [presCheck, setPresCheck] = useState(false)
  const animatedComponents = makeAnimated()
  let roles = perfil.find((perfil: any) => perfil.nombre === 'agenda')
  let rolUs: any = roles.menu.find((rol: any) => rol.name === 'eventos')
  ////////console.log('roles', roles.menu)
  useEffect(() => {
    data.idUsuarioCarga = usuario.id
    data.usuarioCarga = usuario.nombreCompleto
    data.idUsuarioAsignado = usuario.id
    data.usuarioAsignado = usuario.nombreCompleto
    data.organizacion = usuario.organizacion.datosOrganismo.nombre
    data.idArea = usuario.organizacion.datosOrganismo.areaPrincipal.idAreaP
    data.area =
      usuario.organizacion.datosOrganismo.areaPrincipal.tipoArea +
      ' ' +
      usuario.organizacion.datosOrganismo.areaPrincipal.nombreArea
    data.color =
      usuario.organizacion.datosOrganismo.areaPrincipal.detalleSistema.colorFondo
  }, [])
  let sinDetFOR: any = []

  const areasSelect: any = []
  const usTemp: any = []

  useEffect(() => {
    getUsuarios().then((resp: any) => {
      if (resp.data.ok) {
        let us = resp.data.data

        ////////console.log('rolUs', rolUs)
        if (rolUs.roleUs.admin) {
          for (let u = 0; u < us.length; u++) {
            let usuarioOrg: any = us[u]
            if (usuarioOrg.activo) {
              ////////console.log('usuarioOrg', usuarioOrg)
              usTemp.push({
                value: u.toString(),
                label:
                  usuarioOrg.datosPersonales.apellido + ' ' + usuarioOrg.datosPersonales.nombres,
                idUsuario: usuarioOrg._id,
                area:
                  usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.tipoArea +
                  ' ' +
                  usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.nombreArea,
                idArea: usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.idAreaP,
                color:
                  usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.detalleSistema?.colorFondo,
              })
            }
          }
        } else {
          if (rolUs.roleUs.crear) {
            for (let u = 0; u < us.length; u++) {
              let usuarioOrg: any = us[u]
              ////////console.log('usuarioOrg', usuarioOrg)
              if (
                usuarioOrg.activo &&
                usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.idAreaP ===
                usuario.organizacion.datosOrganismo.areaPrincipal.idAreaP
              ) {
                usTemp.push({
                  value: u.toString(),
                  label:
                    usuarioOrg.datosPersonales.apellido + ' ' + usuarioOrg.datosPersonales.nombres,
                  idUsuario: usuarioOrg._id,
                  area:
                    usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.tipoArea +
                    ' ' +
                    usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.nombreArea,
                  idArea: usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.idAreaP,
                })
              }
            }
          }
        }
      }
    })
    let desTemp: any = []
    consultaDestinatarios().then((res: any) => {
      let des = res.data

      for (let d = 0; d < des.length; d++) {
        const destinatario = des[d]
        if (destinatario.activo) {
          if (destinatario.nombre === 'sin destinatario') {
            let sinDet: any = []
            sinDet.push({
              destinatario: destinatario.nombre,
              idDestinatario: destinatario._id,
            })

            sinDetFOR.push({
              destinatario: destinatario.nombre,
              idDestinatario: destinatario._id,
            })
            data.destinatarios = sinDetFOR
            setDestinatarioSelect(sinDet)
            setSinDestinatarioSelect(sinDet)
          } else {
            desTemp.push({
              value: d.toString(),
              label: destinatario.nombre,
              idDestinatario: destinatario._id,
            })
          }
        }
      }
    })

    setUsuariosTodos(usTemp)
    setUsuariosSelect(usTemp)
    setDestinatarioTodos(desTemp)
    /* return () => {
      usuariosTodos
    } */
  }, [])

  useEffect(() => {
    if (data.area !== '') {
      setUsuariosSelect([])
      filtrarUsuarios(data.area)
    }
  }, [data.area])

  useEffect(() => {
    for (let u = 0; u < usuariosSelect.length; u++) {
      const usr: any = usuariosSelect[u]
      if (usr.label === data.usuarioAsignado) {
        data.idUsuarioAsignado = usr.idUsuario
        data.idArea = usr.idArea
        data.color = usr.color
      }
    }
  }, [data.usuarioAsignado])

  useEffect(() => {
    data.destinatarios = [...destinatarioSelect]
  }, [destinatarioSelect])
  ////////console.log('data.destinatarios', data.destinatarios)
  ////////console.log('destinatarioSelect', destinatarioSelect)

  const filtrarUsuarios = (area: string) => {
    ////////console.log('area', area)
    let usuariosTemp: any = []
    for (let u = 0; u < usuariosTodos.length; u++) {
      const usr: any = usuariosTodos[u]
      ////////console.log('usr', usr)
      if (usr.area === area) {
        usuariosTemp.push(usr)
      }
    }
    setUsuariosSelect(usuariosTemp)
    ////////console.log('usuariosTemp', usuariosTemp)
  }

  for (let p = 0; p < areas.length; p++) {
    let a: any = areas[p]
    ////////console.log('a', a)
    areasSelect.push({ value: p.toString(), label: a.tipoArea + ' ' + a.nombreArea })
  }
  areasSelect.sort(function compare(a: any, b: any) {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    // a debe ser igual b
    return 0
  })

  usuariosSelect.sort(function compare(a: any, b: any) {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    // a debe ser igual b
    return 0
  })

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h3 className='fw-bolder text-dark'>Propietario del Evento</h3>
        <div className=' col-8 mb-2 d-flex align-items-center'>
          <label className='col-form-label fw-bold fs-6 pr-2'>
            El evento es propio o se debe asignar a otro usuario?
          </label>
          <label className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              name='communication[]'
              type='checkbox'
              defaultChecked={check}
              onChange={() => {
                check = !check
                if (check) {
                  data.usuarioAsignado = data.usuarioCarga
                  data.idUsuarioAsignado = data.idUsuarioCarga
                } else {
                  data.area = ''
                  data.idArea = ''
                  data.usuarioAsignado = ''
                  data.idUsuarioAsignado = ''
                }
                setCheck(check)
              }}
            />

            <span className='form-check-label fw-bold 0'>
              {' '}
              {check ? 'Propio' : 'Asignar a otro Usuario'}
            </span>
          </label>
        </div>
        <h3 className='fw-bolder text-dark'>Usuario Asignado</h3>
        {check && (
          <div className='row'>
            <div className='col-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Área</label>
              <Field
                as='input'
                disabled={true}
                name='area'
                className='form-control form-control-solid capital'
                value={
                  usuario.organizacion.datosOrganismo.areaPrincipal.tipoArea +
                  ' ' +
                  usuario.organizacion.datosOrganismo.areaPrincipal.nombreArea
                }
              ></Field>
              <ErrorMessage name='localidad' component={TextErrorFormik} />
            </div>
            <div className='col-6 pb-4'>
              <label className='form-label fw-bolder text-dark fs-6'>Usuario</label>
              <Field
                as='input'
                disabled={true}
                name='usuarioAsignado'
                className='form-control form-control-solid capital'
                value={data.usuarioAsignado}
              ></Field>
              <ErrorMessage name='usuarioAsignado' component={TextErrorFormik} />
            </div>
          </div>
        )}
        {!check && rolUs.roleUs.admin && (
          <div className='row'>
            <div className='col-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Área</label>
              <Field
                as='select'
                disabled={false}
                name='area'
                className='form-control form-control-solid capital'
                value={data.area}
              >
                <option value=''>Seleccione el Área</option>
                {areasSelect?.map((option: any) => {
                  return (
                    <option key={option?.value} value={option?.label}>
                      {option?.label}
                    </option>
                  )
                })}
              </Field>
              <ErrorMessage name='area' component={TextErrorFormik} />
            </div>
            <div className='col-6 pb-4'>
              <label className='form-label fw-bolder text-dark fs-6'>Usuario</label>
              <Field
                as='select'
                /* onChange={() => {
                  cargarLocalidades(data.value)
                  setHabLocalidad(false)
                }} */
                name='usuarioAsignado'
                className='form-control form-control-solid capital'
              >
                <option value=''>Seleccione el Usuario</option>
                {usuariosSelect?.map((option: any) => {
                  return (
                    <option key={option?.value} value={option?.label}>
                      {option?.label}
                    </option>
                  )
                })}
              </Field>
              <ErrorMessage name='usuarioAsignado' component={TextErrorFormik} />
            </div>
          </div>
        )}
        {!check && !rolUs.roleUs.admin && (
          <div className='row'>
            <div className='col-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Área</label>
              <Field
                as='input'
                disabled={false}
                name='area'
                className='form-control form-control-solid capital'
                value={
                  usuario.organizacion.datosOrganismo.areaPrincipal.tipoArea +
                  ' ' +
                  usuario.organizacion.datosOrganismo.areaPrincipal.nombreArea
                }
              ></Field>
              <ErrorMessage name='area' component={TextErrorFormik} />
            </div>
            <div className='col-6 pb-4'>
              <label className='form-label fw-bolder text-dark fs-6'>Usuario</label>
              <Field
                as='select'
                /* onChange={() => {
                  cargarLocalidades(data.value)
                  setHabLocalidad(false)
                }} */
                name='usuarioAsignado'
                className='form-control form-control-solid capital'
              >
                <option value=''>Seleccione el Usuario</option>
                {usuariosSelect?.map((option: any) => {
                  return (
                    <option key={option?.value} value={option?.label}>
                      {option?.label}
                    </option>
                  )
                })}
              </Field>
              <ErrorMessage name='usuarioAsignado' component={TextErrorFormik} />
            </div>
          </div>
        )}
        <h3 className='fw-bolder text-dark'>Evento destinado a:</h3>

        <div className='text-gray-400 fw-bold fs-6'>Seleccione quienes participarán del Evento</div>
        <div className='row'>
          <div className='col 12'>
            <Select
              className='form-label fw-bolder text-dark fs-6'
              closeMenuOnSelect={true}
              placeholder={'Sin Destinatario'}
              components={animatedComponents}
              isMulti
              name='destinatarios'
              options={destinatarioTodos}
              onChange={(e: any) => {
                let arr: any = []
                if (e.length === 0) {
                  ////////console.log('sinDetFOR', sinDetFOR)
                  /* sinDetFOR.push({
                    destinatario: sinDestinatarioSelect.label,
                    idDestinatario: sinDestinatarioSelect._id
                  })
                  data.destinatarios = sinDetFOR */
                  setDestinatarioSelect(sinDestinatarioSelect)
                } else {
                  for (let des of e) {
                    let desTemp: any = {
                      destinatario: des.label,
                      idDestinatario: des.idDestinatario,
                    }
                    arr.push(desTemp)
                  }
                  setDestinatarioSelect(arr)
                }
              }}
            ></Select>
          </div>
        </div>
        <h3 className='fw-bolder text-dark pt-4'>Presencia de Ministro/a</h3>
        <div className=' col-8 mb-2 d-flex align-items-center'>
          <label className='col-form-label fw-bold fs-6 pr-2'>
            Requiere la presencia del/la ministro/a?
          </label>
          <label className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              name='data.presMinistro'
              type='checkbox'
              defaultChecked={presCheck}
              onChange={() => {
                presCheck = !presCheck
                setPresCheck(presCheck)
                data.presMinistro = presCheck
              }}
            />

            <span className='form-check-label fw-bold 0'>
              {' '}
              {presCheck ? 'Se requiere presencia' : 'No se requiere Presencia'}
            </span>
          </label>
        </div>
      </div>
    </div>
  )
}

export { Step3 }
