import React, {FC, useState} from 'react'
import {Formik, Form, FormikValues, Field} from 'formik'
import {useLocation} from 'react-router'
import makeAnimated from 'react-select/animated'
import {Link} from 'react-router-dom'
import {getLayout, ILayout, LayoutSetup, useLayout} from '../../../../../_metronic/layout/core'
import clsx from 'clsx'
import {ICreateAccount} from '../interface/EditNoticaHeper'
import {Inoticia} from '../interface/Inoticia'
import {Editor} from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import {convertToRaw, EditorState} from 'draft-js'
import {prepareDraft} from '../utils/utilsFunctions'
import {useSelector} from 'react-redux'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
import moment from 'moment'
import {patchModNoticia} from '../services/Noticias-Services'
import Select from 'react-select'
import Swal from 'sweetalert2'
moment.locale('es-mx');

const ENoticia: FC = () => {
  let data: any = useLocation()
  const animatedComponents = makeAnimated()
  const [objetPreview] = useState<ICreateAccount>(data.state.objet)
  const {setLayout} = useLayout()
  const [tab, setTab] = useState('Noticia')
  const [config, setConfig] = useState<ILayout>(getLayout())
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  const dashState: any = useSelector((state: ISTATE) => state.auth)
const fechaPublicacion = moment(objetPreview.fecha_publicacion).format('L')
  //Estado del Editor
  const [editorState, setEditorState] = useState(
    objetPreview.cuerpo ? prepareDraft(objetPreview.cuerpo) : EditorState.createEmpty()
  )
  const onEditorStateChange = (editorState: EditorState) => {
    const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    objetPreview.cuerpo = forFormik
    setEditorState(editorState)
  }
  //Submit values editado
  const submitStep = (values: Inoticia, actions: FormikValues) => {
   /*  values.fecha_publicacion = moment(values.fecha_publicacion).format('L') */
    let usuarioEditor = {
      nombre: dashState.usuario.nombreCompleto,
      nombreUs: dashState.usuario.usuario,
      fecha: moment().format('L'),
    }
    values.usuario_editor.push(usuarioEditor)
    patchModNoticia(values).then((res: any) => {
      if (res.data.ok === true) {
        Swal.fire('Se edito la noticia')
        window.history.back()
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'No se pudo editar',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    })
  }

  //Array Areas desde BD
  const areasState: any = useSelector((state: ISTATE) => state.areas)
  const {areas} = areasState
  const AreaArray = areas.map(function (nombre: any) {
    return nombre.detalleSistema.nombreCorto
  })
  let AreaArrayNombres: any = []
  for (let element of AreaArray) {
    let arrAr = {
      value: element,
      label: element,
    }
    AreaArrayNombres.push(arrAr)
  }

  //array tematicas harcodeadas
  const tematicasArray = [
    {value: 'Ministerio', label: 'Ministerio'},
    {
      value: 'Subsecretaría de Niñez y Adolescencia',
      label: 'Subsecretaría de Niñez y Adolescencia',
    },
    {value: 'Subsecretaría de Juventud', label: 'Subsecretaría de Juventud'},
    {value: 'Subsecretaría de Discapacidad', label: 'Subsecretaría de Discapacidad'},
    {
      value: 'Subsecretaría de Ciudades Saludables y Prevención de Consumos Problemáticos',
      label: 'Subsecretaría de Ciudades Saludables y Prevención de Consumos Problemáticos',
    },
    {
      value: 'Subsecretaría de Acceso a la Justicia',
      label: 'Subsecretaría de Acceso a la Justicia',
    },
    {
      value: 'Dirección Provincial de Innovación y Tecnología',
      label: 'Dirección Provincial de Innovación y Tecnología',
    },
    {
      value: 'Cambio Climático',
      label: 'Cambio Climático',
    },
    {
      value: 'Territorio',
      label: 'Territorio',
    },
    {
      value: 'Organizaciones',
      label: 'Organizaciones',
    },
    {
      value: 'Cooperación',
      label: 'Cooperación',
    },
    {
      value: 'Participación Ciudadana',
      label: 'Participación Ciudadana',
    },
    {
      value: 'Empleabilidad',
      label: 'Empleabilidad',
    },
    {
      value: 'Comunicación',
      label: 'Comunicación',
    },
    {
      value: 'Sensibilización',
      label: 'Sensibilización',
    },
    {
      value: 'Construcción Ciudadana',
      label: 'Construcción Ciudadana',
    },
    {
      value: 'Apps',
      label: 'Apps',
    },
    {
      value: 'Descargas',
      label: 'Descargas',
    },
    {
      value: 'Inscripciones',
      label: 'Inscripciones',
    },
    {
      value: 'Certificados',
      label: 'Certificados',
    },
  ]

  const updateConfig = () => {
    setConfigLoading(true)
    try {
      LayoutSetup.setConfig(config)
    } catch (error) {
      setConfig(getLayout())
    }
    setTimeout(() => {
      setLayout(config)
      setConfigLoading(false)
    }, 1000)
  }
//disable prev fechas
const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate() + 1).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};
  return (
    <>
      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link`, {active: tab === 'Noticia'})}
                onClick={() => setTab('Noticia')}
                role='tab'
              >
                Noticia
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link`, {active: tab === 'Cuerpo'})}
                onClick={() => setTab('Cuerpo')}
                role='tab'
              >
                Cuerpo
              </a>
            </li>
          </ul>
        </div>
        {/* end::Header */}

        {/* begin::Form */}
        <Formik initialValues={objetPreview} onSubmit={submitStep}>
          {(Formik) => {
            return (
              <Form>
                {/* begin::Body */}
                <div className='card-body pt-0'>
                  <div className='tab-content pt-3'>
                    <div className={clsx('tab-pane', {active: tab === 'Noticia'})}>
                      <div className='row col-lg-10 offset-lg-1'>
                        <div className='col-lg-6'>
                          <label className='col-form-label text-lg-end'>Titulo:</label>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              type='text'
                              className='form-control form-control-solid'
                              name='titulo'
                            />
                          </label>
                        </div>
                        <div className='col-lg-6 '>
                          <label className='col-form-label'>Area: {objetPreview.nombreArea}</label>

                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Select
                              className='col-lg-12 form-label'
                              name='nombreArea'
                              isMulti={false}
                              options={AreaArrayNombres}
                              closeMenuOnSelect={true}
                              placeholder={'Cambiar Area'}
                              onChange={(e: any) => {
                                objetPreview.nombreArea = e.value
                              }}
                            ></Select>
                          </label>
                        </div>
                      </div>
                      <div className='row col-lg-10 offset-lg-1'>
                        <div className='col-lg-6'>
                          <label className='col-lg-2 col-form-label'>Tematica: </label>
                          {objetPreview.tematicas?.map((tematica: any) => (
                            <h3 className='text-gray-700 fw-bold fs-6'>{tematica}</h3>
                          ))}
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Select
                              className='col-lg-12 form-label'
                              name='tematicas'
                              isMulti
                              options={tematicasArray}
                              components={animatedComponents}
                              closeMenuOnSelect={true}
                              placeholder={'Agregar tematica'}
                              onChange={(e: any) => {
                                let arrayTematicas: any = []
                                for (let tem of e) {
                                  arrayTematicas.push(tem.value)
                                  objetPreview.tematicas = arrayTematicas
                                }
                              }}
                            ></Select>
                          </label>
                        </div>
                        <div className='col-lg-6'>
                          <label className='col-lg-6 col-form-label'>
                            Fecha Publicacion: {fechaPublicacion}
                          </label>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              type='date'
                              className='form-control form-control-solid'
                              name='fecha_publicacion'
                              min={disablePastDate()}
                                />
                          </label>
                        </div>
                      </div>
                      <div className='row col-lg-10 offset-lg-1'>
                        <div className='col-lg-6'>
                          <label className='col-lg-3 col-form-label'>Autor:</label>

                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              type='text'
                              className='form-control form-control-solid'
                              placeholder='Autor'
                              name='datos_autor'
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className={clsx('tab-pane', {active: tab === 'Cuerpo'})}>
                      <div className='row col-lg-10 offset-lg-1'>
                        <div>
                          <label className='col-lg-3 col-form-label'>Bajada</label>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              type='text'
                              className='form-control form-control-solid'
                              name='bajada'
                            />
                          </label>
                        </div>
                      </div>

                      <div className='row col-lg-10 offset-lg-1'>
                        <label className='col-lg-3 col-form-label'>Cuerpo</label>

                        <Field type='input' name='cuerpo'>
                          {() => {
                            return (
                              <Editor
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                                wrapperClassName='custom-wrapper'
                                editorClassName='editorClass'
                              />
                            )
                          }}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end::Body */}

                {/* begin::Footer */}
                <div className='card-footer py-6'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <Link to={'/noticia/noticia'}>
                        <button className='btn btn-primary me-2'>Volver</button>
                      </Link>
                    </div>
                    <div className='col-lg-8'>
                      <button type='submit' onClick={updateConfig} className='btn btn-primary me-2'>
                        {!configLoading && <span className='indicator-label'>Actualizar</span>}
                        {configLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Espere un momento...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {/* end::Footer */}
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export {ENoticia}
