import {lazy} from 'react'
import {Route, Switch, Redirect, useRouteMatch} from 'react-router-dom'
import Respuesta from './components/Respuesta'

const CivisRouter = () => {
  const {url} = useRouteMatch() // el hook  tiene el historial de las rutas previas  '/dashboard/webservice'
  const ProfilePageCivis = lazy(() => import('./pages/ProfilePageCivis'))
  const ConsutlasPage = lazy(() => import('./pages/ConsutlasPage'))

  return (
    <Switch>
      <Route exact path={`${url}/servicios`} component={ConsutlasPage} />
      {/* <Route exact path={`${url}/profileData`} component={ProfilePageCivis} />
      <Route path={`${url}/respuesta`} component={Respuesta} /> */}
      {/* <Route path={`${url}/respuesta`} component={Respuesta} /> Nueva ruta para Respuesta */}

      <Route path='*'>
        <Redirect to='/page404' />
      </Route>
    </Switch>
  )
}

export default CivisRouter
