/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { ISTATE } from '../../../../../../../setup/redux/interface/reduxState.interface'
import TextErrorFormik from '../../../../../../components/Formik/TextErrorFormik'
import { getUsuarios } from '../../../../Sis-admin/services/UsuarioService'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'
import { consultaDestinatarios } from '../../../Redux/services/Agenda_Service'
import { evSetFormulario } from '../../../Redux/actions/eventos'

type Props = {
  data: any
}

const UsuariosEvento: FC<Props> = ({ data }) => {

  const dispatch = useDispatch()
  const dataUsStore: any = useSelector((state: ISTATE) => state.auth)
  const { usuario } = dataUsStore
  const dataAreaStore: any = useSelector((state: ISTATE) => state.areas)
  const { areas } = dataAreaStore
  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)
  const { perfil } = dashState
  const eventosStore: any = useSelector((state: ISTATE) => state.eventos)
  const { acciones, formulario } = eventosStore
  const estado = formulario.estado
  const [usuariosTodos, setUsuariosTodos] = useState([])
  let [usuariosSelect, setUsuariosSelect] = useState([])
  const [habilitarUsuario, setHabilitarUsuario] = useState(false)
  let [presCheck, setPresCheck] = useState(false)
  const [destinatarioTodos, setDestinatarioTodos] = useState([])
  const [destPred, setDestPred] = useState([])
  const [destinatarioSelect, setDestinatarioSelect] = useState<any[]>([
    { destinatario: '', idDestinatario: '' },
  ])
  const [sinDestinatario, setSinDestinatario] = useState<any[]>([
    { destinatario: '', idDestinatario: '' },
  ])
  const [sinDestinatarioSelect, setSinDestinatarioSelect] = useState([])
  let [check, setCheck] = useState(true)
  const animatedComponents = makeAnimated()
  let roles = perfil.find((perfil: any) => perfil.nombre === "agenda")
  let rolUs: any = roles.menu.find((rol: any) => rol.name === "eventos")

  let sinDetFOR: any = []
  let destSel: any = []

  const areasSelect: any = []
  const usTemp: any = []
  //console.log('usuario', usuario)
  useEffect(() => {

    data.presMinistro ? setPresCheck(true) : setPresCheck(false)


  }, [])


  useEffect(() => {
    if (acciones.editar === true) {
      getUsuarios().then((resp: any) => {
        if (resp.data.ok) {
          let us = resp.data.data

          //console.log('rolUs', rolUs)
          if (rolUs.roleUs.admin) {

            for (let u = 0; u < us.length; u++) {
              let usuarioOrg: any = us[u]
              if (usuarioOrg.activo) {
                //////console.log('usuarioOrg', usuarioOrg)
                usTemp.push(
                  {
                    value: u.toString(),
                    label: usuarioOrg.datosPersonales.apellido + " " + usuarioOrg.datosPersonales.nombres,
                    idUsuario: usuarioOrg._id,
                    area: usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.tipoArea + " " + usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.nombreArea,
                    idArea: usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.idAreaP,
                    color: usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.detalleSistema?.colorFondo
                  }
                )
              }
            }
          } else {
            if (rolUs.roleUs.crear) {
              for (let u = 0; u < us.length; u++) {
                let usuarioOrg: any = us[u]
                //////console.log('usuarioOrg', usuarioOrg)
                if (usuarioOrg.activo && usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.idAreaP === usuario.organizacion.datosOrganismo.areaPrincipal.idAreaP) {
                  usTemp.push(
                    {
                      value: u.toString(),
                      label: usuarioOrg.datosPersonales.apellido + " " + usuarioOrg.datosPersonales.nombres,
                      idUsuario: usuarioOrg._id,
                      area: usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.tipoArea + " " + usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.nombreArea,
                      idArea: usuarioOrg.datosLaborales.datosOrganismo.areaPrincipal.idAreaP
                    }
                  )
                }
              }
            }
          }
        }
      })
      let desTemp: any = []
      consultaDestinatarios().then((res: any) => {
        let des = res.data
        //console.log('des', des)
        for (let d = 0; d < des.length; d++) {
          const destinatario = des[d];
          if (destinatario.activo) {
            if (destinatario.nombre === 'sin destinatario') {
              let sinDet: any = []
              sinDet.push({

                destinatario: destinatario.nombre,
                idDestinatario: destinatario._id
              })
              setSinDestinatario(sinDet)
              sinDetFOR.push({
                destinatario: destinatario.nombre,
                idDestinatario: destinatario._id
              })
              /* data.destinatarios = sinDetFOR */
              setDestinatarioSelect(sinDet)
              setSinDestinatarioSelect(sinDet)
            } else {

              desTemp.push(
                {
                  value: d.toString(),
                  label: destinatario.nombre,
                  idDestinatario: destinatario._id
                }
              )
            }
          }
        }

      })

      setUsuariosTodos(usTemp)
      setUsuariosSelect(usTemp)
      setDestinatarioTodos(desTemp)
    }
  }, [acciones.guardar])

  const buscarDestinatarios = (des?: any) => {
    destSel = []
    for (let d = 0; d < formulario.destinatarios.length; d++) {
      const des = formulario.destinatarios[d];
      destSel.push({
        value: des.destinatario,
        label: des.destinatario,
        idDestinatario: des.idDestinatario
      })
    }
    return destSel
  }
  useEffect(() => {
    buscarDestinatarios()
  }, [usuariosSelect])



  useEffect(() => {
    if (acciones.guardar) {
      if (data.area !== '') {
        setUsuariosSelect([])
        //console.log('datas', data)
        filtrarUsuarios(data.area.toLowerCase())
      }
    }
    //console.log('data.area', data.area)
  }, [data.area])

  useEffect(() => {
    if (acciones.guardar) {
      for (let u = 0; u < usuariosSelect.length; u++) {
        const usr: any = usuariosSelect[u];
        if (usr.label === data.usuarioAsignado) {
          data.idUsuarioAsignado = usr.idUsuario
          data.idArea = usr.idArea
          data.color = usr.color
        }
      }
    }
  }, [data.usuarioAsignado])

  useEffect(() => {
    if (acciones.editar) {
      data.destinatarios = [...destPred]
    }
    //console.log('destinatarioSelect', data.destinatarioSelect)
  }, [destinatarioSelect])



  useEffect(() => {
    data.idUsuarioAsignado === usuario.id ? setCheck(true) : setCheck(false)
  }, [acciones.guardar])
  //////console.log('data.destinatarios', data.destinatarios)
  //////console.log('destinatarioSelect', destinatarioSelect)

  const filtrarUsuarios = (area: string) => {
    //console.log('area', area)
    let usuariosTemp: any = []
    //console.log('usuariosTodos', usuariosTodos)
    for (let u = 0; u < usuariosTodos.length; u++) {
      const usr: any = usuariosTodos[u];
      //console.log('usr', usr)
      if (usr.area.toLowerCase() === area) {
        //console.log('usr.area, area', usr.area, area)
        usuariosTemp.push(usr)
      }
    }
    setUsuariosSelect(usuariosTemp)
    //////console.log('usuariosTemp', usuariosTemp)
  }

  for (let p = 0; p < areas.length; p++) {
    let a: any = areas[p]
    //////console.log('a', a)
    areasSelect.push({ value: p.toString(), label: a.tipoArea + " " + a.nombreArea })
  }
  areasSelect.sort(function compare(a: any, b: any) {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    // a debe ser igual b
    return 0
  })

  usuariosSelect.sort(function compare(a: any, b: any) {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    // a debe ser igual b
    return 0
  })

  return (
    <>
      {((acciones.editar || estado === 'cancelado' || estado === 'realizado' || estado === 'en curso' || estado === 'planificado') && !acciones.guardar) && (
        <div className='w-100'>
          <div className='pb-10 pb-lg-12'>
            <h3 className='fw-bolder text-dark'>Propietario del Evento</h3>
            <h3 className='fw-bolder text-dark'>Usuario Asignado</h3>
            <div className="row">
              <div className='col-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Área</label>
                <Field
                  as='input'
                  disabled={true}
                  name='area'
                  className='form-control form-control-solid capital'
                  value={data.area}
                >

                </Field>
                <ErrorMessage name='localidad' component={TextErrorFormik} />
              </div>
              <div className='col-6 pb-4'>
                <label className='form-label fw-bolder text-dark fs-6'>Usuario</label>
                <Field
                  as='input'
                  disabled={true}
                  name='usuarioAsignado'
                  className='form-control form-control-solid capital'
                  value={data.usuarioAsignado}
                >
                </Field>
                <ErrorMessage name='usuarioAsignado' component={TextErrorFormik} />
              </div>
            </div>


            <h3 className='fw-bolder text-dark'>Evento destinado a:</h3>

            <div className='text-gray-400 fw-bold fs-6'>
              Seleccione quienes participarán del Evento
            </div>
            <div className='row'>
              <div className='col 12'>
                <Select
                  className='form-label fw-bolder text-dark fs-6'
                  closeMenuOnSelect={true}
                  isDisabled={true}
                  placeholder={'Sin Destinatario'}
                  components={animatedComponents}
                  isMulti
                  name='destinatarios'
                  defaultValue={buscarDestinatarios()}
                  options={destinatarioTodos}

                ></Select>
              </div>
            </div>
            <h3 className='fw-bolder text-dark pt-4'>Presencia de Ministro/a</h3>
            <div className=' col-8 mb-2 d-flex align-items-center'>
              <label className='col-form-label fw-bold fs-6 pr-2'>
                Requiere la presencia del/la ministro/a?
              </label>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  name='data.presMinistro'
                  type='checkbox'
                  disabled={true}
                  defaultChecked={data.presMinistro}
                  onChange={() => {
                    presCheck = !presCheck
                    setPresCheck(presCheck)
                  }}
                />

                <span className='form-check-label fw-bold 0'>
                  {' '}
                  {presCheck ? 'Se requiere presencia' : 'No se requiere Presencia'}
                </span>
              </label>
            </div>
          </div>

        </div >
      )}
      {acciones.guardar && (
        <div className='w-100'>
          <div className='pb-10 pb-lg-12'>
            <h3 className='fw-bolder text-dark'>Propietario del Evento</h3>
            <div className=" col-8 mb-2 d-flex align-items-center">
              <label className='col-form-label fw-bold fs-6 pr-2'>El evento es propio o se debe asignar a otro usuario?</label>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  name='usuarioPropio'
                  type='checkbox'
                  defaultChecked={check}
                  onChange={() => {
                    check = !check
                    if (check) {
                      data.area = usuario.organizacion.datosOrganismo.areaPrincipal.tipoArea + " " + usuario.organizacion.datosOrganismo.areaPrincipal.nombreArea
                      data.idArea = usuario.organizacion.datosOrganismo.areaPrincipal.idAreaP
                      data.usuarioAsignado = usuario.nombreCompleto
                      data.idUsuarioAsignado = usuario.id
                    } else {
                      data.area = ""
                      data.idArea = ""
                      data.usuarioAsignado = ""
                      data.idUsuarioAsignado = ""
                    }
                    setCheck(check)
                    dispatch(evSetFormulario(data))
                  }}
                />

                <span className='form-check-label fw-bold 0'> {check ? 'Propio' : 'Asignar a otro Usuario'}</span>
              </label>
            </div>
            <h3 className='fw-bolder text-dark'>Usuario Asignado</h3>
            {check && (<div className="row">
              <div className='col-6'>
                <label className='form-label fw-bolder text-dark fs-6'>Área</label>
                <Field
                  as='input'
                  disabled={false}
                  name='area'
                  className='form-control form-control-solid capital'
                  value={usuario.organizacion.datosOrganismo.areaPrincipal.tipoArea + " " + usuario.organizacion.datosOrganismo.areaPrincipal.nombreArea}
                >

                </Field>
                <ErrorMessage name='area' component={TextErrorFormik} />
              </div>
              <div className='col-6 pb-4'>
                <label className='form-label fw-bolder text-dark fs-6'>Usuario</label>
                <Field
                  as='input'
                  disabled={true}
                  name='usuarioAsignado'
                  className='form-control form-control-solid capital'
                  value={data.usuarioAsignado}
                >
                </Field>
                <ErrorMessage name='usuarioAsignado' component={TextErrorFormik} />
              </div>
            </div>)}
            {(!check && rolUs.roleUs.admin) && (
              <div className="row">
                <div className='col-6'>
                  <label className='form-label fw-bolder text-dark fs-6'>Área</label>
                  <Field
                    as='select'
                    disabled={false}
                    name='area'
                    className='form-control form-control-solid capital'
                    value={data.area}
                  >
                    <option value=''>Seleccione el Área</option>
                    {areasSelect?.map((option: any) => {

                      return (
                        <option key={option?.value} value={option?.label}>
                          {option?.label}
                        </option>
                      )

                    })}
                  </Field>
                  <ErrorMessage name='area' component={TextErrorFormik} />
                </div>
                <div className='col-6 pb-4'>
                  <label className='form-label fw-bolder text-dark fs-6'>Usuario</label>
                  <Field
                    as='select'
                    /* onChange={() => {
                      cargarLocalidades(data.value)
                      setHabLocalidad(false)
                    }} */
                    disabled={habilitarUsuario}
                    name='usuarioAsignado'
                    className='form-control form-control-solid capital'
                  >
                    <option value=''>Seleccione el Usuario</option>
                    {usuariosSelect?.map((option: any) => {

                      return (
                        <option key={option?.value} value={option?.label}>
                          {option?.label}
                        </option>
                      )

                    })}
                  </Field>
                  <ErrorMessage name='usuarioAsignado' component={TextErrorFormik} />
                </div>
              </div>)}
            {(!check && !rolUs.roleUs.admin) && (
              <div className="row">
                <div className='col-6'>
                  <label className='form-label fw-bolder text-dark fs-6'>Área</label>
                  <Field
                    as='input'
                    disabled={habilitarUsuario}
                    name='area'
                    className='form-control form-control-solid capital'
                    value={usuario.organizacion.datosOrganismo.areaPrincipal.tipoArea + " " + usuario.organizacion.datosOrganismo.areaPrincipal.nombreArea}
                  >

                  </Field>
                  <ErrorMessage name='area' component={TextErrorFormik} />
                </div>
                <div className='col-6 pb-4'>
                  <label className='form-label fw-bolder text-dark fs-6'>Usuario</label>
                  <Field
                    as='select'
                    /* onChange={() => {
                      cargarLocalidades(data.value)
                      setHabLocalidad(false)
                    }} */
                    name='usuarioAsignado'
                    className='form-control form-control-solid capital'
                  >
                    <option value={''}>Seleccione el Usuario</option>
                    {usuariosSelect?.map((option: any) => {

                      return (
                        <option key={option?.value} value={option?.label}>
                          {option?.label}
                        </option>
                      )

                    })}
                  </Field>
                  <ErrorMessage name='usuarioAsignado' component={TextErrorFormik} />
                </div>
              </div>)}
            <h3 className='fw-bolder text-dark'>Evento destinado a:</h3>

            <div className='text-gray-400 fw-bold fs-6'>
              Seleccione quienes participarán del Evento
            </div>
            <div className='row'>
              <div className='col 12'>
                <Select
                  className='form-label fw-bolder text-dark fs-6'
                  closeMenuOnSelect={true}
                  placeholder={'Sin Destinatario'}
                  components={animatedComponents}
                  isMulti
                  name='destinatarios'
                  defaultValue={buscarDestinatarios()}
                  options={destinatarioTodos}
                  onChange={(e: any) => {
                    let arr: any = []
                    //console.log('e.length', e.length)
                    if (e.length === 0) {
                      //console.log('destinatarioTodos', sinDestinatario)
                      setDestinatarioSelect(sinDestinatario)
                      arr.push(sinDestinatario)
                    } else {
                      for (let des of e) {

                        let desTemp: any = {
                          destinatario: des.label,
                          idDestinatario: des.idDestinatario
                        }
                        arr.push(desTemp)
                      }
                      setDestinatarioSelect(arr)
                    }
                    data.destinatarios = arr
                    dispatch(evSetFormulario(data))
                  }}
                ></Select>
              </div>
            </div>
            <h3 className='fw-bolder text-dark pt-4'>Presencia de Ministro/a</h3>
            <div className=' col-8 mb-2 d-flex align-items-center'>
              <label className='col-form-label fw-bold fs-6 pr-2'>
                Requiere la presencia del/la ministro/a?
              </label>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  name='data.presMinistro'
                  type='checkbox'
                  defaultChecked={presCheck}
                  onChange={() => {
                    presCheck = !presCheck
                    setPresCheck(presCheck)
                    data.presMinistro = presCheck

                  }}
                />

                <span className='form-check-label fw-bold 0'>
                  {' '}
                  {presCheck ? 'Se requiere presencia' : 'No se requiere Presencia'}
                </span>
              </label>
            </div>
          </div>


        </div >
      )}
    </>
  )
}

export { UsuariosEvento }
