import React, {useEffect, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import Swal from 'sweetalert2'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
import {getDocMesxMes, modEstadoArchivo, ratificarArchivos} from '../services/EJ.Services'

interface Iprops {
  archivos?: any
  empresa: any
}

export const AbmArchivosEmpresas: React.FC<Iprops> = ({archivos, empresa}) => {
  const [usrEmp, setUsrEmp] = useState<boolean>(false)
  const [documentos, setDocumentos] = useState<any>()
  const [resApiDoc, setResApiDoc] = useState<boolean>(false)
  const authState: any = useSelector((state: ISTATE) => state.auth)
  let {usuario} = authState

  const verPDF = (doc: any) => {
    getDocMesxMes(doc).then((res: any) => {
      const url = window.URL.createObjectURL(res.data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
    })
  }

  const opcRatificar = async (doc: any) => {
    let value: any = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Mensaje para el empleador.',
      inputPlaceholder: 'Escriba su argumentación en este espacio.',
      inputAttributes: {
        'aria-label': 'Escriba su mensaje aquí.',
      },
      width: '800px',
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cerrar',
      showCancelButton: true,
    })
    if (value.value === '') {
      await Swal.fire({toast: true, text: `Es necesario completar este formulario.`, timer: 2000})
      opcRatificar(doc)
    } else {
      setResApiDoc(false)
      modEstadoArchivo(empresa, 'ratificar', doc, value.value).then(async (res: any) => {
        setDocumentos(res.data.archivos)
        setResApiDoc(true)
        await Swal.fire(
          'Documento modificado',
          'El estado fue correctamente modificado.',
          'warning'
        )
      })
    }
  }

  const selectOpBtn = (doc: any) => {
    Swal.fire({
      title: 'Seleccione una opción',
      input: 'select',
      inputOptions: {
        validado: 'Validado',
        ratificar: 'Ratificar',
      },
      inputPlaceholder: 'Seleccione una opción',
      confirmButtonText: 'Cambiar',
      cancelButtonText: 'Cerrar',
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === 'ratificar') {
            opcRatificar(doc)
            resolve('')
          }
          if (value === 'validado') {
            setResApiDoc(false)
            modEstadoArchivo(empresa, value, doc).then(async (res: any) => {
              setDocumentos(res.data.archivos)
              setResApiDoc(true)
              //  console.log(res)
              await Swal.fire(
                'Documento  Validado.',
                'La modificación del estado fue correcta',
                'success'
              )
              resolve('')
            })
          } else {
            resolve('Es necesario seleccionar una opción')
          }
        })
      },
    })
  }

  const reenviarFile = async (doc?: any) => {
    //  console.log(doc)
    const {value: formValues} = await Swal.fire({
      title: 'Seleccione el archivo que desea reenviar.',
      html: '<input type="file" accept=".pdf" id="pdfUpload" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">',

      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cerrar',
      showCancelButton: true,
      width: '600px',
      preConfirm: async (e: any) => {
        if ((document.getElementById('pdfUpload') as HTMLInputElement)!.value === '') {
          await Swal.fire({
            toast: true,
            text: `Es necesario seleccionar un archivo.`,
            timer: 2000,
            showConfirmButton: false,
          })
          reenviarFile(doc)
          return ''
        } else {
          return (document.getElementById('pdfUpload') as HTMLInputElement)!.files
        }
      },
    })

    if (formValues) {
      if (formValues[0]!.size > 2000000) {
        await Swal.fire({
          icon: 'info',
          title: 'El tamaño del archivo excede el limite permitido, 2 MB.',
          timer: 3000,
        })
        reenviarFile(doc)
      } else {
        
        ratificarArchivos(formValues[0], doc.tipo_doc, doc.id_db_fileEj, usrEmp?usuario.usuario:empresa).then(
          async (res: any) => {
            //  console.log(res)
            if (res.data.ok) {
              await Swal.fire(
                'Documento Modificado',
                'El documento fue enviado correctamente, un administrador validara su información.',
                'warning'
              )
            }
          }
        )
      }
    }
  }

  const tooltip = (titulo: string) => {
    return (
      <Tooltip id='tooltip'>
        <strong>{titulo}</strong>
      </Tooltip>
    )
  }

  useEffect(() => {
    !resApiDoc && setDocumentos(archivos)

    usuario.usuario.length > 8 && setUsrEmp(true)
    return () => {}
  }, [resApiDoc, archivos, usuario])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='tab-content'>
        {/* begin::Tap pane */}
        <div className='tab-pane fade show active' id='kt_table_widget_8_tab_1'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr>
                  <th className='p-0 w-50px'></th>
                  <th className='p-0 min-w-100px'></th>
                  <th className='p-0 min-w-50px'></th>
                  <th className='p-0 min-w-50px'></th>
                  <th className='p-0 min-w-50px'></th>
                  <th className='p-0 min-w-120px'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {documentos?.length &&
                  documentos.map((doc: any, index: number) => {
                    // console.log(doc)
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            <div className='symbol symbol-50px me-2'>
                              <span className='symbol-label bg-light-info'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='16'
                                  height='16'
                                  fill='currentColor'
                                  className='bi bi-filetype-pdf'
                                  viewBox='0 0 16 16'
                                >
                                  <path
                                    fill-rule='evenodd'
                                    d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z'
                                  />
                                </svg>
                              </span>
                            </div>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
                            >
                              {doc.tipo_doc === 'ConstanciaAfip' && 'Constancia de Afip'}
                              {doc.tipo_doc === 'ConstanciaInsRentas' &&
                                'Constancia Inscripción Rentas'}
                              {doc.tipo_doc === 'CertPyme' && 'Certificado Centro Pyme'}
                              {doc.tipo_doc === 'ConstanciaEmpTrabajo' && 'Constancia Trabajo'}
                              {doc.tipo_doc === 'LibreDeudaFiscal' && 'Libre deuda Fiscal'}
                              {doc.tipo_doc === 'DDJJEmpresa' && 'DDJJ firmada por el apoderado.'}
                              {doc.tipo_doc === 'PoderEmpresa' && 'Poder Empresa'}
                            </a>
                            {/*  <span className='text-muted fw-bold d-block fs-7 capital'>
                          {doc.tipo_doc} / {meses.mes}
                        </span> */}
                          </td>
                          <td className='text-end'>
                            {doc.estado === 'presentado' && (
                              <span className='badge badge-light-warning'>Presentado</span>
                            )}
                            {doc.estado === 'ratificar' && (
                              <span className='badge badge-light-warning'>Ratificar</span>
                            )}
                            {doc.estado === 'validado' && (
                              <span className='badge badge-light-success'>Validado</span>
                            )}
                            {doc.estado === 'rechazado' && (
                              <span className='badge badge-light-danger'>Rechazado</span>
                            )}
                          </td>
                          <td className='text-end'>
                            <span className='text-dark fw-bolder d-block fs-7'>
                              {doc.fecha_carga}
                            </span>
                            <span className='text-muted fw-bold d-block fs-8'>
                              Fecha de presentación
                            </span>
                          </td>
                          <td className='text-end'>
                            <span className='text-dark fw-bolder d-block fs-7'>
                              {doc.fecha_modf}
                            </span>
                            <span className='text-muted fw-bold d-block fs-8'>
                              Fecha de Modificación
                            </span>
                          </td>
                          <td className='text-end'>
                            {doc.estado === 'rechazado' && (
                              <OverlayTrigger placement='top' overlay={tooltip('Ver observación')}>
                                <div
                                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  onClick={() => {
                                    Swal.fire({html: `${doc.observacion}`})
                                  }}
                                >
                                  <i className='bi bi-info fs-5'></i>
                                </div>
                              </OverlayTrigger>
                            )}
                            {doc.id_db_fileEj !== '' && (
                              <OverlayTrigger placement='top' overlay={tooltip('Ver Documento')}>
                                <div
                                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  onClick={() => {
                                    verPDF(doc.id_db_fileEj)
                                  }}
                                >
                                  <i className='bi bi-eye fs-5'></i>
                                </div>
                              </OverlayTrigger>
                            )}
                            {!usrEmp && doc.id_db_fileEj !== '' && (
                              <OverlayTrigger placement='top' overlay={tooltip('Mod.Estado')}>
                                <div
                                  className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                                  onClick={() => {
                                    selectOpBtn(doc)
                                  }}
                                >
                                  <i className='bi bi-pen fs-5'></i>
                                </div>
                              </OverlayTrigger>
                            )}

                            {doc.estado === 'ratificar'  && (
                              <OverlayTrigger
                                placement='top'
                                overlay={tooltip('Reenviar documentación')}
                              >
                                <div
                                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                  onClick={() => {
                                    reenviarFile(doc)
                                  }}
                                >
                                  <i className='bi bi-upload fs-5'></i>
                                </div>
                              </OverlayTrigger>
                            )}
                          </td>
                        </tr>
                      </>
                    )
                  })}
              </tbody>
              {/* end::Table body */}
            </table>
          </div>
          {/* end::Table */}
        </div>
      </div>
    </div>
  )
}
