/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useEffect, useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import { useDispatch } from 'react-redux'
import es from 'date-fns/locale/es'
//import DatePicker from '@mui/lab/DatePicker'
import Box from '@mui/material/Box'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/locale/es'
import { evSetDias, evSetFormulario } from '../../../../Redux/actions/eventos'
import TextErrorFormik from '../../../../../../../components/Formik/TextErrorFormik'
import CustomInput from '../../../../components/PickersCustomInput'
moment.locale('es')
type Props = {
  data: any
}

const initHoraDia = {
  lunIni: '',
  lunFin: '',
  marIni: '',
  marFin: '',
  mieIni: '',
  mieFin: '',
  jueIni: '',
  jueFin: '',
  vieIni: '',
  vieFin: '',
  sabIni: '',
  sabFin: '',
  domIni: '',
  domFin: '',
}
const initHabilitar = {
  lun: false,
  mar: false,
  mie: false,
  jue: false,
  vie: false,
  sab: false,
  dom: false,
}
const initCheck = {
  lun: false,
  mar: false,
  mie: false,
  jue: false,
  vie: false,
  sab: false,
  dom: false,
}

const Step2: FC<Props> = ({ data }) => {
  const dispatch = useDispatch()
  let hoy = new Date()
  let horaHoy = new Date(moment().format())
  let [fechaInicio, setFechaInicio] = useState<Date>(new Date())
  let [fechaFin, setFechaFin] = useState<Date>(new Date())
  let [horaInicio, setHoraInicio] = useState<any>(hoy)
  let [horaFin, setHoraFin] = useState<any>(hoy)
  let [disBoton, setDisBoton] = useState(true)
  let [disDisFechaFin, setDisFechaFin] = useState(true)
  let [disHoraFin, setDisHoraFin] = useState(true)
  let [duracion, setDuacion] = useState('diario')
  let [dataTemp, setTDataTemp] = useState({
    inicio: '',
    fin: '',
  })
  let [check, setCheck] = useState(true)
  let [mostrar, setMostrar] = useState(false)
  let [checkDia, setCheckDia] = useState(initCheck)
  let [checkHabDia, setCheckHabDia] = useState(initHabilitar)
  let [habilitarFechaFin, setHabilitarFechaFin] = useState(initHabilitar)
  let [horaDia, setHoraDia] = useState<any>(initHoraDia)
  let [listaDias, setListaDias] = useState<any>([])
  let [listaDiasFinal, setListaDiasFinal] = useState<any>([])
  ////console.log('dias', data)

  function cargarDias() {
    let temp: any = []
    listaDias.map((dia: any) => {
      if (check) {
        //console.log('Entro a check')
        dia = {
          ...dia,
          horaInicio: moment(horaInicio).format('HH:mm'),
          horaFin: moment(horaFin).format('HH:mm'),
        }
        {
          dia.dia === 'lunes' && checkDia.lun && temp.push(dia)
        }
        {
          dia.dia === 'martes' && checkDia.mar && temp.push(dia)
        }
        {
          dia.dia === 'miércoles' && checkDia.mie && temp.push(dia)
        }
        {
          dia.dia === 'jueves' && checkDia.jue && temp.push(dia)
        }
        {
          dia.dia === 'viernes' && checkDia.vie && temp.push(dia)
        }
        {
          dia.dia === 'sábado' && checkDia.sab && temp.push(dia)
        }
        {
          dia.dia === 'domingo' && checkDia.dom && temp.push(dia)
        }
      } else {
        if (dia.dia === 'lunes' && checkDia.lun) {
          dia = {
            ...dia,
            horaInicio: moment(horaDia.lunIni).format('HH:mm').toString(),
            horaFin: moment(horaDia.lunFin).format('HH:mm').toString(),
          }
          temp.push(dia)
        }
        if (dia.dia === 'martes' && checkDia.mar) {
          dia = {
            ...dia,
            horaInicio: moment(horaDia.marIni).format('HH:mm').toString(),
            horaFin: moment(horaDia.marFin).format('HH:mm').toString(),
          }
          temp.push(dia)
        }
        if (dia.dia === 'miércoles' && checkDia.mie) {
          dia = {
            ...dia,
            horaInicio: moment(horaDia.mieIni).format('HH:mm').toString(),
            horaFin: moment(horaDia.mieFin).format('HH:mm').toString(),
          }
          temp.push(dia)
        }
        if (dia.dia === 'jueves' && checkDia.jue) {
          dia = {
            ...dia,
            horaInicio: moment(horaDia.jueIni).format('HH:mm').toString(),
            horaFin: moment(horaDia.jueFin).format('HH:mm').toString(),
          }
          temp.push(dia)
        }
        if (dia.dia === 'viernes' && checkDia.vie) {
          dia = {
            ...dia,
            horaInicio: moment(horaDia.vieIni).format('HH:mm').toString(),
            horaFin: moment(horaDia.vieFin).format('HH:mm').toString(),
          }
          temp.push(dia)
        }
        if (dia.dia === 'sábado' && checkDia.sab) {
          dia = {
            ...dia,
            horaInicio: moment(horaDia.sabIni).format('HH:mm').toString(),
            horaFin: moment(horaDia.sabFin).format('HH:mm').toString(),
          }
          temp.push(dia)
        }
        if (dia.dia === 'domingo' && checkDia.dom) {
          dia = {
            ...dia,
            horaInicio: moment(horaDia.domIni).format('HH:mm').toString(),
            horaFin: moment(horaDia.domFin).format('HH:mm').toString(),
          }
          temp.push(dia)
        }
      }
    })
    //console.log('Lista de Dias', temp)
    return temp
  }

  const cargarValores = (tipoFecha: any, fecha: any) => {
    if (tipoFecha === 'inicio') {

      data.start = fecha
      dispatch(evSetFormulario(data))
    }
    if (tipoFecha === 'fin') {

      data.end = fecha
      dispatch(evSetFormulario(data))
    }
    /* if (tipoFecha === 'temporal') {
      let temp = []
      let diasTemp = cargarDias()
      for (let d = 0; d < diasTemp.length; d++) {
        const e = diasTemp[d]
        let datetimeInicio = new Date(
          moment(e.fecha + ' ' + e.horaInicio, 'DD/MM/YYYY HH:mm').format()
        )
        let datetimeFin = new Date(moment(e.fecha + ' ' + e.horaFin, 'DD/MM/YYYY HH:mm').format())
        temp.push({
          inicio: datetimeInicio,
          fin: datetimeFin,
        })
      }
      data.tiempo = temp
      dispatch(evSetFormulario(data))
    } */
  }

  const limpiarDias = () => {
    horaDia = initHoraDia
    setHoraDia(horaDia)
    setHabilitarFechaFin(initHabilitar)
  }
  const inicializarDias = () => {
    listaDias = []
    let diasDif: any = fechaFin
    diasDif = moment(diasDif)
    diasDif = diasDif.diff(fechaInicio, 'days')
    if (diasDif === 0) {
      let temp = {
        fecha: moment(fechaInicio).format('DD/MM/YYYY'),
        dia: moment(fechaInicio).format('dddd'),
        horaInicio: '',
        horaFin: '',
      }
      listaDias.push(temp)
    } else {
      for (let d = 0; d <= diasDif; d++) {
        let temp = {
          fecha: moment(fechaInicio).add(d, 'days').format('DD/MM/YYYY'),
          dia: moment(fechaInicio).add(d, 'days').format('dddd'),
          horaInicio: '',
          horaFin: '',
        }
        listaDias.push(temp)
      }
    }

    if (diasDif > 6) {
      checkHabDia = {
        ...checkHabDia,
        lun: true,
        mar: true,
        mie: true,
        jue: true,
        vie: true,
        sab: true,
        dom: true,
      }
    } else {
      checkHabDia = {
        ...checkHabDia,
        lun: false,
        mar: false,
        mie: false,
        jue: false,
        vie: false,
        sab: false,
        dom: false,
      }
      setCheckHabDia(checkHabDia)

      for (let d = 0; d < diasDif; d++) {
        switch (listaDias[d].dia) {
          case 'lunes':
            checkHabDia = {
              ...checkHabDia,
              lun: true,
            }
            break
          case 'martes':
            checkHabDia = {
              ...checkHabDia,
              mar: true,
            }
            break
          case 'miércoles':
            checkHabDia = {
              ...checkHabDia,
              mie: true,
            }
            break
          case 'jueves':
            checkHabDia = {
              ...checkHabDia,
              jue: true,
            }
            break
          case 'viernes':
            checkHabDia = {
              ...checkHabDia,
              vie: true,
            }
            break
          case 'sábado':
            checkHabDia = {
              ...checkHabDia,
              sab: true,
            }
            break
          case 'domingo':
            checkHabDia = {
              ...checkHabDia,
              dom: true,
            }
            break

          default:
            break
        }
      }
    }

    setListaDias(listaDias)
    setCheckHabDia(checkHabDia)
  }

  //let fechaFin: any = new Date()
  useEffect(() => {
    let fechaInicioTemp = new Date()
    /* data.tiempo = [] */
    data.start = ""
    data.end = ""
    setFechaInicio(fechaInicioTemp)
    setFechaFin(fechaInicioTemp)
  }, [data.duracion])

  useEffect(() => {
    setFechaFin(fechaInicio)
  }, [fechaInicio])

  useEffect(() => {
    if (duracion === 'temporal') {
      inicializarDias()
      limpiarDias()
    }
  }, [fechaFin])

  useEffect(() => {
    setCheck(check)
    if (check) {
      data.horariosDif = false
    } else {
      data.horariosDif = true
    }
  }, [check])

  useEffect(() => {
    cargarDias()
  }, [checkDia])

  useEffect(() => {
    cargarDias()
    cargarValores('temporal', '')
  }, [horaFin])

  useEffect(() => {
    if (duracion === 'temporal') {
      let diasDif: any = fechaFin
      diasDif = moment(diasDif)
      diasDif = diasDif.diff(fechaInicio, 'days')
      if (diasDif >= 6) {
        checkHabDia = {
          ...checkHabDia,
          lun: true,
          mar: true,
          mie: true,
          jue: true,
          vie: true,
          sab: true,
          dom: true,
        }
      } else {
        for (let d = 0; d <= diasDif; d++) {
          switch (listaDias[d].dia) {
            case 'lunes':
              checkHabDia = {
                ...checkHabDia,
                lun: true,
              }
              break
            case 'martes':
              checkHabDia = {
                ...checkHabDia,
                mar: true,
              }
              break
            case 'miércoles':
              checkHabDia = {
                ...checkHabDia,
                mie: true,
              }
              break
            case 'jueves':
              checkHabDia = {
                ...checkHabDia,
                jue: true,
              }
              break
            case 'viernes':
              checkHabDia = {
                ...checkHabDia,
                vie: true,
              }
              break
            case 'sábado':
              checkHabDia = {
                ...checkHabDia,
                sab: true,
              }
              break
            case 'domingo':
              checkHabDia = {
                ...checkHabDia,
                dom: true,
              }
              break

            default:
              break
          }
        }
      }

      setCheckHabDia(checkHabDia)

      cargarDias()
    }
  }, [fechaFin])

  useEffect(() => {
    setHoraDia(horaDia)
    cargarDias()
  }, [horaDia])
  useEffect(() => {
    cargarDias()
  }, [horaFin])
  useEffect(() => {
    dispatch(evSetDias(checkDia))
    cargarValores('temporal', '')
  }, [checkDia])
  useEffect(() => {
    setMostrar(false)
  }, [duracion])

  return (
    <div className='w-100'>
      <div className='pb-2 pb-lg-5'>
        <h3 className='fw-bolder text-dark'>Duración del Evento</h3>

        <div className='text-gray-400 fw-bold fs-6'>Seleccione la duración del evento.</div>
      </div>

      <div className='mb-5 fv-row'>
        <label className='d-flex align-items-center form-label mb-3'>
          Especifique el tipo de Duración
        </label>

        <div className='row mb-4' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='duracion'
              value='diario'
              id='duracion_select_1'
              onClick={() => {
                setDuacion('diario')
                setMostrar(false)
                checkHabDia = {
                  ...checkHabDia,
                  lun: false,
                  mar: false,
                  mie: false,
                  jue: false,
                  vie: false,
                  sab: false,
                  dom: false,
                }
                setCheck(true)
                setMostrar(!mostrar)
                setCheckHabDia(initHabilitar)
                setHabilitarFechaFin(initHabilitar)
              }}
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
              htmlFor='duracion_select_1'
            >
              <span className='fw-bolder fs-6'>Solo un día</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='duracion'
              value='varios'
              id='duracion_select_2'
              onClick={() => {
                setDuacion('varios')
                setMostrar(!mostrar)
                setCheckHabDia(initHabilitar)
                setHabilitarFechaFin(initHabilitar)
              }}
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
              htmlFor='duracion_select_2'
            >
              <span className='fw-bolder fs-6'>Varios días</span>
            </label>
          </div>
          {/* <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='duracion'
              value='temporal'
              id='duracion_select_3'
              onClick={() => {
                setDuacion('temporal')
              }}
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
              htmlFor='duracion_select_3'
            >
              <span className='fw-bolder fs-6'>Temporal en varios días</span>
            </label>
          </div> */}
          <ErrorMessage name='duracion' component={TextErrorFormik} />
        </div>
        {data.duracion === 'diario' && (
          <div className='row mb-4 pt-2' data-kt-buttons='true'>
            <div className='col-3'>
              <Box>
                <DatePicker
                  locale={es}
                  showTimeSelect
                  timeFormat='HH:mm'
                  timeIntervals={15}
                  minDate={hoy}
                  minTime={moment(fechaInicio).format("DD/MM/YY").toString() === moment(new Date()).format("DD/MM/YY").toString() ? fechaInicio : new Date(new Date().setHours(0, 0, 0, 0))}
                  maxTime={new Date(moment(fechaInicio).endOf('day').toString())}
                  selected={fechaInicio}
                  id='date-time-picker-inicio'
                  timeCaption='Hora'
                  dateFormat='dd/MM/yyyy HH:mm aa'
                  name='start'
                  onChange={(date: any) => {
                    setFechaInicio(date)
                    setDisBoton(false)
                    cargarValores('inicio', date)
                  }}
                  customInput={<CustomInput label='Fecha y Hora de Inicio' />}
                />
              </Box>

              <ErrorMessage name={'start'} component={TextErrorFormik} />
            </div>
            <div className='col-3'>
              <Box>
                <DatePicker
                  locale={es}
                  showTimeSelect
                  timeFormat='HH:mm'
                  timeIntervals={15}
                  minDate={fechaInicio}
                  maxDate={fechaInicio}
                  minTime={fechaInicio}
                  maxTime={new Date(moment(fechaInicio).endOf('day').toString())}
                  selected={fechaFin}
                  id='date-time-picker-fin'
                  name='end'
                  timeCaption='Hora'
                  dateFormat='dd/MM/yyyy HH:mm aa'
                  onChange={(date: any) => {
                    setFechaFin(date)
                    cargarValores('fin', date)
                  }}
                  disabled={disBoton}
                  customInput={<CustomInput label='Fecha y Hora de Finalización' />}
                />
              </Box>
              <ErrorMessage name={'end'} component={TextErrorFormik} />
            </div>
          </div>
        )}
        {data.duracion === 'varios' && (
          <div className='row mb-4' data-kt-buttons='true'>
            <div className='col-3'>
              <Box>
                <DatePicker
                  locale={es}
                  showTimeSelect
                  timeFormat='HH:mm'
                  timeIntervals={15}
                  minDate={fechaInicio}
                  minTime={moment(fechaInicio).format("DD/MM/YY").toString() === moment(new Date()).format("DD/MM/YY").toString() ? fechaInicio : new Date(new Date().setHours(0, 0, 0, 0))}
                  maxTime={new Date(moment(fechaInicio).endOf('day').toString())}
                  selected={fechaInicio}
                  id='date-time-picker-inicio'
                  timeCaption='Hora'
                  dateFormat='dd/MM/yyyy HH:mm aa'
                  name='start'
                  onChange={(date: any) => {
                    setFechaInicio(date)
                    setDisBoton(false)
                    cargarValores('inicio', date)
                  }}
                  customInput={<CustomInput label='Fecha y Hora de Inicio' />}
                />
              </Box>
              <ErrorMessage name={'start'} component={TextErrorFormik} />
            </div>
            <div className='col-3'>
              <Box>
                <DatePicker
                  locale={es}
                  showTimeSelect
                  timeFormat='HH:mm'
                  timeIntervals={15}
                  minDate={new Date(moment(fechaInicio, 'DD-MM-YYYY').add(1, 'days').toString())}
                  selected={fechaFin}
                  id='date-time-picker-inicio'
                  timeCaption='Hora'
                  dateFormat='dd/MM/yyyy HH:mm aa'
                  name='start'
                  onChange={(date: any) => {
                    setFechaFin(date)
                    cargarValores('fin', date)
                  }}
                  customInput={<CustomInput label='Fecha y Hora de Finalización' />}
                />
              </Box>
              <ErrorMessage name={'start'} component={TextErrorFormik} />
            </div>
          </div>
        )}
        {/*  {data.duracion === 'temporal' && (
          <div>
            <div className='row mb-4 pt-2' data-kt-buttons='true'>
              <div className='col-3'>
                <Box>
                  <DatePicker
                    locale={es}
                    timeFormat='HH:mm'
                    timeIntervals={15}
                    minDate={fechaInicio}
                    selected={fechaInicio}
                    id='date-picker-inicio'
                    dateFormat='dd/MM/yyyy HH:mm aa'
                    name='start'
                    onChange={(date: any) => {
                      setFechaInicio(date)
                      setDisFechaFin(false)
                      setMostrar(!mostrar)
                    }}
                    customInput={<CustomInput label='Fecha de Inicio' />}
                  />

                </Box>

                <ErrorMessage name={'start'} component={TextErrorFormik} />
              </div>
              <div className='col-3'>
                <Box>
                  <DatePicker
                    locale={es}
                    minDate={new Date(moment(fechaInicio, 'DD-MM-YYYY').add(1, 'days').toString())}
                    selected={fechaFin}
                    id='date-picker-fin'
                    dateFormat='dd/MM/yyyy'
                    name='start'
                    onChange={(date: any) => {
                      setFechaFin(date)
                      inicializarDias()
                    }}
                    disabled={disDisFechaFin}
                    customInput={<CustomInput label='Fecha de Finalizacion' />}
                  />
                </Box>

              </div>
              <div className=' col-5 mb-2 d-flex align-items-center'>
                <label className='col col-form-label fw-bold fs-6'>
                  Los horarios son los mismos para todos los dias?
                </label>
                <label className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    name='communication[]'
                    type='checkbox'
                    defaultChecked={check}
                    onChange={() => {
                      check = !check
                      setCheck(check)
                      check && limpiarDias()
                    }}
                  />
                  <span className='form-check-label fw-bold text-gray-400'>
                    {check ? 'Si' : 'No'}
                  </span>
                </label>
              </div>
            </div>
            {check && (
              <div className='row' data-kt-buttons='true'>
                <div className='col-3'>

                  <Box>
                    <DatePicker
                      locale={es}
                      showTimeSelect
                      selected={horaInicio}
                      timeIntervals={15}
                      showTimeSelectOnly
                      dateFormat='h:mm aa'
                      id='time-only-picker'
                      name='honaInicio'
                      timeCaption='Hora'
                      onChange={(date: any) => {
                        //console.log('date', date)
                        setHoraInicio(new Date(date))
                        setDisHoraFin(false)
                        cargarValores('temporal', date)
                      }}
                      customInput={<CustomInput label='Hora de Inicio' />}
                    />
                  </Box>
                </div>
                <div className='col-3'>
                  <Box>
                    <DatePicker
                      locale={es}
                      showTimeSelect
                      selected={horaFin}
                      timeIntervals={15}
                      showTimeSelectOnly
                      dateFormat='h:mm aa'
                      name='horaFin'
                      id='time-only-picker'
                      timeCaption='Hora'
                      onChange={(date: any) => {
                        //console.log('date', date)
                        setHoraFin(date)
                        cargarValores('temporal', date)
                      }}
                      disabled={disHoraFin}
                      customInput={<CustomInput label='Hora de Finalización' />}
                    />
                  </Box>

                </div>
              </div>
            )}
            {mostrar && (
              <div className='row mb-4' data-kt-buttons='true'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Selecciones los dias</label>
                <div className='row mb-6'>
                  {checkHabDia.lun && (
                    <div className='fv-col'>
                      <div className='d-flex align-items-center mt-3'>
                        <div className='col-2'>
                          <label className='form-check form-switch form-check-custom form-check-solid pr-4'>
                            <input
                              className='form-check-input'
                              name='communication[]'
                              type='checkbox'
                              defaultChecked={checkDia.lun}
                              onChange={() => {
                                checkDia = {
                                  ...checkDia,
                                  lun: !checkDia.lun,
                                }
                                setCheckDia(checkDia)
                                dispatch(evSetDias(checkDia))
                              }}
                            />
                            <span className='fw-bold ps-2 fs-6'>Lunes</span>
                          </label>
                        </div>
                        {checkDia.lun && !check && (
                          <>
                            <div className='col-2'>
                              <Box>
                                <DatePicker
                                  locale={es}
                                  showTimeSelect
                                  selected={horaDia.lunIni !== '' ? horaDia.lunIni : horaHoy}
                                  timeIntervals={15}
                                  showTimeSelectOnly
                                  dateFormat='h:mm aa'
                                  name='horaIniLun'
                                  id='time-only-picker'
                                  timeCaption='Hora'
                                  value={horaDia.lunIni !== '' ? horaDia.lunIni : horaHoy}
                                  onChange={(hora: any) => {
                                    horaDia = {
                                      ...horaDia,
                                      lunIni: hora,
                                    }
                                    setHoraDia(horaDia)
                                    !habilitarFechaFin.lun &&
                                      (habilitarFechaFin = {
                                        ...habilitarFechaFin,
                                        lun: !habilitarFechaFin.lun,
                                      })
                                    setHabilitarFechaFin(habilitarFechaFin)
                                    cargarValores('temporal', hora)
                                  }}
                                  customInput={<CustomInput label='Hora de Inicio' />}
                                />
                              </Box>
                            </div>
                            <div className='col-2 pl-3'>
                              <Box>
                                <DatePicker
                                  locale={es}
                                  showTimeSelect
                                  selected={horaDia.lunFin !== '' ? horaDia.lunFin : horaHoy}
                                  timeIntervals={15}
                                  showTimeSelectOnly
                                  dateFormat='h:mm aa'
                                  name='horaFinLun'
                                  id='time-only-picker'
                                  timeCaption='Hora'
                                  minTime={horaDia.lunIni}
                                  maxTime={new Date(moment(hoy).endOf('day').toString())}
                                  value={horaDia.lunFin !== '' ? horaDia.lunFin : horaHoy}
                                  onChange={(hora: any) => {
                                    horaDia = {
                                      ...horaDia,
                                      lunFin: hora,
                                    }
                                    setHoraDia(horaDia)
                                    cargarValores('temporal', hora)
                                  }}
                                  disabled={!habilitarFechaFin.lun}
                                  customInput={<CustomInput label='Hora de Finalizacion' />}
                                />
                              </Box>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  {checkHabDia.mar && (
                    <div className='fv-col'>
                      <div className='d-flex align-items-center mt-3'>
                        <div className='col-2'>
                          <label className='form-check form-switch form-check-custom form-check-solid pr-4'>
                            <input
                              className='form-check-input'
                              name='communication[]'
                              type='checkbox'
                              defaultChecked={checkDia.mar}
                              onChange={() => {
                                checkDia = {
                                  ...checkDia,
                                  mar: !checkDia.mar,
                                }
                                setCheckDia(checkDia)
                                dispatch(evSetDias(checkDia))
                              }}
                            />
                            <span className='fw-bold ps-2 fs-6'>Martes</span>
                          </label>
                        </div>
                        {checkDia.mar && !check && (
                          <>
                            <div className='col-2'>
                              <Box>
                                <DatePicker
                                  locale={es}
                                  showTimeSelect
                                  selected={horaDia.marIni !== '' ? horaDia.marIni : horaHoy}
                                  timeIntervals={15}
                                  showTimeSelectOnly
                                  dateFormat='h:mm aa'
                                  name='horaInimar'
                                  id='time-only-picker'
                                  timeCaption='Hora'
                                  value={horaDia.marIni !== '' ? horaDia.marIni : horaHoy}
                                  onChange={(hora: any) => {
                                    horaDia = {
                                      ...horaDia,
                                      marIni: hora,
                                    }
                                    setHoraDia(horaDia)
                                    !habilitarFechaFin.mar &&
                                      (habilitarFechaFin = {
                                        ...habilitarFechaFin,
                                        mar: !habilitarFechaFin.mar,
                                      })
                                    setHabilitarFechaFin(habilitarFechaFin)
                                    cargarValores('temporal', hora)
                                  }}
                                  customInput={<CustomInput label='Hora de Inicio' />}
                                />
                              </Box>
                            </div>
                            <div className='col-2 pl-3'>
                              <Box>
                                <DatePicker
                                  locale={es}
                                  showTimeSelect
                                  selected={horaDia.marFin !== '' ? horaDia.marFin : horaHoy}
                                  timeIntervals={15}
                                  showTimeSelectOnly
                                  dateFormat='h:mm aa'
                                  name='horaFinmar'
                                  id='time-only-picker'
                                  timeCaption='Hora'
                                  minTime={horaDia.marIni}
                                  maxTime={new Date(moment(hoy).endOf('day').toString())}
                                  value={horaDia.marFin !== '' ? horaDia.marFin : horaHoy}
                                  onChange={(hora: any) => {
                                    horaDia = {
                                      ...horaDia,
                                      marFin: hora,
                                    }
                                    setHoraDia(horaDia)
                                    cargarValores('temporal', hora)
                                  }}
                                  disabled={!habilitarFechaFin.mar}
                                  customInput={<CustomInput label='Hora de Finalizacion' />}
                                />
                              </Box>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  {checkHabDia.mie && (
                    <div className='fv-col'>
                      <div className='d-flex align-items-center mt-3'>
                        <div className='col-2'>
                          <label className='form-check form-switch form-check-custom form-check-solid pr-4'>
                            <input
                              className='form-check-input'
                              name='communication[]'
                              type='checkbox'
                              defaultChecked={checkDia.mie}
                              onChange={() => {
                                checkDia = {
                                  ...checkDia,
                                  mie: !checkDia.mie,
                                }
                                setCheckDia(checkDia)
                                dispatch(evSetDias(checkDia))
                              }}
                            />
                            <span className='fw-bold ps-2 fs-6'>Miércoles</span>
                          </label>
                        </div>
                        {checkDia.mie && !check && (
                          <>
                            <div className='col-2'>
                              <Box>
                                <DatePicker
                                  locale={es}
                                  showTimeSelect
                                  selected={horaDia.mieIni !== '' ? horaDia.mieIni : horaHoy}
                                  timeIntervals={15}
                                  showTimeSelectOnly
                                  dateFormat='h:mm aa'
                                  name='horaInimie'
                                  id='time-only-picker'
                                  timeCaption='Hora'
                                  value={horaDia.mieIni !== '' ? horaDia.mieIni : horaHoy}
                                  onChange={(hora: any) => {
                                    horaDia = {
                                      ...horaDia,
                                      mieIni: hora,
                                    }
                                    setHoraDia(horaDia)
                                    !habilitarFechaFin.mie &&
                                      (habilitarFechaFin = {
                                        ...habilitarFechaFin,
                                        mie: !habilitarFechaFin.mie,
                                      })
                                    setHabilitarFechaFin(habilitarFechaFin)
                                    cargarValores('temporal', hora)
                                  }}
                                  customInput={<CustomInput label='Hora de Inicio' />}
                                />
                              </Box>
                            </div>
                            <div className='col-2 pl-3'>
                              <Box>
                                <DatePicker
                                  locale={es}
                                  showTimeSelect
                                  selected={horaDia.mieFin !== '' ? horaDia.mieFin : horaHoy}
                                  timeIntervals={15}
                                  showTimeSelectOnly
                                  dateFormat='h:mm aa'
                                  name='horaFinmie'
                                  id='time-only-picker'
                                  timeCaption='Hora'
                                  minTime={horaDia.mieIni}
                                  maxTime={new Date(moment(hoy).endOf('day').toString())}
                                  value={horaDia.mieFin !== '' ? horaDia.mieFin : horaHoy}
                                  onChange={(hora: any) => {
                                    horaDia = {
                                      ...horaDia,
                                      mieFin: hora,
                                    }
                                    setHoraDia(horaDia)
                                    cargarValores('temporal', hora)
                                  }}
                                  disabled={!habilitarFechaFin.mie}
                                  customInput={<CustomInput label='Hora de Finalizacion' />}
                                />
                              </Box>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  {checkHabDia.jue && (
                    <div className='fv-col'>
                      <div className='d-flex align-items-center mt-3'>
                        <div className='col-2'>
                          <label className='form-check form-switch form-check-custom form-check-solid pr-4'>
                            <input
                              className='form-check-input'
                              name='communication[]'
                              type='checkbox'
                              defaultChecked={checkDia.jue}
                              onChange={() => {
                                checkDia = {
                                  ...checkDia,
                                  jue: !checkDia.jue,
                                }
                                setCheckDia(checkDia)
                                dispatch(evSetDias(checkDia))
                              }}
                            />
                            <span className='fw-bold ps-2 fs-6'>Jueves</span>
                          </label>
                        </div>
                        {checkDia.jue && !check && (
                          <>
                            <div className='col-2'>
                              <Box>
                                <DatePicker
                                  locale={es}
                                  showTimeSelect
                                  selected={horaDia.jueIni !== '' ? horaDia.jueIni : horaHoy}
                                  timeIntervals={15}
                                  showTimeSelectOnly
                                  dateFormat='h:mm aa'
                                  name='horaInijue'
                                  id='time-only-picker'
                                  timeCaption='Hora'
                                  value={horaDia.jueIni !== '' ? horaDia.jueIni : horaHoy}
                                  onChange={(hora: any) => {
                                    horaDia = {
                                      ...horaDia,
                                      jueIni: hora,
                                    }
                                    setHoraDia(horaDia)
                                    !habilitarFechaFin.jue &&
                                      (habilitarFechaFin = {
                                        ...habilitarFechaFin,
                                        jue: !habilitarFechaFin.jue,
                                      })
                                    setHabilitarFechaFin(habilitarFechaFin)
                                    cargarValores('temporal', hora)
                                  }}
                                  customInput={<CustomInput label='Hora de Inicio' />}
                                />
                              </Box>
                            </div>
                            <div className='col-2 pl-3'>
                              <Box>
                                <DatePicker
                                  locale={es}
                                  showTimeSelect
                                  selected={horaDia.jueFin !== '' ? horaDia.jueFin : horaHoy}
                                  timeIntervals={15}
                                  showTimeSelectOnly
                                  dateFormat='h:mm aa'
                                  name='horaFinjue'
                                  id='time-only-picker'
                                  timeCaption='Hora'
                                  minTime={horaDia.jueIni}
                                  maxTime={new Date(moment(hoy).endOf('day').toString())}
                                  value={horaDia.jueFin !== '' ? horaDia.jueFin : horaHoy}
                                  onChange={(hora: any) => {
                                    horaDia = {
                                      ...horaDia,
                                      jueFin: hora,
                                    }
                                    setHoraDia(horaDia)
                                    cargarValores('temporal', hora)
                                  }}
                                  disabled={!habilitarFechaFin.jue}
                                  customInput={<CustomInput label='Hora de Finalizacion' />}
                                />
                              </Box>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  {checkHabDia.vie && (
                    <div className='fv-col'>
                      <div className='d-flex align-items-center mt-3'>
                        <div className='col-2'>
                          <label className='form-check form-switch form-check-custom form-check-solid pr-4'>
                            <input
                              className='form-check-input'
                              name='communication[]'
                              type='checkbox'
                              defaultChecked={checkDia.vie}
                              onChange={() => {
                                checkDia = {
                                  ...checkDia,
                                  vie: !checkDia.vie,
                                }
                                setCheckDia(checkDia)
                                dispatch(evSetDias(checkDia))
                              }}
                            />
                            <span className='fw-bold ps-2 fs-6'>Viernes</span>
                          </label>
                        </div>
                        {checkDia.vie && !check && (
                          <>
                            <div className='col-2'>
                              <Box>
                                <DatePicker
                                  locale={es}
                                  showTimeSelect
                                  selected={horaDia.vieIni !== '' ? horaDia.vieIni : horaHoy}
                                  timeIntervals={15}
                                  showTimeSelectOnly
                                  dateFormat='h:mm aa'
                                  name='horaInivie'
                                  id='time-only-picker'
                                  timeCaption='Hora'
                                  value={horaDia.vieIni !== '' ? horaDia.vieIni : horaHoy}
                                  onChange={(hora: any) => {
                                    horaDia = {
                                      ...horaDia,
                                      vieIni: hora,
                                    }
                                    setHoraDia(horaDia)
                                    !habilitarFechaFin.vie &&
                                      (habilitarFechaFin = {
                                        ...habilitarFechaFin,
                                        vie: !habilitarFechaFin.vie,
                                      })
                                    setHabilitarFechaFin(habilitarFechaFin)
                                    cargarValores('temporal', hora)
                                  }}
                                  customInput={<CustomInput label='Hora de Inicio' />}
                                />
                              </Box>
                            </div>
                            <div className='col-2 pl-3'>
                              <Box>
                                <DatePicker
                                  locale={es}
                                  showTimeSelect
                                  selected={horaDia.vieFin !== '' ? horaDia.vieFin : horaHoy}
                                  timeIntervals={15}
                                  showTimeSelectOnly
                                  dateFormat='h:mm aa'
                                  name='horaFinvie'
                                  id='time-only-picker'
                                  timeCaption='Hora'
                                  minTime={horaDia.vieIni}
                                  maxTime={new Date(moment(hoy).endOf('day').toString())}
                                  value={horaDia.vieFin !== '' ? horaDia.vieFin : horaHoy}
                                  onChange={(hora: any) => {
                                    horaDia = {
                                      ...horaDia,
                                      vieFin: hora,
                                    }
                                    setHoraDia(horaDia)
                                    cargarValores('temporal', hora)
                                  }}
                                  disabled={!habilitarFechaFin.vie}
                                  customInput={<CustomInput label='Hora de Finalizacion' />}
                                />
                              </Box>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  {checkHabDia.sab && (
                    <div className='fv-col'>
                      <div className='d-flex align-items-center mt-3'>
                        <div className='col-2'>
                          <label className='form-check form-switch form-check-custom form-check-solid pr-4'>
                            <input
                              className='form-check-input'
                              name='communication[]'
                              type='checkbox'
                              defaultChecked={checkDia.sab}
                              onChange={() => {
                                checkDia = {
                                  ...checkDia,
                                  sab: !checkDia.sab,
                                }
                                setCheckDia(checkDia)
                                dispatch(evSetDias(checkDia))
                              }}
                            />
                            <span className='fw-bold ps-2 fs-6'>Sábado</span>
                          </label>
                        </div>
                        {checkDia.sab && !check && (
                          <>
                            <div className='col-2'>
                              <Box>
                                <DatePicker
                                  locale={es}
                                  showTimeSelect
                                  selected={horaDia.sabIni !== '' ? horaDia.sabIni : horaHoy}
                                  timeIntervals={15}
                                  showTimeSelectOnly
                                  dateFormat='h:mm aa'
                                  name='horaInisab'
                                  id='time-only-picker'
                                  timeCaption='Hora'
                                  value={horaDia.sabIni !== '' ? horaDia.sabIni : horaHoy}
                                  onChange={(hora: any) => {
                                    horaDia = {
                                      ...horaDia,
                                      sabIni: hora,
                                    }
                                    setHoraDia(horaDia)
                                    !habilitarFechaFin.sab &&
                                      (habilitarFechaFin = {
                                        ...habilitarFechaFin,
                                        sab: !habilitarFechaFin.sab,
                                      })
                                    setHabilitarFechaFin(habilitarFechaFin)
                                    cargarValores('temporal', hora)
                                  }}
                                  customInput={<CustomInput label='Hora de Inicio' />}
                                />
                              </Box>
                            </div>
                            <div className='col-2 pl-3'>
                              <Box>
                                <DatePicker
                                  locale={es}
                                  showTimeSelect
                                  selected={horaDia.sabFin !== '' ? horaDia.sabFin : horaHoy}
                                  timeIntervals={15}
                                  showTimeSelectOnly
                                  dateFormat='h:mm aa'
                                  name='horaFinsab'
                                  id='time-only-picker'
                                  timeCaption='Hora'
                                  minTime={horaDia.sabIni}
                                  maxTime={new Date(moment(hoy).endOf('day').toString())}
                                  value={horaDia.sabFin !== '' ? horaDia.sabFin : horaHoy}
                                  onChange={(hora: any) => {
                                    horaDia = {
                                      ...horaDia,
                                      sabFin: hora,
                                    }
                                    setHoraDia(horaDia)
                                    cargarValores('temporal', hora)
                                  }}
                                  disabled={!habilitarFechaFin.sab}
                                  customInput={<CustomInput label='Hora de Finalizacion' />}
                                />
                              </Box>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  {checkHabDia.dom && (
                    <div className='fv-col'>
                      <div className='d-flex align-items-center mt-3'>
                        <div className='col-2'>
                          <label className='form-check form-switch form-check-custom form-check-solid pr-4'>
                            <input
                              className='form-check-input'
                              name='communication[]'
                              type='checkbox'
                              defaultChecked={checkDia.dom}
                              onChange={() => {
                                checkDia = {
                                  ...checkDia,
                                  dom: !checkDia.dom,
                                }
                                setCheckDia(checkDia)
                                dispatch(evSetDias(checkDia))
                              }}
                            />
                            <span className='fw-bold ps-2 fs-6'>Domingo</span>
                          </label>
                        </div>
                        {checkDia.dom && !check && (
                          <>
                            <div className='col-2'>
                              <Box>
                                <DatePicker
                                  locale={es}
                                  showTimeSelect
                                  selected={horaDia.domIni !== '' ? horaDia.domIni : horaHoy}
                                  timeIntervals={15}
                                  showTimeSelectOnly
                                  dateFormat='h:mm aa'
                                  name='horaInidom'
                                  id='time-only-picker'
                                  timeCaption='Hora'
                                  value={horaDia.domIni !== '' ? horaDia.domIni : horaHoy}
                                  onChange={(hora: any) => {
                                    horaDia = {
                                      ...horaDia,
                                      domIni: hora,
                                    }
                                    setHoraDia(horaDia)
                                    !habilitarFechaFin.dom &&
                                      (habilitarFechaFin = {
                                        ...habilitarFechaFin,
                                        dom: !habilitarFechaFin.dom,
                                      })
                                    setHabilitarFechaFin(habilitarFechaFin)
                                    cargarValores('temporal', hora)
                                  }}
                                  customInput={<CustomInput label='Hora de Inicio' />}
                                />
                              </Box>
                            </div>
                            <div className='col-2 pl-3'>
                              <Box>
                                <DatePicker
                                  locale={es}
                                  showTimeSelect
                                  selected={horaDia.domFin !== '' ? horaDia.domFin : horaHoy}
                                  timeIntervals={15}
                                  showTimeSelectOnly
                                  dateFormat='h:mm aa'
                                  name='horaFindom'
                                  id='time-only-picker'
                                  timeCaption='Hora'
                                  minTime={horaDia.domIni}
                                  maxTime={new Date(moment(hoy).endOf('day').toString())}
                                  value={horaDia.domFin !== '' ? horaDia.domFin : horaHoy}
                                  onChange={(hora: any) => {
                                    horaDia = {
                                      ...horaDia,
                                      domFin: hora,
                                    }
                                    setHoraDia(horaDia)
                                    cargarValores('temporal', hora)
                                  }}
                                  disabled={!habilitarFechaFin.dom}
                                  customInput={<CustomInput label='Hora de Finalizacion' />}
                                />
                              </Box>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )
        } */}
      </div >
    </div >
  )
}

export { Step2 }
