import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {MenuBens} from '../components/MenuBens'
import {TablaDatosBens} from '../components/TablaDatosBens'
import {ITablaBens} from '../interfaces/ITablaBens'
import {TokenFind} from '../services/Bens-Services'
import {useLocation} from 'react-router'

const BensPage = () => {
  let location: any = useLocation()

  //Redux de perfil
  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)

  const {perfil} = dashState
  const [selectMenu, setSelectMenu] = useState<ITablaBens>()

  //Filtro el rol del usuario
  let Role: any = []
  let array = perfil
  let menuSeleccionado: any
  for (const menu of array) {
    Role.push(menu)
  }
  const FiltRole: any = Role.filter(function (nombre: {nombre: string}) {
    return nombre.nombre === 'Bens'
  })
  let tablaDatos = {} as any
  const handelChange = async (opcion: string) => {
    switch (opcion) {
      case 'Inscripciones':
        tablaDatos = {
          className: 'card-xxl-stretch mb-5 mb-xl-8 ',
          tituloMenu: 'Inscripciones',
          tablaDatos: location.state.data,
          headColumns: {
            col1: '',
            col2: 'ID',
            col3: 'Dni',
            col4: 'Apellido',
            col5: 'Nombre',
          },
        }
        setSelectMenu(tablaDatos)
        break

      case 'Validados':
        tablaDatos = {
          className: 'card-xxl-stretch mb-5 mb-xl-8',
          tituloMenu: 'Validados',
          tablaDatos: location.state.data,
          headColumns: {
            col1: '',
            col2: 'ID',
            col3: 'Dni',
            col4: 'Apellido',
            col5: 'Nombre',
          },
        }
        setSelectMenu(tablaDatos)
        break
    }
  }

  const selectData = (opcion: string, tablaDatos: ITablaBens) => {
    setSelectMenu(tablaDatos)
  }
  useEffect(() => {
    let tokeSubscribed = true
    //validation Token
    TokenFind().then((resToken: any) => {
      if (tokeSubscribed) {
        if (resToken.data.ok) {
          let tokenOk = resToken.data.res.find((data: any) => data.estadoToken === true)
          sessionStorage.setItem('BensToken', tokenOk.token)
        }
      }
    })
    return () => {
      tokeSubscribed = false
    }
  }, [])
  useEffect(() => {
    location.state && handelChange(location.state.titulo)
  }, [location])

  return (
    <div>
      <PageTitle breadcrumbs={[]}>Boleto estudiantil</PageTitle>
      <div className='row gy-5 gx-xl-12'>
        <div className='col-xxl-3 col-xl-3 col-md-3 '>
          <MenuBens
            className='sombras card-xxl-stretch mb-xl-3'
            mPrincipal={FiltRole[0].menu}
            selectMenu={selectData}
          />
        </div>

        <div className='col-xl-9 col-md-9'>
          <TablaDatosBens
            className={'col-xl-9 col-md-9'}
            tituloMenu={selectMenu?.tituloMenu}
            tablaDatos={selectMenu?.tablaDatos}
            headColumns={selectMenu?.headColumns}
          />
        </div>
      </div>
    </div>
  )
}
export default BensPage
