import {Form, Formik, FormikValues} from 'formik'
import {useEffect, useRef, useState} from 'react'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components/_StepperComponent'
import {KTSVG} from '../../../../../_metronic/helpers'
import {
  validationSchema,
  inits,
  wsPersona,
} from '../components/usuarios/StepsUsuarios/CreateAccountWizardHelper'
import {Step1} from '../components/usuarios/StepsUsuarios/Step1'
import {Step2} from '../components/usuarios/StepsUsuarios/Step2'
import {Step3} from '../components/usuarios/StepsUsuarios/Step3'
import {Step4} from '../components/usuarios/StepsUsuarios/Step4'
import Swal from 'sweetalert2'

import {ICreateUsr} from '../interfaces/ICreateUsr'

import {ICreateOrg} from '../interfaces/ICreateOrg'
import {ICreateArea} from '../interfaces/ICreateArea'
import {ISubArea} from '../interfaces/ICreateSubArea'
import {Link} from 'react-router-dom'
import {postUs} from '../services/UsuarioService'
import {getOneAreaOrg} from '../services/AreaService'
import Spinner from '../../../../components/spinner/Spinner'

const StepHorizontalUsuario = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [load, setLoad] = useState(false)
  const [wsIsok, setWsIsok] = useState(false)
  const [currentSchema, setCurrentSchema] = useState(validationSchema)
  const [initValues, setinitValues] = useState<ICreateUsr>(inits)
  const [secValues, setsecValues] = useState<ICreateUsr>(inits)
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    stepper.current?.currentStepIndex === 2 && setsecValues(inits)
    setWsIsok(false)
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(validationSchema)
  }
  const submitStep = async (values: ICreateUsr, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    setCurrentSchema(validationSchema)

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      await postUs(values).then((res: any) => {
        if (res.data.ok) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'El usuario, fue cargado con exito',
            text: `El password generado es ${values.password}`,
            showConfirmButton: false,
            timer: 4500,
          })
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'Verificar los datos ingresados',
            icon: 'error',
            confirmButtonText: 'Volver',
          })
        }
      })

      setsecValues(inits)
      setinitValues(inits)
      setWsIsok(false)
      actions.resetForm()
      stepper.current.goto(1)
    }
  }

  const buscaWsPersona = async (element: any = {}) => {
    setLoad(true)
    let data: any = await wsPersona(element)

    if (data !== 'Verificar los datos ingresados') {
      setsecValues(data)
      setWsIsok(true)
      setLoad(false)
      stepper.current?.goto(2)
    } else {
      setLoad(false)
      Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: data,
      })
    }
  }
  const datosOrg = (data: ICreateOrg) => {
    let dtOrg: ICreateUsr = secValues
    dtOrg.datosLaborales!.datosOrganismo.nombre = data?.nombre
    dtOrg.datosLaborales!.datosOrganismo.nombreMinistro = data?.nombreMinistro
    setsecValues(dtOrg)
  }
  const datosAreaPrinc = async (data: ICreateArea) => {
    //TODO:Busca los datos en la colleccion areaOrg para completar los datos laborales del usuario
    await getOneAreaOrg(data.nombreArea).then((res: any) => {
      if (res.data.ok) {
        let dtOrg: ICreateUsr = secValues
        dtOrg.datosLaborales!.datosOrganismo.areaPrincipal.nombreArea = res.data.data?.nombreArea
        dtOrg.datosLaborales!.datosOrganismo.areaPrincipal.idAreaP = res.data.data?._id || ''
        dtOrg.datosLaborales!.datosOrganismo.areaPrincipal.categoriaArea =
          res.data.data?.categoriaArea
        dtOrg.datosLaborales!.datosOrganismo.areaPrincipal.tipoArea = res.data.data?.tipoArea
        dtOrg.datosLaborales!.datosOrganismo.areaPrincipal.detalleSistema =
          res.data.data?.detalleSistema

        setsecValues(dtOrg)
      } else {
        let dtOrg: ICreateUsr = secValues
        dtOrg.datosLaborales!.datosOrganismo.areaPrincipal.nombreArea = data?.nombreArea
        dtOrg.datosLaborales!.datosOrganismo.areaPrincipal.idAreaP = ''
        dtOrg.datosLaborales!.datosOrganismo.areaPrincipal.categoriaArea = data?.categoriaArea
        dtOrg.datosLaborales!.datosOrganismo.areaPrincipal.tipoArea = data?.tipoArea
        dtOrg.datosLaborales!.datosOrganismo.areaPrincipal.detalleSistema = data?.detalleSistema

        setsecValues(dtOrg)
      }
    })
  }

  const datosAreaPropia = (data: ISubArea) => {
    let dtOrg: ICreateUsr = secValues
    dtOrg.datosLaborales!.datosOrganismo.areaPropia!.nombreArea = data?.nombreArea
    dtOrg.datosLaborales!.datosOrganismo.areaPropia!.idAreaPro = data?._id || ''
    dtOrg.datosLaborales!.datosOrganismo.areaPropia!.categoriaArea = data?.categoriaArea
    dtOrg.datosLaborales!.datosOrganismo.areaPropia!.tipoArea = data?.tipoArea
    dtOrg.datosLaborales!.datosOrganismo.areaPropia!.detalleSistema = data?.detalleSistema

    setsecValues(dtOrg)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef, wsIsok])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Crear usuario</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Datos Personales</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Datos Laborales</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Finalizar</h3>
            </div>
          </div>

          <Formik
            validationSchema={currentSchema}
            initialValues={secValues || initValues}
            onSubmit={submitStep}
            autocomplete='false'
            enableReinitialize
          >
            {(formik) => {
              //////console.log(formik)

              return (
                <Form
                  className='mx-auto mw-600px w-100 pt-15 pb-10'
                  noValidate
                  id='kt_create_account_form'
                >
                  <div className='current' data-kt-stepper-element='content'>
                    <Step1 />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step2 foto={secValues.datosPersonales.foto} />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step3
                      pasarOrg={datosOrg}
                      pasarArea={datosAreaPrinc}
                      pasarSubArea={datosAreaPropia}
                    />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step4 />
                  </div>

                  <div className='d-flex flex-stack pt-15'>
                    {!wsIsok ? (
                      <div className='mr-2'>
                        <Link to={{pathname: 'servicios', state: 'usuarios'}}>
                          <button type='button' className='btn btn-lg btn-light-primary me-3'>
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr063.svg'
                              className='svg-icon-4 me-1'
                            />
                            Volver
                          </button>
                        </Link>
                      </div>
                    ) : (
                      <div className='mr-2'>
                        <button
                          onClick={prevStep}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr063.svg'
                            className='svg-icon-4 me-1'
                          />
                          Volver
                        </button>
                      </div>
                    )}
                    {load && <Spinner />}
                    <div>
                      {wsIsok ? (
                        <button type='submit' className='btn btn-lg btn-primary me-3'>
                          <span className='indicator-label'>
                            {stepper.current?.currentStepIndex !==
                              stepper.current?.totatStepsNumber! - 1 && 'Siguiente'}
                            {stepper.current?.currentStepIndex ===
                              stepper.current?.totatStepsNumber! - 1 && 'Guardar'}
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        </button>
                      ) : (
                        <button
                          className='btn btn-lg btn-primary me-3'
                          type='button'
                          onClick={() => buscaWsPersona(formik.values)}
                          disabled={!formik.isValid}
                        >
                          Buscar
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default StepHorizontalUsuario
