import {getPersonaPadron} from '../modules/apps/Sis-admin/services/padronService'
import {getPersona} from '../modules/apps/Sis-admin/services/PersonaService'

export const wsPersona: any = async (
  dni: string,
  genero: string,
  tipod: string,
  tipoNacion: string
) => {
  return getPersona(dni, genero)
    .then(async (res: any) => {
      if (res.data.status === 'success') {
        let datos01: any = {
          datapersonal: {
            dni: res.data.persona.dni,
            apellido: res.data.persona.apellido,
            nombre: res.data.persona.nombres,
            sexo: res.data.persona.sexo.toLowerCase(),
            fechaNacimiento: res.data.persona.fecha_nacimiento,
            domicilio: res.data.persona.calle + ' ' + res.data.persona.numero,
            localidad: '',
            departamento: '',
            provincia: res.data.persona.provincia.toLowerCase(),
            tipoNacionalidad: tipoNacion,
            nacionalidad: res.data.persona.nacionalidad || '',
            telefono: res.data.persona.telefono || '',
            mail: res.data.persona.mail || '',
            cuit: res.data.persona.cuil || '',
            codigoPostal: res.data.persona.cpostal || '',
            tipoDoc: tipod,
            tipoResidencia: res.data.persona.cod3 || '',
            fechaVencimientoResidencia: res.data.persona.cod4 || '',
            estadoCivil: res.data.persona.cod5 || '',
            vehiculoLey: res.data.persona.cod6 || '',
            fechaVehiculo: res.data.persona.cod7 || '',
            simboloInternacional: res.data.persona.cod8 || '',
            fechaDesignacionTutor: res.data.persona.cod10 || '',
            juzgado: res.data.persona.cod11 || '',
            secretaria: res.data.persona.cod12 || '',
            dptoJudicial: res.data.persona.cod13 || '',
            fiscalia: res.data.persona.cod14 || '',
            defensoria: res.data.persona.cod15 || '',
            interesadoApoyoJudicial: res.data.persona.cod16 || '',
            interesadoApoyoExtraJudicial: res.data.persona.cod17 || '',
            curadorDesignadoJudicial: res.data.persona.cod18 || '',
            tipoPariente: res.data.persona.cod19 || '',
            derechoAsignacionesF: res.data.persona.cod122 || '',
          },
        }

        return datos01
      } else {
      }

      if (res.data.status === 'error') {
        return await getPersonaPadron(dni).then(async (res: any) => {
          if (res.data.ok) {
            let datos01: any = {
              datapersonal: {
                dni: res.data.data.documento,
                apellido: res.data.data.apellido,
                nombre: res.data.data.nombre,
                sexo: res.data.data.genero.toLowerCase(),
                domicilio: res.data.data.domicilio,
                localidad: '',
                departamento: '',
                nacionalidad: res.data.data.nacionalidad || 'Argentina',
                telefono: res.data.data.telefono || '',
                mail: res.data.data.mail || '',
                fechaNacimiento: res.data.data.nacimiento || '',
                cuit: res.data.data.cuil || '',
                tipoNacionalidad: tipoNacion,
                codigoPostal: res.data.data.cpostal || '',
                tipoDoc: tipod,
                tipoResidencia: res.data.data.cod3 || '',
                fechaVencimientoResidencia: res.data.data.cod4 || '',
                estadoCivil: res.data.data.cod5 || '',
                vehiculoLey: res.data.data.cod6 || '',
                fechaVehiculo: res.data.data.cod7 || '',
                simboloInternacional: res.data.data.cod8 || '',
                tipoTutor: res.data.data.cod9 || '',
                fechaDesignacionTutor: res.data.data.cod10 || '',
                juzgado: res.data.data.cod11 || '',
                secretaria: res.data.data.cod12 || '',
                dptoJudicial: res.data.data.cod13 || '',
                fiscalia: res.data.data.cod14 || '',
                defensoria: res.data.data.cod15 || '',
                interesadoApoyoJudicial: res.data.data.cod16 || '',
                interesadoApoyoExtraJudicial: res.data.data.cod17 || '',
                curadorDesignadoJudicial: res.data.data.cod18 || '',
                tipoPariente: res.data.data.cod19 || '',
                derechoAsignacionesF: res.data.data.cod122 || '',
                provincia: res.data.data.provincia || 'neuquen',
              },
            }
            return datos01
          } else {
            return 'Verificar los datos ingresados'
          }
        })
      } else {
        return 'Verificar los datos ingresados'
      }
    })
    .catch(async (res: any) => {
      return await getPersonaPadron(dni).then(async (res: any) => {
        if (res.data.ok) {
          let datos01: any = {
            datapersonal: {
              dni: res.data.data.documento,
              apellido: res.data.data.apellido,
              nombre: res.data.data.nombre,
              sexo: res.data.data.genero,
              domicilio: res.data.data.domicilio,
              localidad: '',
              departamento: '',
              nacionalidad: res.data.data.nacionalidad || 'Argentina',
              telefono: res.data.data.telefono || '',
              mail: res.data.data.mail || '',
              fechaNacimiento: res.data.data.nacimiento || '',
              autopercepcion: res.data.data.autopercepcion || '',
              cuit: res.data.data.cuil || '',
              tipoNacionalidad: tipoNacion,
              codigoPostal: res.data.data.cpostal || '',
              tipoDoc: tipod,
              tipoResidencia: res.data.data.cod3 || '',
              fechaVencimientoResidencia: res.data.persona?.cod4 || '',
              estadoCivil: res.data.data.cod5 || '',
              vehiculoLey: res.data.data.cod6 || '',
              fechaVehiculo: res.data.data.cod7 || '',
              simboloInternacional: res.data.data.cod8 || '',
              fechaDesignacionTutor: res.data.data.cod10 || '',
              juzgado: res.data.data.cod11 || '',
              secretaria: res.data.data.cod12 || '',
              dptoJudicial: res.data.data.cod13 || '',
              fiscalia: res.data.data.cod14 || '',
              defensoria: res.data.data.cod15 || '',
              interesadoApoyoJudicial: res.data.data.cod16 || '',
              interesadoApoyoExtraJudicial: res.data.data.cod17 || '',
              curadorDesignadoJudicial: res.data.data.cod18 || '',
              tipoPariente: res.data.data.cod19 || '',
              derechoAsignacionesF: res.data.data.cod122 || '',
              provincia: res.data.data.provincia || 'neuquen',
            },
          }
          return datos01
        } else {
          return 'Verificar los datos ingresados'
        }
      })
    })
}
