import {Civistypes} from './Civis-types'

const tematica = {
  tematicas: [],
}

const civisWs = {
  resApi: {},
}

export const TematicaReducer = (state = tematica, action: any) => {
  let item1 = state.tematicas.find(
    (item: any) =>
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      item.acceso_nombre === action.payload?.tematica?.acceso_nombre
  )

  switch (action.type) {
    case Civistypes.SetTematica:
      if (!item1) {
        return {...state, tematicas: [...state.tematicas, action.payload.tematica]}
      } else {
        return {
          ...state,
          tematicas: state.tematicas.filter(
            (item: any) => item?.acceso_nombre !== action?.payload?.tematica?.acceso_nombre
          ),
        }
      }
    case Civistypes.RemTematica:
      return {tematicas: []}
    default:
      return state
  }
}

export const resCivisApiReducer = (state = civisWs, action: any) => {
  switch (action.type) {
    case Civistypes.SetResApi:
      return {civisWs: action.payload.resData}

    case Civistypes.RemResApi:
      return {}
    default:
      return state
  }
}
