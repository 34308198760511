import React, {useEffect, useState} from 'react'
import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import moment from 'moment'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ITablaBens} from '../../interfaces/ITablaBens'
import {getAprobadByDni, getByIdPassed, getFechaPassed} from '../../services/Bens-Services'

import {TablaDni} from './FilterTablas/TablaDni'
import {TablaID} from './FilterTablas/TablaID'

const TablaAprobados: React.FC<ITablaBens> = ({className, tituloMenu, headColumns}) => {
  const [selectItem, setSelectItem] = useState<any>('')
  const [cambio, setCambio] = useState(0)
  const [SearchByDNI, setSearcByDNI] = useState('')
  const [SearchByIdDesde, setSearchByIdDesde] = useState('')
  const [SearchByIdHasta, setSearchByIdHasta] = useState('')
  const [FechaDesde, setFechaDesde] = useState('')
  const [FechaHasta, setFechaHasta] = useState('')
  const [tab, setTab] = useState(false)
  const [tabId, setTabId] = useState(false)
  const [tabArray, setTabArray] = useState(false)
  const [dataId, setDataId] = useState<any>([])
  const [dataArray, setDataArray] = useState<any>([])
  const [data, setData] = useState<any>([])

  //State Input Radio
  const cambioRadio = (e: any) => {
    setCambio(e.target.value)
  }

  useEffect(() => {
    selectItem.length > 1 && setSelectItem([])
  }, [selectItem])

  //send Dni Api
  const sendBusqueda = (bens: string) => {
    const sendByDni = {
      dni: bens,
    }
    getAprobadByDni(sendByDni).then((res: any) => {
      setTabArray(false)
      setTabId(false)
      let InsAprob = res.data.res
      if (res.data.res !== null) {
        if (InsAprob.estado !== 'validado') {
          setTab(false)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: '¡El DNI no se encuentra validado!',
          })
        } else {
          setTab(true)
          setData(InsAprob)
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: '¡El DNI no se encuentra!',
        })
      }
    })
  }

  //Send Fecha Api
  const sendFecha = (FechaDesde: any, FechaHasta: any) => {
    setTab(false)
    setTabId(false)
    const sendByFecha = {
      fechaDesde: FechaDesde,
    }
    //
    getFechaPassed(sendByFecha).then((res: any) => {
      let arrayInscripciones = res.data.res
      if (res.data.ok === true) {
        if (arrayInscripciones.length === 0) {
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            text: '¡No hay Validados!',
          })
        } else {
          setTabArray(true)
          setDataArray(arrayInscripciones)
        }
      } else {
        setTabArray(false)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: '¡Error al traer Validados!',
        })
      }
    })
  }
  //
  const sendBusquedaID = (SearchByIdDesde: any, SearchByIdHasta: any) => {
    setTab(false)
    setTabArray(false)
    setTabId(false)
    const sendById = {
      tramiteDesde: SearchByIdDesde,
      tramiteHasta: SearchByIdHasta,
    }
    getByIdPassed(sendById).then((res: any) => {
      let inscripcion = res.data.res
      if (res.data.ok === true) {
        if (inscripcion.length === 0) {
          setTab(false)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: '¡Ninguno de los ID estan Validado!',
          })
        } else {
          setTabId(true)
          setDataId(inscripcion)
        }
      }
    })
  }
  //On change Input Date
  const onChangeDateDesde = (date: any) => {
    const newDate = moment(date.target.value).format('L')
    setFechaDesde(newDate)
  }

  return (
    <div>
      <div className={`card ${className}`}>
        <div className='card-body border-0 pb-4 pt-3'>
          <div className='fv-row'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder  fs-3 mb-1'>{tituloMenu}</span>
            </h3>
            <FormControl>
              <FormLabel id='demo-row-radio-buttons-group-label'>
                Seleccione metodo de busqueda
              </FormLabel>

              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
              >
                <FormControlLabel
                  value='DNI'
                  control={<Radio value={1} onChange={cambioRadio} />}
                  label='DNI'
                />
                <FormControlLabel
                  value='ID'
                  control={<Radio value={2} onChange={cambioRadio} />}
                  label='ID'
                />
                <FormControlLabel
                  value='Fecha'
                  control={<Radio value={3} onChange={cambioRadio} />}
                  label='Fecha'
                />
              </RadioGroup>
            </FormControl>
            <div className='row'>
              {cambio == 1 ? (
                <div className='col-lg-3 col-5'>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Buscar por DNI'
                    value={SearchByDNI}
                    onChange={(e: any) => setSearcByDNI(e.target.value)}
                  />
                </div>
              ) : null}
              {cambio == 2 ? (
                <>
                  <div className='col-lg-2 col-5'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='ID Desde'
                      value={SearchByIdDesde}
                      onChange={(e) => setSearchByIdDesde(e.target.value)}
                    />
                  </div>
                  <div className='col-lg-2 col-5'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='ID Hasta'
                      value={SearchByIdHasta}
                      onChange={(e) => setSearchByIdHasta(e.target.value)}
                    />
                  </div>
                </>
              ) : null}
              {cambio == 3 ? (
                <>
                  <div className='col-lg-3 col-5'>
                    <input type='date' className='form-control ' onChange={onChangeDateDesde} />
                  </div>
                </>
              ) : null}

              {cambio == 1 ? (
                <div className='col-lg-1 col-2'>
                  <button
                    className='btn btn-icon btn-primary'
                    onClick={() => {
                      sendBusqueda(SearchByDNI)
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-3' />
                  </button>
                </div>
              ) : null}
              {cambio == 2 ? (
                <div className='col-lg-1 col-2'>
                  <button
                    className='btn btn-icon btn-primary'
                    onClick={() => {
                      sendBusquedaID(SearchByIdDesde, SearchByIdHasta)
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-3' />
                  </button>
                </div>
              ) : null}
              {cambio == 3 ? (
                <div className='col-lg-1 col-2'>
                  <button
                    className='btn btn-icon btn-primary'
                    onClick={() => {
                      sendFecha(FechaDesde, FechaHasta)
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-3' />
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {tab ? (
        <TablaDni
          data={data}
          className={className}
          headColumns={headColumns}
          tituloMenu={tituloMenu}
        />
      ) : null}
      {tabArray ? (
        <TablaID
          data={dataArray}
          className={className}
          headColumns={headColumns}
          tituloMenu={tituloMenu}
        />
      ) : null}
      {tabId ? (
        <TablaID
          data={dataId}
          className={className}
          headColumns={headColumns}
          tituloMenu={tituloMenu}
        />
      ) : null}
    </div>
  )
}
export {TablaAprobados}
