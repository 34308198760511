import React from 'react'
import {Field, ErrorMessage} from 'formik'
import TextErrorFormik from './TextErrorFormik'
const RadioButtonFormik: React.FC = (props: any) => {
  const {label, name, options, ...rest} = props
  return (
    <div>
      <label className='form-label fw-bolder text-dark fs-6'>{label}</label>
      <Field name={name}>
        {(field: any) => {
          return options.map((option: any) => {
            return (
              <React.Fragment key={option.key}>
                <input
                  type='radio'
                  id={option.value}
                  {...field}
                  {...rest}
                  value={option.value}
                  checked={field.value === option.value}
                />
                <label htmlFor={option.value}>{option.key}</label>
              </React.Fragment>
            )
          })
        }}
      </Field>
      <ErrorMessage name={name} component={TextErrorFormik} />
    </div>
  )
}

export default RadioButtonFormik
