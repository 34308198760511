/** React */
import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
/**  /** Material */
import InfoIcon from '@mui/icons-material/Info'
import {Box, Button, Collapse} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
/** Services */
import {getLegajos} from '../../services/cud-service'
/** Interfaces */
import {Ilegajos, Ilegajo} from '../../Interfaces/Ilegajo'
/** Moment */
import moment from 'moment'
moment.locale('es-mx')



type Props = {
  filtro?: any
}
export const DataLegajos: React.FC<Props> = () => {  
  const [legajos, setLegajos] = useState<Ilegajos>([])
  const [dataSeleccionada, setDataSeleccionada] = useState<any>([])

  useEffect(() => {
    getLegajos().then((res: any) => {
      setLegajos(res.data.legajos)
    })
    dataSeleccionada.length > 1 && setDataSeleccionada([])
  }, [dataSeleccionada])

  return (
    <>
      <thead>
        <tr className='fw-bolder bg-light'>
          <th className='min-w-4px'></th>
          {/* <th className='min-w-80px'>Legajo</th> */}
          <th className='min-w-50px'>Dni</th>
          <th className='min-w-100px'>Apellido</th>
          <th className='min-w-100px'>Nombre</th>
          <th className='min-w-100px'>Localidad</th>
          <th className='min-w-100px'>Junta</th>
          <th className='min-w-100px'>Genero</th>
          <th className='min-w-25px'>Acciones</th>
          <th className='min-w-8px'></th>
        </tr>
      </thead>
      <tbody>
        {dataSeleccionada.length === 0? legajos?.map((value: Ilegajo, index:any) => {
              return (
                <tr className='table_font_normal' key={index}>
                  <td></td>
                  {/* <td>{value.legajo}</td> */}
                  <td>{value.datosPersonales.dni}</td>
                  <td>{value.datosPersonales.apellido.replace(/\b\w/g, l => l.toUpperCase())}</td>
                  <td>{value.datosPersonales.nombre.replace(/\b\w/g, l => l.toUpperCase())} </td>
                  <td>{value.datosPersonales.localidad.replace(/\b\w/g, l => l.toUpperCase())}</td>
                  <td>{value.junta.replace(/\b\w/g, l => l.toUpperCase())}</td>
                  <td>{value.datosPersonales.sexo.replace(/\b\w/g, l => l.toUpperCase())}</td>
                  <td>
                    <a className='icono_tabla01' type='checkbox' onClick={() => setDataSeleccionada((data: any) => [...data, value])}>
                      <InfoIcon />
                    </a>
                  </td>
                </tr>
              )
            }) : dataSeleccionada?.map((value: Ilegajo, index:any) => {
              return (
                <>
                  <tr className='table_font_normal' key={index}>
                    <td></td>
                    <td>{value.datosPersonales.dni}</td>
                    <td>{value.datosPersonales.nombre.replace(/\b\w/g, l => l.toUpperCase())}</td>
                    <td>{value.datosPersonales.apellido.replace(/\b\w/g, l => l.toUpperCase())}</td>
                    <td>{value.datosPersonales.localidad.replace(/\b\w/g, l => l.toUpperCase())}</td>
                    <td>{value.junta.replace(/\b\w/g, l => l.toUpperCase())}</td>
                    <td>{value.datosPersonales.sexo.replace(/\b\w/g, l => l.toUpperCase())}</td>
                    <td>
                      <a className='icono_tabla01' type='checkbox' onClick={() => setDataSeleccionada((data: any) => [...data, value])}>
                        <CancelIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td style={{paddingBottom: 0, paddingTop: 5}} colSpan={9}>
                       <Collapse in={true} timeout='auto' unmountOnExit>
                        <Box sx={{margin: 2}} className='no-margin-top'>
                          <div className='mt-5 fv-row'>
                            <div className='row'>
                              <div className='col-lg-3'>
                                <label className=''>Legajo</label>
                                <label className='form-label m-0'>
                                  &nbsp;&nbsp;
                                  {(value.legajo)}
                                </label>
                              </div>
                              <div className='col-lg-3'>
                                <label className=''>Observaciones</label>
                                <label className='form-label m-0'>
                                  &nbsp;&nbsp;
                                  {value.observaciones ? value.observaciones.replace(/\b\w/g, l => l.toUpperCase()) : '-'}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div className='mt-5 fv-row'></div>
                            <br /> <br />
                          </div>
                           <br /> <br /> <br /> <br />
                          <Link to={{pathname: 'editLegajo/', state: value}}>
                            <Button href='#' className='btn btn-primary '>
                              Editar Legajo
                            </Button>&nbsp;&nbsp;
                          </Link> 
                          <Link to={{pathname: 'editDoc/', state: value}}>
                            <Button href='#' className='btn btn-primary '>
                              Editar Documentacion
                            </Button>
                          </Link>&nbsp;&nbsp;
                          <Link to={{pathname: 'editOrig/', state: value}}>
                            <Button href='#' className='btn btn-primary '>
                              Editar Orígenes
                            </Button>
                          </Link>
                        </Box>
                      </Collapse>
                    </td>
                  </tr>
                </>
              )
            })}
      </tbody>
    </>
  )
}
export default DataLegajos
