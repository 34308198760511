import { calTypes } from "../types/types";

const initialState = {
  modalOpen: false,
}


export const uiReducer = (state = initialState, action: any) => {

  switch (action.type) {
    case calTypes.uiOpenModal:
      return {
        ...state,
        modalOpen: true
      }
    case calTypes.uiCloseModal:
      return {
        ...state,
        modalOpen: false
      }

    default:
      return state;
  }

}