
import { request } from '../../../../../../setup/axios/axios.util'
import { IEventos } from '../../pages/CrearEventoPage/Steps/EventoData'


const API_URL = process.env.REACT_APP_API_URL || 'api'

export const consultaDependencias = () => {
  const URL = `${API_URL}/modules/sis-admin/areas`
  return request().get(URL)
}
export const consultaEventos = () => {
  const URL = `${API_URL}/modules/agenda/cargareventos`
  return request().get(URL)
}
export const buscarEventos = () => {
  const URL = `${API_URL}/modules/agenda/cargar_eventos`
  return request().get(URL)
}
export const consultaDestinatarios = () => {
  const URL = `${API_URL}/modules/agenda/destinatario`
  return request().get(URL)
}
export const guardarEvento = (datos: IEventos) => {
  let data: any = {}
  data.data = datos
  const URL = `${API_URL}/modules/agenda/guardar_evento`
  return request().post(URL, data)
}
export const actualizarEvento = (datos: IEventos, accion: string) => {
  ////console.log('Lod Datos____>', datos)
  let data: any = {}
  data.op = accion
  data.data = datos
  const URL = `${API_URL}/modules/agenda/actualizar_evento`
  return request().post(URL, data)
}
export const actualizarEstados = (datos: IEventos, accion: string) => {
  ////console.log('Lod Datos____>', datos)
  let data: any = {}
  data.op = accion
  data.data = datos
  const URL = `${API_URL}/modules/agenda/actualizar_estados`
  return request().post(URL, data)
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  consultaDependencias,
  consultaEventos,
  consultaDestinatarios,
  guardarEvento,
  actualizarEvento,
  buscarEventos,
  actualizarEstados
}
