const dataAccesibilidad = {
    menos300mts: false,
    mas300mts: false,
}

const estadoCalles = {
    pavimento: false,
    mejorado: false,
    tierra: false,
}

const tipoColectivo = {
    hospital: false,
    hogar: false,
    prision: false,
    residencia: false,
    otro: false,
}

const condicionTemplate = {
    trabaja: false,
    noAplica: false,
    noTrabaja: false,
    buscaTrabajo: false,
}

const tipoViviendaTemplate = {
    casa: false,
    rancho: false,
    casilla: false,
    departamento: false,
    piezaInquilinato: false,
    piezaHotel: false,
    local: false,
    movil: false,
    situacionCalle: false,
}

const situacionPrevisionalTemplate = {
    jubilado: false,
    pensionado: false,
    pensionInvalidez: false,
    pensionGraciable: false,
    pensionUniversal: false,
    pensionExCombatientes: false,
    pensionMadre: false,
    otra: false,
    sinBenefecio: false,
}

const coberturaSaludTemplate = {
    obraSocial: false,
    prepaga: false,
    pami: false,
    publicacion: false,
    otra: false,
}

const categoriaTemplate = {
    empleado: false,
    patron: false,
    cuentaPropia: false,
    familiar: false,
    otro: false,
    otroTrabajo: "",
}
 
const aportesPrevTemplate = {
    propios: false,
    empleador: false,
    ninguno: false,
}

const nivelEducativoTemplate = {
    alfabetizado: false,
    analfabeto: false,
    noAplica: false,
}

function arrayToObjectConversion(arr: string[], template: any): { [key: string]: boolean } {
    let obj: { [key: string]: boolean } = { ...template };
    for (const item of arr) {
        obj[item] = true;
    }
    return obj;
}




export const adapterDatosEncuesta = (data: any) => {
    const dataConverted = { ...data };
    const { tipoVivienda, vivienda, condicion, categoria, aportes, nivelEducativo, trayectoria } = dataConverted;
    console.log(dataConverted, tipoVivienda, vivienda);
    if (tipoVivienda === 'particular') {
        dataConverted.vivienda.tipo = arrayToObjectConversion([vivienda.tipo], tipoViviendaTemplate);
        dataConverted.vivienda.accesibilidad.medioTransporte = arrayToObjectConversion([vivienda.accesibilidad.medioTransporte], dataAccesibilidad);
        dataConverted.vivienda.accesibilidad.estadoCalles = arrayToObjectConversion([vivienda.accesibilidad.estadoCalles], estadoCalles);
    }
    else {
        dataConverted.vivienda.tipo = arrayToObjectConversion(vivienda.tipo, tipoColectivo);
    }
    dataConverted.nivelEducativo = arrayToObjectConversion([nivelEducativo], nivelEducativoTemplate);
    dataConverted.condicion = arrayToObjectConversion([condicion], condicionTemplate);
    dataConverted.situacionPrevisional = arrayToObjectConversion(dataConverted.situacionPrevisional, situacionPrevisionalTemplate);
    dataConverted.categoria = arrayToObjectConversion([ categoria], categoriaTemplate);
    dataConverted.coberturaSalud = arrayToObjectConversion(dataConverted.coberturaSalud, coberturaSaludTemplate);
    dataConverted.aportes = arrayToObjectConversion([aportes], aportesPrevTemplate);

    dataConverted.educacion = {
        nivelEducativo: dataConverted.nivelEducativo,
        concurrio: dataConverted.concurrio,
        concurre: dataConverted.concurre,
        trayectoria: {
            inicial: trayectoria.inicialList,
            primaria: trayectoria.primariaList,
            secundaria: trayectoria.secundariaList,
            superior: trayectoria.superiorList,
        },
        inclusionEducation: dataConverted.inclusionEducativa,
        inclusionEducActual: dataConverted.inclusionEducActual,
    }
    dataConverted.actividadLaboral = {

            condicion: dataConverted.condicion,
            categoria: {...dataConverted.categoria, otroTrabajo: dataConverted['otroTrabajo']},
            aportesPrevisionales: dataConverted.aportes
    
    }
    console.log(dataConverted);
    return dataConverted;
}