/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import TextErrorFormik from '../../../../../../components/Formik/TextErrorFormik'
import { useSelector } from 'react-redux'
import { ISTATE } from '../../../../../../../setup/redux/interface/reduxState.interface'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { prepareDraft } from '../../../../Levantatuti/utils/utilsFunctions'
import draftToHtml from 'draftjs-to-html'

type Props = {
  data: any
}
const ConclusionEvento: FC<Props> = ({ data }) => {
  const [content, setContent] = useState<string>('');
  const eventosStore: any = useSelector((state: ISTATE) => state.eventos)
  const { acciones, formulario } = eventosStore
  const estado = formulario.estado
  const [editorState, setEditorState] = useState(data.conclusion ? prepareDraft(data.conclusion) : EditorState.createEmpty())

  const onEditorStateChange = (editorState: EditorState) => {

    const conclusion = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    data.conclusion = conclusion
    setEditorState(editorState)
  }
  return (
    <div className='w-100'>
      {((acciones.editar || estado === 'cancelado' || estado === 'realizado' || estado === 'en curso' || estado === 'planificado') && !acciones.guardar && acciones.conclusion) && (
        <div>
          <div className='pb-3 pb-lg-10'>
            <h3 className='fw-bolder d-flex align-items-center text-dark'>
              Conclusion del Evento estado bloqueado
            </h3>

          </div>

          <div className='mb-4 fv-row'>
            <label className='form-label mb-3'>Detalle de la Conclusión del evento</label>
            <Editor
              editorState={editorState}
              readOnly={true}
              editorClassName="border"
              toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
              wrapperClassName="demo-wrapper"
              onEditorStateChange={onEditorStateChange}
            />

          </div>

        </div>)}

      {(!acciones.conclusion && !acciones.guardar && !acciones.editar && !acciones.cancelar) && (
        <div>
          <div className='pb-3 pb-lg-10'>
            <h3 className='fw-bolder d-flex align-items-center text-dark'>
              Conclusion del Evento estado activo
            </h3>

          </div>

          <div className='mb-4 fv-row'>
            <label className='form-label mb-3'>Detalle de la Conclusión del evento</label>
            <Editor
              editorState={editorState}
              editorClassName="border"
              toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
              wrapperClassName="demo-wrapper"
              onEditorStateChange={onEditorStateChange}
            />
          </div>

        </div>)}


    </div>
  )
}

export { ConclusionEvento }
