/** React */
import { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
/** Formik */
import { ErrorMessage, Field, Form, Formik } from 'formik'
import SearchIcon from '@mui/icons-material/Search'
import FormikControl from '../../../../components/Formik/FormikControl'
/** Material - Sass - Custom Components*/
import { Button, Checkbox } from '@mui/material'
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import CancelIcon from '@mui/icons-material/Cancel'

import '../../../../../../src/_metronic/assets/sass/cud.scss'
import { MySelect } from '../components/Formik/MySelect'
/** Services & Interfaces */
import { getLegajoDni, getTramiteDni, updateTramite } from '../services/cud-service'
import { getJuntas } from '../services/cud-service'
import { getPersonaPadron } from '../../Sis-admin/services/padronService'
import { Ijunta, Ijuntas } from '../Interfaces/Ijunta'
/** Tables */
import Datos_Personales from '../components/Tablas_Tramites/Datos_Personales'
import Datos_Tutor from '../components/Tablas_Tramites/Datos_Tutor'
/** Hooks Swal */
import { EditTramite, RejectTramite } from '../hooks/useModal'
/** Moment */
import moment from 'moment'
import { ISTATE } from '../../../../../setup/redux/interface/reduxState.interface'
/** Helper */
import Swal from 'sweetalert2'
import { wsPersona } from '../../../../components/wsPersona'
import { validateSchemaDatosPersonales, validateSchemaDatosTutor } from '../Interfaces/Itramite'
import TextErrorFormik from '../../../../components/Formik/TextErrorFormik'
import { BootstrapTooltip } from '../helpers/bootstrapToolTip'
import { initTramitesN } from '../helpers/inits'
import { clippingParents } from '@popperjs/core'

export const TramitesEdit = () => {
  let existe: Boolean
  const dashState: any = useSelector((state: ISTATE) => state.auth)
  const location: any = useLocation()
  const [legajoEdit, setLegajoEdit] = useState(location.state)

  const [buscador, setBuscador] = useState<any>()
  const [buscadort, setBuscadort] = useState<any>()
  //is checke is false
  const [isChecked, setIsChecked] = useState(false)
  //
  const [formsecu, setFormsecu] = useState<any>(location.state)
  const [formCompleto, setFormCompleto] = useState<any>(initTramitesN)
  const [Disabled1, setDisabled1] = useState<any>(true)
  const [Disabled10, setDisabled10] = useState<any>(true)
  const [menor, setMenor] = useState<any>(false)
  const [Disabled20, setDisabled20] = useState<any>(true)

  const [tipoIndividuoP, setTipoIndividuoP] = useState<any>('persona')
  const [tipoIndividuoT, setTipoIndividuoT] = useState<any>('tutor')

  const [confirmar, setConfirmar] = useState<any>(false)
  const [formatoDisable, setFormatoDisable] = useState<any>(
    'card bg-ligth border mb-6 card_align5 disabled_formato'
  )
  const [opcionConfirma, setOpcionConfirma] = useState<any>(true)
  const [disableConfirma, setDisableConfirma] = useState<any>(false)
  const [formatoOpacity, setFormatoOpacity] = useState<any>('disabled_opacity')

  const [opcionConfirmat, setOpcionConfirmat] = useState<any>(true)
  const [disableConfirmat, setDisableConfirmat] = useState<any>(false)

  const [valueVehiculo, setValueVehiculo] = useState<any>(
    location.state.datosPersonales.vehiculoLey
  )

  const [valueSimbolo, setValueSimbolo] = useState<any>(
    location.state.datosPersonales.simboloInternacional
  )
  const [valueDerecho, setValueDerecho] = useState<any>(
    location.state.datosPersonales.derechoAsignacionesF
  )

  const [valueInteresadoApoyoJudicial, setValueInteresadoApoyoJudicial] = useState<any>(
    location.state.datosTutor?.interesadoApoyoJudicial
  )

  const [valueInteresadoApoyoExtraJudicial, setInteresadoApoyoExtraJudicial] = useState<any>(
    location.state.datosTutor?.interesadoApoyoExtraJudicial
  )

  const [valueCuradorDesignadoJudicial, setValueCuradorDesignadoJudicial] = useState<any>(
    location.state.datosTutor?.curadorDesignadoJudicial
  )

  const [btnActualizar, setBtnActualizar] = useState<any>(true)
  const [btnBuscarOtra, setBbuscarOtra] = useState<any>(true)
  const [btnConfirmar, setBtnConfirmar] = useState<any>(true)
  const [btnEditar, setBtnEditar] = useState<any>(true)
  const [disableTextC, setDisableTextC] = useState<any>(true)
  const [disableTextP, setDisableTextP] = useState<any>(true)
  const [btnDisable, setBtnDisable] = useState(true) // reemplazo por BOTON2

  const [formatoDisablet, setFormatoDisablet] = useState<any>(
    'card bg-ligth border mb-6 card_align5 disabled_formato'
  )
  const [formatoOpacityt, setFormatoOpacityt] = useState<any>('')
  const [confirmart, setConfirmart] = useState<any>(false)

  const [btnActualizart, setBtnActualizart] = useState<any>(true)
  const [btnBuscarOtrat, setBbuscarOtrat] = useState<any>(true)
  const [btnConfirmart, setBtnConfirmart] = useState<any>(true)
  const [btnEditart, setBtnEditart] = useState<any>(false)
  const [disableTextCt, setDisableTextCt] = useState<any>(false)
  const [disableTextPt, setDisableTextPt] = useState<any>(false)
  const [btnSwitch, setBtnSwitch] = useState<any>(false)
  const [alertaMayor, setAlertaMayor] = useState<boolean>(false)

  const [spinnerb, setSpinnerb] = useState(false)
  const [spinnerbt, setSpinnerbt] = useState(false)

  let boton: boolean = true
  let boton2: boolean = true
  let boton3: boolean = true
  let botonMenor: boolean = false

  let history = useHistory()
  let nacimiento: any
  let hoy1: any = moment().format('DD/MM/YYYY ')
  let hoy2 = moment(hoy1, 'DD/MM/YYYY')
  let anios: any

  const miCheck = (values: any) => {
    setIsChecked(!isChecked)
  }

  const eliminaTutor: any = () => {
    setFormsecu({ ...formsecu, datosTutor: initTramitesN.datosTutor })
    setIsChecked(false)
  }

  const reset = (individuo: any) => {
    if (individuo === 'persona') {
      setValueVehiculo('')
      setOpcionConfirma(false)
      setBtnConfirmar(true)
      setBtnEditar(false)
      setDisableTextC(true)
      setDisableTextP(false)
      setBtnActualizar(false)
      setBbuscarOtra(false)
      setFormatoDisable('card bg-ligth border mb-6 card_align5')
      setFormatoOpacity('')

      setFormsecu({
        ...formsecu,
        datosPersonales: initTramitesN.datosPersonales,
      })
    } else {
      setFormatoOpacityt('')
      setBtnConfirmart(true)
      setBtnEditart(false)
      setDisableTextCt(true)
      setDisableTextPt(false)
      setBtnActualizart(false)
      setBbuscarOtrat(false)
      setFormatoDisablet('card bg-ligth border mb-6 card_align5')
      setFormsecu({
        ...formsecu,
        datosTutor: initTramitesN.datosTutor,
      })
    }
  }

  const mostrar = (dni_buscado: string, genero_buscado: string, tipo_doc: string) => {
    setSpinnerb(true)

    getTramiteDni(dni_buscado).then((res: any) => {
      if (res.data.ok) {
        Swal.fire({
          title: 'Atención!',
          text: 'Ya existe un trámite en curso con ese DNI (Sin legajo aún)',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ver Trámite',
          cancelButtonText: 'Cerrar',
        }).then((result) => {
          if (result.isConfirmed) {
            const location = {
              pathname: '/cud/MesaEntrada',

              state: {
                dni: res.data.data.dni,
                activado: true,
              },
            }
            history.push(location)
          }
        })
        setSpinnerb(false)
      } else {
        getLegajoDni(dni_buscado).then(async (resp: any) => {
          if (resp.data.ok) {
            Swal.fire({
              title: 'Atención!',
              text: 'Ya existe un LEGAJO con ese DNI.',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ver Legajo',
              cancelButtonText: 'Cerrar',
            }).then((result) => {
              if (result.isConfirmed) {
                const locationl = {
                  pathname: '/cud/Legajos',

                  state: {
                    dni: res.data.data.dni,
                    activado: true,
                  },
                }
                history.push(locationl)
              }
            })
            setSpinnerb(false)
          } else {
            setSpinnerb(true)

            let data = await wsPersona(dni_buscado, genero_buscado, tipo_doc)
            if (data === 'Verificar los datos ingresados') {
              Swal.fire({
                title: 'Problemas al buscar la información',
                text: 'Verificar los datos ingresados',
                icon: 'warning',
              })
            } else {
              setDisabled10(false)
              setBuscador(data.datapersonal)
              setFormsecu({ ...formsecu, datosPersonales: data.datapersonal })
              setDisableTextC(false)
              setDisableTextP(true)
              setBtnActualizar(true)
              setBbuscarOtra(true)
              setBtnConfirmar(true)
              setOpcionConfirma(true)
            }
          }
        })
      }
    })
  }

  const mostrarEdit = async (
    dni_buscado_edit: string,
    genero_buscado_edit: string,
    tipoDoc_tuto_edit: any,
    values: any
  ) => {
    setSpinnerbt(true)

    let data = await wsPersona(dni_buscado_edit, genero_buscado_edit, tipoDoc_tuto_edit)

    if (location.state.datosPersonales.dni === dni_buscado_edit) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        text: 'El Tutor no puede ser la misma persona que el la solicitante',
      })
      setSpinnerbt(false)
    } else {
      if (data === 'Verificar los datos ingresados') {
        Swal.fire({
          title: 'Problemas al buscar la información',
          text: 'Verificar los datos ingresados',
          icon: 'warning',
        })
        setSpinnerbt(false)
      } else {
        setDisableTextCt(false)
        setDisableTextPt(true)
        setBtnActualizart(true)
        setBbuscarOtrat(true)
        setBtnConfirmart(false)

        setFormsecu({
          ...formsecu,
          junta: values.junta,
          datosTutor: data.datapersonal,
          datosPersonales: values.datosPersonales,
        })
        setSpinnerbt(false)
      }
    }
  }

  const editartramite = (values: any) => {
    values.usuarioModificacion = dashState.usuario.nombreCompleto
    values.fechaModificacion = moment().format('DD/MM/YYYY h:mm:ss')

    values.alertas.cambioMayor = alertaMayor

    updateTramite(values).then((res: any) => {
      if (res.data.ok) {
        EditTramite(history, values)
      } else {
        RejectTramite()
      }
    })
  }

  const handleOnChange = () => {
    setIsChecked(!isChecked)
  }

  useEffect(() => { }, [valueVehiculo])
  useEffect(() => { }, [location.state])
  useEffect(() => { }, [formatoDisablet])
  useEffect(() => { }, [disableTextCt])
  useEffect(() => { }, [btnDisable])
  useEffect(() => { }, [confirmart])
  useEffect(() => { }, [Disabled1])
  useEffect(() => { }, [alertaMayor])

  useEffect(() => { }, [spinnerb])

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <h5 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-5 mb-1 capital'>Editar tramite</span>
        </h5>
        <div className='card-toolbar'>
          <BootstrapTooltip title='Cancelar y Volver' arrow followCursor placement='top'>
            <Link
              to={{
                pathname: '/cud/MesaEntrada',
                state: {
                  activado: true,
                  obj: location.state,
                },
              }}
              className='alineacionb'
            >
              <CancelIcon className='error' />
            </Link>
          </BootstrapTooltip>{' '}
        </div>
      </div>
      <div className='card-body py-1'>
        <div className='table-responsive'>
          <Formik
            initialValues={formsecu}
            validateOnMount={true}
            validationSchema={validateSchemaDatosPersonales}
            onSubmit={editartramite}
            enableReinitialize
          >
            {(formik) => {
              if (formik.values.junta === '') {
                boton2 = false
              } else {
                boton2 = true
              }


              formik.values.datosPersonales.dni !== '' &&
                formik.values.datosPersonales.sexo !== '' &&
                formik.values.datosPersonales.tipoDoc !== ''
                ? (boton = false)
                : (boton = true);


              nacimiento = moment(formik.values.datosPersonales.fechaNacimiento)
              anios = hoy2.diff(nacimiento, 'year')

              return (
                <div>
                  <Form>
                    <div className='col-8 text-gray-700 fw-bold mt-2 fs-6 '>Datos Personales</div>
                    <div className={formatoDisable}>
                      <div className='row'>
                        <div className='col-lg-2'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            Tipo Documento
                          </label>

                          <Field
                            as='select'
                            id='datosPersonales.tipoDoc'
                            name='datosPersonales.tipoDoc'
                            className='form-control form-control-solid capital'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={disableTextP}
                          >
                            <option value=''>Seleccione</option>
                            <option value='DNI F'>DNI F</option>
                            <option value='DNI M'>DNI M</option>
                            <option value='DNI X'>DNI X</option>
                            <option value='LC'>LC</option>
                            <option value='PAS'>PAS</option>
                            <option value='LE'>LE</option>
                            <option value='CI'>CI</option>
                          </Field>

                          {formik.errors.tipoDoc ? <div>{formik.errors.tipoDoc}</div> : null}

                          <ErrorMessage
                            className='error'
                            component={TextErrorFormik}
                            name='datosPersonales.tipoDoc'
                          />
                        </div>
                        <div className='col-lg-2'>
                          <label className='form-label fw-bolder text-dark fs-6'>DNI</label>

                          <Field
                            control='input'
                            type='text'
                            placeholder='Ingrese DNI...'
                            id='datosPersonales.dni'
                            name='datosPersonales.dni'
                            className='form-control form-control-solid capital'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={disableTextP}
                          />

                          <ErrorMessage
                            name='datosPersonales.dni'
                            component={TextErrorFormik}
                            className='error'
                          />
                        </div>
                        <div className='col-lg-2'>
                          <label className='form-label fw-bolder text-dark fs-6'>Género</label>
                          <Field
                            as='select'
                            id='datosPersonales.sexo'
                            name='datosPersonales.sexo'
                            className='form-control form-control-solid capital'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={disableTextP}
                          >
                            <option value=''>Seleccione</option>
                            <option value='m'>Masculino</option>
                            <option value='f'>Femenino</option>
                            <option value='x'>Otro</option>
                          </Field>
                          <ErrorMessage
                            name='datosPersonales.sexo'
                            component={TextErrorFormik}
                            className='error'
                          />
                        </div>
                        <div className='col-lg-2'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            Tipo Nacionalidad
                          </label>

                          <Field
                            as='select'
                            id='datosPersonales.tipoNacionalidad'
                            name='datosPersonales.tipoNacionalidad'
                            className='form-control form-control-solid capital'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={disableTextP}
                          >
                            <option value=''>Seleccione</option>
                            <option value='nativo'>nativo</option>
                            <option value='naturalizado'>naturalizado</option>
                            <option value='extranjero'>extranjero</option>
                          </Field>

                          {formik.errors.tipoNacionalidad ? (
                            <div>{formik.errors.tipoNacionalidad}</div>
                          ) : null}

                          <ErrorMessage
                            className='error'
                            component={TextErrorFormik}
                            name='datosPersonales.tipoNacionalidad'
                          />
                        </div>

                        <div className='col-lg-4'>
                          <button
                            type='button'
                            hidden={btnActualizar}
                            disabled={boton}
                            className='btn btn-primary btn-sm btn-tramite'
                            onClick={(e: any) => {
                              mostrar(
                                formik.values.datosPersonales.dni,
                                formik.values.datosPersonales.sexo,
                                formik.values.datosPersonales.tipoDoc
                              )
                            }}
                          >
                            {spinnerb === true ? (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                <SearchIcon />
                                Buscando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            ) : (
                              <>
                                <SearchIcon />
                                Actualizar datos Persona
                              </>
                            )}
                          </button>

                          <button
                            hidden={!btnBuscarOtra}
                            type='reset'
                            className='btn btn-primary  btn-sm btn-tramite'
                            onClick={() => {
                              reset(tipoIndividuoP)
                              setDisabled1(true)
                            }}
                          >
                            <RestartAltIcon /> Buscar otra persona
                          </button>
                        </div>
                      </div>

                      <Datos_Personales
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        formsecu={formsecu}
                        setFormsecu={setFormsecu}
                        datosPersonales={buscador}
                        formik={formik}
                        valueVehiculo={valueVehiculo}
                        valueSimbolo={valueSimbolo}
                        valueDerecho={valueDerecho}
                        confirmar={confirmar}
                        setConfirmar={setConfirmar}
                        formatoDisable={formatoDisable}
                        setFormatoDisable={setFormatoDisable}
                        opcionConfirma={opcionConfirma}
                        setOpcionConfirma={setOpcionConfirma}
                        disableConfirma={disableConfirma}
                        setDisableConfirma={setDisableConfirma}
                        formatoOpacity={formatoOpacity}
                        setFormatoOpacity={setFormatoOpacity}
                        btnConfirmar={btnConfirmar}
                        setBtnConfirmar={setBtnConfirmar}
                        btnEditar={btnEditar}
                        setBtnEditar={setBtnEditar}
                        disableTextC={disableTextC}
                        setDisableTextC={setDisableTextC}
                        btnSwitch={btnSwitch}
                        setBtnSwitch={setBtnSwitch}
                        anios={anios}
                        setIsChecked={setIsChecked}
                        alertaMayor={alertaMayor}
                        setAlertaMayor={setAlertaMayor}
                      />
                    </div>

                    {formsecu.datosTutor ? (
                      <label className='check_label_posicion2 text-gray-700 check_tutor2'>
                        MODIFICAR TUTOR
                      </label>
                    ) : (
                      <label className='check_label_posicion2 text-gray-700 check_tutor2'>
                        AGREGAR TUTOR
                      </label>
                    )}
                    <Field
                      type='checkbox'
                      as={Checkbox}
                      id='tutor'
                      name='tutor'
                      className='check_label_posicion2 check_tutor2'
                      onChange={formik.handleChange}
                      onClick={() => {
                        miCheck(formik.values)
                      }}
                      onBlur={formik.handleBlur}
                      checked={isChecked}
                    />

                    {anios <= 16 && formik.values.datosTutor.dni === ''
                      ? (botonMenor = true)
                      : (botonMenor = menor)}

                    <h5 hidden={!botonMenor} className='error'>
                      La persona solicitante es menor de 16 años. Por favor agregue un TUTOR.
                    </h5>

                    <div className='row'>
                      <div className='col-lg-6'>
                        <br></br>
                        <button
                          type='submit'
                          disabled={!boton2 || botonMenor || !btnEditar}
                          /* hidden={!btnConfirmar || !isChecked} */
                          className='btn btn-primary'
                        >
                          Guardar Modificaciones
                        </button>
                      </div>
                    </div>
                  </Form>
                  {/* <pre>{JSON.stringify(formik.values.datosPersonales, null, 4)}</pre> */}
                </div>
              )
            }}
          </Formik>
          {confirmart === false
            ? (setBtnActualizart(true),
              setDisableTextCt(true),
              setDisableTextPt(true),
              setBtnEditart(true),
              setConfirmart(true),
              setFormatoOpacityt('disabled_opacity'))
            : null}
          <Formik
            initialValues={formsecu}
            validateOnMount={true}
            validationSchema={validateSchemaDatosTutor}
            enableReinitialize
            onSubmit={editartramite}

          // if (isChecked === false) {
          //   values.datosTutor = initTramitesN.datosTutor
          // }
          >
            {(formik) => {
              return (
                <div>
                  {formik.values.datosTutor?.dni === ''
                    ? (setBtnActualizart(false),
                      setBbuscarOtrat(false),
                      setDisableTextPt(false),
                      setBtnEditart(false),
                      setConfirmart(true),
                      setFormatoOpacityt(''),
                      setFormatoDisablet('card bg-ligth border mb-6 card_align5'))
                    : null}
                  <Form>
                    <div className=''>
                      {isChecked ? (
                        <>
                          <div className='{formatoDisablet} col-8 text-gray-700 fw-bold mt-2 fs-6'>
                            Datos Tutor
                          </div>

                          <div className={formatoDisablet}>
                            <div className='row'>
                              <div className='col-lg-2'>
                                <label className='form-label fw-bolder text-dark fs-6'>
                                  Tipo Documento
                                </label>
                                <Field
                                  as='select'
                                  id='datosTutor.tipoDoc'
                                  name='datosTutor.tipoDoc'
                                  className='form-control form-control-solid capital'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={disableTextPt}
                                >
                                  <option value=''>Seleccione</option>
                                  <option value='DNI F'>DNI F</option>
                                  <option value='DNI M'>DNI M</option>
                                  <option value='DNI X'>DNI X</option>
                                  <option value='LC'>LC</option>
                                  <option value='PAS'>PAS</option>
                                  <option value='LE'>LE</option>
                                  <option value='CI'>CI</option>
                                </Field>
                                {formik.errors.tipoDoc ? <div>{formik.errors.tipoDoc}</div> : null}

                                <ErrorMessage
                                  className='error'
                                  component={TextErrorFormik}
                                  name='datosTutor.tipoDoc'
                                />
                              </div>
                              <div className='col-lg-2'>
                                <label className='form-label fw-bolder text-dark fs-6'>DNI</label>
                                <Field
                                  control='input'
                                  type='text'
                                  id='datosTutor.dni'
                                  name='datosTutor.dni'
                                  label='Documento'
                                  placeholder='Ingrese DNI'
                                  className='form-control form-control-solid capital'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={disableTextPt}
                                />
                                <ErrorMessage
                                  name='datosTutor.dni'
                                  component={TextErrorFormik}
                                  className='error'
                                />
                              </div>
                              <div className='col-lg-2'>
                                <label className='form-label fw-bolder text-dark fs-6'>
                                  Género
                                </label>
                                <Field
                                  as='select'
                                  id='datosTutor.sexo'
                                  name='datosTutor.sexo'
                                  className='form-control form-control-solid capital'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={disableTextPt}
                                >
                                  <option value=''>Seleccione</option>
                                  <option value='m'>Masculino</option>
                                  <option value='f'>Femenino</option>
                                  <option value='x'>Otro</option>
                                </Field>
                                <ErrorMessage
                                  name='datosTutor.sexo'
                                  component={TextErrorFormik}
                                  className='error'
                                />
                              </div>
                              <div className='col-lg-2'>
                                {formik.values.datosTutor?.dni !== '' &&
                                  formik.values.datosTutor?.sexo !== '' &&
                                  formik.values.datosTutor?.tipoDoc !== ''
                                  ? (boton3 = false)
                                  : (boton3 = true)}
                                {/* {location.state.datosTutor.dni !== '' ? ( */}
                                <button
                                  type='button'
                                  className='btn btn-primary btn-sm btn-tramite'
                                  hidden={btnActualizart}
                                  disabled={boton3}
                                  onClick={(e: any) => {
                                    mostrarEdit(
                                      formik.values.datosTutor.dni,
                                      formik.values.datosTutor.sexo,
                                      formik.values.datosTutor.tipoDoc,
                                      formik.values
                                    )
                                  }}
                                >
                                  {spinnerbt === true ? (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                      <SearchIcon />
                                      Buscando...
                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                  ) : (
                                    <>
                                      <SearchIcon />
                                      Buscar
                                    </>
                                  )}{' '}
                                </button>
                                {/* ) : ( */}
                                <button
                                  hidden={!btnBuscarOtrat}
                                  type='reset'
                                  className='btn btn-primary  btn-sm btn-tramite'
                                  onClick={() => {
                                    reset(tipoIndividuoT)
                                    boton3 = true
                                  }}
                                >
                                  <RestartAltIcon /> Buscar otra persona
                                </button>
                                {/* )} */}
                              </div>

                              <div className='col-lg-4'>
                                <button
                                  type='reset'
                                  className='btn btn-danger btn-sm btn-tramite btn-salir'
                                  onClick={() => {
                                    eliminaTutor()
                                  }}
                                >
                                  Eliminar Tutor
                                </button>
                              </div>
                            </div>
                            <Datos_Tutor
                              handleChange={formik.handleChange}
                              handleBlur={formik.handleBlur}
                              existeTutor={existe}
                              datosTutor={buscadort}
                              formik={formik}
                              formSecu={formsecu}
                              setFormSecu={setFormsecu}
                              Check={isChecked}
                              tramite={legajoEdit}
                              menor={botonMenor}
                              valueInteresadoApoyoJudicial={valueInteresadoApoyoJudicial}
                              valueInteresadoApoyoExtraJudicial={valueInteresadoApoyoExtraJudicial}
                              valueCuradorDesignadoJudicial={valueCuradorDesignadoJudicial}
                              confirmart={confirmart}
                              setConfirmart={setConfirmart}
                              setFormatoDisablet={setFormatoDisablet}
                              opcionConfirmat={opcionConfirmat}
                              setOpcionConfirmat={setOpcionConfirmat}
                              setDisableConfirmat={setDisableConfirmat}
                              formatoOpacityt={formatoOpacityt}
                              setFormatoOpacityt={setFormatoOpacityt}
                              Disabled20={Disabled20}
                              setDisabled20={setDisabled20}
                              btnConfirmart={btnConfirmart}
                              setBtnConfirmart={setBtnConfirmart}
                              btnEditart={btnEditart}
                              setBtnEditart={setBtnEditart}
                              disableTextCt={disableTextCt}
                              setDisableTextCt={setDisableTextCt}
                              btnSwitch={btnSwitch}
                              setBtnSwitch={setBtnSwitch}
                              anios={anios}
                            />
                          </div>
                        </>
                      ) : null}

                      <FormikControl
                        control='input'
                        type='hidden'
                        id='fecha'
                        name='fecha'
                        className='input_hidden'
                        value={moment().format('DD/MM/YYYY')}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />

                      <Field
                        type='checkbox'
                        id='activo'
                        name='activo'
                        value='true'
                        className='input_hidden'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <Field
                        type='text'
                        id='metodo'
                        name='metodo'
                        value='local'
                        className='input_hidden'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />

                      <div className='row'>
                        <div className='col-lg-6'>
                          <BootstrapTooltip title='Confirmar' arrow followCursor placement='top'>
                            <button
                              type='submit'
                              disabled={btnSwitch || !boton2}
                              hidden={!btnConfirmart || !isChecked}
                              className='btn btn-primary'
                            >
                              Guardar Modificaciones
                            </button>
                          </BootstrapTooltip>
                        </div>
                      </div>
                    </div>
                  </Form>
{/*                   <pre>
                    {JSON.stringify(formik, null, 4)}
                  </pre> */}
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}
export default TramitesEdit
