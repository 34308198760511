/* import styled from '@emotion/styled'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip' */
import clsx from 'clsx'
import { FC } from 'react'/* 
import { useSelector } from 'react-redux'
import { ISTATE } from '../../../../setup/redux/interface/reduxState.interface' */
import { KTSVG } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarButtonIconSizeClass = ''
const Topbar: FC = () => {
  /*  let fuente: any = '' */
  /*  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
     <Tooltip {...props} classes={{ popper: className }} />
   ))(({ theme }) => ({
     [`& .${tooltipClasses.tooltip}`]: {
       backgroundColor: `${fuente}`,
       color: `#FFFFFF`,
       fontSize: 12,
       fontWeightBold: 700,
       maxWidth: 200,
     },
   })) */
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* Search */}
        <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
          {/*  <Search /> */}
        </div>
        {/* Activities */}
        <div className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}>
          {/* begin::Drawer toggle */}
          {/* <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            id='kt_activities_toggle'
          >
             <KTSVG
              path='/media/icons/duotune/general/gen032.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div> */}
          {/* end::Drawer toggle */}
        </div>
        {/* Quick links */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu wrapper */}
          {/* <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen025.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div> */}
          {/* <QuickLinks /> */}
          {/* end::Menu wrapper */}
        </div>

        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <div
            className={clsx(
              'btn btn-icon btn-active-light-secondary position-relative',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <div className="sombras-2">
            <KTSVG
              path='/media/icons/duotune/communication/com006.svg'
              className={'svg-icon-3x'}
            />
            </div>
          </div>
          <HeaderUserMenu />
        </div>
        {/* CHAT */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu wrapper */}
          {/* <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            id='kt_drawer_chat_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/communication/com012.svg'
              className={toolbarButtonIconSizeClass}
            />

            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
          </div> */}
          {/* end::Menu wrapper */}
        </div>

        {/* NOTIFICATIONS */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu- wrapper */}
          {/* <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen022.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div> */}
          {/*  <HeaderNotificationsMenu /> */}
          {/* end::Menu wrapper */}
        </div>

        {/* begin::User */}

        {/* end::User */}
      </div>
    </div>
  )
}

export { Topbar }
