import React from 'react'
import { useSelector } from 'react-redux'
import { ISTATE } from '../../../../../../setup/redux/interface/reduxState.interface'
import BotoneraCalendario from '../Botones/BotoneraCalendario'

const HeaderIndicadores = () => {

  const dashState: any = useSelector((state: ISTATE) => state.areas)
  const { areas } = dashState
  return (
    <div>
      <div className='card-header align-items-center border-1 bg-light-gray-500'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-blanco'>Listado de Actividades Generales del Ministerio</span>
        </h3>
        <BotoneraCalendario compActivo={'indicadores'} />
      </div>
    </div >
  )
}

export default HeaderIndicadores