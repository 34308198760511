import { calTypes } from "../types/types"


export const areasCargar = (areas: any) => ({
  type: calTypes.setAreas,
  payload: {
    areas,
  },
})


export const RemAreasAction = () => ({
  type: calTypes.remAreas,
})
