import React, {useContext, useEffect, useState} from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {BotonAgendaEvento} from './Botones/BotonAgendaEvento'
import {alpha} from '@mui/material/styles'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import {visuallyHidden} from '@mui/utils'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
import {evCargarFiltro, eventoCargarFull} from '../Redux/actions/eventos'
import {IEventos} from '../pages/CrearEventoPage/Steps/EventoData'

interface Data {
  area: string
  titulo: string
  inicio: string
  fin: string
  localidad: string
  estado: string
  presMinistro: string
  conclusion: string
  ver: string
}
interface EventoTabla {
  area: string
  titulo: string
  inicio: any
  fin: any
  localidad: string
  estado: string
  presMinistro: boolean
  conclusion: string
  color: string
  responsable: string
  descripcion: string
  lugar: string
  evento: IEventos
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: {[key in Key]: number | string}, b: {[key in Key]: number | string}) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'area',
    numeric: false,
    disablePadding: true,
    label: 'Area',
  },
  {
    id: 'titulo',
    numeric: false,
    disablePadding: false,
    label: 'Nombre de la Actividad',
  },
  {
    id: 'inicio',
    numeric: false,
    disablePadding: false,
    label: 'Inicia',
  },
  {
    id: 'fin',
    numeric: false,
    disablePadding: false,
    label: 'Finaliza',
  },
  {
    id: 'localidad',
    numeric: false,
    disablePadding: true,
    label: 'Localidad',
  },
  {
    id: 'estado',
    numeric: false,
    disablePadding: true,
    label: 'Estado',
  },
  {
    id: 'presMinistro',
    numeric: false,
    disablePadding: true,
    label: 'PM',
  },
  {
    id: 'conclusion',
    numeric: false,
    disablePadding: true,
    label: 'CON',
  },
  {
    id: 'ver',
    numeric: false,
    disablePadding: true,
    label: 'Ver',
  },
]

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            /* align={headCell.numeric ? 'right' : 'left'} */
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  numSelected: number
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const {numSelected} = props

  return (
    <Toolbar
      sx={{
        pl: {sm: 2},
        pr: {xs: 1, sm: 1},
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{flex: '1 1 100%'}} color='inherit' variant='subtitle1' component='div'>
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{flex: '1 1 100%'}} variant='h6' id='tableTitle' component='div'>
          Eventos
        </Typography>
      )}

      <Tooltip title='Filtrar el listado'>
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )
}

type Props = {
  dependencia: string
  datos: any
}

const TablaEventosMat: React.FC<Props> = ({dependencia, datos}) => {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('inicio')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const dispatch = useDispatch()
  const eventoState: any = useSelector((state: ISTATE) => state.eventos)
  const {componente, evFiltro} = eventoState
  const menuBotonState: any = useSelector((state: ISTATE) => state.menuBoton)
  const {seleccion} = menuBotonState
  const usrState: any = useSelector((state: ISTATE) => state.auth)
  const {usuario} = usrState
  let areaPUsuario = usuario.organizacion.datosOrganismo.areaPrincipal.idAreaP
  const [eventosArea, setEventosArea] = useState<any>([])
  let temp: any = []

  const ordenarEventos = (ev: any) => {
    ev.sort(function compare(a: any, b: any) {
      if (a.start < b.start) {
        return -1
      }
      if (a.start > b.start) {
        return 1
      }
      return 0
    })
    return ev
  }

  const cargarEventosTabla = () => {
    let eventos = ordenarEventos(evFiltro)
    let evTemp: EventoTabla[] = []
    eventos.map((evento: IEventos) => {
      evTemp.push({
        area: evento.area,
        titulo: evento.title,
        inicio: evento.start,
        fin: evento.end,
        localidad: evento.localidad,
        estado: evento.estado,
        presMinistro: evento.presMinistro,
        conclusion: evento.conclusion,
        responsable: evento.usuarioAsignado,
        color: evento.color,
        descripcion: evento.descripcion,
        lugar: evento.lugar,
        evento: evento,
      })
    })
    setEventosArea(evTemp)
  }

  useEffect(() => {
    cargarEventosTabla()
  }, [])

  useEffect(() => {
    cargarEventosTabla()
  }, [seleccion, evFiltro, componente])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = evFiltro.map((n: any) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - evFiltro.length) : 0

  function Row(props: {row: EventoTabla}) {
    const {row} = props
    const [open, setOpen] = React.useState(false)

    return (
      <React.Fragment>
        <TableRow>
          <TableCell className='sinlinea pad-fleha-tabla'>
            <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align='center' component='th' scope='row'>
            <div className='d-flex align-items-center'>
              <div className='timeline-badge'>
                <i className={'fa fa-circle  fs-2 sinlinea'} style={{color: row.color}}></i>
              </div>
              <div className='col-11 fs-8 capital sinlinea'>{row.area}</div>
            </div>
          </TableCell>
          <TableCell className='fs-8 capital sinlinea' align='left'>
            {row.titulo}
          </TableCell>
          <TableCell className='fs-8 capital sinlinea' align='left'>
            <div>{moment(row.inicio).format('DD/MM/yy, HH:mm').toString()}</div>
          </TableCell>
          <TableCell className='fs-8 capital sinlinea' align='left'>
            <div>{moment(row.fin).format('DD/MM/yy, HH:mm').toString()}</div>
          </TableCell>
          <TableCell className='fs-8 capital sinlinea' align='center'>
            {row.localidad}
          </TableCell>
          {row.estado === 'planificado' && (
            <TableCell align='center'>
              {' '}
              <span className='badge badge-light-warning fs-8 fw-bold capital sinlinea'>
                planificado
              </span>
            </TableCell>
          )}
          {row.estado === 'en curso' && (
            <TableCell align='center'>
              {' '}
              <span className='badge badge-light-info fs-8 fw-bold capital sinlinea'>en curso</span>
            </TableCell>
          )}
          {row.estado === 'realizado' && (
            <TableCell align='center'>
              {' '}
              <span className='badge badge-light-success fs-8 fw-bold capital sinlinea'>
                realizado
              </span>
            </TableCell>
          )}
          {row.estado === 'cancelado' && (
            <TableCell align='center'>
              {' '}
              <span className='badge badge-light-danger fs-8 fw-bold capital sinlinea'>
                cancelado
              </span>
            </TableCell>
          )}
          <TableCell align='center'>
            {row.presMinistro && <i className={'fa fa-circle fs-6'} style={{color: 'grey'}}></i>}
          </TableCell>
          <TableCell align='center'>{row.conclusion === '' ? 'No' : 'Si'}</TableCell>
          <TableCell align='center'>
            <div className='d-flex align-items-center '>
              <BotonAgendaEvento
                pag_inicio={'/agenda/calendario/evento'}
                nombre='Ver Evento'
                imagen='/abstract/abs014.svg'
                colorFondo='btn-active-light-ver'
                sizeBoton='w-20px h-20px w-md-30px h-md-30px'
                sizeIcon='size-icon'
                eventoMostrar={row.evento}
              />
            </div>
          </TableCell>
        </TableRow>
        <TableRow className='clarito'>
          <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{margin: 1}}>
                <Table size='small' aria-label='Descripcion' style={{width: '100%'}}>
                  <TableHead style={{width: '100%'}}>
                    <TableRow>
                      <TableCell className='fs-8 fw-bold'>Nombre Responsable</TableCell>
                      <TableCell className='fs-8 fw-bold' align='center'>
                        Descripcion
                      </TableCell>
                      <TableCell className='fs-8 fw-bold' align='center'>
                        Lugar
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{width: '100%'}}>
                    <TableRow key={row.evento._id}>
                      <TableCell className='fs-8 capital sinlinea' component='th' scope='row'>
                        {row.responsable}
                      </TableCell>
                      <TableCell className='fs-8 sinlinea' align='center'>
                        {row.descripcion}
                      </TableCell>
                      <TableCell className='fs-8 capital sinlinea' align='center'>
                        {row.lugar}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }
  return (
    <>
      {eventosArea && (
        <Box sx={{width: '100%'}}>
          <Paper sx={{width: '100%', mb: 2}}>
            <TableContainer>
              <Table
                sx={{minWidth: 750}}
                aria-labelledby='tableTitle'
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={eventosArea.length}
                />
                <TableBody>
                  {stableSort(eventosArea, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`
                      return <Row key={row._id} row={row} />
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 40, 60, 80, 100, 1000]}
              component='div'
              count={eventosArea.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  )
}

export default TablaEventosMat
