import Modal from 'react-modal'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { uiCloseModal } from '../Redux/actions/ui'
import 'moment/locale/es'
import { BotonAgendaEvento } from './Botones/BotonAgendaEvento'
import { useEffect } from 'react'

Modal.setAppElement('#root')

moment.locale('es')

export const CalendarioModal: React.FC = () => {
  const dispatch = useDispatch()
  const { modalOpen } = useSelector((state: any) => state.ui)
  const { formulario } = useSelector((state: any) => state.eventos)

  const closeModal = () => {
    dispatch(uiCloseModal())
  }
  useEffect(() => {
    //console.log('Se modifico el formulario', formulario)
  }, [formulario])


  return (
    <div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        className='modalCalendario'
        overlayClassName='modalCalendario-fondo'
      >
        <div className='px-2 pt-2'>
          <label>Título del Evento</label>
          <br />
          <h3 className='uppercase'>{formulario?.title}</h3>
        </div>
        <div className='row px-2 mt-4'>
          <div>
            <label>Organismo:</label>
            <strong className='uppercase'> {formulario?.organizacion}</strong>
          </div>
          <div>
            <label>Área:</label>
            <strong className='uppercase'> {formulario?.area}</strong>
          </div>
        </div>
        <div className='px-2 '>
          <hr className='separador-curvo' style={{ borderTopColor: formulario?.color, backgroundColor: 'white' }}></hr>
        </div>
        <div className='row p-2'>
          <div className='col-6'>
            <label>Fecha y Hora de Inicio:</label>
            <br />
            <strong className='capital'>{moment(formulario?.start).format('ddd DD MMMM yyyy - HH:mm')}</strong>
          </div>
          <div className='col-6'>
            <label>Fecha y Hora de Finalización:</label>
            <br />
            <strong className='capital'>{moment(formulario?.end).format('ddd DD MMMM yyyy - HH:mm')}</strong>
          </div>
        </div>
        <div className='row p-2 mt-2'>
          <div className='col-12'>
            <label>A realizase en:</label>
            <strong className='uppercase'> {formulario?.lugar}</strong>
            <br />
            <label>Dirección:</label>
            <strong className='uppercase'>
              {' '}
              {formulario?.calle} {formulario?.numero}, {formulario?.localidad} - {formulario?.provincia} -{' '}
              {formulario?.pais}
            </strong>
          </div>
        </div>
        <div className='p-2 mt-2'>
          <label className='pb-2'>Descripción del Evento</label>
          <br />
          <textarea
            className='form-control'
            placeholder='Notas'
            rows={4}
            name='notas'
            value={formulario?.descripcion}
          ></textarea>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='d-flex align-items-center px-2 pt-2 texto-izquierda '>
              <label>
                Estado de la Actividad:

                {formulario?.estado === 'planificado' && (
                  <strong className='badge badge-light-warning fs-6 fw-bold capital pl-1'>
                    {formulario?.estado}{' '}
                  </strong>
                )}
                {formulario?.estado === 'en curso' && (
                  <strong className='badge badge-light-info fs-6 fw-bold capital pl-1'>
                    {formulario?.estado}{' '}
                  </strong>
                )}
                {formulario?.estado === 'realizado' && (
                  <strong className='badge badge-light-success fs-6 fw-bold capital pl-1'>
                    {formulario?.estado}{' '}
                  </strong>
                )}
                {formulario?.estado === 'cancelado' && (
                  <strong className='badge badge-light-danger fs-6 fw-bold capital pl-1'>
                    {formulario?.estado}{' '}
                  </strong>
                )}
              </label>
              <BotonAgendaEvento
                pag_inicio={'/agenda/calendario/evento'}
                nombre='Ver Evento'
                imagen='/abstract/abs014.svg'
                colorFondo='btn-active-light-ver'
                sizeBoton='w-20px h-20px w-md-30px h-md-30px'
                sizeIcon='size-icon'
                eventoMostrar={formulario}
                componente={'Modal'}
              />
            </div>
          </div>
          <div className='col'>
            <div className='px-2 pt-2 texto-derecha'>
              <label>
                Usuario de Carga: <strong className='capital'>{formulario?.usuarioCarga} </strong>
              </label>
              <br />
              <label>
                Usuario Asignado:{' '}
                <strong className='capital'>{formulario?.usuarioAsignado} </strong>
              </label>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
