/** React */
import React, {useEffect, useState} from 'react'
/** Formik */
import {Field} from 'formik'
/** Material */
import FormikControl from '../../../../../components/Formik/FormikControl'

import {Button} from '@mui/material'
import Select from 'react-select'

import {createFilter} from 'react-select'
import {Icie10s} from '../../Interfaces/Ilegajo'
import {getCie10} from '../../services/cud-service'
import makeAnimated from 'react-select/animated'
import moment from 'moment'

type Props = {
  handleChange: any
  handleBlur: any
  btnEdit?: any
  formik?: any
}

export const Cud: React.FC<Props> = ({handleChange, handleBlur, btnEdit, formik}) => {
  let selectRef: any = null
  let toDay = moment().format('DD/MM/YYYY')

  const [btnEdita, setBtnEdita] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [dataCie10, setDataCie10] = useState<Icie10s>([])
  const [datosCie10, setDatosCie10] = useState<Icie10s>([])

  let dataCIE: any = []
  const MIN_INPUT_LENGTH = 3
  const animatedComponents = makeAnimated()

  // const [vista, setVista] = useState(false);

  const handleBtn = () => {
    setBtnEdita(!btnEdita)
  }

  const miCheck = (values: any) => {
    setIsChecked(!isChecked)
  }

  const filterOption = (candidate: any, input: any) => {
    return (
      // Min input length
      input.length >= MIN_INPUT_LENGTH &&
      // Use Select's default filtering for string matching by creating filter
      createFilter({})(candidate, input)
    )
  }
  const noOptionsMessage = (input: any) => {
    return input.length >= MIN_INPUT_LENGTH
      ? 'No se encuentran resultados.'
      : 'Ingrese por lo menos 3 caracteres.'
  }

  const muestra = async () => {
    const res: any = await getCie10()
    setDataCie10(res.data.cie10)
  }

  const eliminardata = () => {
    selectRef.clearValue()
    setDatosCie10([])
    formik.values.cie10 = []
  }

  const cargarValueSelect = (): [] => {
    let dataSelect: any = []
    for (let data of formik.values.cie10) {
      let cieIndex = dataCie10.findIndex((cie) => cie.label === data)
      cieIndex !== -1 && dataSelect.push(dataCie10[cieIndex])
    }

    return dataSelect
  }

  useEffect(() => {
    let isUmounted = true

    muestra()

    return () => {
      isUmounted = false
    }
  }, [])
  useEffect(() => {
    cargarValueSelect()
    return () => {}
  }, [formik])

  return (
    <>
      {/* Inputs de parte inicial del formulario */}
      <div className='row alineacion_inputs'>
        <div className='col-lg-4'>
          <label className='form-label fw-bolder text-dark fs-6'>Estado Trámite</label>
          <Field
            as='select'
            id='estado'
            name='estado'
            className='form-control form-control-solid capital'
            onChange={formik.handleChange}
            disabled={!btnEdit}
            onBlur={formik.handleBlur}
            required={true}
          >
            <option>Seleccione una Opción</option>
            <option value='pendiente'>Pendiente</option>
            <option value='aprobado'>Aprobado</option>
            <option value='rechazado'>Rechazado</option>
            <option value='archivado'>Archivar</option>
          </Field>
        </div>
        <div className='col-lg-4'>
          <FormikControl
            control='input'
            type='date'
            max={toDay}
            id='fechaInicio'
            name='fechaInicio'
            label='Fecha inicio del trámite'
            disabled={!btnEdit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
        </div>
        {formik.values.estado !== 'pendiente' && (
          <div className='col-lg-4'>
            <FormikControl
              control='input'
              type='date'
              id='fechaCierre'
              name='fechaCierre'
              max={toDay}
              label='Fecha cierre del trámite'
              disabled={!btnEdit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
        )}
      </div>
      <br /> <br />
      {formik.values.estado === 'aprobado' && (
        <>
          <div className='row alineacion_inputs'>
            <div className='col-lg-5'>
              <label className='form-label fw-bolder text-dark fs-6'>Estado del certificado</label>
              <Field
                as='select'
                id='infoCertificado.estadoCertificado'
                name='infoCertificado.estadoCertificado'
                className='form-control form-control-solid capital'
                onChange={formik.handleChange}
                disabled={!btnEdit}
                onBlur={formik.handleBlur}
                required
              >
                <option>Seleccione una Opción</option>
                <option value='vigente'>Vigente</option>
                <option value='devuelto'>Devuelto</option>
                <option value='perdido'>Perdido</option>
                <option value='renovado'>Renovado</option>
              </Field>
            </div>

            <div className='col-lg-5'>
              <FormikControl
                control='input'
                type='text'
                id='infoCertificado.nroCud'
                name='infoCertificado.nroCud'
                className='form-control form-control-solid capital'
                label='Nro Cud'
                disabled={!btnEdit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <br />
          <br />

          <div className='row alineacion_inputs'>
            <div className='col-lg-5'>
              <FormikControl
                control='input'
                type='date'
                max={toDay}
                id='infoCertificado.fechaEntregaCert'
                name='infoCertificado.fechaEntregaCert'
                label='Fecha de entrega del certificado'
                disabled={!btnEdit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
            </div>

            <div className='col-lg-5'>
              <FormikControl
                control='input'
                type='date'
                id='infoCertificado.fechaVencimiento'
                name='infoCertificado.fechaVencimiento'
                label='Fecha de Caducidad'
                disabled={!btnEdit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
        </>
      )}
      <br /> <br />
      <div className='row alineacion_inputs'>
        <div className='col-lg-12'>
          <FormikControl
            control='textarea'
            type='text'
            id='observacion'
            name='observacion'
            label='Observaciones'
            disabled={!btnEdit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
      <br /> <br />
      <div className='row alineacion_inputs'>{/* find de los  Inputs */}</div>
      <br /> <br />
      {/* Checkbox Formulario CUD */}
      <div className='col-8 text-gray-600 fw-bold mt-2 fs-6'>Origenes</div>
      <div className='card bg-ligth border mb-6 card_align5 '>
        <div className='row alineacion_inputs'>
          <div className='col-lg-4'>
            <Field
              type='checkbox'
              id='problemas_origenes.motor'
              name='problemas_origenes.motor'
              className=''
              disabled={!btnEdit}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label className=''>Motor</label>
          </div>
          <div className='col-lg-4'>
            <Field
              type='checkbox'
              id='problemas_origenes.mental'
              name='problemas_origenes.mental'
              className=''
              disabled={!btnEdit}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label className=''>Mental</label>
          </div>
          <div className='col-lg-4'>
            <Field
              type='checkbox'
              id='problemas_origenes.auditivo'
              name='problemas_origenes.auditivo'
              className=''
              disabled={!btnEdit}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label className=''>Auditivo</label>
          </div>
        </div>
        <div className='row alineacion_inputs'>
          <div className='col-lg-4  '>
            <Field
              type='checkbox'
              id='problemas_origenes.visual'
              name='problemas_origenes.visual'
              className=''
              disabled={!btnEdit}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label className=''>Visual</label>
          </div>
          <div className='col-lg-4'>
            <Field
              type='checkbox'
              id='problemas_origenes.visceral'
              name='problemas_origenes.visceral'
              className=''
              disabled={!btnEdit}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label className=''>Visceral</label>
          </div>
          <div className='col-lg-4'>
            <Field
              type='checkbox'
              id='problemas_origenes.intelectual'
              name='problemas_origenes.intelectual'
              className=''
              disabled={!btnEdit}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label className=''>Intelectual</label>
          </div>
        </div>
      </div>
      {/* fin checkbox  */}
      <br /> <br />
      {/*  seleccion del Cie10 */}
      <div>
        <div className='col-8 text-gray-600 fw-bold mt-2 fs-6'>
          Clasificación Internacional de Enfermedades 10º
        </div>
        <div className='card bg-ligth border mb-6 card_align5 '>
          <div className='row alineacion_inputs'>
            <div className='col-lg-12'>
              {formik.values.cie10?.map((datos: any, index: any) => {
                return (
                  <span key={index}>
                    <strong>{datos}</strong> &nbsp;&nbsp;&nbsp;
                  </span>
                )
              })}
            </div>
            <div className='col-lg-12'>
              <br></br>
              <Select
                className='form-label form-control-solid'
                ref={(ref) => {
                  selectRef = ref
                }}
                value={cargarValueSelect()}
                isMulti
                id='cie10'
                name='cie10'
                options={dataCie10}
                openMenuOnClick={false}
                components={animatedComponents}
                closeMenuOnSelect={true}
                placeholder={'Agregar Clasificación Internacional de Enfermedad 10º'}
                filterOption={filterOption}
                noOptionsMessage={noOptionsMessage}
                isDisabled={!btnEdit}
                onBlur={formik.handleBlur}
                onChange={(e: any) => {
                  if (e.length > formik.values.cie10.length) {
                    for (let tem of e) {
                      let dataCie10 = formik.values.cie10.findIndex(
                        (element: any) => element === tem.label
                      )
                      dataCie10 === -1 && formik.values.cie10.push(tem.label)
                    }
                    setDatosCie10(formik.values.cie10)
                    dataCIE = formik.values.cie10
                  } else {
                    let deletElement: any = []
                    for (let tem of e) {
                      let dataCie10 = deletElement.findIndex(
                        (element: any) => element === tem.label
                      )
                      dataCie10 === -1 && deletElement.push(tem.label)
                    }

                    setDatosCie10(deletElement)
                    dataCIE = deletElement
                    formik.values.cie10 = deletElement
                  }
                }}
              ></Select>
            </div>
            <div className='col-lg-2'>
              <Button disabled={!btnEdit} className='btn btn-danger btn-sm' onClick={eliminardata}>
                x
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
