import {IPERFIL} from '../../../app/modules/auth/models/DashModel'
import {types} from '../types'

export const DashAction = (nombre: string, perfil: IPERFIL, isLogged: boolean, primerLogin: boolean, modOk: []) => ({
  type: types.Dashboard,
  payload: {
    nombre,
    perfil,
    isLogged,
    primerLogin,
    modOk,
  },
})

export const logoutDashboard = () => ({
  type: types.logoutDashboard,
})
