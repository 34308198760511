import React, { FC } from 'react'

const PDFToggleDrawer: FC = () => (
  <button
    id='kt_help_toggle'
    className='btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0'
    title='Imprimir o Exportar a PDF'
    data-bs-toggle='tooltip'
    data-bs-placement='left'
    data-bs-dismiss='click'
    data-bs-trigger='hover'
  >
    <span id='kt_engage_demos_label'>PDF</span>
  </button>
)

export { PDFToggleDrawer }
