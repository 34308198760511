/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { toAbsoluteUrl, KTSVG } from '../../../helpers'
import moment from 'moment'
import { ModalPeriodos } from '../../../../app/modules/apps/EmpleoJoven/pages/ModalPeriodos'
import { IEmpleados } from '../../../../app/modules/apps/EmpleoJoven/interfaces/IEmpleados'
import useAreaUser from '../../../../app/Hooks/useAreaUser'
import { useDispatch } from 'react-redux'

type Props = {
  formValue: any
  color?: string
  empleado: any
  online?: boolean
  name: string
  job: string
  altaAfip: string
  altaSistema: string
}

const Card3: FC<Props> = ({
  formValue,
  color = '',
  empleado,
  online = false,
  name,
  job,
  altaAfip,
  altaSistema,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [mostrarBtnCambiar, setMostrarBtnCambiar] = useState<Boolean>(false)
  const [estadoMes, setEstadoMes] = useState<boolean>(false)
  const [estadoDocEmpleado, setEstadoDocEmpleado] = useState<string>('');
  const [selecEmp, setSelecEmp] = useState<IEmpleados>()
  const [titulo, setTitulo] = useState<string>('');
  const { areaUsr } = useAreaUser();

  const dispatch = useDispatch();

  const [total, setTotal] = useState<number>(0)
  const calcTotal = () => {
    let inicio = 50
    empleado.descuentos.genero && (inicio += 10)
    empleado.descuentos.primer_empleo && (inicio += 15)
    empleado.descuentos.cert_capacitacion && (inicio += 5)
    empleado.descuentos.emp_radicada && (inicio += 10)
    empleado.descuentos.titular_prog_social && (inicio += 10)

    setTotal(inicio)
  }

  const verificarEstadoMes = () => {
    let anioIndex = empleado.anio.findIndex((element: any) => element.anio === formValue.anio);

    let mesIndex = empleado.anio[anioIndex]?.meses.findIndex(
      (data: any) => data.mes === formValue.mes
    )
    empleado.anio[anioIndex].meses.length && setMostrarBtnCambiar(true);
    setEstadoMes(true);
    setEstadoDocEmpleado(empleado.anio[anioIndex].meses[mesIndex]?.estado);
    areaUsr === 'asesores pyme' ? setTitulo('cambiar estado cpyme') : setTitulo('periodos cargados');

  }
  const closeModal = () => {
    setOpenModal(!openModal)
    dispatch({
      type: 'SET_ESTADOS_DOCUMENTOS',
      payload: {
        cuit: '',
        anio: '',
        mes: '',
        modificated: false,
      }
    })
  }
  useEffect(() => {
    setEstadoMes(false)
    empleado && verificarEstadoMes()
    empleado && calcTotal()
    return () => { }
  }, [empleado]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='card'>
      <div className='card-body d-flex flex-center flex-column p-9'>
        <div className='mb-5'>
          <div className='symbol symbol-75px symbol-circle'>
            {color ? (
              <span className={`symbol-label bg-light-primary text-success fs-5 fw-bolder`}>
                {name.charAt(0)}
              </span>
            ) : (
              <img alt='Pic' src={toAbsoluteUrl('/media/svg/avatars/blank.svg')} />
            )}
            {online && (
              <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div>
            )}
          </div>
        </div>

        <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0 capital'>
          {name}
        </a>

        <div className='fw-bold text-gray-400 mb-6'> DNI - {job}</div>

        <div className='d-flex flex-center flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>
              {moment.utc(altaAfip).format('DD/MM//YYYY')}
            </div>
            <div className='fw-bold text-gray-400'>Fecha de alta en Afip</div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>{altaSistema}</div>
            <div className='fw-bold text-gray-400'>Fecha de alta en Empleo Joven</div>
          </div>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
            <div className='d-flex flex-stack mb-2'>
              <span className='fs-6 fw-bolder text-gray-700'>{total}%</span>
            </div>
            <div className='progress h-6px w-100'>
              <div
                className='progress-bar bg-prymari'
                role='progressbar'
                style={{ width: '100%' }}
              ></div>
            </div>
            <div className='fw-bold text-gray-400'>Descuento accedido en el mes</div>
          </div>
        </div>
        <div className="mb-2">

          {estadoMes && estadoDocEmpleado === 'aprobado' && (
            <span className='badge badge-success capital'>Aprobado</span>
          )}
          {estadoMes && estadoDocEmpleado === 'presentado' && (
            <span className='badge badge-light-info capital'>Presentado</span>
          )}

          {estadoMes && estadoDocEmpleado === 'pendiente' && (
            <span className='badge badge-warning capital'>Pendiente</span>
          )}
          {estadoMes && estadoDocEmpleado === 'rechazado' && (
            <span className='badge badge-danger capital'>Rechazado</span>
          )}
          {estadoMes && estadoDocEmpleado === 'validado' && (
            <span className='badge badge-light-success capital'>validado</span>
          )}
        </div>
        {((mostrarBtnCambiar && areaUsr !== 'asesores pyme') ||
          /* (mostrarBtnCambiar && estadoDocEmpleado !== 'validado' && areaUsr === 'juventud') || */
          (mostrarBtnCambiar && estadoDocEmpleado === 'validado' && areaUsr === 'aserores pyme')) && (
            <a
              href='#'
              className='btn btn-sm btn-primary'
              onClick={() => {
                setOpenModal(true)
                setSelecEmp(empleado)
              }}
            >
              <KTSVG path='/media/icons/duotune/files/fil019.svg' className='svg-icon-3' />
              Cambiar estado
            </a>
          )}
      </div>
      <ModalPeriodos
        openModal={openModal}
        closeModal={closeModal}
        docPeriodos={empleado}
        titulo={titulo}
        infoValues={formValue}
      />
    </div>
  )
}

export { Card3 }
