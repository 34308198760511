import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import CloudUpload from '@mui/icons-material/CloudUpload'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Modal from 'react-modal'
import styled from '@emotion/styled'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import { updSisModulo } from '../../services/ModuleService'
import Swal from 'sweetalert2'
import { putImgArea } from '../../services/OrganizacionService'
import { putSistemAreaOrgImg } from '../../services/AreaService'

Modal.setAppElement('#root')

interface props {
  openModal: boolean
  servicioName: string
  closeModal(): any
  _idOrg?: string
  modulo?: any
}
interface Iconvert {
  // img: null
  base64URL: string
}
const base64URL = ''

export const ModalImg: React.FC<props> = ({
  openModal,
  modulo,
  servicioName,
  closeModal,
  _idOrg,
}) => {
  const [img64, setImg64] = useState<string>(base64URL)
  const [btnSave, setBtnSave] = useState<boolean>(false)
  useEffect(() => {
    openModal && servicioName === 'modulo' && imgSeleccionada(modulo.icono, servicioName)
    openModal &&
      servicioName === 'area' &&
      imgSeleccionada(modulo.detalleSistema.icono, servicioName)
    // openModal && setOpen(!open)

    return () => { }
  }, [openModal])

  const imgSeleccionada = (imgApi: string, servicio: string) => {
    switch (servicio) {
      case 'modulo':
        setImg64(imgApi)
        break
      case 'area':
        setImg64(imgApi)
        break
      default:
        setImg64('')
        break
    }
  }

  const Input = styled('input')({
    display: 'none',
  })

  const cargarImg = async (files: any = []) => {
    let img = files[0]

    let img2 = await process_image(img)
    setImg64(img2)
    setBtnSave(true)
  }

  const reduce_image_file_size = async (base64Str: string, MAX_WIDTH = 250, MAX_HEIGHT = 250) => {
    let resized_base64 = await new Promise((resolve) => {
      let img = new Image()
      img.src = base64Str
      img.onload = () => {
        let canvas = document.createElement('canvas')
        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }
        canvas.width = width
        canvas.height = height
        let ctx: any = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)
        resolve(canvas.toDataURL()) // this will return base64 image results after resize
      }
    })
    return resized_base64
  }

  async function image_to_base64(file: any) {
    let result_base64 = await new Promise((resolve) => {
      let fileReader = new FileReader()
      fileReader.onload = (e) => resolve(fileReader.result)
      fileReader.onerror = (error) => {
        ////console.log(error)
        alert('An Error occurred please try again, File might be corrupt')
      }
      fileReader.readAsDataURL(file)
    })
    return result_base64
  }

  function calc_image_size(image: any) {
    let y = 1
    if (image.endsWith('==')) {
      y = 2
    }
    const x_size = image.length * (3 / 4) - y
    return Math.round(x_size / 1024)
  }

  async function process_image(file: any, min_image_size = 200) {
    const res: any = await image_to_base64(file)
    if (res) {
      const old_size = calc_image_size(res)
      if (old_size > min_image_size) {
        const resized = await reduce_image_file_size(res)
        const new_size = calc_image_size(resized)
        //////console.log('new_size=> ', new_size, 'KB')
        //////console.log('old_size=> ', old_size, 'KB')
        return resized
      } else {
        //  ////console.log('image already small enough')
        return res
      }
    } else {
      ////console.log('return err')
      return null
    }
  }

  const guardarImgBd = (value: string, servicio?: string, _idOrg?: string) => {
    switch (servicio) {
      case 'modulo':
        updSisModulo(value, modulo._id).then((res: any) => {
          if (res.data.ok) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'La imagen fue cargada con exito.!',
              showConfirmButton: false,
              timer: 1500,
            })
            setImg64('')
            closeModal()
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Verificar conectividad/formato de la imagen',
              showConfirmButton: false,
              timer: 1500,
            })
            setImg64('')
            closeModal()
          }
        })
        break
      case 'area':
        putImgArea(_idOrg || '', value, modulo.nombreArea).then((res: any) => {
          if (res.data.ok) {
            putSistemAreaOrgImg(modulo.nombreArea, value).then((res: any) => {
              if (res.data.ok) {
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'La imagen fue cargada con exito.!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                setImg64('')
                closeModal()
              } else {
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: 'Verificar conectividad/formato de la imagen',
                  showConfirmButton: false,
                  timer: 1500,
                })
                setImg64('')
                closeModal()
              }
            })
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Verificar conectividad/formato de la imagen',
              showConfirmButton: false,
              timer: 1500,
            })
            setImg64('')
            closeModal()
          }
        })
        break
      default:
        break
    }
  }

  return (
    <div>
      <Modal isOpen={openModal} className='modalUploadImg' overlayClassName='modalUploadImg-fondo'>
        <div className='card-label fw-bolder fs-3 mb-1'>Actualizar Imagen</div>
        <div className='card' style={{ width: '18rem' }}>
          <img src={img64} alt='' />
        </div>
        <Stack direction='row' alignItems='center' spacing={10}>
          <label htmlFor='contained-button-file'>
            <Input
              accept='.png,.jpg, .jpeg,.svg,.ico'
              id='contained-button-file'
              multiple
              type='file'
              onChange={(e: any) => cargarImg(e.target.files)}
            />
            <IconButton color='primary' aria-label='upload picture' size='large' component='span'>
              <CloudUpload sx={{ fontSize: 35 }} />
            </IconButton>
          </label>

          {btnSave && (
            <Button
              onClick={() => guardarImgBd(img64, servicioName, _idOrg)}
              variant='contained'
              size='large'
              endIcon={<CheckCircleOutlineIcon />}
            >
              Guardar
            </Button>
          )}

          <Button
            onClick={() => closeModal()}
            variant='contained'
            size='large'
            endIcon={<CheckCircleOutlineIcon />}
          >
            Volver
          </Button>
        </Stack>
      </Modal>
    </div>
  )
}
