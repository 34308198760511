import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Dropdown1} from '../../../../../../_metronic/partials'
interface Iprops {
  descuentos?: any
}
export const PorcEmpleado: React.FC<Iprops> = ({descuentos}) => {
  const [total, setTotal] = useState<number>(0)
  const calcTotal = () => {
    let inicio = 50
    descuentos.genero && (inicio += 10)
    descuentos.primer_empleo && (inicio += 15)
    descuentos.cert_capacitacion && (inicio += 5)
    descuentos.emp_radicada && (inicio += 10)
    descuentos.titular_prog_social && (inicio += 10)

    setTotal(inicio)
  }
  return (
    <div className='card-xl-stretch mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Reconocimiento de crédito fiscal.</span>
          {/* <span className='text-muted fw-bold fs-7'>Pending 10 tasks</span> */}
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}

          {/* begin::Menu 1 */}

          {/* end::Menu 1 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='p-0 min-w-200px'></th>
                <th className='p-0 min-w-100px'></th>
                <th className='p-0 min-w-40px'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                    Descuento inicial.
                  </a>
                  <span className='text-muted fw-bold d-block fs-7'></span>
                </td>
                <td>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-bold'>50%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-warning'
                        role='progressbar'
                        style={{width: '100%'}}
                      ></div>
                    </div>
                  </div>
                </td>
              </tr>

              {descuentos.genero && (
                <tr>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                      Persona contratada sea una mujer, persona trans o persona no binaria.
                    </a>
                    <span className='text-muted fw-bold d-block fs-7'></span>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-bold'>10%</span>
                      </div>
                      <div className='progress h-6px w-100'>
                        <div
                          className='progress-bar bg-primary'
                          role='progressbar'
                          style={{width: '100%'}}
                        ></div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}

              {descuentos.primer_empleo && (
                <tr>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                      Primer empleo formal de la persona contratada.
                    </a>
                    <span className='text-muted fw-bold d-block fs-7'></span>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-bold'>15%</span>
                      </div>
                      <div className='progress h-6px w-100'>
                        <div
                          className='progress-bar bg-primary'
                          role='progressbar'
                          style={{width: '100%'}}
                        ></div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {descuentos.cert_capacitacion && (
                <tr>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                      Certificado de capacitación segun de las entidades habilitadas en el Artículo
                      9º inciso 3.
                    </a>
                    <span className='text-muted fw-bold d-block fs-7'></span>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-bold'>5%</span>
                      </div>
                      <div className='progress h-6px w-100'>
                        <div
                          className='progress-bar bg-primary'
                          role='progressbar'
                          style={{width: '100%'}}
                        ></div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {descuentos.emp_radicada && (
                <tr>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                      Empresa radicada en localidades provinciales hasta de 5000 habitantes.
                    </a>
                    <span className='text-muted fw-bold d-block fs-7'></span>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-bold'>10%</span>
                      </div>
                      <div className='progress h-6px w-100'>
                        <div
                          className='progress-bar bg-primary'
                          role='progressbar'
                          style={{width: '100%'}}
                        ></div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {descuentos.titular_prog_social && (
                <tr>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                      Jóvenes titulares de programas sociales de alcance nacional, provincial o
                      municipal.
                    </a>
                    <span className='text-muted fw-bold d-block fs-7'></span>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-bold'>10%</span>
                      </div>
                      <div className='progress h-6px w-100'>
                        <div
                          className='progress-bar bg-primary'
                          role='progressbar'
                          style={{width: '100%'}}
                        ></div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              <br />
              {total === 0 && calcTotal()}
              <tr>
                <td>
                  <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                    Descuento total accedido.
                  </a>
                  <span className='text-muted fw-bold d-block fs-7'></span>
                </td>
                <td>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-bold'>{total}%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      {total === 100 && (
                        <div
                          className='progress-bar bg-success'
                          role='progressbar'
                          style={{width: '100%'}}
                        ></div>
                      )}
                      {total > 50 && (
                        <div
                          className='progress-bar bg-info'
                          role='progressbar'
                          style={{width: '100%'}}
                        ></div>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}
