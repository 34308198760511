import React, {FC, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Formik, Form, FormikValues} from 'formik'
import Swal from 'sweetalert2'
import {useSelector} from 'react-redux'
import {Step1} from '../components/stepNoticia/stepsEditarNoticia/steps/Step1'
import {Step2} from '../components/stepNoticia/stepsEditarNoticia/steps/Step2'
import {Step3} from '../components/stepNoticia/stepsEditarNoticia/steps/Step3'
import {
  IFormNoticia,
  createAccountSchemas,
  inits,
} from '../components/stepNoticia/stepsEditarNoticia/CreateNoticia'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../../_metronic/helpers'
import {postNotica} from '../services/Noticias-Services'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
//inicio del componente
const CrearNoticia: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0]) 
  const [initValues, setInitValues] = useState<IFormNoticia>(inits)
  const [objet] = useState<IFormNoticia>(initValues)
  const dashState: any = useSelector((state: ISTATE) => state.auth)
console.log(dashState.usuario);

  //usuario nombre corto
  const nombreRes = dashState.usuario.nombres
  let cadena = nombreRes
  let fstChar = cadena.charAt(0)

  //State btn volver
  const [volver, setVolver] = useState(true)

  //Estado del Editor para procesar el requerido
  const [hasContentEditor, sethasContentEditor] = useState(false)
  const sethasConetentTrue = () => sethasContentEditor(true)
  const sethasConetentFalse = () => sethasContentEditor(false)

  //stepper config
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    stepper.current?.currentStepIndex === 1 && setInitValues(inits)
    setVolver(true)

    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: IFormNoticia, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()

      if (stepper.current.currentStepIndex === 3) {
        sethasContentEditor(false)
      }
    } else {
      //Config Values
      const usOrg = dashState.usuario.organizacion.datosOrganismo
      values.usuario_creador.nombre = fstChar + '. ' + dashState.usuario.apellido
      values.usuario_creador.email = dashState.usuario.email
      values.usuario_creador.nombreUsuario = dashState.usuario.usuario
      values.nombreOrg = usOrg.nombre
     //Send values Api
     await postNotica(values).then((res: any) => {
        if (res.data.ok === true) {
          Swal.fire('Se creo la noticia Correctamente')
          window.history.back()
        } else {
          Swal.fire('Error al crear la noticia')
          window.history.back()
        }
      })
    }
  }
  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])
  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column   d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      <div className='d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        <div className='px-6 px-lg-10 px-xxl-15 py-20'>
          <div className='stepper-nav'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <div className='stepper-line w-40px'></div>

              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>1</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'>Crear Noticia</h3>

                <div className='stepper-desc fw-bold'>Título, tematica y tags</div>
              </div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-line w-40px'></div>

              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>2</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'>Cuerpo</h3>
                <div className='stepper-desc fw-bold'>Detalle de las noticias</div>
              </div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-line w-40px'></div>

              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>3</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'> Previsualización </h3>
                <div className='stepper-desc fw-bold'>.....</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
          {(Formik) => {
            const {isValid} = Formik

            return (
              <Form
                className='pb-20 pt-3 w-100 w-xl-700px px-9'
                noValidate
                id='kt_create_account_form'
              >
                <div className='current' data-kt-stepper-element='content'>
                  <Step1 valor={Formik} />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step2
                    valor={Formik}
                    objet={objet}
                    sethasConetentTrue={sethasConetentTrue}
                    sethasConetentFalse={sethasConetentFalse}
                  />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step3 values={Formik.values} />
                </div>

                <div className='d-flex flex-stack pt-10'>
                  {volver ? (
                    <div className='mr-2'>
                      <Link to={'noticia'}>
                        <button type='button' className='btn btn-lg btn-light-primary me-3'>
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr063.svg'
                            className='svg-icon-4 me-1'
                          />
                          Volver
                        </button>
                      </Link>
                    </div>
                  ) : (
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Volver
                      </button>
                    </div>
                  )}
                  <div>
                    <button
                      type='submit'
                      disabled={!isValid && !hasContentEditor}
                      className='btn btn-lg btn-primary me-3'
                      onClick={() => setVolver(false)}
                    >
                      <span className='indicator-label'>
                        {stepper.current?.currentStepIndex !==
                          stepper.current?.totatStepsNumber! - 1 && 'Continuar'}
                        {stepper.current?.currentStepIndex ===
                          stepper.current?.totatStepsNumber! - 1 && 'Guardar'}
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {CrearNoticia}
