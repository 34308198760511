import React from 'react'
import Modal from 'react-modal'
import { PorcEmpleado } from '../components/porcentajesAcc/PorcEmpleado'
import { UpFiles } from '../components/Upfiles/UpFiles'
import { TablaPeriodos } from '../components/PeriodosABM/TablaPeriodos'
import { Card4 } from '../../../../../_metronic/partials/content/cards/Card4'
import '../styles/empleoJoven.scss'
import Swal from 'sweetalert2'
import { ModEstadoOneEmpleado } from '../services/AdminEmpleados'
import { CargasSociales } from '../components/PeriodosABM/CargasSociales'
import { ValidarDocEmp } from '../components/ValidarDocEmpCentroPyme/ValidarDocEmp'
interface IModalArea {
  openModal: boolean
  closeModal(): any
  docPeriodos?: any
  titulo: string
  infoValues?: any
  documento?: any
}
export const ModalPeriodos: React.FC<IModalArea> = ({
  openModal,
  docPeriodos,
  closeModal,
  titulo,
  infoValues,
  documento,
}) => {
  const opcRechazo = async () => {
    let value: any = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Mensaje para el empleador.',
      inputPlaceholder: 'Escriba su argumentación en este espacio.',
      inputAttributes: {
        'aria-label': 'Escriba su mensaje aquí.',
      },
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cerrar',
      showCancelButton: true,
    })
    if (value.value === '') {
      await Swal.fire({ toast: true, text: `Es necesario completar este formulario.`, timer: 2000 })
      opcRechazo()
    } else {
      ModEstadoOneEmpleado(docPeriodos.cuit, docPeriodos.documento, 'borrar', value.value).then(
        async (res: any) => {
          await Swal.fire(
            'Operación exitosa',
            'El empleado fue eliminado correctamente y el empleador fue notficado.',
            'success'
          )
        }
      )
    }
  }
  const validateEmp = async () => {
    const { value: formValues } = await Swal.fire({
      title: 'Seleccione la documentación aprobada',
      html:
        '<label class="form-check-label" for="flexCheckDefault"> <input  type="checkbox"  id="progSocial" class="form-check-input" value="progSocial"> Constancia de programa social.</Label>' +
        '<label class="form-check-label" for="flexCheckDefault"> <input  type="checkbox"  id="historialAnses" class="form-check-input" value="historialAnses">   Historial laboral de ANSES</Label>',

      focusConfirm: false,
      preConfirm: () => {
        return [
          (document.getElementById('progSocial') as HTMLInputElement)!.checked &&
          (document.getElementById('progSocial') as HTMLInputElement)!.value,
          (document.getElementById('historialAnses') as HTMLInputElement)!.checked &&
          (document.getElementById('historialAnses') as HTMLInputElement)!.value,
        ]
      },
    })
    return new Promise((resolve) => {
      if (formValues) {
        ModEstadoOneEmpleado(
          docPeriodos.cuit,
          docPeriodos.documento,
          'validado',
          '',
          formValues
        ).then(async (res: any) => {
          Swal.fire('Operación exitosa', 'La modificación del estado fue correcta', 'success').then(() => {
            console.log(docPeriodos);
            closeModal()
            resolve('')
          })
        })
      }
      resolve('')
    })
  }

  const selectOpBtn = () => {
    Swal.fire({
      title: 'Seleccione una opción',
      input: 'select',
      inputOptions: {
        validado: 'Validar',
        rechazado: 'Rechazar',
      },
      inputPlaceholder: 'Seleccione una opción',
      confirmButtonText: 'Cambiar',
      cancelButtonText: 'Cerrar',
      showCancelButton: true,
      inputValidator: (value) => {
        // console.log(value)
        return new Promise((resolve) => {
          if (value === 'rechazado') {
            opcRechazo()
            resolve('')
            closeModal()
          }
          if (value === 'validado') {
            validateEmp()
            resolve('')
            closeModal()
          } else {
            resolve('Es necesario seleccionar una opción')
          }
        })
      },
    })
  }
  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      closeTimeoutMS={500}
      className='modalCalendario'
      overlayClassName='modalCalendario-fondo'
    >
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark capital'>{titulo}</h3>
        <h5 className='text-gray-400 fw-bold fs-6 capital'>
          {docPeriodos?.datosPersonales.apellido} {docPeriodos?.datosPersonales.nombres}
        </h5>
      </div>
      {titulo === 'cargar documentación' && (
        <UpFiles empleado={docPeriodos} closeModal={closeModal} />
      )}
      {titulo === 'beneficio accedido' && <PorcEmpleado descuentos={docPeriodos.descuentos} />}
      {titulo === 'periodos cargados' && <TablaPeriodos empleado={docPeriodos} infoValues={infoValues?infoValues: undefined}/>}
      {titulo === 'cambiar estado cpyme' && (
        //TODO Ver que modificación hacer para validar y aprobar
        /*  {<h2>Validar doc empresa</h2>} */
        <ValidarDocEmp empleado={docPeriodos} formValues={infoValues} />
      )}
      {/* {titulo === 'cambiar estado juventud' && selectOpBtn()} */}

      {/* {titulo === 'Periodos Cargados' && <TablaPeriodos empleado={docPeriodos} />} */}
      {<div className='cardsPict'>
        {titulo === 'doc inicial' &&
          docPeriodos.documentosUnicos.map((doc: any, index: number) => {
            return (
              <div className=''>
                <div key={index}>
                  <Card4
                    icon='/media/svg/files/pdf.svg'
                    titulo={'doc inicial'}
                    title={doc.tipo_doc}
                    tipoUsuario={'adminUS'}
                    description=''
                    cuit={doc.id_db_fileEj}
                    archivo={doc}
                  />
                </div>
              </div>
            )
          })}
      </div>}
      {titulo === 'Cargas Sociales' && <CargasSociales empleado={docPeriodos} />}
      <br />
      <button type='submit' className='btn btn-primary' onClick={() => closeModal()}>
        Cerrar
      </button>

      {titulo === 'doc inicial' && docPeriodos.estado === 'pendiente' && (
        <button type='submit' className='btn btn-success' onClick={() => selectOpBtn()}>
          Cambiar Estado
        </button>
      )}
    </Modal>
  )
}
