import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ISTATE } from '../../../../../../setup/redux/interface/reduxState.interface'
import { BotonAccion } from '../Botones/BotonAccion'
import { BotonVolver } from '../Botones/BotonVolver'
type Props = {
  titulo: string
  data: any
  evento: any
  valido: boolean
}
const HeaderVerEditarEvento: React.FC<Props> = ({ titulo, data, evento, valido }) => {
  const dispatch = useDispatch()
  const eventoStore: any = useSelector((state: ISTATE) => state.eventos)
  const { acciones, eventoSeleccionado } = eventoStore
  const usrState: any = useSelector((state: ISTATE) => state.auth)
  const { usuario } = usrState
  const perfilState: any = useSelector((state: ISTATE) => state.dashBoard)
  const { perfil } = perfilState
  let roleUS: any
  let admin: any
  //console.log('evento', evento)
  //console.log('valido', valido)
  ////console.log('----------- perfil', perfil)
  let areaPUsuario = usuario.organizacion.datosOrganismo.areaPrincipal.idAreaP
  let agenda = perfil.filter((p: any) => p.nombre === 'agenda')
  ////console.log('areaPUsuario', areaPUsuario)

  if (agenda) {
    roleUS = agenda[0].menu.filter((r: any) => r.name === 'calendario')
    roleUS = roleUS[0]
    ////console.log('roleUS', roleUS)
  }
  if (roleUS) {
    admin = roleUS.roleUs.admin
    ////console.log('admin', admin)
  }
  const areaEvento = evento.idArea
  const areaPrincipalUsuario = usuario.organizacion.datosOrganismo.areaPrincipal.idAreaP



  return (
    <div>
      <div className='card-header align-items-center border-1 bg-light-gray-500'>
        <h3 className='card-title align-items-start flex-column'>
          {evento.estado === 'planificado' && (
            <div>
              <span className='fw-bolder mb-2 pr-2 text-dark'>{titulo}</span>
              <span className='badge badge-light-warning fs-6 fw-bold  capital'>planificado</span>
            </div>
          )}
          {evento.estado === 'en curso' && (
            <div>
              <span className='fw-bolder mb-2 pr-2 text-dark'>{titulo}</span>
              <span className='badge badge-light-info fs-6 fw-bold  capital'>en curso</span>
            </div>
          )}
          {evento.estado === 'realizado' && (
            <div>
              <span className='fw-bolder mb-2 pr-2 text-dark'>{titulo}</span>
              <span className='badge badge-light-success fs-6 fw-bold  capital'>realizado</span>
            </div>
          )}
          {evento.estado === 'cancelado' && (
            <div>
              <span className='fw-bolder mb-2 pr-2 text-dark'>{titulo}</span>
              <span className='badge badge-light-danger fs-6 fw-bold  capital'>cancelado</span>
            </div>
          )}
        </h3>
        <div className='card-toolbar texto-derecha'>
          {evento.estado !== 'realizado' && acciones.guardar && (
            <>
              <BotonAccion
                nombre='Guardar Evento'
                imagen='/files/fil025.svg'
                colorFondo='btn-active-light-info'
                accion='guardar'
                habilitado={!acciones.guardar && valido}
                data={data}
              />
              <BotonAccion
                nombre='Cancelar Edición'
                imagen='/files/fil007.svg'
                colorFondo='btn-active-light-danger'
                accion='cancelarEdicion'
                habilitado={!acciones.guardar}
                data={data}
              />
            </>
          )}

          {areaEvento === areaPrincipalUsuario && (
            <>
              {evento.estado === 'realizado' && !acciones.conclusion && (
                <BotonAccion
                  nombre='Guardar Conclusion'
                  imagen='/files/fil025.svg'
                  colorFondo='btn-active-light-info'

                  accion='guardar'
                  habilitado={acciones.conclusion}
                  data={data}
                />
              )}
              {evento.estado !== 'realizado' &&
                evento.estado !== 'cancelado' &&
                acciones.editar && (
                  <BotonAccion
                    nombre='Editar Evento'
                    imagen='/general/gen055.svg'
                    colorFondo='btn-active-light-success'
                    accion='editar'
                    habilitado={!acciones.editar}
                    data={data}
                  />
                )}

              {evento.estado !== 'realizado' &&
                evento.estado !== 'cancelado' &&
                acciones.cancelar && (
                  <BotonAccion
                    nombre='Cancelar Evento'
                    imagen='/files/fil007.svg'
                    colorFondo='btn-active-light-danger'
                    accion='cancelar'
                    habilitado={!acciones.cancelar}
                    data={data}
                  />
                )}

              {evento.estado === 'realizado' && evento.conclusion === '' && acciones.conclusion && (
                <BotonAccion
                  nombre='Editar Conclusión'
                  imagen='/files/fil025.svg'
                  colorFondo='btn-active-light-success'
                  accion='conclusion'
                  habilitado={!acciones.conclusion}
                  data={data}
                />
              )}
            </>
          )}
          {/*  {(acciones.imprimir) && (<BotonImprimir pag_inicio={'/agenda/calendario/imprimir'} nombre='Imprimir Evento' imagen='/arrows/arr078.svg' colorFondo='btn-active-light-primary' habilitado={!acciones.imprimir} data={data} />)} */}

          <BotonVolver
            pag_inicio={'/agenda/calendario'}
            nombre='Volver el Calendario'
            imagen='/arrows/arr077.svg'
            colorFondo='btn-active-light-warning'
            habilitado={
              !acciones.editar &&
              !acciones.conclusion &&
              !acciones.cancelar &&
              evento.estado !== 'cancelado'
            }
            data={data}
          />
        </div>
      </div>
    </div>
  )
}

export default HeaderVerEditarEvento
