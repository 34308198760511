import {Switch, Route, useRouteMatch, Redirect} from 'react-router-dom'
import {EditarImagenes} from './pages/EditarImagenes'
import {CrearNoticia} from './pages/CrearNoticia'
import LevantatutiPage from './pages/LevantatutiPage'

import './styles/styles.scss'
import {ENoticia} from './pages/EditarNoticia'

const LevantatutiRouter = () => {
  const {url} = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${url}/noticia`} component={LevantatutiPage} />
      <Route exact path={`${url}/CrearNoticia`} component={CrearNoticia} />
      <Route exact path={`${url}/EditarNoticia`} component={ENoticia} />
      <Route exact path={`${url}/EditImagen`} component={EditarImagenes} />
      <Route path='*'>
        <Redirect to='/page404' />
      </Route>
    </Switch>
  )
}

export default LevantatutiRouter
