import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'
import {getDocMesxMes} from '../../services/EJ.Services'

type Props = {
  icon?: string
  downloadPdf?: any
  estado: string
  importe: string
  fecha_alta: string
  resolucion: string
  // users?: Array<IconUserModel>
}

export const CardMisCert: FC<Props> = ({estado, importe, fecha_alta, resolucion, downloadPdf}) => {
  const descargarPdf = () => {
    for (let doc of downloadPdf) {
      setTimeout(() => {
        getDocMesxMes(doc.id_db_fileEj).then((res: any) => {
          const url = window.URL.createObjectURL(res.data)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('target', doc.tipo_doc)
          document.body.appendChild(link)
          link.click()
        })
      }, 1000)
    }
  }

  const tooltip = (titulo: string) => {
    return (
      <Tooltip id='tooltip' className='capital'>
        <strong>{titulo}</strong>
      </Tooltip>
    )
  }

  return (
    <div className='card border border-2 border-gray-300 border-hover'>
      <div className='card-header border-0 pt-9'>
        <div className='card-title m-0'>
          <div className='symbol symbol-50px w-50px bg-light'>
            <img src={toAbsoluteUrl('/media/svg/files/pdf.svg')} alt='card2' className='p-3' />
          </div>
        </div>

        <div className='card-toolbar'>
          <OverlayTrigger placement='top' overlay={tooltip('Descargar Certificados')}>
            <button
              type='button'
              className='btn btn-outline-primary px-4 py-3 capital'
              onClick={() => {
                descargarPdf()
              }}
            >
              {' '}
              Certificados
            </button>
          </OverlayTrigger>
        </div>
      </div>

      <div className='card-body p-9'>
        <div className='fs-3 fw-bolder text-dark'>Certificado Centro Pyme</div>

        <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>Resolución nro: {resolucion}</p>

        <div className='d-flex flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
            <div className='fs-6 text-gray-800 fw-bolder'>{fecha_alta}</div>
            <div className='fw-bold text-gray-400'>Fecha de alta</div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
            <div className='fs-6 text-gray-800 fw-bolder'>$ {importe}</div>
            <div className='fw-bold text-gray-400'>Total del mes</div>
          </div>
        </div>

        <div
          className='h-4px w-100 bg-light mb-5'
          data-bs-toggle='tooltip'
          title='This project completed'
        >
          <div className={`bg-success rounded h-4px`} role='progressbar'></div>
        </div>

        {/* <UsersList users={users} /> */}
      </div>
    </div>
  )
}
