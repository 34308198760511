import React, {FC, useEffect, useState} from 'react'
import {Formik, Form, FormikValues, Field} from 'formik'
import {KTSVG} from '../../../../../_metronic/helpers/components/KTSVG'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import {getLayout, ILayout, LayoutSetup, useLayout} from '../../../../../_metronic/layout/core'
import clsx from 'clsx'
import {ICreateAccount} from '../interface/EditNoticaHeper'
import {Inoticia} from '../interface/Inoticia'
import ImageUploading, {ImageListType} from 'react-images-uploading'
import portada from '../assets/default-portada.jpg'
import miniatura from '../assets/default-miniatura.jpg'
import {postEditarImagenes, postSubirImagen} from '../services/Noticias-Services'
import Swal from 'sweetalert2'

const EditarImagenes: FC = () => {
  let data: any = useLocation()

  const [objetPreview] = useState<ICreateAccount>(data.state.objet)
  const {setLayout} = useLayout()
  const [tab, setTab] = useState('Header')
  const [config, setConfig] = useState<ILayout>(getLayout())
  const [configLoading, setConfigLoading] = useState<boolean>(false)

  const [images, setImages] = useState([] as any)
  const maxNumber = 10
  const onChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    setImages(imageList as never[])
  }

  //Estados de Error Img
  const [errorSizePortada, setErrorSiezPortada] = useState<boolean>(false)
  const [errorSizeMiniatura, setErrorSiezMiniatura] = useState<boolean>(false)

  //Estados de imagenes de Portada & Miniatura
  const [fileUrlPortada, setFileUrlPortada] = useState('')
  const [fileUrlMiniatura, setFileUrlMiniatura] = useState('')
  const [fileImgPortada, setfileImgPortada] = useState<File>()
  const [fileImgMiniatura, setfileImgMiniatura] = useState<File>()

  //Procesa Imagen de Portada
  function procesImgPortada(event: any) {
    const imageUrlPortada: string = URL.createObjectURL(event.target.files[0])
    setFileUrlPortada(imageUrlPortada)
    const reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = (e: any) => {
      const image = new Image()
      image.src = e.target.result
      image.onload = (e: any) => {
        const height = e.target.height
        const width = e.target.width
        if (height === 500 || width === 1200) {
          setErrorSiezPortada(false)
          setfileImgPortada(event.target.files[0])
        } else {
          setErrorSiezPortada(true)
        }
      }
    }
  }

  //Procesa Imagen miniatura
  function procesImgMiniatura(event: any) {
    setfileImgMiniatura(event.target.files[0])
    const imageUrlMiniatura: string = URL.createObjectURL(event.target.files[0])
    setFileUrlMiniatura(imageUrlMiniatura)
    const reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = (e: any) => {
      const image = new Image()
      image.src = e.target.result
      image.onload = (e: any) => {
        const height = e.target.height
        const width = e.target.width
        if (height === 267 || width === 356) {
          setErrorSiezMiniatura(false)
          setfileImgMiniatura(event.target.files[0])
        } else {
          setErrorSiezMiniatura(true)
        }
      }
    }
  }

  //Submit Galeria
  const onSubmitGaleria = async (listImage: any, objet: Inoticia) => {
    setConfigLoading(true)
    try {
      LayoutSetup.setConfig(config)
    } catch (error) {
      setConfig(getLayout())
    }
    
    var formData = new FormData()
    formData.append('id', objet._id!)
    for (const e of listImage) {
      const file = e.file ? e.file : e
      await formData.append('galeria', file)
    }
    postEditarImagenes(formData).then((res:any) => {      
      if(res.data.ok === true){
        setTimeout(() => {
          setLayout(config)
          setConfigLoading(false)
        }, 1000)
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Se Actualizo galeria ',
          showConfirmButton: false,
          timer: 1500,
        })
      }
      
    })
  }

  useEffect(() => {
    setImages(objetPreview.imagen.galeria)
  }, [objetPreview])

  const submitStep = (values: Inoticia, actions: FormikValues) => {}

  //Submit Portada y Miniatura
  const onSubmitImages = async (objet: Inoticia) => {
    console.log(fileImgPortada)
    if (fileImgPortada === undefined || fileImgMiniatura === undefined) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: '¡Por favor cargue las dos imagenes!',
        showConfirmButton: false,
        timer: 1500,
      })
    } else {
      setConfigLoading(true)
      try {
        LayoutSetup.setConfig(config)
      } catch (error) {
        setConfig(getLayout())
      }
     

      const formData = new FormData()
      formData.append('portada', fileImgPortada!)
      formData.append('miniatura', fileImgMiniatura!)
      formData.append('id', objet._id!)
      await postSubirImagen(formData).then((res: any) => {
        
        if (res.data.ok === true) {
          setTimeout(() => {
            setLayout(config)
            setConfigLoading(false)
          }, 1000)
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Se cargo portada y miniatura',
            showConfirmButton: false,
            timer: 1500,
          })
        }
        if(res.data.ext === true){
          setTimeout(() => {
            setLayout(config)
            setConfigLoading(false)
          }, 1000)
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Formato de imágen no valido',
            text: 'Debe ser .png .jpg o .jpeg',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link`, {active: tab === 'Header'})}
                onClick={() => setTab('Header')}
                role='tab'
              >
                Portada/Miniatura
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link`, {active: tab === 'Toolbar'})}
                onClick={() => setTab('Toolbar')}
                role='tab'
              >
                Galería
              </a>
            </li>
          </ul>
        </div>
        {/* end::Header */}

        {/* begin::Form */}
        <Formik initialValues={objetPreview} onSubmit={submitStep}> 
          {(Formik) => {
            return (
              <Form>
                {/* begin::Body */}
                <div className='card-body'>
                  <div className='tab-content pt-3'>
                    <div className={clsx('tab-pane', {active: tab === 'Header'})}>
                      <div className='row col-lg-10 offset-lg-1'>
                        <div className='col-lg-6'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            Imágen Portada
                          </label>
                          <br></br>
                          <Field
                            name='pruebaImg'
                            type='file'
                            className='file-select btn btn-lg btn-primary'
                            accept={'image/*'}
                            onChange={procesImgPortada}
                          />
                          <div className='row pt-2'>
                            {errorSizePortada ? (
                              <span className='required error'>No es el tamaño deseado, debe ser 1200x500</span>
                            ) : null}
                          </div>

                          <img
                            className='imagenSubida'
                            src={fileUrlPortada}
                            onError={(e: any) => {
                              {objetPreview.imagen.portada.url === '' ? 
                              (e.target.src = portada):(e.target.src = objetPreview.imagen.portada.url)}
                              e.onerror = null
                            }}
                          ></img>
                        </div>
                        <div className='col-lg-4 miniatura-derecha'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            Imágen Miniatura
                          </label>
                          <br></br>
                          <Field
                            name='prueba'
                            type='file'
                            className='file-select btn btn-lg btn-primary'
                            accept={'image/*'}
                            onChange={procesImgMiniatura}
                          />
                          <div className='row pt-2'>
                            {errorSizeMiniatura ? (
                              <span className='required error'>No es el tamaño deseado, debe ser 356x267</span>
                            ) : null}
                          </div>
                          <img
                            className='imagenSubida'
                            src={fileUrlMiniatura}
                            onError={(e: any) => {
                              {objetPreview.imagen.miniatura.url === '' ? 
                              (e.target.src = miniatura):(e.target.src = objetPreview.imagen.miniatura.url)}
                              e.onerror = null
                            }}
                          ></img>
                        </div>

                        <div className='fv-plugins-message-container invalid-feedback'></div>
                        <div className='d-flex boton-galeria-centrado pt-3'>
                          <div className='mr-2'>
                            {errorSizeMiniatura || errorSizePortada ? (
                              <button className='btn btn-lg btn-primary me-3 ' disabled>
                                Subir Imágenes
                              </button>
                            ) : (
                              <button
                                className='btn btn-lg btn-primary me-3 '
                                onClick={() => onSubmitImages(objetPreview)}
                              >
                                {!configLoading && (
                                  <span className='indicator-label'>Subir Imágenes</span>
                                )}
                                {configLoading && (
                                  <span className='indicator-progress' style={{display: 'block'}}>
                                    Espere por favor...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={clsx('tab-pane', {active: tab === 'Toolbar'})}>
                      <div className='row col-lg-10 offset-lg-1'>
                        <h2 className='fw-bolder d-flex align-items-center text-dark'>
                          Seleccione las imágenes para la galería
                        </h2>

                        <ImageUploading
                          multiple
                          resolutionType={'absolute'}
                          acceptType={['jpg', 'jpge', 'png']}
                          value={images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                          maxFileSize={1000000}
                        >
                          {({
                            errors,
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className='fv-row'>
                              <div className='row center-btn-galery'>
                                <div className='col-lg-6'>
                                  <div className='upload__image-wrapper'>
                                    <button
                                      className='btn'
                                      style={isDragging ? {color: 'red'} : undefined}
                                      onClick={onImageUpload}
                                      {...dragProps}
                                    >
                                      <label
                                        className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                                        htmlFor='kt_create_account_form_account_type_personal'
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/files/fil009.svg'
                                          className='svg-icon-3x me-5'
                                        />
                                        <span className='d-block fw-bold text-start'>
                                          <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                                            Seleccione<br></br>las Imágenes
                                          </span>
                                        </span>
                                      </label>
                                      {errors && (
                                        <div>
                                          {errors.maxNumber && (
                                            <span className='required error'>
                                              Exede la cantidad permitida
                                            </span>
                                          )}
                                          {errors.acceptType && (
                                            <span className='required error'>
                                              El archivo no tiene la extención correcta
                                            </span>
                                          )}
                                          {errors.maxFileSize && (
                                            <span className='required error'>
                                              La imágen supera el peso permitido
                                            </span>
                                          )}
                                          {errors.resolution && (
                                            <span className='required error'>
                                              La imágen supera resolución permitida
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </button>
                                  </div>
                                </div>
                                <div className='col-lg-6'>
                                  <button className='btn' onClick={onImageRemoveAll}>
                                    <label
                                      className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
                                      htmlFor='kt_create_account_form_account_type_corporate'
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen027.svg'
                                        className='svg-icon-3x me-5'
                                      />

                                      <span className='d-block fw-bold text-start'>
                                        <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                                          Remover todas<br></br>las Imágenes
                                        </span>
                                      </span>
                                    </label>
                                  </button>
                                </div>
                                <div className='fv-plugins-message-container invalid-feedback'></div>
                              </div>
                              <div className='fv-row center-img-galery'>
                                <div className='row'>
                                  {imageList.map((image: any, index: any) => (
                                    <div key={index} className='col-lg-4'>
                                      <img
                                        src={image.dataURL ? image.dataURL : image}
                                        alt=''
                                        width='100'
                                        height='100'
                                      />
                                      <div className='image-item__btn-wrapper'>
                                        <button
                                          className='btn btn-color-primary '
                                          onClick={() => onImageUpdate(index)}
                                        >
                                          Cambiar
                                        </button>
                                        <button
                                          className='btn btn-icon btn-bg-light btn-color-primary btn-sm'
                                          onClick={() => onImageRemove(index)}
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen027.svg'
                                            className='svg-icon-3x me-5'
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div className='fv-plugins-message-container invalid-feedback'>
                                </div>
                              </div>
                              <div className='d-flex boton-galeria-centrado'>
                                <div className='mr-2'>
                                  <button
                                    className='btn btn-primary me-3 '
                                    onClick={() => onSubmitGaleria(imageList, objetPreview)}
                                  >
                                    {!configLoading && (
                                      <span className='indicator-label'>Subir Imágenes</span>
                                    )}
                                    {configLoading && (
                                      <span
                                        className='indicator-progress'
                                        style={{display: 'block'}}
                                      >
                                        Espere por favor...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                      </span>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end::Body */}

                {/* begin::Footer */}
                <div className='card-footer py-6'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <Link to={'/noticia/noticia'}>
                        <button className='btn btn-primary me-2'>Volver</button>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* end::Footer */}
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export {EditarImagenes}
