import { FieldArray } from 'formik'
import FormikControl from '../../../../../../components/Formik/FormikControl'
import clsx from 'clsx'
import { OverlayTrigger, Tooltip } from 'react-bootstrap-v5'
import Swal from 'sweetalert2'

interface props {
  update: boolean
}
const Step2MenusModulo: React.FC<props> = ({ update }) => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        {update ? (
          <h2 className='fw-bolder d-flex align-items-center text-dark'>
            Actualizar datos.
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Billing is issued based on your selected account type'
            ></i>
          </h2>
        ) : (
          <h2 className='fw-bolder d-flex align-items-center text-dark'>
            Complete los datos.
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Billing is issued based on your selected account type'
            ></i>
          </h2>
        )}
        <div className='text-gray-400 fw-bold fs-6'>
          Es necesario completar correctamente los datos, para el correcto funcionamiento del
          codigo.
        </div>
      </div>
      <FieldArray name='menu'>
        {(fieldArrayProps) => {
          // ////console.log('ARRAY', fieldArrayProps)
          const { push, remove, form } = fieldArrayProps
          const { values } = form
          const { menu } = values

          return (
            <div>
              {menu.map((ele: any, index: any) => (
                <>
                  <div className='card '>
                    <div className='row'>
                      <div className='col-8 text-gray-400 fw-bold mt-2 fs-6'>Menu Nro:{index}</div>
                      <div className='col-4 mb-2 texto-derecha'>
                        {index > 0 && (
                          <OverlayTrigger
                            key={'borrarmenu'}
                            placement='top'
                            overlay={<Tooltip id='data'>Borrar Menu</Tooltip>}
                          >
                            <button
                              type='button'
                              className={clsx(`btn btn-light-danger py-1 btn-danger`)}
                              onClick={() => {
                                Swal.fire({
                                  title: 'Esta por eliminar un menu',
                                  text: '¿Desea continuar?',
                                  icon: 'warning',
                                  showCancelButton: true,
                                  confirmButtonColor: '#3085d6',
                                  cancelButtonColor: '#d33',
                                  confirmButtonText: 'Continuar!',
                                  allowOutsideClick: false,
                                }).then((result) => {
                                  result.isConfirmed && remove(index)
                                })
                              }}
                            >
                              -
                            </button>
                          </OverlayTrigger>
                        )}
                        <OverlayTrigger
                          key={'agregarMenu'}
                          placement='top'
                          overlay={<Tooltip id='data'>Agregar Menu</Tooltip>}
                        >
                          <button
                            className='btn btn-light-success btn-success py-1'
                            type='button'
                            onClick={() => {
                              Swal.fire({
                                title: 'Esta por agregar un menu',
                                text: '¿Desea continuar?',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Continuar!',
                                allowOutsideClick: false,
                              }).then((result) => {
                                result.isConfirmed && push(form.initialValues.menu[0])
                              })
                            }}
                          >
                            +
                          </button>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                  <div className='card bg-ligth border mb-6'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-lg-3'>
                          <FormikControl
                            control='input'
                            label='Nombre'
                            name={`menu[${index}].name`}
                            placeholder='Nombre del menu'
                            className='form-control form-control-white'
                          />
                        </div>
                        <div className='col-lg-3'>
                          <FormikControl
                            control='input'
                            label='Tipo acceso'
                            name={`menu[${index}].type`}
                            placeholder='Ej:Link/dropDown'
                            className='form-control  form-control-white'
                          />
                        </div>
                        <div className='col-lg-3'>
                          <FormikControl
                            control='input'
                            label='Tooltip'
                            name={`menu[${index}].tooltip`}
                            placeholder='Tooltip'
                            className='form-control form-control-white'
                          />
                        </div>
                        <div className='col-lg-3'>
                          <FormikControl
                            control='input'
                            label='Ruta de acceso'
                            name={`menu[${index}].state`}
                            placeholder='NombreModulo/Menu'
                            className='form-control  form-control-white'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )
        }}
      </FieldArray>
    </div>
  )
}

export default Step2MenusModulo
