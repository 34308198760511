import { useState, useMemo, useCallback, useRef, useEffect, FC, useContext } from "react";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Circle,
  MarkerClusterer,
} from "@react-google-maps/api";
import "./GoogleMaps.scss";
import { useSelector } from "react-redux";
import { ISTATE } from "../../../../../../setup/redux/interface/reduxState.interface";
type LatLngLiteral = google.maps.LatLngLiteral;
type DirectionsResult = google.maps.DirectionsResult;
type MapOptions = google.maps.MapOptions;



const Mapa: FC = () => {
  const eventosStore: any = useSelector((state: ISTATE) => state.eventos)
  const { formulario } = eventosStore
  let ubicacion = formulario?.geom
  const mapRef = useRef<GoogleMap>();
  let center = useMemo<LatLngLiteral>(
    () => ({ lat: formulario ? formulario.geom.latitud : - 38.951478, lng: formulario ? formulario.geom.longitud : -68.0591339 }),
    [ubicacion]
  );
  useEffect(() => {
    ubicacion = formulario ? formulario.geom : { lat: formulario ? formulario.geom.latitud : - 38.951478, lng: formulario ? formulario.geom.longitud : -68.0591339 }
  }, [formulario?.geom.latitud])

  const options = useMemo<MapOptions>(
    () => ({
      mapId: "eb4da33d85d9c2d4",
      disableDefaultUI: false,
      clickableIcons: false,
    }),
    []
  );
  const onLoad = useCallback((map) => (mapRef.current = map), []);




  return (
    <div className="map-container">
      <div className="map-container__map">
        <GoogleMap
          zoom={18}
          center={center}
          mapContainerClassName="map-container__map"
          options={options}
          onLoad={onLoad}
        >
          <Marker position={center} />
        </GoogleMap>
      </div>
    </div>
  );
}
export { Mapa }

const defaultOptions = {
  strokeOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
};
const closeOptions = {
  ...defaultOptions,
  zIndex: 3,
  fillOpacity: 0.05,
  strokeColor: "#8BC34A",
  fillColor: "#8BC34A",
};
const middleOptions = {
  ...defaultOptions,
  zIndex: 2,
  fillOpacity: 0.05,
  strokeColor: "#FBC02D",
  fillColor: "#FBC02D",
};
const farOptions = {
  ...defaultOptions,
  zIndex: 1,
  fillOpacity: 0.05,
  strokeColor: "#FF5252",
  fillColor: "#FF5252",
};

const generateHouses = (position: LatLngLiteral) => {
  const _houses: Array<LatLngLiteral> = [];
  for (let i = 0; i < 100; i++) {
    const direction = Math.random() < 0.5 ? -2 : 2;
    _houses.push({
      lat: position.lat + Math.random() / direction,
      lng: position.lng + Math.random() / direction,
    });
  }
  return _houses;
};
