import React from 'react'
import FormikControl from '../../../../../../../components/Formik/FormikControl'
import { RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, FormGroup, Checkbox, Divider } from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';
import TextErrorFormik from '../../../Formik/TextErrorFormik';
import MyCustomSelect from '../../../Formik/MyCustomSelect';
import CheckGroupFormik from '../../../../../../../components/Formik/CheckGroupFormik';
const tipoOptions = [
    {
        nombre: 'Casa',
        value: 'casa'
    },
    {
        nombre: 'Rancho',
        value: 'rancho'
    },
    {
        nombre: 'Casilla',
        value: 'casilla'
    },
    {
        nombre: 'Departamento',
        value: 'departamento'
    },
    {
        nombre: 'Pieza en inquilinato',
        value: 'piezaInquilinato'
    },
    {
        nombre: 'Pieza en hotel familiar o pensión',
        value: 'piezaHotel'
    },
    {
        nombre: 'Local no constituido para habitación',
        value: 'local'
    },
    {
        nombre: 'Vivienda móvil',
        value: 'movil'
    },
    {
        nombre: 'Personas en situación de calle',
        value: 'situacionCalle'
    },

];

const mediosTransporte = [
    {
        label: "Menos de 300 metros",
        value: "menos300mts"
    },
    {
        label: "Más de 300 metros",
        value: "mas300mts"
    }
]

const estadoCalles = [
    {
        label: "Pavimento",
        value: "pavimento"
    },
    {
        label: "Mejorado",
        value: "mejorado"
    },
    {
        label: "Tierra",
        value: "tierra"
    }
]

const grupoOptions = [
    {
        label: "Vive sólo/a",
        value: "viveSolo",
    },
    {
        label: "Hijo/a",
        value: "hijo",
    },
    {
        label: "Madre",
        value: "madre",
    },
    {
        label: "Hermano/a",
        value: "hermano",
    },
    {
        label: "Otros familiares",
        value: "otrosFamiliares",
    },
    {
        label: "Cónyuge",
        value: "conyuge",
    },
    {
        label: "Padre",
        value: "padre",
    },
    {
        label: "Abuelos/as",
        value: "abuelos",
    },
    {
        label: "Otros/as no familiares",
        value: "noFamiliares",
    },
];

const FormViviendaParticular = () => {
    const formik = useFormikContext<FormikValues>();
    return (
        <div className="row">
            <div className="col pb-10 pb-lg-12">
                <MyCustomSelect
                    label='Tipo'
                    name='vivienda.tipo'
                    value={formik.values.vivienda.tipo}
                    options={tipoOptions}
                />
            </div>

            <div className="col pb-10 pb-lg-12">
                <label className='form-label fw-bolder text-dark fs-6'>Infraestructura básica?</label>
                <RadioGroup
                    row
                    name="vivienda.infraestructuraBasica"
                    onChange={(event) => {
                        formik.handleChange(event);
                        formik.setFieldValue(
                            'vivienda.infraestructuraBasica',
                            event.target.value === 'true' // Convertir la cadena a booleano
                        );
                    }}
                >

                    <FormControlLabel 
                        value={true} 
                        control={<Radio />} 
                        label="Si"
                        checked={formik.values.vivienda.infraestructuraBasica === true} 
                    />
                    <FormControlLabel 
                        value={false} 
                        control={<Radio />} 
                        label="No"
                        checked={formik.values.vivienda.infraestructuraBasica === false} 
                    />

                </RadioGroup>
            </div>

            <div className="col pb-10 pb-lg-12">
                <label className='form-label fw-bolder text-dark fs-6'>Es vivienda adaptada?</label>
                <RadioGroup row name="vivienda.adaptada" onChange={(event) => {
                    formik.handleChange(event);
                    formik.setFieldValue(
                        'vivienda.adaptada',
                        event.target.value === 'true' // Convertir la cadena a booleano
                    );
                }}>

                    <FormControlLabel 
                        value={true}
                        control={<Radio />} 
                        label="Si" 
                        checked={formik.values.vivienda.adaptada === true}
                    />
                    <FormControlLabel 
                        value={false}
                        control={<Radio />}
                        label="No"
                        checked={formik.values.vivienda.adaptada === false}
                    />

                </RadioGroup>
            </div>

            <div className="col pb-10 pb-lg-12">
                <FormikControl
                    control='input'
                    type="number"
                    min={0}
                    label='Cantidad cuartos'
                    name='vivienda.cantidadCuartos'
                />

            </div>

            <label className='form-label fw-bolder text-dark fs-4'>Accesibilidad</label>
            <div className="col pb-10 pb-lg-1 mt-2">

                <label className='form-label fw-bolder text-dark fs-6'>Medios de transporte</label>

                <RadioGroup
                    row
                    onChange={(event: any) => formik.setFieldValue("vivienda.accesibilidad.medioTransporte", event.currentTarget.value)}
                >
                    {mediosTransporte.map((item) => (
                        <FormControlLabel
                            key={item.value}
                            control={<Radio checked={formik.values.vivienda?.accesibilidad?.medioTransporte?.includes(item.value)} />}
                            label={item.label}
                            value={item.value}
                        />
                    ))}
                    <div>
                    </div>
                </RadioGroup>

            </div>
            <div className="col pb-10 pb-lg-12">
                <label className='form-label fw-bolder text-dark fs-6'>Estado de calles</label>

                <RadioGroup
                    row
                    onChange={(event: any) => formik.setFieldValue("vivienda.accesibilidad.estadoCalles", event.currentTarget.value)}
                >
                    {estadoCalles.map((item) => (
                        <FormControlLabel
                            key={item.value}
                            control={<Radio checked={formik.values.vivienda?.accesibilidad?.estadoCalles?.includes(item.value)}/>}
                            label={item.label}
                            value={item.value}
                        />
                    ))}
                    <div>


                    </div>
                </RadioGroup>

            </div>
            <Divider />

            <label className='form-label fw-bolder text-dark fs-4 mt-5'>Grupo familiar</label>
            <div className="col-6 pb-10 pb-lg-1 mt-2">
                <FormGroup>
                    {grupoOptions.map((item) => (
                        <FormControlLabel
                            key={item.value}
                            control={
                                <Checkbox
                                    onChange={formik.handleChange}
                                    name="vivienda.grupoConviviente"
                                    value={item.value}
                                    checked={formik.values.vivienda?.grupoConviviente?.includes(item.value)}
                                />}
                            label={item.label}
                        />
                        //value={item.value} 
                        //                                checked={formik.values.grupoConviviente.includes(item.value)}
                    ))}

                </FormGroup>

            </div>
            <div className="col-6 mt-6 pb-10 pb-lg-12">
                <label className='form-label fw-bolder text-dark fs-6'>Convive con personas con CUD?</label>
                <RadioGroup row name="vivienda.personaConCud" onChange={(event) => {
                    formik.handleChange(event);
                    formik.setFieldValue(
                        'vivienda.personaConCud',
                        event.target.value === 'true' // Convertir la cadena a booleano
                    );
                }}>

                    <FormControlLabel 
                        value={true} 
                        control={<Radio />} 
                        label="Si" 
                        checked={formik.values.vivienda.personaConCud === true}
                    />
                    <FormControlLabel 
                        value={false} 
                        control={<Radio />} 
                        label="No"
                        checked={formik.values.vivienda.personaConCud === false}
                     />
                </RadioGroup>
                {formik.values.vivienda.personaConCud &&
                    <div className="mt-7">
                        <FormikControl
                            control="input"
                            type="number"
                            min={0}
                            label="Cantidad de personas con CUD"
                            name="vivienda.cantidadPersonas"
                        />

                    </div>
                }
            </div>


        </div>

    )
}

export default FormViviendaParticular