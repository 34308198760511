import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import clsx from 'clsx'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ISTATE } from '../../../../../../setup/redux/interface/reduxState.interface'
import { KTSVG } from '../../../../../../_metronic/helpers/components/KTSVG'
import { eventosSetComponente, setEvFiltro } from '../../Redux/actions/eventos'
import { seleccionMenu } from '../../Redux/actions/menuActions'
import './BotonAgenda.scss'

type Props = {
  imagen?: string
  pag_inicio: string
  nombre?: string
  colorFondo?: string
  colorDisable?: string
  sizeBoton?: string
  sizeIcon?: string
  datos?: any
  componenteActivo?: string
}
const ButtonMarginClass = 'ms-1 ms-lg-3'

const BotonAgenda: React.FC<Props> = ({
  imagen,
  pag_inicio,
  nombre,
  colorFondo,
  colorDisable,
  sizeBoton,
  sizeIcon,
  datos,
  componenteActivo
}) => {
  const dispatch = useDispatch()
  const selStore: any = useSelector((state: ISTATE) => state.menuBoton)
  const { seleccion } = selStore
  const evStore: any = useSelector((state: ISTATE) => state.eventos)
  const { evFull } = evStore
  let color: any = ''
  let ButtonIconSizeClass = sizeIcon !== undefined ? sizeIcon : 'svg-icon-1'
  let ButtonHeightClass = sizeBoton !== undefined ? sizeBoton : 'w-30px h-30px w-md-40px h-md-40px'


  ////////console.log('Boton Agenda eventoSel', eventoSel)
  // ////console.log('-------------modal', modalOpen)

  /*  dispatch(uiCloseModal()) */
  const resetMenu = () => {
    let temp: any = []
    seleccion.map((sel: any) => {
      sel.selec = true
      temp.push(sel)
    })
    dispatch(seleccionMenu(temp))
  }
  useEffect(() => {
    if (componenteActivo === 'indicadores') {
      dispatch(setEvFiltro(evFull))
    }
    dispatch(eventosSetComponente(componenteActivo))
  }, [])


  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement='top' />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${color}`,
      color: `#FFFFFF`,
      boxShadow: theme.shadows[1],
      fontSize: 11,
      fontWeightBold: 700,
      disabled: false,
    },
  }))
  return (
    <>
      <Link to={{ pathname: `${pag_inicio}`, state: { titulo: `${nombre}`, datos: datos } }}>
        <LightTooltip
          className='negrita'
          children={
            <div className={clsx('d-flex align-items-center', ButtonMarginClass)}>
              <button
                className={clsx(
                  `btn btn-icon  ${colorFondo} btn-custom ${colorDisable}`,
                  ButtonHeightClass
                )}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
                onClick={() => {
                  resetMenu()
                  dispatch(eventosSetComponente(componenteActivo))
                }}
              >
                <KTSVG path={`/media/icons/duotune${imagen}`} className={ButtonIconSizeClass} />
              </button>
            </div>
          }
          title={
            <Typography color='inherit' style={{ fontWeight: 600 }}>
              {nombre}
            </Typography>
          }
        ></LightTooltip>
      </Link>
    </>
  )
}
export { BotonAgenda }
