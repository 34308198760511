import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux'
import { ISTATE } from '../../../../../../../setup/redux/interface/reduxState.interface'
import Paper from '@mui/material/Paper';
import moment from 'moment'
import { useContext, useEffect } from 'react'

export const TablaIndicadores = () => {
  const areaState: any = useSelector((state: ISTATE) => state.areas)
  const { areas } = areaState
  const eventoState: any = useSelector((state: ISTATE) => state.eventos)
  const { evFull, evFiltro } = eventoState
  const menuState: any = useSelector((state: ISTATE) => state.menuBoton)
  const { seleccion } = menuState

  let rows: any = []

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  function crearData(
    area: string,
    colorIndicador: string,
    cantidad: number,
  ) {
    return {
      area, colorIndicador, cantidad
    };
  }

  for (let a = 0; a < seleccion.length; a++) {
    const sel = seleccion[a];
    if (sel.selec) {
      let eventosArea = 0
      for (let e = 0; e < evFiltro.length; e++) {
        const eventoF = evFiltro[e];
        if (eventoF.idArea === sel.id) {
          eventosArea = eventosArea + 1
        }
      }
      rows.push(crearData(sel.nombreCorto, sel.colorFondo, eventosArea))
    }
  }
  function CrearRows() {
    for (let a = 0; a < areas.length; a++) {
      const area: any = areas[a];
      let eventosArea = 0
      for (let e = 0; e < evFiltro.length; e++) {
        const eventoF = evFiltro[e];
        if (eventoF.idArea === area._id) {
          eventosArea = eventosArea + 1
        }
      }
      rows.push(crearData(area.detalleSistema.nombreCorto, area.detalleSistema.colorFondo, eventosArea))

    }
  }

  useEffect(() => {
    CrearRows()
  }, [])


  useEffect(() => {
    CrearRows()
  }, [evFiltro])



  return (
    <div><TableContainer component={Paper} key={'IndicadoresTabla'} style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "0rem"
    }}>
      <Table style={{
        width: '98%',
        marginTop: '10px',
        border: '1px solid #222',
      }} aria-label="customized table">
        <TableHead>
          <TableRow style={{ backgroundColor: 'black', color: 'white' }}>
            <TableCell align="center" style={{ color: 'white' }}>Area</TableCell>
            <TableCell align="center" style={{ color: 'white' }}>Cantidad</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <StyledTableRow key={row.area}>
              <TableCell align="center" className='capital ' style={{ borderLeft: '1px solid #222', borderBottom: '1px solid #222' }}>
                <div className='timeline-badge'>
                  <i className={'fa fa-circle  fs-2 sinlinea'} style={{ color: row.colorIndicador }}></i> {row.area}
                </div>
              </TableCell>
              <TableCell align="center" style={{ borderLeft: '1px solid #222', borderBottom: '1px solid #222' }}>{row.cantidad}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer></div>
  )
}
