import React, {useEffect, useState} from 'react'
import {ITablaDatos} from '../interfaces/TablaDatos'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
import {useSelector} from 'react-redux'
import {TablaModulos} from './TipoTablaDatos/TablaModulos'
import TablaUsuario from './TipoTablaDatos/TablaUsuario'
import TablaOrganismo from './TipoTablaDatos/TablaOrganismo'
import {useLocation} from 'react-router-dom'

let tablaInicio: ITablaDatos = {
  className: 'card-xxl-stretch text-white mb-5 mb-xl-8',
  tituloMenu: 'Administracion de sistemas',
  botonPrincipal: 'Crear',
  tablaDatos: [],
  link: '#',
  linkEdit: '#',
  headColumns: {
    col1: '',
    col2: '',
    col3: '',
    col4: '',
  },
}

const TablasDatos: React.FC<ITablaDatos> = ({
  className,
  tituloMenu,
  botonPrincipal,
  tablaDatos,
}) => {
  const [InfoMenu, setInfoMenu] = useState<ITablaDatos>(tablaInicio)
  const infoPrinc: any = useSelector((state: ISTATE) => state.tablaDatos)
  let location = useLocation()

  useEffect(() => {
    infoPrinc.tablaInicial ? setInfoMenu(tablaInicio) : setInfoMenu(infoPrinc)
  }, [infoPrinc])

  if (InfoMenu.tituloMenu === 'Administración de Modulos del Sistema') {
    return (
      <TablaModulos className='card-xxl-stretch text-white mb-5 mb-xl-8' tablaDatos={InfoMenu.tablaDatos} />
    )
  }
  if (InfoMenu.tituloMenu === 'Administración de usuarios') {
    return (
      <TablaUsuario className='card-xxl-stretch text-white mb-5 mb-xl-8' tablaDatos={InfoMenu.tablaDatos} />
    )
  } else {
    return (
      <TablaOrganismo className='card-xxl-stretch text-white mb-5 mb-xl-8' tablaDatos={InfoMenu.tablaDatos} />
    )
  }
}

export default TablasDatos
