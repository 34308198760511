import React from 'react'
import Swal from 'sweetalert2'
import {ErrorMessage} from 'formik'
interface Props {
  formik?: any
}

export const Step3DtsDoc: React.FC<Props> = ({formik}) => {
  const validarPdf = async (data: any, idInput: string) => {
    if (data) {
      if (data.size > 2000000) {
        await Swal.fire({
          icon: 'info',
          title: 'El tamaño del archivo excele el limite permitido, 2 MB.',
          timer: 3000,
        })
        ;(document.getElementById(idInput) as HTMLInputElement)!.value = ''
        return false
      }
      let ext = data.name.split('.')
      if (ext[1] === 'pdf') {
        return true
      } else {
        await Swal.fire({
          icon: 'info',
          title: 'Solo se aceptan archivos PDF.',
          timer: 3000,
        })
        ;(document.getElementById(idInput) as HTMLInputElement)!.value = ''
        return false
      }
    } else {
      ;(document.getElementById(idInput) as HTMLInputElement)!.value = ''
      return false
    }
  }

  return (
    <>
      {(formik.validateOnMount = true)}
      <div className='w-100'>
        <div className='pb-10 pb-lg-15'>
          <h2 className='fw-bolder d-flex align-items-center text-dark p-3'>
            Documentación solicitada por unica vez, para el empleado que se encuentra cargando.
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Billing is issued based on your selected account type'
            ></i>
          </h2>

          {/*  <div className='text-gray-400 fw-bold fs-6 p-3'>
              La nómina de empleados asociada al 931 del/los meses/es por el/los cual/es se solicita
              el beneficio descargada de AFIP.
            </div> */}
          <div className='fv-row'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label '>
                Constancia de programa social de alcance nacional, provincial y municipal.
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='file'
                  accept='.pdf'
                  id='progSocial'
                  name='progSocial'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  onChange={async (e: any) => {
                    if (e.target.files) {
                      ;(await validarPdf(e.target.files[0], 'progSocial')) &&
                        formik.setFieldValue('progSocial', e.target.files[0])
                    }
                  }}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Alta Temprana de AFIP – Formulario 885/A,
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  type='file'
                  accept='.pdf'
                  id='altaAfip'
                  name='altaAfip'
                  onChange={async (e: any) => {
                    if (e.target.files) {
                      ;(await validarPdf(e.target.files[0], 'altaAfip')) &&
                        formik.setFieldValue('altaAfip', e.target.files[0])
                    }
                  }}
                />
              </div>
              <div className='fv-plugins-message-container invalid-feedback'>
                <ErrorMessage name='altaAfip' />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Historial laboral de ANSES
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='file'
                  accept='.pdf'
                  id='historialAnses'
                  name='historialAnses'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  onChange={async (e: any) => {
                    if (e.target.files) {
                      ;(await validarPdf(e.target.files[0], 'historialAnses')) &&
                        formik.setFieldValue('historialAnses', e.target.files[0])
                    }
                  }}
                />
              </div>
              <div className='fv-plugins-message-container invalid-feedback'>
                <ErrorMessage name='historialAnses' />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                DDJJ Firmada por el Empleado
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='file'
                  accept='.pdf'
                  id='ddjjEmp'
                  name='ddjjEmp'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  onChange={async (e: any) => {
                    if (e.target.files) {
                      ;(await validarPdf(e.target.files[0], 'ddjjEmp')) &&
                        formik.setFieldValue('ddjjEmp', e.target.files[0])
                    }
                  }}
                />
                <div className='fv-plugins-message-container invalid-feedback'>
                  <ErrorMessage name='ddjjEmp' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
