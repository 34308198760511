import {useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers/components/KTSVG'
import {ICreateArea, ICreateAreas} from '../../../interfaces/ICreateArea'
import {ModalArea} from '../ModalArea/ModalArea'
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip'
import {styled} from '@mui/material/styles'
import Swal from 'sweetalert2'
import {ModalImg} from '../../ModalImg/ModalImg'
import {useSelector} from 'react-redux'
import {ISTATE} from '../../../../../../../setup/redux/interface/reduxState.interface'
import {ICreateModulo} from '../../../interfaces/ICreateModulo'
type Props = {
  className: string
  tablaDatos?: []
  _idOrg?: string
}

const AreaTable: React.FC<Props> = ({className, tablaDatos, _idOrg}) => {
  let dashState: any = useSelector((state: ISTATE) => state.dashBoard)
  let {perfil} = dashState
  let modulo: ICreateModulo = perfil.find((value: any) => value.nombre === 'administracion')
  let menu = modulo.menu.find((value) => value.name === 'organismos')
  const [filtro, setFiltro] = useState<boolean>(false)
  const [areaSearch, setAreaSearch] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [openModalImg, setOpenModalImg] = useState(false)
  const [areaSelect, setAreaSelect] = useState<ICreateArea>()
  const [imgSelect, setImgSelect] = useState<any>()
  const [currentPage, setCurrentPage] = useState(0)

  let color: string = ''
  const closeModalImg = () => {
    setOpenModalImg(!openModalImg)
  }

  const BootstrapTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} arrow classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
      backgroundColor: `${color}`,
      color: `#919A9B`,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${color}`,
      color: `#FFFFFF`,
      boxShadow: theme.shadows[2],
      fontSize: 11,
      fontWeightBold: 700,
      disabled: false,
    },
  }))

  const modalOpen = (e: any) => {
    e._idOrg = _idOrg
    e._idArea = e._id
    e.nombreArea = e.nombreArea
    setAreaSelect(e)
    setOpenModal(!openModal)
  }
  const siguientePag = () => {
    tablaDatos!.length > currentPage + 5 && setCurrentPage(currentPage + 5)
  }
  const anteriorPag = () => {
    currentPage > 0 && setCurrentPage(currentPage - 5)
  }

  const search = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0)
    setAreaSearch(event.target.value)
  }
  const areas = (): ICreateAreas => {
    let areas: ICreateAreas | undefined = tablaDatos
    if (areaSearch.length === 0) {
      return areas!.slice(currentPage, currentPage + 5)
    } else {
      const area = areas?.filter((ar) => ar.nombreArea.includes(areaSearch))
      return area!.slice(currentPage, currentPage + 5)
    }
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'></span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-light-info'
            onClick={(e: any) => {
              setFiltro(!filtro)
              setAreaSearch('')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-5 svg-icon-gray-500 me-1'
            />
            Filtrar
          </button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* Begin Filtro*/}
          {filtro && (
            <div className='fv-row mb-10'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Ingrese el nombre del área'
                value={areaSearch}
                onChange={search}
              />
            </div>
          )}
          {/* End Filtro*/}

          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'>Áreas</th>
                <th className='min-w-125px'>Responsable</th>
                <th className='min-w-125px'>Localidad</th>
                <th className='min-w-130px rounded-end'>Estado</th>
                {(menu?.roleUs?.editor || menu?.roleUs?.crear) && (
                  <th className='min-w-150px text-end rounded-end'>Opciones</th>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {areas().map((area: ICreateArea) => (
                <tr key={area._id}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-50px me-5'>
                        <img src={area.detalleSistema.icono} alt='' />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <div className='text-dark fw-bolder text-hover-primary mb-1 fs-6 capital'>
                          {area.nombreArea}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6 capital'>
                      {area.datosTitular.nombre}
                    </div>
                    <span className='text-muted fw-bold text-muted d-block fs-7 capital'>
                      {area.datosTitular.apellido}
                    </span>
                  </td>
                  <td>
                    <div className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6 capital'>
                      {area.direccionArea.localidad}
                    </div>
                    <span className='text-muted fw-bold text-muted d-block fs-7 capital'>
                      {area.direccionArea.calle} {area.direccionArea.numero}
                    </span>
                  </td>
                  {area.activo ? (
                    <td>
                      <span className='badge badge-light-success fs-7 fw-bold'>Activo</span>
                    </td>
                  ) : (
                    <td>
                      <span className='badge badge-light-danger fs-7 fw-bold'>Desactivado</span>
                    </td>
                  )}
                  {(menu?.roleUs?.editor || menu?.roleUs?.crear) && (
                    <td className='text-end'>
                      <BootstrapTooltip title='Modificar Logo' arrow followCursor placement='top'>
                        <div
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          onClick={(e: any) => {
                            Swal.fire({
                              title: 'Esta por modificar la imagen.',
                              text: '¿Desea continuar?',
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Continuar!',
                              allowOutsideClick: false,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                setImgSelect(area)
                                setOpenModalImg(!openModalImg)
                              }
                            })
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen019.svg'
                            className='svg-icon-3'
                          />
                        </div>
                      </BootstrapTooltip>

                      <div
                        onClick={() => {
                          modalOpen(area)
                        }}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
          {!filtro && (
            <ul className='pagination'>
              {currentPage === 0 ? (
                <li className='page-item disabled'>
                  <button type='button' className='btn btn-sm btn-light-info' onClick={anteriorPag}>
                    Anterior
                  </button>
                </li>
              ) : (
                <li className='page-item '>
                  <button type='button' className='btn btn-sm btn-light-info' onClick={anteriorPag}>
                    Anterior
                  </button>
                </li>
              )}

              <li className='page-item'>
                <button type='button' className='btn btn-sm btn-light-info' onClick={siguientePag}>
                  Siguiente
                </button>
              </li>
            </ul>
          )}
        </div>
        {/* end::Table container */}
      </div>
      <ModalArea openModal={openModal} OrgArea={areaSelect} />
      {/* begin::Body */}
      <ModalImg
        openModal={openModalImg}
        modulo={imgSelect}
        servicioName={'area'}
        closeModal={closeModalImg}
        _idOrg={_idOrg}
      />
    </div>
  )
}

export default AreaTable
