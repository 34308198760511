import React from 'react'
import FormikControl from '../../../../../../../components/Formik/FormikControl'
import { Formik, FormikValues, useFormikContext } from 'formik';
import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup } from '@mui/material';

const tipoOptions = [
    {
        label: 'Hospital',
        value: 'hospital'
    },
    {
        label: 'Hogar',
        value: 'hogar'
    },
    {
        label: 'Prisión',
        value: 'prision'
    },
    {
        label: 'Residencia',
        value: 'residencia'
    },
    {
        label: 'Otro',
        value: 'otro'
    },
];
const FormViviendaColectiva = () => {
    const formik = useFormikContext<FormikValues>();
    return (
        <div>
            <div className="col-12 pb-10 pb-lg-12">
                <label className='form-label fw-bolder text-dark fs-6'>Tipo de vivienda</label>
                <FormGroup row>
                    {tipoOptions.map((item) => (
                        <FormControlLabel
                            key={item.value}
                            control={
                                <Checkbox
                                    checked={Array.isArray(formik.values.vivienda.tipo) && formik.values.vivienda.tipo.includes(item.value)}
                                    onChange={formik.handleChange}
                                    name="vivienda.tipo"
                                    value={item.value}
                                />}
                            label={item.label}
                        />
                    ))}
                </FormGroup>
                {Array.isArray(formik.values.vivienda.tipo) && formik.values.vivienda.tipo.includes('otro') && <FormikControl
                    control="input"
                    name="vivienda.especificar"
                    label="Especificar otro"
                />}

                <label className='form-label fw-bolder text-dark fs-6'>Se encuentra en condición de externación</label>
                <RadioGroup 
                    row 
                    name="vivienda.condExternacion"
                    onChange={(event) => {
                        formik.handleChange(event);
                        formik.setFieldValue(
                            'vivienda.condExternacion',
                            event.target.value === 'true' // Convertir la cadena a booleano
                        );
                    }}>

                    <FormControlLabel 
                        value={true} 
                        control={<Radio />} 
                        label="Si" 
                        checked={formik.values.vivienda.condExternacion === true}
                    />
                    <FormControlLabel 
                        value={false} 
                        control={<Radio />} 
                        label="No" 
                        checked={formik.values.vivienda.condExternacion === false}
                    />

                </RadioGroup>
            </div>
        </div>
    )
}

export default FormViviendaColectiva;