import { ErrorMessage, Field, Formik } from 'formik'
import { FC, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ISTATE } from '../../../../../../../../setup/redux/interface/reduxState.interface'
import { KTSVG } from '../../../../../../../../_metronic/helpers/components/KTSVG'
import TextErrorFormik from '../../../../../../../components/Formik/TextErrorFormik'
import GeoLoc_Service from '../../../../../../../services/gral/GeoLoc_Service'
import { getLocProv, getProvincias } from '../../../../../../../services/gral/proLocService'
import MapaGoogle from '../../../../components/MapaGoogle/MapaGoogle'
type Props = {
  data: any
  valido?: boolean
}

interface IOptions
  extends Array<{
    value: string
    label: string
  }> { }

const Step4: FC<Props> = ({ data, valido }) => {
  const [localizado, setlocalizado] = useState(false)
  const [dirLatLon, setDirLatLon] = useState<any>({ lat: -38.9512722, lon: -68.0578955 })
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [provincias, setProvincias] = useState([])
  const [localidades, setLocalidades] = useState([])
  const [habLocalidad, setHabLocalidad] = useState(true)
  const [buscar, setBuscar] = useState(0)
  const eventoState: any = useSelector((state: ISTATE) => state.eventos)
  const { eventosFull } = eventoState
  const provOptions: IOptions = []
  let locOptions: IOptions = []



  const buscarGeoDir = (dir: any, buscador: any) => {
    GeoLoc_Service.buscarDireccion(dir).then((res: any) => {
      //console.log('res.data.data.lenght', res.data.data)
      if (res.data.data.length !== 0) {
        let datos = res.data.data[0]
        data.pais = 'Argentina'
        if (buscador === 'Lugar') {
          data.provincia = datos.administrativeLevels.level1long
          data.localidad = datos.administrativeLevels.level1short
          data.pais = datos.country
        }
        let lat = datos.latitude
        let lon = datos.longitude
        data.calle = datos.streetName
        data.numero = datos.streetNumber
        data.geom = {
          latitud: lat,
          longitud: lon,
        }
        //setEvento({ ...evento, data.ubicacion: data.ubicacion })
        setDirLatLon({ lat, lon })

        setlocalizado(true)
      } else {
        ////////console.log("NO SE ENCONTRO LA DIRECCION/LUGAR BUSCADO");
      }
    })
  }
  useEffect(() => {
    getProvincias().then((res: any) => {
      //console.log('res.data.data', res.data.data)
      setProvincias(res.data.data)
    })
  }, [])
  //console.log('provincias', provincias)
  /* data.provincia = 'Neuquén' */

  useEffect(() => { }, [localizado, dirLatLon])

  useEffect(() => {
    if (data.provincia !== '') {
      ////////console.log('data.provincia', data.provincia)
      cargarLocalidades(data.provincia)
      locOptions = []
    } else {

    }
  }, [data.provincia])
  // ////console.log('-Localidades :', localidades)

  useEffect(() => {
  }, [buscar, localizado])

  const cargarLocalidades = (prov: any) => {
    ////////console.log('prov', prov)
    getLocProv(prov).then((res: any) => {
      ////////console.log('LocProv', res.data.data)
      setLocalidades(res.data.data)
      setHabLocalidad(false)
    })
  }
  for (let p = 0; p < localidades.length; p++) {
    let loc: any = localidades[p]
    locOptions.push({ value: p.toString(), label: loc.nombre })
  }
  locOptions.sort(function compare(a: any, b: any) {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    // a debe ser igual b
    return 0
  })
  for (let p = 0; p < provincias.length; p++) {
    let pro: any = provincias[p]
    provOptions.push({ value: pro.id, label: pro.nombre })
  }
  provOptions.sort(function compare(a: any, b: any) {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    // a debe ser igual b
    return 0
  })
  ////////console.log('provOptions', provOptions)
  ////////console.log('locOptions', locOptions)

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h3 className='fw-bolder text-dark'>Ubicación del Evento</h3>
        <div className="row">

          <div className='col-11 mb-5 fv-row'>
            <label className='form-label mb-3'>Lugar donde se realizará el Evento</label>
            <Field
              type='text'
              className='form-control form-control-sm form-control-solid'
              name='lugar'
              placeholder='Complete el Lugar del evento'
              value={data.lugar}
            />
            <ErrorMessage name='lugar' component={TextErrorFormik} />
          </div>
          <div className='col-1 mt-9'>
            <button
              type='button'
              className='btn btn-lg btn-primary me-3'
              onClick={() => {

                let dire = data.lugar
                buscarGeoDir(dire, "Lugar")
              }}
            >
              <span className='indicator-label'>

                <KTSVG
                  path='/media/icons/duotune/general/gen004.svg'
                  className='svg-icon-3 ms-0 me-0'
                />
              </span>
            </button>
          </div>
        </div>
        {/* <div className='col-11 mb-5 fv-row'>
          <label className='form-label mb-3'>Lugar donde se realizará el Evento</label>
          <Field
            type='text'
            className='form-control form-control-sm form-control-solid'
            name='lugar'
            placeholder='Complete el Lugar del evento'
            value={data.lugar}
          />
          <ErrorMessage name='lugar' component={TextErrorFormik} />
        </div> */}
        <div className='row'>
          <h3 className='fw-bolder text-dark'>Dirección del Evento</h3>
          <div className='col-6'>
            <label className='form-label fw-bolder text-dark fs-6'>Provincia</label>
            <Field
              as='select'
              className='form-select form-select-solid capital'
              name='provincia'
            >
              <option value=''>Seleccione</option>
              {provOptions?.map((option: any) => {
                return (
                  <option key={option?.value} value={option?.label}>
                    {option?.label}
                  </option>
                )
              })}
            </Field>
            <ErrorMessage name='provincia' component={TextErrorFormik} />
          </div>
          {/* <div className='col-6'>
            <label htmlFor="provincia" className='form-label fw-bolder text-dark fs-6'>Provincia</label>
            <SelectFormikProv
              value={provincia}
              name='provincia'
              onChange={(value: any) => {
                ////////console.log('value', value)
                provincia = value
                cargarLocalidades(provincia)
              }}
              options={provOptions}
            />
            <ErrorMessage name='provincia' component={TextErrorFormik} />
          </div> */}
          <div className='col-6'>
            <label className='form-label fw-bolder text-dark fs-6'>Localidad</label>
            <Field
              as='select'
              disabled={habLocalidad}
              name='localidad'
              className='form-select form-select-solid capital'
            >
              <option value=''>Seleccione</option>
              {locOptions?.map((option: any) => {
                return (
                  <option key={option?.value} value={option?.label}>
                    {option?.label}
                  </option>
                )
              })}
            </Field>
            <ErrorMessage name='localidad' component={TextErrorFormik} />
          </div>

          <div className='col-4 mb-5'>
            <label className='form-label mb-3'>Calle</label>
            <Field
              type='text'
              className='form-control form-control-sm form-control-solid'
              name='calle'
              placeholder='Complete la Calle del evento'
              value={data.calle}
            />
            <ErrorMessage name='calle' component={TextErrorFormik} />
          </div>
          <div className='col-2 mb-5'>
            <label className='form-label mb-3'>Número</label>
            <Field
              type='text'
              className='form-control form-control-sm form-control-solid'
              name='numero'
              placeholder='Complete Nro'
              value={data.numero}
            />
            <ErrorMessage name='numero' component={TextErrorFormik} />
          </div>

          <div className='col-1 mt-9'>
            <button
              type='button'
              /* disabled={habilitar} */
              className='btn btn-lg btn-primary me-3'
              onClick={() => {
                let dire =
                  data.calle +
                  ' ' +
                  data.numero +
                  ', ' +
                  data.localidad +
                  ', ' +
                  data.provincia
                buscarGeoDir(dire, 'Argentina')
                let busqueda = buscar + 1
                setBuscar(busqueda)
              }}
            >
              <span className='indicator-label'>
                <KTSVG
                  path='/media/icons/duotune/general/gen004.svg'
                  className='svg-icon-3 ms-0 me-0'
                />
              </span>
            </button>
          </div>
          <div className='col-2 mb-5'>
            <label className='form-label mb-3'>Latitud</label>
            <Field
              type='text'
              disabled={true}
              className='form-control form-control-sm form-control-solid'
              name='geom.latitud'
              value={data.geom.latitud}
            />
            <ErrorMessage name='provincia' component={TextErrorFormik} />
          </div>
          <div className='col-2 mb-5'>
            <label className='form-label mb-3'>Longitud</label>
            <Field
              type='text'
              disabled={true}
              className='form-control form-control-sm form-control-solid'
              name='geom.longitud'
              value={data.geom.longitud}
            />
            <ErrorMessage name='provincia' component={TextErrorFormik} />
          </div>
        </div>
      </div>
      <div className='mb-5 fv-row align-items-center '>
        <div className='googlemap-container'>
          <MapaGoogle />
        </div>
      </div>
    </div>
  )
}

export { Step4 }
