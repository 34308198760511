import React, {useEffect} from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {ISubArea, ISubAreas} from '../../interfaces/ICreateSubArea'
import {IOficina} from '../../interfaces/IOficina'
import {Tooltip} from '@mui/material'
import {Link} from 'react-router-dom'

interface ITableMaterial {
  subArea: ISubAreas
  dataApi: {}
}

function Row(props: {row: ISubArea; dataApi: any}) {
  const {row} = props
  let infoApi = {
    _idOrg: props.dataApi._idOrg,
    _idArea: props.dataApi._idArea,
    _idSubArea: props.row._id,
    nombreArea: props.dataApi.nombreArea,
    nombreSubArea: row.nombreArea,
  }
  const [open, setOpen] = React.useState(false)

  return (
    <React.Fragment>
      <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align='left' component='th' scope='row'>
          {row.nombreArea}
        </TableCell>
        <TableCell align='left'>{row.tipoArea}</TableCell>
        <TableCell align='left'>
          {row.datosTitular.apellido} {row.datosTitular.nombre}
        </TableCell>
        <TableCell align='left'>{row.datosContacto.email}</TableCell>

        {row.activo ? (
          <TableCell>
            {' '}
            <span className='badge badge-light-success fs-7 fw-bold'>Activo</span>
          </TableCell>
        ) : (
          <TableCell>
            <span className='badge badge-light-danger fs-7 fw-bold'>Desactivado</span>
          </TableCell>
        )}
        <TableCell align='center'>
          <Tooltip title='Agregar Oficina'>
            <IconButton color='primary' aria-label='upload picture'>
              <Link to={{pathname: 'dependencia', state: infoApi}}>
                <AddIcon />
              </Link>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{margin: 1}}>
              <Typography variant='h6' gutterBottom component='div'>
                Oficinas
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Tipo de oficina</TableCell>
                    <TableCell align='right'>Responsable</TableCell>
                    <TableCell align='right'>Contacto </TableCell>
                    <TableCell align='right'>Estado </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.oficinas?.map((oficina: IOficina) => (
                    <TableRow key={oficina._id}>
                      <TableCell component='th' scope='row'>
                        {oficina.nombreArea}
                      </TableCell>
                      <TableCell>{oficina.tipoArea}</TableCell>
                      <TableCell>
                        {oficina.datosTitular.nombre}
                        {oficina.datosTitular.apellido}
                      </TableCell>
                      <TableCell align='right'>{oficina.datosContacto.email}</TableCell>
                      {oficina.activo ? (
                        <TableCell>
                          {' '}
                          <span className='badge badge-light-success fs-7 fw-bold'>Activo</span>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <span className='badge badge-light-danger fs-7 fw-bold'>Desactivado</span>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const TablaAreasMat: React.FC<ITableMaterial> = ({subArea, dataApi}) => {
  const [areaRows, setAreaRows] = React.useState<ISubAreas>([])

  useEffect(() => {
    setAreaRows(subArea)
    return () => {
      setAreaRows([])
    }
  }, [])

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        {
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align='left'>Area </TableCell>
                <TableCell align='left'>Tipo de Area</TableCell>
                <TableCell align='left'>Responsable</TableCell>
                <TableCell align='center'>Email&nbsp;</TableCell>
                <TableCell align='left'>Estado&nbsp;</TableCell>
                <TableCell align='right'>Agregar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {areaRows.map((row: ISubArea) => (
                <Row key={row._id} row={row} dataApi={dataApi} />
              ))}
            </TableBody>
          </Table>
        }
      </TableContainer>
    </React.Fragment>
  )
}

export default TablaAreasMat
