import React from 'react'
import {useHistory} from 'react-router'

import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

export const Page404Cud: React.FC = () => {
  const history = useHistory()

  const redirectToDashboard = () => {
    history.push('/')
  }
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
        <div className='d-flex flex-column flex-column-fluid text-center p-5 py-lg-20'>
          <a href='/dashboard' className='mb-10 pt-lg-20'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/apex.png')} className='h-50px mb-5' />
          </a>
          <div className='pt-lg-5 mb-5'>
            <>
              <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>
                No cuenta con los permisos necesarios, para acceder al modulo requerido.
              </h1>

              <div className='fw-bold fs-3 text-gray-400 mb-10'>
                Consulte con el administrador del sistema. <br />
              </div>
            </>
          </div>
          <div
            className='
          d-flex
          flex-row-auto
          bgi-no-repeat
          bgi-position-x-center
          bgi-size-contain
          bgi-position-y-bottom
          min-h-200px min-h-lg-350px
        '
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/dozzy-1/4-dark.png')}')`,
            }}
          ></div>
        </div>

        <div className='d-flex flex-center flex-column-auto p-5'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            <a
              href='https://www.ministerionaj.gob.ar/'
              className='text-muted text-hover-primary px-2'
            >
              Acerca de Apex!
            </a>
            <a href='' className='text-muted text-hover-primary px-2'>
              Contacto
            </a>
            <a href='' className='text-muted text-hover-primary px-2'>
              Contacta con nosotros
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
