import React, {useEffect, useState} from 'react'
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip'
import {styled} from '@mui/material/styles'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {ISTATE} from '../../../../../../setup/redux/interface/reduxState.interface'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ITablaDatos} from '../../interfaces/TablaDatos'
import {cambiarPassword, deshabilitarCuenta} from '../../services/UsuarioService'
import {IUsuarios} from '../../interfaces/ICreateUsr'
import {ICreateModulo} from '../../interfaces/ICreateModulo'

let tablaInicio: ITablaDatos = {
  className: 'card-xxl-stretch mb-5 mb-xl-8',
  tituloMenu: 'Administracion de sistemas',
  botonPrincipal: 'Crear',
  tablaDatos: [],
  link: '#',
  linkEdit: '#',
  headColumns: {
    col1: '',
    col2: '',
    col3: '',
    col4: '',
  },
}
const TablaUsuario: React.FC<ITablaDatos> = ({
  className,
  tituloMenu,
  botonPrincipal,
  tablaDatos,
}) => {
  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)
  let {perfil} = dashState
  const modulo: ICreateModulo = perfil.find((value: any) => value.nombre === 'administracion')
  const menu: any = modulo.menu.find((value) => value.name === 'usuarios')

  const [selecUs, setSelecUs] = useState<string>('')
  const [InfoMenu, setInfoMenu] = useState<ITablaDatos>(tablaInicio)
  const [usuario, setUsuario] = useState([])
  const [usuarioOption, setUsuarioOption] = useState<boolean>(false)
  const [filtro, setFiltro] = useState<boolean>(false)
  const [usSearch, setUsSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const infoPrinc: any = useSelector((state: ISTATE) => state.tablaDatos)
  let color: string = ''

  const handelSelecUS = (e: any) => {
    selecUs === e ? setSelecUs('') : setSelecUs(e)
    if (selecUs !== e) {
      let data: any = InfoMenu.tablaDatos?.filter((data: any) => data._id === e)
      setUsuario(data)
      setUsuarioOption(!usuarioOption)
    } else {
      setUsuario([])
      setUsuarioOption(!usuarioOption)
    }
  }

  const resetPsw = (usuario: string) => {
    Swal.fire({
      title: 'Esta por resetear la contraseña',
      text: '¿Desea continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar!',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          usuario: usuario,
          password: '123456',
          passwordNuevo: '',
        }
        let opcion = 'resetPSW'

        cambiarPassword(data, opcion).then((res: any) => {
          if (res.data.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Se reinicio correctamente la contraseña,',
              text: 'La nueva constraseña es el usuario',
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${res.data.msg}`,
            })
          }
        })
      }
    })
  }
  const offCuenta = (usuario: string) => {
    Swal.fire({
      title: 'Esta por modificar el estado de la cuenta',
      text: '¿Desea continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar!',
      allowOutsideClick: false,
    }).then((result) => {
      result.isConfirmed &&
        deshabilitarCuenta(usuario).then((res: any) => {
          if (res.data.ok) {
            Swal.fire({
              icon: 'success',
              title: 'La cuenta fue modificada correctamente',
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${res.data.msg}`,
            })
          }
        })
    })
  }
  //// Paginacion y Busqueda

  const siguientePag = () => {
    InfoMenu?.tablaDatos!.length > currentPage + 5 && setCurrentPage(currentPage + 5)
  }
  const anteriorPag = () => {
    currentPage > 0 && setCurrentPage(currentPage - 5)
  }
  const search = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0)
    setUsSearch(event.target.value)
  }
  const usuarios = (): IUsuarios => {
    let usuarios: IUsuarios | undefined = InfoMenu.tablaDatos
    if (usSearch.length === 0) {
      return usuarios!.slice(currentPage, currentPage + 5)
    } else {
      const usuario = usuarios?.filter((us) => us.usuario.includes(usSearch))
      return usuario!.slice(currentPage, currentPage + 5)
    }
  }

  //// fin de Paginacion y busqueda
  useEffect(() => {
    infoPrinc.tablaInicial ? setInfoMenu(tablaInicio) : setInfoMenu(infoPrinc)
  }, [infoPrinc])

  const BootstrapTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} arrow classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
      backgroundColor: `${color}`,
      color: `#919A9B`,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${color}`,
      color: `#FFFFFF`,
      boxShadow: theme.shadows[2],
      fontSize: 11,
      fontWeightBold: 700,
      disabled: false,
    },
  }))
  return (
    <div className={`card ${InfoMenu.className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label text-white fw-bolder fs-3 mb-1'>{InfoMenu.tituloMenu}</span>
        </h3>
        <div className='card-toolbar'></div>
        <div
          className='card-toolbar'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Crear Usuario'
        >
          {!selecUs && (menu?.roleUs?.consulta || menu?.roleUs?.editor || menu?.roleUs?.crear) && (
            <button
              type='button'
              className='btn btn-sm btn-light-info me-2'
              onClick={(e: any) => {
                setFiltro(!filtro)
                setUsSearch('')
              }}
            >
              <KTSVG
                path='/media/icons/duotune/general/gen031.svg'
                className='svg-icon-5 svg-icon-gray-500 me-1'
              />
              Filtrar
            </button>
          )}
          {infoPrinc.link && !selecUs && menu?.roleUs?.crear && (
            <Link
              to={infoPrinc.link}
              className='btn btn-sm btn-light-primary'
              // data-bs-toggle='modal'
              // data-bs-target='#kt_modal_invite_friends'
            >
              <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              {InfoMenu.botonPrincipal}
            </Link>
          )}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          {filtro && (
            <div className='fv-row mb-10'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Ingrese el número de DNI del usuario a buscar'
                value={usSearch}
                onChange={search}
              />
              <div className='fv-plugins-message-container invalid-feedback'>
                App name is a required field
              </div>
            </div>
          )}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            {InfoMenu.tablaDatos && (
              <thead>
                <tr className='fw-bolder text-muted bg-light'>
                  <th className='w-50px rounded-start'></th>
                  <th className='min-w-150px'>{InfoMenu.headColumns?.col1}</th>
                  <th className='min-w-140px '>{InfoMenu.headColumns?.col2}</th>
                  {!selecUs && (
                    <th className='min-w-120px rounded-end'>{InfoMenu.headColumns?.col3}</th>
                  )}
                  {usuarioOption && (
                    <th className='min-w-100px text-end rounded-end'>
                      {InfoMenu.headColumns?.col4}
                    </th>
                  )}
                </tr>
              </thead>
            )}
            {/* end::Table head */}
            {/* begin::Table body */}
            {usuarios()?.length ? (
              <tbody>
                {selecUs === ''
                  ? usuarios()?.map((item: any) => (
                      <tr key={item._id}>
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              value='1'
                              onChange={(e) => {
                                handelSelecUS(item._id)
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                              <img src={item.datosPersonales.foto} alt='' />
                            </div>

                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                href='#'
                                className='text-dark fw-bolder uppercase text-hover-primary fs-6'
                              >
                                {item.datosPersonales.apellido}
                              </a>
                              <span className='text-muted fw-bold text-muted d-block fs-7 capital'>
                                {item.datosPersonales.nombres}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block fs-6 capital'
                          >
                            {item.datosLaborales?.datosOrganismo.nombre}
                          </a>
                          {item.datosLaborales?.datosOrganismo.areaPrincipal?.nombreArea !==
                            undefined && (
                            <span className='text-muted fw-bold text-muted d-block fs-7 capital'>
                              {item.datosLaborales?.datosOrganismo.areaPrincipal.nombreArea}
                            </span>
                          )}
                        </td>

                        {!selecUs && (
                          <td className='text-end'>
                            {item.activo ? (
                              <span className='badge badge-light-success fs-8 fw-bolder my-2'>
                                Activo
                              </span>
                            ) : (
                              <span className='badge badge-light-danger fs-8 fw-bolder my-2'>
                                Desactivado
                              </span>
                            )}
                          </td>
                        )}
                      </tr>
                    ))
                  : usuario.map((item: any) => (
                      <tr key={item._id}>
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              value='1'
                              onChange={(e) => {
                                handelSelecUS(item._id)
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                              <img src={item.datosPersonales.foto} alt='' />
                            </div>

                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                href='#'
                                className='text-dark fw-bolder uppercase text-hover-primary fs-6'
                              >
                                {item.datosPersonales.apellido}
                              </a>
                              <span className='text-muted fw-bold text-muted d-block fs-7 capital'>
                                {item.datosPersonales.nombres}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block fs-6 capital'
                          >
                            {item.datosLaborales?.datosOrganismo.nombre}
                          </a>
                          {item.datosLaborales?.datosOrganismo.areaPrincipal?.nombreArea !==
                            undefined && (
                            <span className='text-muted fw-bold text-muted d-block fs-7 capital'>
                              {item.datosLaborales?.datosOrganismo.areaPrincipal.nombreArea}
                            </span>
                          )}
                        </td>

                        {!selecUs && (
                          <td className='text-end'>
                            {item.activo ? (
                              <span className='badge badge-light-success fs-8 fw-bolder my-2'>
                                Activo
                              </span>
                            ) : (
                              <span className='badge badge-light-danger fs-8 fw-bolder my-2'>
                                Desactivado
                              </span>
                            )}
                          </td>
                        )}

                        <td>
                          {(menu?.roleUs?.editor || menu?.roleUs?.crear) && (
                            <>
                              <div className='d-flex justify-content-end flex-shrink-0'>
                                <BootstrapTooltip
                                  title='Modificar Perfil'
                                  arrow
                                  followCursor
                                  placement='top'
                                >
                                  <Link
                                    to={{pathname: 'usuario/account', state: item}}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='svg-icon-3'
                                    />
                                  </Link>
                                </BootstrapTooltip>

                                <BootstrapTooltip
                                  title='Activar/Desactivar, Cuenta'
                                  arrow
                                  followCursor
                                  placement='top'
                                >
                                  {item.activo ? (
                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                      <button
                                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                        onClick={() => {
                                          offCuenta(item.usuario)
                                        }}
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/general/gen051.svg'
                                          className='svg-icon-3'
                                        />
                                      </button>
                                    </div>
                                  ) : (
                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                      <button
                                        className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                                        onClick={() => {
                                          offCuenta(item.usuario)
                                        }}
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/general/gen051.svg'
                                          className='svg-icon-3'
                                        />
                                      </button>
                                    </div>
                                  )}
                                </BootstrapTooltip>

                                <BootstrapTooltip
                                  title='Resetear Contraseña'
                                  arrow
                                  followCursor
                                  placement='top'
                                >
                                  <div className='d-flex justify-content-end flex-shrink-0'>
                                    <button
                                      className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1'
                                      onClick={() => {
                                        resetPsw(item.usuario)
                                      }}
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/coding/cod004.svg'
                                        className='svg-icon-3'
                                      />
                                    </button>
                                  </div>
                                </BootstrapTooltip>
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            ) : (
              <tbody></tbody>
            )}
            {/* end::Table body */}
          </table>
          {!filtro && selecUs === '' && (
            <ul className='pagination'>
              {currentPage === 0 ? (
                <li className='page-item disabled'>
                  <a type='button' className='btn btn-sm btn-light-info' onClick={anteriorPag}>
                    Anterior
                  </a>
                </li>
              ) : (
                <li className='page-item '>
                  <a type='button' className='btn btn-sm btn-light-info' onClick={anteriorPag}>
                    Anterior
                  </a>
                </li>
              )}

              <li className='page-item'>
                <a type='button' className='btn btn-sm btn-light-info' onClick={siguientePag}>
                  Siguiente
                </a>
              </li>
            </ul>
          )}

          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default TablaUsuario
