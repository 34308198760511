/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { ListaEventos } from '../../components/ListaEventos'
import { MixedWidget2 } from '../../../_metronic/partials/widgets'
import { Clima } from '../../components/Clima'
import { getClima } from '../../services/gral/clima'
import { useDispatch } from 'react-redux'
import { Box, Typography } from '@mui/material'

type Props = {
  data: any
  icono: string
}

const DashboardPage: React.FC<Props> = ({ data, icono }) => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='d-flex flex-row flex-wrap'>
        <div className='col-md-6 col-sm-12 col-lg-4'>
          <MixedWidget2
            key={'1'}
            className='  mb-xl-8 card-inicio'
            chartColor='secondary'
            chartHeight='200px'
            strokeColor='#cb1e46'
          />
        </div>
        <div className='col-md-6 col-sm-12 col-lg-6 '>
          <ListaEventos className={'mb-xl-8 card-inicio'} chartColor='secondary' />
        </div>
        <div className='col-md-6 col-sm-12 col-lg-2 '>
          <Clima className=' mb-xl-8 card-inicio' data={data} icono={icono} />
        </div>
      </div>
      {/* <div className="text-center col-12">
        <Typography sx={{fontWeight:'bold'}} className='text-center mt-5'>  <span> 2023 © </span> <a rel="stylesheet" href="/*">Dir.Innovación y Tecnología. </a> </Typography> 
      </div> */}
    </div>
  </>
)

const DashboardWrapper: FC = () => {
  const dispatch = useDispatch()
  const [lat, setLat] = useState<string>('-38')
  const [long, setLong] = useState<string>('-68')
  const [data, setData] = useState<any>({})
  const lenguaje = 'es'
  let icono: string = `https://openweathermap.org/img/wn/01d@2x.png`
  useEffect(() => {
    let climaUpdate = true
    const fetchData = async () => {
      navigator.geolocation.getCurrentPosition(function (position: any) {
        position.coords.latitude && setLat(position.coords.latitude)
        position.coords.longitude && setLong(position.coords.longitude)
      })
    }
    
    fetchData()
    return () => {
      climaUpdate = false
    }
  }, [lat, long])
  ////console.log('lat,long', lat, long)

  useEffect(() => {
    let climaUpdate = true
    if (lat && long) {
      getClima(lat, long).then((res: any) => {
        let datos = res.data.data
        datos.main.temp = Math.round(datos.main.temp)
        datos.wind.speed = Math.round(datos.wind.speed * 1.86)
        setData(datos)
        ////console.log('res.data.data', res.data)
        icono = `https://openweathermap.org/img/wn/${datos.weather[0].icon}@2x.png`
        ////console.log('icono', icono)
      })
    }
    return () => {
      climaUpdate = false
    }
  }, [lat, long])
  /*  useEffect(() => {
     buscarEventos().then((datos: any) => {
       // //console.log('datos', datos)
       dispatch(eventoCargarFull(datos))
     })
   }, []) */
  return (
    <>
      <PageTitle breadcrumbs={[]}>MENÚ PRINCIPAL</PageTitle>
      <DashboardPage data={data} icono={icono} />
    </>
  )
}

export { DashboardWrapper }
