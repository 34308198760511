import { TextField } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';
import { Field, useFormik } from 'formik';
import * as yup from 'yup';

interface FormValues {
    usuario: string;
    datosPersonales: {
      sexo: string;
    };
  }

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Enter a valid email'),
    password: yup
      .string()
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
  });
  
  const Step2 = () => {
    const formik = useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        alert(JSON.stringify(values, null, 2));
      },
    });   

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <br />
        <br />
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Domicilio"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <br />
        <br />

        <TextField
          fullWidth
          id="email"
          name="email"
          label="Fecha de nacimiento"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <br />
        <br />

        <TextField
          fullWidth
          id="email"
          name="email"
          label="Nacionalidad"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <br />
        <br />

        <TextField
          fullWidth
          id="email"
          name="email"
          label="Telefono"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <br />
        <br />

        <TextField
          fullWidth
          id="email"
          name="email"
          label="Provincia de origen"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <br />
        <br />

        <TextField
          fullWidth
          id="email"
          name="email"
          label="Institucion que deriva"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <br />
        <br />

        <TextField
          fullWidth
          id="email"
          name="email"
          label="Provincia de origen"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        
      </form>
    </div>
  )
}

export default Step2
