import React, {useEffect, useState} from 'react'
import {ITablaBens} from '../interfaces/ITablaBens'
import {TablaInscripciones} from './TablasBens/ItemMenuInscripciones'
import {TablaDescarga} from './TablasBens/ItemMenuDescargaData'
import {TablaAprobados} from './TablasBens/ItemMenuAprobados'
import {Indicadores} from './Indicadores'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router'

let tablaInicio: ITablaBens = {
  className: 'card-xxl-stretch mb-5 mb-xl-8',
  tituloMenu: 'Administracion de Beng',
  headColumns: {
    col1: '',
    col2: '',
    col3: '',
    col4: '',
  },
}
const TablaDatosBens: React.FC<ITablaBens> = ({className, tituloMenu, tablaDatos, headColumns}) => {
  let location = useLocation()
  const [InfoMenu, setInfoMenu] = useState<ITablaBens>(tablaInicio)

  const infoPrinc: any = useSelector((state: ISTATE) => state.tablaMenuPrincipalBeng)

  useEffect(() => {
    infoPrinc.tablaInicial ? setInfoMenu(tablaInicio) : setInfoMenu(infoPrinc)
  }, [infoPrinc])
  useEffect(() => {
    tituloMenu && console.log('ttttt', tituloMenu)
  }, [tituloMenu])
  return (
    <div>
      {infoPrinc.tituloMenu === 'Administracion de Beng' && (
        <TablaDescarga
          className='card-xxl-stretch mb-5 mb-xl-8'
          tablaDatos={InfoMenu.tablaDatos}
          headColumns={InfoMenu.headColumns}
          tituloMenu={InfoMenu.tituloMenu}
        />
      )}

      {infoPrinc.tituloMenu === 'Descargar de Hacienda' && (
        <TablaDescarga
          className='card-xxl-stretch mb-5 mb-xl-8'
          tablaDatos={InfoMenu.tablaDatos}
          headColumns={InfoMenu.headColumns}
          tituloMenu={InfoMenu.tituloMenu}
        />
      )}
      {infoPrinc.tituloMenu === 'Inscripciones' && (
        <TablaInscripciones
          className='card-xxl-stretch mb-5 mb-xl-8'
          tablaDatos={InfoMenu.tablaDatos}
          headColumns={InfoMenu.headColumns}
          tituloMenu={InfoMenu.tituloMenu}
        />
      )}
      {infoPrinc.tituloMenu === 'Validados' && (
        <TablaAprobados
          className='card-xxl-stretch mb-5 mb-xl-8'
          tablaDatos={InfoMenu.tablaDatos}
          headColumns={InfoMenu.headColumns}
          tituloMenu={InfoMenu.tituloMenu}
        />
      )}
      {infoPrinc.tituloMenu === 'Indicadores' && (
        <Indicadores
          className='card-xxl-stretch mb-5 mb-xl-8'
          tablaDatos={infoPrinc.tablaDatos}
          headColumns={InfoMenu.headColumns}
          tituloMenu={InfoMenu.tituloMenu}
        />
      )}
    </div>
  )
}
export {TablaDatosBens}
