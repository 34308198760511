import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import TablaNoticia from '../components/TablasNoticias/TablaNoticia'
import {Inoticias} from '../interface/Inoticia'
import {getAllNoticias} from '../services/Noticias-Services'

const LevantatutiPage = () => {
  const [noticias, setNoticias] = useState<Inoticias>([])
  const[csv, setCsv]=useState<Inoticias>([])
  const [activePage, setActivePage] = useState(1)
  const rowsPerPage = 5
  const count = noticias.length
  const totalPages = Math.ceil(count / rowsPerPage)
  const calculatedRows = noticias.slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)
  const [busqueda, setBusqueda] = useState('')
  const [tablaNoticias, setTablaNoticias] = useState<Inoticias>([])

  const handleChange = (e: any) => {
    setBusqueda(e.target.value)
    filtrar(e.target.value)
  }
  
  const filtrar = (terminoBusqueda: any) => {
    const resultadoBusqueda = tablaNoticias.filter((posts) => {
      if (posts.titulo.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return posts
      }
    })
    setNoticias(resultadoBusqueda)
  }
  useEffect(() => {
    getAllNoticias().then((res: any) => {
      res.data.ok && setNoticias(res.data.res)
      setCsv(res.data.res)
      setTablaNoticias(res.data.res)
    })

    return () => {}
  }, [])

  return (
    <div>
      <PageTitle breadcrumbs={[]}>Noticias</PageTitle>
      <div className='col-xl-12 col-md-12'>
        <div className='col-xl-12 col-md-12'>        
          <TablaNoticia
            className='mb-5 mb-xl-8'
            csv={csv}
            busqueda={busqueda}
            handleChange={handleChange}
            noticias={calculatedRows}
            activePage={activePage}
            count={count}
            rowsPerPage={rowsPerPage}
            totalPages={totalPages}
            setActivePage={setActivePage}
          />
        </div>
      </div>
    </div>
  )
}

export default LevantatutiPage
