import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
import {getOneEmpresa, saveDocMesxMes} from '../services/EJ.Services'
import {IEmpresa} from '../interfaces/Iempresas'
import Swal from 'sweetalert2'

import {ErrorMessage, Field, Formik, Form} from 'formik'
import {initMesxMes, validationDocSchemaMesxMes} from '../components/Upfiles/UpFilesHelpers'
import Spinner from '../../../../components/spinner/Spinner'
import {postFileMesxMes} from '../services/EJ.Services'
import moment from 'moment'
import {useHistory} from 'react-router'
moment.locale('es')
export const EmpresaMesxMes = () => {
  const history = useHistory()
  const authState: any = useSelector((state: ISTATE) => state.auth)
  let {usuario} = authState

  const [miEmpresa, setMiEmpresa] = useState<IEmpresa>()
  const [mesSelect, setMesSelect] = useState<any>()
  const [mostrarCard, setMostrarCard] = useState<boolean>(false)
  const [tipoUsr, setTipoUsr] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const validarPdf = async (data: any, idInput: string) => {
    if (data) {
      if (data.size > 2000000) {
        await Swal.fire({
          icon: 'info',
          title: 'El tamaño del archivo excede el limite permitido, 2 MB.',
          timer: 3000,
        })
        ;(document.getElementById(idInput) as HTMLInputElement)!.value = ''
        return false
      }
      let ext = data.name.split('.')
      if (ext[1] === 'pdf') {
        return true
      } else {
        await Swal.fire({
          icon: 'info',
          title: 'Solo se aceptan archivos PDF.',
          timer: 3000,
        })
        ;(document.getElementById(idInput) as HTMLInputElement)!.value = ''
        return false
      }
    } else {
      ;(document.getElementById(idInput) as HTMLInputElement)!.value = ''
      return false
    }
  }
  const guardar = (value: any) => {
    setLoading(true)
    let periodo: any = {}
    let documentos: any = []

    postFileMesxMes(value, usuario.usuario)
      .then((res: any) => {
        if (res.data.ok) {
          for (let doc of res.data.fileSaves) {
            let fileName = Object.keys(doc)[0]

            documentos.push({
              tipo_doc: fileName,
              id_db_fileEj: doc[fileName],
              estado: 'pendiente',
              fecha_carga: moment().format('DD/MM/YYYY'),
              fecha_modf: '',
            })
          }
          periodo.anio = value.periodo
          periodo.mes = value.mes
          periodo.documentos = documentos
          saveDocMesxMes(periodo, usuario.usuario)
            .then(async (res) => {
              console.log(res)
              if (res.data.ok) {
                await Swal.fire({
                  icon: 'success',
                  title: 'Documentación cargada con exito.',
                  text: `${res.data.msg}`,
                  timer: 3000,
                })
                setLoading(false)
                history.goBack()
              }
            })
            .catch((err) => console.log(err))
        }
      })
      .catch(async (err) => {
        await Swal.fire({
          icon: 'info',
          title: 'Es necesario cargar al menos un archivo.!',
          timer: 3000,
        })
      })
  }
  const selectMes = (periodo: string) => {
    let meses: any = []
    meses = miEmpresa?.anio?.find((element) => element.anio === periodo)
    setMesSelect(meses)
  }

  useEffect(() => {
    let isMounted = true

    usuario.organizacion.datosOrganismo.nombre === 'empresas nqn' &&
      getOneEmpresa(usuario.usuario).then((res: any) => {
        if (isMounted) {
          res.data.ok && setMiEmpresa(res.data.data)
          setMostrarCard(true)
          setTipoUsr('empresas')
        }
      })

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      <div className='mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <div className='containerTableTitle'>
            <h3 className='headerTableLefet'>
              <span className='card-label fw-bolder fs-3 mb-1'>Documentación a presentar.</span>
            </h3>
          </div>
          <br />
          {miEmpresa && (
            <div id='kt_account_profile_details'>
              <Formik
                initialValues={initMesxMes}
                validationSchema={validationDocSchemaMesxMes}
                onSubmit={guardar}
                validateOnMount={true}
                enableReinitialize
              >
                {(formik) => {
                  //console.log(formik)
                  return (
                    <Form className='form' noValidate>
                      <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                          <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Periodo</span>
                          </label>
                          <div className='col-lg-8 fv-row'>
                            <Field
                              as='select'
                              name='periodo'
                              id='kt_create_account_form_account_type_personal'
                              className='form-select form-select-solid capital'
                            >
                              <option value=''>seleccione una opción</option>
                              {miEmpresa!.anio?.map((anio: any) => {
                                return (
                                  selectMes(formik.values.periodo),
                                  (<option value={anio.anio}>{anio.anio}</option>)
                                )
                              })}
                            </Field>
                            <div className='fv-plugins-message-container invalid-feedback'>
                              <ErrorMessage name='periodo' />
                            </div>
                          </div>
                        </div>
                        {mesSelect && (
                          <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                              <span className='required'>Mes</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                              <Field
                                as='select'
                                name='mes'
                                id='mes'
                                label=''
                                className='form-select form-select-solid capital'
                              >
                                <option value=''>seleccione una opción</option>
                                {mesSelect.meses.map((mes: any) => {
                                  return (
                                    ['pendiente','rechazado'].includes(mes.estado) && (
                                      <option value={mes.mes}>{mes.mes.toUpperCase()}</option>
                                    )
                                  )
                                })}
                              </Field>
                              <div className='fv-plugins-message-container invalid-feedback'>
                                <ErrorMessage name='mes' />
                              </div>
                            </div>
                          </div>
                        )}
                        {formik.values.mes && (
                          <>
                            <div className='row mb-6'>
                              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                Formulario 931, correspondiente al periodo y mes seleccionado.
                              </label>

                              <div className='col-lg-8 fv-row'>
                                <input
                                  accept='.pdf'
                                  type='file'
                                  id='form931'
                                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                  name='form931'
                                  onChange={async (e: any) => {
                                    if (e.target.files) {
                                      ;(await validarPdf(e.target.files[0], 'form931')) &&
                                        formik.setFieldValue('form931', e.target.files[0])
                                    }
                                  }}
                                />
                                <div className='fv-plugins-message-container invalid-feedback'>
                                  <ErrorMessage name='form931' />
                                </div>
                              </div>
                            </div>
                            <div className='row mb-6'>
                              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                Nomina de DDJJ presentadas,correspondiente al periodo y mes
                                seleccionado.
                              </label>

                              <div className='col-lg-8 fv-row'>
                                <input
                                  type='file'
                                  accept='.pdf'
                                  id='nominaDDJJPagoEmp'
                                  name='nominaDDJJPagoEmp'
                                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                  onChange={async (e: any) => {
                                    if (e.target.files) {
                                      ;(await validarPdf(e.target.files[0], 'nominaDDJJPagoEmp')) &&
                                        formik.setFieldValue('nominaDDJJPagoEmp', e.target.files[0])
                                    }
                                  }}
                                />
                                <div className='fv-plugins-message-container invalid-feedback'>
                                  <ErrorMessage name='nominaDDJJPagoEmp' />
                                </div>
                              </div>
                            </div>
                            <div className='row mb-6'>
                              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                VEP, correspondiente al periodo y mes seleccionado.
                              </label>

                              <div className='col-lg-8 fv-row'>
                                <input
                                  accept='.pdf'
                                  type='file'
                                  id='vepPagoElectronico'
                                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                  name='vepPagoElectronico'
                                  onChange={async (e: any) => {
                                    if (e.target.files) {
                                      ;(await validarPdf(
                                        e.target.files[0],
                                        'vepPagoElectronico'
                                      )) &&
                                        formik.setFieldValue(
                                          'vepPagoElectronico',
                                          e.target.files[0]
                                        )
                                    }
                                  }}
                                />
                                <div className='fv-plugins-message-container invalid-feedback'>
                                  <ErrorMessage name='vepPagoElectronico' />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        {loading && <Spinner />}
                        {!loading && formik.isValid && (
                          <button
                            type='button'
                            onClick={() => {
                              guardar(formik.values)
                            }}
                            className='btn btn-lg btn-primary me-3'
                          >
                            Guardar
                          </button>
                        )}
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
