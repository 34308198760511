/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useContext, useEffect, useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import TextErrorFormik from '../../../../../../components/Formik/TextErrorFormik'
import { useDispatch, useSelector } from 'react-redux'
import { ISTATE } from '../../../../../../../setup/redux/interface/reduxState.interface'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import es from 'date-fns/locale/es'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import DatePicker from 'react-datepicker'
import TimePicker from '@mui/lab/TimePicker'
import TextField from '@mui/material/TextField'
import moment from 'moment'
import { evSetDias, evSetFormulario } from '../../../Redux/actions/eventos'
import 'moment/locale/es'
import CustomInput from '../../../components/PickersCustomInput'

moment.locale('es')
type Props = {
  data: any,

}

const initHoraDia = {
  lunIni: '',
  lunFin: '',
  marIni: '',
  marFin: '',
  mieIni: '',
  mieFin: '',
  jueIni: '',
  jueFin: '',
  vieIni: '',
  vieFin: '',
  sabIni: '',
  sabFin: '',
  domIni: '',
  domFin: '',
}
const initHabilitar = {
  lun: false,
  mar: false,
  mie: false,
  jue: false,
  vie: false,
  sab: false,
  dom: false,
}
const initCheck = {
  lun: false,
  mar: false,
  mie: false,
  jue: false,
  vie: false,
  sab: false,
  dom: false,
}

const DuracionEvento: FC<Props> = ({ data }) => {
  ////console.log('data', data)
  const dispatch = useDispatch()
  const eventosStore: any = useSelector((state: ISTATE) => state.eventos)
  const { dias } = eventosStore
  let { acciones, formulario } = eventosStore
  const estado = formulario.estado
  let hoy = new Date()
  let horaHoy = new Date(moment().format())
  let [fechaInicio, setFechaInicio] = useState<any>()
  let [fechaFin, setFechaFin] = useState<any>()
  let [inicio, setInicio] = useState<any>()
  let [fin, setFin] = useState<any>()
  let [horaInicio, setHoraInicio] = useState<any>(hoy)
  let [horaFin, setHoraFin] = useState<any>(hoy)
  let [disBoton, setDisBoton] = useState(true)
  let [duracion, setDuracion] = useState('diario')
  let [dataTemp, setTDataTemp] = useState<any>({
    inicio: '',
    fin: '',
  })
  let [check, setCheck] = useState(true)
  let [disDisFechaFin, setDisFechaFin] = useState(true)
  let [disHoraFin, setDisHoraFin] = useState(true)
  ////console.log('check', check)
  let [mostrar, setMostrar] = useState(false)
  let [checkDia, setCheckDia] = useState(initCheck)
  let [checkHabDia, setCheckHabDia] = useState(initHabilitar)
  let [habilitarFechaFin, setHabilitarFechaFin] = useState(initHabilitar)
  let [horaDia, setHoraDia] = useState<any>(initHoraDia)
  let [listaDias, setListaDias] = useState<any>([])
  let [listaDiasFinal, setListaDiasFinal] = useState<any>([])
  let [selTipo, setSelTipo] = useState<any>(false)
  ////console.log('eventoContext', evento)
  useEffect(() => {
    ////console.log('inicio', moment(evento.start.format('LLL').toString())
    ////console.log('fin', moment(evento.tiempo[evento.tiempo.length - 1].fin))
    setInicio(new Date(formulario.start))
    setFin(new Date(formulario.end))
    setFechaInicio(new Date(formulario.start))
    setFechaFin(new Date(formulario.end))
    if (formulario.horariosDif) { setCheck(true) } else { setCheck(false) }
  }, [])

  useEffect(() => {
    if (formulario.tipo === 'temporal' && acciones.guardar) {
      setDuracion('temporal')
      if (formulario.horariosDif) { setCheck(false) } else { setCheck(true) }
      setMostrar(!mostrar)
      cargarDiasTemp()
    }


  }, [acciones.guardar])


  const cargarDias = () => {
    let temp: any = []
    //console.log('-------------listaDias', listaDias)
    listaDias.map((dia: any) => {
      if (check) {
        dia = {
          ...dia,
          horaInicio: moment(horaInicio).format('HH:mm'),
          horaFin: moment(horaFin).format('HH:mm'),
        }
        {
          dia.dia === 'lunes' && checkDia.lun && temp.push(dia)
        }
        {
          dia.dia === 'martes' && checkDia.mar && temp.push(dia)
        }
        {
          dia.dia === 'miércoles' && checkDia.mie && temp.push(dia)
        }
        {
          dia.dia === 'jueves' && checkDia.jue && temp.push(dia)
        }
        {
          dia.dia === 'viernes' && checkDia.vie && temp.push(dia)
        }
        {
          dia.dia === 'sábado' && checkDia.sab && temp.push(dia)
        }
        {
          dia.dia === 'domingo' && checkDia.dom && temp.push(dia)
        }
      } else {
        if (dia.dia === 'lunes' && checkDia.lun) {
          dia = {
            ...dia,
            horaInicio: moment(horaDia.lunIni).format('HH:mm').toString(),
            horaFin: moment(horaDia.lunFin).format('HH:mm').toString(),
          }
          temp.push(dia)
        }
        if (dia.dia === 'martes' && checkDia.mar) {
          dia = {
            ...dia,
            horaInicio: moment(horaDia.marIni).format('HH:mm').toString(),
            horaFin: moment(horaDia.marFin).format('HH:mm').toString(),
          }
          temp.push(dia)
        }
        if (dia.dia === 'miércoles' && checkDia.mie) {
          dia = {
            ...dia,
            horaInicio: moment(horaDia.mieIni).format('HH:mm').toString(),
            horaFin: moment(horaDia.mieFin).format('HH:mm').toString(),
          }
          temp.push(dia)
        }
        if (dia.dia === 'jueves' && checkDia.jue) {
          dia = {
            ...dia,
            horaInicio: moment(horaDia.jueIni).format('HH:mm').toString(),
            horaFin: moment(horaDia.jueFin).format('HH:mm').toString(),
          }
          temp.push(dia)
        }
        if (dia.dia === 'viernes' && checkDia.vie) {
          dia = {
            ...dia,
            horaInicio: moment(horaDia.vieIni).format('HH:mm').toString(),
            horaFin: moment(horaDia.vieFin).format('HH:mm').toString(),
          }
          temp.push(dia)
        }
        if (dia.dia === 'sábado' && checkDia.sab) {
          dia = {
            ...dia,
            horaInicio: moment(horaDia.sabIni).format('HH:mm').toString(),
            horaFin: moment(horaDia.sabFin).format('HH:mm').toString(),
          }
          temp.push(dia)
        }
        if (dia.dia === 'domingo' && checkDia.dom) {
          dia = {
            ...dia,
            horaInicio: moment(horaDia.domIni).format('HH:mm').toString(),
            horaFin: moment(horaDia.domFin).format('HH:mm').toString(),
          }
          temp.push(dia)
        }
      }
    })
    //console.log('Cargar Dias', temp)
    let evTemp = formulario
    evTemp.tiempo = temp
    //console.log('temp', temp)
    dispatch(evSetFormulario(evTemp))
    setListaDiasFinal(temp)
    return temp
  }

  const cargarValores = (tipoFecha: any, fecha: any) => {

    if (tipoFecha === 'Inicio') {


      let evTemp = formulario
      //console.log('evTemp', evTemp)
      /*    evTemp.start = [fecha] */

      dispatch(evSetFormulario(evTemp))
    }
    if (tipoFecha === 'Fin') {
      const finTemp = {
        ...dataTemp,
        fin: fecha,
      }
      setTDataTemp({
        ...dataTemp,
        fin: fecha,
      })
      let evTemp = formulario
      evTemp.tiempo = [finTemp]
      dispatch(evSetFormulario(evTemp))
    }
    if (tipoFecha === 'temporal') {
      let temp: any = []
      //console.log('Paso por cargar Valores');
      let diasTemp = cargarDias()
      /*   diasTemp = formulario.tiempo */
      //console.log('********** diasTemp', diasTemp)
      for (let d = 0; d < diasTemp.length; d++) {
        const e = diasTemp[d]
        //console.log('e', e)
        let datetimeInicio = new Date(
          moment(e.fecha + ' ' + e.horaInicio, 'DD/MM/YYYY HH:mm').format()
        )
        let datetimeFin = new Date(moment(e.fecha + ' ' + e.horaFin, 'DD/MM/YYYY HH:mm').format())
        temp.push({
          inicio: datetimeInicio,
          fin: datetimeFin,
        })
      }
      //console.log(' +++++++++ temp', temp)
      let evTemp = formulario
      evTemp.tiempo = temp
      dispatch(evSetFormulario(evTemp))
    }
  }

  const limpiarDias = () => {
    horaDia = initHoraDia
    setHoraDia(horaDia)
    setHabilitarFechaFin(initHabilitar)
  }
  const inicializarDias = () => {
    listaDias = []
    let diasDif: any = fechaFin
    diasDif = moment(diasDif)
    diasDif = diasDif.diff(fechaInicio, 'days')
    //console.log('diasDif', diasDif)
    if (diasDif === 0) {
      let temp = {
        fecha: moment(fechaInicio).format('DD/MM/YYYY'),
        dia: moment(fechaInicio).format('dddd'),
        horaInicio: '',
        horaFin: '',
      }
      listaDias.push(temp)
    } else {
      for (let d = 0; d <= diasDif; d++) {
        let temp = {
          fecha: moment(fechaInicio).add(d, 'days').format('DD/MM/YYYY'),
          dia: moment(fechaInicio).add(d, 'days').format('dddd'),
          horaInicio: '',
          horaFin: '',
        }
        listaDias.push(temp)
      }
    }

    if (diasDif > 6) {
      checkHabDia = {
        ...checkHabDia,
        lun: true,
        mar: true,
        mie: true,
        jue: true,
        vie: true,
        sab: true,
        dom: true,
      }
    } else {
      checkHabDia = {
        ...checkHabDia,
        lun: false,
        mar: false,
        mie: false,
        jue: false,
        vie: false,
        sab: false,
        dom: false,
      }
      setCheckHabDia(checkHabDia)
      //console.log('checkHabDia', checkHabDia)
      for (let d = 0; d < diasDif; d++) {
        switch (listaDias[d].dia) {
          case 'lunes':
            checkHabDia = {
              ...checkHabDia,
              lun: true,
            }
            break
          case 'martes':
            checkHabDia = {
              ...checkHabDia,
              mar: true,
            }
            break
          case 'miércoles':
            checkHabDia = {
              ...checkHabDia,
              mie: true,
            }
            break
          case 'jueves':
            checkHabDia = {
              ...checkHabDia,
              jue: true,
            }
            break
          case 'viernes':
            checkHabDia = {
              ...checkHabDia,
              vie: true,
            }
            break
          case 'sábado':
            checkHabDia = {
              ...checkHabDia,
              sab: true,
            }
            break
          case 'domingo':
            checkHabDia = {
              ...checkHabDia,
              dom: true,
            }
            break

          default:
            break
        }
      }
    }

    setListaDias(listaDias)
    setCheckHabDia(checkHabDia)
  }
  const cargarDiasTemp = () => {
    listaDias = []
    let diasDif: any = data.end
    //console.log('diasDif', diasDif)
    diasDif = moment(diasDif)
    diasDif = diasDif.diff(data.start, 'days')
    //console.log('diasDif', diasDif)
    if (diasDif === 0) {
      let temp = {
        fecha: moment(data.start).format('DD/MM/YYYY'),
        dia: moment(data.end).format('dddd'),
        horaInicio: moment(data.start).format('HH:mm'),
        horaFin: moment(data.end).format('HH:mm'),
      }
      listaDias.push(temp)
    } else {
      for (let d = 0; d < data.tiempo.length; d++) {
        //console.log('data.duracion', d, data.duracion)
        let temp = {
          fecha: moment(data.tiempo[d].inicio.toString()).format('DD/MM/YYYY'),
          dia: moment(data.tiempo[d].inicio.toString()).format('dddd'),
          horaInicio: new Date(data.tiempo[d].inicio.toString()),
          horaFin: new Date(data.tiempo[d].fin.toString()),
        }
        listaDias.push(temp)
      }
    }
    //console.log('listaDias', listaDias)
    for (let d = 0; d < listaDias.length; d++) {
      switch (listaDias[d].dia) {
        case 'lunes':
          checkHabDia = {
            ...checkHabDia,
            lun: true,
          }
          break
        case 'martes':
          checkHabDia = {
            ...checkHabDia,
            mar: true,
          }
          break
        case 'miércoles':
          checkHabDia = {
            ...checkHabDia,
            mie: true,
          }
          break
        case 'jueves':
          checkHabDia = {
            ...checkHabDia,
            jue: true,
          }
          break
        case 'viernes':
          checkHabDia = {
            ...checkHabDia,
            vie: true,
          }
          break
        case 'sábado':
          checkHabDia = {
            ...checkHabDia,
            sab: true,
          }
          break
        case 'domingo':
          checkHabDia = {
            ...checkHabDia,
            dom: true,
          }
          break

        default:
          break
      }
    }

    for (let d = 0; d < listaDias.length; d++) {
      switch (listaDias[d].dia) {
        case 'lunes':
          horaDia = {
            ...horaDia,
            lunIni: listaDias[d].horaInicio,
            lunFin: listaDias[d].horaFin,
          }
          break
        case 'martes':
          horaDia = {
            ...horaDia,
            marIni: listaDias[d].horaInicio,
            marFin: listaDias[d].horaFin,
          }
          break
        case 'miércoles':
          horaDia = {
            ...horaDia,
            mieIni: listaDias[d].horaInicio,
            mieFin: listaDias[d].horaFin,
          }
          break
        case 'jueves':
          horaDia = {
            ...horaDia,
            jueIni: listaDias[d].horaInicio,
            jueFin: listaDias[d].horaFin,
          }
          break
        case 'viernes':
          horaDia = {
            ...horaDia,
            vieIni: listaDias[d].horaInicio,
            vieFin: listaDias[d].horaFin,
          }
          break
        case 'sábado':
          horaDia = {
            ...horaDia,
            sabIni: listaDias[d].horaInicio,
            sabFin: listaDias[d].horaFin,
          }
          break
        case 'domingo':
          horaDia = {
            ...horaDia,
            domIni: listaDias[d].horaInicio,
            domFin: listaDias[d].horaFin,
          }
          break

        default:
          break
      }
    }
    ////console.log('checkHabDia', checkHabDia)
    setHoraDia(horaDia)
    setListaDias(listaDias)
    setCheckHabDia(checkHabDia)
    setCheckDia(checkHabDia)
    dispatch(evSetDias(checkHabDia))
  }

  useEffect(() => {

    if (acciones.guardar && selTipo) {

      fechaInicio = new Date()
      formulario.tipo === 'temporal' && (fechaFin = '')
      formulario.tipo === 'temporal' && (fechaInicio = '')
      if (formulario.horariosDif) { setCheck(false) } else { setCheck(true) }
      setFechaInicio(fechaInicio)
      setFechaFin(fechaInicio)
    }
  }, [formulario.tipo])

  useEffect(() => {
    if (acciones.guardar) {
      //console.log('fechaInicio', fechaInicio)
      /* setFechaFin(fechaInicio) */
      inicializarDias()
      limpiarDias()
    }
  }, [fechaInicio])

  useEffect(() => {
    if (acciones.guardar) {

      //console.log(' ------> fechaFin', fechaFin)
      //console.log(' ------> duracion', duracion)
      if (duracion === 'temporal') {
        //console.log('Entro a Limpiar los dias')
        inicializarDias()
        limpiarDias()
        //console.log('Paso por fechaFin')
        cargarDias()
      }
    }
  }, [fechaFin])

  useEffect(() => {
    setCheck(check)
  }, [check])

  useEffect(() => {
    /*  if (acciones.guardar) {
       cargarDias()
     } */
  }, [checkDia])
  useEffect(() => {
    if (acciones.guardar) {
      cargarValores('temporal', '')
      //console.log('Paso por horafin')
    }
  }, [horaFin])

  useEffect(() => {
    if (acciones.guardar) {
      if (duracion === 'temporal') {
        let diasDif: any = fechaFin
        diasDif = moment(diasDif)
        diasDif = diasDif.diff(fechaInicio, 'days')
        if (diasDif >= 6) {
          checkHabDia = {
            ...checkHabDia,
            lun: true,
            mar: true,
            mie: true,
            jue: true,
            vie: true,
            sab: true,
            dom: true,
          }
        } else {
          for (let d = 0; d <= diasDif; d++) {
            switch (listaDias[d].dia) {
              case 'lunes':
                checkHabDia = {
                  ...checkHabDia,
                  lun: true,
                }
                break
              case 'martes':
                checkHabDia = {
                  ...checkHabDia,
                  mar: true,
                }
                break
              case 'miércoles':
                checkHabDia = {
                  ...checkHabDia,
                  mie: true,
                }
                break
              case 'jueves':
                checkHabDia = {
                  ...checkHabDia,
                  jue: true,
                }
                break
              case 'viernes':
                checkHabDia = {
                  ...checkHabDia,
                  vie: true,
                }
                break
              case 'sábado':
                checkHabDia = {
                  ...checkHabDia,
                  sab: true,
                }
                break
              case 'domingo':
                checkHabDia = {
                  ...checkHabDia,
                  dom: true,
                }
                break

              default:
                break
            }
          }
        }

        setCheckHabDia(checkHabDia)
        //console.log('Paso por fechaFin');

        cargarDias()
      } else {
        cargarValores('temporal', '')
      }
    }
  }, [fechaFin])

  useEffect(() => {
    if (acciones.guardar) {
      setHoraDia(horaDia)
      //console.log('Paso por horaDia');
      cargarDias()
      cargarValores('temporal', '')
    }
  }, [horaDia])

  useEffect(() => {
    if (acciones.guardar) {
      dispatch(evSetDias(checkDia))
      cargarValores('temporal', '')
    }
  }, [checkDia])


  return (
    <>
      {((acciones.editar || estado === 'cancelado' || estado === 'realizado' || estado === 'en curso' || estado === 'planificado') && !acciones.guardar) && (
        <>
          <div className='w-100'>
            <div className='pb-2 pb-lg-5'>
              <h3 className='fw-bolder text-dark'>Duración del Evento</h3>
            </div>

            <div className='mb-5 fv-row'>
              {data.duracion === 'diario' && (
                <div className='row mb-4' data-kt-buttons='true'>
                  <div className='col-4'>
                    <div className='mb-4 fv-row'>
                      <label className='form-label mb-3'>Tipo de Duracion</label>
                      <Field
                        type='text'
                        className='form-control form-control-sm form-control-solid uppercase'
                        name='tipo'
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='col-4'>
                    <div>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        Fecha y Hora de Inicio
                      </label>
                      <Field
                        type='text'
                        className='form-control form-control-sm form-control-solid uppercase'

                        value={moment(inicio).format('DD/MM/YYYY HH:mm')}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='col-4'>
                    <div>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        Fecha y Hora de Finalizacion
                      </label>
                      <Field
                        type='text'
                        className='form-control form-control-sm form-control-solid uppercase'

                        value={moment(fin).format('DD/MM/YYYY HH:mm')}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              {data.duracion === 'varios' && (
                <div className='row mb-4' data-kt-buttons='true'>
                  <div className='col-4'>
                    <div className='mb-4 fv-row'>
                      <label className='form-label mb-3'>Tipo de Duracion</label>
                      <Field
                        type='text'
                        className='form-control form-control-sm form-control-solid uppercase'
                        name='tipo'
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='col-4'>
                    <div>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        Fecha y Hora de Inicio
                      </label>
                      <Field
                        type='text'
                        className='form-control form-control-sm form-control-solid uppercase'
                        name='inicio'
                        value={moment(inicio).format('DD/MM/YYYY HH:mm')}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='col-4'>
                    <div>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        Fecha y Hora de Inicio
                      </label>
                      <Field
                        type='text'
                        className='form-control form-control-sm form-control-solid uppercase'
                        name='fin'
                        value={moment(fin).format('DD/MM/YYYY HH:mm')}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              {data.duracion === 'temporal' && (
                <div className='row mb-4' data-kt-buttons='true'>
                  <div className='col-4'>
                    <div className='mb-4 fv-row'>
                      <label className='form-label mb-3'>Tipo de Duracion</label>
                      <Field
                        type='text'
                        className='form-control form-control-sm form-control-solid uppercase'
                        name='tipo'
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='col-4'>
                    <div>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        Fecha y Hora de Inicio
                      </label>
                      <Field
                        type='text'
                        className='form-control form-control-sm form-control-solid uppercase'
                        name='inicio'
                        value={moment(inicio).format('DD/MM/YYYY HH:mm')}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='col-4'>
                    <div>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        Fecha y Hora de Inicio
                      </label>
                      <Field
                        type='text'
                        className='form-control form-control-sm form-control-solid uppercase'
                        name='fin'
                        value={moment(fin).format('DD/MM/YYYY HH:mm')}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {acciones.guardar && (
        <div className='w-100'>
          <div className='pb-2 pb-lg-5'>
            <h3 className='fw-bolder text-dark'>Duración del Evento</h3>
          </div>
          <div className='mb-5 fv-row'>
            <div className='row mb-4' data-kt-buttons='true'>
              <div className='col'>
                <Field
                  type='radio'
                  name='duracion'
                  checked={data.duracion === 'diario'}
                  className='btn-check'
                  value='diario'
                  id='duracion_select_1'
                  onClick={() => {
                    setDuracion('diario')
                    setMostrar(false)
                    setSelTipo(true)
                    setCheck(true)
                    setCheckHabDia(initCheck)
                    setCheckDia(initCheck)
                  }}
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
                  htmlFor='duracion_select_1'
                >
                  <span className='fw-bolder fs-6'>Solo un día</span>
                </label>
              </div>

              <div className='col'>
                <Field
                  type='radio'
                  className='btn-check'
                  checked={data.duracion === 'varios'}
                  name='duracion'
                  value='varios'
                  id='duracion_select_2'
                  onClick={() => {
                    setDuracion('varios')
                    setSelTipo(true)
                    setMostrar(false)
                    setCheckHabDia(initCheck)
                    setCheckDia(initCheck)
                  }}
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
                  htmlFor='duracion_select_2'
                >
                  <span className='fw-bolder fs-6'>Varios días</span>
                </label>
              </div>

              {/* <div className='col'>
                <Field
                  type='radio'
                  className='btn-check'
                  name='duracion'
                  checked={data.duracion === 'temporal'}
                  value='temporal'
                  id='duracion_select_3'
                  onClick={() => {
                    setSelTipo(true)
                    setCheck(true)
                    setCheckHabDia(initCheck)
                    setCheckDia(initCheck)
                    setFechaInicio(horaHoy)
                    setFechaFin(horaHoy)
                    setDuracion('temporal')
                  }}
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
                  htmlFor='duracion_select_3'
                >
                  <span className='fw-bolder fs-6'>Temporal en varios días</span>
                </label>
              </div>
              <ErrorMessage name='tipo' component={TextErrorFormik} /> */}
            </div>
            {data.duracion === 'diario' ? (
              <div className='row mb-4 pt-3' data-kt-buttons='true'>
                <div className='col-3'>
                  <Box>
                    <DatePicker
                      locale={es}
                      showTimeSelect
                      timeFormat='HH:mm'
                      timeIntervals={15}
                      minDate={new Date()}
                      minTime={moment(fechaInicio).format("dd/MM/yy") === moment(new Date()).format("dd/MM/yy") ? fechaInicio : new Date().setHours(0, 0, 0, 0)}
                      maxTime={new Date(moment(fechaInicio).endOf('day').toString())}
                      selected={fechaInicio}
                      id='date-time-picker-inicio'
                      timeCaption='Hora'
                      dateFormat='dd/MM/yyyy HH:mm aa'
                      name='tiempo.inicio'
                      onChange={(date: any) => {
                        setFechaInicio(date)
                        setDisBoton(false)
                        cargarValores('Inicio', date)
                      }}
                      customInput={<CustomInput label='Fecha y Hora de Inicio' />}
                    />
                  </Box>

                  <ErrorMessage name={'tiempo.inicio'} component={TextErrorFormik} />
                </div>
                <div className='col-3'>
                  <Box>
                    <DatePicker
                      locale={es}
                      showTimeSelect
                      timeFormat='HH:mm'
                      timeIntervals={15}
                      minDate={fechaInicio}
                      maxDate={fechaInicio}
                      minTime={fechaInicio}
                      maxTime={new Date(moment(fechaInicio).endOf('day').toString())}
                      selected={fechaFin}
                      id='date-time-picker-fin'
                      name='tiempo.fin'
                      timeCaption='Hora'
                      dateFormat='dd/MM/yyyy HH:mm aa'
                      onChange={(date: any) => {
                        setFechaFin(date)
                        cargarValores('Fin', date)
                      }}
                      disabled={disBoton}
                      customInput={<CustomInput label='Fecha y Hora de Finalización' />}
                    />
                  </Box>
                  <ErrorMessage name={'tiempo.fin'} component={TextErrorFormik} />
                </div>
              </div>
            ) : (
              <div className=''></div>
            )}
            {data.duracion === 'varios' ? (
              <div className='row mb-4 pt-3' data-kt-buttons='true'>
                <div className='col-3'>
                  <Box>
                    <DatePicker
                      locale={es}
                      showTimeSelect
                      timeFormat='HH:mm'
                      timeIntervals={15}
                      minDate={new Date()}
                      minTime={moment(fechaInicio).format("dd/MM/yy") === moment(new Date()).format("dd/MM/yy") ? fechaInicio : new Date().setHours(0, 0, 0, 0)}
                      maxTime={new Date(moment(fechaInicio).endOf('day').toString())}
                      selected={fechaInicio}
                      id='date-time-picker-inicio'
                      timeCaption='Hora'
                      dateFormat='dd/MM/yyyy HH:mm aa'
                      name='tiempo.inicio'
                      onChange={(date: any) => {
                        setFechaInicio(date)
                        setDisBoton(false)
                        cargarValores('Inicio', date)
                      }}
                      customInput={<CustomInput label='Fecha y Hora de Inicio' />}
                    />
                  </Box>
                  <ErrorMessage name={'tiempo.inicio'} component={TextErrorFormik} />
                </div>
                <div className='col-3'>
                  <Box>
                    <DatePicker
                      locale={es}
                      showTimeSelect
                      timeFormat='HH:mm'
                      timeIntervals={15}
                      minDate={new Date(moment(fechaInicio, 'DD-MM-YYYY').add(1, 'days').toString())}
                      selected={fechaFin}
                      id='date-time-picker-inicio'
                      timeCaption='Hora'
                      dateFormat='dd/MM/yyyy HH:mm aa'
                      name='tiempo.inicio'
                      onChange={(date: any) => {
                        setFechaFin(date)
                        cargarValores('Fin', date)
                      }}
                      customInput={<CustomInput label='Fecha y Hora de Finalización' />}
                    />
                  </Box>
                  <ErrorMessage name={'tiempo.inicio'} component={TextErrorFormik} />
                </div>
              </div>
            ) : (
              <div className=''></div>
            )}
            {data.duracion === 'temporal' ? (
              {/* <>
                <div className='row mb-4 pt-3' data-kt-buttons='true'>
                  <div className='col-3'>
                    <Box>
                      <DatePicker
                        locale={es}
                        minDate={new Date()}
                        selected={fechaInicio}
                        id='date-time-picker-inicio'
                        timeCaption='Hora'
                        dateFormat='dd/MM/yyyy'
                        name='tiempo.inicio'
                        value={fechaInicio}
                        onChange={(date: any) => {
                          setFechaInicio(date)
                          setFechaFin(date)
                          setDisFechaFin(false) 
                          setMostrar(true)
                        }}
                        customInput={<CustomInput label='Fecha de Inicio' />}
                      />
                    </Box>
                    <ErrorMessage name={'tiempo.inicio'} component={TextErrorFormik} />
                  </div>
                  <div className='col-3'>
                    <Box>
                      <DatePicker
                        locale={es}
                        minDate={new Date(moment(fechaInicio, 'DD-MM-YYYY').add(1, 'days').toString())}
                        selected={fechaFin}
                        id='date-time-picker-fin'
                        dateFormat='dd/MM/yyyy'
                        name='tiempo.fin'
                        value={fechaFin}
                        onChange={(date: any) => {
                          setFechaFin(date)
                          cargarValores('Temporal', date)
                        }}
                        disabled={disDisFechaFin}
                        customInput={<CustomInput label='Fecha de Finalizacion' />}
                      />
                    </Box>
                    <ErrorMessage name={'tiempo.fin'} component={TextErrorFormik} />
                  </div>
                  <div className=' col-5 mb-2 d-flex align-items-center'>
                    <label className='col col-form-label fw-bold fs-6'>
                      Los horarios son los mismos para todos los dias?
                    </label>
                    <label className='form-check form-switch form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        name='check'
                        type='checkbox'
                        defaultChecked={check}
                        onChange={() => {
                          check = !check
                          let evTemp = formulario
                          if (check) {
                            evTemp.horariosDif = false
                            dispatch(evSetFormulario(evTemp))
                          } else {
                            evTemp.horariosDif = true
                            dispatch(evSetFormulario(evTemp))
                          }
                          setCheck(check)
                          dispatch(evSetFormulario(evTemp))
                          check && limpiarDias()
                        }}
                      />
                      <span className='form-check-label fw-bold text-gray-400'>
                        {check ? 'Si' : 'No'}
                      </span>
                    </label>
                  </div>
                </div>
                {check && (
                  <div className='row' data-kt-buttons='true'>
                    <div className='col-3'>

                      <Box>
                        <DatePicker
                          locale={es}
                          showTimeSelect
                          selected={horaInicio}
                          timeIntervals={15}
                          showTimeSelectOnly
                          dateFormat='h:mm aa'
                          id='time-only-picker'
                          name='honaInicio'
                          timeCaption='Hora'
                          onChange={(date: any) => {
                            //console.log('date', date)
                            setHoraInicio(new Date(date))
                            setDisHoraFin(false)
                            cargarValores('Temporal', date)
                          }}
                          customInput={<CustomInput label='Hora de Inicio' />}
                        />
                      </Box>
                    </div>
                    <div className='col-3'>
                      <Box>
                        <DatePicker
                          locale={es}
                          showTimeSelect
                          selected={horaFin}
                          timeIntervals={15}
                          minTime={horaInicio}
                          maxTime={new Date(moment(horaInicio).endOf('day').toString())}
                          showTimeSelectOnly
                          dateFormat='h:mm aa'
                          name='horaFin'
                          id='time-only-picker'
                          timeCaption='Hora'
                          onChange={(date: any) => {
                            //console.log('date', date)
                            setHoraFin(date)
                            cargarValores('Temporal', date)
                          }}
                          disabled={disHoraFin}
                          customInput={<CustomInput label='Hora de Finalización' />}
                        />
                      </Box>

                    </div>
                  </div>
                )}
                {mostrar && (
                  <div className='row mb-4' data-kt-buttons='true'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Selecciones los dias</label>
                    <div className='row mb-6'>
                      {checkHabDia.lun && (
                        <div className='fv-col'>
                          <div className='d-flex align-items-center mt-3'>
                            <div className='col-2'>
                              <label className='form-check form-switch form-check-custom form-check-solid pr-4'>
                                <input
                                  className='form-check-input'
                                  name='communication[]'
                                  type='checkbox'
                                  defaultChecked={checkDia.lun}
                                  onChange={() => {
                                    checkDia = {
                                      ...checkDia,
                                      lun: !checkDia.lun,
                                    }
                                    setCheckDia(checkDia)
                                    dispatch(evSetDias(checkDia))
                                  }}
                                />
                                <span className='fw-bold ps-2 fs-6'>Lunes</span>
                              </label>
                            </div>
                            {checkDia.lun && !check && (
                              <>
                                <div className='col-2'>
                                  <Box>
                                    <DatePicker
                                      locale={es}
                                      showTimeSelect
                                      selected={horaDia.lunIni !== '' ? horaDia.lunIni : horaHoy}
                                      timeIntervals={15}
                                      showTimeSelectOnly
                                      dateFormat='h:mm aa'
                                      name='horaIniLun'
                                      id='time-only-picker'
                                      timeCaption='Hora'
                                      value={horaDia.lunIni !== '' ? horaDia.lunIni : horaHoy}
                                      onChange={(hora: any) => {
                                        horaDia = {
                                          ...horaDia,
                                          lunIni: hora,
                                        }
                                        setHoraDia(horaDia)
                                        !habilitarFechaFin.lun &&
                                          (habilitarFechaFin = {
                                            ...habilitarFechaFin,
                                            lun: !habilitarFechaFin.lun,
                                          })
                                        setHabilitarFechaFin(habilitarFechaFin)
                                        cargarValores('temporal', hora)
                                      }}
                                      customInput={<CustomInput label='Hora de Inicio' />}
                                    />
                                  </Box>
                                </div>
                                <div className='col-2 pl-3'>
                                  <Box>
                                    <DatePicker
                                      locale={es}
                                      showTimeSelect
                                      selected={horaDia.lunFin !== '' ? horaDia.lunFin : horaHoy}
                                      timeIntervals={15}
                                      showTimeSelectOnly
                                      dateFormat='h:mm aa'
                                      name='horaFinLun'
                                      id='time-only-picker'
                                      timeCaption='Hora'
                                      minTime={horaDia.lunIni}
                                      maxTime={new Date(moment(hoy).endOf('day').toString())}
                                      value={horaDia.lunFin !== '' ? horaDia.lunFin : horaHoy}
                                      onChange={(hora: any) => {
                                        horaDia = {
                                          ...horaDia,
                                          lunFin: hora,
                                        }
                                        setHoraDia(horaDia)
                                        cargarValores('temporal', hora)
                                      }}
                                      disabled={!habilitarFechaFin.lun}
                                      customInput={<CustomInput label='Hora de Finalizacion' />}
                                    />
                                  </Box>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {checkHabDia.mar && (
                        <div className='fv-col'>
                          <div className='d-flex align-items-center mt-3'>
                            <div className='col-2'>
                              <label className='form-check form-switch form-check-custom form-check-solid pr-4'>
                                <input
                                  className='form-check-input'
                                  name='communication[]'
                                  type='checkbox'
                                  defaultChecked={checkDia.mar}
                                  onChange={() => {
                                    checkDia = {
                                      ...checkDia,
                                      mar: !checkDia.mar,
                                    }
                                    setCheckDia(checkDia)
                                    dispatch(evSetDias(checkDia))
                                  }}
                                />
                                <span className='fw-bold ps-2 fs-6'>Martes</span>
                              </label>
                            </div>
                            {checkDia.mar && !check && (
                              <>
                                <div className='col-2'>
                                  <Box>
                                    <DatePicker
                                      locale={es}
                                      showTimeSelect
                                      selected={horaDia.marIni !== '' ? horaDia.marIni : horaHoy}
                                      timeIntervals={15}
                                      showTimeSelectOnly
                                      dateFormat='h:mm aa'
                                      name='horaInimar'
                                      id='time-only-picker'
                                      timeCaption='Hora'
                                      value={horaDia.marIni !== '' ? horaDia.marIni : horaHoy}
                                      onChange={(hora: any) => {
                                        horaDia = {
                                          ...horaDia,
                                          marIni: hora,
                                        }
                                        setHoraDia(horaDia)
                                        !habilitarFechaFin.mar &&
                                          (habilitarFechaFin = {
                                            ...habilitarFechaFin,
                                            mar: !habilitarFechaFin.mar,
                                          })
                                        setHabilitarFechaFin(habilitarFechaFin)
                                        cargarValores('temporal', hora)
                                      }}
                                      customInput={<CustomInput label='Hora de Inicio' />}
                                    />
                                  </Box>
                                </div>
                                <div className='col-2 pl-3'>
                                  <Box>
                                    <DatePicker
                                      locale={es}
                                      showTimeSelect
                                      selected={horaDia.marFin !== '' ? horaDia.marFin : horaHoy}
                                      timeIntervals={15}
                                      showTimeSelectOnly
                                      dateFormat='h:mm aa'
                                      name='horaFinmar'
                                      id='time-only-picker'
                                      timeCaption='Hora'
                                      minTime={horaDia.marIni}
                                      maxTime={new Date(moment(hoy).endOf('day').toString())}
                                      value={horaDia.marFin !== '' ? horaDia.marFin : horaHoy}
                                      onChange={(hora: any) => {
                                        horaDia = {
                                          ...horaDia,
                                          marFin: hora,
                                        }
                                        setHoraDia(horaDia)
                                        cargarValores('temporal', hora)
                                      }}
                                      disabled={!habilitarFechaFin.mar}
                                      customInput={<CustomInput label='Hora de Finalizacion' />}
                                    />
                                  </Box>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {checkHabDia.mie && (
                        <div className='fv-col'>
                          <div className='d-flex align-items-center mt-3'>
                            <div className='col-2'>
                              <label className='form-check form-switch form-check-custom form-check-solid pr-4'>
                                <input
                                  className='form-check-input'
                                  name='communication[]'
                                  type='checkbox'
                                  defaultChecked={checkDia.mie}
                                  onChange={() => {
                                    checkDia = {
                                      ...checkDia,
                                      mie: !checkDia.mie,
                                    }
                                    setCheckDia(checkDia)
                                    dispatch(evSetDias(checkDia))
                                  }}
                                />
                                <span className='fw-bold ps-2 fs-6'>Miércoles</span>
                              </label>
                            </div>
                            {checkDia.mie && !check && (
                              <>
                                <div className='col-2'>
                                  <Box>
                                    <DatePicker
                                      locale={es}
                                      showTimeSelect
                                      selected={horaDia.mieIni !== '' ? horaDia.mieIni : horaHoy}
                                      timeIntervals={15}
                                      showTimeSelectOnly
                                      dateFormat='h:mm aa'
                                      name='horaInimie'
                                      id='time-only-picker'
                                      timeCaption='Hora'
                                      value={horaDia.mieIni !== '' ? horaDia.mieIni : horaHoy}
                                      onChange={(hora: any) => {
                                        horaDia = {
                                          ...horaDia,
                                          mieIni: hora,
                                        }
                                        setHoraDia(horaDia)
                                        !habilitarFechaFin.mie &&
                                          (habilitarFechaFin = {
                                            ...habilitarFechaFin,
                                            mie: !habilitarFechaFin.mie,
                                          })
                                        setHabilitarFechaFin(habilitarFechaFin)
                                        cargarValores('temporal', hora)
                                      }}
                                      customInput={<CustomInput label='Hora de Inicio' />}
                                    />
                                  </Box>
                                </div>
                                <div className='col-2 pl-3'>
                                  <Box>
                                    <DatePicker
                                      locale={es}
                                      showTimeSelect
                                      selected={horaDia.mieFin !== '' ? horaDia.mieFin : horaHoy}
                                      timeIntervals={15}
                                      showTimeSelectOnly
                                      dateFormat='h:mm aa'
                                      name='horaFinmie'
                                      id='time-only-picker'
                                      timeCaption='Hora'
                                      minTime={horaDia.mieIni}
                                      maxTime={new Date(moment(hoy).endOf('day').toString())}
                                      value={horaDia.mieFin !== '' ? horaDia.mieFin : horaHoy}
                                      onChange={(hora: any) => {
                                        horaDia = {
                                          ...horaDia,
                                          mieFin: hora,
                                        }
                                        setHoraDia(horaDia)
                                        cargarValores('temporal', hora)
                                      }}
                                      disabled={!habilitarFechaFin.mie}
                                      customInput={<CustomInput label='Hora de Finalizacion' />}
                                    />
                                  </Box>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {checkHabDia.jue && (
                        <div className='fv-col'>
                          <div className='d-flex align-items-center mt-3'>
                            <div className='col-2'>
                              <label className='form-check form-switch form-check-custom form-check-solid pr-4'>
                                <input
                                  className='form-check-input'
                                  name='communication[]'
                                  type='checkbox'
                                  defaultChecked={checkDia.jue}
                                  onChange={() => {
                                    checkDia = {
                                      ...checkDia,
                                      jue: !checkDia.jue,
                                    }
                                    setCheckDia(checkDia)
                                    dispatch(evSetDias(checkDia))
                                  }}
                                />
                                <span className='fw-bold ps-2 fs-6'>Jueves</span>
                              </label>
                            </div>
                            {checkDia.jue && !check && (
                              <>
                                <div className='col-2'>
                                  <Box>
                                    <DatePicker
                                      locale={es}
                                      showTimeSelect
                                      selected={horaDia.jueIni !== '' ? horaDia.jueIni : horaHoy}
                                      timeIntervals={15}
                                      showTimeSelectOnly
                                      dateFormat='h:mm aa'
                                      name='horaInijue'
                                      id='time-only-picker'
                                      timeCaption='Hora'
                                      value={horaDia.jueIni !== '' ? horaDia.jueIni : horaHoy}
                                      onChange={(hora: any) => {
                                        horaDia = {
                                          ...horaDia,
                                          jueIni: hora,
                                        }
                                        setHoraDia(horaDia)
                                        !habilitarFechaFin.jue &&
                                          (habilitarFechaFin = {
                                            ...habilitarFechaFin,
                                            jue: !habilitarFechaFin.jue,
                                          })
                                        setHabilitarFechaFin(habilitarFechaFin)
                                        cargarValores('temporal', hora)
                                      }}
                                      customInput={<CustomInput label='Hora de Inicio' />}
                                    />
                                  </Box>
                                </div>
                                <div className='col-2 pl-3'>
                                  <Box>
                                    <DatePicker
                                      locale={es}
                                      showTimeSelect
                                      selected={horaDia.jueFin !== '' ? horaDia.jueFin : horaHoy}
                                      timeIntervals={15}
                                      showTimeSelectOnly
                                      dateFormat='h:mm aa'
                                      name='horaFinjue'
                                      id='time-only-picker'
                                      timeCaption='Hora'
                                      minTime={horaDia.jueIni}
                                      maxTime={new Date(moment(hoy).endOf('day').toString())}
                                      value={horaDia.jueFin !== '' ? horaDia.jueFin : horaHoy}
                                      onChange={(hora: any) => {
                                        horaDia = {
                                          ...horaDia,
                                          jueFin: hora,
                                        }
                                        setHoraDia(horaDia)
                                        cargarValores('temporal', hora)
                                      }}
                                      disabled={!habilitarFechaFin.jue}
                                      customInput={<CustomInput label='Hora de Finalizacion' />}
                                    />
                                  </Box>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {checkHabDia.vie && (
                        <div className='fv-col'>
                          <div className='d-flex align-items-center mt-3'>
                            <div className='col-2'>
                              <label className='form-check form-switch form-check-custom form-check-solid pr-4'>
                                <input
                                  className='form-check-input'
                                  name='communication[]'
                                  type='checkbox'
                                  defaultChecked={checkDia.vie}
                                  onChange={() => {
                                    checkDia = {
                                      ...checkDia,
                                      vie: !checkDia.vie,
                                    }
                                    setCheckDia(checkDia)
                                    dispatch(evSetDias(checkDia))
                                  }}
                                />
                                <span className='fw-bold ps-2 fs-6'>Viernes</span>
                              </label>
                            </div>
                            {checkDia.vie && !check && (
                              <>
                                <div className='col-2'>
                                  <Box>
                                    <DatePicker
                                      locale={es}
                                      showTimeSelect
                                      selected={horaDia.vieIni !== '' ? horaDia.vieIni : horaHoy}
                                      timeIntervals={15}
                                      showTimeSelectOnly
                                      dateFormat='h:mm aa'
                                      name='horaInivie'
                                      id='time-only-picker'
                                      timeCaption='Hora'
                                      value={horaDia.vieIni !== '' ? horaDia.vieIni : horaHoy}
                                      onChange={(hora: any) => {
                                        horaDia = {
                                          ...horaDia,
                                          vieIni: hora,
                                        }
                                        setHoraDia(horaDia)
                                        !habilitarFechaFin.vie &&
                                          (habilitarFechaFin = {
                                            ...habilitarFechaFin,
                                            vie: !habilitarFechaFin.vie,
                                          })
                                        setHabilitarFechaFin(habilitarFechaFin)
                                        cargarValores('temporal', hora)
                                      }}
                                      customInput={<CustomInput label='Hora de Inicio' />}
                                    />
                                  </Box>
                                </div>
                                <div className='col-2 pl-3'>
                                  <Box>
                                    <DatePicker
                                      locale={es}
                                      showTimeSelect
                                      selected={horaDia.vieFin !== '' ? horaDia.vieFin : horaHoy}
                                      timeIntervals={15}
                                      showTimeSelectOnly
                                      dateFormat='h:mm aa'
                                      name='horaFinvie'
                                      id='time-only-picker'
                                      timeCaption='Hora'
                                      minTime={horaDia.vieIni}
                                      maxTime={new Date(moment(hoy).endOf('day').toString())}
                                      value={horaDia.vieFin !== '' ? horaDia.vieFin : horaHoy}
                                      onChange={(hora: any) => {
                                        horaDia = {
                                          ...horaDia,
                                          vieFin: hora,
                                        }
                                        setHoraDia(horaDia)
                                        cargarValores('temporal', hora)
                                      }}
                                      disabled={!habilitarFechaFin.vie}
                                      customInput={<CustomInput label='Hora de Finalizacion' />}
                                    />
                                  </Box>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {checkHabDia.sab && (
                        <div className='fv-col'>
                          <div className='d-flex align-items-center mt-3'>
                            <div className='col-2'>
                              <label className='form-check form-switch form-check-custom form-check-solid pr-4'>
                                <input
                                  className='form-check-input'
                                  name='communication[]'
                                  type='checkbox'
                                  defaultChecked={checkDia.sab}
                                  onChange={() => {
                                    checkDia = {
                                      ...checkDia,
                                      sab: !checkDia.sab,
                                    }
                                    setCheckDia(checkDia)
                                    dispatch(evSetDias(checkDia))
                                  }}
                                />
                                <span className='fw-bold ps-2 fs-6'>Sábado</span>
                              </label>
                            </div>
                            {checkDia.sab && !check && (
                              <>
                                <div className='col-2'>
                                  <Box>
                                    <DatePicker
                                      locale={es}
                                      showTimeSelect
                                      selected={horaDia.sabIni !== '' ? horaDia.sabIni : horaHoy}
                                      timeIntervals={15}
                                      showTimeSelectOnly
                                      dateFormat='h:mm aa'
                                      name='horaInisab'
                                      id='time-only-picker'
                                      timeCaption='Hora'
                                      value={horaDia.sabIni !== '' ? horaDia.sabIni : horaHoy}
                                      onChange={(hora: any) => {
                                        horaDia = {
                                          ...horaDia,
                                          sabIni: hora,
                                        }
                                        setHoraDia(horaDia)
                                        !habilitarFechaFin.sab &&
                                          (habilitarFechaFin = {
                                            ...habilitarFechaFin,
                                            sab: !habilitarFechaFin.sab,
                                          })
                                        setHabilitarFechaFin(habilitarFechaFin)
                                        cargarValores('temporal', hora)
                                      }}
                                      customInput={<CustomInput label='Hora de Inicio' />}
                                    />
                                  </Box>
                                </div>
                                <div className='col-2 pl-3'>
                                  <Box>
                                    <DatePicker
                                      locale={es}
                                      showTimeSelect
                                      selected={horaDia.sabFin !== '' ? horaDia.sabFin : horaHoy}
                                      timeIntervals={15}
                                      showTimeSelectOnly
                                      dateFormat='h:mm aa'
                                      name='horaFinsab'
                                      id='time-only-picker'
                                      timeCaption='Hora'
                                      minTime={horaDia.sabIni}
                                      maxTime={new Date(moment(hoy).endOf('day').toString())}
                                      value={horaDia.sabFin !== '' ? horaDia.sabFin : horaHoy}
                                      onChange={(hora: any) => {
                                        horaDia = {
                                          ...horaDia,
                                          sabFin: hora,
                                        }
                                        setHoraDia(horaDia)
                                        cargarValores('temporal', hora)
                                      }}
                                      disabled={!habilitarFechaFin.sab}
                                      customInput={<CustomInput label='Hora de Finalizacion' />}
                                    />
                                  </Box>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {checkHabDia.dom && (
                        <div className='fv-col'>
                          <div className='d-flex align-items-center mt-3'>
                            <div className='col-2'>
                              <label className='form-check form-switch form-check-custom form-check-solid pr-4'>
                                <input
                                  className='form-check-input'
                                  name='communication[]'
                                  type='checkbox'
                                  defaultChecked={checkDia.dom}
                                  onChange={() => {
                                    checkDia = {
                                      ...checkDia,
                                      dom: !checkDia.dom,
                                    }
                                    setCheckDia(checkDia)
                                    dispatch(evSetDias(checkDia))
                                  }}
                                />
                                <span className='fw-bold ps-2 fs-6'>Domingo</span>
                              </label>
                            </div>
                            {checkDia.dom && !check && (
                              <>
                                <div className='col-2'>
                                  <Box>
                                    <DatePicker
                                      locale={es}
                                      showTimeSelect
                                      selected={horaDia.domIni !== '' ? horaDia.domIni : horaHoy}
                                      timeIntervals={15}
                                      showTimeSelectOnly
                                      dateFormat='h:mm aa'
                                      name='horaInidom'
                                      id='time-only-picker'
                                      timeCaption='Hora'
                                      value={horaDia.domIni !== '' ? horaDia.domIni : horaHoy}
                                      onChange={(hora: any) => {
                                        horaDia = {
                                          ...horaDia,
                                          domIni: hora,
                                        }
                                        setHoraDia(horaDia)
                                        !habilitarFechaFin.dom &&
                                          (habilitarFechaFin = {
                                            ...habilitarFechaFin,
                                            dom: !habilitarFechaFin.dom,
                                          })
                                        setHabilitarFechaFin(habilitarFechaFin)
                                        cargarValores('temporal', hora)
                                      }}
                                      customInput={<CustomInput label='Hora de Inicio' />}
                                    />
                                  </Box>
                                </div>
                                <div className='col-2 pl-3'>
                                  <Box>
                                    <DatePicker
                                      locale={es}
                                      showTimeSelect
                                      selected={horaDia.domFin !== '' ? horaDia.domFin : horaHoy}
                                      timeIntervals={15}
                                      showTimeSelectOnly
                                      dateFormat='h:mm aa'
                                      name='horaFindom'
                                      id='time-only-picker'
                                      timeCaption='Hora'
                                      minTime={horaDia.domIni}
                                      maxTime={new Date(moment(hoy).endOf('day').toString())}
                                      value={horaDia.domFin !== '' ? horaDia.domFin : horaHoy}
                                      onChange={(hora: any) => {
                                        horaDia = {
                                          ...horaDia,
                                          domFin: hora,
                                        }
                                        setHoraDia(horaDia)
                                        cargarValores('temporal', hora)
                                      }}
                                      disabled={!habilitarFechaFin.dom}
                                      customInput={<CustomInput label='Hora de Finalizacion' />}
                                    />
                                  </Box>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                
              </> */}
            ) : (
              <div className=''></div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export { DuracionEvento }
