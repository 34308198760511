/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import Evento from '../../../../components/Evento'
import { IEventos } from '../EventoData'

type Props = {
  evento: IEventos
}
const Step5: FC<Props> = ({ evento }) => {
  return (
    <div className='w-100'>
      <div className='card-header align-items-start'>
        <div className='col'>
          <h3 className='fw-bolder text-dark'>Evento Completo</h3>
          <div className='text-gray-400 fw-bold fs-6'>Este es un resumen detallado del evento.</div>
        </div>
        {/*  <BotonImprimir pag_inicio={'/agenda/calendario/miagenda'} nombre='Imprimir Evento' imagen='/arrows/arr078.svg' colorFondo='btn-active-light-danger' /> */}
      </div>
      <Evento evento={evento} />
    </div>
  )
}

export { Step5 }
