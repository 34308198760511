import { useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import Spinner from 'react-bootstrap-v5/lib/esm/Spinner'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { ISTATE } from '../../../../../setup/redux/interface/reduxState.interface'
import { getMovimientosEmpresas, getOneEmprendedor, getOneEmpresa } from '../services/EJ.Services'
import { IEmpresa } from '../interfaces/Iempresas'
import { IEmprendedor } from '../interfaces/Iemprendedor'
import { CardMisCert } from '../components/CardMisCert/CardMisCert'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
import useAreaUser from '../../../../Hooks/useAreaUser'
import clsx from 'clsx'
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap-v5';


const ESTADOS = ['presentado', 'validado', 'aprobado', 'rechazado'];

export const MovimientosMensuales = () => {
  const [loadSpinner, setLoadSpinner] = useState<Boolean>(false)
  const [anioSelected, setAnioSelected] = useState('');

  const [miEmpresa, setMiEmpresa] = useState<IEmpresa>()
  const [empresas, setEmpresas] = useState<any[]>([])
  const [mostrarCard, setMostrarCard] = useState<boolean>(false)
  const [documentos, setDocumentos] = useState<any>()
  const [certCpyme, setCertCpyme] = useState<any>()
  const [miEmprendimiento, setMiEmprendimiento] = useState<IEmprendedor>()

  const [meses, setMeses] = useState<any>()
  const [totalEmpresas, setTotalEmpresas] = useState<number>(0)
  const [selectEmpresas, setSelectEmpresas] = useState([]);
  const [estadoSelected, setEstadoSelected] = useState<string | undefined>('');
  const [cuitSelected, setCuitSelected] = useState('');
  const [page, setPage] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState(5)
  const fecha = new Date()
  const hoy = fecha.toLocaleDateString()

  const siguientePag = () => {
    totalEmpresas > currentPage && setCurrentPage(currentPage + 5)
    totalEmpresas > currentPage && setPage(page + 1)
  }
  const anteriorPag = () => {
    currentPage > 0 && setCurrentPage(currentPage - 5)
    currentPage > 0 && setPage(page - 1)
  }

  const resetPage = () => {
    setCurrentPage(1);
    setPage(1);
  }
  const consultaValidate = Yup.object().shape({
    anio: Yup.string().required('El campo es requerido'),
    mes: Yup.string().required('El campo es requerido'),
  })

  const { usuario, areaUsr } = useAreaUser();

  useEffect(() => {
    let isMounted = true
    usuario.organizacion.datosOrganismo.nombre === 'empresas nqn' &&
      getOneEmpresa(usuario.usuario).then((res: any) => {
        if (isMounted) {
          //console.log(res.data.data)
          res.data.ok && setMiEmpresa(res.data.data)
          setMostrarCard(true)
          //  setTipoUsr('empresas')
        }
      })
    usuario.organizacion.datosOrganismo.nombre === 'emprendedor nqn' &&
      getOneEmprendedor(usuario.usuario).then((res: any) => {
        if (isMounted) {
          res.data.ok && setMiEmprendimiento(res.data.data)
          setMostrarCard(true)
          //setTipoUsr('emprendedores')
        }
      })
    const data = {
      anio: anioSelected || new Date().getFullYear(),
      area: areaUsr,
      cuit: cuitSelected,
      estado: estadoSelected !== '' ? estadoSelected?.toLowerCase() : '',
    }
    getMovimientosEmpresas(data, page).then((res: any) => {
      setEmpresas(res.data.data);
      if (selectEmpresas.length === 0) {
        setSelectEmpresas(res.data.empresasMatch);
      }
      setTotalEmpresas(res.data.totalEmpresas);

    });
    return () => {
      isMounted = false
    }
  }, [page, cuitSelected, estadoSelected]);


  useEffect(() => {
    if (cuitSelected !== '') {
      const data = {
        anio: anioSelected || new Date().getFullYear(),
        area: areaUsr,
        cuit: cuitSelected,
        estado: estadoSelected !== '' ? estadoSelected?.toLowerCase() : '',
      }

      getMovimientosEmpresas(data, page).then((res: any) => {
        if (areaUsr === 'asesores pyme') {
          const empresasFiltered = res.data?.data?.filter((emp: any) => emp.estado === 'validado');
          //const empresasFiltered = res.data?.data?.filter((emp:any) => emp.estado === 'pendiente' || emp.estado === 'presentado');
          setEmpresas(empresasFiltered);
          setTotalEmpresas(empresasFiltered.totalEmpresas);
          resetPage();
        } else {
          setEmpresas(res.data.data);
          setTotalEmpresas(res.data.totalEmpresas);
          resetPage();
        }
      });
    }
  }, [cuitSelected])

  return (
    <>

      <>
        <div className='mb-5 mb-xl-8'>
          <div className='card-header border-0 pt-5'>
            <Formik
              initialValues={{
                anio: '2023',
                mes: '',
                empresas: [],
                estado: '',
              }}
              validationSchema={consultaValidate}
              onSubmit={(values: any) => {
                setLoadSpinner(true)

                setTimeout(() => { }, 2000)
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    <div className='row justify-content-md-center'>
                      <div className='col-2'>
                        <FormGroup>
                          <FormLabel
                            className='form-label fs-6 fw-bolder text-dark'
                            htmlFor='anio'
                          >
                            Año
                          </FormLabel>

                          <Field
                            as='select'
                            id='anio'
                            name='anio'
                            className='form-select form-select-solid capital'
                            onChange={(e: any) => {
                              formik.setFieldValue('anio', e.target.value)
                            }}
                          >
                            <option value=''>Seleccione el periodo</option>
                            {[{ anio: '2023' }, { anio: '2024' }]?.map((item: any) => {
                              return <option value={item.anio}>{item.anio}</option>
                            })}
                            {formik.errors.anio && formik.touched.anio ? (
                              <div>{formik.errors.anio}</div>
                            ) : null}
                          </Field>
                        </FormGroup>
                      </div>
                      <div className='col-3'>
                        <FormGroup>
                          <FormLabel
                            className='form-label fs-6 fw-bolder text-dark'
                            htmlFor='empresa'
                          >
                            Empresa
                          </FormLabel>

                          <Field
                            as='select'
                            id='empresa'
                            name='empresa'
                            className='form-select form-select-solid capital'
                            onChange={(e: any) => {
                              if (e.target.value === '') {
                                setCuitSelected('');

                              }
                              formik.setFieldValue('empresa', e.target.value);
                              setCuitSelected(e.target.value);
                            }}
                          >
                            <option value=''>Todas</option>
                            {selectEmpresas?.map((item: any) => {
                              return <option value={item.cuit}>{item.nombre}</option>
                            })}
                            {/*                           {formik.errors.anio && formik.touched.anio ? (
                            <div>{formik.errors.anio}</div>
                            ) : null}
                          */}                        </Field>
                        </FormGroup>
                      </div>

                      {areaUsr === 'juventud' &&
                        <div className='col-3'>
                          <FormGroup>


                            <FormLabel className='form-label fs-6 fw-bolder text-dark' htmlFor='estado'>
                              Estado
                            </FormLabel>
                            <Field
                              as='select'
                              id='estado'
                              name='estado'
                              placeholder='Estado'
                              className='form-select form-select-solid capital'
                              onChange={(e: any) => {
                                if (e.target.value === '') {
                                  setEstadoSelected('');

                                }
                                formik.setFieldValue('estado', e.target.value);
                                setEstadoSelected(e.target.value);
                              }}
                            >
                              <option value=''>Todos</option>
                              {ESTADOS?.map((item: any) => {
                                return <option value={item}>{item}</option>
                              })}
                              {/*                           {formik.errors.anio && formik.touched.anio ? (
                              <div>{formik.errors.anio}</div>
                              ) : null}
                            */}                        </Field>
                          </FormGroup>
                        </div>
                      }
                    </div>

                  </Form>
                )
              }}
            </Formik>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Movimientos de empresas al: {hoy} </span>
              </h3>

              {/* begin::Body */}
              <div className='card-body py-3 border-0 pt-5'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='w-25px'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value='1'
                              data-kt-check='true'
                              data-kt-check-target='.widget-13-check'
                            />
                          </div>
                        </th>
                        <th className='min-w-150px'>CUIT/CUIL</th>
                        <th className='min-w-140px'>Nombre</th>
                        {/* <th className='min-w-120px'>F.Inscripción </th> */}
                        <th className='min-w-120px'>Tipo Documento</th>
                        <th className='min-w-120px'>Periodo</th>
                        <th className='min-w-120px'>Estado</th>
                        <th className='min-w-120px'>Acciones</th>
                        {/* Se deja comentado en caso de necesitar que juventud no vea la columna y la acción */}
                        {/*{areaUsr !== 'juventud' &&
                          <th className='min-w-120px'>Acciones</th>
                        } */}
                        {/*  <th className='min-w-100px text-end'>Acciones</th> */}
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {empresas.map((empresa: any, index) => {
                        return (
                          <tr key={index}>
                            <td key={index}>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input widget-13-check'
                                  type='checkbox'
                                  value='1'
                                />
                              </div>
                            </td>
                            <td>
                              <div className='text-dark fw-bolder text-hover-primary fs-6'>
                                {empresa.cuit}
                              </div>
                            </td>
                            <td>
                              <div className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                                {empresa.nombre}
                              </div>
                              {/* <span className='text-muted fw-bold text-muted d-block fs-7'>Code: PH</span> */}
                            </td>
                            <td>
                              {empresa.tipoDocumento === 'Empleador' ?
                                <div className='badge badge-dark'>
                                  {empresa.tipoDocumento}
                                </div>
                                :
                                <div className='badge badge-primary text-default'>
                                  {empresa.tipoDocumento}
                                </div>

                              }
                            </td>
                            <td>
                              <div className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                                {empresa.mes.toUpperCase()}
                              </div>
                            </td>

                            <td>
                              {empresa.estado === 'aprobado' && (
                                <span className='badge badge-success capital'>Aprobado</span>
                              )}
                              {empresa.estado === 'presentado' && (
                                <span className='badge badge-light-info capital'>Presentado</span>
                              )}

                              {empresa.estado === 'pendiente' && (
                                <span className='badge badge-warning capital'>Pendiente</span>
                              )}
                              {empresa.estado === 'rechazado' && (
                                <span className='badge badge-danger capital'>Rechazado</span>
                              )}
                              {empresa.estado === 'validado' && (
                                <span className='badge badge-light-success capital'>validado</span>
                              )}

                            </td>
                            {/* {areaUsr !== 'juventud' && */}
                            <td className='text-center'>
                              <Link
                                to={{
                                  pathname: 'periodos',

                                  state: { empresa, us: 'gob', tipoUsuario: 'Empleo Joven' },
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                data-bs-dismiss-='click'
                                title='Ver documentos empresa'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen004.svg'
                                  className='svg-icon-3'
                                />

                              </Link>
                            </td>
                            {/* } */}
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                  {/* BTN Paginacion */}
                  <ul className='pagination'>
                    {currentPage === 0 ? (
                      <li className='page-item disabled'>
                        <a type='button' className='btn btn-sm btn-light-info' onClick={anteriorPag}>
                          Anterior
                        </a>
                      </li>
                    ) : (
                      <li className='page-item '>
                        <a type='button' className='btn btn-sm btn-light-info' onClick={anteriorPag}>
                          Anterior
                        </a>
                      </li>
                    )}

                    <li className='page-item'>
                      <a type='button' className='btn btn-sm btn-light-info' onClick={siguientePag}>
                        Siguiente
                      </a>
                    </li>
                  </ul>
                </div>
                {/* end::Table container */}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}
