/** React */
import {useState, useEffect} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
/** Formik */
import {Field, Form, Formik} from 'formik'
/** Material & Sass */
import {Button, Checkbox} from '@mui/material'
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp'
import '../../../../../../src/_metronic/assets/sass/cud.scss'
/** Services */
import {getJuntas, updateLegajo} from '../services/cud-service'
/** Hook */
import {CreateModal2} from '../hooks/useModal'
/** Interfaces */
import {Ijuntas, Ijunta} from '../Interfaces/index'
import {validateSchemaDatosPersonales} from '../Interfaces/Ilegajo'

/** Tables */
import {DatosPersonales, DatosTutor} from '../components/Tablas/index'
import { unformatdate } from '../helpers/fechaUtil'

export const LegajoEdit = () => {
  const location: any = useLocation()
  const [juntas, setJuntas] = useState<Ijuntas>([])
  const [btnEdit, setBtnEdit] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const handleOnChange = () => setIsChecked(!isChecked)
  const handleBtn = () => setBtnEdit(!btnEdit)
  let history = useHistory();

  useEffect(() => {
    getJuntas().then((res: any) => {
      setJuntas(res.data.juntas)
    })
  }, [])

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <Link
          to={{
            pathname: '/cud/Legajos',
            state: {
              activado: true,
              obj: location.state,
            },
          }}
          className='alineacionb'
        >
          <ArrowBackSharpIcon />
        </Link>
      </div>
      <div className='card-body py-1'>
        <div className='table-responsive'>
          <Formik
            initialValues={location.state}
            validationSchema={validateSchemaDatosPersonales}
            onSubmit={(values) => {
              values.estado = 'activo';
              const fechaNacimiento = values.datosPersonales?.fechaNacimiento;
              if (fechaNacimiento) {
                values.datosPersonales.fechaNacimiento = unformatdate(fechaNacimiento);
              }
              const fechaVencimientoRes = values.datosPersonales.fechaVencimientoResidencia;
              if (fechaVencimientoRes) {
                values.datosPersonales.fechaVencimientoResidencia = unformatdate(fechaVencimientoRes);
              }
              const fechaVehiculo = values.datosPersonales?.fechaVehiculo;
              if (fechaVehiculo) {
                values.datosPersonales.fechaVehiculo = unformatdate(fechaVehiculo);
              }
              
              updateLegajo(values).then((res: any) => {


                CreateModal2(history);
              })
            }}
          >
            {({handleChange, handleBlur, values, errors}) => (
              <div>
                <Form>
                  <div>
                    <div className='col-8 text-gray-400 fw-bold mt-2 fs-6'>Datos Personales</div>
                    <div className='card bg-ligth border mb-6 card_align5'>
                      <br />
                      <DatosPersonales
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        btnEdit={btnEdit}
                        formikValues={values}
                      />
                      <div className='row alineacion_inputs'>
                        <div className='col-lg-5'>
                          <label className='form-label fw-bolder text-dark fs-6'>Junta</label>
                          <Field
                            as='select'
                            id='junta'
                            name='junta'
                            className='form-control form-control-solid capital'
                            disabled={!btnEdit}
                          >
                            <option value='Seleccione'>Seleccione</option>
                            {juntas.map((value: Ijunta, index) => {
                              return (
                                <option key={index} value={value.nombre}>
                                  {value.nombre}
                                </option>
                              )
                            })}
                          </Field>
                        </div>
                        <div className='col-lg-6 capital'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            Observaciones
                          </label>
                          <Field
                            control='input'
                            type='text'
                            id='observaciones'
                            name='observaciones'
                            className='form-control form-control-solid capital'
                            disabled={!btnEdit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <br />
                    </div>
                    <br />
                    {location.state.datosTutor?.nombre && location.state.datosTutor.nombre ? (
                      <>
                        <div className='col-8 text-gray-600 fw-bold mt-2 fs-6'>DATOS TUTOR</div>
                        <div className='card bg-ligth border mb-6 card_align5'>
                          <DatosTutor
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            btnEdit={btnEdit}
                            formikValue={values}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <Field
                          type='checkbox'
                          as={Checkbox}
                          id='tutor'
                          name='tutor'
                          className='check_label_posicion2 check_tutor2'
                          onChange={handleChange}
                          onClick={handleOnChange}
                          onBlur={handleBlur}
                          checked={isChecked}
                        />
                        <label className='check_label_posicion2 check_tutor2'>
                          AGREGAR DATOS DE TUTOR
                        </label>
                      </>
                    )}
                    {isChecked ? (
                      <div className='card bg-ligth border mb-6 card_align5'>
                        <DatosTutor
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          btnEdit={btnEdit}
                        />
                      </div>
                    ) : null}
                    <div className='row card_align5'>
                      <div className='col-lg-8'>
                        <Button className='btn btn-primary' onClick={handleBtn}>
                          {btnEdit === false ? 'Habilitar Edición' : 'Deshabilitar Edición'}
                        </Button>
                        &nbsp;&nbsp;
                        {btnEdit === true ? (
                          <Button type='submit' className='btn btn-success' disabled={false}>
                            Guardar
                          </Button>
                        ) : (
                          ''
                        )}
                      </div>{' '}
                    </div>
                  </div>
{/*                   <pre>
                    {JSON.stringify({values, errors}, null, 2)}
                  </pre> */}
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
