import React from 'react'
import {PageLink} from '../../../../../_metronic/layout/core'
import {FC} from 'react'
interface Props {
  description?: string
  breadcrumbs?: Array<PageLink>
}
export const PageTitleCud: FC<Props> = ({children, description, breadcrumbs}) => {
  return <div className='capital'> Sistema Gestor de Certificado único de Discapacidad</div>
}

export default PageTitleCud
