import {useSelector} from 'react-redux'
import {Redirect, Route, useRouteMatch} from 'react-router'
import {Switch} from 'react-router-dom'
import {ISTATE} from '../../../../setup/redux/interface/reduxState.interface'
import {PageTitle} from '../../../../_metronic/layout/core'
import {ICreateModulo} from '../Sis-admin/interfaces/ICreateModulo'
import DocPresentada from './pages/DocPresentada'
import {FormEmpleado} from './pages/FormEmpleado'
import MisDatos from './pages/MisDatos'
import {MPHeader} from './pages/MPHeader'
import TablaEmpleados from './pages/TablaEmpleados'
import TablaEmprendedores from './pages/TablaEmprendedores'

import TablaEmpresas from './pages/TablaEmpresas'
import TablaMisEmpleados from './pages/TablaMisEmpleados'
import {PeriodosEmp} from './pages/PeriodosEmp'
import {EmpresaMesxMes} from './pages/EmpresaMesxMes'
import {MisCertificados} from './pages/MisCertificados'
import { MovimientosMensuales } from './pages/MovimientosMensuales'

const EmpleoJovenRouter = () => {
  const {url} = useRouteMatch()

  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)
  let {perfil} = dashState

  const modulo: ICreateModulo = perfil.find((value: any) => value.nombre === 'Empleo Joven')
  console.log("MODULO: ", modulo);
  return (
    <>
      <MPHeader />
      <Switch>
        {modulo.menu.map((data: any) => {
          console.log(data.name);
          return (
            <Route path={`/${data.state}`}>
              <PageTitle>
                <div className='capital'>{data.name}</div>
              </PageTitle>
              {data.name === 'empresas' && <TablaEmpresas />}
              {data.name === 'emprendedores' && <TablaEmprendedores />}
              {data.name === 'empleados' && <TablaEmpleados />}
              {data.name === 'mis datos' && <MisDatos />}
              {data.name === 'mis empleados' && <TablaMisEmpleados />}
              {data.name === 'mis documentos' && <DocPresentada />}
              {data.name === 'periodos' && <PeriodosEmp />}
              {data.name === 'mi empresa mes a mes' && <EmpresaMesxMes />}
              {data.name === 'mis certificados' && <MisCertificados />}
              {data.name === 'movimientos mensuales' && <MovimientosMensuales />}
            </Route>
          )
        })}
        <Route
          exact
          path={`${url}/formEmpleado`}
          render={() => {
            const menuAct = modulo.menu.find((value) => value.name === 'mis empleados')

            return menuAct?.roleUs?.crear ? <FormEmpleado /> : <Redirect to={`${url}`} />
          }}
        ></Route>
        <Route
          exact
          path={`${url}/misdatos`}
          render={() => {
            const menuAct = modulo.menu.find(
              (value) => value.name === 'empresas' || value.name === 'emprendedores'
            )
            return <MisDatos />
          }}
        ></Route>
        <Route
          exact
          path={`${url}/misdocumentos`}
          render={() => {
            const menuAct = modulo.menu.find(
              (value) => value.name === 'empresas' || value.name === 'emprendedores'
            )
            return <DocPresentada />
          }}
        ></Route>
      </Switch>
    </>
  )
}

export {EmpleoJovenRouter}
