/** React */
import React, {useEffect, useState} from 'react'
/** Formik */
import {ErrorMessage, Field} from 'formik'
import {localidadesNqn} from '../../../../../services/gral/proLocService'
import TextErrorFormik from '../../../../../components/Formik/TextErrorFormik'
import {FormControlLabel, Radio, RadioGroup} from '@mui/material'

import {Ijunta, Ijuntas} from '../../Interfaces/Ijunta'
import {getJuntas} from '../../services/cud-service'
import Swal from 'sweetalert2'
import moment from 'moment'

type Props = {
  handleChange: any
  handleBlur: any
  formsecu?: any
  setFormsecu?: any
  datosPersonales?: any
  mi_estado_tutor?: any
  formik?: any
  valueVehiculo?: any
  valueSimbolo?: any
  valueDerecho?: any
  menor?: any
  confirmar?: any
  setConfirmar?: any
  formatoDisable?: any
  setFormatoDisable?: any
  opcionConfirma?: any
  setOpcionConfirma?: any
  disableConfirma?: any
  setDisableConfirma?: any
  formatoOpacity?: any
  setFormatoOpacity?: any
  //
  btnConfirmar?: any
  setBtnConfirmar?: any
  btnEditar?: any
  setBtnEditar?: any
  disableTextC?: any
  setDisableTextC?: any
  btnDisable?: any
  setBtnDisable?: any
  btnSwitch?: any
  setBtnSwitch?: any
  miHidden?: any
  anios?: any
  setIsChecked?: any
  setAlertaMayor?: any
  alertaMayor?: any
}

export const Datos_Personales: React.FC<Props> = ({
  handleChange,
  handleBlur,
  formsecu,
  setFormsecu,
  datosPersonales,
  formik,
  valueVehiculo,
  valueSimbolo,
  valueDerecho,
  formatoDisable,
  setFormatoDisable,
  opcionConfirma,
  setOpcionConfirma,
  setDisableConfirma,
  formatoOpacity,
  setFormatoOpacity,
  btnConfirmar,
  setBtnConfirmar,
  btnEditar,
  setBtnEditar,
  disableTextC,
  setDisableTextC,
  btnSwitch,
  setBtnSwitch,
  miHidden,
  anios,
  setIsChecked,
  alertaMayor,
  setAlertaMayor,
}) => {
  const [localidad, setLocalidad] = useState<any>([])
  const [fechaNow, setFechaNow] = useState(moment().format('DD/MM/YYYY'))
  const [juntas, setJuntas] = useState<Ijuntas>([])

  const checkeomayor = (anios: any, formik: any) => {
    setFormsecu({
      ...formsecu,
      junta: formik.values.junta,
      datosPersonales: formik.values.datosPersonales,
      datosTutor: formik.values.datosTutor,
    })

    if (
      anios >= 16 &&
      formsecu.alertas.cambioMayor === false &&
      formik.values.datosTutor.dni !== ''
    ) {
      Swal.fire({
        title: 'Atención',
        html: 'La persona solicitante posee la mayoria de edad.<br>Actualmente tiene asignado un Tutor.<br><br>Si desea eliminarlo, seleccione la opción <b><font style=color:red>Eliminar Tutor</font></b>',

        icon: 'warning',
        confirmButtonColor: 'green',
        confirmButtonText: 'Cerrar',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsChecked(true)
          setAlertaMayor(true)
        }
      })
    }
  }

  const confirma = (opcion: any) => {
    setFormsecu({
      ...formsecu,
      junta: formik.values.junta,
      datosPersonales: formik.values.datosPersonales,
    })

    if (opcion === 'true') {
      checkeomayor(anios, formik)
      setFormatoDisable('card bg-ligth border mb-6 card_align5 disabled_formato')
      setFormatoOpacity('disabled_opacity')
      setDisableConfirma(true)
      setBtnConfirmar(true)
      setBtnEditar(true)
      setDisableTextC(true)
      setBtnSwitch(false)
    } else {
      setFormatoDisable('card bg-ligth border mb-6 card_align5')
      setFormatoOpacity('')
      setOpcionConfirma(true)
      setBtnConfirmar(false)
      setBtnEditar(false)
      setDisableTextC(false)
      setBtnSwitch(true)
    }
  }

  const mijunta = (): Ijunta[] => {
    let juntaActiva = juntas.filter((juntas: Ijunta) => juntas.activo === true)
    return juntaActiva
  }
  function changedateformat(val: any) {
    let myArray = val.split('/')

    let year = myArray[0]
    let month = myArray[1]
    let day = myArray[2]

    let formatteddate = day + '-' + month + '-' + year

    return formatteddate
  }

  useEffect(() => {
    localidadesNqn().then((res: any) => {
      setLocalidad(res.data.data)
    })
  }, [])

  useEffect(() => {}, [datosPersonales])
  useEffect(() => {}, [formatoDisable])
  useEffect(() => {}, [disableTextC])
  useEffect(() => {
    getJuntas().then((res: any) => {
      setJuntas(res.data.juntas)
    })
  }, [])

  return (
    <>
      <div className={formatoOpacity}>
        <br></br>

        <div className='row'>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Apellido</label>
            <Field
              control='input'
              type='text'
              placeholder='Ingrese Apellido'
              id='datosPersonales.apellido'
              name='datosPersonales.apellido'
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextC}
            />
            {formik.errors.apellido && formik.touched.apellido ? (
              <div>{formik.errors.apellido}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.apellido'
            />
          </div>

          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Nombre</label>

            <Field
              control='input'
              placeholder='Ingrese Nombres'
              type='text'
              id='datosPersonales.nombre'
              name='datosPersonales.nombre'
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextC}
            />
            {formik.errors.nombre && formik.touched.nombre ? (
              <div>{formik.errors.apellido}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.nombre'
            />
          </div>

          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>Cuit</label>
            <Field
              control='input'
              type='text'
              id='datosPersonales.cuit'
              name='datosPersonales.cuit'
              placeholder='Ingrese CUIT'
              className='form-control form-control-solid '
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextC}
            />
            {formik.errors.cuit && formik.touched.cuit ? <div>{formik.errors.cuit}</div> : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.cuit'
            />
          </div>
          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>Fecha de Nacimiento.</label>
            <Field
              type='date'
              id='datosPersonales.fechaNacimiento'
              name='datosPersonales.fechaNacimiento'
              value={changedateformat(formik.values.datosPersonales.fechaNacimiento)}
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextC}
              max={fechaNow}
            />

            {formik.errors.fechaNacimiento && formik.touched.fechaNacimiento ? (
              <div>{formik.errors.fechaNacimiento}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.fechaNacimiento'
            />
          </div>
        </div>

        <br></br>
        <br></br>

        <div className='row'>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Domicilio</label>

            <Field
              control='input'
              placeholder='Ingrese Domicilio'
              type='text'
              id='datosPersonales.domicilio'
              name='datosPersonales.domicilio'
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextC}
            />
            {formik.errors.domicilio && formik.touched.domicilio ? (
              <div>{formik.errors.domicilio}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.domicilio'
            />
          </div>

          <div className='col-lg-2'>
            <>
              <label className='form-label fw-bolder text-dark fs-6'>Localidad</label>
              <Field
                as='select'
                id='datosPersonales.localidad'
                name='datosPersonales.localidad'
                className='form-control form-control-solid capital'
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disableTextC}
              >
                <option value=''>Seleccionar</option>
                {localidad.map((value: any, index: any) => {
                  return (
                    <option key={index} value={value.nombre.toLowerCase()}>
                      {value.nombre}
                    </option>
                  )
                })}
              </Field>
            </>

            {formik.errors.localidad && formik.touched.localidad ? (
              <div>{formik.errors.localidad}</div>
            ) : null}
            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.localidad'
            />
          </div>
          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>Departamento</label>
            <Field
              as='select'
              id='datosPersonales.departamento'
              name='datosPersonales.departamento'
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextC}
            >
              <option value=''>Seleccionar</option>
              <option value='añelo'>añelo</option>
              <option value='catán lil'>catán lil</option>
              <option value='chos malal'>chos malal</option>
              <option value='collón curá'>collón curá</option>
              <option value='confluencia'>confluencia</option>
              <option value='huiliches'>huiliches</option>
              <option value='lácar'>lácar</option>
              <option value='loncopué'>loncopué</option>
              <option value='los lagos'>los lagos</option>
              <option value='minas'>minas</option>
              <option value='ñorquín'>ñorquín</option>
              <option value='pehuenches'>pehuenches</option>
              <option value='picún leufú'>picún leufú</option>
              <option value='picunches'>picunches</option>
              <option value='zapala'>zapala</option>
            </Field>
            {formik.errors.departamento && formik.touched.departamento ? (
              <div>{formik.errors.departamento}</div>
            ) : null}
            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.departamento'
            />
          </div>
          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>Nacionalidad</label>

            <Field
              control='input'
              type='text'
              id='datosPersonales.nacionalidad'
              name='datosPersonales.nacionalidad'
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextC}
            />
            {formik.errors.nacionalidad && formik.touched.nacionalidad ? (
              <div>{formik.errors.nacionalidad}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.nacionalidad'
            />
          </div>
          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>Codigo Postal</label>

            <Field
              control='input'
              type='text'
              placeholder='Ingrese Cod Postal'
              id='datosPersonales.codigoPostal'
              name='datosPersonales.codigoPostal'
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextC}
            />
            {formik.errors.codigoPostal && formik.touched.codigoPostal ? (
              <div>{formik.errors.codigoPostal}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.codigoPostal'
            />
          </div>
        </div>
        <br></br>
        <br></br>
        <div className='row'>
          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>Estado Civil</label>

            <Field
              as='select'
              id='datosPersonales.estadoCivil'
              name='datosPersonales.estadoCivil'
              className='form-control form-control-solid'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={disableTextC}
            >
              <option value=''>Seleccione</option>
              <option value='soltero/a'>soltero/a</option>
              <option value='casado/a'>casado/a</option>
              <option value='separado/a'>separado/a</option>
              <option value='divorcialdo/a'>divorciado/a</option>
              <option value='viudo/a'>viudo/a</option>
              <option value='union civil / convivencial'>union Civil / convivencial</option>
            </Field>
            {formik.errors.estadoCivil && formik.touched.estadoCivil ? (
              <div>{formik.errors.estadoCivil}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.estadoCivil'
            />
          </div>

          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>Tipo Residencia</label>

            <Field
              as='select'
              id='datosPersonales.tipoResidencia'
              name='datosPersonales.tipoResidencia'
              className='form-control form-control-solid capital'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={disableTextC}
            >
              <option value=''>Seleccione</option>
              <option value='permanente'>Permanente</option>
              <option value='transitoria'>Transitoria</option>
              <option value='temporaria'>Temporaria</option>
              <option value='precaria'>Precaria</option>
            </Field>
            {formik.errors.tipoResidencia && formik.touched.tipoResidencia ? (
              <div>{formik.errors.tipoResidencia}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.tipoResidencia'
            />
          </div>

          {formik.values.datosPersonales.tipoResidencia !== 'permanente' &&
          formik.values.datosPersonales.tipoResidencia !== '' ? (
            <div className='col-lg-2'>
              <label className='form-label fw-bolder text-dark fs-6'>Vencimiento Residencia</label>
              <Field
                control='input'
                type='date'
                id='datosPersonales.fechaVencimientoResidencia'
                name='datosPersonales.fechaVencimientoResidencia'
                className='form-control form-control-solid '
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disableTextC}
              />
              {formik.errors.fechaVencimientoResidencia &&
              formik.touched.fechaVencimientoResidencia ? (
                <div>{formik.errors.fechaVencimientoResidencia}</div>
              ) : null}

              <ErrorMessage
                className='error'
                component={TextErrorFormik}
                name='datosPersonales.fechaVencimientoResidencia'
              />
            </div>
          ) : null}

          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>Telefono</label>

            <Field
              control='input'
              type='text'
              placeholder='Ingrese Teléfono'
              id='datosPersonales.telefono'
              name='datosPersonales.telefono'
              className='form-control form-control-solid capital'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextC}
            />
            {formik.errors.telefono && formik.touched.telefono ? (
              <div>{formik.errors.telefono}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.telefono'
            />
          </div>

          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Email</label>

            <Field
              control='input'
              type='email'
              id='datosPersonales.mail'
              placeholder='Ingrese Correo'
              name='datosPersonales.mail'
              className='form-control form-control-solid'
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableTextC}
            />
            {formik.errors.mail && formik.touched.mail ? <div>{formik.errors.mail}</div> : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.mail'
            />
          </div>
        </div>
        <br></br>
        <br></br>
        <div className='row'>
          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>
              Adquirió vehículo a traves de Ley 19.279?
            </label>
          </div>

          <div className='col-lg-2'>
            <RadioGroup
              row
              aria-labelledby='demo-row-radio-buttons-group-label'
              name='datosPersonales.vehiculoLey'
              defaultValue={valueVehiculo}
            >
              <FormControlLabel
                control={
                  <Radio
                    disabled={disableTextC}
                    value={'si'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                }
                label='Si'
              />
              <FormControlLabel
                control={
                  <Radio
                    disabled={disableTextC}
                    value={'no'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                }
                label='No'
              />
            </RadioGroup>
            {formik.errors.vehiculoLey && formik.touched.vehiculoLey ? (
              <div>{formik.errors.vehiculoLey}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.vehiculoLey'
            />
          </div>

          {formik.values.datosPersonales.vehiculoLey === 'si' ? (
            <div className='col-lg-2'>
              <label className='form-label fw-bolder text-dark fs-6'>Fecha</label>
              <Field
                control='input'
                type='date'
                id='datosPersonales.fechaVehiculo'
                name='datosPersonales.fechaVehiculo'
                className='form-control form-control-solid '
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disableTextC}
                max={fechaNow}
              />
              {formik.errors.fechaVehiculo && formik.touched.fechaVehiculo ? (
                <div>{formik.errors.fechaVehiculo}</div>
              ) : null}
              <ErrorMessage
                className='error'
                component={TextErrorFormik}
                name='datosPersonales.fechaVehiculo'
              />
            </div>
          ) : null}
          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>
              Posee simbolo internacional de Acceso?
            </label>
          </div>

          <div className='col-lg-2'>
            <RadioGroup
              row
              aria-labelledby='demo-row-radio-buttons-group-label'
              name='datosPersonales.simboloInternacional'
              id='datosPersonales.simboloInternacional'
              defaultValue={valueSimbolo}
            >
              <FormControlLabel
                control={
                  <Radio
                    disabled={disableTextC}
                    value={'si'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                }
                label='Si'
              />
              <FormControlLabel
                control={
                  <Radio
                    disabled={disableTextC}
                    value={'no'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                }
                label='No'
              />
            </RadioGroup>
            {formik.errors.fechaVehiculo && formik.touched.simboloInternacional ? (
              <div>{formik.errors.simboloInternacional}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.simboloInternacional'
            />
          </div>
        </div>
        <br></br>
        <div className='row'>
          <div className='col-lg-8'>
            <label className='form-label fw-bolder text-dark fs-6'>
              Estaria interesada en percibir las asignaciones familiares vinvuladas a la
              discapacidad a las que pudise tener derecho?
            </label>
          </div>

          <div className='col-lg-2'>
            <RadioGroup
              row
              aria-labelledby='demo-row-radio-buttons-group-label'
              name='datosPersonales.derechoAsignacionesF'
              id='datosPersonales.derechoAsignacionesF'
              defaultValue={valueDerecho}
            >
              <FormControlLabel
                control={
                  <Radio
                    disabled={disableTextC}
                    value={'si'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                }
                label='Si'
              />

              <FormControlLabel
                control={
                  <Radio
                    disabled={disableTextC}
                    value={'no'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                }
                label='No'
              />
            </RadioGroup>
            {formik.errors.derechoAsignacionesF && formik.touched.derechoAsignacionesF ? (
              <div>{formik.errors.derechoAsignacionesF}</div>
            ) : null}

            <ErrorMessage
              className='error'
              component={TextErrorFormik}
              name='datosPersonales.derechoAsignacionesF'
            />
          </div>
        </div>
      </div>

      <div className='col-8 text-gray-800 fw-bold fs-6'>Junta Asignada.</div>
      <div className='card bg-ligth border mb-6 card_align5'>
        <label className='form-label fw-bolder text-dark fs-6' htmlFor='juntas'></label>
        <Field
          as='select'
          label=''
          id='junta'
          name='junta'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className='form-control form-control-solid capital'
          disabled={disableTextC}
        >
          <option value=''>Seleccione una opción</option>
          {mijunta().map((value: any, index: any) => {
            return (
              <option key={index} value={value.nombre}>
                {value.nombre}
              </option>
            )
          })}
          {formik.errors.junta ? <div>{formik.errors.junta}</div> : null}
        </Field>

        <ErrorMessage className='error' component={TextErrorFormik} name='junta' />
      </div>
      {formik.validateOnMount === true && formik.isValid === true && opcionConfirma === true ? (
        <>
          <div className='col-lg-12'>
            <button
              hidden={btnEditar}
              type='button'
              className='btn btn-warning btn-sm btn-salir'
              onClick={() => {
                confirma('true')
              }}
            >
              Confirmar
            </button>
          </div>

          <div className='col-lg-12'>
            <button
              type='button'
              hidden={!btnEditar}
              className='btn btn-warning btn-sm btn-salir'
              onClick={() => {
                confirma('false')
              }}
              disabled={btnSwitch}
            >
              Editar
            </button>
          </div>
        </>
      ) : (
        <div className='col-lg-12'>
          <button
            type='button'
            hidden={!btnEditar}
            className='btn btn-warning btn-sm btn-salir'
            onClick={() => {
              confirma('false')
            }}
            disabled={btnSwitch}
          >
            Editar
          </button>
        </div>
      )}
    </>
  )
}
export default Datos_Personales
