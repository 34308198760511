import React, {useState} from 'react'

import {Card4} from '../../../../../_metronic/partials/content/cards/Card4'
import {useLocation} from 'react-router'
import {useHistory} from 'react-router-dom'

import {AbmArchivosEmpresas} from '../components/abmArchivosEmpresas'

const DocPresentada = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  let location: any = useLocation()
  let history: any = useHistory()

  //console.log('🚀 ~ file: DocPresentada.tsx:8 ~ DocPresentada ~ location:', location)

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          Mis Documentos
          <span className='fs-6 text-gray-400 fw-bold ms-1'>
            {location.state.documentos.length} Archivos
          </span>
        </h3>
        {/*  {location.state?.archivos[0]?.estado === 'rectificar' && (
          <button
            type='button'
            className='btn btn-primary me-2 px-6'
            onClick={() => {
              closeModal()
            }}
          >
            Rectificar Documentación
          </button>
        )} */}

        <button
          type='button'
          className='btn btn-primary me-2 px-6'
          onClick={() => {
            history.goBack()
          }}
        >
          Volver
        </button>
      </div>

      <div className='row'>
        {location.state?.archivos[0]?.estado !== 'ratificar' ? (
          location?.state.documentos.map((doc: any, index: any) => {
            let nombreArch = doc.split('_')
            return (
              <div key={index} className='col-3 col-sm-3 col-xl'>
                <Card4
                  icon='/media/svg/files/pdf.svg'
                  title={nombreArch[4]}
                  tipoUsuario={location.state.tipoUs}
                  description=''
                  cuit={nombreArch[0]}
                  archivo={doc}
                />
              </div>
            )
          })
        ) : (
          <AbmArchivosEmpresas
            archivos={location.state?.archivos[0]?.archivos}
            empresa={location.state?.empresa}
          />
        )}
      </div>
    </>
  )
}

export default DocPresentada
