import {ErrorMessage} from 'formik'
import React from 'react'
import FormikControl from '../../../../../components/Formik/FormikControl'

interface IProps {
  update?: boolean
  wsOk?: boolean
  formik?: any
}
export const Step2DtsLaborales: React.FC<IProps> = ({update, wsOk, formik}) => {
  let fecha = new Date()
  let output =
    String(fecha.getMonth() + 1).padStart(2, '0') +
    '/' +
    String(fecha.getDate()).padStart(2, '0') +
    '/' +
    fecha.getFullYear()

  return (
    <>
      <div className='w-100'>
        <div className='pb-10 pb-lg-15'>
          <h2 className='fw-bolder d-flex align-items-center text-dark'>
            Complete los datos Laborales.
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Billing is issued based on your selected account type'
            ></i>
          </h2>

          <div className='text-gray-400 fw-bold fs-6'>
            Estos campos son requeridos para poder completar el alta.
          </div>
          <div className='fv-row'>
            <div className='row'>
              <div className='col-lg-6'>
                <FormikControl
                  control='input'
                  name='datosLaborales.legajo'
                  label='Legajo'
                  placeholder='Legajo'
                  className='form-control form-control-solid'
                />
                <div className='fv-plugins-message-container invalid-feedback'>
                  <ErrorMessage name='datosLaborales.legajo' />
                </div>
              </div>
              <div className='col-lg-6'>
                <FormikControl
                  minDate={new Date('11/24/2022')}
                  control='date'
                  maxDate={new Date(output)}
                  name='datosLaborales.fechaAlta'
                  label='Fecha de alta en AFIP.'
                  className='form-select form-select-solid capital'
                  id='kt_create_account_form_account_type_personal'
                  placeHolder='La fecha debe ser superior al 23/11/22'
                ></FormikControl>
              </div>
              <div className='fv-plugins-message-container invalid-feedback'>
                <ErrorMessage name='datosLaborales.fechaAlta' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
