import {request} from '../../../../../setup/axios/axios.util'
const API_URL = process.env.REACT_APP_API_URL || 'api'
const URL = `${API_URL}/modules/web-services`
const URL2 = `${API_URL}/modules/bens`

const tokenBens = sessionStorage.getItem('BensToken')

//find Token True
export const TokenFind = () => {
  return request().get(`${URL2}/TokenFind`)
}

//Download inscripciones
export const downloadHacienda = (bens: any) => {
  return request().post(`${URL2}/downloadbens`, bens)
}

export const patchAprobarBens = (bens: any) => {
  return request().patch(`${URL2}/AprobBens`, bens)
}

export const UpdateBens = (bens: any) => {
  return request().patch(`${URL2}/UpdateBens`, bens)
}

export const patchMov = (bens: any) => {
  return request().patch(`${URL2}/movimientos`, bens)
}

export const getpdfIns = (bens: any, token: any) => {
  const sendPdf = {
    data: {
      nroTramiteBens: bens,
      tokenUs: token,
    },
  }

  return request().post(`${URL}/bens/getPdfInscripcion`, sendPdf, {responseType: 'arraybuffer'})
}

export const getImgins = (bens: any) => {
  const SendImg = {
    data: {
      idComprobante: bens,
      tokenUs: tokenBens,
    },
  }
  return request().post(`${URL}/bens/getImgComprobante`, SendImg, {responseType: 'arraybuffer'})
}

export const getByDni = (bens: any) => {
  return request().post(`${URL2}/getByDni`, bens)
}

export const getFecha = (bens: any) => {
  return request().post(`${URL2}/getByFecha`, bens)
}

export const getFechaHacienda = (bens: any) => {
  return request().post(`${URL}/bens/getByFecha`, bens)
}

export const getById = (bens: any) => {
  return request().post(`${URL2}/getByNroTramite`, bens)
}

//Search by ID aprobados
export const getByIdPassed = (bens: any) => {
  return request().post(`${URL2}/getByIdPassed`, bens)
}

//Search by fecha aprobados
export const getFechaPassed = (bens: any) => {
  return request().post(`${URL2}/FechaPassed`, bens)
}
//Search by DNI aprobado
export const getAprobadByDni = (bens: any) => {
  return request().post(`${URL2}/getAprobadoByDni`, bens)
}

//Search by DNI aprobado
export const getIndicadores = () => {
  return request().get(`${URL2}/getIndicadores`)
}

export const aprobarBensHacienda = (id_tramite: string, token: string) => {
  const data: any = {
    nroTramiteBens: id_tramite,
    tokenUs: token,
  }
  return request().post(`${URL}/bens/aprobarTramite`, data)
}
export const rechazarBensHacienda = (id_tramite: string, token: string) => {
  const data: any = {
    nroTramiteBens: id_tramite,
    tokenUs: token,
  }
  return request().post(`${URL}/bens/declineTramite`, data)
}
export const transferirBensHacienda = (id_tramite: any, token: string) => {
  const data: any = {
    nroTramiteBens: id_tramite,
    tokenUs: token,
  }
  return request().post(`${URL}/bens/transferirTramite`, data)
}
