import React from 'react'
import {useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import {initCud} from '../Interfaces/inits'
import {postLegajo} from '../services/cud-service'
import moment from 'moment'

export const crearlegajo = (legajo: any, history: any, objeto: any) => {
  legajo.datosCud = {...initCud, estado: 'pendiente', fechaInicio: moment().format('DD/MM/YYYY')}
  Swal.fire({
    title: 'Seguro desea Crear Legajo?',
    text: 'Una vez creado ya no se podra editar o borrar!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si,Crear Legajo!',
  }).then((result) => {
    if (result.isConfirmed) {
      postLegajo(legajo)
      Swal.fire('OK!', 'Legajo creado correctamente.', 'success')
    }
    const inition = {
      pathname: '/cud/MesaEntrada',
      state: {activando: true, obj: objeto},
    }
    history.push(inition)
    window.location.reload()
  })
}
