import React from 'react'
import { ProfileDetails } from './cards/ProfileDetails'
import { SignInMethod } from './cards/SignInMethod'
import { Notifications } from './cards/Notifications'
import { DeactivateAccount } from './cards/DeactivateAccount'
import { ICreateUsr } from '../../../../../interfaces/ICreateUsr'

interface props {
  usuario?: ICreateUsr
  op?: string
}

export function Settings({ usuario, op }: props) {
  //  ////console.log(usuario)
  return (
    <>
      <ProfileDetails usuario={usuario!} />
      {op !== '' && <SignInMethod usuario={usuario?.usuario} />}
      {op !== 'BtnPerfil' && <Notifications idUsuario={usuario?._id} usuario={usuario} />}
      {op !== '' && <DeactivateAccount />}
    </>
  )
}
