import Tooltip from '@mui/material/Tooltip'
import { Formik, Form, FieldArray, Field } from 'formik'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import Swal from 'sweetalert2'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { ICreateModulos, ICreateModulo } from '../../../interfaces/ICreateModulo'
import { postPerfilUs } from '../../../services/UsuarioService'
import VerificaMenuSelec from '../../../util/CrearPerfil/VerificaMenuSelec'

interface IProps {
  perfil: ICreateModulos
  idUsuario: string
  btnSave(data: boolean): void
  update: boolean
}

export const TablePerfil: React.FC<IProps> = ({ perfil, idUsuario, btnSave, update }) => {
  const [current, setCurrent] = useState(0)
  const [admin, setAdmin] = useState<boolean>(false)
  const [difAdmin, setDifAdmin] = useState<boolean>(false)
  const [total, setTotal] = useState(perfil.length)
  const [moduloName, setModuloName] = useState<ICreateModulos>(perfil)
  let history: any = useHistory()

  useEffect(() => {
    let real = perfil.length - 1
    setTotal(real)
    setCurrent(0)
    setModuloName(perfil)
  }, [perfil])

  const guardarPerfil = async (perfil: ICreateModulos, update: boolean) => {
    let dataSave = VerificaMenuSelec(perfil)

    await postPerfilUs(dataSave, idUsuario, update).then(async (resp: any) => {
      if (resp.data.ok) {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'El perfil, fue cargado con exito',
          showConfirmButton: false,
          timer: 3500,
        })
        history.push({ pathname: '/admin/usuario/account', state: resp.data.data })
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Verificar los datos ingresados',
          icon: 'error',
          confirmButtonText: 'Volver',
        })
      }
    })
  }

  const setOpcion = (obj: any): boolean => {
    let opc = Object.values(obj)
    let found = opc.find((ele) => ele === true)
    let exist: boolean = false
    found ? (exist = true) : (exist = false)
    return exist
  }

  const filtro = (): ICreateModulos => {
    let data: ICreateModulos = []
    data.push(moduloName[current])
    return data
  }

  return (
    <div>
      <div id='kt_account_notifications' className='collapse show'>
        {filtro().map((modulo: ICreateModulo, index: any) => {
          return (
            <Formik
              initialValues={modulo}
              onSubmit={(modulo) => {
                guardarPerfil(moduloName, update)
              }}
              enableReinitialize
            >
              {(formik) => {
                //////console.log('formik', formik)
                return (
                  <Form>
                    {' '}
                    <FieldArray name='menu'>
                      {(fieldArray) => {
                        const { form } = fieldArray
                        const { values } = form
                        const { menu } = values
                        return (
                          <>
                            {' '}
                            <div>
                              <div className='table-responsive'>
                                <table className='table table-row-dashed border-gray-400 align-middle gy-3 '>
                                  <thead>
                                    <tr key={menu._id}>
                                      <th className='min-w-250px fs-4 fw-bolder'>
                                        {' '}
                                        Nombre del menú
                                      </th>
                                      <th className='w-125px'>Administrador</th>
                                      <th className='w-125px'>Consultar</th>
                                      <th className='w-125px'>Crear</th>
                                      <th className='w-125px'>Editar</th>
                                      <th className='w-125px'>Exportar</th>
                                      <th className='w-125px'>Indicadores</th>
                                    </tr>
                                  </thead>
                                  <tbody className='fs-6 fw-bold'>
                                    {menu.map((ele: any, index: any) => {
                                      return (
                                        <>
                                          <tr key={index}>
                                            <Tooltip title={ele.tooltip} placement='right-start'>
                                              <td className='capital'>{ele.name}</td>
                                            </Tooltip>

                                            <td>
                                              <div className='form-check form-check-solid'>
                                                <Field
                                                  disabled={
                                                    (formik.values.menu[index].roleUs!.consulta ||
                                                      formik.values.menu[index].roleUs!.crear ||
                                                      formik.values.menu[index].roleUs!.editor ||
                                                      formik.values.menu[index].roleUs!.exportar ||
                                                      formik.values.menu[index].roleUs!
                                                        .indicadores) &&
                                                    !formik.values.menu[index].roleUs!.admin
                                                  }
                                                  type='checkbox'
                                                  name={`menu[${index}].roleUs.admin`}
                                                  className='form-check-input'
                                                  onChange={(e: any) => {
                                                    formik.values.menu[index].roleUs!.admin =
                                                      !formik.values.menu[index].roleUs!.admin
                                                    formik.values.menu[index].roleUs!.consulta =
                                                      !formik.values.menu[index].roleUs!.consulta
                                                    formik.values.menu[index].roleUs!.crear =
                                                      !formik.values.menu[index].roleUs!.crear
                                                    formik.values.menu[index].roleUs!.editor =
                                                      !formik.values.menu[index].roleUs!.editor
                                                    formik.values.menu[index].roleUs!.exportar =
                                                      !formik.values.menu[index].roleUs!.exportar
                                                    formik.values.menu[index].roleUs!.indicadores =
                                                      !formik.values.menu[index].roleUs!.indicadores
                                                    setAdmin(!admin)
                                                    {
                                                      setOpcion(formik.values.menu[index].roleUs)
                                                        ? btnSave(true)
                                                        : btnSave(false)
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </td>
                                            <td>
                                              <div className='form-check form-check-solid'>
                                                <Field
                                                  id={ele.name}
                                                  disabled={formik.values.menu[index].roleUs!.admin}
                                                  type='checkbox'
                                                  name={`menu[${index}].roleUs.consulta`}
                                                  className='form-check-input'
                                                  onClick={(e: any) => {
                                                    formik.values.menu[index].roleUs!.consulta =
                                                      !formik.values.menu[index].roleUs!.consulta
                                                    {
                                                      setOpcion(formik.values.menu[index].roleUs)
                                                        ? btnSave(true)
                                                        : btnSave(false)
                                                    }
                                                    ///////console.log(formik.values)
                                                  }}
                                                />
                                              </div>
                                            </td>
                                            <td>
                                              <div className='form-check form-check-solid'>
                                                <Field
                                                  disabled={formik.values.menu[index].roleUs!.admin}
                                                  type='checkbox'
                                                  name={`menu[${index}].roleUs.crear`}
                                                  className='form-check-input'
                                                  onClick={(e: any) => {
                                                    formik.values.menu[index].roleUs!.crear =
                                                      !formik.values.menu[index].roleUs!.crear
                                                    {
                                                      setOpcion(formik.values.menu[index].roleUs)
                                                        ? btnSave(true)
                                                        : btnSave(false)
                                                    }
                                                    ///////console.log(formik.values)
                                                  }}
                                                />
                                              </div>
                                            </td>
                                            <td>
                                              <div className='form-check form-check-solid'>
                                                <Field
                                                  disabled={formik.values.menu[index].roleUs!.admin}
                                                  type='checkbox'
                                                  name={`menu[${index}].roleUs.editor`}
                                                  className='form-check-input'
                                                  onClick={(e: any) => {
                                                    formik.values.menu[index].roleUs!.editor =
                                                      !formik.values.menu[index].roleUs!.editor
                                                    {
                                                      setOpcion(formik.values.menu[index].roleUs)
                                                        ? btnSave(true)
                                                        : btnSave(false)
                                                    }
                                                    ///////console.log(formik.values)
                                                  }}
                                                />
                                              </div>
                                            </td>
                                            <td>
                                              <div className='form-check form-check-solid'>
                                                <Field
                                                  disabled={formik.values.menu[index].roleUs!.admin}
                                                  type='checkbox'
                                                  name={`menu[${index}].roleUs.exportar`}
                                                  className='form-check-input'
                                                  onClick={(e: any) => {
                                                    formik.values.menu[index].roleUs!.exportar =
                                                      !formik.values.menu[index].roleUs!.exportar
                                                    {
                                                      setOpcion(formik.values.menu[index].roleUs)
                                                        ? btnSave(true)
                                                        : btnSave(false)
                                                    }
                                                    ///////console.log(formik.values)
                                                  }}
                                                />
                                              </div>
                                            </td>
                                            <td>
                                              <div className='form-check form-check-solid'>
                                                <Field
                                                  disabled={formik.values.menu[index].roleUs!.admin}
                                                  type='checkbox'
                                                  name={`menu[${index}].roleUs.indicadores`}
                                                  className='form-check-input'
                                                  onClick={(e: any) => {
                                                    formik.values.menu[index].roleUs!.indicadores =
                                                      !formik.values.menu[index].roleUs!.indicadores
                                                    {
                                                      setOpcion(formik.values.menu[index].roleUs)
                                                        ? setDifAdmin(true)
                                                        : setDifAdmin(false)
                                                    }
                                                    {
                                                      setOpcion(formik.values.menu[index].roleUs)
                                                        ? btnSave(true)
                                                        : btnSave(false)
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    })}
                                  </tbody>
                                </table>
                                <div className='d-flex flex-stack pt-15'>
                                  <>
                                    <div className='mr-2'>
                                      {current > 0 && (
                                        <button
                                          type='button'
                                          className='btn btn-light btn-active-light-primary'
                                          onClick={() => {
                                            setCurrent(current - 1)
                                          }}
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/arrows/arr063.svg'
                                            className='svg-icon-4 me-1'
                                          />
                                          Anterior
                                        </button>
                                      )}
                                    </div>

                                    <Tooltip
                                      title={moduloName[current]?.descripcion}
                                      placement='bottom'
                                    >
                                      <div className='capital'>{moduloName[current]?.nombre}</div>
                                    </Tooltip>

                                    <div>
                                      {total > 0 && current < total && (
                                        <button
                                          type='button'
                                          className='btn btn-light btn-active-light-primary'
                                          onClick={() => {
                                            setCurrent(current + 1)
                                            setAdmin(false)
                                          }}
                                        >
                                          Siguiente
                                          <KTSVG
                                            path='/media/icons/duotune/arrows/arr064.svg'
                                            className='svg-icon-3 ms-2 me-0'
                                          />
                                        </button>
                                      )}
                                      {total === current && (
                                        <>
                                          {update ? (
                                            <button type='submit' className='btn btn-primary'>
                                              Actualizar
                                              <KTSVG
                                                path='/media/icons/duotune/arrows/arr064.svg'
                                                className='svg-icon-3 ms-2 me-0'
                                              />
                                            </button>
                                          ) : (
                                            <button type='submit' className='btn btn-primary'>
                                              Guardar
                                              <KTSVG
                                                path='/media/icons/duotune/arrows/arr064.svg'
                                                className='svg-icon-3 ms-2 me-0'
                                              />
                                            </button>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      }}
                    </FieldArray>
                  </Form>
                )
              }}
            </Formik>
          )
        })}
      </div>
    </div>
  )
}
