import { useLocation } from 'react-router'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment'
import { useSelector } from 'react-redux';
import { ISTATE } from '../../../../../../setup/redux/interface/reduxState.interface';
import IndicadoresPage from '../IndicadoresCal';

export const VerExportacion = () => {

  const eventosStore: any = useSelector((state: ISTATE) => state.eventos)
  const { evFiltro, componente } = eventosStore

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      borderBottom: '1px solid #000000',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
      heigth: '100px',
    },
  }));

  function crearData(
    area: string,
    nombre_evento: string,
    start: string,
    end: string,
    localidad: string,
    presencia_ministro: string,
    estado: string,
  ) {
    return {
      area, nombre_evento, start, end, localidad, presencia_ministro
      , estado
    };
  }

  let rows: any = []
  for (let ev = 0; ev < evFiltro.length; ev++) {
    const e: any = evFiltro[ev];
    rows.push(crearData(e.area, e.title, e.start, e.end, e.localidad, e.presMinistro ? "Si" : "No", e.estado))
  }


  return (
    <div id='Exportar'>
      {componente !== 'indicadores' && (

        <TableContainer component={Paper} key={'Exportar'} style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem"
        }}>
          <Table style={{
            width: '98%',
            marginTop: '10px',
            border: '1px solid #000000',
          }} aria-label="customized table">
            <TableHead>
              <TableRow style={{ backgroundColor: 'black', color: 'white' }}>
                <TableCell align="center" style={{ color: 'white' }}>Area</TableCell>
                <TableCell align="center" style={{ color: 'white' }}>Nombre del Evento</TableCell>
                <TableCell align="center" style={{ color: 'white' }}>Fecha de Inicio</TableCell>
                <TableCell align="center" style={{ color: 'white' }}>Fecha de Finalización</TableCell>
                <TableCell align="center" style={{ color: 'white' }}>Localidad</TableCell>
                <TableCell align="center" style={{ color: 'white' }}>PM</TableCell>
                <TableCell align="center" style={{ color: 'white' }}>Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: any) => (
                <StyledTableRow key={''}>

                  <TableCell align="center" className='capital' style={{ border: '1px solid #000000', height: 80 }}>{row.area}</TableCell>
                  <TableCell align="center" className='capital' style={{ border: '1px solid #000000' }}>{row.nombre_evento}</TableCell>
                  <TableCell align="center" style={{ border: '1px solid #000000' }}>{moment(row.start).format("DD/MM/YYYY HH:mm")}</TableCell>
                  <TableCell align="center" style={{ border: '1px solid #000000' }}>{moment(row.end).format("DD/MM/YYYY HH:mm")}</TableCell>
                  <TableCell align="center" className='capital' style={{ border: '1px solid #000000' }}>{row.localidad}</TableCell>
                  <TableCell align="center" style={{ border: '1px solid #000000' }}>{row.presencia_ministro}</TableCell>
                  <TableCell align="center" className='capital' style={{ border: '1px solid #000000' }}>{row.estado}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
      }
      {
        componente === 'indicadores' && (

          <TableContainer component={Paper} key={'Exportar'} style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem"
          }}>
            <Table style={{
              width: '98%',
              marginTop: '10px',
              border: '1px solid #000000',
            }} aria-label="customized table">
              <TableHead>
                <TableRow style={{ backgroundColor: 'black', color: 'white' }}>
                  <TableCell align="center" style={{ color: 'white' }}>Area</TableCell>
                  <TableCell align="center" style={{ color: 'white' }}>Nombre del Evento</TableCell>
                  <TableCell align="center" style={{ color: 'white' }}>Fecha de Inicio</TableCell>
                  <TableCell align="center" style={{ color: 'white' }}>Fecha de Finalización</TableCell>
                  <TableCell align="center" style={{ color: 'white' }}>Localidad</TableCell>
                  <TableCell align="center" style={{ color: 'white' }}>PM</TableCell>
                  <TableCell align="center" style={{ color: 'white' }}>Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any) => (
                  <StyledTableRow key={''}>
                    <TableCell align="center" className='capital' style={{ border: '1px solid #000000' }}>{row.area}</TableCell>
                    <TableCell align="center" className='capital' style={{ border: '1px solid #000000' }}>{row.nombre_evento}</TableCell>
                    <TableCell align="center" style={{ border: '1px solid #000000' }}>{moment(row.start).format("DD/MM/YYYY HH:mm")}</TableCell>
                    <TableCell align="center" style={{ border: '1px solid #000000' }}>{moment(row.end).format("DD/MM/YYYY HH:mm")}</TableCell>
                    <TableCell align="center" className='capital' style={{ border: '1px solid #000000' }}>{row.localidad}</TableCell>
                    <TableCell align="center" style={{ border: '1px solid #000000' }}>{row.presencia_ministro}</TableCell>
                    <TableCell align="center" className='capital' style={{ borderLeft: '1px solid #000000', borderRight: '1px solid #000000', borderBottom: '1px solid #000000' }}>{row.estado}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      }

    </div >
  )
}
