import axios from 'axios'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/sesion`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`

// Server should return AuthModel
export async function login(usuario: string, password: string) {
  return await axios.post(LOGIN_URL, {usuario, password})
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<any>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export const renewToken = (ps: string, idUs: string) => {
  let data: any = {}
  data.data = {
    ps: ps,
    idUs: idUs,
  }

  return axios.post(GET_USER_BY_ACCESSTOKEN_URL, data)
}

export const forgotPasw = (usuario: string, ruta: {}) => {
  let data: any = {}
  data = {
    usr: usuario,
    ruta: ruta,
  }
  return axios.post(REQUEST_PASSWORD_URL, data)
}
