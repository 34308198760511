/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import TextErrorFormik from '../../../../../../components/Formik/TextErrorFormik'
import { useDispatch, useSelector } from 'react-redux'
import { ISTATE } from '../../../../../../../setup/redux/interface/reduxState.interface'
import { evSetFormulario } from '../../../Redux/actions/eventos'

const DatosEvento: FC = () => {
  const dispatch = useDispatch()
  const eventosStore: any = useSelector((state: ISTATE) => state.eventos)
  const { acciones, formulario } = eventosStore
  const estado = formulario.estado
  ////console.log('Datos Evento formulario.estado', formulario)
  // ////console.log('acciones', acciones)
  useEffect(() => {
    //  ////console.log('Datos Evento acciones', acciones)
  }, [acciones])
  const tipoEvento = (tipo: string) => {
    let evTemp = formulario
    evTemp.tipo = tipo
    dispatch(evSetFormulario(evTemp))
  }
  return (
    <div className='w-100'>
      {((acciones.editar || estado === 'cancelado' || estado === 'realizado' || estado === 'en curso' || estado === 'planificado') && !acciones.guardar) && (
        <div>
          <div className='pb-3 pb-lg-10'>
            <h3 className='fw-bolder d-flex align-items-center text-dark'>
              Datos Básicos del Evento
            </h3>
          </div>

          <div className='mb-4 fv-row'>
            <label className='form-label mb-3'>Titulo</label>
            <Field
              type='text'
              className='form-control form-control-sm form-control-solid uppercase'
              name='title'
              disabled={true}
            />
          </div>
          <div className='row'>
            <div className='col-6 mb-4'>
              <label className='form-label'>Descripcion</label>
              <Field
                as='textarea'
                name='descripcion'
                className='form-control form-control-sm form-control-solid uppercase'
                rows={3}
                disabled={true}
              />
            </div>

            <div className='col-6 mb-4'>
              <label className='form-label'>Objetivo</label>
              <Field
                as='textarea'
                name='objetivo'
                className='form-control form-control-sm form-control-solid uppercase'
                rows={3}
                disabled={true}
              />
            </div>
          </div>
          <div className=' mb-4'>
            <label className='form-label'>Actividad</label>
            <Field
              as='textarea'
              name='actividad'
              className='form-control form-control-sm form-control-solid uppercase'
              rows={2}
              disabled={true}
            />
          </div>
          <div className='mb-4 fv-row'>
            <label className='form-label mb-3'>Tipo de Evento</label>
            <Field
              type='text'
              className='form-control form-control-sm form-control-solid uppercase w-25'
              name='tipo'
              disabled={true}
            />
          </div>
        </div>
      )}
      {acciones.guardar && (
        <div>
          <div className='pb-3 pb-lg-10'>
            <h3 className='fw-bolder d-flex align-items-center text-dark'>
              Datos Básicos del Evento
            </h3>
          </div>

          <div className='mb-4 fv-row'>
            <label className='form-label mb-3'>Titulo</label>
            <Field
              type='text'
              className='form-control form-control-sm form-control-solid'
              name='title'
              placeholder='Complete el Título del evento'
            />
            <ErrorMessage name='title' component={TextErrorFormik} />
          </div>
          <div className='row'>
            <div className='col-6 mb-4'>
              <label className='form-label'>Descripcion</label>
              <Field
                as='textarea'
                name='descripcion'
                className='form-control form-control-sm form-control-solid'
                rows={3}
                placeholder='Complete la Descripción del evento'
              />
              <ErrorMessage name='descripcion' component={TextErrorFormik} />
            </div>

            <div className='col-6 mb-4'>
              <label className='form-label'>Objetivo</label>
              <Field
                as='textarea'
                name='objetivo'
                className='form-control form-control-sm form-control-solid'
                rows={3}
                placeholder='Complete la Descripción del evento'
              />
              <ErrorMessage name='objetivo' component={TextErrorFormik} />
            </div>
          </div>
          <div className=' mb-4'>
            <label className='form-label'>Actividad</label>
            <Field
              as='textarea'
              name='actividad'
              className='form-control form-control-sm form-control-solid'
              rows={2}
              placeholder='Complete la Actividad a desarrollar en el evento'
            />
            <ErrorMessage name='actividad' component={TextErrorFormik} />
          </div>
          <div className='mb-4 fv-row'>
            <label className='d-flex align-items-center form-label mb-3'>Tipo de Evento</label>

            <div className='row mb-2' data-kt-buttons='true'>
              <div className='col'>
                <Field
                  type='radio'
                  className='btn-check'
                  checked={formulario.tipo === 'público'}
                  name='tipo'
                  value='público'
                  id='tipo_0'
                  onClick={() => {
                    tipoEvento('público')
                  }}
                />
                <label
                  className='btn btn-sm btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
                  htmlFor='tipo_0'
                >
                  <span className='fw-bolder fs-6'>Público</span>
                </label>
              </div>
              <div className='col'>
                <Field
                  type='radio'
                  className='btn-check'
                  name='tipo'
                  checked={formulario.tipo === 'privado'}
                  value='privado'
                  id='tipo_1'
                  onClick={() => {
                    tipoEvento('privado')
                  }}
                />
                <label
                  className='btn btn-sm btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
                  htmlFor='tipo_1'
                >
                  <span className='fw-bolder fs-6'>Privado</span>
                </label>
              </div>

              <div className='col'>
                <Field
                  type='radio'
                  className='btn-check'
                  name='tipo'
                  checked={formulario.tipo === 'proyecto'}
                  value='proyecto'
                  id='tipo_2'
                  onClick={() => {
                    tipoEvento('proyecto')
                  }}
                />
                <label
                  className='btn btn-sm btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
                  htmlFor='tipo_2'
                >
                  <span className='fw-bolder fs-6'>Proyecto</span>
                </label>
              </div>

              <div className='col'>
                <Field
                  type='radio'
                  className='btn-check'
                  name='tipo'
                  value='capacitación'
                  checked={formulario.tipo === 'capacitación'}
                  id='tipo_3'
                  onClick={() => {
                    tipoEvento('capacitación')
                  }}
                />
                <label
                  className='btn btn-sm btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
                  htmlFor='tipo_3'
                >
                  <span className='fw-bolder fs-6'>Capacitación</span>
                </label>
              </div>
              <div className='col'>
                <Field
                  type='radio'
                  className='btn-check'
                  name='tipo'
                  checked={formulario.tipo === 'programa'}
                  value='programa'
                  id='tipo_4'
                  onClick={() => {
                    tipoEvento('programa')
                  }}
                />
                <label
                  className='btn btn-sm btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
                  htmlFor='tipo_4'
                >
                  <span className='fw-bolder fs-6'>Programa</span>
                </label>
              </div>
            </div>
            <ErrorMessage name='tipo' component={TextErrorFormik} />
          </div>
        </div>
      )}
    </div>
  )
}

export { DatosEvento }
