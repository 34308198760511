export const calTypes = {

  uiOpenModal: '[ui] Open Modal',
  uiCloseModal: '[ui] Close Modal',

  setAreas: '[Set Areas] Action',

  remAreas: '[Set Areas] Action',

  SetVista: '[Set Vista] Action',
  RemVista: '[Set Vista] Action',

  SetEventoActivo: '[evento] Seleccionar Evento Activo',
  SetEventoSeleccionado: '[evento] Seleccionar Evento Formik',
  AddEventoNuevo: '[evento] Agregar un Nuevo Evento',
  GetEventos: '[evento] Cargar Eventos',
  GetEventosFull: '[evento] Cargar Eventos Full',
  SetEvFull: '[evento] Cargar Eventos Full evFull',
  SetEvPub: '[evento] Cargar Eventos Publicos evPub',
  SetEvFiltro: '[evento] Cargar Eventos Filtro evFiltro',
  SetFiltros: '[evento] Cargar Filtros',
  SetFecha: '[evento] Cargar Fecha',
  SetComponente: '[evento] Cargar Componente',
  SetDias: '[evento] Cargar Dias Disponibles',
  SetAcciones: '[evento] Seleccionar Accion',
  SetFormulario: '[evento] Seleccionar SetFormulario',

  cargarSelAreas: '[Set Seleccion Areas] Cargar Areas Seleccionadas',
  seleccionMenu: '[Seleccion Areas Menu] Seleccionar Areas',

}