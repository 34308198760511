import React from 'react'
import {Field, ErrorMessage} from 'formik'
import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'
import Stack from '@mui/material/Stack'
import TextErrorFormik from './TextErrorFormik'
import {registerLocale} from 'react-datepicker'
import es from 'date-fns/locale/es' // the locale you want
import {useDispatch, useSelector} from 'react-redux'
import {ISTATE} from '../../../setup/redux/interface/reduxState.interface'
import {eventosCargarFecha} from '../../modules/apps/Agenda/Redux/actions/eventos'
registerLocale('es', es)

const DateTimePickerFormik: React.FC<{}> = (props: any) => {
  const {label, name, tipofecha} = props

  const dispatch = useDispatch()
  // Tomo los datos del Perfil del Usuario del State
  const dashState: any = useSelector((state: ISTATE) => state.eventos)
  const {fechas} = dashState
  let fecha: any
  if (tipofecha === 'inicio') {
    fecha = new Date()
  } else {
    fecha = fechas[0].fechaInicio
  }
  return (
    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
      <Stack spacing={4}>
        <div>
          <label className='form-label fw-bolder text-dark fs-6'>{label}</label>
          <Field name={name}>
            {(form: any) => {
              const {setFieldValue} = form.form
              const selected = form.field.value

              return (
                <DateTimePicker
                  renderInput={(params: any) => <TextField {...params} />}
                  minDate={fecha}
                  todayText='now'
                  inputFormat='dd/MM/yyyy HH:mm'
                  value={selected}
                  onChange={(date: any) => {
                    setFieldValue(name, date)
                    if (tipofecha === 'inicio') {
                      dispatch(eventosCargarFecha({fechaInicio: fecha}))
                    }
                  }}
                  InputProps={{
                    style: {
                      fontSize: 14,
                      height: 44,
                      width: 246,
                    },
                  }}
                />
              )
            }}
          </Field>

          <ErrorMessage name={name} component={TextErrorFormik} />
        </div>
      </Stack>
    </LocalizationProvider>
  )
}

export default DateTimePickerFormik
