import Swal from 'sweetalert2'
import {imgAvatar} from '../../Sis-admin/components/usuarios/StepsUsuarios/imgUs'
import {postUs} from '../../Sis-admin/services/UsuarioService'
import {modInscripcion} from '../services/EJ.Services'

import moment from 'moment'

let fecha = new Date()
let hoy = fecha.toLocaleDateString()

const crearPas = (valor?: string): string => {
  //)
  let dni: any = valor?.slice(0, 5) //primeros 5 numeros
  let doc = parseInt(dni) //convierto a number
  let psw = `EJ${doc * 4}Nqn**`

  return psw
}

export const apEmpresa = async (emp: any, estado: string, msgMail: string) => {
  let modUsEmp = {
    usuario: emp.cuit,
    primerLogin: true,
    password: crearPas(emp.cuit),
    errPassword: {
      intentos: 0,
      fecha_err: '',
    },
    role: '',
    activo: true,
    fechaAltaUsuario: hoy,
    fechaBajaUsuario: '',
    lastLogin: '',
    datosPersonales: {
      nombres: emp.razon_social,
      apellido: '',
      dni: '',
      cuil: emp.cuit,
      ciudad: emp.localidad,
      provincia: emp.provincia,
      fechaNacimiento: emp.fecha_inicio_actividad,
      sexo: '',
      foto: imgAvatar,
      ID_TRAMITE_PRINCIPAL: '',
    },
    datosContacto: {
      email: emp.email,
      telefono: emp.telefono,
    },
    datosLaborales: {
      servicioPuestoPrincipal: '',
      legajo: emp.cuit, //cuit de la empresa
      fechaAlta: emp.fecha_alta, //fecha de ap en trabajo
      tipoRelacionLaboral: emp.tipo_empresa || '', //categoria
      datosOrganismo: {
        nombre: 'empresas nqn',
        nombreMinistro: 'sin datos',
        datosSistemas: {
          colorLetra: '',
          colorMinisterioA: '',
          colorMinisterioB: '',
        },
        areaPrincipal: {
          idAreaP: '63ca76f277aacd0554afe988',
          nombreArea: 'empleo joven',
          categoriaArea: 'A',
          tipoArea: 'SubSecreatria',
          detalleSistema: {
            icono: '',
            nombreCorto: '',
            colorLetra: '#4A9396',
            colorFondo: '#B6EAF2',
          },
        },
        areaPropia: {
          nombreArea: '',
          idAreaPro: '',
          titularArea: '',
          tipoArea: '',
          categoriaArea: '',
          detalleSistema: {
            icono: '',
            nombreCorto: '',
            colorLetra: '',
            colorFondo: '',
            menusAct: false,
          },
        },
      },
    },
    perfilUs: [
      {
        nombre: 'agenda',
        descripcion: 'ABM de Calendario Actividades del Ministerio',
        paginaInicio: 'agenda/calendario',
        favorito: false,
        menu: [
          {
            name: 'calendario',
            type: 'link',
            tooltip: 'agenda del ministerio',
            icon: '',
            state: 'agenda/calendario',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: false,
              crear: false,
              consulta: true,
              editor: false,
              exportar: false,
              indicadores: false,
            },
          },
          {
            name: 'mi agenda',
            type: 'link',
            tooltip: 'mis eventos',
            icon: '',
            state: 'agenda/calendario/miagenda',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: false,
              crear: false,
              consulta: true,
              editor: false,
              exportar: false,
              indicadores: false,
            },
          },
          {
            name: 'eventos',
            type: 'link',
            tooltip: 'nuevo evento',
            icon: '',
            state: 'agenda/calendario/nuevoevento',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: false,
              crear: false,
              consulta: true,
              editor: false,
              exportar: false,
              indicadores: false,
            },
          },
          {
            name: 'agenda completa',
            type: 'link',
            tooltip: 'agenda completa del ministerio',
            icon: '',
            state: 'agenda/calendario/agendacompleta',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: false,
              crear: false,
              consulta: true,
              editor: false,
              exportar: false,
              indicadores: false,
            },
          },
        ],
        WSmodulo: [],
        acciones: [],
        activo: true,
      },

      {
        nombre: 'Empleo Joven',
        descripcion: 'Administración y gestión del programa empleo joven',
        paginaInicio: 'ej/home',
        favorito: false,
        menu: [
          {
            name: 'mis datos',
            type: 'link',
            tooltip: 'consultas',
            icon: '',
            state: 'ej/misdatos',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: true,
              crear: true,
              consulta: true,
              editor: true,
              exportar: true,
              indicadores: true,
            },
          },

          {
            name: 'mis empleados',
            type: 'link',
            tooltip: 'consultas',
            icon: '',
            state: 'ej/misempleados',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: true,
              crear: true,
              consulta: true,
              editor: true,
              exportar: true,
              indicadores: true,
            },
          },
          {
            name: 'mi empresa mes a mes',
            type: 'link',
            tooltip: 'consultas',
            icon: '',
            state: 'ej/miempxmes',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: true,
              crear: true,
              consulta: true,
              editor: true,
              exportar: true,
              indicadores: true,
            },
          },
          {
            name: 'mis certificados',
            type: 'link',
            tooltip: 'certificados aprobados por centro pyme',
            icon: '',
            state: 'ej/miscertificados',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: false,
              crear: false,
              consulta: false,
              editor: false,
              exportar: false,
              indicadores: false,
            },
          },
        ],
        WSmodulo: [],
        acciones: [],
        activo: true,
      },
    ],
    tipoUs: {
      appMovil: false,
      appWeb: true,
    },
  }
  return new Promise((resolve, reject) => {
    postUs(modUsEmp).then(async (res: any) => {
      // console.log(res)
      if (res.data.msg === 'usr existe') {
        modInscripcion(estado, emp.cuit, '', msgMail).then(async (res: any) => {
          if (res.data.ok) {
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Modifico el estado de la Empresa ',
              text: `La empresa se encuentra habilitada para ratificar documentación.`,
              showConfirmButton: false,
              timer: 5500,
            })
            resolve('Usuario Creado')
          } else {
            await Swal.fire({
              position: 'center',
              title: 'Error!',
              text: 'Verificar los datos ingresados',
              icon: 'error',
              confirmButtonText: 'Volver',
            })
            reject('Algo paso')
          }
        })
      } else {
        modInscripcion(estado, emp.cuit, '', msgMail).then(async (res: any) => {
          if (res.data.ok) {
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'La empresa, fue aprobada dada de alta en el programa Empleo Joven.',
              text: `El password generado es ${modUsEmp.password}`,
              showConfirmButton: false,
              timer: 5500,
            })
            resolve('Usuario Creado')
          } else {
            await Swal.fire({
              position: 'center',
              title: 'Error!',
              text: 'Verificar los datos ingresados',
              icon: 'error',
              confirmButtonText: 'Volver',
            })
            reject('Algo paso')
          }
        })
      }
    })
  })
}

export const apEmprendedor = async (emprendedor: any) => {
  let modUsEmp = {
    usuario: emprendedor.cuil,
    password: crearPas(emprendedor?.cuil),
    primerLogin: true,
    errPassword: {
      intentos: 0,
      fecha_err: '',
    },
    role: '',
    activo: true,
    fechaAltaUsuario: hoy,
    fechaBajaUsuario: '',
    lastLogin: '',
    datosPersonales: {
      nombres: emprendedor?.nombres,
      apellido: '',
      dni: '',
      cuil: emprendedor?.cuil,
      ciudad: emprendedor?.localidad,
      provincia: emprendedor?.provincia,
      fechaNacimiento: emprendedor?.fecha_nacimiento,
      sexo: '',
      foto: imgAvatar,
      ID_TRAMITE_PRINCIPAL: '',
    },
    datosContacto: {
      email: emprendedor?.email,
      telefono: emprendedor?.telefono,
    },
    datosLaborales: {
      servicioPuestoPrincipal: '',
      legajo: emprendedor?.cuil, //cuit de la empresa
      fechaAlta: moment(emprendedor?.fecha_inicio_actividad).format('DD/MM/YYYY'), //fecha de ap en trabajo
      tipoRelacionLaboral: '', //categoria
      datosOrganismo: {
        nombre: 'emprendedor nqn',
        nombreMinistro: 'sin datos',
        datosSistemas: {
          colorLetra: '',
          colorMinisterioA: '',
          colorMinisterioB: '',
        },
        areaPrincipal: {
          idAreaP: '63ca76f277aacd0554afe988',
          nombreArea: 'empleo joven',
          categoriaArea: 'A',
          tipoArea: 'SubSecreatria',
          detalleSistema: {
            icono: '',
            nombreCorto: '',
            colorLetra: '#4A9396',
            colorFondo: '#B6EAF2',
          },
        },
        areaPropia: {
          nombreArea: '',
          idAreaPro: '',
          titularArea: '',
          tipoArea: '',
          categoriaArea: '',
          detalleSistema: {
            icono: '',
            nombreCorto: '',
            colorLetra: '',
            colorFondo: '',
            menusAct: false,
          },
        },
      },
    },
    perfilUs: [
      {
        nombre: 'agenda',
        descripcion: 'ABM de Calendario Actividades del Ministerio',
        paginaInicio: 'agenda/calendario',
        favorito: false,
        menu: [
          {
            name: 'calendario',
            type: 'link',
            tooltip: 'agenda del ministerio',
            icon: '',
            state: 'agenda/calendario',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: false,
              crear: false,
              consulta: true,
              editor: false,
              exportar: false,
              indicadores: false,
            },
          },
          {
            name: 'mi agenda',
            type: 'link',
            tooltip: 'mis eventos',
            icon: '',
            state: 'agenda/calendario/miagenda',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: false,
              crear: false,
              consulta: true,
              editor: false,
              exportar: false,
              indicadores: false,
            },
          },
          {
            name: 'eventos',
            type: 'link',
            tooltip: 'nuevo evento',
            icon: '',
            state: 'agenda/calendario/nuevoevento',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: false,
              crear: false,
              consulta: true,
              editor: false,
              exportar: false,
              indicadores: false,
            },
          },
          {
            name: 'agenda completa',
            type: 'link',
            tooltip: 'agenda completa del ministerio',
            icon: '',
            state: 'agenda/calendario/agendacompleta',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: false,
              crear: false,
              consulta: true,
              editor: false,
              exportar: false,
              indicadores: false,
            },
          },
        ],
        WSmodulo: [],
        acciones: [],
        activo: true,
      },
      {
        nombre: 'Empleo Joven',
        descripcion: 'Administración y gestión del programa empleo joven',
        paginaInicio: 'ej/home',
        favorito: false,
        menu: [
          {
            name: 'mis datos',
            type: 'link',
            tooltip: 'consultas',
            icon: '',
            state: 'ej/misdatos',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: true,
              crear: true,
              consulta: true,
              editor: true,
              exportar: true,
              indicadores: true,
            },
          },
          {
            name: 'mis documentos',
            type: 'link',
            tooltip: 'consultas',
            icon: '',
            state: 'ej/misdocumentos',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: true,
              crear: true,
              consulta: true,
              editor: true,
              exportar: true,
              indicadores: true,
            },
          },
          {
            name: 'mis certificados',
            type: 'link',
            tooltip: 'certificados aprobados por centro pyme',
            icon: '',
            state: 'ej/miscertificados',
            disabled: false,
            level: 0,
            expandable: false,
            children: [],
            roleUs: {
              admin: false,
              crear: false,
              consulta: false,
              editor: false,
              exportar: false,
              indicadores: false,
            },
          },
        ],
        WSmodulo: [],
        acciones: [],
        activo: true,
      },
    ],
    tipoUs: {
      appMovil: false,
      appWeb: true,
    },
  }
  //console.log(modUsEmp)
  return new Promise((resolve, reject) => {
    postUs(modUsEmp).then(async (res: any) => {
      if (res.data.ok) {
        modInscripcion('aprobado', '', emprendedor?.cuil).then(async (res: any) => {
          if (res.data.ok) {
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'El emprendedor, fue dado de alta en el programa Empleo Joven.',
              text: `El password generado es ${modUsEmp.password}`,
              showConfirmButton: false,
              timer: 5500,
            })
            resolve('Usuario Creado')
          } else {
            await Swal.fire({
              position: 'center',
              title: 'Error!',
              text: 'Verificar los datos ingresados',
              icon: 'error',
              confirmButtonText: 'Volver',
            })
            reject('Algo paso')
          }
        })
      }
    })
  })
}
