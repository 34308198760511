import React, {useEffect, useState} from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ITablaBens} from '../../interfaces/ITablaBens'
import {downloadHacienda, getFechaHacienda, TokenFind} from '../../services/Bens-Services'
import {getLayout, ILayout, LayoutSetup, useLayout} from '../../../../../../_metronic/layout/core'
import {FormLabel} from '@mui/material'
moment.locale('es-mx')

const TablaDescarga: React.FC<ITablaBens> = ({className, headColumns, tituloMenu}) => {
  const {setLayout} = useLayout()

  const [FechaDesde, setFechaDesde] = useState('')
  const [FechaHasta, setFechaHasta] = useState('')
  const [tabArray, setTabArray] = useState(false)
  const [dataArray, setDataArray] = useState<any>([])

  //Estados y constantes Paginado
  const [activePage, setActivePage] = useState(1)
  const rowsPerPage = 5
  const count = dataArray.length
  const totalPages = Math.ceil(count / rowsPerPage)
  const calculatedRows = dataArray.slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)
  const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1
  const end = activePage === totalPages ? count : beginning + rowsPerPage - 1
  //Loading congfig ''
  const [config, setConfig] = useState<ILayout>(getLayout())
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  //BensToken
  const tokenBens = sessionStorage.getItem('BensToken')

  //Envio de fechas
  const sendFecha = (FechaDesde: any, FechaHasta: any) => {
    setConfigLoading(true)
    try {
      LayoutSetup.setConfig(config)
    } catch (error) {
      setConfig(getLayout())
    }
    const sendByFecha = {
      data: {
        fechaDesde: FechaDesde,
        fechaHasta: FechaHasta,
        tokenUs: tokenBens,
      },
    }
    //getFecha WebServices
    getFechaHacienda(sendByFecha)
      .then(async (res: any) => {
        let arrayDescargas = res.data
        if (res.data.ok === true) {
          setTimeout(() => {
            setLayout(config)
            setConfigLoading(false)
          }, 1000)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: '¡Se Descargaron las Inscripciones!',
            showConfirmButton: false,
            timer: 2000,
          })
          setTabArray(true)
          setDataArray(arrayDescargas.data)
        } else {
          setConfigLoading(false)
          Swal.fire({
            position: 'center',
            icon: 'info',
            title: arrayDescargas.msg,
            showConfirmButton: false,
            timer: 2000,
          })
        }
      })
      .catch((res: any) => {
        setConfigLoading(false)
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: 'Temporalmente sin servicio',
          showConfirmButton: false,
          timer: 2000,
        })
      })
  }

  const onChangeDateDesde = (date: any) => {
    const newDate = moment(date.target.value).format('DD-MM-YYYY')
    setFechaDesde(newDate)
  }
  const onChangeDateHasta = (date: any) => {
    const newDate = moment(date.target.value).format('DD-MM-YYYY')
    setFechaHasta(newDate)
  }

  return (
    <>
      <div className={`card ${className}`}>
        <div className='card-header border-0 pt-3 pb-3'>
          <div className='fv-row'>
            <div className='row'>
              <div className='col-lg-5 col-5'>
                <input type='date' className='form-control' onChange={onChangeDateDesde} />
              </div>
              <div className='col-lg-5 col-5'>
                <input type='date' className='form-control' onChange={onChangeDateHasta} />
              </div>
              <div className='col-lg-1 col-2'>
                {!configLoading && (
                  <button
                    className='btn btn-icon btn-primary'
                    onClick={() => {
                      sendFecha(FechaDesde, FechaHasta)
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-3' />
                  </button>
                )}
                {configLoading && (
                  <button className='btn btn-primary me-2'>
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {tabArray ? (
        <div className={`card ${className}`}>
          <div className='card-body py-3'>
            <div className='table'>
              <table className='table align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder text-muted bg-light'>
                    <div className=''>
                      <div className='row pb-0'>
                        <div className='col-1'>
                          <th className='fw-bolder text-dark'>{headColumns?.col2}</th>
                        </div>
                        <div className='col-2'>
                          <th className='fw-bolder text-dark'>{headColumns?.col3}</th>
                        </div>
                        <div className='col-3'>
                          <th className='fw-bolder text-dark'>{headColumns?.col4}</th>
                        </div>
                        <div className='col-3'>
                          <th className='fw-bolder text-dark'>{headColumns?.col5}</th>
                        </div>
                        <div className='col-2'>
                          <th className='fw-bolder text-dark'> Estado</th>
                        </div>
                      </div>
                    </div>
                  </tr>
                </thead>
                <tbody>
                  {calculatedRows?.map((values: any) => (
                    <tr key={values._id}>
                      <div className='row py-0'>
                        <div className='col-1 altura-tabla'>
                          <td>
                            <div className='d-flex justify-content-start flex-column'>
                              <a className='text-dark text-hover-primary fs-6'>
                                {values.id_tramite}
                              </a>
                            </div>
                          </td>
                        </div>
                        <div className='col-2 altura-tabla'>
                          <td>
                            <div className='d-flex justify-content-start flex-column'>
                              <a className='text-dark text-hover-primary fs-6'>{values.dni}</a>
                            </div>
                          </td>
                        </div>
                        <div className='col-3 altura-tabla'>
                          <td>
                            <div className='d-flex justify-content-start flex-column'>
                              <a className='text-dark text-hover-primary fs-6'>{values.apellido}</a>
                            </div>
                          </td>
                        </div>
                        <div className='col-3 altura-tabla'>
                          <td>
                            <div className='d-flex justify-content-start flex-column'>
                              <a className='text-dark text-hover-primary fs-6'>{values.nombre}</a>
                            </div>
                          </td>
                        </div>
                        <div className='col-2 altura-tabla'>
                          <td>
                            <div className='d-flex justify-content-start flex-column'>
                              {values.estado === 'Aprobado' ? (
                                <a className='badge badge-light-success'>{values.estado}</a>
                              ) : (
                                <a className='badge badge-light-warning'>{values.estado}</a>
                              )}
                            </div>
                          </td>
                        </div>
                      </div>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='row pag-cen-tabla'>
              <div className='mt-5 col-2'></div>
              <ul className='pagination mt-3 col-8'>
                <li className='page-item disabled'>
                  <button
                    className='btn btn-sm btn-light-primary'
                    disabled={activePage === 1}
                    onClick={() => setActivePage(1)}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-3' />{' '}
                    Primero
                  </button>
                </li>
                <li className='page-item '>
                  <button
                    className='btn btn-sm btn-light-primary'
                    disabled={activePage === 1}
                    onClick={() => setActivePage(activePage - 1)}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr022.svg' className='svg-icon-3' />
                    Anterior
                  </button>
                </li>
                <li className='page-item '>
                  <button
                    className='btn btn-sm btn-light-primary'
                    disabled={activePage === totalPages}
                    onClick={() => setActivePage(activePage + 1)}
                  >
                    Siguiente{' '}
                    <KTSVG path='/media/icons/duotune/arrows/arr071.svg' className='svg-icon-3' />
                  </button>
                </li>

                <button
                  className='btn btn-sm btn-light-primary'
                  disabled={activePage === totalPages}
                  onClick={() => setActivePage(totalPages)}
                >
                  Último{' '}
                  <KTSVG path='/media/icons/duotune/arrows/arr080.svg' className='svg-icon-3' />
                </button>
              </ul>
              <div className='mt-5 col-2'>
                <span>Página {activePage} </span>
                de <span>{totalPages}</span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
export {TablaDescarga}
