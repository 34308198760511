import FormikControl from '../../../../../../components/Formik/FormikControl'

const Step02Area = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Complete los datos.
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Es necesario contar con los datos del titular del area.
        </div>
      </div>

      <div className='fv-row '>
        <div className='row justify-content-center'>
          <div className='col-lg-4'>
            <FormikControl
              label='Nombre'
              control='input'
              name='datosTitular.nombre'
              placeholder='Nombre'
              className='form-control form-control-solid'
            />
          </div>
          <div className='col-lg-4'>
            <FormikControl
              label='Apellido'
              control='input'
              name='datosTitular.apellido'
              placeholder='Apellido'
              className='form-control form-control-solid'
            />
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-lg-4'>
            <FormikControl
              label='Email'
              control='input'
              name='datosTitular.email'
              placeholder='Email'
              className='form-control form-control-solid'
            />
          </div>
          <div className='col-lg-4'>
            <FormikControl
              label='Nro:.Telefono'
              control='input'
              name='datosTitular.telefono'
              placeholder='Telefono'
              className='form-control form-control-solid'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step02Area
