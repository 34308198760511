import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ISTATE } from '../../../../../../setup/redux/interface/reduxState.interface'
import { evCargarFiltro } from '../../Redux/actions/eventos'
import { BotonAgenda } from './BotonAgenda'

type Props = {
  compActivo: any
}

const BotoneraCalendario: React.FC<Props> = ({ compActivo }) => {


  return (

    <div className='card-toolbar'>
      {/* begin::Botones del Menu */}

      {compActivo !== "calendario" ? (
        <BotonAgenda pag_inicio={'/agenda/calendario'} nombre='Calendario' imagen='/general/gen014.svg' colorFondo='btn-active-light-primary' componenteActivo={compActivo} />
      ) : <BotonAgenda pag_inicio={'/agenda/calendario'} nombre='Calendario' imagen='/general/gen014.svg' colorFondo='btn-primary' componenteActivo={compActivo} />}
      {compActivo !== "nuevoevento" ? (
        <BotonAgenda pag_inicio={'/agenda/calendario/nuevoevento'} nombre='Nuevo evento' imagen='/general/gen035.svg' colorFondo='btn-active-light-success' componenteActivo={compActivo} />) : (
        <BotonAgenda pag_inicio={'/agenda/calendario/nuevoevento'} nombre='Nuevo evento' imagen='/general/gen035.svg' colorFondo='btn-success' componenteActivo={compActivo} />
      )}
      {compActivo !== "indicadores" ? (
        <BotonAgenda pag_inicio={'/agenda/calendario/indicadores'} nombre='Indicadores' imagen='/general/gen032.svg' colorFondo='btn-active-light-warning' componenteActivo={compActivo} />) : (
        <BotonAgenda pag_inicio={'/agenda/calendario/indicadores'} nombre='Indicadores' imagen='/general/gen032.svg' colorFondo='btn-warning' componenteActivo={compActivo} />
      )}
      {compActivo !== "agendacompleta" ? (
        <BotonAgenda pag_inicio={'/agenda/calendario/agendacompleta'} nombre='Agenda Completa' imagen='/files/fil002.svg' colorFondo='btn-active-light-info' componenteActivo={compActivo} />) : (
        <BotonAgenda pag_inicio={'/agenda/calendario/agendacompleta'} nombre='Agenda Completa' imagen='/files/fil002.svg' colorFondo='btn-info' componenteActivo={compActivo} />
      )}
      {compActivo !== "miagenda" ? (
        <BotonAgenda pag_inicio={'/agenda/calendario/miagenda'} nombre='Ver mi agenda' imagen='/communication/com005.svg' colorFondo='btn-active-light-danger' componenteActivo={compActivo} />) : (
        <BotonAgenda pag_inicio={'/agenda/calendario/miagenda'} nombre='Ver mi agenda' imagen='/communication/com005.svg' colorFondo='btn-danger' componenteActivo={compActivo} />
      )}
      {/* end::Botones del Menu */}
    </div>

  )
}

export default BotoneraCalendario