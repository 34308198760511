import * as Yup from 'yup'

export const validateSchemaDatosPersonales = Yup.object({
  datosPersonales: Yup.object({
    dni: Yup.string()
      .min(7, 'Caracteres minimos: 7')
      .max(8, 'Caracteres maximos: 8')
      .required('El campo es requerido'),
    nombre: Yup.string().min(1).required('El campo es requerido'),
    apellido: Yup.string().required('El campo es requerido'),
    domicilio: Yup.string().required('El campo es requerido'),
    localidad: Yup.string().required('El campo es requerido'),
    fechaNacimiento: Yup.string().required('El campo es requerido'),
    nacionalidad: Yup.string().required('El campo es requerido'),
    sexo: Yup.string().required('El campo es requerido'),
    mail: Yup.string(),
    telefono: Yup.string().required('El campo es requerido'),
    tipoDoc: Yup.string().required('El campo es requerido'),
    cuit: Yup.string().required('El campo es requerido'),
    codigoPostal: Yup.string().required('El campo es requerido'),
    departamento: Yup.string().required('El campo es requerido'),
    tipoResidencia: Yup.string().required('El campo es requerido'),
    // fechaVencimientoResidencia: Yup.string().required('El campo es requerido'),
    estadoCivil: Yup.string().min(1).required('El campo es requerido'),
    tipoNacionalidad: Yup.string().min(1).required('El campo es requerido'),
    vehiculoLey: Yup.string().required('El campo es requerido'),
    fechaVehiculo: Yup.string().when('vehiculoLey', {
      is: 'si',
      then: Yup.string().required('Ingrese Fecha de inicio'),
    }),
    derechoAsignacionesF: Yup.string().required('El campo es requerido'),

    simboloInternacional: Yup.string().required('El campo es requerido'),
  }),
})

// export const validateSchemaDatosTutor = Yup.object().shape({
//   datosTutor: Yup.object().shape({
//     dni: Yup.string()
//       .min(7, 'Caracteres minimos: 7')
//       .max(8, 'Caracteres maximos: 8')
//       .required('El campo es requerido'),
//     nombre: Yup.string().required('El campo es requerido'),
//     tipoPariente: Yup.string().required('El campo es requerido'),
//     apellido: Yup.string().required('El campo es requerido'),
//     domicilio: Yup.string().required('El campo es requerido'),
//     localidad: Yup.string().required('El campo es requerido'),
//     fechaNacimiento: Yup.string().required('El campo es requerido'),
//     sexo: Yup.string().required('El campo es requerido'),
//     mail: Yup.string().email('Completar el formato'),
//     nacionalidad: Yup.string().required('El campo es requerido'),
//     telefono: Yup.string().required('El campo es requerido'),
//     codigoPostal: Yup.string().required('El campo es requerido'),
//     tipoDoc: Yup.string().required('El campo es requerido'),
//     tipoTutor: Yup.string().when('tipoPariente', {
//       is: 'tutor designado judicialmente',
//       then: Yup.string().required('El campo es requerido'),
//     }),
//     fechaDesignacionTutor: Yup.string().when('tipoPariente', {
//       is: 'tutor designado judicialmente',
//       then: Yup.string().required('El campo es requerido'),
//     }),
//     juzgado: Yup.string().when('tipoPariente', {
//       is: 'tutor designado judicialmente',
//       then: Yup.string().required('El campo es requerido'),
//     }),
//     secretaria: Yup.string().when('tipoPariente', {
//       is: 'tutor designado judicialmente',
//       then: Yup.string().required('El campo es requerido'),
//     }),
//     dptoJudicial: Yup.string().when('tipoPariente', {
//       is: 'tutor designado judicialmente',
//       then: Yup.string().required('El campo es requerido'),
//     }),
//     fiscalia: Yup.string().when('tipoPariente', {
//       is: 'tutor designado judicialmente',
//       then: Yup.string().required('El campo es requerido'),
//     }),
//     defensoria: Yup.string().when('tipoPariente', {
//       is: 'tutor designado judicialmente',
//       then: Yup.string().required('El campo es requerido'),
//     }),
//     anios: Yup.string(),
//     interesadoApoyoJudicial: Yup.string().when('anios', {
//       is: 'false',
//       then: Yup.string().required('El campo es requerido'),
//     }),
//     interesadoApoyoExtraJudicial: Yup.string().when('anios', {
//       is: 'false',
//       then: Yup.string().required('El campo es requerido'),
//     }),
//     curadorDesignadoJudicial: Yup.string().when('anios', {
//       is: 'false',
//       then: Yup.string().required('El campo es requerido'),
//     }),
//   }),
// })

export interface Ilegajo {
  legajo: string
  datosPersonales: {
    tipoDoc: string
    dni: string
    cuit: string
    apellido: string
    nombre: string
    sexo: string
    fechaNacimiento: string
    localidad: string
    domicilio: string
    mail: string
    telefono: string
    codigoPostal: string
    departamento: string
    tipoResidencia: string
    fechaVencimientoResidencia: string
    estadoCivil: string
    nacionalidad: string
    tipoNacionalidad: string //
    vehiculoLey: string
    fechaVehiculo: string
    simboloInternacional: string
    derechoAsignacionesF: string
    menor: boolean
    empleadoPublico: boolean
    hasATutor: boolean
  }
  datosTutor?: {
    dni: string
    nombre: string
    apellido: string
    domicilio: string
    localidad: string
    fechaNacimiento: string
    sexo: string
    mail: string
    nacionalidad: string
    telefono: string
    parentezco: string
    codigoPostal: string
    tipoDoc: string
    tipoTutor: string
    fechaDesignacionTutor: string
    juzgado: string
    secretaria: string
    dptoJudicial: string
    fiscalia: string
    defensoria: string
    interesadoApoyoJudicial: string
    interesadoApoyoExtraJudicial: string
    curadorDesignadoJudicial: string
  }
  datosCud?: [
    {
      estado: string
      fechaInicio: string
      fechaCierre: string
      observacion: string
      infoCertificado: {
        fechaVencimiento: string
        nroCud: string
        fechaEntregaCert: string
        estadoCertificado: string
      }
      problemas_origenes: {
        // (DONE)
        motor: boolean
        mental: boolean
        auditivo: boolean
        visual: boolean
        visceral: boolean
        intelectual: boolean
      }
      cie10: [string]
    }
  ]

  observaciones?: string
  documentacion: {
    cudAnterior: boolean
    certificadoMedico: boolean
    estudiosComplem: boolean
    partidaNac: boolean
    extravioCud: boolean
    historiaClinica: boolean
    copiaDni: boolean
    supervivencia: boolean
    informes: boolean
    planillaMedica: boolean
    copiaOSocial: boolean
    reciboPension: boolean
    curatela: boolean
  }
  problemas_origenes: {
    motor: false
    mental: false
    auditivo: false
    visual: false
    visceral: false
    intelectual: false
  }

  estado?: string
  fecha: string
  junta: string
  usuarioCreacion: String
  fechaCreacion: String
  usuarioModificacion: String
  fechaModificacion: String
  dni: String
}

export interface Icie10 {
  label: {type: String; require: true}
  value: {type: String; require: true}
}
export interface Icie10s extends Array<Icie10> {}
export interface Ilegajos extends Array<Ilegajo> {}
