import React, { useState } from 'react'
import moment from 'moment'

import 'moment/locale/es'
import { useSelector } from 'react-redux'
import { ISTATE } from '../../../../../setup/redux/interface/reduxState.interface'
import { IEventos } from '../pages/CrearEventoPage/Steps/EventoData'

moment.locale('es')

type Props = {
  evento: IEventos
}

const Evento: React.FC<Props> = ({ evento }) => {
  const eventosStore: any = useSelector((state: ISTATE) => state.eventos)
  const [mostrar, setMostrar] = useState(false)
  const [dirLatLon, setDirLatLon] = useState<any>({ lat: '-38.9512722', lon: '-68.0578955' })
  const [localizado, setLocalizado] = useState(false)
  const { dias } = eventosStore
  let dia = ''
  let inicio = ''
  let fin = ''
  let tipoDuracion = ''
  let diaInicio = ''
  let horaInicio = ''
  let diaFin = ''
  let horaFin = ''
  let destinatarios = ''

  /*  useEffect(() => {
     const googleMapScript = loadMapApi()
     googleMapScript.addEventListener('load', function () {
 
 
 
       setMostrar(true)
     })
   }, [dirLatLon]) */

  /* if (evento.geom.latitud !== '-38.9512722' && evento.geom.longitud !== '-68.0578955') {
    setLocalizado(true)
  } */
  /*  useEffect(() => {
     setDirLatLon({ lat: evento.geom.latitud, lon: evento.geom.longitud })
   }, [localizado]) */

  ////////console.log('evento.tiempo.length', evento.tiempo.length)
  switch (evento.duracion) {
    case 'diario':
      dia = moment(evento.start).format('LL').toString()
      inicio = moment(evento.start).format('HH:mm').toString()
      fin = moment(evento.end).format('HH:mm').toString()
      tipoDuracion = `Es un evento Diario, a realizarse el día ${dia},  comenzando a las ${inicio} hs y finalizando aproximadamente a las ${fin} hs.`
      break
    case 'varios':
      diaInicio = moment(evento.start).format('LL').toString()
      horaInicio = moment(evento.start).format('HH:mm').toString()
      diaFin = moment(evento.end).format('LL').toString()
      horaFin = moment(evento.end).format('HH:mm').toString()
      tipoDuracion = `Varios días consecutivos, comenzando el día ${diaInicio} a las ${horaInicio} hs y finalizando el día ${diaFin} aproximadamente a las ${horaFin} hs.`
      break
    /* case 'Temporal':
      let diasImp = ''
      let final = evento.tiempo.length - 1
      diaInicio = moment(evento.tiempo[0].inicio).format('LL').toString()
      diaFin = moment(evento.tiempo[final].fin).format('LL').toString()
      let diasEvento: any = []
      if (dias.lun) {
        diasEvento.push('Lunes')
      }
      if (dias.mar) {
        diasEvento.push('Martes')
      }
      if (dias.mie) {
        diasEvento.push('Miércoles')
      }
      if (dias.jue) {
        diasEvento.push('Jueves')
      }
      if (dias.vie) {
        diasEvento.push('Viernes')
      }
      if (dias.sab) {
        diasEvento.push('Sábados')
      }
      if (dias.dom) {
        diasEvento.push('Domingos')
      }
      for (let d = 0; d < diasEvento.length; d++) {
        const e = diasEvento[d]
        diasImp = diasImp + ` ${e}`
        let dif = d + 2
        if (dif === diasEvento.length) {
          diasImp = diasImp + ` y`
        } else {
          diasImp = diasImp + `,`
        }
      }
      tipoDuracion = `Temporal, comenzando el día ${diaInicio} y finalizando el día ${diaFin}. Los dias en que se realizara el evento son todos los${diasImp} entre las fechas mencionadas.`
      break */
    default:
      break
  }

  if (evento.destinatarios.length > 1) {
    for (let d = 0; d < evento.destinatarios.length; d++) {
      const destinatarioTemp = evento.destinatarios[d]
      if (evento.destinatarios.length === 1) {
        destinatarios = destinatarioTemp.destinatario
      }
      destinatarios = destinatarios + ' ' + destinatarioTemp.destinatario
    }
  } else {
    destinatarios = evento.destinatarios[0].destinatario
  }
  // ////console.log('evento', evento)
  return (
    <div className='card mb-5 mb-xl-10 border' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0 capital'>Evento: {evento.title}</h3>
        </div>
      </div>

      <div className='card-body sombras p-9 border-bottom'>
        <div className='row mb-5'>
          <label className='col-lg-3 fw-bold text-muted'>Descripcion</label>

          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark capital'>{evento.descripcion}</span>
          </div>
        </div>

        <div className='row mb-5'>
          <label className='col-lg-3 fw-bold text-muted'>Objetivo</label>

          <div className='col-lg-9 fv-row'>
            <span className='fw-bold fs-6 capital'>{evento.objetivo}</span>
          </div>
        </div>
        <div className='row mb-5'>
          <label className='col-lg-3 fw-bold text-muted'>Actividad</label>

          <div className='col-lg-9 fv-row'>
            <span className='fw-bold fs-6 capital'>{evento.actividad}</span>
          </div>
        </div>
        <div className='row mb-5'>
          <label className='col-lg-3 fw-bold text-muted'>Tipo de Evento</label>

          <div className='col-lg-9 fv-row'>
            <span className='fw-bold fs-6 capital'>{evento.duracion}</span>
          </div>
        </div>
      </div>
      <div className='card-body sombras p-9 border-bottom'>
        <div className='row mb-5'>
          <label className='col-lg-3 fw-bold text-muted'>Duración del evento</label>

          <div className='col-lg-9'>
            <div className='col'>
              <span className=' fw-bolder fs-6 text-dark'>{tipoDuracion}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='card-body sombras p-9 border-bottom'>
        <div className='row mb-5'>
          <label className='col-lg-3 fw-bold text-muted'>Propietario del Evento</label>

          <div className='col-lg-9 fv-row'>
            <span className='fw-bold fs-6 capital'>{evento.usuarioAsignado}</span>
          </div>
        </div>
        <div className='row mb-5'>
          <label className='col-lg-3 fw-bold text-muted'>Área</label>

          <div className='col-lg-9 fv-row'>
            <span className='fw-bold fs-6 capital'>{evento.area}</span>
          </div>
        </div>

        <div className='row mb-5'>
          <label className='col-lg-3 fw-bold text-muted'>Destinatarios</label>

          <div className='col-lg-9 fv-row'>
            <span className='fw-bold fs-6 capital'>{destinatarios}</span>
          </div>
        </div>
        <div className='row mb-5'>
          <label className='col-lg-3 fw-bold text-muted'>Presencia del Ministro/a</label>

          <div className='col-lg-9 fv-row'>
            <span className='fw-bold fs-6 capital'>{evento.presMinistro}</span>
          </div>
        </div>
      </div>
      <div className='card-body sombras p-9 border-bottom'>
        <div className='row mb-5'>
          <label className='col-lg-3 fw-bold text-muted'>Lugar</label>

          <div className='col-lg-9 fv-row'>
            <span className='fw-bold fs-6 capital'>{evento.lugar}</span>
          </div>
        </div>
        <div className='row mb-5'>
          <label className='col-lg-3 fw-bold text-muted'>Calle</label>

          <div className='col-lg-3 fv-row'>
            <span className='fw-bold fs-6 capital'>{evento.calle}</span>
          </div>
          <label className='col-lg-3 fw-bold text-muted'>Número</label>

          <div className='col-lg-3 fv-row'>
            <span className='fw-bold fs-6 capital'>{evento.numero}</span>
          </div>
        </div>
        <div className='row mb-5'>
          <label className='col-lg-3 fw-bold text-muted'>Localidad</label>

          <div className='col-lg-3 fv-row'>
            <span className='fw-bold fs-6 capital'>{evento.localidad}</span>
          </div>
          <label className='col-lg-3 fw-bold text-muted'>Provincia</label>

          <div className='col-lg-3 fv-row'>
            <span className='fw-bold fs-6 capital'>{evento.provincia}</span>
          </div>
        </div>
        <div className='row mb-5'>
          <label className='col-lg-3 fw-bold text-muted'>Lat</label>

          <div className='col-lg-3 fv-row'>
            <span className='fw-bold fs-6 capital'>{evento.geom.latitud}</span>
          </div>
          <label className='col-lg-3 fw-bold text-muted'>Long</label>

          <div className='col-lg-3 fv-row'>
            <span className='fw-bold fs-6 capital'>{evento.geom.longitud}</span>
          </div>
        </div>

        {/*  <div className='mb-5 fv-row align-items-center '>
          {mostrar && (
            <div className='googlemap-container'>
              <MapaGoogle
                mapType={google.maps.MapTypeId.ROADMAP}
                mapTypeControl={true}
                geo={dirLatLon}
              />
            </div>
          )}
        </div> */}
      </div>
    </div>
  )
}
export default Evento
