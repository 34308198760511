//FUNCION PARA CAMBIAR EL FORMATO DD/MM/YYYY QUE VIENE EN DB A YYYY-MM-DD QUE ACEPTA DATEPICKER
export const changedateformat = (val: string)  =>{
    let formatteddate: string = val;
    if (val.includes('/')) {
      let myArray = val.split('/');
      let year = myArray[2];
      let month = myArray[1];
      let day = myArray[0];
      formatteddate = year + '-' + month + '-' + day;
    }
    return formatteddate
  }

//FUNCION PARA CAMBIAR EL FORMATO DEL DATEPICKER YYYY-MM-DD AL FORMATO A GUARDAR EN DB DD/MM/YYYY
 export const unformatdate = (val: string) => {
    let formatteddate: string = val;
    if (val.includes('-')) {
      let myArray = val.split('-');
      let year = myArray[0];
      let month = myArray[1];
      let day = myArray[2];
      formatteddate = day + '/' + month + '/' + year;
    }
    return formatteddate
  }