import * as Yup from 'yup'

export interface IEventos {
  _id?: string
  idEvento: number
  title: string
  descripcion: string
  objetivo: string
  conclusion: string
  idUsuarioCarga: string
  usuarioCarga: string
  actividad: string
  presMinistro: boolean
  eje: string
  eje_detalle: string
  eje_tipo: string
  color: string
  idUsuarioAsignado: string
  usuarioAsignado: string
  area: string
  idArea: string
  organizacion: string
  /* colaboradores: [
    {
      nombre: string
      area: string
      organizacion: string
    }
  ] */
  tipo: string
  duracion: string
  horariosDiferentes: boolean
  start: Date
  end: Date
  estado: string
  lugar: string
  calle: string
  numero: string
  localidad: string
  provincia: string
  pais: string
  geom: {
    latitud: number
    longitud: number
  }
  destinatarios: [
    {
      destinatario: string
      idDestinatario: string
    }
  ]
  programa: string
  idPrograma: string
  /*  orgParticipantes: [{
     nombre: string
     direccion: string
     cuit: string
     tipo: string
   }] */
}

const eventoSchemas = [
  Yup.object({
    title: Yup.string().required('El Título es requerido.').label('Titulo del Evento'),
    descripcion: Yup.string()
      .label('Descripción del Evento'),
    objetivo: Yup.string().label('Objetivo del Evento'),
    actividad: Yup.string().label('Actividad del Evento'),
    tipo: Yup.string().required('El Tipo de Evento es requerido.').label('Tipo de Evento'),
  }),

  Yup.object({
    duracion: Yup.string()
      .required('El Tipo de Duración es requerido.')
      .label('Tipo de Duracíon del Evento'),
    /* horariosDiferentes: Yup.boolean().required('El Tipo de Duración es requerido.').label('Tipo de Duracíon del Evento'), */
    start: Yup.string()
      .required('La fecha de Inicio es requerida.')
      .label('Descripción del Evento'),
    end: Yup.string()
      .required('La fecha de Fin es requerida.')
      .label('Descripción del Evento'),
  }),
  Yup.object({

    area: Yup.string().required('El Área es requerida.').label('Lugar del Evento').min(1),
    idArea: Yup.string().required('El Área es requerida.').label('Lugar del Evento').min(1),
    idUsuarioAsignado: Yup.string().required('El Usuario es requerido.').label('Usuario Asignado').min(1),
    usuarioAsignado: Yup.string().required('El Usuario es requerido.').label('Usuario Asignado').min(1),
    idUsuarioCarga: Yup.string().required('El Usuario es requerido.').label('Usuario Asignado').min(1),
    usuarioCarga: Yup.string().required('El Usuario es requerido.').label('Usuario Asignado').min(1),

  }),

  Yup.object({

    lugar: Yup.string().required('El lugar del evento es Obligatorio').label('Lugar del Evento'),
    calle: Yup.string().label('Lugar del Evento'),
    numero: Yup.number()
      .label('Lugar del Evento'),
    localidad: Yup.string().required('La localidad es Obligatoria').label('Lugar del Evento'),
    provincia: Yup.string().required('La provincia es Obligatoria').label('Lugar del Evento'),
  }),
]


const evInit = {
  idEvento: 0,
  title: '',
  descripcion: '',
  objetivo: '',
  conclusion: '',
  idUsuarioCarga: '',
  usuarioCarga: '',
  actividad: '',
  presMinistro: false,
  eje: '',
  eje_detalle: '',
  eje_tipo: '',
  color: '',
  idUsuarioAsignado: '',
  usuarioAsignado: '',
  area: '',
  idArea: '',
  organizacion: '',
  /* colaboradores: [
    {
      nombre: '',
      area: '',
      organizacion: '',
    }
  ] */
  tipo: '',
  duracion: '',
  horariosDiferentes: false,
  start: new Date(),
  end: new Date(),
  estado: '',
  lugar: '',
  calle: '',
  numero: '',
  localidad: '',
  provincia: '',
  pais: '',
  geom: {
    latitud: -34.6037,
    longitud: -68.3816,
  },
  destinatarios: [
    {
      destinatario: '',
      idDestinatario: '',
    }
  ],
  programa: '',
  idPrograma: '',
  /*  orgParticipantes: [{
     nombre: '',
     direccion: '',
     cuit: '',
     tipo: '',
   }] */
}

export { eventoSchemas, evInit }
