import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ICreateModulo} from '../../Sis-admin/interfaces/ICreateModulo'
import {analitcaAllEmpleados, analitcaMenuHeaderEmpresa} from '../services/EJ.Empleado.Services'
import {analitcaMenuHeaderUsuario, getOneEmpresa} from '../services/EJ.Services'
import {TotalesEmpleadosReact} from '../components/analitMenuHeaders/totalesEmpleados'
import {TotalesEmpresas} from '../components/analitMenuHeaders/TotalesEmpresas'
import {TotalesEmprendedores} from '../components/analitMenuHeaders/TotalesEmprendedores'
import {TotalEmpleadosGral} from '../components/analitMenuHeaders/TotalEmpleadosGral'

import {DropdownTools} from '../components/DropDownHeader/DropdownTools'
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'

interface ItotalEmpleados {
  total: number
  apTotal: number
  rechTotal: number
  pendTotal: number
}
interface IregTotales {
  totalEmpresas: number
  totalEmprendedores: number
  apTotalEmpresas: number
  apTotalEmprendedores: number
  rechTotalEmpresas: number
  rechTotalEmprendedores: number
}

const MPHeader: React.FC = () => {
  const [empTotales, setEmpTotales] = useState<ItotalEmpleados>()
  const [empInsTotales, setEmpInscTotales] = useState<ItotalEmpleados>()
  const [regTotales, setRegTotales] = useState<IregTotales>()
  const [estadoEmp, setEstadoEmp] = useState<boolean>(false)

  const location = useLocation()
  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)

  let {perfil} = dashState
  const authState: any = useSelector((state: ISTATE) => state.auth)
  let {usuario} = authState
  const modulo: ICreateModulo = perfil.find((value: any) => value.nombre === 'Empleo Joven')

  useEffect(() => {
    let unMount: boolean = true
    usuario.usuario.length > 8 &&
      analitcaMenuHeaderEmpresa(usuario.usuario).then((res: any) => {
        if (unMount) {
          setEmpTotales(res.data.totales)
        }
      })
    usuario.usuario.length > 8 &&
      getOneEmpresa(usuario.usuario).then((res: any) => {
        if (res.data.ok && unMount && res.data.data.estado) {
          setEstadoEmp(true)
        }
      })
    usuario.usuario.length <= 8 &&
      analitcaMenuHeaderUsuario(usuario.usuario).then((res: any) => {
        if (unMount) {
          setRegTotales(res.data.regTotales)
          setEstadoEmp(true)
        }
      })
    analitcaAllEmpleados().then((res: any) => {
      if (unMount) {
        setEmpInscTotales(res.data.totales)
      }
    })
    return () => {
      unMount = false
    }
  }, [location])

  const tooltip = (titulo: string) => {
    return (
      <Tooltip id='tooltip' className='capital'>
        <strong>{titulo}</strong>
      </Tooltip>
    )
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={toAbsoluteUrl('/media/avatars/Empleo-Joven-2.png')} alt='Metornic' />
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a
                    href='#'
                    className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 capital'
                  >
                    {dashState.nombre}
                  </a>
                  <a href='#'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen026.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                  </a>
                </div>

                {/* <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 capital'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com006.svg'
                      className='svg-icon-4 me-1'
                    />
                    {usuario.organizacion.datosOrganismo.areaPrincipal.nombreArea}
                  </a>

                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-4 me-1'
                    />
                    {usuario.email}
                  </a>
                </div> */}
              </div>

              <div className='d-flex my-4'>
                {/*  <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr012.svg'
                    className='svg-icon-3 d-none'
                  />

                  <span className='indicator-label'>Reglamentación Emplo Joven</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </a> */}
                <OverlayTrigger placement='top' overlay={tooltip('Información y Documentación')}>
                  <div className='me-0'>
                    <button
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='bi bi-info fs-3'></i>
                    </button>
                    <DropdownTools />
                  </div>
                </OverlayTrigger>
              </div>
            </div>

            {/* Inicio est de empresas */}
            {modulo.menu.some((menu) => menu.name === 'empresas') && (
              <>
                <TotalesEmpresas
                  totalEmpresas={regTotales?.totalEmpresas}
                  apTotalEmpresas={regTotales?.apTotalEmpresas}
                  pendTotalEmpresas={regTotales?.rechTotalEmpresas}
                />
                <TotalesEmprendedores
                  totalEmprededores={regTotales?.totalEmprendedores}
                  apTotalEmprededores={regTotales?.apTotalEmprendedores}
                  pendTotalEmprededores={regTotales?.rechTotalEmprendedores}
                />
              </>
            )}
            {/* Inicio est de Empleados */}
            {modulo.menu.some((menu) => menu.name === 'mis empleados') && (
              <TotalesEmpleadosReact
                total={empTotales?.total}
                apTotal={empTotales?.apTotal}
                pendTotal={empTotales?.pendTotal}
                rechTotal={empTotales?.rechTotal}
              />
            )}
            {modulo.menu.some((menu) => menu.name === 'empleados') && (
              <TotalEmpleadosGral
                total={empInsTotales?.total}
                apTotal={empInsTotales?.apTotal}
                pendTotal={empInsTotales?.pendTotal}
                rechTotal={empInsTotales?.rechTotal}
              />
            )}
          </div>
        </div>
        {estadoEmp ? (
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              {modulo.menu.map((menu: any, index: any) => {
                return (
                  <li className='nav-item' key={index}>
                    <OverlayTrigger placement='top' overlay={tooltip(`${menu.tooltip}`)}>
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname === `${menu.state}` && 'active')
                        }
                        to={`/${menu.state}`}
                      >
                        {menu.name !== 'empresas'}

                        <div className='capital'>{menu.name}</div>
                      </Link>
                    </OverlayTrigger>
                  </li>
                )
              })}
            </ul>
          </div>
        ) : (
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <OverlayTrigger placement='top' overlay={tooltip(`Mis datos de Registro`)}>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === `ej/misdatos` && 'active')
                    }
                    to={`/ej/misdatos`}
                  >
                    {/* {menu.name !== 'empresas'} */}

                    <div className='capital'>mis datos</div>
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export {MPHeader}
