import { Button, TextField } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import FormikControl from '../../../../components/Formik/FormikControl'
import { ICreateUsr } from '../../Sis-admin/interfaces/ICreateUsr'
import { getOneUsuarios } from '../../Sis-admin/services/UsuarioService'
import { saveCargo } from '../services/telefonia-service'
import '../../../../../_metronic/assets/sass/Telefonia.scss'

const inicialValue: any = {
  numero: '',
  dni: '',
  nombre: '',
  apellido: '',
  n_empleado: '',
  nombre_dependencia: '',
  nombre_organismo: '',
  fecha_inicio: '',
  n_nota: '',
  estado_cargo: '',
}

const cualquiera: any = {
  apellido: 'de los santos',
  dni: '32212845',
  fecha_inicio: '',
  n_nota: '',
  nombre: 'daniel nicolas',
  nombre_dependencia: 'innovación y tecnología',
  nombre_organismo: 'ministerio de niñez, adolescencia, juventud y ciudadania',
  n_empleado: '255658',
  numero: '2993244102',
  estado_cargo: true,
}
export const NuevoCargo = () => {
  const location: any = useLocation()
  ////console.log('data enviada:' + location.state.numero)

  const [Cusuario, setCusuario] = useState<ICreateUsr>()

  const [callBD, setCallBD] = useState<boolean>(false)

  const [usuario, setusuario] = useState(inicialValue)
  const [usuarioBd, setusuarioBd] = useState(null)

  const mostrarUsuarios = (dni: string) => {
    getOneUsuarios(dni).then(async (res: any) => {
      if (res.data.ok) {
        ////console.log('36 ' + res.data)
        cualquiera.numero = location.state.numero
        cualquiera.dni = res.data.data.datosPersonales.dni
        cualquiera.nombre = res.data.data.datosPersonales.nombres
        cualquiera.apellido = res.data.data.datosPersonales.apellido
        cualquiera.n_empleado = res.data.data.datosLaborales.legajo
        cualquiera.nombre_dependencia =
          res.data.data.datosLaborales.datosOrganismo.areaPrincipal.nombreArea
        cualquiera.nombre_organismo = res.data.data.datosLaborales.datosOrganismo.nombre
        cualquiera.estado_cargo = true
        await setusuarioBd(cualquiera)

        setCallBD(true)
        // ////console.log(usuarioBd)
      }
    })
  }

  const guardarUsuario = (value: any) => {
    saveCargo(value).then((res: any) => {
      ////console.log(res)
    })
  } //endpoint de guardar usuario

  useEffect(() => {
    ////console.log(usuarioBd)
  }, [usuarioBd])

  return (
    <div className='card'>
      <div className='card-body'>
        <Formik initialValues={usuarioBd || usuario} onSubmit={guardarUsuario} enableReinitialize>
          {(formik) => {
            // ////console.log(formik)
            return (
              <Form>
                <Field
                  type='text'
                  id='numero'
                  name='numero'
                  variant='standard'
                  className='input_hidden'
                  label='Numero'
                />

                <div>
                  <FormikControl
                    label='DNI'
                    control='input'
                    id='dni'
                    name='dni'
                    placeholder='Ingrese DNI'
                  />
                  <ErrorMessage name='dni' />

                  <button
                    type='button'
                    className='button01'
                    onClick={(e: any) => {
                      mostrarUsuarios(formik.values.dni)
                    }}
                  >
                    Buscar
                  </button>
                  <p></p>
                  {callBD && (
                    <FormikControl
                      control='input'
                      label='Nombre'
                      id='nombre'
                      name='nombre'
                      className='input_cargo'
                      placeholder='Nombre'
                      readonly='true'
                    />
                  )}

                  {callBD && (
                    <>
                      <p></p>
                      <div>
                        <FormikControl
                          control='input'
                          type='text'
                          id='apellido'
                          name='apellido'
                          label='Apellido'
                          className='input_carga capitalize'
                          readonly='true'
                        />
                        <p></p>

                        <FormikControl
                          control='input'
                          type='text'
                          id='nro_empleado'
                          name='n_empleado'
                          label='Nro Empleado'
                          readonly='true'
                        />
                        <p></p>

                        <FormikControl
                          control='input'
                          type='text'
                          id='nombre_dependencia'
                          name='nombre_dependencia'
                          label='Dependencia'
                          readonly='true'
                        />
                        <p></p>
                      </div>
                      <div>
                        <FormikControl
                          control='input'
                          type='text'
                          id='nombre_organismo'
                          name='nombre_organismo'
                          label='Organismo'
                          readonly='true'
                        />
                        <p></p>

                        <FormikControl
                          control='input'
                          type='date'
                          id='fecha_inicio'
                          name='fecha_inicio'
                          label='Fecha de Inicio'
                        />
                      </div>
                      <p></p>

                      <div>
                        <FormikControl
                          control='input'
                          type='text'
                          id='n_nota'
                          name='n_nota'
                          label='Nº Nota'
                        />
                        <FormikControl
                          control='input'
                          type='hidden'
                          id='estado_cargo'
                          name='estado_cargo'
                          className='input_hidden'
                        />

                        <p></p>
                        <div>
                          <Button type='submit' className='button1'>
                            Guardar
                          </Button>
                        </div>
                      </div>
                      <p></p>
                    </>
                  )}
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
export default NuevoCargo
