//import {da} from 'date-fns/locale'
import {request} from '../../../../../setup/axios/axios.util'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const URL = `${API_URL}/modules/ej`

export const getAllEmpresasOk = () => {
  return request().get(`${URL}/getSelecEmpresas`)
}

export const getEmpleadosByCuit = (cuit: string) => {
  return request().get(`${URL}/getEmpleados?cuit=${cuit}`)
}

/* export const getPdfDocInitEmp = (cuit: string, dni: string, archivo: string) => {
  let data = {
    cuit: cuit,
    empleado: dni,
    archivo: archivo,
  }
  return request().post(`${URL}/getPdfDocInitEmp`, data, {responseType: 'blob'})
} */

export const ModEstadoOneEmpleado = (
  cuit: string,
  empleado: string,
  estado: string,
  mensaje?: any,
  docValidada?: any,
  email?: string
) => {
  let data: any = {
    cuit: cuit,
    empleado: empleado,
    estado: estado,
    mensaje: mensaje,
    docValidada,
    email: email,
  }
  return request().post(`${URL}/ModOneEmpleado`, data)
}

export const ModDocPeridoEmpleado = (
  empleado: string,
  estado: string,
  doc_id: string,
  anio: string,
  mes: string,
  observacion?: string
) => {
  let data: any = {
    empleado: empleado,
    estado: estado,
    doc_id,
    anio,
    mes,
    observacion: observacion,
  }
  return request().post(`${URL}/ModDocPeriodo`, data)
}

export const PutDocPeridoEmpleado = (
  archivo: any,
  tipoArchivo: string,
  docViejo: string,
  empleado: string,
  anio: string,
  mes: string,
  cuit: string
) => {
  console.log(tipoArchivo)

  let formData = new FormData()
  formData.append(`${tipoArchivo}`, archivo)
  formData.append('tipoArchivo', tipoArchivo)
  formData.append('empleado', empleado)
  formData.append('docViejo', docViejo)
  formData.append('anio', anio)
  formData.append('mes', mes)
  formData.append('cuit', cuit)
  return request().post(`${URL}/putDocPeriodo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getEmpleadosMesxMes = (values: any) => {
  let data: any = {}
  data.cuit = values.cuit
  data.mes = values.mes
  data.anio = values.anio
  return request().post(`${URL}/getEmpleadosMesxMes`, data)
}

export const getDocsEmpresa = ({cuit, anio, mes}: any) => {
  return request().get(`${URL}/getDocsEmpresa?cuit=${cuit}&anio=${anio}&mes=${mes}`);
}

//TODO: funcion para poder generar el zip con todos los documentos!

export const getAllfilesEmpZipByeOneMes = (values: any) => {
  let data: any = {}
  data.values = values
  return request().post(`${URL}/getFilesZip`, data, {responseType: 'blob'})
}
