import React, { useState, useEffect, useCallback } from "react";
import Chart from "react-apexcharts";
import ApexCharts, { type ApexOptions } from 'apexcharts'
import { getIndicadorPorLocalidad, getIndicadoresEdades } from '../../services/cud-service'
import { AxiosResponse } from "axios";
import { IdataSerie, IrangoDataY, IrangoEdadSeries, Rangos } from "../../Interfaces/IindicadorRangos";
import useChartLocale from "../../../../../Hooks/useLocales";
import { localidadesNqn } from "../../../../../services/gral/proLocService";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Swal from "sweetalert2";

const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#00D9E9', '#FF66C3'];
const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
const CardDinamicAges: React.FC = () => {

  const chartLocale = useChartLocale();

  const [maxZoom, setMaxZoom] = useState<number>(5000);

  const [dataPlot, setDataPlot] = useState<Rangos[]>([]);

  const [options, setOptions] = useState<ApexOptions>({

    chart: {
      ...chartLocale,
      id: "barYear",
      height: 400,
      width: '50%',
      toolbar: {
        show: true,
      },
      /*       zoom: {
              enabled: true,
              type: 'xy',
              autoScaleYaxis: false,
            }, */
      events: {
        dataPointSelection: function (e, chart, opts) {
          const quarterChartEl = document.querySelector("#chart-quarter");
          const yearChartEl = document.querySelector("#chart-year");

          if (opts.selectedDataPoints[0].length === 1) {
            if (quarterChartEl?.classList.contains("active")) {
              updateQuarterChart(chart, 'barQuarter')
            } else {
              yearChartEl?.classList.add("chart-quarter-activated")
              quarterChartEl?.classList.add("active");
              updateQuarterChart(chart, 'barQuarter')
            }
          } else {
            updateQuarterChart(chart, 'barQuarter')
          }

          if (opts.selectedDataPoints[0].length === 0) {
            yearChartEl?.classList.remove("chart-quarter-activated")
            quarterChartEl?.classList.remove("active");
          }

        },
        updated: function (chart) {
          updateQuarterChart(chart, 'barQuarter')
        }
      },


    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
        barHeight: '60%',
        dataLabels: {
          position: 'bottom',
        }
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#fff']
      },
      formatter: /* undefined, */
        (val, opt) => {
          return opt.w.globals.labels[opt.dataPointIndex]
        },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      }
    },
    colors: colors,
    states: {
      normal: {
        filter: {
          type: 'desaturate'
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'darken',
          value: 1
        }
      }
    },
    tooltip: {
      x: {
        show: false
      },
      y: {

        formatter:
          function (val: any, opts: any) {
            return val;
          }
      }
    },
    xaxis: {
      categories: [],
      tickPlacement: 'on'
    },
    /*     title: {
          text: 'Totales CUD',
          offsetX: 15
        }, */
    subtitle: {
      text: '(Click para ver detalles)',
      offsetX: 15
    },
    yaxis: {
      labels: {
        show: false
      },
      max: 5000
    }

  });

  const [series, setSeries] = useState<IdataSerie[]>([{
    name: 'Totales CUD por mes',
    data: []
  }]);

  const [seriesQuarter, setSeriesQuarter] = useState<IrangoDataY[]>([{
    name: 'Datos por edades',
    data: [],
  }]);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [optionsQuarter, setOptionsQuarter] = useState<ApexOptions>({
    chart: {
      id: 'barQuarter',
      height: 400,
      width: '100%',
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          zoom: true,
        }
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        columnWidth: '80%',
        horizontal: false
      }
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'center', //| 'right' |  'left'
      fontSize: 'small',
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        }
      },
      xaxis: {
        lines: {
          show: true,
        }
      }
    },
    xaxis: {
      type: 'category',
    },
    yaxis: {
      labels: {
        show: true
      },

    },
    title: {
      text: 'Totales por edad/genero',
      offsetX: 10
    },
    tooltip: {
      x: {
        formatter: function (val: any, opts: any) {
          return opts.w.globals.seriesNames[opts.seriesIndex]
        }
      },
      y: {

        formatter: function (val: any, opts: any) {
          return val
        }

      }
    }
  });

  const [locNqn, setLocNqn] = useState([]);

  const [localidadSelected, setLocalidadSelected] = useState('');

  useEffect(() => {
    getIndicadoresEdades().then((response: AxiosResponse) => {
      const res = response?.data;
      if (res.ok === true) {
        setLoaded(true);
        setDataPlot(res.data);
      }
    })

  }, []);

  useEffect(() => {
    if (localidadSelected !== '') {
      getIndicadorPorLocalidad(localidadSelected).then((response: AxiosResponse) => {
        const res = response?.data;
        if (res.data.length === 0) {
          Swal.fire(`Sin datos para ${localidadSelected}`, "Se muestran datos generales de la provincia", 'error')
          setLocalidadSelected('');
          return;
        }
        setLoaded(true);
        setDataPlot(res.data);
      })
    }
    else {
      getIndicadoresEdades().then((response: AxiosResponse) => {
        const res = response?.data;
        if (res.ok === true) {
          setLoaded(true);
          setDataPlot(res.data);
        }
      })
    }
  }, [localidadSelected]);

  useEffect(() => {
    localidadesNqn().then((response: AxiosResponse) => {
      if (response.data.ok) {
        setLocNqn(response.data.data);
      }
    })
  }, []);

  const makeData = useCallback(() => {
    if (dataPlot?.length > 0) {


      const rangoData = dataPlot.map(elemento => {
        const _idSplitted = elemento._id.split('/');
        const mes: number = parseInt(_idSplitted[0]);
        const mesToShow = meses[mes - 1];
        const transfData = elemento.data.map(item => ({
          x: `${item.ageRange}`,
          gender: item.gender.toLowerCase(),
          y: item.count,
        }));

        const transfDataHombres = transfData.filter(item => item.gender.includes('m')).map(it => ({ x: it.x, y: it.y, fillColor: colors[0], strokeColor: '#C23829' }));
        const transfDataMujeres = transfData.filter(item => item.gender.includes('f')).map(it => ({ x: it.x, y: it.y, fillColor: colors[1], strokeColor: '#C23829' }));
        return {
          id: elemento._id,
          totalCount: elemento.totalCount,
          name: mesToShow,
          men: transfDataHombres,
          women: transfDataMujeres,
        }
      });

      const dataIndicador: IrangoEdadSeries[] = rangoData.map(item => {
        const data = {
          x: item.name,
          y: item.totalCount,
          rangos: [{
            name: 'Hombres',
            data: item.men,
          }, {
            name: 'Mujeres',
            data: item.women,
          }],
        }
        return data;
      });
      const categ = dataIndicador.map((elem: IrangoEdadSeries) => elem.x);

      setOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: categ
        }
      }));

      setSeries(prevSeries => {
        const updatedData = [...prevSeries];
        updatedData[0].data = dataIndicador;
        return updatedData;
      });
    }
  }, [dataPlot, setOptions, setSeries]);

  useEffect(() => {
    makeData();
  }, [makeData]);

  useEffect(() => {

  });

  function updateQuarterChart(sourceChart: any, destChartIDToUpdate: any) {
    let series: any[] = [];
    let seriesIndex = 0;

    if (sourceChart.w.globals.selectedDataPoints[0]) {
      var selectedPoints = sourceChart.w.globals.selectedDataPoints;
      for (var i = 0; i < selectedPoints[seriesIndex].length; i++) {
        var selectedIndex = selectedPoints[seriesIndex][i];
        var yearSeries = sourceChart.w.config.series[seriesIndex];
        series = yearSeries.data[selectedIndex].rangos.map((elem: any) => {
          return {
            name: elem.name,
            data: elem.data,
          }
        });
      }
      if (series.length === 0) series = [{
        name: '',
        data: []
      }]

      setSeriesQuarter((prevState) => prevState[0].data = series);
      setOptionsQuarter((prevState) => ({
        ...prevState,
        colors,
      }));
      return ApexCharts.exec(destChartIDToUpdate, 'updateOptions', {
        series: series,
        colors: [colors[0], colors[1]]

      })
    }
  }

  const handleZoomChange = (event: any) => {
    console.log(event.target.name);
    if (maxZoom > 0) {
      const maximo = event.target?.name === 'plus' ? maxZoom / 5 : maxZoom * 5;
      setMaxZoom(maximo);
      setOptions(prevState => ({
        ...prevState,
        yaxis: {
          ...prevState.yaxis,
          max: maximo
        }
      }));
    }
    else {
      const maximo = event.target.name === 'minus' ? maxZoom * 5 : 0;
      setMaxZoom(maximo);
    }

  }

  const handleChange = (event: any) => setLocalidadSelected(event.target.value);

  const showNoData = () => {
    Swal.fire('The Internet?', 'That thing is still around?', 'question');
  }

  return (
    <div className="app">
      {loaded ? (
        <div id="wrap">

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Localidad</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={localidadSelected}
              label="Localidad"
              onChange={handleChange}
            >
              {locNqn.map((item: any) => <MenuItem key={item.nombre} value={item.nombre}>{item.nombre}</MenuItem>)}
            </Select>
          </FormControl>

          <div className="mb-3 row"></div>
          <div id="html-dist"></div>
          <div id="chart-year">
            <div className="d-flex justify-content-between align-items-start flex-wrap">
              <h3 className="chart-title">Totales CUD</h3>
              <div>
                <button onClick={handleZoomChange} type="button" name="minus" className="btn btn-sm btn-primary">-</button>
                <button onClick={handleZoomChange} type="button" name="plus" className="btn btn-sm btn-primary">+</button>
              </div>
            </div>
            <Chart options={options} series={series} type="bar" height={400} />
            {/* {JSON.stringify(options)} */}
          </div>
          <div id="chart-quarter">
            <Chart options={optionsQuarter} series={seriesQuarter} type="bar" height={400} />
          </div>

        </div>
      ) : showNoData}
      <div id="html-dist"></div>
    </div>
  );
}


export default CardDinamicAges;