import { useEffect, useState } from 'react'
import { Calendar, momentLocalizer, View } from 'react-big-calendar'
import { mensajes } from './CalendarioEsp'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import 'moment/locale/es'
import { CalendarioEvento } from './CalendarioEvento'
import './Agenda.scss'

import { useDispatch, useSelector } from 'react-redux'
import { ISTATE } from '../../../../../setup/redux/interface/reduxState.interface'
import { SetVistaAction } from '../Redux/actions/vista'
import { CalendarioModal } from './CalendarioModal'
import { uiCloseModal, uiOpenModal } from '../Redux/actions/ui'
import {
  eventosSetAccion,
  evSetFormulario,
  setEvFull,
  setEvPub,
  setEvFiltro,
} from '../Redux/actions/eventos'
import Agenda_Service from '../Redux/services/Agenda_Service'
import HeaderCalendario from './Headers/HeaderCalendario'
import { areasCargar } from '../Redux/actions/areasActions'
import { cargarSelAreas, seleccionMenu } from '../Redux/actions/menuActions'
import { PageTitle } from '../../../../../_metronic/layout/core'
import Typography from '@mui/material/Typography'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import styled from '@emotion/styled'
import { buscarEventos } from '../Utils/BuscarEventos'
import FullCalendar, { EventApi, DateSelectArg, EventClickArg, EventContentArg, formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import { evInit, IEventos } from '../pages/CrearEventoPage/Steps/EventoData'
import { EventosDrawer } from './explore/Eventos-Drawer/EventosDrawer'
import { ExploreMainFiltros } from './explore/ExploreMain'
import { ToggleFiltros } from './explore/ToggleFiltros'

moment.locale('es')

const localizer = momentLocalizer(moment)



const Calendario: React.FC = () => {
  const dispatch = useDispatch()
  const dataUsStore: any = useSelector((state: ISTATE) => state.auth)
  const { usuario } = dataUsStore

  const eventosStore: any = useSelector((state: ISTATE) => state.eventos)
  const { evPub, evFiltro, evFull } = eventosStore
  const [areas, setAreas] = useState<any>([])
  let [eventos, setEventos] = useState([])
  const [lastView, setlastView] = useState<any>(localStorage.getItem('lastView') || 'month')
  const selStore: any = useSelector((state: ISTATE) => state.menuBoton)
  const { seleccion } = selStore
  let areaPUsuario = usuario.organizacion.datosOrganismo.areaPrincipal.idAreaP


  useEffect(() => {
    Agenda_Service.consultaDependencias().then((res: any) => {
      let areasMenu = res.data.data
      areasMenu.sort(function compare(a: any, b: any) {
        if (a.categoriaArea < b.categoriaArea) {
          return -1
        }
        if (a.categoriaArea > b.categoriaArea) {
          return 1
        }
        // a debe ser igual b
        return 0
      })

      ////////console.log('areasMenu', areasMenu)
      let areasTemp: any = []
      for (let area = 0; area < areasMenu.length; area++) {
        const a = areasMenu[area]
        if (usuario.organizacion.datosOrganismo.areaPrincipal.idAreaP !== a._id) {
          if (a.categoriaArea === 'A' || a.categoriaArea === 'B') {
            areasTemp.push({
              nombreCorto: a.detalleSistema.nombreCorto,
              tooltip: `${a.tipoArea} de ${a.nombreArea}`,
              colorFondo: a.detalleSistema.colorFondo,
              id: a._id,
              selec: false,
            })
          }
        } else {
          // ////console.log('Entrooooooooooooo')
          if (a.categoriaArea === 'A' || a.categoriaArea === 'B') {

            areasTemp.push({
              nombreCorto: a.detalleSistema.nombreCorto,
              tooltip: `${a.tipoArea} de ${a.nombreArea}`,
              colorFondo: a.detalleSistema.colorFondo,
              id: a._id,
              selec: true,
            })
          }
        }
      }
      setAreas(areasMenu)
      dispatch(areasCargar(areasMenu))
      dispatch(cargarSelAreas(areasTemp))
    })
    Agenda_Service.buscarEventos().then(async (res: any) => {
      let eventos: any
      await Agenda_Service.actualizarEstados(res.data, 'upd').then((res: any) => {
        if (res.data.ok) {
          //console.log(`Evento actualizado correctamente`)
          //console.log('res.data', res.data)
          eventos = res.data.eventos
        }
      })
      //console.log('eventos', eventos)
      dispatch(setEvFull(eventos))
      let temp: IEventos[] = []
      //console.log('usData.areaResponsable', usuario)
      let areaPUsuario = usuario.organizacion.datosOrganismo.areaPrincipal.idAreaP
      for (let ev = 0; ev < eventos.length; ev++) {
        const e: IEventos = eventos[ev]
        if (e.idArea !== areaPUsuario && e.tipo === 'privado') {
        } else {
          temp.push(e)
        }
      }
      //console.log('temp', temp)
      dispatch(setEvPub(temp))
      let eventosFiltrados: any = []
      for (let filtro = 0; filtro < evFull.length; filtro++) {
        const fil: IEventos = evFull[filtro];
        if (fil.idArea === areaPUsuario) {
          eventosFiltrados.push(fil)
        }
      }
      //console.log('eventosFiltrados', eventosFiltrados)
      dispatch(setEvFiltro(eventosFiltrados))
    })
  }, [])





  useEffect(() => {
    filtrarEventos('')
  }, [seleccion])

  function filtrarEventos(area?: string) {

    if (area === 'Todas' || area === 'Ninguna') {
      switch (area) {
        case 'Todas':
          evPub.map((e: any) => {
            e.ver = true
          })
          break
        case 'Ninguna':
          evPub.map((e: any) => {
            e.ver = false
          })
          break

        default:
          break
      }
    } else {
      if (area === '') {
        seleccion.map((sel: any) => {
          evPub.map((e: any) => {
            if (e.idArea === sel.id) {
              e.ver = sel.selec
            }
          })
        })
      } else {
        evPub.map((e: any) => {
          if (e.idArea === area) {
            e.ver = !e.ver
          }
        })
      }
    }
    let filtro = cargoEventos(evPub)
    dispatch(setEvFiltro(filtro))
  }

  const onSelectEvent = (e: any) => {
    moment().format('LLL')
    //console.log('e', e._id)
    //console.log('evPub', evPub)
    /*     let eveTemp: IEventos[] = evPub.filter((ev: any) => ev._id === e.event.extendedProps._id)*/
    let eveTemp: IEventos[] = evPub.filter((ev: any) => ev._id === e._id)
    //console.log('eveTemp', eveTemp[0]);

    let eventoModal: any = eveTemp.length ? eveTemp[0] : evInit;
    let accionesTemp = {}

    //console.log('/////////////////eventoSel', eveTemp)
    switch (eventoModal.estado) {
      case 'planificado':
        accionesTemp = {
          borrar: false,
          editar: true,
          cancelar: true,
          guardar: true,
          enviarForm: false,
          imprimir: true,
          conclusion: false,
        }
        break
      case 'en curso':
        accionesTemp = {
          borrar: false,
          editar: false,
          cancelar: true,
          guardar: false,
          enviarForm: false,
          imprimir: true,
          conclusion: false,
        }
        break
      case 'realizado':
        accionesTemp = {
          borrar: false,
          editar: false,
          cancelar: false,
          guardar: false,
          enviarForm: false,
          imprimir: true,
          conclusion: true,
        }
        break
      case 'cancelado':
        accionesTemp = {
          borrar: false,
          editar: false,
          cancelar: false,
          guardar: false,
          enviarForm: false,
          imprimir: true,
          conclusion: false,
        }
        break

      default:
        break
    }
    dispatch(eventosSetAccion(accionesTemp))
    dispatch(evSetFormulario(eveTemp[0]))
  }

  const onDoubleClick = (e: any) => {
    //console.log('e', e.event)
    let eveTemp = {
      ...e.event.extendedProps,
      color: e.event.backgroundColor,
      start: e.event.start,
      end: e.event.end,
      title: e.event.title,
    }
    dispatch(evSetFormulario(eveTemp))
    dispatch(uiOpenModal())
  }



  const handleButtonClick = (e: any) => {
    dispatch(seleccionMenu(e))
    filtrarEventos(e)
  }
  const cargoEventos = (ev: any) => {
    let eventostemp: any = []
    ev.map((e: any) => {
      if (e.ver === true) {
        eventostemp.push(e)
      }
    })
    ////////console.log('Entro a Cargar Eventos')
    return eventostemp
  }
  const cargarEventosCalendario = () => {
    let eventostemp: any = []
    eventos.map((e: any) => {
      if (e.ver === true) {
        eventostemp.push(e)
      }
    })
    ////////console.log('Entro a Cargar Eventos')
    return eventostemp
  }

  const eventStyleGetter = (e: any) => {
    const style = {
      backgroundColor: `${e.backgroundColor}`,
      backgroundOpacity: 0.5,
      borderRadius: '3px',
      color: '#000000',
      fontSize: 13,
      fontWeight: 400,
      paddingLeft: '5px',
      justifyContent: 'center',
      width: '100%',
    }
    return style
  }

  const botonMenu = (col: any) => {
    if (col.selec) {
      return {
        backgroundColor: `${col.colorFondo}`,
        backgroundOpacity: 0.5,
        borderRadius: '3px',
        fontSize: 13,
        fontWeight: 400,
        paddingLeft: '5px',
        justifyContent: 'center',
        width: '100%',
        color: '#4e4e4e',
      }
    } else {
      return {
        backgroundColor: `#f5f5f5`,
        backgroundOpacity: 0.5,
        borderRadius: '3px',
        fontSize: 13,
        fontWeight: 400,
        paddingLeft: '5px',
        justifyContent: 'center',
        width: '100%',
        color: '#4e4e4e',
      }
    }
  }

  let fuente: any = ''

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement='right' />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${fuente}`,
      color: `#FFFFFF`,
      fontSize: 12,
      fontWeightBold: 700,
      maxWidth: 200,
    },
  }))


  //TODO: FullCalendar

  function renderEventContent(eventContent: EventContentArg) {
    return (
      <>
        <Typography
          color='inherit'
          style={eventStyleGetter(eventContent)}
        >
          {eventContent.event.title} {/* - {eventContent.event.extendedProps.usuarioAsignado} */}
        </Typography>

      </>
    )
  }


  return (
    <div className={`card sombras`} id='Calendario'>
      {/* begin::Header */}
      <PageTitle>AGENDA</PageTitle>
      <HeaderCalendario />

      {/* end::Header */}
      {/* begin::Body */}
      <div className='row card-body' key='Agenda'>
        <div className='col-2'>
          <LightTooltip
            className='negrita'
            children={
              <button
                id={'Todas'}
                type='button'
                className={`btn btn-sm boton-menu  mb-2`}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                style={botonMenu({ colorFondo: '#c5c5c5', selec: true })}
                onClick={() => {
                  handleButtonClick('Todas')
                }}
              >
                Todas
              </button>
            }
            title={
              <Typography
                color='inherit'
                style={{ fontWeight: 600, textTransform: 'capitalize', textAlign: 'center' }}
              >
                Seleccionar todas las dependencias
              </Typography>
            }
          ></LightTooltip>
          <LightTooltip
            className='negrita'
            children={
              <button
                id={'Ninguna'}
                type='button'
                className={`btn btn-sm boton-menu  mb-2`}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                style={botonMenu({ colorFondo: '#c5c5c5', selec: true })}
                onClick={() => {
                  handleButtonClick('Ninguna')
                }}
              >
                Ninguna
              </button>
            }
            title={
              <Typography
                color='inherit'
                style={{ fontWeight: 600, textTransform: 'capitalize', textAlign: 'center' }}
              >
                Deseleccionar todas las dependencias
              </Typography>
            }
          ></LightTooltip>

          {seleccion.map((item: any) => (
            <LightTooltip
              className='negrita'
              children={
                <button
                  id={item.nombreCorto}
                  type='button'
                  className={`btn btn-sm boton-menu  mb-2`}
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  style={botonMenu(item)}
                  onClick={() => {
                    handleButtonClick(item.id)
                  }}
                >
                  {item.nombreCorto}
                </button>
              }
              title={
                <Typography
                  color='inherit'
                  style={{ fontWeight: 600, textTransform: 'capitalize', textAlign: 'center' }}
                >
                  {item.tooltip}
                </Typography>
              }
            ></LightTooltip>
          ))}

        </div>
        <div className='col-10  '>
          {/* begin::Timeline */}
          <div id='calendar-container' className='calendario-screen capital'>
            <FullCalendar
              locale={esLocale}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
              }}
              initialView='dayGridMonth'
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              events={evFiltro} // alternatively, use the `events` setting to fetch from a feed
              eventContent={renderEventContent} // custom render function
              eventClick={onDoubleClick}
            />
          </div>

          {/* end::Timeline */}
        </div>
      </div>
      {/* end: Card Body */}

      <CalendarioModal />
    </div>
  )
}

export { Calendario }

