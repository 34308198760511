import {ErrorMessage, Field} from 'formik'
import React from 'react'
import FormikControl from '../../../../../components/Formik/FormikControl'
interface Iprops {
  wsOk?: boolean
}

export const Step1DtsPersonales: React.FC<Iprops> = ({wsOk}) => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Complete los datos.
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Es necesario el número de documento y el genero cómo figura en el documento físico, para
          verificar los datos en RENAPER.
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6'>
            <FormikControl
              control='input'
              name='documento'
              label='Número de documento'
              placeholder='Nro..'
              className='form-control form-control-solid'
            />
            <div className='fv-plugins-message-container invalid-feedback'>
              <ErrorMessage name='documento' />
            </div>
          </div>
          <div className='col-lg-6'>
            <FormikControl
              control='input'
              name='datosPersonales.sexo'
              label='Genero'
              as='select'
              className='form-select form-select-solid capital'
              id='kt_create_account_form_account_type_personal'
            >
              <option value=''>Seleccione</option>
              <option value='f'>Femenio</option>
              <option value='m'>Masculino</option>
              <option value='x'>X</option>
            </FormikControl>
          </div>
          <div className='fv-plugins-message-container invalid-feedback'>
            <ErrorMessage name='datosPersonales.sexo' />
          </div>
        </div>
      </div>
      <hr />
      {wsOk && (
        <div className='fv-row align-items-center'>
          <div className='row '>
            <div className='col-lg-4'>
              <label className='form-label fw-bolder  fs-6'>Nombres</label>
              <Field
                type='text'
                name='datosPersonales.nombres'
                className='form-control form-control-solid capitalize'
                disabled={true}
              ></Field>
            </div>
            <div className='col-lg-4'>
              <label className='form-label fw-bolder  fs-6'>Apellido</label>
              <Field
                type='text'
                name='datosPersonales.apellido'
                className='form-control form-control-solid capitalize'
                disabled={true}
              ></Field>
            </div>

            <div className='col-lg-4'>
              <label className='form-label fw-bolder  fs-6'>Número de CUIL</label>
              <Field
                type='text'
                name='datosPersonales.cuil'
                className='form-control form-control-solid'
                disabled={true}
              ></Field>
            </div>
            {/*  <div className='col-lg-4'>
            <label className='form-label fw-bolder  fs-6'>Fecha de nacimiento</label>
            <Field
              type='text'
              name='datosPersonales.fechaNacimiento'
              className='form-control form-control-solid'
              disabled={true}
            ></Field>
          </div> */}
          </div>

          <hr />

          <div className='row '>
            <div className='col-lg-6'>
              <label className='form-label fw-bolder  fs-6'>Provincia</label>
              <Field
                type='text'
                name='datosPersonales.provincia'
                className='form-control form-control-solid capitalize'
                disabled={true}
              ></Field>
            </div>
            <div className='col-lg-6'>
              <label className='form-label fw-bolder  fs-6'>Localidad</label>
              <Field
                type='text'
                name='datosPersonales.ciudad'
                className='form-control form-control-solid capitalize'
                disabled={true}
              ></Field>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
