import * as Yup from 'yup'
import { object } from 'yup';

export interface IFormNoticia {
  _id?: string,
  id_noticia:number,  
  titulo: string,
  tematicas: [string],
  cuerpo: string,
  bajada:string,
  fecha_publicacion:string,
  usuario_creador: {
    nombre: string
    nombreUsuario: string
    email: string
 }
 
  datos_autor: string;
  
  nombreOrg:string;
  nombreArea:string;

  imagen?: {
    portada?: {
      tipo: string
      url: string
   }
   miniatura?: {
      tipo: string
      url: string
   }
  };
   /* prueba1?:File   */
 }

const createAccountSchemas = [
  Yup.object({
    titulo: Yup.string().required('Ingrese un titulo').label('Tipo de Titulo es requerido'),
  }),
]

const inits: IFormNoticia = {
  id_noticia:0,
  titulo: '',
  tematicas: [''],
  cuerpo: '',
  bajada:'',
  fecha_publicacion:'',
  usuario_creador: {
    nombre: '',
    nombreUsuario: '',
    email: '',
 },

  datos_autor: '',
  nombreOrg:'',
  nombreArea:'',
  imagen: {
    portada: {
      tipo:'',
      url: ''
   },
   miniatura: {
      tipo: '',
      url: '',
   },
  },
 
 
  }


export {createAccountSchemas, inits}
