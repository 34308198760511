import {combineReducers} from 'redux'
import {persistReducer} from 'redux-persist'
import session from 'redux-persist/lib/storage/session'
import {areasReducer} from '../../../app/modules/apps/Agenda/Redux/reducers/areasReducer'
import {eventoReducer} from '../../../app/modules/apps/Agenda/Redux/reducers/eventosReducer'
import {menuReducer} from '../../../app/modules/apps/Agenda/Redux/reducers/menuReducer'
import {uiReducer} from '../../../app/modules/apps/Agenda/Redux/reducers/uiReducer'
import {
  resCivisApiReducer,
  TematicaReducer,
} from '../../../app/modules/apps/Civis/Redux/TematicaReducer'
import {TablaDatosReducer} from '../../../app/modules/apps/Sis-admin/redux/TablaDatosReducer'
import {TablaDatosBengReducer} from '../../../app/modules/apps/bens/redux/TablaDatosReducer'

///// importar los reducer que se utilizaran en todo el sistema

import {AuthReducer} from './AuthReducer'
import {DashReducer} from './DashReducer'
import {climaReducer} from './ClimaReducer'
import { ejReducer } from '../../../app/modules/apps/EmpleoJoven/redux/reducers/ejReducer';
// configuramos el persistStorage

const persistConfig = {
  key: 'root',
  storage: session,
  whitelist: [
    'dashBoard',
    'auth',
    'eventos',
    'ui',
    'areas',
    'menuBoton',
    'clima',
    'tablaMenuPrincipalBeng',
  ],
}

const rootReducer = combineReducers({
  auth: AuthReducer,
  dashBoard: DashReducer,
  tematicas: TematicaReducer,
  civisResApi: resCivisApiReducer,
  tablaDatos: TablaDatosReducer,
  tablaMenuPrincipalBeng: TablaDatosBengReducer,
  ui: uiReducer,
  eventos: eventoReducer,
  areas: areasReducer,
  menuBoton: menuReducer,
  clima: climaReducer,
  empleoJoven: ejReducer,
})

export default persistReducer(persistConfig, rootReducer)

///https://levelup.gitconnected.com/persisting-your-react-application-state-with-redux-and-typescript-51e4e66c4e53

//LEEEEEERRR LPM
