import { FormikConfig, FormikValues, useFormikContext } from 'formik';
import FormikControl from '../../../../../../../components/Formik/FormikControl';
import { IdatosPersonales } from '../../../../Interfaces/IdatosPerEncuesta';
import { getLegajoDni } from '../../../../services/cud-service';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { KTSVG } from '../../../../../../../../_metronic/helpers/components/KTSVG';


export const DatosPersonales: React.FC<IdatosPersonales> = ({ nombre, apellido }) => {
    const formik = useFormikContext<FormikValues>();
    const [existCud, setExistCud] = useState(false);
    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const { dni, sexo } = formik.values;
        const dataSearched = await getLegajoDni(dni);
        if (dataSearched.data.ok) {
            console.log("Datos recuperados correctamente: ", dataSearched.data);
            const data = dataSearched.data;
            console.log(formik.values);
            console.log("DATOS LEGAJO: ");
            console.log(data.mi_legajo);
            if (data.ok) {
                setExistCud(true);
                formik.setFieldValue("apellido", data.mi_legajo.datosPersonales.apellido.toUpperCase());
                formik.setFieldValue("nombre", data.mi_legajo.datosPersonales.nombre.toUpperCase());
                formik.setFieldValue("domicilioReal", data.mi_legajo.datosPersonales.domicilio);
                formik.setFieldValue("codigoPostal", data.mi_legajo.datosPersonales.codigoPostal);
                formik.setFieldValue("localidad", data.mi_legajo.datosPersonales.localidad?.toLowerCase().trim());
                formik.setFieldValue("provincia", data.mi_legajo.datosPersonales.provincia?.toLowerCase().trim());
                formik.setFieldValue("telefono", data.mi_legajo.datosPersonales.telefono);
                formik.setFieldValue("departamento", data.mi_legajo.datosPersonales.departamento);

            }
        }
        else {
            setExistCud(false);
            Swal.fire("Error", "Datos de CUD no encontrados", "error");
        }
    }
    return (
        <div className="card card-custom card-strech mt-10">
            <div className="card-header shadow bg-body rounded">
                <div className="card-title">
                    <h1>Datos Personales</h1>

                </div>

            </div>
            <div className="card-body">
                <div className="row d-flex pt-3">
                    <div className="row d-flex justify-content-center">
                        <div className="col-4 pb-10 pb-lg-12">
                            <FormikControl
                                control='input'
                                label='Dni'
                                id='dni'
                                name='dni'
                            />
                        </div>
                        <div className="col-4 pb-10 pb-lg-12">
                            <FormikControl
                                control='select'
                                label='Sexo'
                                id='sexo'
                                name='sexo'
                                options={[{
                                    nombre: 'Masculino',
                                }, {
                                    nombre: 'Femenino'
                                }]}
                            />
                        </div>
                        <div className="col-4 mt-7">
                            <button type="button" className="btn btn-primary font-weight-bold mr-2" onClick={handleClick}>
                                <span className='btn-icon svg-icon-2'>
                                    <KTSVG path='/media/icons/duotune/general/gen021.svg' />
                                </span>
                                Buscar
                            </button>
                        </div>
                    </div>
                    <div className="row d-flex ">

                        <div className="col-6">
                            <FormikControl
                                control='input'
                                label='Apellido'
                                name='apellido'
                            />
                        </div>
                        <div className="col-6">
                            <FormikControl
                                control='input'
                                label='Nombre'
                                name='nombre'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};