import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'

type Props = {
  activePage: any
  totalPages: any
  setActivePage: any
}

export const Pagination: React.FC<Props> = ({activePage, setActivePage, totalPages}) => {
  return (
    <div className='pag-cen-tabla not-border-tabla'>
      <ul className='pagination'>
        <li className='page-item disabled'>
          <button
            className='btn btn-sm btn-light-primary menu-btn-cud'
            disabled={activePage === 1}
            onClick={() => setActivePage(1)}>
            <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-3' /> Primero
          </button>
        </li>
        <li className='page-item '>
          <button
            className='btn btn-sm btn-light-primary menu-btn-cud'
            disabled={activePage === 1}
            onClick={() => setActivePage(activePage - 1)}>
            <KTSVG path='/media/icons/duotune/arrows/arr022.svg' className='svg-icon-3' />
            Anterior
          </button>
        </li>
        <li className='page-item '>
          <button
            className='btn btn-sm btn-light-primary menu-btn-cud'
            disabled={activePage === totalPages}
            onClick={() => setActivePage(activePage + 1)}>
            Siguiente <KTSVG path='/media/icons/duotune/arrows/arr071.svg' className='svg-icon-3' />
          </button>
        </li>
        <li className='page-item '>
          <button
            className='btn btn-sm btn-light-primary menu-btn-cud'
            disabled={activePage === totalPages}
            onClick={() => setActivePage(totalPages)}>
            Ultimo <KTSVG path='/media/icons/duotune/arrows/arr080.svg' className='svg-icon-3' />
          </button>
        </li>
      </ul>
    </div>
  )
}
