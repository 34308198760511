/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'

import * as Yup from 'yup'
import {useFormik} from 'formik'
import {IUpdatePassword, updatePassword} from '../SettingsModel'
import {cambiarPassword} from '../../../../../../services/UsuarioService'
import Swal from 'sweetalert2'

interface props {
  usuario?: string
}
const passwordFormValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Password es requerido'),
  newPassword: Yup.string()
    .min(8, 'Mínimo 8 caracteres')

    .max(12, 'Máximo 12 símbolos')
    .required('Constraseña es requerida')
    .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, 'Necesita un caracter especial'),
  passwordConfirmation: Yup.string()
    .min(8, 'Mínimo 8 caracteres')
    .max(12, 'Máximo 12 simbolos')
    .required('Constraseña es requerida')
    .oneOf([Yup.ref('newPassword'), null], 'La contgraseña débe coincidir!'),
})

const SignInMethod: React.FC<props> = ({usuario}) => {
  const [passwordUpdateData, setPasswordUpdateData] = useState<IUpdatePassword>(updatePassword)
  const [showPasswordForm, setPasswordForm] = useState<boolean>(false)

  const [loading2, setLoading2] = useState(false)

  const formik2 = useFormik<IUpdatePassword>({
    initialValues: {
      ...passwordUpdateData,
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: async (values) => {
      let data: any = {
        usuario: usuario,
        password: values.currentPassword,
        passwordNuevo: values.newPassword,
      }
      let op = 'cambiarPSW'
      await cambiarPassword(data, op).then((res: any) => {
        if (res.data.ok) {
          Swal.fire({
            icon: 'success',
            title: 'El cambio fue registrado, correctamente',
          })
          setLoading2(true)
          setTimeout((values) => {
            setPasswordUpdateData(values)
            setLoading2(false)
            setPasswordForm(false)
          }, 1000)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${res.data.msg}`,
          })
        }
      })
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_signin_method'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder text-blanco m-0'>Modificar Contraseña</h3>
        </div>
      </div>

      <div id='kt_account_signin_method' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='d-flex flex-wrap align-items-center mb-10'>
            <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
              <div className='fs-6 fw-bolder mb-1'>Contraseña</div>
              <div className='fw-bold text-gray-600'>************</div>
            </div>

            <div
              id='kt_signin_password_edit'
              className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
            >
              <form
                onSubmit={formik2.handleSubmit}
                id='kt_signin_change_password'
                className='form'
                noValidate
              >
                <div className='row mb-1'>
                  <div className='col-lg-4'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='currentpassword' className='form-label fs-6 fw-bolder mb-3'>
                        Contraseña actual
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid '
                        id='currentpassword'
                        {...formik2.getFieldProps('currentPassword')}
                      />
                      {formik2.touched.currentPassword && formik2.errors.currentPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='error'>{formik2.errors.currentPassword}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-lg-4'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                        Contraseña nueva
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid '
                        id='newpassword'
                        {...formik2.getFieldProps('newPassword')}
                      />
                      {formik2.touched.newPassword && formik2.errors.newPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='error'>{formik2.errors.newPassword}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-lg-4'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                        Confirmar contraseña
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid '
                        id='confirmpassword'
                        {...formik2.getFieldProps('passwordConfirmation')}
                      />
                      {formik2.touched.passwordConfirmation && formik2.errors.passwordConfirmation && (
                        <div className='fv-plugins-message-container'>
                          <div className='error'>{formik2.errors.passwordConfirmation}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='form-text mb-5 error'>
                  La contraseña débe tener al menos 8 caracteres y contener un símbolo
                </div>

                <div className='d-flex'>
                  <button
                    id='kt_password_submit'
                    type='submit'
                    className='btn btn-primary me-2 px-6'
                  >
                    {!loading2 && 'Actualizar contraseña'}
                    {loading2 && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Por favor espere...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button
                    onClick={() => {
                      setPasswordForm(false)
                      formik2.resetForm()
                    }}
                    id='kt_password_cancel'
                    type='button'
                    className='btn btn-color-gray-400 btn-active-light-primary px-6'
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>

            <div
              id='kt_signin_password_button'
              className={'ms-auto ' + (showPasswordForm && 'd-none')}
            >
              <button
                onClick={() => {
                  setPasswordForm(true)
                }}
                className='btn btn-light btn-active-light-primary'
              >
                Modificar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {SignInMethod}
