import { calTypes } from "../types/types"


const initialState = {
  areas: [],
}

export const areasReducer = (state = initialState, action: any) => {


  switch (action.type) {
    case calTypes.setAreas:

      return {
        ...state,
        areas: action.payload.areas
      }

    case calTypes.remAreas:
      return {}
    default:
      return state
  }
}
