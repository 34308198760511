import React from 'react'
import FormikControl from '../../../../../../components/Formik/FormikControl'
interface props {
  update: boolean
}

const Step1DetallesModulo: React.FC<props> = ({update}) => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        {update ? (
          <h2 className='fw-bolder d-flex align-items-center text-dark'>
            Actualizar datos.
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Billing is issued based on your selected account type'
            ></i>
          </h2>
        ) : (
          <h2 className='fw-bolder d-flex align-items-center text-dark'>
            Complete los datos.
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Billing is issued based on your selected account type'
            ></i>
          </h2>
        )}

        <div className='text-gray-400 fw-bold fs-6'>
          Es necesario completar correctamente los datos, para el correcto funcionamiento del
          codigo.
        </div>
      </div>

      <div className='fv-row'>
        <div className='row pb-10 pb-lg-15'>
          <div className='col-lg-6'>
            <FormikControl
              control='input'
              label='Nombre del Modulo'
              name='nombre'
              placeholder='Nombre del Modulo'
              className='form-control form-control-solid'
            />
          </div>
          <div className='col-lg-6'>
            <FormikControl
              control='input'
              label='Pagina de inicio'
              name='paginaInicio'
              placeholder='nombreModulo/.....'
              className='form-control form-control-solid'
            />
          </div>
        </div>
        <div className='row '>
          <div className='col-lg-12'>
            <FormikControl
              name='descripcion'
              control='textarea'
              className='form-control form-control-solid'
              placeholder='Descripción del Modulo'
              label='Breve descripción, de las principales funciones del modulo '
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step1DetallesModulo
