import React from 'react'
import {ITablaBens} from '../interfaces/ITablaBens'
import {PieChart, Pie, Cell} from 'recharts'
import {CSVLink} from 'react-csv'
import {SiMicrosoftexcel} from 'react-icons/si'
type state = {
  cx: any
  cy: any
  midAngle: any
  innerRadius: any
  outerRadius: any
  percent: any
  index: any
}
const Indicadores: React.FC<ITablaBens> = ({className, tituloMenu, tablaDatos}) => {
  //heders CVS
  const headers = [
    {label: 'Masculinos', key: 'Masculinos'},
    {label: 'Femeninos', key: 'Femeninos'},
    {label: 'Otros', key: 'Otros'},
    {label: 'Aprobados', key: 'Aprobados'},
    {label: 'Validados', key: 'Validados'},
    {label: 'Pendientes', key: 'Pendientes'},
    {label: 'Totales', key: 'Totales'},
  ]

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']
  const RADIAN = Math.PI / 180
  const renderCustomizedLabel: React.FC<state> = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
      >
        {`${(percent * 100).toFixed(1)}%`}
      </text>
    )
  }

  return (
    <div>
      <div className={`card ${className}`}>
        <div className='card-body border-0 pb-4 pt-3'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{tituloMenu}</span>
          </h3>
        </div>

        <div className='row'>
          <div className='col-4  offset-lg-1'>
            <span className='fw-bolder fs-5 mb-1  offset-lg-2'>Totales y Aprobados:</span>
            <PieChart width={250} height={200}>
              <Pie
                data={tablaDatos.dataTotales}
                cx='50%'
                cy='50%'
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill='#8884d8'
                dataKey='value'
              >
                {tablaDatos.dataTotales?.map((entry: any, index: any) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
            <div>
              <input type='color' value='#0088FE' className='indicador-color' />
              <span className='pb'>
                {tablaDatos.dataTotales[0].name} : {tablaDatos.dataTotales[0].value}
              </span>
            </div>
            <div>
              <input type='color' value='#00C49F' className='indicador-color' />

              <span>
                {tablaDatos.dataTotales[1].name} : {tablaDatos.dataTotales[1].value}
              </span>
            </div>
            <div>
              <input type='color' value='#ffbb28' className='indicador-color' />

              <span>
                {tablaDatos.dataTotales[2].name} : {tablaDatos.dataTotales[2].value}
              </span>
            </div>
          </div>
          <div className='col-4'>
            <span className='fw-bolder fs-5 mb-1 offset-lg-4'>Genero:</span>
            <PieChart width={250} height={200}>
              <Pie
                data={tablaDatos.dataGenero}
                cx='50%'
                cy='50%'
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill='#8884d8'
                dataKey='value'
              >
                {tablaDatos.dataGenero?.map((entry: any, index: any) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
            <div className=' offset-lg-2'>
              <input type='color' value='#0088FE' className='indicador-color' />
              <span className='pb'>
                {tablaDatos.dataGenero[0].name} : {tablaDatos.dataGenero[0].value}
              </span>
            </div>
            <div className=' offset-lg-2'>
              <input type='color' value='#00C49F' className='indicador-color' />
              <span>
                {tablaDatos.dataGenero[1].name} : {tablaDatos.dataGenero[1].value}
              </span>
            </div>
            <div className=' offset-lg-2'>
              <input type='color' value='#FFBB28' className='indicador-color' />
              <span>
                {tablaDatos.dataGenero[2].name} : {tablaDatos.dataGenero[2].value}
              </span>
            </div>
          </div>
          <div className='col-2'>
            <CSVLink data={tablaDatos.dataCvs!} filename={'Bens.csv'} headers={headers}>
              <button className='btn btn-sm btn-light-primary'>
                <SiMicrosoftexcel />
                Exportar CVS
              </button>
            </CSVLink>
          </div>
        </div>
      </div>
    </div>
  )
}
export {Indicadores}
