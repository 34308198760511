/** React */
import { BrowserRouter as Router, Switch, Route, NavLink, Redirect } from 'react-router-dom'
import { useState } from 'react'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
/** Component Metronic */
import PageTitleCud from './components/PageTitleCud'
/** Pages */
import {Cud, Legajos, Tramites, Juntas, TramitesEdit, JuntaEdit, LegajoEdit, DocumEdit, OrigenesEdit, EditCud} from './pages/Index'
/** Menu Components */
import {
  CalendarioTurnos,
  Encuesta,
  Indicadores,
  JuntasMedicas,
  MesaEntrada,
  TramitesLegajos,
} from './components/Menu/Index'
/** Styles Cud */
import './styles/styles.scss'
import { BootstrapTooltip } from './helpers/bootstrapToolTip'
import { FormCud } from './pages/FormCud'
import { useSelector } from 'react-redux'
import { ISTATE } from '../../../../setup/redux/interface/reduxState.interface'

import { ErrorsPage } from '../../errors/ErrorsPage'
import { ICreateModulo } from '../Sis-admin/interfaces/ICreateModulo'

import { Page404Cud } from './pages/page404Cud'

export const CudRoute = () => {
  /** btn filter states  */
  const [btnClick, setBtnClick] = useState(false)
  const [routeTitle, setRouteTitle] = useState('')

  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)
  let { perfil } = dashState
  const modulo: ICreateModulo = perfil.find((value: any) => value.nombre === 'cud')

  return (
    <>
      <div className='card-title fw-bolder text-white'>
        <PageTitleCud breadcrumbs={[]}>CUD</PageTitleCud>
      </div>
      <Router>
        <div className='card-xxl-stretch mb-5 mb-xl-8'>
          <div className='card'>
            <div className='card-body py-3'>
              <div className='row '>
                {dashState?.perfil.map((valu: any) => {
                  return valu.nombre === 'cud' ? (
                    <>
                      <div className='col-9'>
                        <div className='d-flex align-items-center probandoconlea'>
                          {valu.menu.map((menus: any, index: any) => {
                            switch (menus.name) {
                              case 'mesa de entradas':
                                return (
                                  <div key={index} className='flex-grow-1 mx-2 '>
                                    <NavLink
                                      to='/cud/MesaEntrada'
                                      activeClassName='nav-active'
                                      exact
                                    >
                                      <button
                                        className='btn btn-xl btn-light-primary capital menu-btn-cud'
                                        name='Mesa de Entrada'
                                      >
                                        Mesa de Entrada
                                      </button>
                                    </NavLink>
                                  </div>
                                )

                              case 'tramites con legajos':
                                return (
                                  <div key={index} className='flex-grow-1 mx-2'>
                                    <NavLink to='/cud/Legajos' activeClassName='nav-active' exact>
                                      <button
                                        className='btn btn-xl btn-light-primary capital menu-btn-cud'
                                        name='Legajos'
                                      >
                                        Legajos
                                      </button>
                                    </NavLink>
                                  </div>
                                )

                              case 'juntas medicas':
                                return (
                                  <div key={index} className='flex-grow-1 mx-2'>
                                    <NavLink
                                      to='/cud/JuntasMedicas'
                                      activeClassName='nav-active'
                                      exact
                                    >
                                      <button
                                        className='btn btn-xl btn-light-primary capital menu-btn-cud'
                                        name='Juntas Medicas'
                                      >
                                        Juntas Medicas
                                      </button>
                                    </NavLink>
                                  </div>
                                )

                              case 'calendario de turnos':
                                return (
                                  <div key={index} className='flex-grow-1 mx-2'>
                                    <NavLink
                                      to='/cud/Calendario'
                                      activeClassName='nav-active'
                                      exact
                                    >
                                      <button
                                        className='btn btn-xl btn-light-primary capital menu-btn-cud'
                                        name='Calendario'
                                      >
                                        Calendario
                                      </button>
                                    </NavLink>
                                  </div>
                                )

                              case 'indicadores':
                                return (
                                  <div key={index} className='flex-grow-1 mx-2'>
                                    <NavLink
                                      to='/cud/Indicadores'
                                      activeClassName='nav-active'
                                      exact
                                    >
                                      <button
                                        className='btn btn-xl btn-light-primary capital menu-btn-cud'
                                        name='Indicadores'
                                      >
                                        Indicadores
                                      </button>
                                    </NavLink>
                                  </div>
                                )
                              case 'encuesta':
                                return (
                                  <div key={index} className='flex-grow-1 mx-2'>
                                    <NavLink
                                      to='/cud/Encuesta'
                                      activeClassName='nav-active'
                                      exact
                                    >
                                      <button
                                        className='btn btn-xl btn-light-primary capital menu-btn-cud'
                                        name='Encuesta'
                                      >
                                        Encuesta
                                      </button>
                                    </NavLink>
                                  </div>
                                )
                              default:
                                return (
                                  <Route key={index} path='/cud/404'>
                                    <ErrorsPage />
                                  </Route>
                                )
                            }
                          })}
                        </div>
                      </div>
                    </>
                  ) : null
                })}

                {routeTitle ? (
                  <div className='col-3 '>
                    <BootstrapTooltip title='Tipos de busquedas' arrow followCursor placement='top'>
                      <button
                        className='btn btn-bg-primary btn-color-light btn-active-light btn-active-color-primary  capital'
                        name='Filtros'
                        onClick={() => setBtnClick(true)}
                      >
                        <FilterAltIcon /> Filtros
                      </button>
                    </BootstrapTooltip>
                  </div>
                ) : (
                  <Route path='/cud/404'>
                    <ErrorsPage />
                  </Route>
                )}
              </div>
            </div>
          </div>
        </div>

        <Switch>
          <Route path='/cud/home'>
            <Cud />
          </Route>
          <Route
            path='/cud/NuevoTramite'
            render={() => {
              const menuAct = modulo.menu.find((value) => value.name === 'mesa de entradas')

              return menuAct?.roleUs?.crear ? <Tramites /> : <Page404Cud />
            }}
          ></Route>
          <Route
            path='/cud/MesaEntrada'
            render={() => {
              const menuAct = modulo.menu.find((value) => value.name === 'mesa de entradas')

              return menuAct?.roleUs?.crear ||
                menuAct?.roleUs?.editor ||
                menuAct?.roleUs?.consulta ? (
                <MesaEntrada
                  className={''}
                  click={btnClick}
                  setBtnClick={setBtnClick}
                  setRouteTitle={setRouteTitle}
                />
              ) : (
                <Redirect to={`/cud/404`} />
              )
            }}
          ></Route>

          <Route path='/cud/EditarTramite'>
            <TramitesEdit />
          </Route>
          <Route path='/cud/NuevoLegajo'>
            <Legajos />
          </Route>
          <Route
            path='/cud/EditLegajo'
            render={() => {
              const menuAct = modulo.menu.find((value) => value.name === 'tramites con legajos')

              return menuAct?.roleUs?.crear || menuAct?.roleUs?.editor ? (
                <LegajoEdit />
              ) : (
                <Redirect to={`/cud/404`} />
              )
            }}
          ></Route>
          <Route
            path='/cud/Legajos'
            render={() => {
              const menuAct = modulo.menu.find((value) => value.name === 'tramites con legajos')

              return menuAct?.roleUs?.crear ||
                menuAct?.roleUs?.editor ||
                menuAct?.roleUs?.consulta ? (
                <TramitesLegajos
                  className=''
                  click={btnClick}
                  setBtnClick={setBtnClick}
                  setRouteTitle={setRouteTitle}
                />
              ) : (
                <Redirect to={`/cud/404`} />
              )
            }}
          ></Route>
          <Route
            path='/cud/editDoc'
            render={() => {
              const menuAct = modulo.menu.find((value) => value.name === 'tramites con legajos')

              return menuAct?.roleUs?.crear || menuAct?.roleUs?.editor ? (
                <DocumEdit />
              ) : (
                <Redirect to={`/cud/404`} />
              )
            }}
          ></Route>
          <Route
            path='/cud/editOrig'
            render={() => {
              const menuAct = modulo.menu.find((value) => value.name === 'tramites con legajos')

              return menuAct?.roleUs?.crear || menuAct?.roleUs?.editor ? (
                <OrigenesEdit />
              ) : (
                <Redirect to={`/cud/404`} />
              )
            }}
          ></Route>
          <Route
            path='/cud/editCud'
            render={() => {
              const menuAct = modulo.menu.find((value) => value.name === 'tramites con legajos')

              return menuAct?.roleUs?.crear || menuAct?.roleUs?.editor ? (
                <EditCud />
              ) : (
                <Redirect to={`/cud/404`} />
              )
            }}
          ></Route>

          <Route
            path='/cud/data'
            render={() => {
              const menuAct = modulo.menu.find((value) => value.name === 'tramites con legajos')

              return menuAct?.roleUs?.crear || menuAct?.roleUs?.editor ? (
                <FormCud />
              ) : (
                <Redirect to={`/cud/404`} />
              )
            }}
          ></Route>
          <Route path='/cud/EditJunta'>
            <JuntaEdit />
          </Route>
          <Route path='/cud/Juntas'>
            <Juntas />
          </Route>
          <Route
            path='/cud/Indicadores'
            render={() => {
              const menuAct = modulo.menu.find((value) => value.name === 'indicadores')

              return menuAct?.roleUs?.crear ||
                menuAct?.roleUs?.editor ||
                menuAct?.roleUs?.consulta ? (
                <Indicadores className={''} setRouteTitle={setRouteTitle} />
              ) : (
                <Page404Cud />
              )
            }}
          ></Route>

          <Route
            path='/cud/JuntasMedicas'
            render={() => {
              const menuAct = modulo.menu.find((value) => value.name === 'juntas medicas')

              return menuAct?.roleUs?.crear ||
                menuAct?.roleUs?.editor ||
                menuAct?.roleUs?.consulta ? (
                <JuntasMedicas className={''} setRouteTitle={setRouteTitle} />
              ) : (
                <Redirect to={`/cud/404`} />
              )
            }}
          ></Route>
          <Route path='/cud/Calendario'>
            <CalendarioTurnos className={''} setRouteTitle={setRouteTitle} />
          </Route>
          <Route
            path='/cud/Encuesta'
            render={() => {
              const menuAct = modulo.menu.find((value) => value.name === 'encuesta')

              return menuAct?.roleUs?.crear ||
                menuAct?.roleUs?.editor ||
                menuAct?.roleUs?.consulta ? (
                <Encuesta className='card-xxl-stretch mb-5 mb-xl-8' />
              ) : (
                <Page404Cud />
              )
            }}
          ></Route>
          <Route path='*'>
            <Redirect to='/page404' />
          </Route>
        </Switch>
      </Router>
    </>
  )
}

