import {ErrorMessage, Field, Form} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {convertToRaw, EditorState} from 'draft-js'
import {Editor} from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import {IFormNoticia, inits} from '../CreateNoticia'
import {prepareDraft} from '../../../../utils/utilsFunctions'
import TextErrorFormik from '../../../../../../../components/Formik/TextErrorFormik'

type Props = {
  valor?: any
  objet: IFormNoticia
  sethasConetentFalse: () => void
  sethasConetentTrue: () => void
}

const Step2: FC<Props> = ({valor, objet, sethasConetentTrue}) => {
  const [valueFormik, setValueFormik] = useState(inits)

  const [editorState, setEditorState] = useState(
    valueFormik.cuerpo ? prepareDraft(valueFormik.cuerpo) : EditorState.createEmpty()
  )
  const onEditorStateChange = (editorState: EditorState) => {
    const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    valor.values.cuerpo = forFormik
    if (valor.values.cuerpo !== '') {
      sethasConetentTrue()
    }
    setEditorState(editorState)
  }
  useEffect(() => {
    setValueFormik(valueFormik)
  }, [])

  return (
    <div className='w-100'>
      <div className='p-top-6 pb-4 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Cuerpo
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Agregar el cuerpo de la noticia en la caja de texto inferior.
        </div>
        <hr className='text-gray-500' />
      </div>
      <Form>
        <div className='pb-6 col-lg-12'>
          <label className='form-label fw-bolder text-dark fs-6'>Bajada</label>
          <Field
            name= 'bajada'
            placeholder='Ingrese Bajada..'
            type='text'
            className='form-control form-control-solid'
          />
        </div>
        <label className='form-label fw-bolder text-dark fs-6'>Cuerpo</label>

        <Field type='input' name='cuerpo'>
          {() => {
            return (
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                placeholder='Ingrese aqui el cuerpo de la noticia'
                wrapperClassName='custom-wrapper'
                editorClassName='editorClass'
              />
            )
          }}
        </Field>
      </Form>
    </div>
  )
}
export {Step2}
