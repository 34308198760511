/* import { style } from '@mui/system' */
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ISTATE} from '../../setup/redux/interface/reduxState.interface'
import {IEventos} from '../modules/apps/Agenda/pages/CrearEventoPage/Steps/EventoData'
import {
  evCargarFiltro,
  evCargarPublicos,
  eventoCargarFull,
  setEvFiltro,
  setEvFull,
  setEvPub,
} from '../modules/apps/Agenda/Redux/actions/eventos'
import Agenda_Service from '../modules/apps/Agenda/Redux/services/Agenda_Service'

moment.locale('es')

type Props = {
  className: string
  chartColor: string
}
const ListaEventos: React.FC<Props> = ({className, chartColor}) => {
  const dispatch = useDispatch()
  const usrState: any = useSelector((state: ISTATE) => state.auth)
  const {usuario} = usrState
  const evState: any = useSelector((state: ISTATE) => state.eventos)
  const {eventosPublicos} = evState
  const [eventos, setEventos] = useState([])
  let temp: any = []
  let eventos10: any = []

  function eventosDiez() {
    let evTemp: any = []
    const hoy = new Date()
    temp.map((dato: any) => {
      let fechaIni = new Date(dato.start)
      if (hoy <= fechaIni) {
        let datoTemp = {}
        datoTemp = {
          color: dato.color,
          inicio: dato.start,
          titulo: dato.title,
        }
        evTemp.push(datoTemp)
      }
    })
    evTemp.sort(function compare(a: any, b: any) {
      if (a.inicio < b.inicio) {
        return -1
      }
      if (a.inicio > b.inicio) {
        return 1
      }
      return 0
    })
    setEventos(evTemp)
  }

  useEffect(() => {
    Agenda_Service.buscarEventos().then((res: any) => {
      let datosFull: IEventos[] = res.data
      dispatch(setEvFull(datosFull))
      let areaPUsuario = usuario.organizacion.datosOrganismo.areaPrincipal.idAreaP
      for (let ev = 0; ev < datosFull.length; ev++) {
        const e: IEventos = datosFull[ev]
        if (e.idArea !== areaPUsuario && e.tipo === 'privado') {
        } else {
          temp.push(e)
        }
      }
      dispatch(setEvPub(temp))
      eventosDiez()
    })
  }, [])

  /*   useEffect(() => {
      Agenda_Service.consultaEventos().then((res: any) => {
  
        let datosFull: IEventos[] = res.data
        let areaPUsuario = usuario.organizacion.datosOrganismo.areaPrincipal.idAreaP
        dispatch(setEvFull(datosFull))
        for (let ev = 0; ev < datosFull.length; ev++) {
          const e: IEventos = datosFull[ev]
          if (e.idArea !== areaPUsuario && e.tipo === 'privado') {
          } else {
            temp.push(e)
          }
        }
        dispatch(evCargarFiltro(temp))
        dispatch(evCargarPublicos(temp))
        eventosDiez()
      })
    }, []) */

  useEffect(() => {
    for (let i = 0; i < eventos.length && i < 10; i++) {
      const element = eventos[i]
      eventos10.push(element)
      /*  //console.log('eventos10', eventos10) */
    }
    setEventos(eventos10)
  }, [eventos.length])

  return (
    <div className={`card sombras ${className}`}>
      {/* begin::Header */}
      <div className={`card-header border-0 py-1 `} style={{backgroundColor: '#708090'}}>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-white mt-4'>Actividades Programadas</span>
          <span className='text-white fw-bold fs-7'>Proximos 10 eventos</span>
        </h3>
      </div>

      <div className='card-body pt-4'>
        {/* begin::Timeline */}
        {eventos?.length > 0 ? (
          <div className='timeline-label-lista'>
            {/* begin::Item */}

            {eventos?.map((e: any, index: any) => (
              <div key={index} className='timeline-item'>
                {/* begin::Label */}
                <div className='timeline-label-lista  timeline-label fw-bolder text-gray-800 fs-8'>
                  {moment(e.inicio).format('DD/MM - HH:mm').toString()}
                </div>
                {/* end::Label */}
                {/* begin::Badge */}
                <div className='timeline-badge'>
                  <i className='fa fa-genderless fs-1' style={{color: e.color}}></i>
                </div>
                {/* end::Badge */}
                {/* begin::Content */}
                <div className='timeline-content d-flex capital'>
                  <span className='fw-bolder text-gray-800 ps-3'>{e.titulo}</span>
                </div>
                {/* end::Content */}
              </div>
            ))}
          </div>
        ) : (
          <div className='col'>
            <span className='fw-bolder mb-2 mt-4'>
              No se encontraron eventos programados para los proximos 10 días
            </span>
          </div>
        )}
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export {ListaEventos}
