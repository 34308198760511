import * as React from 'react'

import { Box, Button, FormControl, InputLabel, NativeSelect, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import { useLocation } from 'react-router-dom'
import { saveEquipo } from '../services/telefonia-service'
import '../../../../../_metronic/assets/sass/Telefonia.scss'

export const NuevoEquipo = () => {
  const location: any = useLocation()
  ////console.log('data:' + location.state.numero)

  const opciones_estado = [
    { value: true, label: 'ACTIVO' },
    { value: false, label: 'NO ACTIVO' },
  ]

  return (
    <div className='card'>
      <div className='card-body'>
        <Formik
          initialValues={{
            nlinea: location.state.numero,
            marca: '',
            modelo: '',
            imei: '',
            compania: '',
            estado_equipo: true,
            fecha_inicio: '',
          }}
          onSubmit={(values, { resetForm }) => {
            ////console.log(values)
            saveEquipo(values).then((res: any) => {
              ////console.log(res)
            })
          }}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <TextField
                type='text'
                id='nlinea'
                name='nlinea'
                className='input_hidden'
                variant='standard'
                label='Nº linea'
                value={location.state.numero}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p></p>

              <div className='container1'>
                <TextField
                  type='text'
                  id='marca'
                  name='marca'
                  className='input_cargo'
                  variant='standard'
                  label='Marca'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p></p>

                <TextField
                  type='text'
                  id='modelo'
                  name='modelo'
                  className='input_cargo'
                  variant='standard'
                  label='Modelo'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <p></p>

                <TextField
                  type='text'
                  id='imei'
                  name='imei'
                  className='input_cargo'
                  variant='standard'
                  label='IMEI'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <Box sx={{ minWidth: 120 }}>
                  <FormControl className='select_lebel'>
                    <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                      &nbsp;&nbsp;&nbsp;Compañia
                    </InputLabel>
                    <NativeSelect
                      defaultValue={''}
                      inputProps={{
                        name: 'compania',
                        id: 'compania',
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={''}></option>
                      <option value={'CLARO'}>CLARO</option>
                      <option value={'MOVISTAR'}>MOVISTAR</option>
                      <option value={'PERSONAL'}>PERSONAL</option>
                    </NativeSelect>
                  </FormControl>
                </Box>

                <p></p>

                <div>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl className='select_lebel'>
                      <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                        &nbsp;&nbsp;&nbsp;Estado
                      </InputLabel>
                      <NativeSelect
                        defaultValue={''}
                        inputProps={{
                          name: 'estado_equipo',
                          id: 'estado_equipo',
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value={''}></option>
                        <option value={'true'}> ACTIVO</option>
                        <option value={'false'}>NO ACTIVO</option>
                      </NativeSelect>
                    </FormControl>
                  </Box>

                  <Box sx={{ minWidth: 120 }}>
                    <FormControl className='select_lebel'>
                      <TextField
                        type='date'
                        id='fecha_inicio'
                        name='fecha_inicio'
                        className='input_fecha'
                        variant='standard'
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormControl>
                  </Box>
                </div>
                <TextField
                  type='text'
                  id='observaciones'
                  name='observaciones'
                  className='input_obs'
                  variant='standard'
                  label='Obs'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <p></p>
                <p></p>

                <Button type='submit' className='button1'>
                  Guardar Equipo
                </Button>
              </div>
            </Form>
          )}
        </Formik>{' '}
      </div>
    </div>
  )
}
