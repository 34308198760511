import {request} from '../../../../../setup/axios/axios.util'
import {ICreateModulos} from '../interfaces/ICreateModulo'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const URL = `${API_URL}/modules/sis-admin/usuarios`

export const postUs = (usuarioNuevo: {}) => {
  let urlPost = `${API_URL}/modules/sis-admin/usuario`
  let us = {data: {}}
  us.data = usuarioNuevo

  return request().post(urlPost, us)
}

export const getUsuarios = () => {
  return request().get(URL)
}
export const getOneUsuarios = (usuario: string) => {
  let urlGetOnce = `${API_URL}/modules/sis-admin/usuarios/${usuario}`
  return request().get(urlGetOnce)
}

export const postPerfilUs = (perfil: ICreateModulos, idUsuario: string, update: boolean) => {
  let urlPost = `${API_URL}/modules/sis-admin/usuarioPerfil/${idUsuario}`
  let us = {
    data: {
      perfilUs: perfil,
    },
    op: update ? 'upd' : 'alta',
  }

  return request().patch(urlPost, us)
}

interface IcambiarPassword {
  usuario: string
  password: string
  passwordNuevo: string
}

export const cambiarPassword = (value: IcambiarPassword, op?: string) => {
  const {usuario, password, passwordNuevo} = value
  let urlPost = `${API_URL}/modules/sis-admin/usuarioPerfil/chpsw`
  let us = {
    data: {
      usuario,
      password,
      passwordNuevo,
    },
    op: op,
  }
  return request().post(urlPost, us)
}

export const cambiarPasswordPorEmail = (value: IcambiarPassword, op?: string) => {
  const {usuario, password, passwordNuevo} = value
  let urlPost = `${API_URL}/auth/changePassword`
  let us = {
    data: {
      usuario,
      password,
      passwordNuevo,
    },
    op: op,
  }
  return request().post(urlPost, us)
}

export const deshabilitarCuenta = (usuario: string) => {
  let urlPost = `${API_URL}/modules/sis-admin/usuarioPerfil/deshabilitar`

  let us = {
    data: {
      usuario,
    },
  }
  return request().post(urlPost, us)
}
export const updateDatosPer = (datosPersonales: {}, datosContacto: {}, usuario: string) => {
  let us = {
    data: {
      datosPersonales,
      datosContacto,
    },
  }
  let urlPatch = `${API_URL}/modules/sis-admin/updData/${usuario}`

  return request().patch(urlPatch, us)
}
