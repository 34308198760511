import { clippingParents } from '@popperjs/core'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useReactToPrint } from 'react-to-print'
import Swal from 'sweetalert2'
import { KTSVG } from '../../../../../../_metronic/helpers'
import Spinner from '../../../../../components/spinner/Spinner'
import { ExportPDF } from '../Exports/ExportPDF'
type props = {
  show: boolean
  handleCloseModal: () => void
  msjes: any
  footer: any
}

class PDF {
  render() {
    // console.log(this.props.data[0].dni);

  }
}

export const ModalBusqueda: React.FC<props> = ({ show, handleCloseModal, msjes, footer }) => {

  return (
    <Modal

      className=''
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}

      centered
      aria-hidden='true'


    >
      <div className='modal-content'>
        <div className='modal-body modal-center'>
          <div className='centrado '>

            <i>{msjes}</i>

            <Spinner></Spinner>
            <b><i>Por favor espere un momento...</i></b>
          </div>
        </div>

        {footer ? (
          <div className='modal-footer'>
            CERRAR
          </div>
        ) : null}
      </div>
    </Modal>
  )
}
