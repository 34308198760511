import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip'
import {useHistory} from 'react-router-dom'
import {styled} from '@mui/material/styles'
import './BotonMenu.scss'
import clsx from 'clsx'
import {Typography} from '@mui/material'
import Swal from 'sweetalert2'

type Props = {
  imagen: string
  pag_inicio: string
  nombre?: string
  descripcion?: string
  activo?: boolean
}
const BotonMenu: React.FC<Props> = ({imagen, pag_inicio, nombre, descripcion, activo}) => {
  let history = useHistory()
  let fuente: any = ''

  const LightTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${fuente}`,
      color: `#FFFFFF`,
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontWeightBold: 700,
      maxWidth: 200,
    },
  }))

  return (
    <div className={`col-12`}>
      {activo ? (
        <div
          className='pointer'
          onClick={() => {
            let inico = pag_inicio.split(':')
            inico[0] === 'https' ? window.open(pag_inicio, '_blank') : history.push(pag_inicio)
          }}
        >
          <LightTooltip
            className='negrita'
            children={
              <div className={clsx('pt-4')}>
                <div
                  data-kt-menu-trigger='click'
                  data-kt-menu-attach='parent'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='bottom'
                >
                  <div className='d-flex flex-column flex-wrap align-items-center'>
                    <div className={`card card-boton sombras`}>
                      <img className={`img-boton`} src={imagen} alt='' />
                    </div>
                    <div className='text-secondary uppercase centrado fw-bold fs-8 pt-3'>
                      {nombre}
                    </div>
                  </div>
                </div>
              </div>
            }
            title={
              <Typography
                color='inherit'
                style={{fontWeight: 600, textTransform: 'capitalize', textAlign: 'center'}}
              >
                {descripcion}
              </Typography>
            }
          ></LightTooltip>
        </div>
      ) : (
        <LightTooltip
          onClick={() =>
            Swal.fire({
              icon: 'warning',
              title: 'Fuera de Servicio',
              text: 'El Modulo, momentaneamente se encuenta fuera de servicio. Disculpe las molestias.',
            })
          }
          className='negrita'
          children={
            <div className={clsx('pt-4')}>
              <div
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
              >
                <div className='d-flex flex-column align-items-center'>
                  <div className={`card card-boton`}>
                    <img className={`img-boton`} src={imagen} alt='' />
                  </div>
                  <div className='text-secondary uppercase fw-bold fs-8 pt-3'>{nombre}</div>
                </div>
              </div>
            </div>
          }
          title={
            <Typography
              color='inherit'
              style={{fontWeight: 600, textTransform: 'capitalize', textAlign: 'center'}}
            >
              {descripcion}
            </Typography>
          }
        ></LightTooltip>
      )}
    </div>
  )
}
export {BotonMenu}
