import {Form, Formik} from 'formik'
import React, {useEffect, useRef, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../../_metronic/helpers'
import Spinner from '../../../../components/spinner/Spinner'
import {Step1DtsPersonales} from '../components/StepFormEmp/Step1DtsPersonales'
import {Step2DtsLaborales} from '../components/StepFormEmp/Step2DtsLaborales'
import {empleadoInit, validationSchema} from '../components/StepFormEmp/helpersFormEmpleado'
import {IEmpleados} from '../interfaces/IEmpleados'
import Swal from 'sweetalert2'
import {getPersona} from '../../Sis-admin/services/PersonaService'
import {verificarEdad, verificarLocalidad} from '../util/verificacionDatosEmpleados'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
import {
  altaEmpleado,
  getEmpleadosOneEmpresa,
  upLoadFileFormEmpleado,
} from '../services/EJ.Empleado.Services'
import {Step3DtsDoc} from '../components/StepFormEmp/Step3DtsDoc'
import {calcularMesEmpleado} from '../util/formUsEmp'

export const FormEmpleado = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [volver, setVolver] = useState(true)
  const [btnUpdate, setBtnUpdate] = useState<boolean>(false)
  const [currentSchema, setCurrentSchema] = useState(validationSchema[0])
  const [initValuesForm, setInitValuesForm] = useState<IEmpleados>(empleadoInit)
  const [editValuesForm, setEditValuesForm] = useState<IEmpleados>()

  const [load, setLoad] = useState(false)
  const [wsIsok, setWsIsok] = useState(false)
  const history = useHistory()
  const item: any = useLocation()
  moment.locale('es')

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger',
    },
    buttonsStyling: true,
  })
  const authState: any = useSelector((state: ISTATE) => state.auth)
  let {usuario} = authState

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    stepper.current?.currentStepIndex === 1 && setInitValuesForm(empleadoInit)
    setVolver(true)
    setBtnUpdate(false)
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(validationSchema[stepper.current.currentStepIndex - 1])
  }
  const submitStep = async (values: IEmpleados, actions: any) => {
    if (!stepper.current) {
      return
    }

    setCurrentSchema(validationSchema[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      let fecha_convertida = moment().format('LL')
      let mes = fecha_convertida.split(' ')
      let documentos: any = []
      values.fechaCarga = moment().format('DD/MM/YYYY')
      values.estado = 'pendiente'
      values.cuit = usuario.usuario
      values.anio[0].meses = calcularMesEmpleado(mes[2])
      // values.datosLaborales.fechaAlta = moment(values.datosLaborales.fechaAlta).format('DD/MM/YYYY')

      swalWithBootstrapButtons
        .fire({
          title: 'Términos y condiciones según lo establecido en la reglamentación Ley 3360',
          text: 'El Usuario deberá respetar estos Términos y Condiciones, establecidos en la presente ley. Las infracciones por acción u omisión de los presentes Términos y Condiciones de Uso generarán el derecho a favor del Administrador de aplicar las saciones correspondientes y suspender al Usuario que las haya realizado.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Acepto',
          cancelButtonText: 'No acepto!',
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            values.terminos_condiciones = true
            //   console.log(values)
            upLoadFileFormEmpleado(
              values.documento,
              values.cuit,
              values.altaAfip,
              values.progSocial,
              values.historialAnses,
              values.ddjjEmp
            )
              .then(async (resp: any) => {
                // console.log(resp)
                if (resp.data.ok) {
                  for (let doc of resp.data.fileSaves) {
                    let fileName = Object.keys(doc)[0]
                    documentos.push({
                      tipo_doc: fileName,
                      id_db_fileEj: doc[fileName],
                      estado: 'pendiente',
                      fecha_carga: moment().format('DD/MM/YYYY'),
                      fecha_modf: '',
                    })
                  }

                  values.documentosUnicos = documentos

                  await altaEmpleado(values).then(async (res: any) => {
                    if (res.status === 200) {
                      actions.resetForm()
                      await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'El empleado, fue cargado con exito',
                        showConfirmButton: false,
                        timer: 1500,
                      })
                    } else {
                      actions.resetForm()
                      await Swal.fire({
                        title: 'Error!',
                        text: 'Verificar los datos ingresados',
                        icon: 'error',
                        confirmButtonText: 'Volver',
                      })
                    }
                  })

                  setInitValuesForm(empleadoInit)
                  setEditValuesForm(empleadoInit)
                  setWsIsok(false)
                  actions.resetForm()
                  history.push({pathname: 'misempleados'})
                }
              })
              .catch(async (err: any) => {
                await Swal.fire({
                  title: 'Error!',
                  text: `${err}`,
                  icon: 'error',
                  confirmButtonText: 'Volver',
                })
              })
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            setInitValuesForm(empleadoInit)
            setEditValuesForm(empleadoInit)
            setWsIsok(false)
            actions.resetForm()
            history.push({pathname: 'misempleados'})
          }
        })
    }
  }
  const buscaWsPersona = async (element: IEmpleados) => {
    setLoad(true)

    getEmpleadosOneEmpresa(usuario.usuario, element.documento).then(async (resp: any) => {
      if (resp.data.ok) {
        await Swal.fire({
          title: 'El empleado ya se encuentra cargado',
          text: `${resp.data.datosPersonales.apellido.toLowerCase()}, ${resp.data.datosPersonales.nombres.toLowerCase()}`,
          icon: 'warning',
          confirmButtonText: 'Volver',
        })

        history.push({pathname: 'misempleados'})
      } else {
        getPersona(element.documento, element.datosPersonales.sexo.toUpperCase()).then(
          (resp: any) => {
            if (resp.data.msg !== 'Datos no encontrados') {
              resp.data.persona.sexo = element.datosPersonales.sexo
              verificarLocalidad(resp.data.persona.provincia)
                .then((res: any) => {
                  verificarEdad(resp.data.persona.fecha_nacimiento)
                    .then((res: any) => {
                      setInitValuesForm({
                        ...initValuesForm,
                        datosPersonales: resp.data.persona,
                        documento: element.documento,
                      })
                      setWsIsok(true)
                      setLoad(false)
                      // stepper.current?.goto(2)
                    })
                    .catch((error) => {
                      setLoad(false)
                      Swal.fire({
                        icon: 'error',
                        title: 'No cumple con la Reglamentación Ley 3360',
                        text: `${error}`,
                      })
                    })
                })
                .catch((error) => {
                  setLoad(false)
                  Swal.fire({
                    icon: 'error',
                    title: 'No cumple con la Reglamentación Ley 3360',
                    text: `${error}`,
                  })
                })
            } else {
              setLoad(false)
              Swal.fire({
                icon: 'error',
                title: 'Oops',
                text: 'Verificar los datos Ingresados',
              })
            }
          }
        )
      }
    })
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
    item.state && setEditValuesForm(item.state)
    item.state && setBtnUpdate(true)
  }, [stepperRef, item])
  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Datos Personales</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Datos Laborales</h3>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Documentación Artículo 9º</h3>
            </div>
          </div>

          <Formik
            validationSchema={currentSchema}
            initialValues={initValuesForm || editValuesForm}
            enableReinitialize
            validateOnMount={true}
            onSubmit={submitStep}
          >
            {(formik) => {
              // console.log(formik)
              return (
                <Form
                  className='mx-auto mw-600px w-100 pt-15 pb-10'
                  noValidate
                  id='kt_create_account_form'
                >
                  <div className='current' data-kt-stepper-element='content'>
                    <Step1DtsPersonales wsOk={wsIsok} />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step2DtsLaborales />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <Step3DtsDoc formik={formik} />
                  </div>
                  <div className='d-flex flex-stack pt-15'>
                    {volver ? (
                      <div className='mr-2'>
                        <Link to={'misempleados'}>
                          <button type='button' className='btn btn-lg btn-light-primary me-3'>
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr063.svg'
                              className='svg-icon-4 me-1'
                            />
                            Volver
                          </button>
                        </Link>
                      </div>
                    ) : (
                      <div className='mr-2'>
                        <button
                          onClick={prevStep}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr063.svg'
                            className='svg-icon-4 me-1'
                          />
                          Volver
                        </button>
                      </div>
                    )}
                    {load && <Spinner />}
                    <div>
                      {btnUpdate ? (
                        <button
                          type='submit'
                          className='btn btn-lg btn-primary me-3'
                          disabled={!formik.isValid || formik.isSubmitting}
                          onClick={() => setVolver(false)}
                        >
                          <span className='indicator-label'>
                            {stepper.current?.currentStepIndex! < stepper.current?.totatStepsNumber!
                              ? 'Continuar'
                              : 'Actualizar'}

                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        </button>
                      ) : wsIsok ? (
                        <button
                          type='submit'
                          className='btn btn-lg btn-primary me-3'
                          disabled={!formik.isValid || formik.isSubmitting}
                          onClick={() => setVolver(false)}
                        >
                          <span className='indicator-label'>
                            {stepper.current?.currentStepIndex! < stepper.current?.totatStepsNumber!
                              ? 'Continuar'
                              : 'Guardar'}

                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        </button>
                      ) : (
                        <button
                          className='btn btn-lg btn-primary me-3'
                          type='button'
                          onClick={() => {
                            buscaWsPersona(formik.values)
                            setVolver(false)
                          }}
                          disabled={!formik.isValid}
                        >
                          <span className='indicator-label'>
                            Buscar
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr071.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}
