import { request } from '../../../../../setup/axios/axios.util'
import { ICreateArea } from '../interfaces/ICreateArea'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const URL = `${API_URL}/modules/sis-admin`
const localJwt = sessionStorage.getItem('token')

const headers: any = { 'Content-Type': 'application/json' }

if (localJwt) {
  headers['Authorization'] = `jwt ${localJwt}`
}

export const postArea = (obj: ICreateArea, id_org: string) => {
  let data = { data: {}, idOrg: '' }
  data.data = obj
  data.idOrg = id_org
  let postUrl = `${URL}/area`
  return request().post(postUrl, data, headers)
}
export const postSubArea = (
  obj: ICreateArea,
  id_org: string,
  id_area: string,
  nombreArea: string
) => {
  let data = { data: {}, id_org, id_area, nombreArea }
  data.data = obj
  let postUrl = `${URL}/area/subarea`
  return request().post(postUrl, data)
}

export const postOficina = async (
  obj: ICreateArea,
  id_org: string,
  id_area: string,
  id_subArea: string,
  nombreArea: string,
  nombreSubArea: string
) => {
  let data = { data: {}, id_org, id_area, nombreArea, id_subArea, nombreSubArea }
  data.data = obj

  let postUrl = `${URL}/area/subarea/oficina`
  return request().post(postUrl, data)
}

export const getAll = () => {
  let getUrl = `${URL}/organizaciones`
  return request().get(getUrl, headers)
}
export const getOneAreaOrg = (nombreArea?: string) => {
  //////console.log(nombreArea)
  let getUrl = `${URL}/areOrg/${nombreArea}`
  return request().get(getUrl)
}

export const putSistemAreaOrg = (nombreArea: string, estado: boolean) => {
  let opApi = ''
  estado ? (opApi = 'activarArea') : (opApi = 'desactivarArea')

  let patchUrl = `${URL}/area/${nombreArea}`
  let data = { op: opApi }

  return request().patch(patchUrl, data)
}

export const putSistemAreaOrgImg = (nombreArea: string, img: string) => {
  let opApi = 'modImg'
  let patchUrl = `${URL}/area/${nombreArea}`
  let data = { op: opApi, imgMod: img }

  return request().patch(patchUrl, data)
}
