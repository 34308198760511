import {ITablaBens} from '../interfaces/ITablaBens'
import {BengAdminTypes} from './Bens-types'

const tablaInicial: ITablaBens = {
  className: 'card-xxl-stretch mb-5 mb-xl-8',
  tituloMenu: 'Administración de Beng',
  tablaDatos: {},
  headColumns: {
    col1: '',
    col2: '',
    col3: '',
    col4: '',
  },
}

export const TablaDatosBengReducer = (state = tablaInicial, action: any) => {
  switch (action.type) {
    case BengAdminTypes.SetTabla:
      return {
        className: action.payload.tablaDatosBeng.className,
        tituloMenu: action.payload.tablaDatosBeng.tituloMenu,
        tablaDatos: action.payload.tablaDatosBeng.tablaDatos,
        headColumns: action.payload.tablaDatosBeng.headColumns,
      }
    case BengAdminTypes.RemTabla:
      return {tablaInicial}
    default:
      return state
  }
}
