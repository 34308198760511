import {types} from '../types'

export const DashReducer = (state = {}, action: any) => {
  switch (action.type) {
    case types.Dashboard:
      return {
        //modulos: action.payload.perfil,
        nombre: action.payload.nombre,
        perfil: action.payload.perfil,
        isLogged: action.payload.isLogged,
        primerLogin: action.payload.primerLogin,
        modOk: action.payload.modOk,
      }
    case types.logoutDashboard:
      return {}
    default:
      return state
  }
}
