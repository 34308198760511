/** React */
import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
/** Material */
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
/** Metronic */
import { KTSVG } from '../../../../../../_metronic/helpers/components/KTSVG'
/** SweetAlert */
import Swal from 'sweetalert2'
/**Services CUD */
import { localidadesNqn } from '../../../../../services/gral/proLocService'
import { searchByDni, searchByFecha, searchByLegajo, searchByLocalidad } from '../../services/cud-service'
/** Components */
import { BootstrapTooltip } from '../../helpers/bootstrapToolTip'
import { SiMicrosoftexcel } from 'react-icons/si'
import { CSVLink } from 'react-csv'
import { Ifilter } from '../../Interfaces/Ifilter'

/** props component BtnSearch */
type props = {
  titlePage: string
  data: any
  filtroLegajo?: boolean
  setValue: any
  setVista: any
  setBtnClick?: any
  activePage?: any
  setActivePage?: any
  setPagination?: any
  setFilter?: any
}

interface DatosCsv {
  dni: string;
  nombre: string;
  apellido: string;
  localidad: string;
  fecha: string;
  genero: string;
}
export const BtnSearch: React.FC<props> = ({
  titlePage,
  data,
  setValue,
  activePage,
  filtroLegajo,
  setActivePage,
  setFilter,
  setPagination,
  setVista,
  setBtnClick
}) => {
  /** State Localidades */
  const [loc, setLoc] = useState([]);
  /** States inputs Search */
  const [cambio, setCambio] = useState(0);
  const [input, setInput] = useState('');
  const [select, setSelect] = useState('');
  const [date, setDate] = useState('');
  const [dateHasta, setDatehasta] = useState('');

  const [csv, setCsv] = useState<DatosCsv[]>([]);
  const csvLink = useRef<any | null>(null);
  const [csvTotal, setCsvTotal] = useState<DatosCsv[]>([]);

  const headers = [
    { label: 'Dni', key: 'datosPersonales.dni' },
    { label: 'Nombre', key: 'datosPersonales.nombre' },
    { label: 'Apellido', key: 'datosPersonales.apellido' },
    { label: 'Localidad', key: 'datosPersonales.localidad' },
    { label: 'Fecha', key: 'fechaCreacion' },
    { label: 'Genero', key: 'datosPersonales.sexo' },
  ]

  useEffect(() => {
    /** Res Api LOCALIDADES Array*/
    localidadesNqn().then((res: any) => {
      setLoc(res.data.data)
    })
  }, [])

  /** State change radio Input */
  const cambioRadio = (e: any) => {
    const value = parseInt(e.target.value);
    setCambio(value);
    setFilter(null);
    setCsv([]);
    setActivePage(1);
    setPagination({});
    //cleanup();
  }

  const handleClickCsv = () => {
    switch (cambio) {
      case 1:
        setTimeout(() => {
          //setCsv(result.data.res);
          csvLink?.current?.link?.click();
        });
        break;
      case 2:
        const dataLocLegajos: Ifilter = {
          mod: titlePage,
          tipo: 'localidad',
          localidad: select,
          page: 1,
          limit: 0,
        }
        searchByLocalidad(dataLocLegajos).then((result) => {
          setTimeout(() => {
            setCsv(result.data.res);
            csvLink?.current?.link?.click();
          });
        })
        break;
      case 3:
        const dataDateLegajos: any = {
          mod: titlePage,
          tipo: 'fecha',
          fecha: date,
          fechaHasta: dateHasta,
          page: 1,
          limit: 0
        }
        searchByFecha(dataDateLegajos).then((result: any) => {
          setTimeout(() => {
            setCsv(result.data.res);
            csvLink?.current?.link?.click();
          });
        });
        break;
    }
  }

  /** Config Select localidades */
  const LocalidadesArray = loc.map(function (nombre: any) {
    return nombre.nombre
  })
  let AreaArrayNombres: any = []
  for (let element of LocalidadesArray) {
    let arrAr = {
      value: element.toLowerCase(),
      label: element,
    }
    AreaArrayNombres.push(arrAr)
  }


  /** Search function by DNI */
  const switchbydni = () => {
    switch (titlePage) {
      case 'Legajos':
        /** Objet send API */
        const dataDniLegajo: any = {
          mod: titlePage,
          dni: input,
        }
        /** Res Api DNI in Legajos page*/
        searchByDni(dataDniLegajo, 4).then((res: any) => {
          //console.log(res.data);
          setInput('')
          setValue(res.data.res)
          setCsv(res.data.res)

          setVista(true)
          /** Alert if res is 0 */
          if (res.data.res.length === 0) {
            setInput('')
            setVista(false)
            setValue(data)
            Swal.fire({
              icon: 'error',
              text: 'No se encontraron Tramites!',
            })
          }
        })
        break
      case 'Mesa':
        /** Objet send API */
        const dataDniMesa: any = {
          mod: titlePage,
          dni: input,
        }
        /** Res Api DNI in Mesa de entrada page*/
        searchByDni(dataDniMesa, 4).then((res: any) => {
          //console.log(res.data);
          setInput('')
          setValue(res.data.res)
          setCsv(res.data.res)

          setVista(true)
          /** Alert if res is 0 */
          if (res.data.res.length === 0) {
            setInput('')
            setVista(false)
            setValue(data)
            Swal.fire({
              icon: 'error',
              text: 'No se encontraron Tramites!',
            })
          }
        })
        break
    }
  }
  /** Search function by LOCALIDAD */
  const switchbylocalidad = () => {
    switch (titlePage) {
      case 'Legajos':
        /** Objet send API */
        const dataLocLegajos: Ifilter = {
          mod: titlePage,
          tipo: 'localidad',
          localidad: select,
          page: 1,
          limit: 4,
        }
        setFilter(dataLocLegajos)
        /** Res Api LOCALIDAD in Legajos page*/
        searchByLocalidad(dataLocLegajos).then((res: any) => {
          setInput('')
          //setVista(true)
          setVista(false);
          setPagination(res.data)
          setValue(res.data.res)
          setCsv(res.data.res)

          /** Alert if res is 0 */
          if (res.data.res.length === 0) {
            setInput('')
            setVista(false)
            setValue(data)
            Swal.fire({
              icon: 'error',
              text: 'No se encontraron Tramites!',
            })
          }
        })
        break
      case 'Mesa':
        /** Objet send API */
        const dataLocMesa: Ifilter = {
          mod: titlePage,
          tipo: 'localidad',
          localidad: select,
          page: 1,
          limit: 4
        }
        setFilter(dataLocMesa);
        /** Res Api LOCALIDAD in Mesa de entrada page*/
        searchByLocalidad(dataLocMesa).then((res: any) => {
          setInput('');
          setVista(false);
          setPagination(res.data);
          setValue(res.data.res);
          setCsv(res.data.res);

          /** Alert if res is 0 */
          if (res.data.res.length === 0) {
            setInput('')
            setVista(false)
            setValue(data)
            Swal.fire({
              icon: 'error',
              text: 'No se encontraron Tramites!',
            })
          }
        })
        break
    }
  }
  /** Search function by FECHA */
  const switchbyfecha = () => {
    switch (titlePage) {
      case 'Legajos':
        /** Objet send API */
        const dataDateLegajos: any = {
          mod: titlePage,
          tipo: 'fecha',
          fecha: date,
          fechaHasta: dateHasta,
          page: 1,
          limit: 4
        }
        setFilter(dataDateLegajos);
        /** Res Api FECHA in Legajos page*/
        searchByFecha(dataDateLegajos).then((res: any) => {
          setInput('')
          setVista(false);
          setCsv(res.data.res);
          setValue(res.data.res);

          /** Alert if res is 0 */
          if (res.data.res.length === 0) {
            setInput('')
            setVista(false)
            setValue(data)
            Swal.fire({
              icon: 'error',
              text: 'No se encontraron Tramites!',
            })
          }
        })
        break
      case 'Mesa':
        /** Objet send API */
        const dataDateMesa: any = {
          mod: titlePage,
          tipo: 'fecha',
          fecha: date,
          fechaHasta: dateHasta,
          page: 1,
          limit: 4,
        }
        setFilter(dataDateMesa);
        /** Res Api FECHA in Mesa de entrada page*/
        searchByFecha(dataDateMesa).then((res: any) => {
          setInput('');
          setVista(false);
          setCsv(res.data.res);
          setValue(res.data.res);

          /** Alert if res is 0 */
          if (res.data.res.length === 0) {
            setInput('')
            setVista(false)
            setValue(data)
            Swal.fire({
              icon: 'error',
              text: 'No se encontraron Tramites!',
            })
          }
        })
        break
    }
  }

  const switchByLegajo = () => {
    switch (titlePage) {
      case 'Legajos':
        /** Objet send API */
        const dataLegajo: any = {
          mod: titlePage,
          legajo: input?input.trim():'',
        }
        /** Res Api DNI in Legajos page*/
        searchByLegajo(dataLegajo, 4).then((res: any) => {
          //console.log(res.data);
          setInput('')
          setValue(res.data.res)
          setCsv(res.data.res)

          setVista(true)
          /** Alert if res is 0 */
          if (res.data.res.length === 0) {
            setInput('')
            setVista(false)
            setValue(data)
            Swal.fire({
              icon: 'error',
              text: 'No se encontraron Tramites!',
            })
          }
        })
        break;
    }

  }

  return (
    <>
      <div className='row'>
        <div className='col-5'>
          {/* Control of Radio select search */}
          <FormControl>
            <FormLabel id='demo-row-radio-buttons-group-label'>
              Seleccione metodo de busqueda
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby='demo-row-radio-buttons-group-label'
              name='row-radio-buttons-group'
            >
              <FormControlLabel
                value='DNI'
                control={<Radio value={1} onChange={cambioRadio} />}
                label='DNI'
              />
              <FormControlLabel
                value='Localidad'
                control={<Radio value={2} onChange={cambioRadio} />}
                label='Localidad'
              />
              <FormControlLabel
                value='Fecha'
                control={<Radio value={3} onChange={cambioRadio} />}
                label='Fecha'
              />
              {filtroLegajo && 
                <FormControlLabel
                  value='Legajo'
                  control={<Radio value={4} onChange={cambioRadio} />}
                  label='Legajo'
                />
              }
              {csv && csv.length > 0 && (
                <>
                  <BootstrapTooltip title='Exportar csv' arrow followCursor placement='top'>
                    <button className='btn btn-primary btn-sm' onClick={handleClickCsv} >
                      <SiMicrosoftexcel /> CSV
                    </button>
                  </BootstrapTooltip>
                  <CSVLink
                    data={csv}
                    filename={'Cud.csv'}
                    headers={headers}
                    ref={csvLink}
                  />
                </>
              )}
            </RadioGroup>
          </FormControl>

          <div className='row'>
            {/* Input text by DNI */}
            {cambio === 1 ? (
              <>
                <div className='col-8 col-lg-8'>
                  <input
                    type='text'
                    className='form-control'
                    value={input.trim()}
                    placeholder='Buscar por DNI...'
                    onChange={(e: any) => {
                      setInput(e.target.value)
                    }}
                  />
                </div>
                <div className='col-1'>
                  <button type='button' className='btn btn-icon btn-primary' onClick={switchbydni}>
                    <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-3' />
                  </button>
                </div>
              </>
            ) : null}
            {/* Input Select by LOCALIDAD */}
            {cambio === 2 ? (
              <>
                <div className='col-8'>
                  <Select
                    className='form-control-solid'
                    name='slc-Localidad'
                    isMulti={false}
                    options={AreaArrayNombres}
                    closeMenuOnSelect={true}
                    placeholder={'Buscar por Localidad...'}
                    onChange={(e: any) => {
                      setSelect(e.value)
                    }}
                  ></Select>
                </div>
                <div className='col-1'>
                  <button
                    type='button'
                    className='btn btn-icon btn-primary'
                    onClick={switchbylocalidad}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-3' />
                  </button>
                </div>
              </>
            ) : null}
            {/* Input Date by FECHA */}
            {cambio === 3 ? (
              <>
                <div className='col-4'>
                  <input
                    type='date'
                    name='inp-fecha'
                    className='form-control'
                    onChange={(e: any) => {
                      setDate(e.target.value)
                    }}
                  />

                </div>
                <div className='col-4'>
                  <input
                    type='date'
                    name='inp-fecha'
                    className='form-control'
                    onChange={(e: any) => {
                      setDatehasta(e.target.value)
                    }}
                  />

                </div>
                <div className='col-1'>
                  <button
                    type='button'
                    name='btn-fecha'
                    className='btn btn-icon btn-primary'
                    onClick={switchbyfecha}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-3' />
                  </button>
                </div>
              </>
            ) : null}
                        {/* Input text by LEGAJO */}
                        {cambio === 4 ? (
              <>
                <div className='col-8 col-lg-8'>
                  <input
                    type='text'
                    className='form-control'
                    value={input.trim()}
                    placeholder='Buscar por legajo...'
                    onChange={(e: any) => {
                      setInput(e.target.value)
                    }}
                  />
                </div>
                <div className='col-1'>
                  <button type='button' className='btn btn-icon btn-primary' onClick={switchByLegajo}>
                    <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-3' />
                  </button>
                </div>
              </>
            ) : null}
            {/* Input Select by LOCALIDAD */}
          </div>
        </div>
        <div className='col-7 pt-2'>
          {/* btn close filter component */}
          <BootstrapTooltip title='Cerrar filtros' arrow followCursor placement='top'>
            <button
              className='btn btn-icon btn-primary btn-salir'
              onClick={() => {
                setBtnClick(false)
              }}
            >
              {' '}
              <CancelIcon />
            </button>
          </BootstrapTooltip>
        </div>
      </div>
    </>
  )
}
