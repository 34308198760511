import { types } from '../types'
const initialState = {
  clima: [],
}

export const climaReducer = (state = initialState, action: any) => {


  switch (action.type) {
    case types.setClima:

      return {
        ...state,
        clima: action.payload.clima
      }

    case types.remClima:
      return {

      }
    default:
      return state
  }
}
