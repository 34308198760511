import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ISTATE} from '../../../../../../setup/redux/interface/reduxState.interface'
import Box from '@mui/material/Box'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {BotonAreas} from '../Botones/BotonAreas'
import DatePicker from 'react-datepicker'
import es from 'date-fns/locale/es'
import CustomInput from '../../components/PickersCustomInput'
import {setEvFiltro, setFiltros} from '../../Redux/actions/eventos'
import {IEventos} from '../../pages/CrearEventoPage/Steps/EventoData'
import {styled} from '@mui/material/styles'
import Button from '@mui/material/Button'
// import { purple } from '@mui/material/colors';

type ArrayFecha = {
  fechaInicio: Date
  fechaFin: Date
}

export const Filtros = () => {
  const dispatch = useDispatch()
  const [arrLoc, setArrLoc] = useState([])
  const [arrEst, setArrEst] = useState([])
  const [arrFec, setArrFec] = useState<ArrayFecha[]>([])
  const [fechaInicio, setFechaInicio] = useState(new Date())
  const [fechaFin, setFechaFin] = useState(new Date())
  const [inicio, setInicio] = useState(false)
  const [arrPresMin, setArrPresMin] = useState('')
  const [arrCon, setArrCon] = useState('')
  const animatedComponents = makeAnimated()
  const dashState: any = useSelector((state: ISTATE) => state.areas)
  const {areas} = dashState
  const menuState: any = useSelector((state: ISTATE) => state.menuBoton)
  const {seleccion} = menuState
  const eventoState: any = useSelector((state: ISTATE) => state.eventos)
  const {componente, evFull, evPub} = eventoState
  const usrState: any = useSelector((state: ISTATE) => state.auth)
  const {usuario} = usrState
  let areaPUsuario = usuario.organizacion.datosOrganismo.areaPrincipal.idAreaP

  const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 12,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    width: '100%',
    /* backgroundColor: '#0063cc',
    borderColor: '#0063cc', */
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#0062cc',
      backgroundColor: '#FFFFFF',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#FFFFFF',
      borderColor: '#005cbf',
    },
    '&:focus': {
      color: '#0062cc',
      backgroundColor: '#FFFFFF',
      borderColor: '#005cbf',
      boxShadow: '0 0 0 0.2rem rgba(0,255,251,.5)',
    },
  })

  // const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  //   color: theme.palette.getContrastText(purple[500]),
  //   backgroundColor: purple[500],
  //   '&:hover': {
  //     backgroundColor: purple[700],
  //   },
  // }));

  const siMisEventos = (ev: any) => {
    let evTemp: any = []
    ev.map((e: any) => {
      if (e.idArea === areaPUsuario) {
        evTemp.push(e)
      }
      return null
    })
    return evTemp
  }
  useEffect(() => {
    switch (componente) {
      case 'indicadores':
        dispatch(setEvFiltro(evFull))
        dispatch(setFiltros({areas: [], fechaFin: '', fechaInicio: ''}))
        break
      case 'agendacompleta':
        dispatch(setEvFiltro(evPub))
        dispatch(setFiltros({areas: [], fechaFin: '', fechaInicio: ''}))
        break
      case 'miagenda':
        let ev = siMisEventos(evPub)
        dispatch(setEvFiltro(ev))
        dispatch(setFiltros({areas: [], fechaFin: '', fechaInicio: ''}))
        break
      case 'calendario':
        dispatch(setFiltros({areas: [], fechaFin: '', fechaInicio: ''}))
        break
      default:
        break
    }
  }, [componente])

  function seleccionarTablaFiltro() {
    switch (componente) {
      case 'indicadores':
        dispatch(setEvFiltro(evFull))
        return evFull
      case 'agendacompleta':
        dispatch(setEvFiltro(evPub))
        return evPub
      case 'miagenda':
        dispatch(setEvFiltro(siMisEventos(evPub)))
        return siMisEventos(evPub)
      default:
        break
    }
  }
  function TablaFiltro() {
    switch (componente) {
      case 'indicadores':
        return evFull
      case 'agendacompleta':
        return evPub
      case 'miagenda':
        return siMisEventos(evPub)
      default:
        break
    }
  }

  function capitalize(s: string) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase()
    })
  }

  function buscarLocalidades() {
    let localidadesTemp: any = []
    let ev = TablaFiltro()
    ev?.map((e: IEventos) => {
      let opt = {
        label: e.localidad.toLowerCase(),
        value: e.localidad.toLowerCase(),
      }
      localidadesTemp.push(opt)
      return null // add this line to fix the error
    })
    let localidadesMap = localidadesTemp.map((item: any) => {
      return [item.value, item.label]
    })
    let localidadesMapArr = new Map(localidadesMap) // Pares de clave y valor
    let unicos: any = Array.from(localidadesMapArr).map(([label, value]) => ({label, value}))
    return unicos
  }

  function buscarEstados() {
    let estadosTemp: any = []
    let ev = TablaFiltro()
    ev?.map((e: IEventos) => {
      let opt = {
        label: e.estado,
        value: capitalize(e.estado),
      }
      estadosTemp.push(opt)
      return null // add this line to fix the error
    })
    let localidadesMap = estadosTemp.map((item: any) => {
      return [item.value, item.label]
    })
    let localidadesMapArr = new Map(localidadesMap) // Pares de clave y valor
    let unicos: any = Array.from(localidadesMapArr).map(([label, value]) => ({label, value}))
    return unicos
  }

  function filtrarXEventos() {
    let evTabla = seleccionarTablaFiltro()
    switch (componente) {
      case 'indicadores':
        dispatch(setEvFiltro(evFull))
        evTabla = evFull
        break
      case 'agendacompleta':
        dispatch(setEvFiltro(evPub))
        evTabla = evPub
        break
      case 'miagenda':
        let ev = siMisEventos(evPub)
        evTabla = ev
        dispatch(setEvFiltro(ev))
        break
      default:
        break
    }
    let evTempSel: any = []
    seleccion.forEach((sel: any) => {
      if (componente === 'miagenda') {
        sel.selec = sel.id !== areaPUsuario
        evTabla?.forEach((ev: IEventos) => {
          if (sel.id === ev.idArea) {
            evTempSel.push(ev)
          }
        })
      } else {
        if (sel.selec) {
          evTabla?.forEach((ev: IEventos) => {
            if (sel.id === ev.idArea) {
              evTempSel.push(ev)
            }
          })
        }
      }
    })
    let eventosTempFec: any = []
    let eventosTempLoc: any = []
    let eventosTempEst: any = []
    let eventosTempPresMin: any = []
    let eventosTempCon: any = []
    if (arrFec.length !== 0) {
      evTempSel.forEach((e: IEventos) => {
        let find = arrFec.find(
          (fecha: any) =>
            new Date(e.start) >= fecha.fechaInicio && new Date(e.start) <= fecha.fechaFin
        )
        if (find) {
          eventosTempFec.push(e)
        }
      })
    } else {
      eventosTempFec = evTempSel
    }
    if (arrLoc.length !== 0) {
      eventosTempFec.forEach((e: IEventos) => {
        let find = arrLoc.find((loc: any) => loc === e.localidad)
        if (find) {
          eventosTempLoc.push(e)
        }
      })
    } else {
      eventosTempLoc = eventosTempFec
    }
    if (arrEst.length !== 0) {
      eventosTempLoc.forEach((e: IEventos) => {
        let find = arrEst.find((loc: any) => loc === e.estado)
        if (find) {
          eventosTempEst.push(e)
        }
      })
    } else {
      eventosTempEst = eventosTempLoc
    }

    switch (arrPresMin) {
      case 'Si':
        eventosTempEst.forEach((e: IEventos) => {
          if (e.presMinistro) {
            eventosTempPresMin.push(e)
          }
        })
        break
      case 'No':
        eventosTempEst.forEach((e: IEventos) => {
          if (!e.presMinistro) {
            eventosTempPresMin.push(e)
          }
        })
        break
      default:
        eventosTempPresMin = eventosTempEst
        break
    }
    switch (arrCon) {
      case 'Si':
        eventosTempPresMin.forEach((e: IEventos) => {
          if (e.conclusion !== '') {
            eventosTempCon.push(e)
          }
        })
        break
      case 'No':
        eventosTempPresMin.forEach((e: IEventos) => {
          if (e.conclusion === '') {
            eventosTempCon.push(e)
          }
        })
        break

      default:
        eventosTempCon = eventosTempPresMin
        break
    }

    return eventosTempCon
  }

  useEffect(() => {
    let filtrosTemp = {
      areas: seleccion,
      fechaInicio: '',
      fechaFin: '',
    }
    dispatch(setFiltros(filtrosTemp))
  }, [])

  useEffect(() => {
    let temp = filtrarXEventos()
    if (inicio) {
      let filtrosTemp = {
        areas: seleccion,
        fechaInicio: fechaInicio,
        fechaFin: fechaFin,
      }
      dispatch(setFiltros(filtrosTemp))
    }
    setInicio(true)

    dispatch(setEvFiltro(temp))
  }, [arrFec, arrLoc, arrEst, arrCon, arrPresMin, componente, fechaFin, fechaInicio])

  useEffect(() => {
    let temp = filtrarXEventos()

    dispatch(setEvFiltro(temp))
  }, [seleccion])

  return (
    <>
      <div
        id='kt_explore_scroll'
        className='scroll-y me-n5 pe-5'
        data-kt-scroll='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-wrappers='#kt_explore_body'
        data-kt-scroll-dependencies='#kt_explore_header, #kt_explore_footer'
        data-kt-scroll-offset='5px'
      >
        {componente !== 'miagenda' && (
          <div className='rounded border border-dashed border-gray-300 py-4 px-6 mb-5'>
            <div className='d-flex flex-row  flex-wrap'>
              <div className='d-flex align-items-center mb-1'>
                <BotonAreas nombre={`Todas`} nombreCorto={'Todas'} colorFondo={'#c5c5c5'} id={''} />
              </div>
              <div className='d-flex align-items-center mb-1'>
                <BotonAreas
                  nombre={`Ninguna`}
                  nombreCorto={'Ninguna'}
                  colorFondo={'#c5c5c5'}
                  id={''}
                />
              </div>
              {areas.map((area: any) => (
                <div className='d-flex align-items-center mb-1'>
                  <BotonAreas
                    nombre={`${area.tipoArea} de ${area.nombreArea}`}
                    nombreCorto={area.detalleSistema.nombreCorto}
                    colorFondo={area.detalleSistema.colorFondo}
                    id={area._id}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        <div className='rounded border border-dashed border-gray-300 py-4 px-6 mb-5'>
          <div className='d-flex flex-column  flex-wrap'>
            <div className='col mb-1'>
              <div className='row mb-4 pt-2' data-kt-buttons='true'>
                <div className='col-12'>
                  <BootstrapButton
                    variant='contained'
                    disableRipple
                    className='btn-lg btn-block'
                    onClick={() => {
                      let inicio = new Date('2020-06-01')
                      let dateIni = new Date('2020-06-01')
                      for (let fin = 0; fin < evFull.length; fin++) {
                        const f = evFull[fin]
                        if (new Date(f.start) >= inicio) {
                          inicio = new Date(f.start)
                        }
                      }
                      let date = inicio
                      let primerDia = new Date(dateIni.getFullYear(), dateIni.getMonth(), 1)
                      let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0)
                      primerDia.setHours(0, 0, 0, 0)
                      setFechaInicio(primerDia)
                      ultimoDia.setHours(23, 59, 59, 999)
                      setFechaFin(ultimoDia)
                      let fecTemp: any = [
                        {
                          fechaInicio: primerDia,
                          fechaFin: ultimoDia,
                        },
                      ]
                      setArrFec(fecTemp)
                    }}
                  >
                    Todos los eventos Cargados
                  </BootstrapButton>
                </div>
              </div>
              <div className='row mb-4 pt-2' data-kt-buttons='true'>
                <div className='col-6 '>
                  <BootstrapButton
                    variant='contained'
                    disableRipple
                    className='btn-lg btn-block'
                    onClick={() => {
                      let date = new Date()
                      let primerDia = new Date(date.getFullYear(), date.getMonth(), 1)
                      let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0)
                      primerDia.setHours(0, 0, 0, 0)
                      setFechaInicio(primerDia)
                      ultimoDia.setHours(23, 59, 59, 999)
                      setFechaFin(ultimoDia)
                      let fecTemp: any = [
                        {
                          fechaInicio: primerDia,
                          fechaFin: ultimoDia,
                        },
                      ]
                      setArrFec(fecTemp)
                    }}
                  >
                    Mes en Curso
                  </BootstrapButton>
                </div>
                <div className='col-6'>
                  <BootstrapButton
                    variant='contained'
                    disableRipple
                    className='btn-lg btn-block'
                    onClick={() => {
                      let dia = new Date()
                      let primeroSemana = new Date(dia.setDate(dia.getDate() - dia.getDay() + 1))
                      let firstday = new Date(
                        primeroSemana.setDate(primeroSemana.getDate() - primeroSemana.getDay() - 6)
                      )
                      let lastday = new Date(
                        primeroSemana.setDate(primeroSemana.getDate() - primeroSemana.getDay() + 7)
                      )
                      firstday.setHours(0, 0, 0, 0)
                      lastday.setHours(23, 59, 59, 999)
                      setFechaInicio(firstday)
                      setFechaFin(lastday)

                      let fecTemp: any = [
                        {
                          fechaInicio: firstday,
                          fechaFin: lastday,
                        },
                      ]
                      setArrFec(fecTemp)
                    }}
                  >
                    Semana Anterior
                  </BootstrapButton>
                </div>
              </div>
              <div className='row mb-4 pt-2' data-kt-buttons='true'>
                <div className='col-6'>
                  <BootstrapButton
                    variant='contained'
                    disableRipple
                    onClick={() => {
                      let dia = new Date()
                      let primeroSemana = new Date(dia.setDate(dia.getDate() - dia.getDay() + 1))
                      let firstday = new Date(
                        primeroSemana.setDate(primeroSemana.getDate() - primeroSemana.getDay() + 1)
                      )
                      let lastday = new Date(
                        primeroSemana.setDate(primeroSemana.getDate() - primeroSemana.getDay() + 7)
                      )
                      firstday.setHours(0, 0, 0, 0)
                      lastday.setHours(23, 59, 59, 999)
                      setFechaInicio(firstday)
                      setFechaFin(lastday)

                      let fecTemp: any = [
                        {
                          fechaInicio: firstday,
                          fechaFin: lastday,
                        },
                      ]
                      setArrFec(fecTemp)
                    }}
                  >
                    Semana en Curso
                  </BootstrapButton>
                </div>

                <div className='col-6'>
                  <BootstrapButton
                    variant='contained'
                    disableRipple
                    onClick={() => {
                      let dia = new Date()
                      let primeroSemana = new Date(dia.setDate(dia.getDate() - dia.getDay() + 1))
                      let firstday = new Date(
                        primeroSemana.setDate(primeroSemana.getDate() - primeroSemana.getDay() + 8)
                      )
                      let lastday = new Date(
                        primeroSemana.setDate(primeroSemana.getDate() - primeroSemana.getDay() + 7)
                      )
                      firstday.setHours(0, 0, 0, 0)
                      lastday.setHours(23, 59, 59, 999)
                      setFechaInicio(firstday)
                      setFechaFin(lastday)

                      let fecTemp: any = [
                        {
                          fechaInicio: firstday,
                          fechaFin: lastday,
                        },
                      ]
                      setArrFec(fecTemp)
                    }}
                  >
                    Próxima Semana
                  </BootstrapButton>
                </div>
              </div>
              <div className='row mb-4 pt-2' data-kt-buttons='true'>
                <div className='col-6'>
                  <Box>
                    <DatePicker
                      locale={es}
                      timeFormat='HH:mm'
                      timeIntervals={15}
                      minDate={new Date('01/01/2015')}
                      selected={fechaInicio}
                      id='date-time-picker-inicio'
                      timeCaption='Dia Inicial'
                      dateFormat='dd/MM/yyyy'
                      name='start'
                      yearDropdownItemNumber={5}
                      showYearDropdown
                      onChange={(date: any) => {
                        date.setHours(0, 0, 0, 0)
                        setFechaInicio(date)
                        setFechaFin(date)

                        let fecTemp: any = [
                          {
                            ...arrFec,
                            fechaInicio: date,
                          },
                        ]
                        setArrFec(fecTemp)
                      }}
                      customInput={<CustomInput label='Fecha Inicial' />}
                    />
                  </Box>
                </div>
                <div className='col-6'>
                  <Box>
                    <DatePicker
                      locale={es}
                      timeFormat='HH:mm'
                      timeIntervals={15}
                      minDate={fechaInicio}
                      selected={fechaFin}
                      id='date-time-picker-inicio'
                      timeCaption='Dia Final'
                      dateFormat='dd/MM/yyyy'
                      name='start'
                      yearDropdownItemNumber={5}
                      showYearDropdown
                      onChange={(date: any) => {
                        date.setHours(23, 59, 59, 999)
                        setFechaFin(date)

                        let fecTemp: any = [
                          {
                            fechaInicio: fechaInicio,
                            fechaFin: date,
                          },
                        ]
                        setArrFec(fecTemp)
                      }}
                      customInput={<CustomInput label='Fecha Final' />}
                    />
                  </Box>
                </div>
              </div>

              <div className='fw-bold fs-8'>Localidades</div>
              <Select
                className='form-label fw-bolder text-dark fs-8'
                closeMenuOnSelect={true}
                placeholder={'Localidades'}
                components={animatedComponents}
                isMulti
                name='localidades'
                options={buscarLocalidades()}
                onChange={(e: any) => {
                  let temp: any = []
                  if (e.length !== 0) {
                    for (let loc of e) {
                      temp.push(loc.value.toLowerCase())
                    }
                  }
                  setArrLoc(temp)
                }}
              ></Select>
            </div>
            <div className='col mb-1'>
              <div className='fw-bold fs-8'>Estados</div>
              <Select
                className='form-label fw-bolder text-dark fs-8'
                closeMenuOnSelect={true}
                placeholder={'Estados'}
                components={animatedComponents}
                isMulti
                name='estados'
                options={buscarEstados()}
                onChange={(e: any) => {
                  let temp: any = []
                  if (e.length !== 0) {
                    for (let loc of e) {
                      temp.push(loc.value.toLowerCase())
                    }
                  }
                  setArrEst(temp)
                }}
              ></Select>
            </div>
            <div className='col mb-1'>
              <div className='fw-bold fs-8'>Presencia Ministro/a</div>
              <Select
                className='form-label fw-bolder text-dark fs-8'
                closeMenuOnSelect={true}
                placeholder={'Presencia Ministro/a'}
                components={animatedComponents}
                defaultValue={''}
                name='presencia'
                // options={[{ value: 'Si', label: 'Si' }, { value: 'No', label: 'No' }, { value: 'Todas', label: 'Todas' }]}
                onChange={(e: any) => {
                  setArrPresMin(e.value)
                }}
              ></Select>
            </div>
            <div className='col mb-1'>
              <div className='fw-bold fs-8'>Conclusión</div>
              <Select
                className='form-label fw-bolder text-dark fs-8'
                closeMenuOnSelect={true}
                placeholder={'Conclusión'}
                components={animatedComponents}
                defaultValue={''}
                name='conclusion'
                // options={[{ value: 'Si', label: 'Si' }, { value: 'No', label: 'No' }, { value: 'Todas', label: 'Todas' }]}
                onChange={(e: any) => {
                  setArrCon(e.value)
                }}
              ></Select>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
