/** React */
import { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
/**  Icons Material UI */
import InfoIcon from '@mui/icons-material/Info'
import {Box, Button, Collapse, styled} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
/** Interfaces */
import { Ilegajo, Ilegajos } from '../../Interfaces/Ilegajo'
/** Moment */
import moment from 'moment'
/** Helper */
import { BootstrapTooltip } from '../../helpers/bootstrapToolTip'

import { Pagination } from '../../helpers/pagination'

/* 
import {ModalPrevPDF} from '../Modal/ModalPrevPDF' */
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { ModaleEntrega } from '../Modal/ModaleEntrega'
import { useReactToPrint } from 'react-to-print'

moment.locale('es-mx')

type Props = {
  legajo: Ilegajos
  vista: boolean
  activePage: any
  totalPages: any
  setActivePage: any
  activando?: any
}
export const DataLegajo: React.FC<Props> = ({
  legajo,
  vista,
  activePage,
  totalPages,
  setActivePage,
  activando,
}) => {
  const locationBtnPrincipal: any = useLocation()

  //PDF
  const [show, setShow] = useState<boolean>(false)
  const handleCloseModal = () => setShow(false)

  const [badgeColor, setbadgeColor] = useState('badge bg-info text-dark')
  const [legajos, setLegajos] = useState<Ilegajos>([])
  const [dataSeleccionada, setDataSeleccionada] = useState<any>([])
  const [dataCud, setDataCud] = useState<any>({})
  let componentRef: any = useRef()
  const handelprint = useReactToPrint({
    content: () => componentRef.current,
  })

  let history = useHistory()

  useEffect(() => {
    dataSeleccionada.length > 1 && setDataSeleccionada([])
    setLegajos(legajo)
  }, [legajo, dataSeleccionada])

  const mfuncion = (leg: any) => {
    history.push({ pathname: '/cud/Legajos', state: null })
    activando = false
    setDataSeleccionada((data: any) => [...data, leg])
    window.location.reload()
  }

  const badgeCorlor = (estado: string): string => {
    switch (estado) {
      case 'pendiente':
        return 'badge bg-info '
      case 'aprobado':
        return 'badge badge-light-success'
      case 'rechazado':
        return 'badge badge-light-danger'
      case 'archivado':
        return 'badge badge-secondary'
      default:
        return ''
    }
  }
  useEffect(() => {
    let isMounted = true
    locationBtnPrincipal.state?.activado
      ? setDataSeleccionada([locationBtnPrincipal.state.obj])
      : setDataSeleccionada([])
    return () => {
      isMounted = false
    }
  }, [locationBtnPrincipal])

  useEffect(() => {
    let ultimo = dataSeleccionada[0]?.datosCud[dataSeleccionada[0].datosCud.length - 1]

    setbadgeColor(badgeCorlor(ultimo?.estado))

    setDataCud(ultimo)
  }, [dataSeleccionada])

  return (
    <>
      <table className='table align-middle gs-0 gy-4'>
        <thead className='border-tabla-right'>
          <tr className='fw-bolder bg-light'>
            <th className='min-w-4px'></th>
            <th className='min-w-50px'>Dni</th>
            <th className='min-w-50px'>Legajo</th>
            <th className='min-w-100px'>Apellido</th>
            <th className='min-w-100px'>Nombre</th>
            <th className='min-w-100px'>Localidad</th>
            <th className='min-w-100px'>Junta</th>
            <th className='min-w-100px'>Genero</th>
            <th className='min-w-25px'>Acciones</th>
            <th className='min-w-8px'></th>
          </tr>
        </thead>
        <tbody className='border-tabla-top'>
          {dataSeleccionada.length === 0
            ? legajos?.map((value: Ilegajo, index: any) => {
              return (
                <tr className='table_font_normal' key={index}>
                  <td></td>
                  <td>{value.datosPersonales.dni}</td>
                  <td>{value.legajo}</td>

                  <td>
                    {value.datosPersonales.apellido.replace(/\b\w/g, (l) => l.toUpperCase())}
                  </td>
                  <td>
                    {value.datosPersonales.nombre.replace(/\b\w/g, (l) => l.toUpperCase())}{' '}
                  </td>
                  <td>
                    {value.datosPersonales.localidad.replace(/\b\w/g, (l) => l.toUpperCase())}
                  </td>
                  <td>{value.junta.replace(/\b\w/g, (l) => l.toUpperCase())}</td>
                  <td>{value.datosPersonales.sexo.replace(/\b\w/g, (l) => l.toUpperCase())}</td>
                  {activando !== true ? (
                    <td>
                      <BootstrapTooltip title='Detalles' arrow followCursor placement='top'>
                        <button
                          className='btn btn-icon btn-bg-primary btn-color-light btn-active-light btn-active-color-primary btn-sm me-1'
                          onClick={() => setDataSeleccionada((data: any) => [...data, value])}
                        >
                          <InfoIcon />
                        </button>
                      </BootstrapTooltip>
                    </td>
                  ) : (
                    setDataSeleccionada((data: any) => [...data, value])
                  )}
                </tr>
              )
            })
            : dataSeleccionada?.map((value: Ilegajo, index: any) => {
              return (
                <>
                  <tr className='table_font_normal' key={index}>
                    <td></td>
                    <td>{value.datosPersonales.dni}</td>
                    <td>
                      {value.datosPersonales.apellido.replace(/\b\w/g, (l) => l.toUpperCase())}
                    </td>
                    <td>
                      {value.datosPersonales.nombre.replace(/\b\w/g, (l) => l.toUpperCase())}
                    </td>
                    <td>
                      {value.datosPersonales.localidad.replace(/\b\w/g, (l) => l.toUpperCase())}
                    </td>
                    <td>{value.junta.replace(/\b\w/g, (l) => l.toUpperCase())}</td>
                    <td>{value.datosPersonales.sexo.replace(/\b\w/g, (l) => l.toUpperCase())}</td>

                    <td>
                      <div>
                        {!activando ? (
                          <BootstrapTooltip title='Cerrar' arrow followCursor placement='top'>
                            <button
                              className='btn btn-icon btn-bg-primary btn-color-light btn-active-light btn-active-color-primary btn-sm me-1'
                              onClick={() => setDataSeleccionada((data: any) => [...data, value])}
                            >
                              <CancelIcon />
                            </button>
                          </BootstrapTooltip>
                        ) : (
                          <BootstrapTooltip title='Cerrar' arrow followCursor placement='top'>
                            <button
                              className='btn btn-icon btn-bg-primary btn-color-light btn-active-light btn-active-color-primary btn-sm me-1'
                              onClick={() => {
                                mfuncion(value)
                              }}
                            >
                              <CancelIcon />
                            </button>
                          </BootstrapTooltip>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: 0, paddingTop: 5 }} colSpan={9}>
                      <Collapse in={true} timeout='auto' unmountOnExit>
                        <Box sx={{ margin: 2 }} className='no-margin-top'>
                          <div className='mt-5 fv-row'>
                            <div className='row'>
                              <div className='col-lg-3'>
                                <label className=''>Legajo:</label>
                                <label className='form-label m-0'>
                                  &nbsp;
                                  {value.legajo}
                                </label>
                              </div>
                              <div className='col-lg-3'>
                                <label className=''>Observaciones:</label>
                                <label className='form-label m-0'>
                                  &nbsp;
                                  {value.observaciones
                                    ? value.observaciones.replace(/\b\w/g, (l) => l.toUpperCase())
                                    : '-'}
                                </label>
                              </div>
                            </div>
                            <br />
                            <br /> <br />
                          </div>
                          <span>Último Tramite Vigente</span>
                          <br />
                          <hr />
                          {/* Inicia la tabla para el mostrar el ultimo certificado! */}
                          {dataCud && (
                            <table className='table align-middle gs-0 gy-4'>
                              <thead className='border-tabla-right'>
                                <tr className='fw-bolder bg-light'>
                                  <th className='min-w-4px texto_center'></th>
                                  <th className='min-w-50px texto_center'>Estado</th>
                                  <th className='min-w-50px texto_center'>Fecha de Inicio</th>
                                  {dataCud.estado === 'aprobado' && (
                                    <th className='min-w-50px texto_center'>Fecha Aprobación</th>
                                  )}
                                  {dataCud.estado === 'aprobado' && (
                                    <th className='min-w-100px texto_center'>Nro de CUD</th>
                                  )}
                                  {dataCud.estado === 'aprobado' && (
                                    <th className='min-w-100px texto_center'>
                                      Caducidad del Certificado
                                    </th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                <tr key={'1'}>
                                  <td></td>
                                  <td className='table_cap texto_center'>
                                    <span className={`${badgeColor}`}>{dataCud.estado}</span>
                                  </td>
                                  <td className='table_cap texto_center'>
                                  {dataCud.fechaInicio?.includes('-') ?
                                        moment(dataCud?.fechaInicio).format('DD/MM/YYYY') :
                                        dataCud.fechaInicio
                                      }
                                  </td>
                                  {dataCud.estado === 'aprobado' && (
                                    <td className='table_cap texto_center'>
                                      {dataCud.infoCertificado?.fechaEntregaCert.includes('-') ?
                                        moment(dataCud.infoCertificado?.fechaEntregaCert).format('DD/MM/YYYY') :
                                        dataCud.infoCertificado?.fechaEntregaCert
                                      }
                                    </td>
                                  )}
                                  {dataCud.estado === 'aprobado' && (
                                    <td className='table_cap texto_center'>
                                      <b>{dataCud.infoCertificado.nroCud}</b>
                                    </td>
                                  )}
                                  {dataCud.estado === 'aprobado' && (
                                    <td className='table_cap texto_center'>
                                      {moment(dataCud.infoCertificado.fechaVencimiento).format(
                                        'l'
                                      )}
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                              <></>
                            </table>
                          )}
                          {/* Fin la tabla para el mostrar el ultimo certificado! */}
                          <br />
                          <br />
                          <br />
                          <hr />
                          <div>
                            <Link to={{ pathname: 'editLegajo/', state: value }}>
                              <Button className='btn btn-primary btn-sm'>Legajo</Button>
                              &nbsp;&nbsp;
                            </Link>
                            <Link to={{ pathname: 'editDoc/', state: value }}>
                              <Button className='btn btn-primary btn-sm'>Documentacion</Button>
                            </Link>
                            &nbsp;&nbsp;
                            <Link to={{ pathname: 'data/', state: value }}>
                              <Button className='btn btn-primary btn-sm '>Datos Cud</Button>
                            </Link>
                            &nbsp;&nbsp;
                            <button
                              className='btn btn-primary btn-sm float-end'
                              onClick={() => setShow(true)}
                              // onClick={handelprint}
                              name='Descargar PDF'
                            >
                              Doc. Presentada &nbsp;&nbsp;
                              <PictureAsPdfIcon />
                            </button>
                          </div>
                        </Box>
                      </Collapse>
                    </td>
                  </tr>
                </>
              )
            })}
        </tbody>
      </table>

      {vista === true ? null : dataSeleccionada.length === 0 ? (
        <Pagination setActivePage={setActivePage} activePage={activePage} totalPages={totalPages} />
      ) : null}

      <ModaleEntrega
        show={show}
        dataTramite={dataSeleccionada}
        handleCloseModal={handleCloseModal}
      />
    </>
  )
}
export default DataLegajo
