import Swal from "sweetalert2"
import { KTSVG } from "../../../../../../_metronic/helpers"

type Props = {
    id: any;
    index: number;
    texto: string;
    onDelete: (index: number, id: string) => void;
}

const BotonEliminarMod: React.FC<Props> = ({id, index, texto, onDelete}) => {

    const handleDeleteClick = () => {
        Swal.fire({
            title: `Confirma eliminación de \n ${texto}`,
            text: 'Se eliminarán todos los datos, desea continuar?',
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            denyButtonText: 'Cancelar'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                onDelete(index, id);
            }
        })}
    return (
        <span
           className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            data-bs-toggle='tooltip'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            title='Eliminar'
            onClick={handleDeleteClick}
        >
            <KTSVG
                path='/media/icons/duotune/general/gen040.svg'
                className='svg-icon-3 svg-icon-danger'
            />
        </span>
    )
}

export default BotonEliminarMod