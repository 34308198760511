import {styled, TextField, Tooltip, tooltipClasses, TooltipProps} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Inoticias, Inoticia} from '../../interface/Inoticia'
import {ButtonActions} from '../ButtonActions'
import {CSVLink} from 'react-csv'
import {SiMicrosoftexcel} from 'react-icons/si'
import {color} from '@mui/system'
import {useSelector} from 'react-redux'
import {ISTATE} from '../../../../../../setup/redux/interface/reduxState.interface'
import moment from 'moment'

type Props = {
  csv: Inoticias
  className: string
  noticias: Inoticias
  busqueda: string
  handleChange: any
  count: any
  activePage: any
  rowsPerPage: any
  totalPages: any
  setActivePage: any
}
const BootstrapTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: `${color}`,
    color: `#919A9B`,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${color}`,
    color: `#FFFFFF`,
    boxShadow: theme.shadows[2],
    fontSize: 15,
    fontWeightBold: 700,
    disabled: false,
  },
}))
const TablaNoticia: React.FC<Props> = ({
  className,
  noticias,
  busqueda,
  handleChange,
  setActivePage,
  activePage,
  rowsPerPage,
  totalPages,
  count,
  csv,
}) => {
  const headers = [
    {label: 'Titulo', key: 'titulo'},
    {label: 'Fecha Creacion', key: 'fecha_creacion'},
    {label: 'Organizacion', key: 'nombreOrg'},
  ]

  const [data, setData] = useState<Inoticias>()
  const [selectNoticia, setSelectNoticia] = useState<string>('')
  const [tablaNoticias, setTablaNoticias] = useState<Inoticias>([])
  const [NoticiaOptions, setNoticiaOptions] = useState<boolean>(false)

  const perfilState: any = useSelector((state: ISTATE) => state.dashBoard)

  let Role: any = []
  let array = perfilState.perfil
  for (const menu of array) {
    Role.push(menu)
  }

  const FiltRole: any = Role.filter(function (nombre: {nombre: string}) {
    return nombre.nombre === 'Noticias'
  })

  const FiltMenu = FiltRole[0].menu
  
  const FiltPermisoCrear: any = FiltMenu.filter(function (name: {name: string}) {
    return name.name === 'crear noticia'
  })

  const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1
  const end = activePage === totalPages ? count : beginning + rowsPerPage - 1

  const handelSelecNoticia = (e: any) => {
    selectNoticia === e ? setSelectNoticia('') : setSelectNoticia(e)
    if (selectNoticia !== e) {
      let result = tablaNoticias.filter((data: any) => data._id === e)
      setData(result)
      setNoticiaOptions(!NoticiaOptions)
    } else {
      setData(noticias)
      setNoticiaOptions(!NoticiaOptions)
    }
  }
  useEffect(() => {
    noticias.length > 0 && setData(noticias)
    setTablaNoticias(noticias)
    return () => {}
  }, [noticias])

  
  return (
    <div className={`card sombras ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label text-white fw-bolder fs-3 mb-1'>Tabla de Noticias</span>
        </h3>
        <div className="text-white">
        {selectNoticia ? null : (
          <TextField
            id='standard-basic'
            label='Búsqueda por título'
            variant='standard'
            value={busqueda}
            onChange={handleChange}
          />
        )}
        </div>
        <div className='card-toolbar'>
          {FiltPermisoCrear[0].roleUs.crear ? (
            selectNoticia ? null : (
              <Link to={'CrearNoticia'}>
                <button className='btn btn-sm btn-light-primary correrbtn fs-6'>
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                  Nueva Noticia
                </button>
              </Link>
            )
          ) : null}
          {FiltPermisoCrear[0].roleUs.exportar ? (selectNoticia ? null : (
            <CSVLink data={csv} filename={'noticias.csv'} headers={headers}>
              <BootstrapTooltip title='Exportar CSV' arrow followCursor placement='top'>
                <button className='btn btn-sm btn-light-primary'>
                  <SiMicrosoftexcel />
                </button>
              </BootstrapTooltip>
            </CSVLink>
          )):null}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='w-25px'>
                  <div className='ps-4 form-check form-check-sm form-check-custom form-check-solid '>
                    <input
                      className='form-check-input shadow-check'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px fw-bolder text-dark rounded-start'>Título</th>
                {selectNoticia ? (
                  <th className='min-w-125px fw-bolder text-dark'>Modificado</th>
                ) : (
                  <th className='min-w-125px fw-bolder text-dark'>F. Creación</th>
                )}
                {selectNoticia ? (
                  <th className='min-w-175px fw-bolder text-dark'>Usuario Creador</th>
                ) : (
                  <th className='min-w-150px fw-bolder text-dark'>Área</th>
                )}
                {selectNoticia ? null : (
                  <th className='min-w-125px fw-bolder text-dark'>F. Publicación</th>
                )}

                {selectNoticia ? null : (
                  <th className='min-w-125px text-dark fw-bolder'>Imágenes</th>
                )}

                {selectNoticia ? null : (
                  <th className='min-w-125px text-dark fw-bolder'>Estado</th>
                )}

                {selectNoticia ? (
                  <th className='min-w-125px text-dark fw-bolder'>Acciones</th>
                ) : null}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data?.map((value: Inoticia) => (
                <tr key={value._id}>
                  <td>
                    <div className='ps-4 form-check form-check-sm form-check-custom form-check-solid border-check'>
                      <input
                        className='form-check-input shadow-check widget-9-check'
                        type='checkbox'
                        value='1'
                        onChange={(e) => {
                          handelSelecNoticia(value._id)
                        }}
                      />
                    </div>
                  </td>
                  <td className='min-w-250px rounded-start'>
                    <div className='min-w-300px rounded-start'>
                      <div className='d-flex justify-content-start flex-column'>
                        <BootstrapTooltip title={`${value.id_noticia} - ${value.titulo}`}  arrow followCursor placement='top'>
                          <a className='text-dark text-hover-primary mb-1 fs-6 acortar-titulo'>
                            {value.titulo}
                          </a>
                        </BootstrapTooltip>
                      </div>
                    </div>
                  </td>
                  <td>
                    {selectNoticia ? (
                      <div className='d-flex justify-content-start flex-column'>
                        {value.fecha_edicion !== '' ? (
                          <a className='text-dark text-hover-primary mb-1 fs-6'>
                            {value.fecha_creacion}
                          </a>
                        ) : (
                          <a className='text-dark text-hover-primary mb-1 fs-6'>
                            {value.fecha_edicion}
                          </a>
                        )}
                      </div>
                    ) : (
                      <div className='d-flex justify-content-start flex-column'>
                        <a className='text-dark text-hover-primary mb-1 fs-6'>
                          {value.fecha_creacion}
                        </a>
                      </div>
                    )}
                  </td>

                  <td>
                    {selectNoticia ? (
                      <div className='d-flex justify-content-start flex-column'>
                        <a className='text-dark text-hover-primary mb-1 fs-6'>
                          {value?.usuario_creador.nombre}
                        </a>
                      </div>
                    ) : (
                      <div className='d-flex justify-content-start flex-column'>
                        <a className='text-dark text-hover-primary mb-1 fs-6'>
                          {value?.nombreArea}
                        </a>
                      </div>
                    )}
                  </td>
                  <td className='min-w-40px max-w-70px justify-content-end'>
                    {selectNoticia ? (
                      <a>
                        {' '}
                        <ButtonActions objet={value} />
                      </a>
                    ) : (
                      <a className='text-dark text-hover-primary mb-1 fs-6'>
                        {moment(value?.fecha_publicacion).format('L')}
                      </a>
                    )}
                  </td>
                  <td>
                  {selectNoticia ? null : (
                    <div className='d-flex justify-content-start flex-column'>
                      <a className='text-dark text-hover-primary mb-1 fs-6'>
                        {value.imagen.portada?.url !== '' ? (
                          <span className='badge badge-light-success m-1'>P</span>
                        ) : (
                          <span className='badge badge-light-danger m-1'>P</span>
                        )}
                        {value.imagen.miniatura?.url !== '' ? (
                          <span className='badge badge-light-success m-1'>M</span>
                        ) : (
                          <span className='badge badge-light-danger m-1'>M</span>
                        )}
                        <span className='badge badge-light-info m-1'>
                          G{value.imagen.galeria?.length}
                        </span>
                      </a>
                    </div>
                  )}
                  </td>
                  {selectNoticia ? null : value?.publicacion ? (
                    <td>
                      <span className='badge badge-light-success'>{value.publicacionView}</span>
                    </td>
                  ) : value?.publicacionView === 'Incompleta' ? (
                    <td>
                      <span className='badge badge-light-warning'>{value.publicacionView}</span>
                    </td>
                  ) : (
                    <td>
                      <span className='badge badge-light-danger'>{value.publicacionView}</span>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
          {selectNoticia ? null : (
            <div className='pag-cen-tabla'>
              <ul className='pagination'>
                <li className='page-item'>
                  <button
                    className='btn btn-sm btn-light-primary'
                    disabled={activePage === 1}
                    onClick={() => setActivePage(1)}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-3' />{' '}
                    Primero
                  </button>
                </li>
                <li className='page-items'>
                  <button
                    className='btn btn-sm btn-light-primary'
                    disabled={activePage === 1}
                    onClick={() => setActivePage(activePage - 1)}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr022.svg' className='svg-icon-3' />
                    Anterior
                  </button>
                </li>
                <li className='page-item '>
                  <button
                    className='btn btn-sm btn-light-primary'
                    disabled={activePage === totalPages}
                    onClick={() => setActivePage(activePage + 1)}
                  >
                    Siguiente{' '}
                    <KTSVG path='/media/icons/duotune/arrows/arr071.svg' className='svg-icon-3' />
                  </button>
                </li>

                <button
                  className='btn btn-sm btn-light-primary'
                  disabled={activePage === totalPages}
                  onClick={() => setActivePage(totalPages)}
                >
                  Último{' '}
                  <KTSVG path='/media/icons/duotune/arrows/arr080.svg' className='svg-icon-3' />
                </button>
              </ul>
            </div>
          )}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default TablaNoticia
