/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'

const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Complete los datos.
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Es necesario el número de documento y el sexo, para generar el usuario.
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6'>
            <label className='form-label fw-bolder text-dark fs-6'>Número de documento</label>
            <Field
              type='text'
              name='usuario'
              placeholder='Nro..'
              className='form-control form-control-solid'
            />
            <ErrorMessage name='usuario' />
          </div>
          <div className='col-lg-6'>
            <label className='form-label fw-bolder text-dark fs-6'>Genero</label>
            <Field
              name='datosPersonales.sexo'
              as='select'
              className='form-select form-select-solid capital'
              id='kt_create_account_form_account_type_personal'
            >
              <option value=''>Seleccione</option>
              <option value='F'>Femenio</option>
              <option value='M'>Masculino</option>
              <option value='X'>Otros</option>
            </Field>
          </div>
          <div className='fv-plugins-message-container invalid-feedback'>
            <ErrorMessage name='datosPersonales.sexo' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
