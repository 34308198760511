import React, {useEffect, useState} from 'react'
import Chart from "react-apexcharts";

import type {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'
import { IdatosGrafico } from '../../Interfaces/Iindicador'
type Props = {
  className: string
  chartColor: string
  chartHeight: string
  titulo: string
  data: IdatosGrafico
  total: number | undefined
}


const CardSupTotales: React.FC<Props> = ({className, chartColor, chartHeight, titulo, data, total}) => {
  const labelColor = getCSSVariableValue('--bs-gray-dark')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)

  const [series, setSeries] = useState<ApexAxisChartSeries>([]);

  const [options, setOptions] = useState<ApexOptions>(
    {
      chart: {
        fontFamily: 'inherit',
        type: 'area',
        height: chartHeight,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
         sparkline: {
          enabled: false,
        }, 
      },
      plotOptions: {},
      legend: {
        show: true,
        labels: {
          colors: "#008FFB",
          useSeriesColors: true,
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: [labelColor]
        },
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 4,
        colors: [baseColor],
      },
      xaxis: {
        type: 'category',
        categories: data?.categories,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          show: false,
          position: 'front',
          stroke: {
            color: strokeColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        min: 0,
        max: 60000,
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val: any, opts: any) {
            return val 
          },
        },
      },
      colors: [lightColor],
      markers: {
        colors: [lightColor],
        strokeColors: [baseColor],
        strokeWidth: 3,
      },
  }
  );

  useEffect(() => {
    const series = {
      name: 'Totales',
      data: data.series,
    }
    setSeries([series]);
    
    return () => {
      console.log("Unmounted");
    }
  }, [data])
  


  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column p-0'>
        {/* begin::Stats */}
        <div className='flex-grow-1 card-p pb-0'>
          <div className='d-flex flex-stack flex-wrap'>
            <div className='me-2'>
              <span className='text-dark text-hover-primary fw-bolder fs-3'>{titulo}</span>

              <div className='text-muted fs-7 fw-bold'>
                Registros, almacenados en la base de datos
              </div>
            </div>
            {data && total?
              <div className={`fw-bolder fs-3 text-${chartColor}`}>{total}</div>:null
            }
          </div>
        </div>
        {/* end::Stats */}
        <div className="mixed-widget-7-chart card-rounded-bottom">
          <Chart options={options} series={series} type="area" height={chartHeight}/>
        </div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}


export {CardSupTotales}
