import React, {useRef} from 'react'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import {useReactToPrint} from 'react-to-print'
import {buildEventRangeKey} from '@fullcalendar/react'

type props = {
  data: any
}
export const ExportPDF: React.FC<props> = ({data}) => {
  let genero = ''
  switch (data.datosPersonales.sexo) {
    case 'f':
      genero = 'Femenino'
      break
    case 'm':
      genero = 'Masculino'
      break
    case 'x':
      genero = 'Otros'
      break
    default:
      break
  }

  return (
    <>
      <div className='row'>
        <label className='form-label fw-bolder text-dark fs-6'>
          SOLICITUD DE CERTIFICADO ÚNICO DE DISCAPACIDAD
        </label>
      </div>
      <div className='row'>
        <label className=''>DATOS DE LA PERSONA A EVALUAR</label>
      </div>

      <br />
      {/* Nombre-Apellido */}
      <div className='row'>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6 joni'>APELLIDO </label>
          <span className='form-control'>{data.datosPersonales.apellido}</span>
        </div>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>NOMBRE/S </label>
          <span className='form-control'>{data.datosPersonales.nombre}</span>
        </div>
      </div>
      <br />
      {/* fecha-genero */}
      <div className='row'>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>FECHA NACIMIENTO</label>
          <span className='form-control'> {data.datosPersonales.fechaNacimiento} </span>
        </div>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>GENERO</label>
          <span className='form-control'>{genero}</span>
        </div>
      </div>
      <br />
      {/* nac-loc */}
      <div className='row'>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>NACIONALIDAD</label>
          <span className='form-control'>{data.datosPersonales.nacionalidad}</span>
        </div>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>LOCALIDAD</label>
          <span className='form-control'>{data.datosPersonales.localidad}</span>
        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6 '>DOMICILIO </label>
          <span className='form-control'> {data.datosPersonales.domicilio}</span>
        </div>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>TELEFONO</label>
          <span className='form-control'>{data.datosPersonales.telefono}</span>
        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>CORREO</label>
          <span className='form-control'>{data.datosPersonales.mail}</span>{' '}
        </div>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>CODIGO POSTAL</label>
          <span className='form-control'> {data.datosPersonales.codigoPostal} </span>{' '}
        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>
            ADQUIRIÓ VEHÍCULO A TRAVÉS DE LEY 19.279
          </label>
          <span className='form-control'> {data.datosPersonales.vehiculoLey}</span>{' '}
        </div>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>
            POSEE SÍMBOLO INTERNACIONAL DE ACCESO
          </label>
          <span className='form-control'> {data.datosPersonales.simboloInternacional} </span>{' '}
        </div>
      </div>
      <br />
      {/* <div className='row'>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>ESTADO CIVIL</label>
          <span className='form-control'>{data.datosPersonales.mail} </span>{' '}
        </div>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>CODIGO POSTAL</label>
          <span className='form-control'>{data.datosPersonales.mail}</span>{' '}
        </div>
      </div> */}
    </>
  )
}
