import { request } from "../../../setup/axios/axios.util"

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const getClima = (lat: string, long: string) => {
  let data = { data: {} }
  data.data = {
    'lat': lat,
    'lon': long
  }
  const URL = `${API_URL}/modules/clima/clima`
  return request().post(URL, data)

}
