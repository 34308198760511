import React, {useEffect, useState} from 'react'

import FormikControl from '../../../../../../components/Formik/FormikControl'
import {localidadesNqn} from '../../../../../../services/gral/proLocService'
import {categoriasAreas, tipoArea} from '../../../interfaces/IConfigAreas'

//let dataLocNqn: any = []

const Step01Area = () => {
  const [locNqn, setlocNqn] = useState([])

  useEffect(() => {
    localidadesNqn().then((resp: any) => {
      // dataLocNqn = resp.data.data
      setlocNqn(resp.data.data)
    })

    return () => {}
  }, [])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Complete los datos.
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Es necesario contar con la fecha de creacion de la area antes de continuar.
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-4'>
            <FormikControl
              control='input'
              label='Nombre del Area'
              name='nombreArea'
              placeholder='Nombre del Area'
              className='form-control form-control-solid'
            />
          </div>
          <div className='col-lg-4'>
            <FormikControl
              control='select'
              label='Tipo de Area'
              name='tipoArea'
              options={tipoArea}
            />
          </div>
          <div className='col-lg-4'>
            <FormikControl
              control='select'
              label='Categoria del Area'
              name='categoriaArea'
              options={categoriasAreas}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-f'>Fecha de Alta</label>
            <FormikControl
              control='date'
              name='fechaAlta'
              className='form-control form-control-solid'
            />
          </div>
          <div className='col-lg-4'>
            <FormikControl
              control='select'
              label='Localidad'
              name='direccionArea.localidad'
              options={locNqn}
            />
          </div>
          <div className='col-lg-4'>
            <FormikControl
              name='direccionArea.calle'
              control='input'
              className='form-control form-control-solid'
              placeholder='Calle'
              label='Calle'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-2'>
            <FormikControl
              name='direccionArea.numero'
              label='Número'
              control='input'
              className='form-control form-control-solid'
              placeholder='Nro..'
            />
          </div>

          <div className='col-lg-2'>
            <FormikControl
              name='direccionArea.cp'
              control='input'
              className='form-control form-control-solid'
              placeholder='Nro..'
              label='C.Postal'
            />
          </div>
          <div className='col-lg-4'>
            <FormikControl
              control='input'
              name='datosContacto.email'
              className='form-control form-control-solid'
              placeholder='email'
              label='Correo electrónico'
            />
          </div>
          <div className='col-lg-4'>
            <FormikControl
              control='input'
              name='datosContacto.telefono'
              placeholder='Número de telefono'
              className='form-control form-control-solid'
              label='Número de Teléfono'
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export {Step01Area}
