import React from 'react'
import CheckGroupFormik from './CheckGroupFormik'
import DatePickerFormik from './DatePickerFormik'
import DateTimePickerFormik from './DateTimePickerFormik'
import InputFomik from './InputFomik'
import RadioButtonFormik from './RadioButtonFormik'
import SelectFormik from './SelectFormik'
import TextAreaFormik from './TextAreaFormik'

const FormikControl: React.FC<any> = (props: any) => {
  const { control, ...rest } = props
  switch (control) {
    case 'input':
      return <InputFomik {...rest} />
    case 'textarea':
      return <TextAreaFormik {...rest} />
    case 'select':
      return <SelectFormik {...rest} />
    case 'radio':
      return <RadioButtonFormik {...rest} />
    case 'checkbox':
      return <CheckGroupFormik {...rest} />
    case 'date':
      return <DatePickerFormik {...rest} />
    case 'datetime':
      return <DateTimePickerFormik {...rest} />
    default:
      return null
  }
}

export default FormikControl
