import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'

import AgendaPage from './pages/AgendaPage'
import CargarEventoPage from './pages/CargarEventoPage'
import EventosPage from './pages/EventosPage/EventosPage'
import IndicadoresPage from './pages/IndicadoresCal'
import ListaEventosPage from './pages/ListaEventosPage'
import MisEventosPage from './pages/MisEventos'
import { ImprimirComponente } from '../../../components/ImprimirComponente'
import { ExportarEventos } from './pages/ExportarEventos'

function AgendaRoute() {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${url}/calendario`} component={AgendaPage} />
      <Route exact path={`${url}/calendario/nuevoevento`} component={CargarEventoPage} />
      <Route exact path={`${url}/calendario/indicadores`} component={IndicadoresPage} />
      <Route exact path={`${url}/calendario/agendacompleta`} component={ListaEventosPage} />
      <Route exact path={`${url}/calendario/miagenda`} component={MisEventosPage} />
      <Route exact path={`${url}/calendario/evento`} component={EventosPage} />
      <Route exact path={`${url}/calendario/imprimir`} component={ImprimirComponente} />
      <Route exact path={`${url}/calendario/exportar`} component={ExportarEventos} />
      <Route path='*'>
        <Redirect to='/page404' />
      </Route>
    </Switch>
  )
}

export default AgendaRoute