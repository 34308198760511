/** React */
import React, {useState, useEffect} from 'react'
/** Interfaces */
import {Idatos} from '../../Interfaces/Datos-interface'
/** Material & Sass */
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp'
/** Services */
/** Tablas */
import {DataAdmision} from '../../components/Tablas'
import {Link, useLocation} from 'react-router-dom'
import {BtnSearch} from '../../components/Filter/BtnSearch'
import {BootstrapTooltip} from '../../helpers/bootstrapToolTip'
import Spinner from '../../../../../components/spinner/Spinner'
import { Ifilter } from '../../Interfaces/Ifilter'
import Stepper from '../Forms/IngresoDav/Stepper'
import { getAdmisiones } from '../../services/dav-service'
import { Iadmision, Iadmisiones } from '../../Interfaces/Iadmision'

export const Admision: React.FC<Idatos> = ({
  className,
  click,
  setBtnClick,
  setRouteTitle,
}) => {

  const locationl: any = useLocation()
  /** Title Page */
  let titlePage = 'Admisiones'
  /** Value Array Legajos of API  */
  const [admList, setAdmList] = useState<Iadmisiones>([])
  /** Vista condicional para paginacion */
  const [vista, setVista] = useState(false)
  /** Setup pagination */
  const [pagination, setPagination] = useState<any>([])
  const [activePage, setActivePage] = useState(1)
  const [filter, setFilter] = useState<Ifilter>();
  const totalPages = Math.ceil(pagination.totalResult / pagination.limit)
  const [objetol, setObjetol] = useState('')
  const [loading, setLoading] = useState<any>(true)
  const [showStepper, setShowStepper] = useState(false);

  let activando: boolean = false
  let dnile: any = ''

  const buscarAdmision = (): Iadmision[] => {
    let admisionDniList = admList.filter((adm: Iadmision) => adm.datosPersonales.dni)
    console.log(admisionDniList);
    return admisionDniList;
  }

  /** Function pedido Api */
  const legajosVew = async () => {
/*     if (filter) {
      if (filter.page) {
        filter.page= activePage;
      }
      switch (filter.tipo) {
        case 'localidad':  
          searchByLocalidad(filter).then((res: any) => {
          setPagination(res.data)
          setValue(res.data.res)
        })
        break;
        case 'fecha': 
          searchByFecha(filter).then((res: any) => {
            setPagination(res.data);
            setValue(res.data.res);
          });
        break;

      }
    }
    else { */
      const res: any = await getAdmisiones(activePage)
      setPagination(res.data)
      setAdmList(res.data.result)
      setTimeout(()=>{
        setLoading(false)
      },1000)
/*     } */
    }

  useEffect(() => {
    setRouteTitle(titlePage)
    legajosVew()
  }, [activePage])

  /** Function Btn Volver */
  const goBack = () => {
    setVista(false)
    legajosVew()
  }

  useEffect(() => {
    if (locationl.state != null) {
      setObjetol(locationl.state.obj)
    }
  }, [objetol])

  if (locationl.state !== null) {
    activando = locationl.state.activado
  }

  useEffect(() => {}, [activando])
  return (
    <>
      {click ? (
        <div className='card-xxl-stretch mb-5 mb-xl-8'>
          <div className='card'>
            <div className='card-body py-3'>
              <BtnSearch
                titlePage={titlePage}
                data={admList}
                setValue={setAdmList}
                setActivePage={setActivePage}
                setVista={setVista}
                activePage={activePage}
                setPagination={setPagination}
                setBtnClick={setBtnClick}
                setFilter={setFilter}
              />
            </div>
          </div>
        </div>
      ) : null}
        <div className={`card ${className}`}>

        <div className='card-header' style={{width:'2100px'}}>
        <h5 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-5 mb-1 capital'>Mesa de entrada</span>
        </h5>
        <div className='card-toolbar'>
            <button
                className='btn btn-sm btn-success'
                onClick={() => setShowStepper(true)}
            > + Nuevo ingreso</button>
        </div>
        
{/*     <h5 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-5 mb-1 capital'>Legajos</span>
            </h5>
            <button type='button'
                        className='btn btn-light-primary btn-sm button_b'
                        onClick={goBack}
                        name='volver'
                > + Agregar legajo</button> */}

            <div className='card-toolbar'>
                <div>
                {vista ? (
                    <BootstrapTooltip title='Volver' arrow followCursor placement='top'>
                    <button
                        type='button'
                        className='btn btn-light-primary btn-sm button_b'
                        onClick={goBack}
                        name='volver'
                    >
                        <ArrowBackSharpIcon />
                    </button>
                    </BootstrapTooltip>
                ) : null}
                </div>
            </div>
            </div>

            {/* /crear-legajos */}
            {/* <div style={{margin: '50px'}}>
                <Stepper />
            </div> */}

        {showStepper && (
        <div style={{ margin: '50px' }}>
            <Stepper />
        </div>
        )}       

        <div className='card-body py-3'>
          <div className='table-responsive'>

            {loading === true ? (
            <div className='col-lg-12 elemento_centro'>
            <Spinner></Spinner>
            <p><span><b>Cargando Información...</b></span></p>
            </div>
            ) : (
              <DataAdmision
                vista={vista}
                activePage={activePage}
                totalPages={totalPages}
                setActivePage={setActivePage}
                activando={activando}
                admision={buscarAdmision()}
              />
            )}
          </div>
        </div>

      </div>
    </>
  )

}
