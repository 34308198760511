import React from 'react'

import { ITablaDatosT } from '../../Interfaces/TablaDatosT-interface'
import { TablaActivas } from './TablasEstados/TablaActivas'
import { TablaPendiente } from './TablasEstados/TablaPendiente'
import { TablaConsumo } from './TablasEstados/TablaConsumo'

// let tablaInicio: ITablaDatosT = {
//   className: 'card-xxl-stretch mb-5 mb-xl-8',
//   tituloMenu: 'Administracion de sistemas',
//   botonPrincipal: 'Crear',
//   tablaDatos: [],
//   link: '#',
//   linkEdit: '#',
//   headColumns: {
//     col1: '',
//     col2: '',
//     col3: '',
//     col4: '',
//     col5: '',
//   },
// }

const TablasDatos: React.FC<ITablaDatosT> = ({
  className,
  tituloMenu,
  botonPrincipal,
  tablaDatos,
  headColumns,
}) => {
  ////console.log(tituloMenu)

  return (
    <div>
      {tituloMenu === ' ' && (
        <TablaActivas
          className='card-xxl-stretch mb-5 mb-xl-8'
          tablaDatos={tablaDatos}
          botonPrincipal={botonPrincipal}
          headColumns={headColumns}
          tituloMenu={tituloMenu}
        />
      )}

      {tituloMenu === 'Lineas Activas' && (
        <TablaActivas
          className='card-xxl-stretch mb-5 mb-xl-8'
          tablaDatos={tablaDatos}
          botonPrincipal={botonPrincipal}
          headColumns={headColumns}
          tituloMenu={tituloMenu}
        />
      )}

      {tituloMenu === 'Lineas Pendientes' && (
        <TablaPendiente
          className='card-xxl-stretch mb-5 mb-xl-8'
          tablaDatos={tablaDatos}
          botonPrincipal={botonPrincipal}
          headColumns={headColumns}
          tituloMenu={tituloMenu}
        />
      )}

      {tituloMenu === 'Consumos' && (
        <TablaConsumo
          className='card-xxl-stretch mb-5 mb-xl-8'
          tablaDatos={tablaDatos}
          botonPrincipal={botonPrincipal}
          headColumns={headColumns}
        />
      )}
    </div>
  )
}

export default TablasDatos
