import moment from 'moment'
import React, {useRef, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useReactToPrint} from 'react-to-print'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ExportPDF} from '../Exports/ExportPDF'
type props = {
  dataTramite?: any
  show: boolean
  handleCloseModal: () => void
}

type MyProps = {
  data: any
  ref: any
}

class PDF extends React.Component<MyProps> {
  render() {
    // console.log(this.props.data[0].dni);
    const data = this.props.data[0]
    return (
      <>
        <div className='print-container centrado pt-3' style={{margin: '0', padding: '0'}}>
          <h3>Tramite Seleccionado</h3>
          <h5>Generado: {moment(new Date()).format('DD/MM/YYYY HH:mm')}</h5>
          <ExportPDF key={'VerExportacion'} data={data} />
        </div>
      </>
    )
  }
}

export const ModalPrevPDF: React.FC<props> = ({show, dataTramite, handleCloseModal}) => {
  let componentRef: any = useRef()
  const handelprint = useReactToPrint({
    content: () => componentRef.current,
  })
  return (
    <Modal
      className='modal fade config-modal'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Previsializacion</h5>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleCloseModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <PDF ref={componentRef} data={dataTramite} />
        </div>

        <button className='btn btn-primary' onClick={handelprint}>
          descargar pdf
        </button>
      </div>
    </Modal>
  )
}
