import React, { useEffect, useState } from 'react'
import {
  getAllEmpresasOk,
  getAllfilesEmpZipByeOneMes,
  getDocsEmpresa,
  getEmpleadosMesxMes,
} from '../services/AdminEmpleados'
import { Field, Form, Formik } from 'formik'
import { IEmpresa } from '../interfaces/Iempresas'

import { Card4 } from '../../../../../_metronic/partials/content/cards/Card4' //Card que muestra los docs de la empresa
import { Card3 } from '../../../../../_metronic/partials/content/cards/Card3' //Card que muestra empleados validados
import { KTSVG } from '../../../../../_metronic/helpers'
import { selectOpBtn } from '../components/ValidarDocEmpCentroPyme/ValidarDocMes'
import { useLocation } from 'react-router-dom'
import { setTimeout } from 'timers'
import Spinner from 'react-bootstrap-v5/lib/esm/Spinner'
import useAreaUser from '../../../../Hooks/useAreaUser'
import * as Yup from 'yup'
import { modEstadoDocMensualEmpresa } from '../services/EJ.Services'
import { ISTATE } from '../../../../../setup/redux/interface/reduxState.interface'
import { useDispatch, useSelector } from 'react-redux'

export const PeriodosEmp = () => {
  const [fomrVaule, setFomrVaule] = useState<any>()
  const [loading, setLoading] = useState<Boolean>(false)
  const [loadingReg, setLoadingReg] = useState<Boolean>(false)
  const [loadSpinner, setLoadSpinner] = useState<Boolean>(false)
  const [empresas, setEmpresas] = useState<IEmpresa[]>([])
  const [cuitSelected, setCuitSelected] = useState<string>('');
  const [anioSelected, setAnioSelected] = useState<string>('');
  const [mesSelected, setMesSelected] = useState<string>('');

  const [anio, setAnio] = useState<any>()
  const [meses, setMeses] = useState<any>()
  const [docPeriodo, setDocPeriodo] = useState<any>([])
  const [downLoadFiles, setDownLoadFiles] = useState<any>()
  const [empleados, setEmpleados] = useState<any>()
  const [selecEmp, setSelecEmp] = useState<any>()
  const consultaValidate = Yup.object().shape({
    cuit: Yup.string().required('El campo es requerido'),
    anio: Yup.string().required('El campo es requerido'),
    mes: Yup.string().required('El campo es requerido'),
  })

  const location = useLocation<any>();

  const { areaUsr } = useAreaUser();
  const modificated = useSelector((state: ISTATE) => state.empleoJoven.modificated);
  const dispatch = useDispatch();

  const filtrarEmpleado = (cuit: string) => {
    setCuitSelected(cuit);
    return setAnio(empresas.find((emp: any) => emp.cuit === cuit)?.anio)
  }

  const filtYearAct = (year: string) => {
    setAnioSelected(year);
    setMeses(anio?.find((elemt: any) => elemt.anio === year)?.meses)
  }

  const filtDocMes = (mes: string) => {
    setMesSelected(mes);
    if (areaUsr === 'asesores pyme') {

      setDocPeriodo(meses?.find((elemt: any) => elemt.mes === mes && elemt.estado === 'validado')?.documentos)
    }
    else {
      setDocPeriodo(meses?.find((elemt: any) => elemt.mes === mes)?.documentos)
    }
  }

  const downloadFilesZip = () => {
    getAllfilesEmpZipByeOneMes(downLoadFiles).then((response: any) => {
      const href = URL.createObjectURL(response.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute(
        'download',
        `${downLoadFiles.cuit}-${downLoadFiles.anio}-${downLoadFiles.mes}.zip`
      ) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }

  const filtrarDocMensEmp = (empleadosApi: any, anioSelected: string) => {
    for (let emp of empleadosApi) {
      let anioIndx = emp.anio.findIndex((item: any) => item.anio === anioSelected)
      if (emp.anio[anioIndx].meses.length > 0) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    let isMounted = true
    setEmpleados([])
    getAllEmpresasOk().then((res: any) => {
      if (isMounted) {
        res.data.ok && setEmpresas(res.data.data)
      }
    })
    return () => {
      isMounted = false
    }
  }, [])

  // Llamamos a searchDataEmpleados cuando modificated es true
  useEffect(() => {
    if (modificated) {
      console.log("Se ejecuta efecto y se llama a API")
      searchDataEmpleados(fomrVaule); // Asegúrate de pasar los valores necesarios
    }
    return () => {
    /*   dispatch({type: "SET_ESTADOS_DOCUMENTOS", payload: {modificated: false}}); */
    console.log("Se desmonta componenente")
    }
  }, [modificated]);

  const changeStateHandler = async (idItem: string, estado: string, observacion?: string) => {
    const postData = {
      cuit: cuitSelected,
      estado: estado,
      anio: anioSelected,
      doc_id: idItem,
      mes: mesSelected,
      observacion,
    }
    try {
      const response = await modEstadoDocMensualEmpresa(postData);
      if (response.status === 200) {

        /*         const updatedItems = docPeriodo.map((item: any) => {
                  if (item.id_db_fileEj === idItem) {
                    return { ...item, estado: estado };
                  }
                  return item;
                });
                setDocPeriodo(updatedItems); */
        // Actualiza el estado de empresas se debe actualizar de esta forma por el filtro del mes que sobreescribe la modificación de docPeriodo
        const updatedEmpresas = empresas.map((empresa) => {
          if (empresa.cuit === cuitSelected) {
            const updatedAnio = empresa.anio?.map((anioItem) => {
              if (anioItem.anio === anioSelected) {
                const updatedMeses = anioItem.meses.map((mesItem) => {
                  if (mesItem.mes === mesSelected) {
                    const updatedDocumentos = mesItem.documentos.map((doc) => {
                      if (doc.id_db_fileEj === idItem) {
                        return { ...doc, estado: estado };
                      }
                      return doc;
                    });
                    return { ...mesItem, documentos: updatedDocumentos };
                  }
                  return mesItem;
                });
                return { ...anioItem, meses: updatedMeses };
              }
              return anioItem;
            });
            return { ...empresa, anio: updatedAnio };
          }
          return empresa;
        });

        setEmpresas(updatedEmpresas);
      }
    } catch (error) {
      console.error('Error al actualizar el estado del documento:', error);
    }
  }

  const searchDataEmpleados = (values: any) => {
    getEmpleadosMesxMes(values).then((res: any) => {
      if (res.data.data.length) {
        if (filtrarDocMensEmp(res.data.data, values.anio)) {
          setLoadSpinner(false)
          setLoadingReg(false)
          setEmpleados(res.data.data)
          setLoading(true)
        } else {
          setLoadSpinner(false)
          setLoadingReg(true)
        }
      } else {
        setLoadSpinner(false)
        setLoadingReg(true)
        /* setLoading(true) */
      }
    })
    if (docPeriodo) {
      setLoadSpinner(false)
      setLoadingReg(false)
      setLoading(true)
    }

  }





  return (
    <>
      <div className='mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <div className='containerTableTitle'>
            <h3 className='headerTableLefet'>
              <span className='card-label fw-bolder fs-3 mb-1'>
                Seleccione empresa, periodo y mes.
              </span>
            </h3>
          </div>
          <br />
          <Formik
            initialValues={{
              cuit: location.state?.empresa ? location.state.empresa.cuit : '',
              anio: location.state?.empresa.anio ? location.state.empresa.anio : '',
              mes: location.state?.empresa.mes ? location.state.empresa.mes : '',
            }}
            validationSchema={consultaValidate}
            onSubmit={(values: any) => {
              setLoadSpinner(true)
              setTimeout(() => {
                setFomrVaule(values)
                setDownLoadFiles(values)
                setEmpleados([])
                searchDataEmpleados(values);
                /* getEmpleadosMesxMes(values).then((res: any) => {
                  if (res.data.data.length) {
                    if (filtrarDocMensEmp(res.data.data, values.anio)) {
                      setLoadSpinner(false)
                      setLoadingReg(false)
                      setEmpleados(res.data.data)
                      setLoading(true)
                    } else {
                      setLoadSpinner(false)
                      setLoadingReg(true)
                    }
                  } else {
                    setLoadSpinner(false)
                    setLoadingReg(true)
                    setLoading(true)
                  }
                })
                if (docPeriodo) {
                  setLoadSpinner(false)
                  setLoadingReg(false)
                  setLoading(true)
                } */
              }, 1000)
            }}
          >
            {(formik) => {

              return (
                <Form>
                  {formik.values.cuit !== '' ? filtrarEmpleado(formik.values.cuit) : ""}
                  {formik.values.anio !== '' ? filtYearAct(formik.values.anio) : ""}
                  {formik.values.mes !== '' ? filtDocMes(formik.values.mes) : ""}
                  <div className='row justify-content-md-center'>
                    <div className='col-4'>
                      <Field
                        as='select'
                        id='cuit'
                        name='cuit'
                        className='form-select form-select-solid capital'
                        onChange={(e: any) => {
                          formik.setFieldValue('cuit', e.target.value)
                          filtrarEmpleado(e.target.value)
                        }}
                      >
                        <option value=''>seleccione una empresa </option>
                        {empresas.map((emp: any) => {
                          return <option value={emp.cuit}>{emp.razon_social.toUpperCase()}</option>
                        })}
                        {formik.errors.cuit && formik.touched.cuit ? (
                          <div>{formik.errors.cuit}</div>
                        ) : null}
                      </Field>
                    </div>
                    {anio && (
                      <div className='col-2'>
                        <Field
                          as='select'
                          id='anio'
                          name='anio'
                          className='form-select form-select-solid capital'
                          onChange={(e: any) => {
                            formik.setFieldValue('anio', e.target.value)
                            filtYearAct(e.target.value)
                          }}
                        >
                          <option value=''>Seleccione el periodo</option>
                          {anio.map((item: any) => {
                            return <option value={item.anio}>{item.anio}</option>
                          })}
                          {formik.errors.anio && formik.touched.anio ? (
                            <div>{formik.errors.anio}</div>
                          ) : null}
                        </Field>
                      </div>
                    )}
                    {meses && (
                      <div className='col-2'>
                        <Field
                          as='select'
                          id='mes'
                          name='mes'
                          className='form-select form-select-solid capital'
                          onChange={(e: any) => {
                            formik.setFieldValue('mes', e.target.value)
                            filtDocMes(e.target.value)
                          }}
                        >
                          <option value=''>Seleccione el Mes</option>
                          {meses.map((item: any) => {
                            return <option value={item.mes}>{item.mes}</option>
                          })}
                          {formik.errors.mes && formik.touched.mes ? (
                            <div>{formik.errors.mes}</div>
                          ) : null}
                        </Field>
                      </div>
                    )}

                    {docPeriodo && (
                      <div className='col-1'>
                        <button
                          type='submit'
                          className='btn btn-sm btn-light-primary'
                          disabled={!formik.isValid}
                        >
                          <KTSVG
                            path='/media/icons/duotune/files/fil024.svg'
                            className='svg-icon-3'
                          />
                          Buscar Documentos
                        </button>
                      </div>
                    )}

                    {loading && (
                      <>
                        <div className='col-1'>
                          <button
                            type='button'
                            onClick={() => downloadFilesZip()}
                            className='btn btn-sm btn-light-info'
                          >
                            <KTSVG
                              path='/media/icons/duotune/files/fil021.svg'
                              className='svg-icon-3'
                            />
                            Descargar Información
                          </button>
                        </div>
                        {areaUsr === 'asesores pyme' &&
                          <div className='col-1'>
                            <button
                              type='button'
                              onClick={() => {
                                selectOpBtn(formik.values)
                              }}
                              className='btn btn-sm btn-light-success'
                            >
                              <KTSVG
                                path='/media/icons/duotune/files/fil011.svg'
                                className='svg-icon-3'
                              />
                              Cambiar estado
                            </button>
                          </div>
                        }
                      </>
                    )}

                    {loadSpinner && (
                      <>
                        <div className='col-2'>
                          <Spinner
                            className='spinner-border m-5'
                            animation='border'
                            role='status'
                            variant='primary'
                          >
                            <span className='visually-hidden'>Loading...</span>
                          </Spinner>
                        </div>
                      </>
                    )}
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>



      {loading && (
        <>
          <div className='mb-5 mb-xl-8'>
            <div className='card-header border-0 pt-5'>
              <div className='containerTableTitle'>
                <h3 className='headerTableLefet'>
                  {loading && (
                    <span className='card-label fw-bolder fs-3 mb-1'>
                      Documentación de la empresa correspondiente al periodo {fomrVaule.anio} y al mes de{' '}
                      {fomrVaule.mes}.
                    </span>
                  )}
                  {!loading && (
                    <span className='card-label fw-bolder fs-3 mb-1'>
                      Sin registros para el peridodo y mes seleccionados.!!
                    </span>
                  )}
                </h3>
              </div>
              <br />
              {docPeriodo && (
                <div className='row justify-content-md-center'>
                  {docPeriodo?.map((doc: any, index: any) => {
                    return (
                      <div key={index} className='col-3 col-sm-3 col-xl'>
                        <Card4
                          icon='/media/svg/files/pdf.svg'
                          title={doc.tipo_doc}
                          titulo='doc periodos'
                          tipoUsuario={areaUsr === 'asesores pyme' ? 'centroPyme' : 'juventud'}
                          description=''
                          cuit={doc.id_db_fileEj}
                          cuitEmpresa={cuitSelected}
                          archivo={doc}
                          onChangeState={changeStateHandler}
                        />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
          {!loadingReg &&
            <div className='mb-5 mb-xl-8'>
              <div className='card-header border-0 pt-5'>
                <div className='containerTableTitle'>
                  <h3 className='headerTableLefet'>
                    <span className='card-label fw-bolder fs-3 mb-1 capital'>
                      Empleados correspondientes al periodo {fomrVaule.anio} y al mes de{' '}
                      {fomrVaule.mes}.
                    </span>
                  </h3>
                </div>
                <br />
                <div className='row justify-content-md-center'>
                  {empleados &&
                    empleados.map((emp: any, index: any) => {
                      if (emp.estado === 'validado') {
                        return (
                          <>
                            <div key={index} className='col-3 col-sm-3 col-xl'>
                              <Card3
                                formValue={fomrVaule}
                                name={`${emp.datosPersonales.apellido} ${emp.datosPersonales.nombres}`}
                                empleado={emp}
                                job={emp.documento}
                                altaAfip={emp.datosLaborales.fechaAlta}
                                altaSistema={emp.fechaCarga}
                              />
                            </div>
                          </>
                        )
                      }
                    })}{' '}
                </div>
              </div>
            </div>}
          {loadingReg && (
            <div className='mb-5 mb-xl-8'>
              <div className='card-header border-0 pt-5'>
                <div className='containerTableTitle'>
                  <h3 className='headerTableLefet'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                      Sin registros de empleados para el peridodo y mes seleccionados.!!
                    </span>
                  </h3>
                </div>
                <br />
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}
