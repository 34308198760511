import * as Yup from 'yup'
import { object } from 'yup';


export const validateSchema =  Yup.object({
    nombre: Yup.string().required('El nombre es requerido'),
    domicilio: Yup.string().required('El domicilio es requerido'),
    localidad: Yup.string().required('La localidad es requerida').notOneOf([""], "Invalid option."),
  })

export interface Ijunta {
  nombre: string
  domicilio: string
  localidad: string
  itinerante: boolean
  principal: boolean
  activo: boolean
}
export interface Ijuntas extends Array<Ijunta> {}
