import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ISTATE } from '../../../../../setup/redux/interface/reduxState.interface'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { RightToolbar } from '../components/explore/RightToolbar'
import HeaderListaEventos from '../components/Headers/HeaderLisEventos'
import HeaderMisEventos from '../components/Headers/HeaderMisEventos'
import TablaEventos from '../components/TablaEventos'
type Props = {
  className: string
}
let tablaInicio: Props = {
  className: 'card mb-5 mb-xl-8',
}
const MisEventosPage: React.FC<Props> = ({ className }) => {
  const dataUsStore: any = useSelector((state: ISTATE) => state.auth)
  const { usuario } = dataUsStore
  const evStore: any = useSelector((state: ISTATE) => state.auth)
  const { eventosPublicos } = evStore
  const [eventos, setEventos] = useState<any>([])
  const [eventosFiltrar, setEventosFiltrar] = useState<any>([])
  ////////console.log('usuario', usuario)
  return (
    <div className={`card sombras`} id='MiCompleta'>
      <PageTitle breadcrumbs={[]}>Agenda</PageTitle>
      <HeaderMisEventos
        area={usuario.organizacion.datosOrganismo.areaPrincipal}
        modulo={'miseventos'}
      />
      <RightToolbar />
      <TablaEventos dependencia={usuario.organizacion.datosOrganismo.areaPrincipal.idAreaP} datos={undefined} />
    </div>
  )
}

export default MisEventosPage
