import { calTypes } from "../types/types"


const initialState = {

  seleccion: [{
    nombreCorto: '',
    tooltip: '',
    colorFondo: '',
    id: '',
    selec: false
  }]
}

export const menuReducer = (state = initialState, action: any) => {


  switch (action.type) {

    case calTypes.cargarSelAreas:
      return {
        ...state,
        seleccion: action.payload.seleccion
      }
    case calTypes.seleccionMenu:
      if (action.payload.areaId === 'Todas') {
        return {
          ...state,
          seleccion: state.seleccion.map((sel: any) => {

            return {
              ...sel,
              selec: true
            }
          })
        }
      } else {
        if (action.payload.areaId === 'Ninguna') {
          return {
            ...state,
            seleccion: state.seleccion.map((sel: any) => {
              return {
                ...sel,
                selec: false
              }
            })
          }
        } else {
          return {
            ...state,
            seleccion: state.seleccion.map((sel: any) => {
              if (sel.id === action.payload.areaId) {
                return {
                  ...sel,
                  selec: !sel.selec
                }
              } else {
                return sel
              }
            })
          }
        }
      }

    default:
      return state
  }
}
