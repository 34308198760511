/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Switch, Route} from 'react-router-dom'

import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
//import { ErrorsPage } from '../modules/errors/ErrorsPage'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {shallowEqual, useSelector} from 'react-redux'
import {ResetPaswoord} from '../modules/auth/components/ResetPaswoord'
import {WrapperAuth} from '../modules/auth/components/WrapperAuth'

const Routes: FC = () => {
  const isAuthorized = useSelector<any>(({dashBoard}) => dashBoard.isLogged, shallowEqual)
  const primerLogin = useSelector<any>(({dashBoard}) => dashBoard.primerLogin, shallowEqual)
  const usuario = useSelector<any>(({auth}) => auth.usuario?.usuario, shallowEqual)

  //const primerLogin = true;
  return (
    <>
      <Switch>
        {/* <Route path='/error' component={ErrorsPage} /> */}
        <Route path='/logout' component={Logout} />

        {/*         {!isAuthorized && <Redirect to='/auth/login' />}
        {isAuthorized && primerLogin && <Redirect to='/auth/reset-passord' />}
        {isAuthorized && 
          <MasterLayout>
          <PrivateRoutes />
          </MasterLayout>
        } */}
        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Route>
            <AuthPage />
          </Route>
        ) : primerLogin ? (
          <WrapperAuth>
            <Route>
              <ResetPaswoord usuario={usuario} />
            </Route>
            {/* <Route path="/auth/reset-password" render={(props) => <ResetPaswoord usuario={usuario} {...props}/>} /> */}
          </WrapperAuth>
        ) : (
          <MasterLayout>
            <PrivateRoutes />
          </MasterLayout>
        )}
        {/*         {primerLogin && 
        <Redirect to='/reset-password' />} */}
      </Switch>
      <MasterInit />
    </>
  )
}

export {Routes}
