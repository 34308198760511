export const modUsEmp = {
  usuario: '',
  password: '',
  errPassword: {
    intentos: 0,
    fecha_err: '',
  },
  role: '',
  activo: true,
  fechaAltaUsuario: '',
  fechaBajaUsuario: '',
  lastLogin: '',
  datosPersonales: {
    nombres: '',
    apellido: '',
    dni: '',
    cuil: '',
    ciudad: '',
    provincia: '',
    fechaNacimiento: '',
    sexo: '',
    foto: '',
    ID_TRAMITE_PRINCIPAL: '',
  },
  datosContacto: {email: '', telefono: ''},
  datosLaborales: {
    servicioPuestoPrincipal: '',
    legajo: '', //cuit de la empresa
    fechaAlta: '', //fecha de ap en trabajo
    tipoRelacionLaboral: '', //categoria
    datosOrganismo: {
      nombre: 'empresas nqn',
      nombreMinistro: 'sin datos',
      datosSistemas: {
        colorLetra: '',
        colorMinisterioA: '',
        colorMinisterioB: '',
      },
      areaPrincipal: {
        idAreaP: '63ca76f277aacd0554afe988',
        nombreArea: 'empleo joven',
        categoriaArea: 'A',
        tipoArea: 'SubSecreatria',
        detalleSistema: {
          icono: '',
          nombreCorto: '',
          colorLetra: '#4A9396',
          colorFondo: '#B6EAF2',
        },
      },
      areaPropia: {
        nombreArea: '',
        idAreaPro: '',
        titularArea: '',
        tipoArea: '',
        categoriaArea: '',
        detalleSistema: {
          icono: '',
          nombreCorto: '',
          colorLetra: '',
          colorFondo: '',
          menusAct: false,
        },
      },
    },
  },
  perfilUs: [
    {
      nombre: 'agenda',
      descripcion: 'ABM de Calendario Actividades del Ministerio',
      paginaInicio: 'agenda/calendario',
      favorito: false,
      menu: [
        {
          name: 'calendario',
          type: 'link',
          tooltip: 'agenda del ministerio',
          icon: '',
          state: 'agenda/calendario',
          disabled: false,
          level: 0,
          expandable: false,
          children: [],
          roleUs: {
            admin: false,
            crear: false,
            consulta: true,
            editor: false,
            exportar: false,
            indicadores: false,
          },
        },
        {
          name: 'mi agenda',
          type: 'link',
          tooltip: 'mis eventos',
          icon: '',
          state: 'agenda/calendario/miagenda',
          disabled: false,
          level: 0,
          expandable: false,
          children: [],
          roleUs: {
            admin: false,
            crear: false,
            consulta: true,
            editor: false,
            exportar: false,
            indicadores: false,
          },
        },
        {
          name: 'eventos',
          type: 'link',
          tooltip: 'nuevo evento',
          icon: '',
          state: 'agenda/calendario/nuevoevento',
          disabled: false,
          level: 0,
          expandable: false,
          children: [],
          roleUs: {
            admin: false,
            crear: false,
            consulta: true,
            editor: false,
            exportar: false,
            indicadores: false,
          },
        },
        {
          name: 'agenda completa',
          type: 'link',
          tooltip: 'agenda completa del ministerio',
          icon: '',
          state: 'agenda/calendario/agendacompleta',
          disabled: false,
          level: 0,
          expandable: false,
          children: [],
          roleUs: {
            admin: false,
            crear: false,
            consulta: true,
            editor: false,
            exportar: false,
            indicadores: false,
          },
        },
      ],
      WSmodulo: [],
      acciones: [],
      activo: true,
    },
    {
      nombre: 'Empleo Joven',
      descripcion: 'Administración y gestión del programa empleo joven',
      paginaInicio: 'ej/home',
      favorito: false,
      menu: [
        {
          name: 'mis datos',
          type: 'link',
          tooltip: 'consultas',
          icon: '',
          state: 'ej/misdatos',
          disabled: false,
          level: 0,
          expandable: false,
          children: [],
          roleUs: {
            admin: true,
            crear: true,
            consulta: true,
            editor: true,
            exportar: true,
            indicadores: true,
          },
        },
        {
          name: 'mis empleados',
          type: 'link',
          tooltip: 'consultas',
          icon: '',
          state: 'ej/misempleados',
          disabled: false,
          level: 0,
          expandable: false,
          children: [],
          roleUs: {
            admin: true,
            crear: true,
            consulta: true,
            editor: true,
            exportar: true,
            indicadores: true,
          },
        },
        {
          name: 'mis documentos',
          type: 'link',
          tooltip: 'consultas',
          icon: '',
          state: 'ej/misdocumentos',
          disabled: false,
          level: 0,
          expandable: false,
          children: [],
          roleUs: {
            admin: true,
            crear: true,
            consulta: true,
            editor: true,
            exportar: true,
            indicadores: true,
          },
          _id: {
            $oid: '63c7ff80eeaee9693b2f387e',
          },
        },
      ],
      WSmodulo: [],
      acciones: [],
      activo: true,
    },
  ],
  tipoUs: {
    appMovil: false,
    appWeb: true,
  },
}

export const calcularMesEmpleado = (mesInscripcion: string) => {
  let meses: any
  switch (mesInscripcion) {
    case 'enero':
      meses = [
        {
          estado: 'pendiente',
          mes: 'enero',
        },
        {
          estado: 'pendiente',
          mes: 'febrero',
        },
        {
          estado: 'pendiente',
          mes: 'marzo',
        },
        {
          estado: 'pendiente',
          mes: 'abril',
        },
        {
          estado: 'pendiente',
          mes: 'mayo',
        },
        {
          estado: 'pendiente',
          mes: 'junio',
        },
        {
          estado: 'pendiente',
          mes: 'julio',
        },
        {
          estado: 'pendiente',
          mes: 'agosto',
        },
        {
          estado: 'pendiente',
          mes: 'septiembre',
        },
        {
          estado: 'pendiente',
          mes: 'octubre',
        },
        {
          estado: 'pendiente',
          mes: 'noviembre',
        },
        {
          estado: 'pendiente',
          mes: 'diciembre',
        },
      ]
      break
    case 'febrero':
      meses = [
        {
          estado: 'cerrado',
          mes: 'enero',
        },
        {
          estado: 'pendiente',
          mes: 'febrero',
        },
        {
          estado: 'pendiente',
          mes: 'marzo',
        },
        {
          estado: 'pendiente',
          mes: 'abril',
        },
        {
          estado: 'pendiente',
          mes: 'mayo',
        },
        {
          estado: 'pendiente',
          mes: 'junio',
        },
        {
          estado: 'pendiente',
          mes: 'julio',
        },
        {
          estado: 'pendiente',
          mes: 'agosto',
        },
        {
          estado: 'pendiente',
          mes: 'septiembre',
        },
        {
          estado: 'pendiente',
          mes: 'octubre',
        },
        {
          estado: 'pendiente',
          mes: 'noviembre',
        },
        {
          estado: 'pendiente',
          mes: 'diciembre',
        },
      ]
      break
    case 'marzo':
      meses = [
        {
          estado: 'cerrado',
          mes: 'enero',
        },
        {
          estado: 'cerrado',
          mes: 'febrero',
        },
        {
          estado: 'pendiente',
          mes: 'marzo',
        },
        {
          estado: 'pendiente',
          mes: 'abril',
        },
        {
          estado: 'pendiente',
          mes: 'mayo',
        },
        {
          estado: 'pendiente',
          mes: 'junio',
        },
        {
          estado: 'pendiente',
          mes: 'julio',
        },
        {
          estado: 'pendiente',
          mes: 'agosto',
        },
        {
          estado: 'pendiente',
          mes: 'septiembre',
        },
        {
          estado: 'pendiente',
          mes: 'octubre',
        },
        {
          estado: 'pendiente',
          mes: 'noviembre',
        },
        {
          estado: 'pendiente',
          mes: 'diciembre',
        },
      ]
      break
    case 'abril':
      meses = [
        {
          estado: 'cerrado',
          mes: 'enero',
        },
        {
          estado: 'cerrado',
          mes: 'febrero',
        },
        {
          estado: 'cerrado',
          mes: 'marzo',
        },
        {
          estado: 'pendiente',
          mes: 'abril',
        },
        {
          estado: 'pendiente',
          mes: 'mayo',
        },
        {
          estado: 'pendiente',
          mes: 'junio',
        },
        {
          estado: 'pendiente',
          mes: 'julio',
        },
        {
          estado: 'pendiente',
          mes: 'agosto',
        },
        {
          estado: 'pendiente',
          mes: 'septiembre',
        },
        {
          estado: 'pendiente',
          mes: 'octubre',
        },
        {
          estado: 'pendiente',
          mes: 'noviembre',
        },
        {
          estado: 'pendiente',
          mes: 'diciembre',
        },
      ]
      break
    case 'mayo':
      meses = [
        {
          estado: 'cerrado',
          mes: 'enero',
        },
        {
          estado: 'cerrado',
          mes: 'febrero',
        },
        {
          estado: 'cerrado',
          mes: 'marzo',
        },
        {
          estado: 'cerrado',
          mes: 'abril',
        },
        {
          estado: 'pendiente',
          mes: 'mayo',
        },
        {
          estado: 'pendiente',
          mes: 'junio',
        },
        {
          estado: 'pendiente',
          mes: 'julio',
        },
        {
          estado: 'pendiente',
          mes: 'agosto',
        },
        {
          estado: 'pendiente',
          mes: 'septiembre',
        },
        {
          estado: 'pendiente',
          mes: 'octubre',
        },
        {
          estado: 'pendiente',
          mes: 'noviembre',
        },
        {
          estado: 'pendiente',
          mes: 'diciembre',
        },
      ]
      break
    case 'junio':
      meses = [
        {
          estado: 'cerrado',
          mes: 'enero',
        },
        {
          estado: 'cerrado',
          mes: 'febrero',
        },
        {
          estado: 'cerrado',
          mes: 'marzo',
        },
        {
          estado: 'cerrado',
          mes: 'abril',
        },
        {
          estado: 'cerrado',
          mes: 'mayo',
        },
        {
          estado: 'pendiente',
          mes: 'junio',
        },
        {
          estado: 'pendiente',
          mes: 'julio',
        },
        {
          estado: 'pendiente',
          mes: 'agosto',
        },
        {
          estado: 'pendiente',
          mes: 'septiembre',
        },
        {
          estado: 'pendiente',
          mes: 'octubre',
        },
        {
          estado: 'pendiente',
          mes: 'noviembre',
        },
        {
          estado: 'pendiente',
          mes: 'diciembre',
        },
      ]
      break
    case 'julio':
      meses = [
        {
          estado: 'cerrado',
          mes: 'enero',
        },
        {
          estado: 'cerrado',
          mes: 'febrero',
        },
        {
          estado: 'cerrado',
          mes: 'marzo',
        },
        {
          estado: 'cerrado',
          mes: 'abril',
        },
        {
          estado: 'cerrado',
          mes: 'mayo',
        },
        {
          estado: 'cerrado',
          mes: 'junio',
        },
        {
          estado: 'pendiente',
          mes: 'julio',
        },
        {
          estado: 'pendiente',
          mes: 'agosto',
        },
        {
          estado: 'pendiente',
          mes: 'septiembre',
        },
        {
          estado: 'pendiente',
          mes: 'octubre',
        },
        {
          estado: 'pendiente',
          mes: 'noviembre',
        },
        {
          estado: 'pendiente',
          mes: 'diciembre',
        },
      ]
      break
    case 'agosto':
      meses = [
        {
          estado: 'cerrado',
          mes: 'enero',
        },
        {
          estado: 'cerrado',
          mes: 'febrero',
        },
        {
          estado: 'cerrado',
          mes: 'marzo',
        },
        {
          estado: 'cerrado',
          mes: 'abril',
        },
        {
          estado: 'cerrado',
          mes: 'mayo',
        },
        {
          estado: 'cerrado',
          mes: 'junio',
        },
        {
          estado: 'cerrado',
          mes: 'julio',
        },
        {
          estado: 'pendiente',
          mes: 'agosto',
        },
        {
          estado: 'pendiente',
          mes: 'septiembre',
        },
        {
          estado: 'pendiente',
          mes: 'octubre',
        },
        {
          estado: 'pendiente',
          mes: 'noviembre',
        },
        {
          estado: 'pendiente',
          mes: 'diciembre',
        },
      ]
      break
    case 'septiembre':
      meses = [
        {
          estado: 'cerrado',
          mes: 'enero',
        },
        {
          estado: 'cerrado',
          mes: 'febrero',
        },
        {
          estado: 'cerrado',
          mes: 'marzo',
        },
        {
          estado: 'cerrado',
          mes: 'abril',
        },
        {
          estado: 'cerrado',
          mes: 'mayo',
        },
        {
          estado: 'cerrado',
          mes: 'junio',
        },
        {
          estado: 'cerrado',
          mes: 'julio',
        },
        {
          estado: 'cerrado',
          mes: 'agosto',
        },
        {
          estado: 'pendiente',
          mes: 'septiembre',
        },
        {
          estado: 'pendiente',
          mes: 'octubre',
        },
        {
          estado: 'pendiente',
          mes: 'noviembre',
        },
        {
          estado: 'pendiente',
          mes: 'diciembre',
        },
      ]
      break
    case 'octubre':
      meses = [
        {
          estado: 'cerrado',
          mes: 'enero',
        },
        {
          estado: 'cerrado',
          mes: 'febrero',
        },
        {
          estado: 'cerrado',
          mes: 'marzo',
        },
        {
          estado: 'cerrado',
          mes: 'abril',
        },
        {
          estado: 'cerrado',
          mes: 'mayo',
        },
        {
          estado: 'cerrado',
          mes: 'junio',
        },
        {
          estado: 'cerrado',
          mes: 'julio',
        },
        {
          estado: 'cerrado',
          mes: 'agosto',
        },
        {
          estado: 'cerrado',
          mes: 'septiembre',
        },
        {
          estado: 'pendiente',
          mes: 'octubre',
        },
        {
          estado: 'pendiente',
          mes: 'noviembre',
        },
        {
          estado: 'pendiente',
          mes: 'diciembre',
        },
      ]
      break
    case 'noviembre':
      meses = [
        {
          estado: 'cerrado',
          mes: 'enero',
        },
        {
          estado: 'cerrado',
          mes: 'febrero',
        },
        {
          estado: 'cerrado',
          mes: 'marzo',
        },
        {
          estado: 'cerrado',
          mes: 'abril',
        },
        {
          estado: 'cerrado',
          mes: 'mayo',
        },
        {
          estado: 'cerrado',
          mes: 'junio',
        },
        {
          estado: 'cerrado',
          mes: 'julio',
        },
        {
          estado: 'cerrado',
          mes: 'agosto',
        },
        {
          estado: 'cerrado',
          mes: 'septiembre',
        },
        {
          estado: 'cerrado',
          mes: 'octubre',
        },
        {
          estado: 'pendiente',
          mes: 'noviembre',
        },
        {
          estado: 'pendiente',
          mes: 'diciembre',
        },
      ]
      break
    case 'diciembre':
      meses = [
        {
          estado: 'cerrado',
          mes: 'enero',
        },
        {
          estado: 'cerrado',
          mes: 'febrero',
        },
        {
          estado: 'cerrado',
          mes: 'marzo',
        },
        {
          estado: 'cerrado',
          mes: 'abril',
        },
        {
          estado: 'cerrado',
          mes: 'mayo',
        },
        {
          estado: 'cerrado',
          mes: 'junio',
        },
        {
          estado: 'cerrado',
          mes: 'julio',
        },
        {
          estado: 'cerrado',
          mes: 'agosto',
        },
        {
          estado: 'cerrado',
          mes: 'septiembre',
        },
        {
          estado: 'cerrado',
          mes: 'octubre',
        },
        {
          estado: 'cerrado',
          mes: 'noviembre',
        },
        {
          estado: 'pendiente',
          mes: 'diciembre',
        },
      ]
      break
    default:
      break
  }

  return meses
}
