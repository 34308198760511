import * as Yup from 'yup'
import {IEmpleados} from '../../interfaces/IEmpleados'

const validationSchema = [
  Yup.object({
    documento: Yup.string()
      .required('El número de DNI, es necesario')
      .label('Documento')
      .min(7)

      .max(8),
    datosPersonales: Yup.object({
      sexo: Yup.string().required('El genero es necesario'),
    }),
  }),
  Yup.object({
    datosLaborales: Yup.object({
      legajo: Yup.string().required('El número de legajo es necesario'),
      fechaAlta: Yup.date().required('El Fecha de alta en la Afip es necesaria'),
    }),
  }),
  Yup.object({
    altaAfip: Yup.mixed().required('El archivo es requerido'),
    historialAnses: Yup.mixed().required('El archivo es requerido'),
    ddjjEmp: Yup.mixed().required('El archivo es requerido'),
  }),
]

const empleadoInit: IEmpleados = {
  _id: '',
  documento: '',
  cuit: '',
  fechaCarga: '',
  fechaModf: '',
  estado: '',
  progSocial: null,
  altaAfip: null,
  historialAnses: null,
  ddjjEmp: null,
  terminos_condiciones: false,
  documentosUnicos: [],
  datosPersonales: {
    nombres: '',
    apellido: '',
    cuil: '',
    ciudad: '',
    provincia: '',
    fecha_nacimiento: '',
    sexo: '',
  },
  descuentos: {
    genero: false,
    primer_empleo: false,
    cert_capacitacion: false,
    emp_radicada: false,
    titular_prog_social: false,
  },
  anio: [
    {
      anio: '2023',
      estado: 'pendiente',
      meses: [
        {
          estado: 'pendiente',
          mes: 'enero',
        },
        {
          estado: 'pendiente',
          mes: 'febrero',
        },
        {
          estado: 'pendiente',
          mes: 'marzo',
        },
        {
          estado: 'pendiente',
          mes: 'abril',
        },
        {
          estado: 'pendiente',
          mes: 'mayo',
        },
        {
          estado: 'pendiente',
          mes: 'junio',
        },
        {
          estado: 'pendiente',
          mes: 'julio',
        },
        {
          estado: 'pendiente',
          mes: 'agosto',
        },
        {
          estado: 'pendiente',
          mes: 'septiembre',
        },
        {
          estado: 'pendiente',
          mes: 'octubre',
        },
        {
          estado: 'pendiente',
          mes: 'noviembre',
        },
        {
          estado: 'pendiente',
          mes: 'diciembre',
        },
      ],
    },
  ],

  datosLaborales: {
    legajo: '',
    fechaAlta: '',
    estado: '',
  },
}

export {empleadoInit, validationSchema}
