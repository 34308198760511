import {request} from '../../../../../setup/axios/axios.util'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const URL = `${API_URL}/modules/noticia`

export const getAllNoticias = () => {
  return request().get(`${URL}/noticias`)
}

export const postNotica = (noticia: any) => {
  return request().post(`${URL}/nuevanoticia`, noticia)
}

export const postEditarImagenes = (noticia: any) => {
  return request().post(`${URL}/subirGaleria`, noticia)
}

export const postSubirPortada = (noticia: any) => {
  return request().post(`${URL}/portada`, noticia)
}
export const postSubirMiniatura = (noticia: any) => {
  return request().post(`${URL}/miniatura`, noticia)
}

export const patchBajarNoticia = (noticia: any) => {
  return request().patch(`${URL}/bajanoticia`, noticia)
} 

export const patchAltaNoticia = (noticia: any) => {
  return request().patch(`${URL}/altanoticia`, noticia)
}

export const patchPublicar = (noticia: any) => {
  return request().patch(`${URL}/publicarbtn`, noticia)
}

export const patchModNoticia = (noticia: any) => {
  return request().patch(`${URL}/modnoticia`, noticia)
}
export const postSubirImagen = (noticia: any) => {
  return request().post(`${URL}/subirImagenes`, noticia)
}
