import {Modal} from 'react-bootstrap-v5'

import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import moment from 'moment'
import {patchMov} from '../../services/Bens-Services'
import Swal from 'sweetalert2'
import {useSelector} from 'react-redux'
import {ISTATE} from '../../../../../../setup/redux/interface/reduxState.interface'

type props = {
  data?: any
  show: boolean
  tituloMenu?: string
  handleCloseModal: () => void
}
export const ModalBens: React.FC<props> = ({data, show, handleCloseModal, tituloMenu}) => {
  const [movi, setMovi] = useState([])

  const [movimiento, setMovimiento] = useState('')
  const [activePage, setActivePage] = useState(1)

  const rowsPerPage = 5
  const count = movi?.length
  const totalPages = Math.ceil(count / rowsPerPage)
  const calculatedRows = movi?.slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)
  const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1
  const end = activePage === totalPages ? count : beginning + rowsPerPage - 1

  const dashState: any = useSelector((state: ISTATE) => state.auth)

  //usuario nombre corto
  const nombreRes = dashState.usuario.nombreCompleto
  let cadena = nombreRes
  let fstChar = cadena.charAt(0)

  //
  const CargarMovimiento = (movimiento: any) => {
    const sendMov = {
      dni: data.dni,
      mov: {
        movimiento: movimiento,
        fecha: moment().format('L'),
        usuario: fstChar + '. ' + dashState.usuario.apellido,
      },
    }
    patchMov(sendMov).then((res: any) => {
      if (res.data.ok === true) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Movimiento cargado',
          showConfirmButton: false,
          timer: 1500,
        })
        setMovimiento('')
        setMovi(res.data.res.movimientos)
      }
    })
  }
  useEffect(() => {
    data.movimientos?.length > 0 && setMovi(data.movimientos)
    return () => {
      setMovi([])
    }
  }, [data.movimientos])

  return (
    <Modal
      className='modal fade '
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Movimientos</h5>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleCloseModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-lg-6 col-xl-9'>
              <input
                type='text'
                className='form-control form-control-solid'
                value={movimiento}
                onChange={(e: any) => setMovimiento(e.target.value)}
              />
            </div>
            {
              <div className='col-lg-6 col-xl-3'>
                {movimiento == '' ? (
                  <button type='button' className='btn btn-light-primary' disabled>
                    Cargar
                  </button>
                ) : (
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={() => {
                      CargarMovimiento(movimiento)
                    }}
                  >
                    Cargar
                  </button>
                )}
              </div>
            }
          </div>
          <div className='row'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted bg-light'>
                  <div className=''>
                    <div className='row pb-0'>
                      <div className='col-lg-6'>
                        <th className='fw-bolder text-dark'> Movimientos </th>
                      </div>
                      <div className='col-lg-4'>
                        <th className='fw-bolder text-dark'> Usuario </th>
                      </div>
                      <div className='col-lg-2'>
                        <th className='fw-bolder text-dark'> Fecha </th>
                      </div>
                    </div>
                  </div>
                </tr>
              </thead>
              <tbody>
                {calculatedRows?.map((value: any) => (
                  <tr key={value._id}>
                    <div className='row py-0'>
                      <div className='col-lg-6 altura-tabla fs-7'>
                        <td>{value.movimiento}</td>
                      </div>
                      <div className='col-lg-4 altura-tabla fs-7'>
                        <td>{value.usuario}</td>
                      </div>
                      <div className='col-lg-2 altura-tabla fs-7'>
                        <td>{value.fecha}</td>
                      </div>
                    </div>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='pag-cen-tabla'>
            <ul className='pagination'>
              <li className='page-item disabled'>
                <button
                  className='btn btn-sm btn-light-primary'
                  disabled={activePage === 1}
                  onClick={() => setActivePage(1)}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-3' />{' '}
                  Primero
                </button>
              </li>
              <li className='page-item '>
                <button
                  className='btn btn-sm btn-light-primary'
                  disabled={activePage === 1}
                  onClick={() => setActivePage(activePage - 1)}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr022.svg' className='svg-icon-3' />
                  Anterior
                </button>
              </li>
              <li className='page-item '>
                <button
                  className='btn btn-sm btn-light-primary'
                  disabled={activePage === totalPages}
                  onClick={() => setActivePage(activePage + 1)}
                >
                  Siguiente{' '}
                  <KTSVG path='/media/icons/duotune/arrows/arr071.svg' className='svg-icon-3' />
                </button>
              </li>

              <button
                className='btn btn-sm btn-light-primary'
                disabled={activePage === totalPages}
                onClick={() => setActivePage(totalPages)}
              >
                Último{' '}
                <KTSVG path='/media/icons/duotune/arrows/arr080.svg' className='svg-icon-3' />
              </button>
            </ul>
          </div>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-light-primary' onClick={handleCloseModal}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}
