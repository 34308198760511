import * as Yup from 'yup'

const validationDocSchema = Yup.object({
  anio: Yup.string().required('Debe seleccionar una opción').label('Año'),
  mes: Yup.string().required('Debe seleccionar una opción').label('Mes'),
  reciboSueldo: Yup.mixed().required('El archivo es requerido'),
})
const initPeriodosUP = {
  anio: '',
  mes: '',
  cerCap: null,
  reciboSueldo: null,
}

const validationDocSchemaMesxMes = Yup.object({
  periodo: Yup.string().required('Debe seleccionar una opción').label('Periodo'),
  mes: Yup.string().required('Debe seleccionar una opción').label('Mes'),
  form931: Yup.mixed().required('El archivo es requerido'),
  nominaDDJJPagoEmp: Yup.mixed().required('El archivo es requerido'),
  vepPagoElectronico: Yup.mixed().required('El archivo es requerido'),
})
const initMesxMes = {
  periodo: '',
  mes: '',
  documento: '',
  tipoDoc: '',
  estado: 'pendiente',
  fechaCarga: '',
  fechaModf: '',
  form931: null,
  nominaDDJJPagoEmp: null,
  vepPagoElectronico: null,
}

export {initPeriodosUP, validationDocSchema, validationDocSchemaMesxMes, initMesxMes}
