/** React */
import {Link, useHistory, useLocation} from 'react-router-dom'
import {useState} from 'react'
/** Formik */
import {Form, Formik} from 'formik'
/** Material */
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp'
import '../../../../../../src/_metronic/assets/sass/cud.scss'
/** Services */
import {updateLegajo} from '../services/cud-service'
/** Hook */
import {CreateModal2} from '../hooks/useModal'
/** Tables */
import {Patologias} from '../components/Tablas'
import {ButtonOrig} from '../components/Common/EditOrigBtn'
import Filter from '../components/Common/FilterCdi'

export const OrigenesEdit = () => {
  const location: any = useLocation()
  const [btnEdit, setBtnEdit] = useState(false)
  const history = useHistory();

  const handleBtn = () => {
    setBtnEdit(!btnEdit)
  }

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
      <Link to={{pathname: '/cud/Legajos', state:{
                activado: true,
                obj: location.state
              },}} className='alineacionb'>
          <ArrowBackSharpIcon />
          </Link>
      </div>
      <div className='card-body py-1'>
        <div className='table-responsive'>
          <Formik
            initialValues={location.state}
            onSubmit={(values) => {
              updateLegajo(values).then((res: any) => {
                CreateModal2(history)
                window.history.back()
              })
            }}
          >
            {({handleChange, handleBlur, values}) => (
              <div>
                <Form>
                  <div>
                    <div className='col-8 text-gray-600 fw-bold mt-2 fs-6'>
                      Problemas / Origenes{' '}
                    </div>
                    <div className='card bg-ligth border mb-6 card_align5 '>
                      {/* <ProblemasOrigenes handleChange={handleChange} handleBlur={handleBlur} /> */}
                      <Patologias
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values}
                        btnEdit={btnEdit}
                      />
                    </div>
                    {/* <Filter btnEdit={btnEdit} /> */}
                    <ButtonOrig btnEdit={btnEdit} handleBtn={handleBtn} />
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}