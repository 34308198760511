import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {getAllModulos} from '../services/ModuleService'
import {TablePerfil} from '../components/ModulosPerfil/PerilUs/TablePerfil'
import {ICreateModulos} from '../interfaces/ICreateModulo'
import {useLocation} from 'react-router'
import {ILocation} from '../../../../Interface/Ilocation'

interface ImoduloOptions {
  value: {}
  label: ''
  activo: boolean
}
interface IModulosOption extends Array<ImoduloOptions> {}

const SelectModulosPerfil = () => {
  let location: ILocation = useLocation()
  let perfilUS: ICreateModulos = location.state.perfilUs
  const [data, setData] = useState<ICreateModulos>([])
  const animatedComponents = makeAnimated()
  const [tablaPerfil, setTablaPerfil] = useState<ICreateModulos>(perfilUS)
  const [loading, setLoading] = useState(false)
  const [btnSave, setBtnSave] = useState<boolean>(false)
  const [opUpdate, setOpUpdate] = useState<boolean>(false)
  const ModulosOptions: IModulosOption = []

  useEffect(() => {
    getAllModulos().then((res: any) => {
      setData(res.data.data)
    })
    getAllModulos()
  }, [])

  useEffect(() => {
    tablaPerfil.length && setLoading(true)
    perfilUS.length && setOpUpdate(true)
  }, [tablaPerfil])

  for (let mod of data) {
    let nodOption: any = {}
    nodOption.value = mod
    nodOption.label = mod.nombre
    nodOption.activo = mod.activo
    ModulosOptions.push(nodOption)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 '>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Configuración del perfil</h3>
        </div>
        {/*  <div className='card-title m-0'>
          {btnSave && (
            <button className='btn btn-primary' onClick={() => {}}>
              Guardar
            </button>
          )}
        </div> */}
      </div>
      <div id='kt_account_notifications' className='collapse show'>
        <form className='form'>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <div className='container'>
              <div className='row'>
                <div className='col 12'>
                  <label>Seleccione Modulo</label>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={ModulosOptions}
                    onChange={(e: any) => {
                      let arr: any = []
                      if (e.length === 0) {
                        setLoading(false)
                        setTablaPerfil(perfilUS)
                      }
                      if (e.lenght === 0 && tablaPerfil.length === 0) {
                        setLoading(false)
                        setTablaPerfil(arr)
                      } else {
                        for (let mod of e) {
                          let exist = tablaPerfil.some((menu) => menu.nombre === mod.label)

                          let menuFil = tablaPerfil.filter((menu) => menu.nombre !== mod.label)

                          !exist
                            ? setTablaPerfil([...tablaPerfil, mod.value])
                            : setTablaPerfil(menuFil)
                        }
                        setLoading(true)
                      }
                    }}
                  ></Select>
                </div>
              </div>
            </div>
          </div>
        </form>
        {loading && (
          <div className='card-body border-top px-9 pt-3 pb-4'>
            <div className='table-responsive'>
              {
                <TablePerfil
                  perfil={tablaPerfil}
                  btnSave={setBtnSave}
                  idUsuario={location.state.idUsuario}
                  update={opUpdate}
                />
              }
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SelectModulosPerfil
