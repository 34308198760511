/** React */
import React, {useState, useEffect} from 'react'
/** Interfaces */
import {Ilegajo, Ilegajos} from '../../Interfaces'
import {Idatos} from '../../Interfaces/Datos-interface'
/** Material & Sass */
import CancelIcon from '@mui/icons-material/Cancel'
import TextField from '@mui/material/TextField'
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp'
/** Services */
import {getLegajos, searchByFecha, searchByLocalidad} from '../../services/cud-service'
/** Tablas */
import {DataLegajo} from '../Tablas'
import {useLocation} from 'react-router-dom'
import {BtnSearch} from '../Filter/BtnSearch'
import {BootstrapTooltip} from '../../helpers/bootstrapToolTip'
import Spinner from '../../../../../components/spinner/Spinner'
import { Ifilter } from '../../Interfaces/Ifilter'
import { ErrorMessage, Field, Form, Formik } from 'formik'

export const TramitesLegajos: React.FC<Idatos> = ({
  className,
  click,
  setBtnClick,
  setRouteTitle,
}) => {
  const locationl: any = useLocation()
  /** Title Page */
  let titlePage = 'Legajos'
  /** Value Array Legajos of API  */
  const [value, setValue] = useState<Ilegajos>([])
  /** Vista condicional para paginacion */
  const [vista, setVista] = useState(false)
  /** Setup pagination */
  const [pagination, setPagination] = useState<any>([])
  const [activePage, setActivePage] = useState(1)
  const [filter, setFilter] = useState<Ifilter>();
  const totalPages = Math.ceil(pagination.totalResult / pagination.limit)
  const [objetol, setObjetol] = useState('')
  const [loading, setLoading] = useState<any>(true)

  let activando: boolean = false
  let dnile: any = ''

  const buscarL = (): Ilegajo[] => {
    let dniL = value.filter((leg: Ilegajo) => leg.datosPersonales.dni)
    return dniL;
  }

  /** Function pedido Api */
  const legajosVew = async () => {
    if (filter) {
      if (filter.page) {
        filter.page= activePage;
      }
      switch (filter.tipo) {
        case 'localidad':  
          searchByLocalidad(filter).then((res: any) => {
          setPagination(res.data)
          setValue(res.data.res)
        })
        break;
        case 'fecha': 
          searchByFecha(filter).then((res: any) => {
            setPagination(res.data);
            setValue(res.data.res);
          });
        break;

      }
    }
    else {
      const res: any = await getLegajos(activePage)
      setPagination(res.data)
      setValue(res.data.result)
      setTimeout(()=>{
        setLoading(false)
      },2300)
    }
    }

  useEffect(() => {
    setRouteTitle(titlePage)
    legajosVew()
  }, [activePage])

  /** Function Btn Volver */
  const goBack = () => {
    setVista(false)
    legajosVew()
  }

  useEffect(() => {
    if (locationl.state != null) {
      setObjetol(locationl.state.obj)
    }
  }, [objetol])

  if (locationl.state !== null) {
    activando = locationl.state.activado
  }

  useEffect(() => {}, [activando])

  return (
    <>
      {click ? (
        <div className='card-xxl-stretch mb-5 mb-xl-8'>
          <div className='card'>
            <div className='card-body py-3'>
              <BtnSearch
                titlePage={titlePage}
                filtroLegajo={true}
                data={value}
                setValue={setValue}
                setActivePage={setActivePage}
                setVista={setVista}
                activePage={activePage}
                setPagination={setPagination}
                setBtnClick={setBtnClick}
                setFilter={setFilter}
              />
            </div>
          </div>
        </div>
      ) : null}
      <div className={`card ${className}`}>
        <div className='card-header'>
          <h5 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-5 mb-1 capital'>Legajos</span>
          </h5>
          <div className='card-toolbar'>
            <div>
              {vista ? (
                <BootstrapTooltip title='Volver' arrow followCursor placement='top'>
                  <button
                    type='button'
                    className='btn btn-light-primary btn-sm button_b'
                    onClick={goBack}
                    name='volver'
                  >
                    <ArrowBackSharpIcon />
                  </button>
                </BootstrapTooltip>
              ) : null}
            </div>
          </div>
        </div>
       

        <div className='card-body py-3'>
          <div className='table-responsive'>

            {loading === true ? (
            <div className='col-lg-12 elemento_centro'>
            <Spinner></Spinner>
            <p><span><b>Cargando Información...</b></span></p>
            </div>
            ) : (
              <DataLegajo
                vista={vista}
                activePage={activePage}
                totalPages={totalPages}
                setActivePage={setActivePage}
                activando={activando}
                legajo={buscarL()}
              />
            )}
          </div>
        </div>

      </div>
    </>
  )
}
