/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { Field, ErrorMessage } from 'formik'
import TextErrorFormik from '../../../../../../../components/Formik/TextErrorFormik'
import { IEventos } from '../EventoData'
import { useSelector } from 'react-redux'
import { ISTATE } from '../../../../../../../../setup/redux/interface/reduxState.interface'

type Props = {
  data: IEventos
}

const Step1: FC<Props> = ({ data }) => {
  const evState: any = useSelector((state: ISTATE) => state.eventos)
  const { evFull } = evState
  data.idEvento = evFull.length
  data.estado = 'planificado'
  //console.log('data.idEvento', data.idEvento)
  return (
    <div className='w-100'>
      <div className='pb-3 pb-lg-10'>
        <h3 className='fw-bolder d-flex align-items-center text-dark'>
          Datos Básicos del Evento
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h3>

        <div className='text-gray-400 fw-bold fs-6'>
          Completar todos los Datos Básicos.
        </div>
      </div>

      <div className='mb-4 fv-row'>
        <label className='form-label mb-3'>Titulo</label>
        <Field
          type='text'
          className='form-control form-control-sm form-control-solid'
          name='title'
          placeholder='Complete el Título del evento'
        />
        <ErrorMessage name='title' component={TextErrorFormik} />
      </div>
      <div className="row">

        <div className='col-6 mb-4'>
          <label className='form-label'>Descripcion</label>
          <Field
            as='textarea'
            name='descripcion'
            className='form-control form-control-sm form-control-solid'
            rows={3}
            placeholder='Complete la Descripción del evento'
          />
          <ErrorMessage name='descripcion' component={TextErrorFormik} />
        </div>

        <div className='col-6 mb-4'>
          <label className='form-label'>Objetivo</label>
          <Field
            as='textarea'
            name='objetivo'
            className='form-control form-control-sm form-control-solid'
            rows={3}
            placeholder='Complete la Descripción del evento'
          />
          <ErrorMessage name='objetivo' component={TextErrorFormik} />
        </div>
      </div>
      <div className=' mb-4'>
        <label className='form-label'>Actividad</label>
        <Field
          as='textarea'
          name='actividad'
          className='form-control form-control-sm form-control-solid'
          rows={2}
          placeholder='Complete la Actividad a desarrollar en el evento'
        />
        <ErrorMessage name='actividad' component={TextErrorFormik} />
      </div>
      <div className='mb-4 fv-row'>
        <label className='d-flex align-items-center form-label mb-3'>
          Tipo de Evento
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Seleccione el tipo de Evento que desea generar'
          ></i>
        </label>

        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='tipo'
              value='Público'
              id='tipo_0'
            />
            <label
              className='btn btn-sm btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
              htmlFor='tipo_0'
            >
              <span className='fw-bolder fs-6'>Público</span>
            </label>
          </div>
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='tipo'
              value='Privado'
              id='tipo_1'
            />
            <label
              className='btn btn-sm btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
              htmlFor='tipo_1'
            >
              <span className='fw-bolder fs-6'>Privado</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='tipo'
              value='Proyecto'
              id='tipo_2'
            />
            <label
              className='btn btn-sm btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
              htmlFor='tipo_2'
            >
              <span className='fw-bolder fs-6'>Proyecto</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='tipo'
              value='Capacitacion'
              id='tipo_3'
            />
            <label
              className='btn btn-sm btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
              htmlFor='tipo_3'
            >
              <span className='fw-bolder fs-6'>Capacitación</span>
            </label>
          </div>
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='tipo'
              value='Programa'
              id='tipo_4'
            />
            <label
              className='btn btn-sm btn-outline btn-outline-dashed btn-outline-default w-100 p-3'
              htmlFor='tipo_4'
            >
              <span className='fw-bolder fs-6'>Programa</span>
            </label>
          </div>

        </div>

        <div className='form-text'>
          Seleccione el Tipo de Evento que desea crear.
        </div>
        <ErrorMessage name='tipo' component={TextErrorFormik} />
      </div>

    </div>
  )
}

export { Step1 }
