import { useState, useMemo, useCallback, useRef, useEffect, FC, useContext } from "react";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Circle,
  MarkerClusterer,
} from "@react-google-maps/api";
import "./GoogleMaps.scss";
import { useSelector } from "react-redux";
import { ISTATE } from "../../../../../../../../setup/redux/interface/reduxState.interface";
import { IEventos } from "../../../CrearEventoPage/Steps/EventoData";
type LatLngLiteral = google.maps.LatLngLiteral;
type DirectionsResult = google.maps.DirectionsResult;
type MapOptions = google.maps.MapOptions;



const MapaInd: FC = () => {
  const eventosStore: any = useSelector((state: ISTATE) => state.eventos)
  const { evFiltro } = eventosStore
  /* let ubicacion = eventoSeleccionado.geom */
  const mapRef = useRef<GoogleMap>();
  ////console.log('ubicacion', ubicacion)
  let center = useMemo<LatLngLiteral>(
    () => ({ lat: -38.6417575824599, lng: -70.1185705180601 }),
    []
  );
  ////console.log('center', center)
  /* useEffect(() => {
    ubicacion = eventoSeleccionado.geom


  }, [eventoSeleccionado.geom.latitud]) */
  const options = useMemo<MapOptions>(
    () => ({
      mapId: "eb4da33d85d9c2d4",
      disableDefaultUI: false,
      clickableIcons: false,
    }),
    []
  );
  const onLoad = useCallback((map) => (mapRef.current = map), []);

  return (
    <div id="MapaIndicadores" className="map-container">
      <div className="map-container__map">
        <GoogleMap
          zoom={7}
          center={center}
          mapContainerClassName="map-container__map"
          options={options}
          onLoad={onLoad}
        >

          {evFiltro.map((ev: IEventos) => (

            < Marker key={ev._id}
              position={{ lat: ev.geom.latitud, lng: ev.geom.longitud }}
              icon={{  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
                path: google.maps.SymbolPath.CIRCLE,
                anchor: new google.maps.Point(1, 1),
                fillOpacity: 1,
                fillColor: ev.color,
                strokeWeight: 1,
                strokeColor: "black",
                scale: 12,
                /* labelOrigin: {ev.titulo}, */
              }} />
          ))}
        </GoogleMap>
      </div>
    </div>
  );
}
export { MapaInd }

const defaultOptions = {
  strokeOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
};
const closeOptions = {
  ...defaultOptions,
  zIndex: 3,
  fillOpacity: 0.05,
  strokeColor: "#8BC34A",
  fillColor: "#8BC34A",
};
const middleOptions = {
  ...defaultOptions,
  zIndex: 2,
  fillOpacity: 0.05,
  strokeColor: "#FBC02D",
  fillColor: "#FBC02D",
};
const farOptions = {
  ...defaultOptions,
  zIndex: 1,
  fillOpacity: 0.05,
  strokeColor: "#FF5252",
  fillColor: "#FF5252",
};

const generateHouses = (position: LatLngLiteral) => {
  const _houses: Array<LatLngLiteral> = [];
  for (let i = 0; i < 100; i++) {
    const direction = Math.random() < 0.5 ? -2 : 2;
    _houses.push({
      lat: position.lat + Math.random() / direction,
      lng: position.lng + Math.random() / direction,
    });
  }
  return _houses;
};
