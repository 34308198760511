/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import parse from 'html-react-parser'

type Props = {
  values: any
  
}

const Step3: FC<Props> = ({values}) => {
  const viewCuerpo = values.cuerpo ? parse(values.cuerpo) : ''

  return (
    <>
      <div className='w-100'>
        <div className='p-top-6 pb-4 pb-lg-15'>
          <h2 className='fw-bolder d-flex align-items-center text-dark'>
            Previsualización
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Billing is issued based on your selected account type'
            ></i>
          </h2>

          <div className='text-gray-400 fw-bold fs-6'>Previsualización de datos ingresados .</div>
          <hr className='text-gray-500' />
        </div>

        <div className='pb-10 pb-lg-12'>
          <div className='pb-2 fw-bolder text-dark'>
            <h3>Título de Noticia</h3>
          </div>
          <h3 className='text-gray-700 fw-bold fs-6'>{values.titulo}</h3>
        </div>

        <div className='pb-10 pb-lg-12'>
          <div className='pb-2 fw-bolder text-dark'>
            <h3>Temáticas</h3>
          </div>
          {values.tematicas?.map((tematica: any) => (
            <h3 className='text-gray-700 fw-bold fs-6'>{tematica}</h3>
          ))}
        </div>

        <div className='pb-10 pb-lg-12'>
          <div className='pb-2 fw-bolder text-dark'>
            <h3>Autor</h3>
          </div>
          <h3 className='text-gray-700 fw-bold fs-6'>{values.datos_autor}</h3>
        </div>

        <div className='pb-10 pb-lg-12'>
          <div className='pb-2 fw-bolder text-dark'>
            <h3>Cuerpo de Noticia</h3>
          </div>
          <h3 className='text-gray-700 fw-bold fs-6'>{viewCuerpo}</h3>
        </div>
      </div>
    </>
  )
}

export {Step3}
