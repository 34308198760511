export const types = {
  login: '[Auth] Login',
  logout: '[Auth] Logout',
  Register: '[Register] Action',
  SetUser: '[Set User] Action',
  Dashboard: '[Set Menu] Action',
  logoutDashboard: '[Set Menu] Logout',
  setClima: '[Set Clima] Setear CLima',
  remClima: '[Set Clima] Remover CLima'
}
