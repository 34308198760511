import { Form, Formik, FormikValues } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { Step01Area } from '../components/Organismos/StepsAreas/Step01Area'
import Step02Area from '../components/Organismos/StepsAreas/Step02Area'
import { useHistory, useLocation } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
import { initArea, validateDepSchema } from '../components/Organismos/StepsAreas/CreateAreaHelper'
import { areasPost } from '../util/CrearArea/sub'
import { ICreateArea } from '../interfaces/ICreateArea'

import Swal from 'sweetalert2'

const StepHorizontalArea = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(validateDepSchema[0])
  const [initValues] = useState<ICreateArea>(initArea)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(validateDepSchema[stepper.current.currentStepIndex - 1])
  }

  const orgId: any = useLocation()
  // ////console.log('Area/Sub', orgId)
  let history = useHistory()

  const submitStep = async (values: ICreateArea, actions: FormikValues) => {
    ////console.log(orgId)
    if (!stepper.current) {
      return
    }

    setCurrentSchema(validateDepSchema[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      let ids: any = {
        _idOrg: orgId.state._idOrg,
        _idArea: orgId.state._idArea,
        _idSubArea: orgId.state._idSubArea,
        nombreArea: orgId.state.nombreArea,
        nombreSubArea: orgId.state.nombreSubArea,
        orgID: orgId.state._id,
      }
      // ////console.log(ids)
      let respArea: any = await areasPost(values, ids)
      // ////console.log(respArea)

      if (respArea.data.ok) {
        let respOrg = respArea.data.dataOrg
        let respMsg = respArea.data.msg
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: respMsg,
          showConfirmButton: false,
          timer: 3500,
        })
        actions.resetForm()
        history.push({ pathname: '/admin/organismos/settings', state: respOrg })
      } else {
        let respOrg = respArea.data.data
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'los datos que intenta crear, ya existen en la organización',
          showConfirmButton: false,
          timer: 3500,
        })
        actions.resetForm()
        history.push({ pathname: '/admin/servicios', state: respOrg })
      }
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Datos de la Area</h3>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Informacion del titular</h3>
            </div>
          </div>

          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {(formik) => {
              //  ////console.log(formik)
              return (
                <Form
                  className='mx-auto mw-800px w-100 pt-10 pb-10'
                  noValidate
                  id='kt_create_account_form'
                >
                  <div className='current' data-kt-stepper-element='content'>
                    <Step01Area />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step02Area />
                  </div>
                  <div className='d-flex flex-stack pt-15'>
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Volver
                      </button>
                    </div>

                    <div>
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {stepper.current?.currentStepIndex !==
                            stepper.current?.totatStepsNumber! - 1 && 'Continuar'}
                          {stepper.current?.currentStepIndex ===
                            stepper.current?.totatStepsNumber! - 1 && 'Guardar'}
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default StepHorizontalArea
