/** React */
import {useEffect, useState} from 'react'
import {Link, useLocation, NavLink, useHistory} from 'react-router-dom'
/** Formik */
import {Form, Formik} from 'formik'
/** Material & Sass */
import {Button} from '@mui/material'
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp'
import '../../../../../../src/_metronic/assets/sass/cud.scss'
/** Services */
import {postCud, updateLegajo} from '../services/cud-service'
/** Hook */
import {CreateModal2} from '../hooks/useModal'
/** Tables */
// import { Documentacion} from '../components/Tablas/index'
import {Cud} from '../components/Tablas/DataCud'
import {initCud, initLegajo} from '../Interfaces/inits'

export const EditCud = () => {
  const [btnEdit, setBtnEdit] = useState(false)
  const [editdata, setEditdata] = useState<any>()
  const location: any = useLocation()
  let history = useHistory()

  const handleBtn = () => {
    setBtnEdit(!btnEdit)
  }
  useEffect(() => {
    let isMounted = true
    location.state?.tipo === 'edit' && setEditdata(location.state.data)

    return () => {
      isMounted = false
    }
  }, [location])

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <Link
          to={{
            pathname: '/cud/data/',
            state: {
              activado: true,
              obj: location.state.obj,
            },
          }}
          className='alineacionb'
        >
          <ArrowBackSharpIcon />
        </Link>
      </div>
      <div className='card-body py-1'>
        <div className='table-responsive'>
          <Formik
            initialValues={editdata || initCud}
            onSubmit={(values) => {
              let data: any = {}
              location.state.tipo === 'edit' && (data.legajo = location.state.obj.legajo)
              location.state.tipo !== 'edit' && (data.legajo = location.state.obj.legajo)
              data.tipo = location.state.tipo

              postCud(values, data).then((res: any) => {
                CreateModal2(history);
                history.push({
                  pathname: '/cud/data/',
                  state: {
                    activado: true,
                    obj: res.data.data,
                  },
                })
              })
              console.log(values)
              console.log(location.state)
            }}
            enableReinitialize
          >
            {(formik) => {
              return (
                <div>
                  <Form>
                    <div>
                      <div className='col-8 text-gray-700 fw-bold mt-2 fs-6'>DATOS CUD</div>
                      <div className='card bg-ligth border mb-6 card_align5 '>
                        <Cud
                          handleChange={formik.handleChange}
                          handleBlur={formik.handleBlur}
                          btnEdit={btnEdit}
                          formik={formik}
                        />
                      </div>
                      <div className='row card_align5'>
                        <div className='col-lg-8'>
                          <Button className='btn btn-primary btn-sm' onClick={handleBtn}>
                            {!btnEdit ? 'Editar Edición' : 'Deshabilitar Edición'}
                          </Button>
                          &nbsp;&nbsp;
                          {btnEdit && (
                            <Button
                              type='submit'
                              className='btn btn-primary btn-sm '
                              disabled={false}
                            >
                              Guardar
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}
