/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { getDocMesxMes, getPdf, modEstadoDocMensualEmpresa } from '../../../../app/modules/apps/EmpleoJoven/services/EJ.Services'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { ModalPeriodos } from '../../../../app/modules/apps/EmpleoJoven/pages/ModalPeriodos'
import Swal from 'sweetalert2'
import useAreaUser from '../../../../app/Hooks/useAreaUser'
type Props = {
  icon: string
  title: string
  titulo?: string
  description: string
  tipoUsuario?: string
  cuit?: string
  cuitEmpresa?: string
  archivo?: any
  dni?: string
  onChangeState?: any
}

const Card4: FC<Props> = ({ icon, title, titulo, description, cuit, cuitEmpresa, tipoUsuario, archivo, dni, onChangeState }) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  const [load, setLoad] = useState<boolean>(true)

  const { areaUsr } = useAreaUser();

  const closeModal = () => {
    setOpenModal(!openModal)
  }
  const verPDF = () => {
    setLoad(false)
    getPdf(tipoUsuario || '', cuit || '', archivo || '').then((res: any) => {
      const url = window.URL.createObjectURL(res.data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
      setLoad(true)
    })
  }

  const verPDFDocInitUs = () => {
    setTimeout(() => {
      console.log('estoyyy')
      getDocMesxMes(cuit).then((res: any) => {
        const url = window.URL.createObjectURL(res.data)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('target', '_blank')
        document.body.appendChild(link)
        link.click()
        setLoad(true)
      })
    }, 1000)
  }
  const verPDFMesXMes = () => {
    setTimeout(() => {
      getDocMesxMes(cuit).then((res: any) => {
        const url = window.URL.createObjectURL(res.data)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('target', '_blank')
        document.body.appendChild(link)
        link.click()
        setLoad(true)
      })
    }, 1000)
  }

  useEffect(() => { }, [archivo]);


  const opcRechazo = async () => {
    let value: any = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Mensaje para el empleador.',
      inputPlaceholder: 'Escriba su argumentación en este espacio.',
      inputAttributes: {
        'aria-label': 'Escriba su mensaje aquí.',
      },
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cerrar',
      showCancelButton: true,
    })
    if (value.value === '') {
      await Swal.fire({ toast: true, text: `Es necesario completar este formulario.`, timer: 2000 })
      opcRechazo()
    } else {
      return onChangeState(cuit, 'rechazado', value.value).then(() => {
        Swal.fire('Operación exitosa', 'Estado actualizado', 'success');
      });
    }
  }

  const selectOpBtn = async () => {
    const inputOptions: any = {};
    if (areaUsr === 'juventud') {
      // Usuario de Juventud
      inputOptions.validado = 'Validado';
      inputOptions.rechazado = 'Rechazado';
    } else {
      // Usuario de PYME
      inputOptions.aprobado = 'Aprobado';
      inputOptions.rechazado = 'Rechazado';
    }
    Swal.fire({
      title: 'Seleccione una opción',
      input: 'select',
      inputOptions,
      inputPlaceholder: 'Seleccione una opción',
      confirmButtonText: 'Cambiar',
      cancelButtonText: 'Cerrar',
      showCancelButton: true,
      inputValidator: (value) => {
        // console.log(value)
        return new Promise((resolve) => {
          if (value === 'rechazado') {
            opcRechazo()
              .then(() => {
                Swal.fire('Operación exitosa', 'Estado actualizado', 'success');
                resolve('');
              })
              .catch((error: any) => {
                resolve('');
              });
            resolve('')
          }
          if (value === 'validado') {
            onChangeState(cuit, 'validado').then(() => {
              Swal.fire('Operación exitosa', 'Estado actualizado', 'success');
              resolve('');
            });
          }
          if (value === 'aprobado') {
            onChangeState(cuit, 'aprobado').then(() => {
              Swal.fire('Operación exitosa', 'Estado actualizado', 'success');
              resolve('');
            });
          } else {
            resolve('Es necesario seleccionar una opción')
          }
        })
      },
    })
  }

  return (
    <div className='card h-100'>
      <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
        <a
          onClick={() => {
            if (load) {
              setLoad(false)
              if (tipoUsuario === 'adminUS') {
                verPDFDocInitUs()
              }
              if (tipoUsuario === 'centroPyme') {
                verPDFMesXMes()
              }
              if (tipoUsuario === 'empresas') {
                verPDF()
              }
              if (tipoUsuario === 'juventud') {
                verPDFMesXMes()
              }
            }
          }}
          className='text-gray-800 text-hover-primary d-flex flex-column'
        >
          <div className='symbol symbol-75px mb-6'>
            <img src={toAbsoluteUrl(icon)} alt='' />
          </div>
          {title === 'nominaddjjpagoemp' && (
            <div className='fs-6 fw-bolder mb-2'>Nomina DDJJ Empleados</div>
          )}
          {title === 'vep' && (
            <div className='fs-6 fw-bolder mb-2'>Comprobante pago</div>
          )}

          {title !== 'nominaddjjpagoemp' && (
            <div className='fs-6 fw-bolder mb-2 capital'>{title}</div>
          )}

        </a>
        {archivo && titulo !== 'doc inicial' && (
          <>
            <div className="px-3 mb-3">
              {archivo?.estado === 'aprobado' && (
                <span className='badge badge-success capital'>Aprobado</span>
              )}
              {archivo?.estado === 'presentado' && (
                <span className='badge badge-light-info capital'>Presentado</span>
              )}

              {archivo?.estado === 'pendiente' && (
                <span className='badge badge-warning capital'>Pendiente</span>
              )}
              {archivo?.estado === 'rechazado' && (
                <span className='badge badge-danger capital'>Rechazado</span>
              )}
              {archivo?.estado === 'validado' && (
                <span className='badge badge-light-success capital'>validado</span>
              )}
            </div>
            {['vep', 'nominaddjjpagoemp', 'form931'].includes(archivo.tipo_doc) && <div className='d-flex flex-center flex-wrap mb-5'>
              <a
                href='#'
                className='btn btn-sm btn-primary'
                onClick={() => {
                  selectOpBtn();
                }}
              >

                <KTSVG path='/media/icons/duotune/files/fil019.svg' className='svg-icon-3' />
                Cambiar estado
              </a>
            </div>}
          </>)}
        <div className='fs-7 fw-bold text-gray-400 mt-auto'>{description}</div>
      </div>
      {tipoUsuario === 'centroPyme' && (
        <ModalPeriodos
          openModal={openModal}
          closeModal={closeModal}
          titulo={'cambiar estado cpyme'}
        />)}
    </div>
  )
}

export { Card4 }
