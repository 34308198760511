import {request} from '../../../../../setup/axios/axios.util'
import {ICreateModulo} from '../interfaces/ICreateModulo'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const URL = `${API_URL}/modules/sis-admin`

export const getAllModulos = async () => {
  return request().get(`${URL}/modulo`)
}

export const postModulo = async (obj: ICreateModulo) => {
  let data = {data: {}}
  data.data = obj
  let urlPost = `${URL}/modulo`
  return request().post(urlPost, data)
}
export const updateModulo = async (value: ICreateModulo) => {
  let data = {data: {}}
  data.data = value
  let urlUpd = `${URL}/modulo/${value._id}`
  return request().put(urlUpd, data)
}

export const updSisModulo = async (icono: string, id: string) => {
  let data = {data: {icono: ''}, tipo: 'icono'}
  data.data.icono = icono
  let urlUpd = `${URL}/modulo/sistema/${id}`
  return request().put(urlUpd, data)
}
export const ActDescModulo = async (id: string) => {
  let data = {data: {}, tipo: 'estado'}
  let urlUpd = `${URL}/modulo/sistema/${id}`
  return request().put(urlUpd, data)
}
