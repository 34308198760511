import React, { ChangeEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ITablaDatosT } from '../../../Interfaces/TablaDatosT-interface'
import { ISTATE } from '../../../../../../../setup/redux/interface/reduxState.interface'
import * as swal from 'sweetalert2'
import { Ilinea2, Ilineas2 } from '../../../Interfaces/lineas-interfaces'
import { KTSVG } from '../../../../../../../_metronic/helpers/components/KTSVG'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import PhoneAndroid from '@mui/icons-material/PhoneAndroid'
import SignalCellularNodataIcon from '@mui/icons-material/SignalCellularNodata'
import { TextField } from '@mui/material'
import { Link } from 'react-router-dom'

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'

let tablaInicio: any = {
  className: 'card-xxl-stretch mb-5 mb-xl-8',
  tituloMenu: 'Administracion de sistemas',
  botonPrincipal: '',
  tablaDatos: [],
  link: '#',
  linkEdit: '#',
  headColumns: {
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
  },
}
export const TablaPendiente: React.FC<ITablaDatosT> = ({
  className,
  tituloMenu,
  botonPrincipal,
  tablaDatos,
  headColumns,
}) => {
  const [dataSeleccionada, setdataSeleccionada] = useState<any>([])
  const [selecUs, setSelecUs] = useState<string>('')

  const [Paginado1, setPaginado1] = useState(0)
  const [SearchActivo, setSearchActivo] = useState('')

  const filtrado2 = (): Ilinea2[] => {
    if (SearchActivo.length === 0) return tablaDatos!.slice(Paginado1, Paginado1 + 5)

    const filtrado1: any = tablaDatos?.filter((filtro1: Ilinea2) =>
      filtro1.numero.includes(SearchActivo)
    )
    return filtrado1.slice(Paginado1, Paginado1 + 5)
  }

  const proxPag = () => {
    if (
      tablaDatos!.filter((filtro1: Ilinea2) => filtro1.numero.includes(SearchActivo)).length >
      Paginado1 + 5
    )
      setPaginado1(Paginado1 + 5)
  }

  const antPag = () => {
    if (Paginado1 > 0) setPaginado1(Paginado1 - 5)
  }

  const onSearchChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setPaginado1(0)
    setSearchActivo(target.value)
  }

  useEffect(() => {
    ////console.log(dataSeleccionada.length)
    dataSeleccionada.length > 1 && setdataSeleccionada([])
  }, [dataSeleccionada])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder  fs-3 mb-1'>{tituloMenu}</span>
        </h3>
        <div className='card-toolbar'>
          {/* <a href='#' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            New Member
          </a> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='ps-4 min-w-10px rounded-start'>{headColumns?.col1}</th>
                <th className='min-w-100px'>{headColumns?.col2}</th>
                <th className='min-w-150px'>{headColumns?.col3}</th>
                <th className='min-w-150px'>{headColumns?.col4}</th>
                <th className='min-w-80px'>{headColumns?.col5}</th>
                <th className='min-w-20px'></th>
              </tr>
            </thead>
            {/* End::Table head */}

            {/* begin::Table body */}
            <tbody>
              {dataSeleccionada.length === 0
                ? filtrado2()?.map((datos: Ilinea2, index) => {
                  return (
                    <tr>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'></div>
                          <input type='checkbox' onClick={() => setdataSeleccionada([datos])} />
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'></div>
                          {datos.numero}
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'></div>
                          {'No asignado'}
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'></div>
                          {'No asignado'}
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'></div>
                          {datos.estado_linea}
                        </div>
                      </td>
                    </tr>
                  )
                })
                : dataSeleccionada?.map((datos: Ilinea2) => {
                  return (
                    <tr>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'></div>
                          <input
                            type='checkbox'
                            onClick={() => setdataSeleccionada((data: any) => [...data, datos])}
                          />
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'></div>
                          {datos.numero}
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'></div>
                          {'No asignado'}
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'></div>
                          {'No asignado'}
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'></div>
                          {datos.estado_linea}
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'></div>
                          <Link to={{ pathname: 'NuevoCargo/', state: { numero: datos.numero } }}>
                            {' '}
                            <button className='button01'>
                              <PersonAddAltIcon />
                            </button>{' '}
                          </Link>{' '}
                          <button className='button01'>
                            <SignalCellularNodataIcon />
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
            {/* end::Table body */}
          </table>
          {dataSeleccionada.length === 0 && (
            <TextField
              type='text'
              id='search'
              name='search'
              className='input_buscar'
              placeholder='Buscar'
              variant='standard'
              label=''
              value={SearchActivo}
              onChange={onSearchChange}
            ></TextField>
          )}
          {dataSeleccionada.length === 0 && (
            <a href='#' onClick={antPag} className='btn btn-sm btn-light-primary centrar-paginado'>
              <NavigateBeforeIcon />
            </a>
          )}
          &nbsp;
          {dataSeleccionada.length === 0 && (
            <a href='#' onClick={proxPag} className='btn btn-sm btn-light-primary centrar-paginado'>
              <NavigateNextIcon />
            </a>
          )}
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}
