import React from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
interface IregTotalesEmprendedores {
  totalEmprededores?: number
  apTotalEmprededores?: number
  pendTotalEmprededores?: number
}
export const TotalesEmprendedores: React.FC<IregTotalesEmprendedores> = ({
  totalEmprededores,
  apTotalEmprededores,
  pendTotalEmprededores,
}) => {
  return (
    <>
      <div className='d-flex flex-wrap flex-stack'>
        <div className='d-flex flex-column flex-grow-1 pe-8'>
          <div className='d-flex flex-wrap'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr066.svg'
                  className='svg-icon-3 svg-icon-primary me-2'
                />
                <div className='fs-2 fw-bolder'>{totalEmprededores}</div>
              </div>

              <div className='fw-bold fs-6 text-gray-400'>Emprendedores Inscriptos</div>
            </div>

            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr066.svg'
                  className='svg-icon-3 svg-icon-success me-2'
                />
                <div className='fs-2 fw-bolder'>{apTotalEmprededores}</div>
              </div>

              <div className='fw-bold fs-6 text-gray-400'>Emprendedores Validados</div>
            </div>

            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
              <div className='d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 svg-icon-warning me-2'
                />
                <div className='fs-2 fw-bolder'>{pendTotalEmprededores}</div>
              </div>

              <div className='fw-bold fs-6 text-gray-400'>Emprendedores Pendientes</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
