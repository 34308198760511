import React, { useState } from 'react'
import { PageLink } from '../../../../../../_metronic/layout/core'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { getAllTelefonia } from '../../services/telefonia-service'

type Props = {
  className: string
  mPrincipal?: []
  selectMenu(opcion: string, tablaDatos: {}): any
}

let tablaDatos = {} as any

const MenuPrincipalTelefonia: React.FC<Props> = ({ className, mPrincipal, selectMenu }) => {
  const handelChange = async (opcion: string) => {
    switch (opcion) {
      case 'activas':
        getAllTelefonia().then((resp: any) => {
          tablaDatos = {
            className: 'card-xxl-stretch mb-5 mb-xl-8',
            tablaDatos: resp.data.data_todo_activo,
            tituloMenu: 'Lineas Activas',
            botonPrincipal: 'Nueva alta',
            link: 'aLineas',
            linkEdit: '/abmtelefonia/alineas',
            headColumns: {
              col1: '',
              col2: 'Nro Linea',
              col3: 'Cargo',
              col4: 'Equipo',
              col5: 'Estado',
              col6: 'Opciones',
            },
          }

          selectMenu('activas', tablaDatos)
        })
        break

      case 'pendientes':
        getAllTelefonia().then((resp: any) => {
          ////console.log(resp.data)
          tablaDatos = {
            className: 'card-xxl-stretch mb-5 mb-xl-8',
            tablaDatos: resp.data.data_todo_pendiente,
            tituloMenu: 'Lineas Pendientes',
            botonPrincipal: 'Crear Usuario',
            link: 'aLineas',
            linkEdit: '/abmtelefonia/alineas',
            headColumns: {
              col1: '',
              col2: 'Nro Linea',
              col3: 'Cargo',
              col4: 'Equipo',
              col5: 'Estado',
              col6: 'Opciones',
            },
          }
          selectMenu('activas', tablaDatos)
        })
        break

      case 'consumos':
        getAllTelefonia().then((resp: any) => {
          ////console.log(resp.data)
          tablaDatos = {
            className: 'card-xxl-stretch mb-5 mb-xl-8',
            tablaDatos: resp.data.data_todo,
            tituloMenu: 'Consumos',
            botonPrincipal: 'Crear Usuario',
            link: 'aLineas',
            linkEdit: '/abmtelefonia/alineas',
            headColumns: {
              col1: '',
              col2: 'Nro Linea',
              col3: 'Cargo',
              col4: 'Equipo',
              col5: 'Estado',
              col6: 'Opciones',
            },
          }
          selectMenu('activas', tablaDatos)
        })
        break
    }
  }
  return (
    <div className={`card sombras ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Menú de Opciones</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {/* begin::Item */}
        {mPrincipal?.map((item: any) => {
          return (
            <div className='d-flex align-items-center mb-8' key={item._id}>
              <span className='bullet bullet-vertical h-40px bg-success'></span>

              <div className='flex-grow-1 mx-5'>
                <button
                  onClick={() => handelChange(item.name)}
                  className='btn btn-xl btn-light-primary capital'
                >
                  {item.name}
                </button>
              </div>
            </div>
          )
        })}

        {/* end:Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export default MenuPrincipalTelefonia
