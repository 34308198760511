/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {remTablaDatos} from '../../../../app/modules/apps/Sis-admin/redux/TablaDatosAction'

import {ISTATE} from '../../../../setup/redux/interface/reduxState.interface'
import {logoutDashboard} from '../../../../setup/redux/ReduxActions/DashActions'

const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(logoutDashboard())
    dispatch(remTablaDatos())
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('BensToken')
    sessionStorage.clear()
  }
  const dashState: any = useSelector((state: ISTATE) => state.auth)
  const {foto, usuario} = dashState

  return (
    <div
      className='sombras menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-325px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex px-3'>
          <img className='h-75px  rounded' src={foto} alt='fotoperfil' />
          <div className='d-flex flex-column pl-3'>
            <div className='fw-bolder d-flex  fs-5 capital'>{usuario.nombreCompleto}</div>
            <div className='fw-bolder d-flex align-items-start fs-5'>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {usuario.email}
              </a>
            </div>
            <div className='fw-bolder d-flex align-items-start fs-5'>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                Última conexión:{usuario.lastLogin}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link
          to={{
            pathname: '/admin/usuario/account',
            state: {usuario: usuario.usuario, op: 'BtnPerfil'},
          }}
          className='menu-link px-5'
        >
          Perfil de usuario
        </Link>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Salir
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
