import React from 'react'
import {useState} from 'react'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {red} from '@mui/material/colors'

interface props {
  perfil: []
}

function Row(props: {row: any}) {
  const {row} = props

  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align='left' component='th' scope='row' className={'capital'}>
          {row.nombre}
        </TableCell>
        <TableCell align='left' component='th' scope='row'>
          {row.descripcion}
        </TableCell>
        <TableCell align='left' component='th' scope='row'>
          {row.favorito ? (
            <CheckCircleOutlineIcon color='success' />
          ) : (
            <HighlightOffIcon sx={{color: red[900]}} />
          )}
        </TableCell>
        <TableCell align='justify' component='th' scope='row'>
          {row.WSmodulo.length}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{margin: 1}}>
              <Typography variant='h6' gutterBottom component='div'>
                Menús
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell align='center'>Administrador</TableCell>
                    <TableCell align='center'>Consultar </TableCell>
                    <TableCell align='center'>Crear </TableCell>
                    <TableCell align='center'>Editar </TableCell>
                    <TableCell align='center'>Exportar </TableCell>
                    <TableCell align='center'>Indicadores </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.menu?.map((menu: any, index: any) => (
                    <TableRow key={index}>
                      <TableCell component='th' scope='row' className={'capital'}>
                        {menu.name}
                      </TableCell>
                      <TableCell component='th' scope='row' align='center'>
                        {menu.roleUs.admin ? (
                          <CheckCircleOutlineIcon color='success' />
                        ) : (
                          <HighlightOffIcon sx={{color: red[900]}} />
                        )}
                      </TableCell>
                      <TableCell component='th' scope='row' align='center'>
                        {menu.roleUs.consulta ? (
                          <CheckCircleOutlineIcon color='success' />
                        ) : (
                          <HighlightOffIcon sx={{color: red[900]}} />
                        )}
                      </TableCell>
                      <TableCell component='th' scope='row' align='center'>
                        {menu.roleUs.crear ? (
                          <CheckCircleOutlineIcon color='success' />
                        ) : (
                          <HighlightOffIcon sx={{color: red[900]}} />
                        )}
                      </TableCell>

                      <TableCell component='th' scope='row' align='center'>
                        {menu.roleUs.editor ? (
                          <CheckCircleOutlineIcon color='success' />
                        ) : (
                          <HighlightOffIcon sx={{color: red[900]}} />
                        )}
                      </TableCell>
                      <TableCell component='th' scope='row' align='center'>
                        {menu.roleUs.exportar ? (
                          <CheckCircleOutlineIcon color='success' />
                        ) : (
                          <HighlightOffIcon sx={{color: red[900]}} />
                        )}
                      </TableCell>
                      <TableCell component='th' scope='row' align='center'>
                        {menu.roleUs.indicadores ? (
                          <CheckCircleOutlineIcon color='success' />
                        ) : (
                          <HighlightOffIcon sx={{color: red[900]}} />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export const TablaPeril: React.FC<props> = ({perfil}) => {
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        {
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align='left'>Modulo</TableCell>
                <TableCell align='left'>Descripción</TableCell>
                <TableCell align='justify'>Favorito&nbsp;</TableCell>
                <TableCell align='justify'>Web Service&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {perfil.map((row: any, index) => (
                <Row key={index} row={row} />
              ))}
            </TableBody>
          </Table>
        }
      </TableContainer>
    </React.Fragment>
  )
}
