import React, {useEffect, useState} from 'react'
import CambiarContraseñaCard from '../components/CardsMisDatos/CambiarContraseñaCard'
import DatosEmpCard from '../components/CardsMisDatos/DatosEmpCard'
//import DocPresentada from './DocPresentada'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
import {IEmpresa} from '../interfaces/Iempresas'
import {getOneEmprendedor, getOneEmpresa} from '../services/EJ.Services'
import {IEmprendedor} from '../interfaces/Iemprendedor'

const MisDatos = () => {
  let location: any = useLocation()

  const dashState: any = useSelector((state: ISTATE) => state.auth)
  const [miEmpresa, setMiEmpresa] = useState<IEmpresa>()
  const [miEmprendimiento, setMiEmprendimiento] = useState<IEmprendedor>()
  const [mostrarCard, setMostrarCard] = useState<boolean>(false)
  const [tipoUsr, setTipoUsr] = useState<string>('')
  let {usuario} = dashState

  useEffect(() => {
    let isMounted = true

    usuario.organizacion.datosOrganismo.nombre === 'empresas nqn' &&
      getOneEmpresa(usuario.usuario).then((res: any) => {
        if (isMounted) {
          res.data.ok && setMiEmpresa(res.data.data)
          setMostrarCard(true)
          setTipoUsr('empresas')
        }
      })
    usuario.organizacion.datosOrganismo.nombre === 'emprendedor nqn' &&
      getOneEmprendedor(usuario.usuario).then((res: any) => {
        if (isMounted) {
          res.data.ok && setMiEmprendimiento(res.data.data)
          setMostrarCard(true)
          setTipoUsr('emprendedores')
        }
      })
    return () => {
      isMounted = false
    }
  }, [usuario])

  return (
    <>
      {mostrarCard && (
        <DatosEmpCard empresa={miEmpresa} emprendedor={miEmprendimiento} tipoUsuario={tipoUsr} />
      )}
      {location.state?.us === 'gob' && (
        <DatosEmpCard
          empresa={location.state.empresa}
          emprendedor={location.state.emprendedor}
          tipoUsuario={location.state.tipoUsuario}
        />
      )}
      {/*  {location.state?.us === 'gob' && <CambiarContraseñaCard />} */}
    </>
  )
}
export default MisDatos
