import { useState } from "react"
import { useSelector } from "react-redux"
import { BrowserRouter as Router, Switch, Route, NavLink, Redirect } from 'react-router-dom'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { ISTATE } from "../../../../setup/redux/interface/reduxState.interface"
import { ErrorsPage } from "../../errors/ErrorsPage"
import { Admision } from "./components/Menu/Admision"
import { BootstrapTooltip } from "../Cud/helpers/bootstrapToolTip"
import { ICreateModulo } from "../Sis-admin/interfaces/ICreateModulo"
import { Dav } from "./pages/Home"
import { Legajos } from "./components/Menu/Legajos";



export const DavRoute = () => {
  /** btn filter states  */
  const [btnClick, setBtnClick] = useState(false)
  const [routeTitle, setRouteTitle] = useState('')

  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)
  let { perfil } = dashState
  const modulo: ICreateModulo = perfil.find((value: any) => value.nombre === 'dav')

  return (
    <>
      <div className='card-title fw-bolder text-white'>
        {/* <PageTitleCud breadcrumbs={[]}>CUD</PageTitleCud> */}
        <h1 style={{ textAlign: 'center' }}>DAV - Dispositivo de atencion a violentos</h1>
        <br />
      </div>
      <Router>
        <div className='card-xxl-stretch mb-5 mb-xl-8'>
          <div className='card'>
            <div className='card-body py-3'>
              <div className='row '>
                {dashState?.perfil.map((valu: any) => {
                  return valu.nombre === 'dav' ? (
                    <>
                      <div className='col-9'>
                        <div className='d-flex align-items-center probandoconlea'>
                          {valu.menu.map((menus: any, index: any) => {
                            switch (menus.name) {
                              case 'admision':
                                return (
                                  <div key={index} className='flex-grow-1 mx-2 '>
                                    <NavLink
                                      to='/dav/admision'
                                      activeClassName='nav-active'
                                      exact
                                    >
                                      <button
                                        className='btn btn-xl btn-light-primary capital menu-btn-cud'
                                        name='admision'
                                      >
                                        Admisión
                                      </button>
                                    </NavLink>
                                  </div>
                                )

                              case 'legajos':
                                return (
                                  <div key={index} className='flex-grow-1 mx-2'>
                                    <NavLink to='/dav/legajos' activeClassName='nav-active' exact>
                                      <button
                                        className='btn btn-xl btn-light-primary capital menu-btn-cud'
                                        name='legajos'
                                      >
                                        Legajos
                                      </button>
                                    </NavLink>
                                  </div>
                                )
                              default:
                                return (
                                  <Route key={index} path='/dav/404'>
                                    <ErrorsPage />
                                  </Route>
                                )
                            }
                          })}
                        </div>
                      </div>
                    </>
                  ) : null
                })}
              </div>
            </div>
          </div>
        </div>

        <Switch>
          <Route path='/dav/home'>
            <Dav />
          </Route>

          <Route
            path='/dav/admision'
            render={() => {
              const menuAct = modulo.menu.find((value) => value.name === 'admision')

              return menuAct?.roleUs?.crear ||
                menuAct?.roleUs?.editor ||
                menuAct?.roleUs?.consulta ? (
                <Admision
                  className={''}
                  click={btnClick}
                  setBtnClick={setBtnClick}
                  setRouteTitle={setRouteTitle}
                />
              ) : (
                <Redirect to={`/cud/404`} />
              )
            }}
          ></Route>
          <Route
            path='/dav/legajos'
            render={() => {
              const menuAct = modulo.menu.find((value) => value.name === 'legajos')

              return menuAct?.roleUs?.crear ||
                menuAct?.roleUs?.editor ||
                menuAct?.roleUs?.consulta ? (
                <Legajos
                  className={''}
                  click={btnClick}
                  setBtnClick={setBtnClick}
                  setRouteTitle={setRouteTitle}
                />
              ) : (
                <Redirect to={`/cud/404`} />
              )
            }}
          ></Route>
          <Route path='*'>
            <Redirect to='/page404' />
          </Route>
        </Switch>
      </Router>
    </>
  )
}
