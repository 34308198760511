import React, { useState, useEffect } from 'react'
import { Idatos } from '../../Interfaces/Datos-interface'
/** Services */
import { getTotalTramites, getTotalLegajos } from '../../services/cud-service'

import { CardSupTotales } from '../Indicadores/cardSupTotales'
import { IdatosGrafico, IindicadorTramite, Iresult, IresultLegajo, ItotalLegajo, ItotalMonth, Itotales } from '../../Interfaces/Iindicador'
import { AxiosResponse } from 'axios'
import HeaderCudIndicadores from '../Indicadores/HeaderCudIndicadores'
import CardDinamicAges from '../Indicadores/CardDinamicAges'
const TITLES_MESA = [
  { title: 'Proceso', valor: 0 },
  { title: 'Activos', valor: 0 },
  { title: 'Archivado', valor: 0 }
];
const TITLES_LEGAJOS = [
  { title: 'En lista de espera', valor: 0 },
  { title: 'Rechazado', valor: 0 },
  { title: 'Archivado', valor: 0 },
  { title: 'Aprobado', valor: 0 }
];
const SUBTITLE = "Información sobre los totales registrados hasta el día de la fecha.";


export interface HeaderIndicador {
  title: string
  valor: number
}

export const Indicadores: React.FC<Idatos> = ({ className, setRouteTitle }) => {
  const [Tramites, setTramites] = useState<IindicadorTramite | undefined>();
  const [totalTramites, setTotalTramites] = useState<Itotales | undefined>();

  const [totalLegajos, setTotalLegajos] = useState<ItotalLegajo | undefined>();
  const [datosGrafMesa, setDatosGrafMesa] = useState<IdatosGrafico>({ series: [], categories: [] });
  const [datosGrafLegajo, setDatosGrafLegajo] = useState<IdatosGrafico>({ series: [], categories: [] });

  const [cargarGraficas, setCargarGraficas] = useState(false);
  const [indicadorMesa, setIndicadorMesa] = useState<HeaderIndicador[]>(TITLES_MESA);
  const [indicadorLegajo, setIndicadorLegajo] = useState<HeaderIndicador[]>(TITLES_LEGAJOS);


  useEffect(() => {
    // if (Tramites.length) return;
    //setRouteTitle('')
    setCargarGraficas(false);
    getTotalTramites().then((res: AxiosResponse) => {
      let series: number[] = [];
      let categories: string[] = [];
      if (res.data && res.data.totalMonth) {
        series = res.data?.totalMonth.map((item: any) => item.reg);
        categories = res.data?.totalMonth.map((item: any) => item._id);
      }
      setDatosGrafMesa(prevState => ({ ...prevState, series, categories }));

      const resultTramites: Iresult[] = res.data.result;
      const registrosTotales: ItotalMonth[] = res.data.totalMonth;
      setCargarGraficas(true);

      let sumaTotal: number = 0;
      let sumaEnProceso: number = 0;
      let sumaActivos: number = 0;
      let sumaArchivados: number = 0;
      if (registrosTotales) {
        sumaTotal = registrosTotales.reduce((acc: number, curr: ItotalMonth) => acc + curr.reg, 0);
      }
      sumaEnProceso = resultTramites.reduce((acc: number, curr: Iresult) => acc + curr.totalEstadoEnProceso, 0);
      sumaActivos = resultTramites.reduce((acc: number, curr: Iresult) => acc + curr.totalEstadoActivo, 0);
      sumaArchivados = resultTramites.reduce((acc: number, curr: Iresult) => acc + curr.totalEstadoArchivado, 0);

      setTotalTramites({
        total: sumaTotal,
        totalEnProceso: sumaEnProceso,
        totalActivos: sumaActivos,
        totalArchivados: sumaArchivados,
      });

      setIndicadorMesa(prevState => {
        const newIndicadorMesa = [...prevState];
        const iProceso = newIndicadorMesa.findIndex(indicador => indicador.title === 'Proceso');
        const iActivos = newIndicadorMesa.findIndex(indicador => indicador.title === 'Activos');
        const iArchivados = newIndicadorMesa.findIndex(indicador => indicador.title === 'Archivados');
        if (iProceso !== -1) {
          newIndicadorMesa[iProceso] = {
            ...newIndicadorMesa[iProceso],
            valor: sumaEnProceso
          };
        }

        if (iActivos !== -1) {
          newIndicadorMesa[iActivos] = {
            ...newIndicadorMesa[iActivos],
            valor: sumaActivos
          };
        }

        if (iArchivados !== -1) {
          newIndicadorMesa[iArchivados] = {
            ...newIndicadorMesa[iArchivados],
            valor: sumaArchivados
          };
        }
        return newIndicadorMesa;
      });
    })

    getTotalLegajos().then((res: any) => {
      setTotalLegajos(res.data);
      let series: number[] = [];
      let categories: string[] = [];
      if (res.data && res.data.totalMonth) {
        series = res.data?.totalActivoByMonth.map((item: any) => item.reg);
        categories = res.data?.totalActivoByMonth.map((item: any) => item._id.fecha);
      }
      setDatosGrafLegajo(prevState => ({ ...prevState, series, categories }));
      let sumaTotal: number = 0;
      if (res.data.totalMonth) {
        sumaTotal = res.data.totalActivoByMonth.reduce((acc: number, curr: ItotalMonth) => acc + curr.reg, 0);
      }
      setTotalLegajos({
        total: sumaTotal,
        totalActivos: 50,
        totalTramiteConTurno: 0,
        totalAprobados: 0
      });
      setIndicadorLegajo(prevState => {
        const newIndicadorLegajo = [...prevState];
        const iEspera = newIndicadorLegajo.findIndex(indicador => indicador.title === 'En lista de espera');
        const iRechazado = newIndicadorLegajo.findIndex(indicador => indicador.title === 'Rechazado');
        const iAprobado = newIndicadorLegajo.findIndex(indicador => indicador.title === 'Aprobado');
        const iArchivado = newIndicadorLegajo.findIndex(indicador => indicador.title === 'Archivado');
        if (iEspera !== -1) {
          const { total: valor } = res.data.totalMonth.find((item: any) => item._id.estado === 'En lista de espera');
          newIndicadorLegajo[iEspera] = {
            ...newIndicadorLegajo[iEspera],
            valor
          };
        }
        if (iRechazado !== -1) {
          const { total: valor } = res.data.totalMonth.find((item: any) => item._id.estado === 'Rechazado');
          newIndicadorLegajo[iRechazado] = {
            ...newIndicadorLegajo[iRechazado],
            valor
          };
        }
        if (iAprobado !== -1) {
          const { total: valor } = res.data.totalMonth.find((item: any) => item._id.estado === 'Aprobado');
          newIndicadorLegajo[iAprobado] = {
            ...newIndicadorLegajo[iAprobado],
            valor
          };
        }
        console.log(iArchivado);
        if (iArchivado !== -1) {
          const { total: valor } = res.data.totalMonth.find((item: any) => item._id.estado === 'Archivado');
          newIndicadorLegajo[iArchivado] = {
            ...newIndicadorLegajo[iArchivado],
            valor
          };
        }
        return newIndicadorLegajo;
      });
    })
  }, [])

/*   useEffect(() => {


    console.log(Tramites)
  }, [cargarGraficas]); */

  return (
    <>
      <div className='row g-5 g-xl-8'>
        {/* begin::Card Mesa de Entrada */}
        <div className='col-xl-6'>
          <HeaderCudIndicadores indicatorTitles={indicadorMesa} title="Mesa de entrada" subtitle={SUBTITLE} className={className}>
            {/* begin::Chart */}
            {cargarGraficas ? <CardSupTotales
              className='card-xl-stretch mb-xl-8'
              chartColor='info'
              chartHeight='200px'
              titulo='Total'
              data={datosGrafMesa}
              total={totalTramites?.total}
            /> : <h1>Cargando...</h1>
            }
          </HeaderCudIndicadores>
        </div>
{        <div className='col-xl-6'>
          <HeaderCudIndicadores indicatorTitles={indicadorLegajo} title="Legajos" subtitle={SUBTITLE} className={className}>
            {cargarGraficas ? <CardSupTotales
              className='card-xl-stretch mb-xl-8'
              chartColor='primary'
              chartHeight='200px'
              titulo='Legajos'
              data={datosGrafLegajo}
              total={totalLegajos?.total}
            /> : <h1>Cargando...</h1>
            }
          </HeaderCudIndicadores>

        </div>}
        <div className="row">
          <CardDinamicAges />
        </div>
      </div>
    </>
  )
}
