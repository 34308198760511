import {request} from '../../../../../setup/axios/axios.util'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const getPersona = (dni: string, sexo: string) => {
  const URL = `${API_URL}/core/persona?dni=${dni}&sexo=${sexo}`
  return request().get(URL)
}
// Revisar lo cambie dni por documento
export const getDatosEmpleados = (dni: string, sexo: string) => {
  const URL = `${API_URL}/core/empleado?documento=${dni}&sexo=${sexo}`
  return request().get(URL)
}

// export const getIssnPersonaEstado = (dni: string, sexo: string) => {
//   const URL = `${API_URL}/core/WSJP_Estado?dni=${dni}&sexo=${sexo}`
//   return request().get(URL)
// }