import {ITablaDatos} from '../interfaces/TablaDatos'
import {SisAdminTypes} from './SisAdmin-types'

const tablaInicial: ITablaDatos = {
  className: 'card-xxl-stretch mb-5 mb-xl-8',
  tituloMenu: 'Administración de sistemas',
  botonPrincipal: 'Crear',
  tablaDatos: [],
  link: '#',
  linkEdit: '#',
  headColumns: {
    col1: '',
    col2: '',
    col3: '',
    col4: '',
  },
}

export const TablaDatosReducer = (state = tablaInicial, action: any) => {
  switch (action.type) {
    case SisAdminTypes.SetTabla:
      return {
        className: action.payload.tablaDatos.className,
        botonPrincipal: action.payload.tablaDatos.botonPrincipal,
        tituloMenu: action.payload.tablaDatos.tituloMenu,
        tablaDatos: action.payload.tablaDatos.tablaDatos,
        link: action.payload.tablaDatos.link,
        linkEdit: action.payload.tablaDatos.linkEdit,
        headColumns: action.payload.tablaDatos.headColumns,
      }
    case SisAdminTypes.RemTabla:
      return {tablaInicial}
    default:
      return state
  }
}
