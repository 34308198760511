import { useEffect, useState } from 'react';
import FormikControl from '../../../../../../../components/Formik/FormikControl';
import { FormikValues, useFormikContext } from 'formik';
import FormViviendaParticular from '../stepsEncuestaCommons/FormViviendaParticular';
import FormViviendaColectiva from '../stepsEncuestaCommons/FormViviendaColectiva';
import MyCustomSelect from '../../../Formik/MyCustomSelect';
import { getProvincias, localidadesNqn } from '../../../../../../../services/gral/proLocService';

const tipoVivOptions = [
    {
        nombre: 'Particular',
        value: 'particular',
    },
    {
        nombre: 'Colectiva',
        value: 'colectiva',
    }
]

export const DatosHabitacionales = () => {


    const formik = useFormikContext<FormikValues>();
    const [localidades, setLocalidades] = useState<any>([]);
    const [provincias, setProvincias] = useState<any>([]);

    useEffect(() => {
        localidadesNqn().then((res: any) => {
            const data = res.data.data.map((item: any) => ({ nombre: item.nombre, value: item.nombre.toLowerCase() }));
            setLocalidades(data)
        });
    }, []);
    useEffect(() => { }, [localidades]);
    useEffect(() => {
        getProvincias().then((res: any) => {
            const data = res.data.data.map((item: any) => ({ nombre: item.nombre, value: item.nombre }));
            setProvincias(data)
        });
    }, []);     
     useEffect(() => {
            const { setFieldValue, values } = formik;
            if (values.tipoVivienda === 'particular') {
                setFieldValue("vivienda", {
                    tipo: values.vivienda.tipo,
                    infraestructuraBasica: values.vivienda.infraestructuraBasica,
                    adaptada: values.vivienda.adaptada,
                    cantidadCuartos: values.vivienda.cantidadCuartos,
                    accesibilidad: values.vivienda.accesibilidad,
                    grupoConviviente: values.vivienda.grupoConviviente,
                    personaConCud: values.vivienda.personaConCud,
                    cantidadPersonas: values.vivienda.cantidadPersonas,
                });
            }
            if (values.tipoVivienda === 'colectiva') {
                setFieldValue("vivienda", {
                    tipoC: values.vivienda.tipoC,
                    condicionExternacion: values.vivienda.condicionExternacion,
                })
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [formik.values.tipoVivienda]); 
    return (
        <div className="card card-custom card-strech mt-10">
            <div className="card-header shadow bg-body rounded">
                <div className="card-title">
                    <h1>Situación Habitacional</h1>

                </div>

            </div>
            <div className="card-body">
                <div className="fv-row">
                    <div className="row pb-10 pb-lg-15">
                        <div className="col-3 pb-10 pb-lg-12">
                            <FormikControl
                                control='input'
                                label='Domicilio'
                                name='domicilioReal'
                            />
                        </div>

                        <div className="col-3 pb-10 pb-lg-12">
                            <FormikControl
                                control='input'
                                label='Código Postal'
                                name='codigoPostal'
                            />
                        </div>

                        <div className="col-3 pb-10 pb-lg-12">
                            <FormikControl
                                control='input'
                                label='Teléfono'
                                name='telefono'
                            />
                        </div>

                        <div className="col-3 pb-10 pb-lg-12">
                            <MyCustomSelect
                                label='Localidad'
                                name='localidad'
                                value={formik.values.localidad}
                                options={localidades}
                            />

                        </div>
                        <div className="col-3 pb-10 pb-lg-12">
                            <MyCustomSelect
                                label='Provincia'
                                name='provincia'
                                value={formik.values.provincia ? formik.values.provincia : "neuquen"}
                                options={provincias}
                            />

                        </div>
                        <div className="col-3 pb-10 pb-lg-12">
                            <FormikControl
                                control='input'
                                label='Departamento'
                                name='departamento'
                            />
                        </div>

                        <div className="col-3 pb-10 pb-lg-12">
                            <MyCustomSelect
                                label="Tipo vivienda"
                                name="tipoVivienda"
                                value={formik.values.tipoVivienda}
                                options={tipoVivOptions}
                            />
                        </div>

                        {formik.values.tipoVivienda === 'particular' ?
                            <FormViviendaParticular />
                            : null
                        }
                        {formik.values.tipoVivienda === 'colectiva' ?

                            <FormViviendaColectiva />
                            : null
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};