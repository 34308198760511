import {ErrorMessage, Field} from 'formik'
import React from 'react'
import TextErrorFormik from './TextErrorFormik'

interface Props {
    label: string,
    name: string,
    value?: string,
    options: {
        nombre: string,
        value: string,
    }[]
} 
const MyCustomSelect: React.FC<Props> = (props: Props) => {
  const {label, name, options, value, ...rest} = props
  return (
    <div>
      <label className='form-label fw-bolder text-dark fs-6'>{label}</label>
      <Field
        as='select'
        id={name}
        name={name}
        value={value}
        {...rest}
        className='form-control form-control-solid capital'
      >
        <option value=''>Seleccione</option>
        {options?.map((option: any) => {
          if (option.nombre) {
            return (
              <option key={option?.value} value={option?.value}>
                {option?.nombre}
              </option>
            )
          } else {
            return (
              <option key={option?._id} value={option?.nombreArea}>
                {option?.nombreArea}
              </option>
            )
          }
        })}
      </Field>
      <ErrorMessage name={name} component={TextErrorFormik} />
    </div>
  )
}

export default MyCustomSelect;
