import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {ISTATE} from '../../../../../../setup/redux/interface/reduxState.interface'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ITablaDatos} from '../../interfaces/TablaDatos'
import {ActDescModulo} from '../../services/ModuleService'
import {ModalImg} from '../ModalImg/ModalImg'
import Swal from 'sweetalert2'
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip'
import {styled} from '@mui/material/styles'
let tablaInicio: ITablaDatos = {
  className: 'card-xxl-stretch mb-5 mb-xl-8',
  tituloMenu: 'Administracion de sistemas',
  botonPrincipal: '',
  tablaDatos: [],
  link: '#',
  linkEdit: '#',
  headColumns: {
    col1: '',
    col2: '',
    col3: '',
    col4: '',
  },
}
export const TablaModulos: React.FC<ITablaDatos> = ({
  className,
  tituloMenu,
  botonPrincipal,
  tablaDatos,
}) => {
  const [InfoMenu, setInfoMenu] = useState<ITablaDatos>(tablaInicio)
  const infoPrinc: any = useSelector((state: ISTATE) => state.tablaDatos)
  const [selectModulo, setSelectModulo] = useState<string>('')
  const [moduloSelect, setModuloSelect] = useState([])
  const [moduloOption, setModuloOption] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState(false)
  const [imgSelect, setImgSelect] = useState()
  let color: string = ''
  const handelActivar = (nombre: string) => {
    Swal.fire({
      title: 'Esta por modificar el estado del modulo',
      text: '¿Desea continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar!',
      allowOutsideClick: false,
    }).then((result) => {
      result.isConfirmed &&
        ActDescModulo(nombre).then((res: any) => {
          if (res.data.ok) {
            Swal.fire({
              icon: 'success',
              title: 'El modulo fue modificado correctamente',
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${res.data.msg}`,
            })
          }
        })
    })
  }

  const handelSelecMod = (e: any) => {
    selectModulo === e ? setSelectModulo('') : setSelectModulo(e)
    if (selectModulo !== e) {
      let data: any = InfoMenu.tablaDatos?.filter((data: any) => data._id === e)
      setModuloSelect(data)
      setModuloOption(!moduloOption)
    } else {
      setModuloSelect([])
      setModuloOption(!moduloOption)
    }
  }

  useEffect(() => {
    infoPrinc.tablaInicial ? setInfoMenu(tablaInicio) : setInfoMenu(infoPrinc)
  }, [infoPrinc])

  const closeModal = () => {
    setOpenModal(!openModal)
  }

  const BootstrapTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} arrow classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
      backgroundColor: `${color}`,
      color: `#919A9B`,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${color}`,
      color: `#FFFFFF`,
      boxShadow: theme.shadows[2],
      fontSize: 11,
      fontWeightBold: 700,
      disabled: false,
    },
  }))

  return (
    <div>
      <div className={`card ${InfoMenu.className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-4'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label text-white fw-bolder fs-3 mb-1'>{InfoMenu.tituloMenu}</span>
          </h3>

          {infoPrinc.link && (
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a user'
            >
              <Link
                to={infoPrinc.link}
                className='btn btn-sm btn-light-primary'
                // data-bs-toggle='modal'
                // data-bs-target='#kt_modal_invite_friends'
              >
                <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                {InfoMenu.botonPrincipal}
              </Link>
            </div>
          )}
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              {InfoMenu.tablaDatos && (
                <thead>
                  <tr className='fw-bolder text-muted bg-light'>
                    <th className='w-50px rounded-start'></th>
                    <th className='min-w-150px'>{InfoMenu.headColumns?.col1}</th>
                    <th className='min-w-140px'>{InfoMenu.headColumns?.col2}</th>
                    {!selectModulo && (
                      <th className='min-w-100px text-end rounded-end'>
                        {InfoMenu.headColumns?.col3}
                      </th>
                    )}
                    {moduloOption && (
                      <th className='min-w-100px text-end rounded-end'>
                        {InfoMenu.headColumns?.col4}
                      </th>
                    )}
                  </tr>
                </thead>
              )}
              {/* end::Table head */}
              {/* begin::Table body */}

              <tbody>
                {selectModulo === ''
                  ? InfoMenu.tablaDatos?.map((item: any) => (
                      <tr key={item._id}>
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              value='1'
                              onChange={(e) => handelSelecMod(item._id)}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                              <img src={item.icono} alt='' />
                            </div>

                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                href='#'
                                className='text-dark fw-bolder uppercase text-hover-primary fs-6'
                              >
                                {item.nombre}
                              </a>
                            </div>
                          </div>
                        </td>

                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block fs-6 capital'
                          >
                            {item.menu.length}
                          </a>
                        </td>

                        <td className='text-end'>
                          {item.activo ? (
                            <span className='badge badge-light-success fs-8 fw-bolder my-2'>
                              Activo
                            </span>
                          ) : (
                            <span className='badge badge-light-danger fs-8 fw-bolder my-2'>
                              Desactivado
                            </span>
                          )}
                        </td>
                        {/* <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen019.svg'
                              className='svg-icon-3'
                            />
                          </a>

                          <Link
                            to={{pathname: infoPrinc.linkEdit, state: item}}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </Link>
                        </div>
                      </td> */}
                      </tr>
                    ))
                  : moduloSelect.map((item: any) => (
                      <tr key={item._id}>
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              value='1'
                              onChange={(e) => handelSelecMod(item._id)}
                            />
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                              <img src={item.icono} alt='' />
                            </div>

                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                href='#'
                                className='text-dark fw-bolder uppercase text-hover-primary fs-6'
                              >
                                {item.nombre}
                              </a>
                            </div>
                          </div>
                        </td>

                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block fs-6 capital'
                          >
                            {item.menu.length}
                          </a>
                        </td>

                        {!selectModulo && (
                          <td className='text-end'>
                            {item.activo ? (
                              <span className='badge badge-light-success fs-8 fw-bolder my-2'>
                                Activo
                              </span>
                            ) : (
                              <span className='badge badge-light-danger fs-8 fw-bolder my-2'>
                                Desactivado
                              </span>
                            )}
                          </td>
                        )}
                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <BootstrapTooltip
                              title='Modificar Logo'
                              arrow
                              followCursor
                              placement='top'
                            >
                              <div
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                onClick={(e: any) => {
                                  Swal.fire({
                                    title: 'Esta por modificar la imagen.',
                                    text: '¿Desea continuar?',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Continuar!',
                                    allowOutsideClick: false,
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      setImgSelect(item)
                                      setOpenModal(!openModal)
                                    }
                                  })
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen019.svg'
                                  className='svg-icon-3'
                                />
                              </div>
                            </BootstrapTooltip>
                            <BootstrapTooltip
                              title='Activar/Desactivar, Modulo'
                              arrow
                              followCursor
                              placement='top'
                            >
                              {item.activo ? (
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                  <button
                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                    onClick={() => {
                                      handelActivar(item._id)
                                    }}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen051.svg'
                                      className='svg-icon-3'
                                    />
                                  </button>
                                </div>
                              ) : (
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                  <button
                                    className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                                    onClick={() => {
                                      handelActivar(item._id)
                                    }}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen051.svg'
                                      className='svg-icon-3'
                                    />
                                  </button>
                                </div>
                              )}
                            </BootstrapTooltip>
                            <BootstrapTooltip
                              title='Editar, Modulo'
                              arrow
                              followCursor
                              placement='top'
                            >
                              <Link
                                to={{pathname: infoPrinc.linkEdit, state: item}}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='svg-icon-3'
                                />
                              </Link>
                            </BootstrapTooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>

              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <ModalImg
        openModal={openModal}
        modulo={imgSelect}
        servicioName={'modulo'}
        closeModal={closeModal}
      />
    </div>
  )
}
