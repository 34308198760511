/** React */
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { DataTramites } from '../Tablas/DataTramites'
/** Material */
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp'
/** Services */
import { getTramites, searchByLocalidad } from '../../services/cud-service'
/** Interfaces */
import { Idatos } from '../../Interfaces/Datos-interface'
import { Itramites, Itramitess } from '../../Interfaces/Itramite'
/** Moment */
import moment from 'moment'
import { BtnSearch } from '../Filter/BtnSearch'
import { BootstrapTooltip } from '../../helpers/bootstrapToolTip'
import Spinner from '../../../../../components/spinner/Spinner'
import { TIMEOUT } from 'dns'
import { Ifilter } from '../../Interfaces/Ifilter'
moment.locale('es-mx')

export const MesaEntrada: React.FC<Idatos> = ({
  className,
  click,
  setBtnClick,
  setRouteTitle
}) => {
  const location: any = useLocation()
  /** Title Page */
  let titlePage = 'Mesa'
  /** Value Array Tramites of API  */
  const [value, setValue] = useState<Itramitess>([])
  /** Vista condicional para paginacion */
  const [vista, setVista] = useState(false)
  /** Setup pagination */
  const [pagination, setPagination] = useState<any>({})
  const [activePage, setActivePage] = useState(1)
  const [filter, setFilter] = useState<Ifilter>();
  const totalPages = Math.ceil(pagination.totalResult / pagination.limit)
  const [objeto, setObjeto] = useState<any>('')
  const [loading, setLoading] = useState<any>(true)
  let activando: boolean = false
  let dnib: any = ''

  /** Function Btn Volver */

  /** Function Btn Volver */

  const buscarT = (): Itramites[] => {
     if (objeto !== '') {
      const dniT = [objeto];
      return dniT;
    }
    else { 
      let dniT = value.filter((tra: Itramites) => tra.dni);
      return dniT;
     } 
  }
  const goBack = () => {
    setVista(false)
    tramitesVew()
  }
  useEffect(() => {
    if (location.state != null && activando !== false) {
      console.log(location.state.obj);
      setObjeto(location.state.obj)
    }
  }, [objeto])
  
  /** Function pedido Api */
  const tramitesVew = async () => {
    if (filter) {
      if (filter.page) {
        filter.page = activePage;
      }
      searchByLocalidad(filter).then((res: any) => {
        setPagination(res.data);
        setValue(res.data.res);
      })
    }
    else {
      const res: any = await getTramites(activePage)
      setPagination(res.data)
      setValue(res.data.result)
      setTimeout(() => {
        setLoading(false)
      }, 1500)
    }
  }

  useEffect(() => {
    setRouteTitle(titlePage)
    tramitesVew()
  }, [activePage])


  useEffect(() => { }, [loading])

  if (location.state !== null) {
    activando = location.state.activado
  }

  useEffect(() => { }, [activando])

  useEffect(() => { }, [activando])

 // useEffect(() => { }, [buscarT])

  return (
    <>
      {click ? (
        <div className='card-xxl-stretch mb-5 mb-xl-8'>
          <div className='card'>
            <div className='card-body py-3'>
              <BtnSearch
                titlePage={titlePage}
                data={value}
                setValue={setValue}
                setActivePage={setActivePage}
                setVista={setVista}
                activePage={activePage}
                setPagination={setPagination}
                setBtnClick={setBtnClick}
                setFilter={setFilter}
              />
            </div>
          </div>
        </div>
      ) : null}
      <div className={`card ${className}`}>
        <div className='card-header'>
          <h5 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-5 mb-1 capital'>Mesa de entrada</span>
          </h5>

          <div className='card-toolbar'>
            {vista ? (
              <BootstrapTooltip title='Volver a la tabla' arrow followCursor placement='top'>
                <button
                  type='button'
                  className='btn btn-light-primary btn-sm'
                  name='Volver'
                  onClick={goBack}
                >
                  <ArrowBackSharpIcon />
                </button>
              </BootstrapTooltip>
            ) : value ? (
              <Link to={'/cud/NuevoTramite'} className='alineacionb'>
                <button className='btn btn-sm btn-light-primary menu-btn-cud' name='Nuevo Tramite'>
                  Nuevo Tramite
                </button>
              </Link>
            ) : null}
          </div>
        </div>

        {/* <div id="splash-screen" class="splash-screen">
            <img src="%PUBLIC_URL%/media/logos/logo-cargando.svg" alt="Apex logo" class="logoCargando" />
            <span>Cargando ...</span>
          </div> */}

        <div className='card-body py-3'>
          <div className='table-responsive'>
            {/* Compnente tabla tramites */}

            {loading === true ? (
              <div className='col-lg-12 elemento_centro'>
                <Spinner></Spinner>
                <p>
                  <span>
                    <b>Cargando Información...</b>
                  </span>
                </p>
              </div>
            ) : (
              <DataTramites
                vista={vista}
                activePage={activePage}
                totalPages={totalPages}
                setActivePage={setActivePage}
                activando={activando}
                tramite={buscarT()}
                objeto={objeto}
                setObjeto={setObjeto}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}