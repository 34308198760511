import { FormikValues, useFormikContext } from 'formik';
import TrayectoriaEducativa from '../stepsEncuestaCommons/TrayectoriaEducativa';
import FormikControl from '../../../../../../../components/Formik/FormikControl';
import MyCustomSelect from '../../../Formik/MyCustomSelect';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const SELECT_ALF_OPTIONS = [
    {
        nombre: "Alfabetizado",
        value: "alfabetizado"
    },
    {
        nombre: "Analfabeto",
        value: "analfabeto"
    },
    {
        nombre: "No aplica",
        value: "noAplica"
    },
];

const DatosEducacion = () => {
    const formik = useFormikContext<FormikValues>();

    return (
        <div className="card card-custom card-strech mt-10">
            <div className="card-header shadow bg-body rounded">
                <div className="card-title">
                    <h1>Educación</h1>

                </div>

            </div>
            <div className="card-body">
                <div className="fv-row">
                    <div className="row d-flex flex-start pt-10 pb-10 pb-lg-15">
                        <div className="col-12 p-3 mb-10 ">
                            <MyCustomSelect
                                label="Nivel Educativo"
                                name="nivelEducativo"
                                value={formik.values.nivelEducativo}
                                options={SELECT_ALF_OPTIONS}
                            />
                        </div>
                        <div className="col-12 p-3  mb-10">
                            <FormGroup row>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formik.values.concurrio === true}
                                            onChange={formik.handleChange}
                                            name="concurrio"
                                            value={formik.values.concurrio}
                                        />}
                                    label="Concurrió a alguna modalidad del sistema educativo"
                                />

                            </FormGroup>
                        </div>
                        <div className="col-12 p-3  mb-10">
                            <FormGroup row>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formik.values.concurre === true}
                                            onChange={formik.handleChange}
                                            name="concurre"
                                            value={formik.values.concurre}
                                        />}
                                    label="Actualmente concurre a alguna modalidad del sistema educativo"
                                />

                            </FormGroup>
                        </div>
                        <div className="col-12 p-3  mb-10">
                            <div className="text-center">
                                <h2 className="label font-weight-bold mb-5 text-uppercase">trayectoria educativa</h2>
                            </div>
                            <p className="d-flex text-danger justify-content-center">Se deben completar todos los campos que correspondan y la información completa de cada
                                nivel cursado
                            </p>

                            <TrayectoriaEducativa
                                name="inicial"
                            />
                            <TrayectoriaEducativa
                                name="primaria"
                            />
                            <TrayectoriaEducativa
                                name="secundaria"
                            />
                            <TrayectoriaEducativa
                                name="superior"
                            />

                        </div>
                        <div className="col-12 p-3  mb-10">
                            <FormGroup row>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formik.values.inclusionEducativa === true}
                                            onChange={formik.handleChange}
                                            name="inclusionEducativa"
                                            value={formik.values.inclusionEducativa}
                                        />}
                                    label="Participó en proyecto de inclusión educativa"
                                />

                            </FormGroup>

                        </div>
                        <div className="col-12 p-3  mb-10">
                            <FormGroup row>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formik.values.inclusionEducActual === true}
                                            onChange={formik.handleChange}
                                            name="inclusionEducActual"
                                            value={formik.values.inclusionEducActual}
                                        />}
                                    label="Actualmente participa en proyecto de inclusión educativa"
                                />

                            </FormGroup>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default DatosEducacion;
