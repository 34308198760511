import {ErrorMessage, Field} from 'formik'
import React from 'react'
import TextErrorFormik from './TextErrorFormik'

const InputFomik: React.FC = (props: any) => {
  const {label, name, disabled, ...rest} = props

  return (
    <div>
      <label className='form-label fw-bolder text-dark fs-6'>{label}</label>
      <Field
        id={name}
        name={name}
        {...rest}
        className='form-control form-control-solid'
        disabled={disabled}
      />
      <ErrorMessage name={name} component={TextErrorFormik} />
    </div>
  )
}

export default InputFomik
