import React from 'react'
import {Field, ErrorMessage} from 'formik'
import TextErrorFormik from './TextErrorFormik'

const TextAreaFormik: React.FC = (props: any) => {
  const {label, name, ...rest} = props
  return (
    <div>
      <label className='form-label fw-bolder text-dark fs-6'>{label}</label>
      <Field
        as='textarea'
        id={name}
        name={name}
        {...rest}
        className='form-control form-control-solid'
      />

      <ErrorMessage name={name} component={TextErrorFormik} />
    </div>
  )
}

export default TextAreaFormik
