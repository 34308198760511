import ReactDOM from 'react-dom'
// Redux
// http://github.com/rt2zz/redux-persist

// Axios

import { Chart, registerables } from 'chart.js'

// Apps
import { App } from './app/App'
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
/**
 * Base URL of the website.
 *
 * @see http://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see http://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see http://github.com/axios/axios#interceptors
 */

Chart.register(...registerables)

if (!navigator.geolocation) {
  alert('Tu navegador no tiene habilitada la Geolocalización')
  throw new Error('Tu navegador  no tiene habilitada la Geolocalización')
}

ReactDOM.render(
  <MetronicI18nProvider>
    {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}

    <App basename={PUBLIC_URL} />
  </MetronicI18nProvider>,
  document.getElementById('root')
)
