import React from 'react'

import { Calendario } from '../components/Calendario'

const AgendaPage: React.FC = () => {

  return (
    <>
      <Calendario />
    </>
  )
}

export default AgendaPage
