import React, {useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {ISTATE} from '../../../../setup/redux/interface/reduxState.interface'

import {BotonMenu} from '../botonmenu/BotonMenu'
import {RemTematicaAction} from '../../../../app/modules/apps/Civis/Redux/TematicaAction'
import {remTablaDatos} from '../../../../app/modules/apps/Sis-admin/redux/TablaDatosAction'
import {remTablaDatosBeng} from '../../../../app/modules/apps/bens/redux/TablaDatosAction'

type Props = {
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string
}

const MixedWidget2: React.FC<Props> = ({className, chartColor, chartHeight, strokeColor}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch()
  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)

  const {modOk} = dashState

  // //console.log('perfil', perfil)

  useEffect(() => {
    dispatch(RemTematicaAction())
    dispatch(remTablaDatos())
    dispatch(remTablaDatosBeng())
    localStorage.removeItem('lastView')
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartColor, strokeColor)
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  return (
    <div className={`card sombras ${className}`}>
      {/* begin::Header */}
      <div className={`card-header border-0 py-1 `} style={{backgroundColor: '#708090'}}>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-white mt-4'>Aplicaciones</span>
          <span className='text-white fw-bold fs-7'>
            Sistemas habilitados por perfil de Usuario
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body p-0'>
        <div className='d-flex flex-row flex-wrap '>
          {modOk.map((item: any) => (
          
            <div className='col rounded-2mb-7 pb-4' key={item.nombre}>
              
              <BotonMenu
                imagen={item.icono}
                pag_inicio={item.paginaInicio}
                nombre={item.nombre}
                descripcion={item.descripcion}
                activo={item.activo}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const chartOptions = (
  chartHeight: string,
  chartColor: string,
  strokeColor: string
): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const color = getCSSVariableValue('--bs-' + chartColor)

  return {
    series: [
      {
        name: 'Net Profit',
        data: [30, 45, 32, 70, 40, 40, 40],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ['transparent'],
    markers: {
      colors: [color],
      strokeColors: [strokeColor],
      strokeWidth: 3,
    },
  }
}

export {MixedWidget2}
