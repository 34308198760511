import {ErrorMessage, Field} from 'formik'
import React from 'react'
import TextErrorFormik from './TextErrorFormik'

const CheckGroupFormik = (props: any) => {
  const {label, name, options, ...rest} = props
  return (
    <div>
      <label className='form-label fw-bolder text-dark fs-6'>{label}</label>
      <Field id={name} name={name} {...rest} className='form-control form-control-solid'>
        {(field: any) => {
          return options.map((option: any) => {
            return (
              <React.Fragment key={option.key}>
                <input
                  type='checkbox'
                  id={option.value}
                  {...field}
                  {...rest}
                  value={option.value}
                  checked={field.value?.includes(option.value)}
                />
                <label htmlFor={option.value}>{option.key}</label>
              </React.Fragment>
            )
          })
        }}
      </Field>
      <ErrorMessage name={name} component={TextErrorFormik} />
    </div>
  )
}

export default CheckGroupFormik
