/** React */
import {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
/**  Icons Material UI */
import InfoIcon from '@mui/icons-material/Info'
import {Box, Button, Collapse} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
/** Interfaces */
import {Ijuntas, Ijunta} from '../../Interfaces/index'
import {BootstrapTooltip} from '../../helpers/bootstrapToolTip'
import {Pagination} from '../../helpers/pagination'

type Props = {
  junta: Ijuntas
  activePage: any
  totalPages: any
  setActivePage: any
}

export const DataJuntas: React.FC<Props> = ({junta, setActivePage, activePage, totalPages}) => {
  let locationBtnPrincipal = useLocation()

  /** State to save and set the value provided from the props junta */
  const [juntas, setJuntas] = useState<Ijuntas>([])
  /** State 01 to change the views of the render and set the iterator of the array */
  const [dataJunta, setDataJunta] = useState<any>([])
  /** State 02 to change the views of the render (Pagination vs Edit Item) */
  const [hidden, setHidden] = useState(false)

  /**
   * Effects to change sets and configurate the conditional rendering
   */
  useEffect(() => {
    dataJunta.length > 1 && setDataJunta([])
    setJuntas(junta)
    dataJunta.length === 1 && setHidden(true)
    return () => {}
  }, [junta, dataJunta])

  useEffect(() => {
    dataJunta.length !== 1 && setHidden(false)
    return () => {}
  }, [dataJunta, setHidden])

  useEffect(() => {
    setDataJunta([])
    return () => {}
  }, [locationBtnPrincipal])
  return (
    <>
      <table className='table align-middle gs-0 gy-4'>
        <thead className='border-tabla-right'>
          <tr className='fw-bolder bg-light'>
            <th className='min-w-4px'></th>
            <th className='min-w-150px'>Nombre</th>
            <th className='min-w-150px'>Domicilio</th>
            <th className='min-w-150px'>Localidad</th>
            <th className='min-w-150px'>Estado</th>
            <th className='min-w-40px'>Acciones</th>
          </tr>
        </thead>
        <tbody className='border-tabla-top'>
          {dataJunta.length === 0
            ? juntas?.map((value: Ijunta, index: any) => {
                return (
                  <tr className='table_font_normal'>
                    <td></td>
                    <td>{value.nombre.replace(/\b\w/g, (l) => l.toUpperCase())}</td>
                    <td>{value.domicilio.replace(/\b\w/g, (l) => l.toUpperCase())} </td>
                    <td>{value.localidad.replace(/\b\w/g, (l) => l.toUpperCase())}</td>
                    <td>
                      {value.activo === true ? (
                        <span className='badge badge-light-success'>Activo</span>
                      ) : (
                        <span className='badge badge-light-warning'>Inactivo</span>
                      )}{' '}
                    </td>
                    <td>
                      <BootstrapTooltip title='Detalles' arrow followCursor placement='top'>
                        <button
                          className='btn btn-icon btn-bg-primary btn-color-light btn-active-light btn-active-color-primary btn-sm me-1'
                          onClick={() => setDataJunta((data: any) => [...data, value])}
                        >
                          <InfoIcon />
                        </button>
                      </BootstrapTooltip>
                    </td>
                  </tr>
                )
              })
            : dataJunta?.map((value: Ijunta, index: any) => {
                return (
                  <>
                    <tr className='table_font_normal'>
                      <td></td>
                      <td>{value.nombre.replace(/\b\w/g, (l) => l.toUpperCase())}</td>
                      <td>{value.domicilio.replace(/\b\w/g, (l) => l.toUpperCase())} </td>
                      <td>{value.localidad.replace(/\b\w/g, (l) => l.toUpperCase())}</td>
                      <td>
                        {value.activo === true ? (
                          <span className='badge badge-light-success'>Activo</span>
                        ) : (
                          <span className='badge badge-light-warning'>Inactivo</span>
                        )}{' '}
                      </td>
                      <td>
                        <BootstrapTooltip title='Mostrar Todos' arrow followCursor placement='top'>
                          <button
                            className='btn btn-icon btn-bg-primary btn-color-light btn-active-light btn-active-color-primary btn-sm me-1'
                            onClick={() => setDataJunta((data: any) => [...data, value])}
                          >
                            <CloseIcon />
                          </button>
                        </BootstrapTooltip>
                      </td>
                    </tr>
                    <tr>
                      <td style={{paddingBottom: 0, paddingTop: 2}} colSpan={5}>
                        <Collapse in={true} timeout='auto' unmountOnExit>
                          <Box sx={{margin: 1}} className='no-margin-top'>
                            <div className='mt-5 fv-row'></div>
                            <br></br>
                            <Link to={{pathname: 'editJunta/', state: value}}>
                              <Button className='btn btn-sm btn-light-primary menu-btn-cud'>
                                Editar Junta
                              </Button>
                            </Link>
                          </Box>
                        </Collapse>
                      </td>
                    </tr>
                  </>
                )
              })}
        </tbody>
      </table>
      {hidden === false ? (
        <Pagination activePage={activePage} setActivePage={setActivePage} totalPages={totalPages} />
      ) : (
        ''
      )}
    </>
  )
}
