import {useSelector} from 'react-redux'
import React, {useEffect, useState} from 'react'
import {MenuPrincipalCud, DatosMenu, PageTitleCud} from '../components/Index'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
import {Idatos} from '../Interfaces/Datos-interface'

type Props = {
  className: string
  mPrincipal?: []
}

export const Cud = () => {
  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)
  const {perfil} = dashState
  
  const [selectMenu, setSelectMenu] = useState<Idatos>()

/*   const selectData = (opcion: string, tablaDatos: Idatos) => {
    setSelectMenu(tablaDatos)
  } */
  //Filtro el rol del usuario
  let Role: any = []
  let array = perfil
  for (const menu of array) {
    Role.push(menu)
  }
/*   const FiltRole: any = Role.filter(function (nombre: {nombre: string}) {
    return nombre.nombre === 'cud'
  }) */
  useEffect(() => {}, [selectMenu])
  return (
    <div className='card-title fw-bolder text-white pt-3'>
      <div  className='row gy-5 gx-xl-12'>
       
        <div className='col-xl-9 col-md-9'>
          {/* <DatosMenu className={'col-xl-9 col-md-9'} tituloMenu={selectMenu?.tituloMenu} /> */}
        </div>
      </div>
    </div>
  )
}
