import React from 'react';
import { Field, useFormikContext, FormikValues } from 'formik';
import FormikControl from '../../../../../../../components/Formik/FormikControl';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, styled } from '@mui/material';
import TextErrorFormik from '../../../Formik/TextErrorFormik';

const CONDICION_LABORAL = [
  {
    label: 'Trabaja',
    name: 'trabaja',
    value: 'trabaja',
  },
  {
    label: 'No Trabaja',
    name: 'noTrabaja',
    value: 'noTrabaja',
  },
  {
    label: 'Busca trabajo',
    name: 'buscaTrabajo',
    value: 'buscaTrabajo',
  },
  {
    label: 'No aplica',
    name: 'noAplica',
    value: 'noAplica',
  },
];

const CATEGORIAS = [
  {
    label: "Obrero/a o Empleado/a",
    name: 'empleado',
    value: 'empleado'
  },
  {
    label: "Patrón (con personal a cargo)",
    name: 'patron',
    value: 'patron'
  },
  {
    label: "Trabajo por cuenta propia",
    name: 'cuentaPropia',
    value: 'cuentaPropia'
  },
  {
    label: "Trabajador/a Familiar",
    name: 'familiar',
    value: 'familiar'
  },
  {
    label: "Otro trabajo",
    name: 'otro',
    value: 'otro'
  },
];

const APORTES = [
  {
    label: "Propios",
    name: "propios",
    value: "propios",
  },
  {
    label: "Empleador",
    name: "empleador",
    value: "empleador",
  },
  {
    label: "Ninguno",
    name: "ninguno",
    value: "ninguno",
  },
];

export const ActividadLaboral = () => {
  const formik = useFormikContext<FormikValues>();
  console.log(formik.values);
  const StyledFormGroup = styled(FormGroup)({
    display: 'flex',
    flexDirection: 'row',
    gap: '50px',
  });

  const StyledRadio = styled(Radio)({
    width: '50px', // Ajusta el ancho del checkbox aquí
    height: '40px', // Ajusta la altura del checkbox aquí
  });

  const StyledFormControlLabel = styled(FormControlLabel)({
    fontSize: '15px', // Ajusta el tamaño de fuente del texto aquí
    color: '#333', // Ajusta el color del texto aquí
  });

  return (
    <>
      <div className="card card-custom card-strech mt-10">
        <div className="card-header shadow bg-body rounded">
          <div className="card-title">
          <h1>Actividad Laboral</h1>

          </div>

        </div>
        <div className="card-body">
          <div className="fv-row">
            <div className="row d-flex flex-start pt-10 pb-10 pb-lg-15">
              <FormControl component="fieldset">
                <FormLabel component="legend">Condicion Actividad</FormLabel>
                <RadioGroup
                  row
                  value={formik.values.condicion}
                  onChange={(event) => formik.setFieldValue("condicion", event.target.value)}
                >
                  {CONDICION_LABORAL.map((item) => (
                    <FormControlLabel
                      key={item.name}
                      name={item.name}
                      value={item.value}
                      control={
                        <Radio
                          checked={formik.values.condicion === item.name}

                        />
                      }
                      label={item.label}
                    />
                  ))}
                  {formik.touched.condicion && formik.errors.condicion && <TextErrorFormik>{formik.errors.condicion.toString()}</TextErrorFormik>}

                </RadioGroup>
              </FormControl>
            </div>
            <div className="row d-flex flex-start pt-10 pb-10 pb-lg-15">
              <FormControl component="fieldset">
                <FormLabel component="legend">Categoría</FormLabel>
                <RadioGroup
                  row
                  value={formik.values.categoria}
                  onChange={(event) => formik.setFieldValue("categoria", event.target.value)}
                  name='categoria-items'
                >
                  {CATEGORIAS.map((item) => (
                    <FormControlLabel
                      key={item.name}
                      name={item.name}
                      value={item.value}
                      control={
                        <Radio
                          checked={formik.values.categoria === item.name}

                        />
                      }
                      label={item.label}
                    />
                  ))}
                  {formik.touched.categoria && formik.errors.categoria && <TextErrorFormik>{formik.errors.categoria.toString()}</TextErrorFormik>}
                </RadioGroup>
              </FormControl>
              {formik.values.categoria === 'otro' &&
                <FormikControl
                  control="input"
                  defaultValue=""
                  label="Especificar otro"
                  name="otroTrabajo"
                />
              }

            </div>
            <div className="row d-flex flex-start pt-10 pb-10 pb-lg-15">
              <FormControl component="fieldset">
                <FormLabel component="legend">Aportes Previsionales</FormLabel>
                <RadioGroup
                  row
                  value={formik.values.aportes}
                  onChange={(event) => formik.setFieldValue("aportes", event.target.value)}
                  name='categoria-items'
                >
                  {APORTES.map((item) => (
                    <FormControlLabel
                      key={item.name}
                      name={item.name}
                      value={item.value}
                      control={
                        <Radio
                          checked={formik.values.aportes === item.name}

                        />
                      }
                      label={item.label}
                    />
                  ))}
                  {formik.touched.aportes && formik.errors.aportes && <TextErrorFormik>{formik.errors.aportes.toString()}</TextErrorFormik>}
                </RadioGroup>
              </FormControl>

            </div>

          </div>
        </div>
      </div>
    </>
  );
};


