import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISTATE } from '../../../../../setup/redux/interface/reduxState.interface';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { RightToolbar } from '../components/explore/RightToolbar';
import HeaderIndicadores from '../components/Headers/HeaderIndicadores';
import { evCargarFiltro } from '../Redux/actions/eventos';
import { IndCalculos } from './Indicadores/components/IndicadorGraf';
import { MapaIndicadores } from './Indicadores/components/MapaIndicadores';
import { TablaIndicadores } from './Indicadores/components/TablaIndicadores';
type Props = {
  className: string
}
let tablaInicio: Props = {
  className: 'card mb-5 mb-xl-8',
}
const IndicadoresPage: React.FC<Props> = ({ className }) => {
  const menuState: any = useSelector((state: ISTATE) => state.menuBoton)
  const { seleccion } = menuState
  return (
    <div className={`card sombras`} id='IndicadoresCal'>
      <PageTitle breadcrumbs={[]}>Agenda</PageTitle>
      <HeaderIndicadores />
      <RightToolbar />
      <IndCalculos className={''} />
      <div className="row p-5">

        <div className="col-4"><TablaIndicadores /></div>
        <div className="col-8"><MapaIndicadores /></div>
      </div>
    </div>
  )
};

export default IndicadoresPage;
