import React from 'react'
import {useSelector} from 'react-redux'
import {Switch, Route, useRouteMatch, Redirect} from 'react-router-dom'
import {ISTATE} from '../../../../setup/redux/interface/reduxState.interface'
import {ICreateModulo} from '../Sis-admin/interfaces/ICreateModulo'
import {TablaInscripciones} from './components/TablasBens/ItemMenuInscripciones'
import BensPage from './pages/BensPage'
import {Horizontal} from './pages/VerInscripcion'

import './styles/styles.scss'

const BensRouter = () => {
  const {url} = useRouteMatch()

  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)
  let {perfil} = dashState
  const modulo: ICreateModulo = perfil.find((value: any) => value.nombre === 'Bens')

  return (
    <Switch>
      <Route exact path={`${url}/bens`} component={BensPage} />
      <Route exact path={`${url}/EditarInscripcion`} component={Horizontal} />
      <Route exact path={`${url}/inscripciones`} component={TablaInscripciones} />

      <Route path='*'>
        <Redirect to='/page404' />
      </Route>
    </Switch>
  )
}
export default BensRouter
