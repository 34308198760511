import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ISTATE } from '../../../../../../setup/redux/interface/reduxState.interface'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { IEventos } from '../../pages/CrearEventoPage/Steps/EventoData'
import { eventosSetAccion, eventosSetSeleccionado, evSetFormulario } from '../../Redux/actions/eventos'
import { seleccionMenu } from '../../Redux/actions/menuActions'
import { uiCloseModal } from '../../Redux/actions/ui'
import './BotonAgenda.scss'

type Props = {
  imagen?: string
  pag_inicio: string
  nombre?: string
  colorFondo?: string
  colorDisable?: string
  sizeBoton?: string
  sizeIcon?: string
  eventoMostrar: IEventos
  componente?: any
}
const ButtonMarginClass = 'ms-1 ms-lg-3'

const BotonAgendaEvento: React.FC<Props> = ({
  imagen,
  pag_inicio,
  nombre,
  colorFondo,
  colorDisable,
  sizeBoton,
  sizeIcon,
  eventoMostrar,
}) => {
  const dispatch = useDispatch()
  const { modalOpen } = useSelector((state: any) => state.ui)
  const selStore: any = useSelector((state: ISTATE) => state.menuBoton)
  const { seleccion } = selStore
  const eventosStore: any = useSelector((state: ISTATE) => state.eventos)
  const { formulario } = eventosStore
  let color: any = ''
  let ButtonIconSizeClass = sizeIcon !== undefined ? sizeIcon : 'svg-icon-1'
  let ButtonHeightClass = sizeBoton !== undefined ? sizeBoton : 'w-30px h-30px w-md-40px h-md-40px'

  const resetMenu = () => {
    let temp: any = []
    seleccion.map((sel: any) => {
      sel.selec = true
      temp.push(sel)
    })
    dispatch(seleccionMenu(temp))
  }
  // ////console.log('Acciones en BotonAgenda Evento', acciones)
  //////console.log(' +-+-+-+-+-+-+-+-+-+-++--+-+-+-+-+-+- Datos en BotonAgenda Evento', eventoMostrar)
  //////console.log(' +-+-+-+-+-+-+-+-+-+-++--+-+-+-+-+-+- Datos en BotonAgenda Evento', eventoSeleccionado)
  //////console.log(' +-+-+-+-+-+-+-+-+-+-++--+-+-+-+-+-+- Datos en BotonAgenda Evento', eventoActivo)

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement='top' />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${color}`,
      color: `#FFFFFF`,
      boxShadow: theme.shadows[1],
      fontSize: 11,
      fontWeightBold: 700,
      disabled: false,
    },
  }))
  return (
    <>
      <Link
        to={{ pathname: `${pag_inicio}`, state: { titulo: `${nombre}`, eventoSel: formulario } }}
      >
        <LightTooltip
          className='negrita'
          children={
            <div className={clsx('d-flex align-items-center', ButtonMarginClass)}>
              <button
                className={clsx(
                  `btn btn-icon  ${colorFondo} btn-custom ${colorDisable}`,
                  ButtonHeightClass
                )}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
                onClick={() => {
                  let accionesTemp = {}

                  //////console.log('/////////////////eventoSel', eventoMostrar)
                  switch (eventoMostrar.estado) {
                    case 'planificado':
                      accionesTemp = {
                        borrar: false,
                        editar: true,
                        cancelar: true,
                        guardar: false,
                        enviarForm: false,
                        imprimir: true,
                        conclusion: false,
                      }
                      break
                    case 'en curso':
                      accionesTemp = {
                        borrar: false,
                        editar: false,
                        cancelar: true,
                        guardar: false,
                        enviarForm: false,
                        imprimir: true,
                        conclusion: false,
                      }
                      break
                    case 'realizado':
                      accionesTemp = {
                        borrar: false,
                        editar: false,
                        cancelar: false,
                        guardar: false,
                        enviarForm: false,
                        imprimir: true,
                        conclusion: true,
                      }
                      break
                    case 'cancelado':
                      accionesTemp = {
                        borrar: false,
                        editar: false,
                        cancelar: false,
                        guardar: false,
                        enviarForm: false,
                        imprimir: true,
                        conclusion: false,
                      }
                      break

                    default:
                      break
                  }
                  // ////console.log('eventoSel BotonAgendaEvento', eventoMostrar)
                  dispatch(uiCloseModal())
                  dispatch(evSetFormulario(eventoMostrar))
                  dispatch(eventosSetSeleccionado(eventoMostrar))
                  dispatch(eventosSetAccion(accionesTemp))
                  resetMenu()
                }}
              >
                <KTSVG path={`/media/icons/duotune${imagen}`} className={ButtonIconSizeClass} />
              </button>
            </div>
          }
          title={
            <Typography color='inherit' style={{ fontWeight: 600 }}>
              {nombre}
            </Typography>
          }
        ></LightTooltip>
      </Link>
    </>
  )
}
export { BotonAgendaEvento }
