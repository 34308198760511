import {ITablaDatos} from '../interfaces/TablaDatos'
import {SisAdminTypes} from './SisAdmin-types'

export const tablaDatosAction = (tablaDatos: ITablaDatos) => ({
  type: SisAdminTypes.SetTabla,
  payload: {
    tablaDatos,
  },
})

export const remTablaDatos = () => ({
  type: SisAdminTypes.RemTabla,
})
