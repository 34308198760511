import moment from 'moment'

const initTramitesN = {
  fecha: moment().format('DD/MM/YYYY'),
  dni: '',
  datosPersonales: {
    dni: '',
    sexo: '',
    apellido: '',
    nombre: '',
    domicilio: '',
    fechaNacimiento: '',
    telefono: '',
    localidad: '',
    mail: '',
    nacionalidad: 'Argentina',
    autopercepcion: '',
    tipoDoc: '',
    cuit: '',
    codigoPostal: '',
    departamento: '',
    tipoResidencia: '',
    fechaVencimientoResidencia: '',
    estadoCivil: '',
    tipoNacionalidad: '',
    vehiculoLey: '',
    fechaVehiculo: '',
    simboloInternacional: '',
    derechoAsignacionesF: '',
    provincia:''
  },

  activo: true,
  estado: 'activo',
  metodo: 'Local',
  fechaCreacion: '',
  usuarioCreacion: '',
  fechaModificacion: '',
  usuarioModificacion: '',
  junta: '',
  datosTutor: {
    dni: '',
    nombre: '',
    apellido: '',
    domicilio: '',
    localidad: '',
    fechaNacimiento: '',
    sexo: '',
    mail: '',
    nacionalidad: '',
    telefono: '',
    codigoPostal: '',
    tipoDoc: '',
    tipoTutor: '',
    fechaDesignacionTutor: '',
    juzgado: '',
    secretaria: '',
    dptoJudicial: '',
    fiscalia: '',
    defensoria: '',
    interesadoApoyoJudicial: '',
    interesadoApoyoExtraJudicial: '',
    curadorDesignadoJudicial: '',
    tipoPariente: '',
  },
  alertas: {
    cambioMayor: false,
  },
}

export {initTramitesN}

const datosR: any = {
  activo: true,
  metodo: 'Local',
  usuarioCreacion: '',
  junta: '',
  dni: '',
  datosPersonales: {
    apellido: '',
    nombre: '',
    dni: '',
    domicilio: '',
    localidad: '',
    sexo: '',
    metodo: 'LocalES',
    activo: true,
  },
}
export {datosR}

const datosT: any = {
  datosTutor: {
    apellido: '',
    nombre: '',
    dni: '',
    domicilio: '',
    localidad: '',
    sexo: '',
    metodo: 'LocalES',
    activo: true,
    telefono: '',
    mail: '',
    nacionalidad: 'Argentina',
  },
}
export {datosT}

const datosE: any = {
  datosTutor: {
    apellido: '',
    nombre: '',
    dni: '',
    domicilio: '',
    localidad: '',
    sexo: '',
    metodo: 'LocalES',
    activo: true,
    telefono: '',
    mail: '',
    nacionalidad: 'Argentina',
  },
}

export {datosE}
