import {Box, Collapse} from '@mui/material'

import React, {useState, useEffect} from 'react'
import {ButtonActionBens} from '../../ButtonActionBens'
import {ModalBens} from '../../Modal/ModalBens'

type props = {
  data: any
  className: string
  headColumns?: {
    col1?: string
    col2?: string
    col3?: string
    col4?: string
    col5?: string
    col6?: string
  }
  tituloMenu?: string
}
export const TablaDni: React.FC<props> = ({data, className, headColumns, tituloMenu}) => {
  const [open] = useState(true)
  const [selectItem, setSelectItem] = useState<any>('')
  const [modalState, setModalState] = useState(false)
  const handleCloseModal = () => setModalState(false)

  const handelSelecBens = (e: any) => {
    setSelectItem(e)
  }

  return (
    <div className={`card ${className}`}>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <div className=''>
                  <div className='row pb-0'>
                    <div className='col-1'>
                      <th>
                        <div className='d-flex justify-content-start flex-column'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            value='1'
                            data-kt-check='false'
                            data-kt-check-target='.widget-9-check'
                          />
                        </div>
                      </th>
                    </div>
                    <div className='col-1'>
                      <th className='fw-bolder text-dark'> {headColumns?.col2} </th>
                    </div>
                    <div className='col-2'>
                      <th className='fw-bolder text-dark'> {headColumns?.col3} </th>
                    </div>
                    <div className='col-2'>
                      <th className='fw-bolder text-dark'> {headColumns?.col4} </th>
                    </div>
                    <div className='col-2 '>
                      <th className='fw-bolder text-dark'> {headColumns?.col5} </th>
                    </div>
                    <div className='col-3'>
                      {selectItem.length === 0 ? (
                        <th className='fw-bolder text-dark'> Estado</th>
                      ) : (
                        <th className='fw-bolder text-dark'>Acciones</th>
                      )}
                    </div>
                  </div>
                </div>
              </tr>
            </thead>
            <tbody>
              {selectItem ? (
                <tr>
                  <div className='row'>
                    <div className='col-1'>
                      <td>
                        <div className='d-flex justify-content-start flex-column'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            data-kt-check='true'
                            checked
                            data-kt-check-target='.widget-9-check'
                            onChange={(e) => {
                              handelSelecBens('')
                            }}
                          />
                        </div>
                      </td>
                    </div>
                    <div className='col-1 altura-tabla'>
                      <td>
                        <div className='d-flex justify-content-start flex-column'>
                          <a className='text-dark text-hover-primary fs-6'>{data.id_tramite}</a>
                        </div>
                      </td>
                    </div>
                    <div className='col-2 altura-tabla'>
                      <td>
                        <div className='d-flex justify-content-start flex-column'>
                          <a className='text-dark text-hover-primary fs-6'>{data.dni}</a>
                        </div>
                      </td>
                    </div>
                    <div className='col-2 altura-tabla'>
                      <td>
                        <div className='d-flex justify-content-start flex-column'>
                          <a className='text-dark text-hover-primary fs-6'>{data.apellido}</a>
                        </div>
                      </td>
                    </div>
                    <div className='col-2 altura-tabla'>
                      <td>
                        <div className='d-flex justify-content-start flex-column'>
                          <a className='text-dark text-hover-primary fs-6'>{data.nombre}</a>
                        </div>
                      </td>
                    </div>
                    <div className='col-3 altura-tabla'>
                      <td className='text-end actions-td-button'>
                        <div className='d-flex justify-content-start flex-column'>
                          <ButtonActionBens
                            objet={data}
                            tituloMenu={tituloMenu}
                            tituloTabla='DNI'
                            setModalState={setModalState}
                          />
                        </div>
                      </td>
                    </div>
                  </div>
                </tr>
              ) : (
                <>
                  <tr>
                    <div className='row py-0'>
                      <div className='col-1 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              data-kt-check='true'
                              data-kt-check-target='.widget-9-check'
                              onChange={(e) => {
                                handelSelecBens(data._id)
                              }}
                            />
                          </div>
                        </td>
                      </div>
                      <div className='col-1 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <a className='text-dark text-hover-primary fs-6'>{data.id_tramite}</a>
                          </div>
                        </td>
                      </div>
                      <div className='col-2 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <a className='text-dark text-hover-primary fs-6'>{data.dni}</a>
                          </div>
                        </td>
                      </div>
                      <div className='col-2 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <a className='text-dark text-hover-primary fs-6'>{data.apellido}</a>
                          </div>
                        </td>
                      </div>
                      <div className='col-2 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <a className='text-dark text-hover-primary fs-6'>{data.nombre}</a>
                          </div>
                        </td>
                      </div>
                      <div className='col-3 altura-tabla'>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            {data.estado === 'validado' ? (
                              <a className='badge badge-light-success'>{data.estado}</a>
                            ) : (
                              <a className='badge badge-light-warning'>{data.estado}</a>
                            )}
                          </div>
                        </td>
                      </div>
                    </div>
                  </tr>
                  <tr>
                    <td style={{paddingBottom: 0, paddingTop: 8}} colSpan={6}>
                      <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box sx={{margin: 1}} className='no-margin-top'>
                          <span className='fw-bolder fs-3 mb-5'>Datos Inscripcion:</span>
                          <div className='mt-5 fv-row'>
                            <div className='row'>
                              <div className='col-lg-4'>
                                <label className='form-label m-0'>
                                  Telefono:&nbsp;&nbsp;{data.telefono}
                                </label>
                              </div>
                              <div className='col-lg-8'>
                                <label className='form-label m-0'>
                                  Empresa:&nbsp;&nbsp;{data.transporte}
                                </label>
                              </div>
                            </div>
                            <hr />
                            <div className='row'>
                              <div className='col-lg-12'>
                                <label className='form-label m-0'>
                                  Domicilio Legal:&nbsp;&nbsp;{data.domicilio}
                                </label>
                              </div>
                            </div>
                            <hr />
                            <div className='row'>
                              <div className='col-lg-12'>
                                <label className='form-label m-0'>
                                  Domicilio Actual:&nbsp;&nbsp;{data.domicilioActual}
                                </label>
                              </div>
                            </div>
                            <hr />
                            <div className='row'>
                              <div className='col-lg-12'>
                                <label className='form-label m-0'>
                                  Correo:&nbsp;&nbsp;{data.correo}
                                </label>
                              </div>
                            </div>
                            <hr />
                            <div className='row'>
                              <div className='col-lg-12 mb-1'>
                                <label className='form-label m-0'>
                                  Fecha Inscripcion:&nbsp;&nbsp;{data.fecha_inscripcion}
                                </label>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </Collapse>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
            {/* End::Table head */}
          </table>
          {/* begin::Table body */}
        </div>
        {/* end::Table container */}
      </div>
      <ModalBens
        data={data}
        show={modalState}
        handleCloseModal={handleCloseModal}
        tituloMenu={tituloMenu}
      />
      {/* begin::Body */}
    </div>
  )
}
