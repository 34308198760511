import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { ISTATE } from '../../../../../setup/redux/interface/reduxState.interface'
import { PageTitle } from '../../../../../_metronic/layout/core'
import HeaderSisAdmin from '../components/HeaderSisAdmin'
import { MenuPrincial } from '../components/MenuPrincipal'
import TablasDatos from '../components/TablasDatos'

let data: any = []

export const SisAdminPage = () => {
  const { url } = useRouteMatch()
  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)
  const { perfil } = dashState
  let menu = perfil.filter((data: any) => '/' + data.paginaInicio === url)

  return (
    <div>
      {/*   <HeaderSisAdmin /> */}
      <PageTitle breadcrumbs={[]}>ADMINISTRACIÓN</PageTitle>
      <div className='row gy-5 gx-xl-12'>
        <div className='col-xxl-3 col-xl-3 col-md-3'>
          <MenuPrincial className='sombras card-xxl-stretch mb-xl-3' mPrincipal={menu[0].menu} />
        </div>
        <div className='col-xl-9 col-md-9'>
          <div className="sombras">
          <TablasDatos className='card-xxl-stretch mb-5 mb-xl-8' tablaDatos={data} />
          </div>
        </div>
      </div>
    </div>
  )
}
