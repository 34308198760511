import axios, {AxiosInstance} from 'axios'
import {remTablaDatos} from '../../app/modules/apps/Sis-admin/redux/TablaDatosAction'
import {logoutDashboard} from '../redux/ReduxActions/DashActions'
import {store} from '../redux/ReduxStore/Store'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import Swal from 'sweetalert2'
import {renewToken} from '../../app/modules/auth/Services/AuthService'
const SERVER_API = `${process.env.REACT_APP_API_URL}/api ` || 'api'

export const request = (): AxiosInstance => {
  const localJwt = sessionStorage.getItem('token')
  let isExpired: any
  const headers: any = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  }

  if (localJwt) {
    const tokenDecode: any = jwt_decode(localJwt)

    isExpired = moment.unix(tokenDecode.exp).diff(moment()) < 1

    if (isExpired) {
      Swal.fire({
        title: 'Su sesión a finalizado.',
        text: 'Para mantener la sesión, debe ingresar nuevamente la contraseña.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Salir',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ingresar la contraseña.',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const {value: password} = await Swal.fire({
            title: 'Ingrese su contraseña',
            input: 'password',
            inputPlaceholder: 'Contraseña',
            inputAttributes: {
              maxlength: '10',
              autocapitalize: 'off',
              autocorrect: 'off',
            },
          })
          if (password) {
            await renewToken(password, tokenDecode.id).then((resp) => {
              if (resp.data.ok) {
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  },
                })
                Toast.fire({
                  icon: 'success',
                  title: 'La sesión fue renovada con exito!',
                })
                headers['Authorization'] = `Bearer ${resp.data.token}`
                sessionStorage.setItem('token', resp.data.token)
              } else {
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  },
                })
                Toast.fire({
                  icon: 'error',
                  title: resp.data.msg,
                })
              }
            })
          }
        } else {
          store.dispatch(logoutDashboard())
          store.dispatch(remTablaDatos())
          sessionStorage.removeItem('token')
        }
      })
    } else {
      headers['Authorization'] = `Bearer ${localJwt}`
    }
  }

  return axios.create({
    baseURL: SERVER_API,
    headers,
    withCredentials: false,
  })
}
