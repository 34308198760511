import * as Yup from 'yup'
import { IdatosTrayectoria } from './IdatosTrayectoria';

export interface IdatosPersonales {
  apellido: string;
  nombre: string;
}

const TIPO_VIVIENDA = [
  "casa",
  "rancho",
  "casilla",
  "departamento",
  "piezaInquilinato",
  "piezaHotel",
  "local",
  "movil",
  "situacionCalle"
];

export const DatosPersonalesSchema = Yup.object().shape({
  dni: Yup.string().required('El dni es requerido'),
  sexo: Yup.string().required('El sexo es requerido'),
  apellido: Yup.string().required('El apellido es requerido'),
  nombre: Yup.string().required('El nombre es requerido'),
});

const tipoColectivoOptions = [
  "hospital",
  "hogar",
  "prision",
  "residencia",
  "otro"
];

const tipoAccesibilidad = [
  "menos300mts",
  "mas300mts"];

const estadoCalles = [
  "pavimento",
  "mejorado",
  "tierra"
];
const grupoConvivientes = [
  "viveSolo",
  "hijo",
  "madre",
  "hermano",
  "otrosFamiliares",
  "conyuge",
  "padre",
  "abuelo",
  "noFamiliar",
  "personasDisca"
];
export const SituacionHabitacionalSchema = Yup.object().shape({
  domicilioReal: Yup.string().required('El domicilio es requerido'),
  codigoPostal: Yup.string().required('El código postal es requerido'),
  telefono: Yup.string().required('El teléfono es requerido'),
  localidad: Yup.string().required('La localidad es requerida'),
  provincia: Yup.string().required('La provincia es requerida'),
  departamento: Yup.string().required('El departamento es requerido'),
  tipoVivienda: Yup.string().required('El tipo de vivienda es requerido'),
  vivienda: Yup.object().when('tipoVivienda', {
    is: 'Particular',
    then: Yup.object().shape({
      tipo: Yup.string().required('El tipo es requerido'),
      infraestructuraBasica: Yup.boolean(),
      adaptada: Yup.boolean(),
      cantidadCuartos: Yup.number(),
      accesibilidad: Yup.object().shape({
        medioTransporte: Yup.string().oneOf(tipoAccesibilidad).required("Debe seleccionar alguna de las opciones"),
        estadoCalles: Yup.string().oneOf(estadoCalles).required("Debe seleccionar alguna de las opciones"),
      }),
      grupoConviviente: Yup.array()
        .of(Yup.string().oneOf(grupoConvivientes)
          .min(1, "Debe seleccionar al menos una opción")
          .required("Debe seleccionar alguna de las opciones")),
      personaConCud: Yup.boolean(),
      cantidadPersonas: Yup.number(),
    })
  }),
  otherwise: Yup.object().shape({
    tipo: Yup.array()
      .of(Yup.string().oneOf(tipoColectivoOptions)
        .min(1, "Debe seleccionar al menos una opción")
        .required("Debe seleccionar alguna de las opciones")),
    especificar: Yup.object().when('tipo', {
      is: 'otro',
      then: Yup.string().required('El tipo de vivienda es requerido'),
    }),
    condExternacion: Yup.boolean(),
  })
});

export const TipoCompletitud = Yup.object().shape({
  completado: Yup.boolean().required(),
  fechaFinalizacion: Yup.date().when('completado', {
    is: true,
    then: Yup.date().required(),
    otherwise: Yup.date(),
  }),
});


interface Trayectoria {
  tipo: string;
  estado: string;
}

interface Trayectorias {
  inicialList?: Trayectoria[];
  primariaList?: Trayectoria[];
  secundariaList?: Trayectoria[];
  educEspecialList?: Trayectoria[];
  superiorList?: Trayectoria[];
}

interface EducacionFormValues {
  nivelEducativo: string;
  concurrio?: boolean;
  concurre?: boolean;
  inicial?: boolean;
  primaria?: boolean;
  secundaria?: boolean;
  superior?: boolean;
  especial?: boolean;
  //trayectoria?: Trayectorias;
  inclusionEducativa: boolean;
  inclusionEducActual: boolean;
}

export const EducacionSchema: Yup.SchemaOf<EducacionFormValues> = Yup.object().shape({
  nivelEducativo: Yup.string().required("El nivel educativo es requerido"),
  concurrio: Yup.boolean(),
  concurre: Yup.boolean(),
  inicial: Yup.boolean().test('inicialValidation', 'Debe seleccionar un tipo válido', function (value) {
    const { inicialList } = this.parent.trayectoria || {}; // Obtener el valor de trayectoria.inicialList
    if (value && (!inicialList || inicialList.length === 0)) {
      return this.createError({ message: 'Debe seleccionar un tipo válido' });
    }
    if (value && inicialList.some((item: IdatosTrayectoria) => !item.tipo || !item.estado)) {
      return this.createError({ message: 'Debe seleccionar un tipo y estado válidos' })
    }
    return true;
  }),
  primaria: Yup.boolean().test('primariaValidation', 'Debe seleccionar un tipo válido', function (value) {
    const { primariaList } = this.parent.trayectoria || {}; // Obtener el valor de trayectoria.primariaList
    if (value && (!primariaList || primariaList.length === 0)) {
      return this.createError({ message: 'Debe seleccionar un tipo válido' });
    }
    if (value && primariaList.some((item: IdatosTrayectoria) => !item.tipo || !item.estado)) {
      return this.createError({ message: 'Debe seleccionar un tipo y estado válidos' })
    }
    return true;
  }),
  secundaria: Yup.boolean().test('secundariaValidation', 'Debe seleccionar un tipo válido', function (value) {
    const { secundariaList } = this.parent.trayectoria || {}; // Obtener el valor de trayectoria.secundariaList
    if (value && (!secundariaList || secundariaList.length === 0)) {
      return this.createError({ message: 'Debe seleccionar un tipo válido' });
    }
    if (value && secundariaList.some((item: IdatosTrayectoria) => !item.tipo || !item.estado)) {
      return this.createError({ message: 'Debe seleccionar un tipo y estado válidos' })
    }
    return true;
  }),
  superior: Yup.boolean().test('superiorValidation', 'Debe seleccionar un tipo válido', function (value) {
    const { superiorList } = this.parent.trayectoria || {}; // Obtener el valor de trayectoria.superiorList
    if (value && (!superiorList || superiorList.length === 0)) {
      return this.createError({ message: 'Debe seleccionar un tipo válido' });
    }
    if (value && superiorList.some((item: IdatosTrayectoria) => !item.tipo || !item.estado)) {
      return this.createError({ message: 'Debe seleccionar un tipo y estado válidos' })
    }
    return true;
  }),
  especial: Yup.boolean().test('especialValidation', 'Debe seleccionar un tipo válido', function (value) {
    const { especialList } = this.parent.trayectoria || {}; // Obtener el valor de trayectoria.especialList
    if (value && (!especialList || especialList.length === 0)) {
      return this.createError({ message: 'Debe seleccionar un tipo válido' });
    }
    if (value && especialList.some((item: IdatosTrayectoria) => !item.tipo || !item.estado)) {
      return this.createError({ message: 'Debe seleccionar un tipo y estado válidos' })
    }
    return true;
  }),
  inclusionEducativa: Yup.boolean().required(),
  inclusionEducActual: Yup.boolean().required(),
});

const condicionOptions = ["trabaja", "noTrabaja", "buscaTrabajo", "noAplica"];
const categoriaOptions = ["empleado", "patron", "cuentaPropia", "familiar", "otro"];
const aportesOptions = ["propios", "empleador", "ninguno"];




export const ActividadLaboralSchema = Yup.object().shape({
  condicion: Yup.string().oneOf(condicionOptions).required("Debe seleccionar alguna de las opciones"),
  categoria: Yup.string().oneOf(categoriaOptions).required("Debe seleccionar alguna de las opciones"),
  otroTrabajo: Yup.string().when('categoria', {
    is: 'otro',
    then: Yup.string().required("Debe especificar que otro trabajo"),
    otherwise: Yup.string(),
  }),
  aportes: Yup.string().oneOf(aportesOptions).required("Debe seleccionar alguna de las opciones"),
});

export const situacionPrevisionalOptions = [
  "jubilado",
  "pensionado",
  "pensionInvalidez",
  "pensionGraciable",
  "pensionUniversal",
  "pensionExCombatientes",
  "pensionMadre",
  "otra",
  "sinBenefecio"
];

export const SituacionPrevisionalSchema = Yup.object().shape({
  situacionPrevisional: Yup.array()
    .of(Yup.string().oneOf(situacionPrevisionalOptions)
      .min(1, "Debe seleccionar al menos una opción")
      .required("Debe seleccionar alguna de las opciones"))
});

const coberturaSaludOptions = [
  "obraSocial",
  "prepaga",
  "pami",
  "publicacion",
  "otra"
];

export const CoberturaSaludSchema = Yup.object({
  coberturaSalud: Yup.array()
    .of(Yup.string().oneOf(coberturaSaludOptions)
      .min(1, "Debe seleccionar al menos una opción")
      .required("Debe seleccionar alguna de las opciones")),
  nombreCobertura: Yup.string().required('El nombre de la cobertura es requerido'),
});



