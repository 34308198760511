/** React */
import React, { useEffect, useState } from 'react'
/** Formik */
import { Field } from 'formik'
/** Material Ui */
import { Autocomplete, Checkbox, TextField, Button } from '@mui/material';
import { Icie10s } from '../../Interfaces/Ilegajo'
import { getCie10 } from '../../services/cud-service'
import makeAnimated from 'react-select/animated'
import { createFilter } from 'react-select'
import Select from 'react-select'

type Props = {
  handleChange: any
  handleBlur: any
  btnEdit?: any
  values?: any
}

export const Patologias: React.FC<Props> = ({ handleChange, handleBlur, btnEdit, values }) => {

  const [dataCie10, setDataCie10] = useState<Icie10s>([])
  const [datosCie10, setDatosCie10] = useState<Icie10s>([])
  const animatedComponents = makeAnimated()
  const MIN_INPUT_LENGTH = 3


  const filterOption = (candidate: any, input: any) => {
    return (
      // Min input length
      input.length >= MIN_INPUT_LENGTH &&
      // Use Select's default filtering for string matching by creating filter
      createFilter({})(candidate, input)
    )
  }

  const noOptionsMessage = (input: any) =>
    input.length >= MIN_INPUT_LENGTH
      ? 'No se encuentran resultados.'
      : 'Ingrese por lo menos 3 caracteres.'

  const muestra = async () => {
    const res: any = await getCie10()
    setDataCie10(res.data.cie10)
  }

  const eliminardata = (() => {

    setDatosCie10([]);
    values.datosCud.cie10 = []

  })

  useEffect(() => {
    muestra()
    setDatosCie10(values.cie10)
  }, [])

  return (
    <>
      <div className='row alineacion_inputs'>
        <div className='col-lg-4'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='problemas_origenes.motor'
            name='problemas_origenes.motor'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur} />
          <label className=''>Motor</label>
        </div>
        <div className='col-lg-4'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='problemas_origenes.mental'
            name='problemas_origenes.mental'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur} />
          <label className=''>Mental</label>
        </div>
        <div className='col-lg-4'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='problemas_origenes.auditivo'
            name='problemas_origenes.auditivo'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Auditivo</label>
        </div>
      </div>
      <div className='row alineacion_inputs'>
        <div className='col-lg-4'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='problemas_origenes.visual'
            name='problemas_origenes.visual'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Visual</label>
        </div>
        <div className='col-lg-4'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='problemas_origenes.visceral'
            name='problemas_origenes.visceral'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Visceral</label>
        </div>
        <div className='col-lg-4'>
          <Field
            type='checkbox'
            as={Checkbox}
            id='problemas_origenes.intelectual'
            name='problemas_origenes.intelectual'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Intelectual</label>
        </div>
      </div>
      <br></br>
      <br></br>
      <div>

        <div className='col-8 text-gray-600 fw-bold mt-2 fs-6'>
          Clasificación Internacional de Enfermedades 10º
        </div>
        <div className='card bg-ligth border mb-6 card_align5 '>
          <div className='row alineacion_inputs'>
            <div className='col-lg-12'>

              {datosCie10?.map((datos: any) => {
                return (<span><strong>{datos}</strong> &nbsp;&nbsp;&nbsp;</span>)
              })}
            </div>
            <div className='col-lg-12'>

              <br></br>
              <Select
                className='form-label form-control-solid'
                isMulti
                id='cie10'
                name='cie10'
                options={dataCie10}
                openMenuOnClick={false}
                components={animatedComponents}
                closeMenuOnSelect={true}
                placeholder={'Agregar Clasificación Internacional de Enfermedad 10º'}
                filterOption={filterOption}
                noOptionsMessage={noOptionsMessage}
                isDisabled={!btnEdit}

                onChange={(e: any) => {
                  let guardar_cie: any = []
                  guardar_cie = datosCie10
                  for (let tem of e) {
                    guardar_cie.push(tem.label)

                    values.cie10 = guardar_cie
                  }
                }}
              ></Select>
            </div>
            <div  className='col-lg-2'>
              <Button
              disabled={!btnEdit} 
                className="btn btn-danger btn-sm" onClick={eliminardata}>x</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
