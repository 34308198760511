import Swal from 'sweetalert2'
import {ModDocPeridoEmpleado, PutDocPeridoEmpleado} from '../../services/AdminEmpleados'
import {addCertPyme, postCertCpyme} from '../../services/EJ.Empleado.Services'
import moment from 'moment'
import {error} from 'console'
import {icon} from '@fortawesome/fontawesome-svg-core'
import { modEstadoArchivo } from '../../services/EJ.Services'

export const opcRechazo = async (doc: any) => {
  let value: any = await Swal.fire({
    input: 'textarea',
    inputLabel: 'Mensaje para el empleador.',
    inputPlaceholder: 'Escriba su argumentación en este espacio.',
    inputAttributes: {
      'aria-label': 'Escriba su mensaje aquí.',
    },
    confirmButtonText: 'Enviar',
    cancelButtonText: 'Cerrar',
    showCancelButton: true,
    width: '600px',
  })
  if (value.value === '') {
    await Swal.fire({toast: true, text: `Es necesario completar este formulario.`, timer: 2000})
    opcRechazo(doc)
  } else {
    ModDocPeridoEmpleado(
      doc.documento,
      'rechazado',
      doc.id_db_fileEj,
      doc.anio,
      doc.mes,
      value.value
    ).then(async (res: any) => {
      await Swal.fire('Documento rechazado', 'El dato fue correctamente modificado.', 'warning')
    })
  }
}

export const selectOpBtn = (doc: any) => {
  Swal.fire({
    title: 'Seleccione una opción',
    input: 'select',
    inputOptions: {
      aprobado: 'Aprobado',
      rechazado: 'Rechazado',
    },
    inputPlaceholder: 'Seleccione una opción',
    confirmButtonText: 'Cambiar',
    cancelButtonText: 'Cerrar',
    showCancelButton: true,
    inputValidator: (value) => {
      return new Promise((resolve) => {
        if (value === 'rechazado') {
          opcRechazo(doc)
          console.log(doc);

          //TODO: recordar cambiar este hardcodeado
          modEstadoArchivo(doc.cuit,'ratificar', doc).then((response) => {
            console.log(response.data);
          });
          console.log("Se rechaza la doc");
          resolve('')
        }
        if (value === 'aprobado') {
          formValuesPeriodo(doc)
        } else {
          resolve('Es necesario seleccionar una opción')
        }
      })
    },
  })
}

const reenviarFile = async (doc?: any, formularioValores?: any) => {
  const {value: formValues} = await Swal.fire({
    title: 'Seleccione los certificados de aprobación.',
    html:
      '<input type="file" accept=".pdf" id="pdfCert1Upload" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">' +
      '<br/>' +
      ' <input type="file" accept=".pdf" id="pdfCert2Upload" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">',

    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cerrar',
    showCancelButton: true,
    width: '600px',
    preConfirm: async (e: any) => {
      if (
        (document.getElementById('pdfCert1Upload') as HTMLInputElement)!.value === '' &&
        (document.getElementById('pdfCert2Upload') as HTMLInputElement)!.value === ''
      ) {
        await Swal.fire({
          toast: true,
          text: `Es necesario seleccionar un archivo.`,
          timer: 2000,
          showConfirmButton: false,
        })
        reenviarFile()
        return ''
      } else {
        let dataCert = []

        dataCert.push((document.getElementById('pdfCert1Upload') as HTMLInputElement)!.files)
        dataCert.push((document.getElementById('pdfCert2Upload') as HTMLInputElement)!.files)
        return dataCert
      }
    },
  })

  if (formValues) {
    const documentos: any = []
    for (let dataFile of formValues) {
      // @ts-ignore: Object is possibly 'null'.
      if (dataFile[0]!.size > 2000000) {
        await Swal.fire({
          icon: 'info',
          // @ts-ignore: Object is possibly 'null'.
          title: `El archivo ${dataFile[0]!.name}, supera los 2 MB, permitidos. `,
          timer: 3000,
        })
        reenviarFile()
      } else {
        //console.log(doc, formularioValores, dataFile)
        let tipo_doc = dataFile![0].name.split('.') || ''
        await postCertCpyme(
          // @ts-ignore: Object is possibly 'null'.
          dataFile[0],
          tipo_doc[0],
          doc.cuit
        )
          .then(async (res: any) => {
            if (res.data.ok) {
              for (let doc of res.data.fileSaves) {
                let fileName = Object.keys(doc)[0]
                documentos.push({
                  tipo_doc: fileName,
                  id_db_fileEj: doc[fileName],
                  estado: 'aprobado_Cpyme',
                  fecha_carga: moment().format('DD/MM/YYYY'),
                  fecha_modf: '',
                })
              }
            }
          })
          .catch(async (error) => {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error,
            })
          })
      }
    }
    addCertPyme(doc.cuit, doc.anio, doc.mes, formularioValores, documentos)
      .then(async (res: any) => {
        if (res.data.ok) {
          await Swal.fire(
            'Documento Modificado',
            'El certificado fue cargado correctamente, la empresa ya puede descargar el certificado.',
            'warning'
          )
        }
      })
      .catch(async (error) => {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
        })
      })
  }
}

const formValuesPeriodo = async (doc?: any) => {
  const {value: formValues} = await Swal.fire({
    width: '450px',
    title: 'Valores correspondientes al mes aprobado.',
    html:
      '<label for="lname">Importe correspondiente al mes</label><br>' +
      '<input type="number" placeholder="Importe correspondiente al periodo" id="importe"  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">' +
      '</br>' +
      '<label for="lname">Seleccione la fecha de emisión</label><br>' +
      '<input type="date"  id="fecha_de_ap" placeholder="Fecha de aprobación" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">' +
      '</br>' +
      '<label for="lname">Ingrese el Nro de resolución </label><br>' +
      '<input  autocomplete="on" id="nroCert" placeholder="Nro.Resolución" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">',

    focusConfirm: false,
    preConfirm: () => {
      if (
        (document.getElementById('importe') as HTMLInputElement).value === '' ||
        (document.getElementById('fecha_de_ap') as HTMLInputElement).value === '' ||
        (document.getElementById('nroCert') as HTMLInputElement).value === ''
      ) {
        Swal.fire('Es necesario completar los campos para poder continuar.')
      } else {
        return [
          (document.getElementById('importe') as HTMLInputElement).value,
          (document.getElementById('fecha_de_ap') as HTMLInputElement).value,
          (document.getElementById('nroCert') as HTMLInputElement).value,
        ]
      }
    },
  })
  if (formValues) {
    console.log(formValues)
    reenviarFile(doc, formValues)
  } else {
    Swal.fire('Es necesario completar los campos para poder continuar.')
  }
}
