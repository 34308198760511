/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Footer } from '../../../../_metronic/layout/components/Footer'
import { ForgotPassword } from './ForgotPassword'
import { Login } from './Login'
import { ResetPaswoord } from './ResetPaswoord'

export function WrapperAuth(props: any) {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    >

      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>

        <a className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/apex.png')} className='h-65px' />
        </a>

        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            {props.children}
                <a className='mb-12 mt-8'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/marcablanca.png')} className='h-60px' />
        </a>
        <Footer />

      </div>

    </div>
  </div>
  )
}
