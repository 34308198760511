import { FC } from 'react'
import { FiltrosToggleDrawer } from './Filtros-Drawer/FiltrosToggleDrawer'


import { PDFDrawer } from './PDF-drawer/PDFDrawer'
import { FiltrosDrawer } from './Filtros-Drawer/FiltrosDrawer'
import { PDFToggleDrawer } from './PDF-drawer/PDFToggleDrawer'

const RightToolbar: FC = () => {
  return (
    <>
      <div className='engage-toolbar d-flex position-fixed px-5 fw-bolder zindex-2 top-50 end-0 transform-90 mt-20 gap-2'>
        <FiltrosToggleDrawer />
        <PDFToggleDrawer />

      </div>

      <FiltrosDrawer />
      <PDFDrawer />
    </>
  )
}

export { RightToolbar }
