import { calTypes } from "../types/types"

export const cargarSelAreas = (seleccion: any) => ({
  type: calTypes.cargarSelAreas,
  payload: {
    seleccion,
  },
})
export const seleccionMenu = (areaId: any) => ({
  type: calTypes.seleccionMenu,
  payload: {
    areaId,
  },
})