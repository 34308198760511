import {Ilegajo} from './Ilegajo'
import {Ijunta} from './Ijunta'
import moment from 'moment'

const initJunta: Ijunta = {
  nombre: '',
  domicilio: '',
  localidad: '',
  principal: false,
  itinerante: false,
  activo: false,
}
const initLegajo: Ilegajo = {
  legajo: '',
  dni: '',
  datosPersonales: {
    tipoDoc: '',
    dni: '',
    cuit: '',
    apellido: '',
    nombre: '',
    domicilio: '',
    codigoPostal: '',
    localidad: '',
    departamento: '',
    tipoResidencia: '',
    fechaVencimientoResidencia: '',
    telefono: '',
    mail: '',
    sexo: '',
    estadoCivil: '',
    fechaNacimiento: moment().format('DD-MM-YYYY'),
    nacionalidad: '',
    tipoNacionalidad: '',
    vehiculoLey: '',
    fechaVehiculo: '',
    simboloInternacional: '',
    derechoAsignacionesF: '',
    menor: false,
    empleadoPublico: false,
    hasATutor: false,
  },
  fecha: moment().format('DD-MM-YYYY'),
  datosTutor: {
    dni: '',
    tipoDoc: '',
    apellido: '',
    nombre: '',
    sexo: '',
    fechaNacimiento: moment().format('DD-MM-YYYY'),
    localidad: '',
    domicilio: '',
    codigoPostal: '',
    nacionalidad: '',
    telefono: '',
    mail: '', //
    parentezco: '',
    tipoTutor: '',
    fechaDesignacionTutor: '',
    juzgado: '',
    secretaria: '',
    dptoJudicial: '',
    fiscalia: '',
    defensoria: '',
    interesadoApoyoJudicial: '',
    interesadoApoyoExtraJudicial: '',
    curadorDesignadoJudicial: '',
  },
  datosCud: [
    {
      estado: '',
      fechaInicio: '',
      fechaCierre: '',
      observacion: '',
      infoCertificado: {
        nroCud: '',
        fechaVencimiento: '',
        fechaEntregaCert: '',
        estadoCertificado: '',
      },
      problemas_origenes: {
        // (DONE)
        motor: false,
        mental: false,
        auditivo: false,
        visual: false,
        visceral: false,
        intelectual: false,
      },
      cie10: [''],
    },
  ],
  observaciones: '',
  documentacion: {
    cudAnterior: false,
    certificadoMedico: false,
    estudiosComplem: false,
    partidaNac: false,
    extravioCud: false,
    historiaClinica: false,
    copiaDni: false,
    supervivencia: false,
    informes: false,
    planillaMedica: false,
    copiaOSocial: false,
    reciboPension: false,
    curatela: false,
  },
  problemas_origenes: {
    motor: false,
    mental: false,
    auditivo: false,
    visual: false,
    visceral: false,
    intelectual: false,
  },

  estado: '',
  junta: '',
  usuarioCreacion: '',
  fechaCreacion: '',
  usuarioModificacion: '',
  fechaModificacion: '',
}

const initCud: any = {
  estado: '',
  fechaInicio: '',
  fechaCierre: '',
  observacion: '',
  infoCertificado: {
    nroCud: '', // Input para carga de nro de cud
    fechaVencimiento: '', // Input para carga de fecha de caducidad del certificado
    fechaEntregaCert: '',
    estadoCertificado: '',
  },
  problemas_origenes: {
    // (DONE)
    motor: false,
    mental: false,
    auditivo: false,
    visual: false,
    visceral: false,
    intelectual: false,
  },
  cie10: [],
}
export {initLegajo, initJunta, initCud}
