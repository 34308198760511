/** React */
import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {useSelector} from 'react-redux'
import {ISTATE} from '../../../../../setup/redux/interface/reduxState.interface'
/** Formik */
import {ErrorMessage, Field, Form, Formik} from 'formik'
import FormikControl from '../../../../components/Formik/FormikControl'
/** Material & Sass */
import {Button} from '@mui/material'
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp'
import '../../../../../../src/_metronic/assets/sass/cud.scss'
/** Services */
import {getJuntas, postLegajo} from '../services/cud-service'
import {CreateModal} from '../hooks/useModal'
/** Interfaces */
import {Ijuntas, Ijunta} from '../Interfaces/index'
import {validateSchemaDatosPersonales} from '../Interfaces/Ilegajo'
/** Tables */
import {DatosPersonales} from '../components/Tablas/index'
import moment from 'moment'
import {validateSchema} from '../Interfaces/Ijunta'

export const Legajos = () => {
  const dashState: any = useSelector((state: ISTATE) => state.auth)
  const location: any = useLocation()
  const [juntas, setJuntas] = useState<Ijuntas>([])
  const [btnEdit, setBtnEdit] = useState(false)

  const handleBtn = () => {
    setBtnEdit(!btnEdit)
  }

  useEffect(() => {
    getJuntas().then((res: any) => {
      setJuntas(res.data.juntas)
    })
    return () => {}
  }, [])

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <Link to={'/cud/home'} className='alineacionb'>
          <ArrowBackSharpIcon />
        </Link>
      </div>
      <div className='card-body py-1'>
        <div className='table-responsive'>
          <Formik
            initialValues={location.state}
            validationSchema={validateSchemaDatosPersonales}
            onSubmit={(values, {resetForm}) => {
              values.usuarioCreacion = dashState.usuario.nombreCompleto
              values.fechaCreacion = moment().format('DD/MM/YYYY')
              postLegajo(values).then((res: any) => {
                resetForm()
                CreateModal()
                window.history.back()
              })
            }}
          >
            {({handleChange, handleBlur, values}) => {
              return (
                <div>
                  <Form>
                    <div className='col-8 text-gray-600 fw-bold mt-2 fs-6'>Datos Personales</div>
                    <div className='card bg-ligth border mb-6 card_align5'>
                      <br />
                      <DatosPersonales
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        btnEdit={btnEdit}
                      />
                      <div className='row alineacion_inputs'>
                        <div className='col-lg-4'>
                          <br></br>
                          <label className='form-label fw-bolder text-dark fs-6'>Junta</label>
                          <Field
                            as='select'
                            id='junta'
                            name='junta'
                            className='form-control form-control-solid capital'
                            disabled={!btnEdit}
                          >
                            <option value='Seleccione'>Seleccione</option>
                            {juntas.map((value: Ijunta, index) => {
                              return (
                                <option key={index} value={value.nombre}>
                                  {value.nombre}
                                </option>
                              )
                            })}
                          </Field>
                        </div>
                        <div className='col-lg-7'>
                          <br></br>
                          <FormikControl
                            control='input'
                            type='text'
                            id='observaciones'
                            name='observaciones'
                            label='Observaciones'
                            onChange={handleChange}
                            disabled={!btnEdit}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <br />
                    </div>
                    <br /> <br />
                    <div className='row card_align5'>
                      <div className='col-lg-8'>
                        <Button className='btn btn-primary btn-sm' onClick={handleBtn}>
                          {btnEdit === false ? 'Habilitar Edición' : 'Deshabilitar Edición'}
                        </Button>
                        &nbsp;&nbsp;
                        {btnEdit === true ? (
                          <Button type='submit' className='btn btn-success btn-sm' disabled={false}>
                            Guardar
                          </Button>
                        ) : (
                          ''
                        )}
                      </div>{' '}
                    </div>
                  </Form>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}
