import React from 'react'

import HeaderOrgnismo from '../components/Organismos/OrganimosSettings/HeaderOrgnismo'
import { useLocation } from 'react-router'
import DependenciasTable from '../components/Organismos/OrganimosSettings/AreasTable'

const OrganimosSettings = (props: any) => {
  const params: any = useLocation()
  // ////console.log(params)
  return (
    <>
      <div>
        <HeaderOrgnismo org={params.state} />
      </div>
      <div className='col-xl-12 col-md-12'>
        <DependenciasTable
          className='card mb-5 mb-xl-8'
          tablaDatos={params.state.areasOrg}
          _idOrg={params.state._id}
        />
      </div>
    </>
  )
}

export default OrganimosSettings
