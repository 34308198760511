import { Form, Formik, FormikValues } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components/_StepperComponent'
import { KTSVG } from '../../../../../_metronic/helpers'
import { inits, validOrgSchema } from '../components/Organismos/StepsOrganismos/CreateOrganismoHelper'
import { Step1Org } from '../components/Organismos/StepsOrganismos/Step1Org'
import { Step2Org } from '../components/Organismos/StepsOrganismos/Step2Org'
import { Step3Org } from '../components/Organismos/StepsOrganismos/Step3Org'

import { ICreateOrg } from '../interfaces/ICreateOrg'
import { postOrg } from '../services/OrganizacionService'

const StepHorizontalOrganismos = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const [currentSchema, setCurrentSchema] = useState(validOrgSchema[0])
  const [initValues, setInitValues] = useState<ICreateOrg>(inits)
  const [volver, setVolver] = useState(true)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  const prevStep = () => {
    stepper.current?.currentStepIndex === 1 && setInitValues(inits)
    setVolver(true)
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(validOrgSchema[stepper.current.currentStepIndex - 1])
  }
  const submitStep = async (values: ICreateOrg, actions: FormikValues) => {
    // ////console.log('finalizar FORMMMM', stepper.current)
    if (!stepper.current) {
      return
    }
    setCurrentSchema(validOrgSchema[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      await postOrg(values).then((res: any) => {
        if (res.data.ok) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'El formulario, fue cargado con exito',
            showConfirmButton: false,
            timer: 3500,
          })
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'Verificar los datos ingresados',
            icon: 'error',
            confirmButtonText: 'Volver',
          })
        }
      })
      stepper.current.goto(1)
      actions.resetForm()
    }
  }
  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])
  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Datos del Ministerio</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Datos Fiscales </h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Finalizar</h3>
            </div>

            {/* <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Finalizar</h3>
            </div> */}
          </div>

          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {(formik) => {
              // ////console.log(formik)
              return (
                <Form
                  className='mx-auto mw-800px w-100 pt-10 pb-10'
                  noValidate
                  id='kt_create_account_form'
                >
                  <div className='current' data-kt-stepper-element='content'>
                    <Step1Org />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step2Org />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step3Org />
                  </div>

                  {/*  <div data-kt-stepper-element='content'>
                    <Step4Org />
                  </div> */}

                  <div className='d-flex flex-stack pt-15'>
                    {volver ? (
                      <div className='mr-2'>
                        <Link to={'servicios'}>
                          <button type='button' className='btn btn-lg btn-light-primary me-3'>
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr063.svg'
                              className='svg-icon-4 me-1'
                            />
                            Volver
                          </button>
                        </Link>
                      </div>
                    ) : (
                      <div className='mr-2'>
                        <button
                          onClick={prevStep}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr063.svg'
                            className='svg-icon-4 me-1'
                          />
                          Volver
                        </button>
                      </div>
                    )}

                    <div>
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary me-3'
                        onClick={() => {
                          setVolver(false)
                        }}
                      >
                        <span className='indicator-label'>
                          {stepper.current?.currentStepIndex !==
                            stepper.current?.totatStepsNumber! - 1 && 'Siguiente'}
                          {stepper.current?.currentStepIndex ===
                            stepper.current?.totatStepsNumber! - 1 && 'Guardar'}
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default StepHorizontalOrganismos
