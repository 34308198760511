/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'

const Step4: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Datos de contacto</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Es obligatorio completar los siguientes campos.
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='row fv-row'>
            <div className='col-6'>
              <label className='required fs-6 fw-bold form-label mb-2'>Correo electrónico</label>
              <Field
                type='email'
                name='datosContacto.email'
                className='form-control form-control-solid'
              />
              <div className='fv-plugins-message-container invalid-feedback'>
                <ErrorMessage name='datosContacto.email' />
              </div>
            </div>
            <div className='col-6'>
              <label className='required fs-6 fw-bold form-label mb-2'>Teléfono</label>
              <Field
                type='text'
                name='datosContacto.telefono'
                className='form-control form-control-solid'
              />

              <div className='fv-plugins-message-container invalid-feedback'>
                <ErrorMessage name='datosContacto.telefono' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step4}
