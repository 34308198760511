import * as Yup from 'yup'
import {ICreateUsr} from '../../../interfaces/ICreateUsr'
import {getPersonaPadron} from '../../../services/padronService'
import {getDatosEmpleados, getPersona} from '../../../services/PersonaService'
import {imgAvatar} from './imgUs'

const validationSchema = Yup.object({
  usuario: Yup.string().required('El número de DNI, es necesario').label('usuario').min(7).max(8),
  datosPersonales: Yup.object({
    sexo: Yup.string().required('El genero es necesario'),
  }),
  /* datosContacto: Yup.object({
    email: Yup.string().email().required(),
    telefono: Yup.string().required(),
  }), */
})
const ValidationDC = Yup.object({
  datosContacto: Yup.object({
    email: Yup.string().email().required(),
    telefono: Yup.string().required(),
  }),
})

const inits: ICreateUsr = {
  usuario: '',
  password: '',
  errPassword: {
    intentos: 0,
    fecha_err: '',
  },
  role: '',
  activo: true,
  fechaAltaUsuario: '',
  fechaBajaUsuario: '',
  lastLogin: '',
  datosPersonales: {
    nombres: '',
    apellido: '',
    dni: '',
    cuil: '',
    ciudad: '',
    provincia: '',
    fechaNacimiento: '',
    sexo: '',
    foto: '',
    ID_TRAMITE_PRINCIPAL: '',
  },
  datosContacto: {email: '', telefono: ''},
  datosLaborales: {
    servicioPuestoPrincipal: '',
    legajo: '',
    fechaAlta: '',
    tipoRelacionLaboral: '',
    datosOrganismo: {
      nombre: '',
      nombreMinistro: '',
      datosSistemas: {
        logo: '',
        colorLetra: '',
        colorMinisterioA: '',
        colorMinisterioB: '',
      },
      areaPrincipal: {
        idAreaP: '',
        nombreArea: '',
        categoriaArea: '',
        tipoArea: '',
        detalleSistema: {
          icono: '',
          nombreCorto: '',
          colorLetra: '',
          colorFondo: '',
          menusAct: false,
        },
      },
      areaPropia: {
        nombreArea: '',
        idAreaPro: '',
        titularArea: '',
        tipoArea: '',
        categoriaArea: '',
        detalleSistema: {
          icono: '',
          nombreCorto: '',
          colorLetra: '',
          colorFondo: '',
          menusAct: false,
        },
      },
    },
  },
  tipoUs: {
    appMovil: false,
    appWeb: true,
  },
}

const crearPas = (nroDoc: string) => {
  let dni = nroDoc.slice(0, 5) //primeros 5 numeros
  let doc = parseInt(dni) //convierto a number
  let psw = `${doc * 4}NaJc`

  return psw
}
const wsPersona = async (element: any = {}) => {
  return getPersona(element.usuario, element.datosPersonales.sexo)
    .then(async (res: any) => {
      if (res.data.status === 'success') {
        let dataRenaper: ICreateUsr = {
          usuario: element.usuario,
          errPassword: {
            intentos: 0,
            fecha_err: '',
          },
          password: '',
          role: '',
          activo: true,
          fechaAltaUsuario: '',
          fechaBajaUsuario: '',
          lastLogin: '',
          datosPersonales: {
            nombres: res.data.persona.nombres,
            apellido: res.data.persona.apellido,
            foto: imgAvatar, //res.data.persona.foto,
            sexo: res.data.persona.sexo,
            fechaNacimiento: res.data.persona.fecha_nacimiento.slice(0, 10),
            dni: element.usuario,
            cuil: res.data.persona.cuil,
            ciudad: res.data.persona.ciudad,
            provincia: res.data.persona.provincia,
            ID_TRAMITE_PRINCIPAL: res.data.persona.id_tramite_principal,
          },
          datosContacto: {email: '', telefono: ''},
          datosLaborales: {
            servicioPuestoPrincipal: '',
            legajo: '',
            fechaAlta: '',
            tipoRelacionLaboral: '',
            datosOrganismo: {
              nombre: '',
              nombreMinistro: '',
              datosSistemas: {
                colorLetra: '',
                colorMinisterioA: '',
                colorMinisterioB: '',
              },
              areaPrincipal: {
                idAreaP: '',
                nombreArea: '',
                categoriaArea: '',
                tipoArea: '',
                detalleSistema: {
                  nombreCorto: '',
                  colorLetra: '',
                  colorFondo: '',
                  menusAct: false,
                },
              },
              areaPropia: {
                nombreArea: '',
                idAreaPro: '',
                titularArea: '',
                tipoArea: '',
                categoriaArea: '',
                detalleSistema: {
                  nombreCorto: '',
                  colorLetra: '',
                  colorFondo: '',
                  menusAct: false,
                },
              },
            },
          },
          tipoUs: {
            appMovil: false,
            appWeb: true,
          },
        }
        return getDatosEmpleados(element.usuario, element.datosPersonales.sexo).then((res: any) => {
          if (res.data?.legajo !== '000000') {
            dataRenaper.datosLaborales!.servicioPuestoPrincipal = res.data.servicioPuestoPrincipal
            dataRenaper.datosLaborales!.legajo = res.data.legajo
            dataRenaper.datosLaborales!.fechaAlta = res.data.fechaAlta
            dataRenaper.datosLaborales!.tipoRelacionLaboral = res.data.tipoRelacionLaboral
            dataRenaper.password = crearPas(element.usuario)

            return dataRenaper
          } else {
            dataRenaper.password = crearPas(element.usuario)
            return dataRenaper
          }
        })
      }
      if (res.data.status === 'error') {
        return getPersonaPadron(element.usuario).then(async (res: any) => {
          if (res.data.ok) {
            let dataRenaper: ICreateUsr = {
              usuario: element.usuario,
              password: '',
              errPassword: {
                intentos: 0,
                fecha_err: '',
              },
              role: '',
              activo: true,
              fechaAltaUsuario: '',
              fechaBajaUsuario: '',
              lastLogin: '',
              datosPersonales: {
                nombres: res.data.data.nombre,
                apellido: res.data.data.apellido,
                foto: imgAvatar,
                sexo: res.data.data.genero,
                fechaNacimiento: res.data.data.clase,
                dni: element.usuario,
                cuil: '',
                ciudad: res.data.data.localidad,
                provincia: 'neuquen',
                ID_TRAMITE_PRINCIPAL: '',
              },
              datosContacto: {email: '', telefono: ''},
              datosLaborales: {
                servicioPuestoPrincipal: '',
                legajo: '',
                fechaAlta: '',
                tipoRelacionLaboral: '',
                datosOrganismo: {
                  nombre: '',
                  nombreMinistro: '',
                  datosSistemas: {
                    colorLetra: '',
                    colorMinisterioA: '',
                    colorMinisterioB: '',
                  },
                  areaPrincipal: {
                    idAreaP: '',
                    nombreArea: '',
                    categoriaArea: '',
                    tipoArea: '',
                    detalleSistema: {
                      nombreCorto: '',
                      colorLetra: '',
                      colorFondo: '',
                      menusAct: false,
                    },
                  },
                  areaPropia: {
                    nombreArea: '',
                    idAreaPro: '',
                    titularArea: '',
                    tipoArea: '',
                    categoriaArea: '',
                    detalleSistema: {
                      nombreCorto: '',
                      colorLetra: '',
                      colorFondo: '',
                      menusAct: false,
                    },
                  },
                },
              },
              tipoUs: {
                appMovil: false,
                appWeb: true,
              },
            }
            return getDatosEmpleados(element.usuario, element.datosPersonales.sexo).then(
              (res: any) => {
                if (res.data?.legajo !== '000000') {
                  dataRenaper.datosLaborales!.servicioPuestoPrincipal =
                    res.data.servicioPuestoPrincipal
                  dataRenaper.datosLaborales!.legajo = res.data.legajo
                  dataRenaper.datosLaborales!.fechaAlta = res.data.fechaAlta
                  dataRenaper.datosLaborales!.tipoRelacionLaboral = res.data.tipoRelacionLaboral
                  dataRenaper.password = crearPas(element.usuario)
                  return dataRenaper
                } else {
                  dataRenaper.password = crearPas(element.usuario)
                  return dataRenaper
                }
              }
            )
          } else {
            return 'Verificar los datos ingresados'
          }
        })
      } else {
        return 'Verificar los datos ingresados'
      }
    })
    .catch((res: any) => {
      return getPersonaPadron(element.usuario).then(async (res: any) => {
        if (res.data.ok) {
          let dataRenaper: ICreateUsr = {
            usuario: element.usuario,
            password: '',
            errPassword: {
              intentos: 0,
              fecha_err: '',
            },
            role: '',
            activo: true,
            fechaAltaUsuario: '',
            fechaBajaUsuario: '',
            lastLogin: '',
            datosPersonales: {
              nombres: res.data.data.nombre,
              apellido: res.data.data.apellido,
              foto: imgAvatar,
              sexo: res.data.data.genero,
              fechaNacimiento: res.data.data.clase,
              dni: element.usuario,
              cuil: '',
              ciudad: res.data.data.localidad,
              provincia: 'neuquen',
              ID_TRAMITE_PRINCIPAL: '',
            },
            datosContacto: {email: '', telefono: ''},
            datosLaborales: {
              servicioPuestoPrincipal: '',
              legajo: '',
              fechaAlta: '',
              tipoRelacionLaboral: '',
              datosOrganismo: {
                nombre: '',
                nombreMinistro: '',
                datosSistemas: {
                  colorLetra: '',
                  colorMinisterioA: '',
                  colorMinisterioB: '',
                },
                areaPrincipal: {
                  idAreaP: '',
                  nombreArea: '',
                  categoriaArea: '',
                  tipoArea: '',
                  detalleSistema: {
                    nombreCorto: '',
                    colorLetra: '',
                    colorFondo: '',
                    menusAct: false,
                  },
                },
                areaPropia: {
                  nombreArea: '',
                  idAreaPro: '',
                  titularArea: '',
                  tipoArea: '',
                  categoriaArea: '',
                  detalleSistema: {
                    nombreCorto: '',
                    colorLetra: '',
                    colorFondo: '',
                    menusAct: false,
                  },
                },
              },
            },
            tipoUs: {
              appMovil: false,
              appWeb: true,
            },
          }
          return getDatosEmpleados(element.usuario, element.datosPersonales.sexo).then(
            (res: any) => {
              if (res.data?.legajo !== '000000') {
                dataRenaper.datosLaborales!.servicioPuestoPrincipal =
                  res.data.servicioPuestoPrincipal
                dataRenaper.datosLaborales!.legajo = res.data.legajo
                dataRenaper.datosLaborales!.fechaAlta = res.data.fechaAlta
                dataRenaper.datosLaborales!.tipoRelacionLaboral = res.data.tipoRelacionLaboral
                dataRenaper.password = crearPas(element.usuario)
                return dataRenaper
              } else {
                dataRenaper.password = crearPas(element.usuario)
                return dataRenaper
              }
            }
          )
        } else {
          return 'Verificar los datos ingresados'
        }
      })
    })
}

export {validationSchema, inits, ValidationDC, wsPersona}
