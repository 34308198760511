import React, { useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { ExploreMainFiltros } from '../components/explore/ExploreMain'
import { RightToolbar } from '../components/explore/RightToolbar'
import HeaderListaEventos from '../components/Headers/HeaderLisEventos'
import TablaEventosMat from '../components/TablaEventos'
type Props = {
  className: string
}
let tablaInicio: Props = {
  className: 'card mb-5 mb-xl-8',
}
const ListaEventosPage: React.FC<Props> = ({ className }) => {
  return (

    <div className={`card sombras`} id='AgendaCompleta'>
      <PageTitle breadcrumbs={[]}>Agenda</PageTitle>
      <HeaderListaEventos />
      <RightToolbar />
      <TablaEventosMat dependencia={''} datos={''} />
    </div>
  )
}

export default ListaEventosPage


