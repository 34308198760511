import { IdatosTrayectoria } from '../../../../Interfaces/IdatosTrayectoria';
import { FieldArray, FormikValues, useFormikContext } from 'formik';
import FormikControl from '../../../../../../../components/Formik/FormikControl';
import { KTSVG } from '../../../../../../../../_metronic/helpers';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useEffect } from 'react';
import TextErrorFormik from '../../../Formik/TextErrorFormik';


type Props = {
    name: string;
}

const SELECT_ESCCOM_OPTIONS = [
    { nombre: "Completo" },
    { nombre: "Incompleto" },
];

const SELECT_TREDUC_OPTIONS = [
    { nombre: "Escuela Común" },
    { nombre: "Escuela Especial" },
    { nombre: "Escuela Domiciliaria" },
    { nombre: "Escuela Rural" },
    { nombre: "Otro" },
];

const INIT_STATE: IdatosTrayectoria = {
    tipo: '',
    estado: ''
}

const disabledStyle = {
    border: 'none',
    background: '0',
    marginTop: '20px',
    cursor: 'pointer',

};


const TrayectoriaEducativa: React.FC<Props> = ({ name }) => {
    const formik = useFormikContext<FormikValues>();
    const changeCheckbox = (event: any) => {
        console.log(event.target);
        if (!event.target.checked && formik.values.trayectoria[`${name}List`].length > 0) {
            formik.setFieldValue(`trayectoria.${name}List`, []);
        }
        if (event.target.checked) {
            formik.setFieldValue(`trayectoria.${name}List`, [{ tipo: "", estado: "" }])
        }

        formik.handleChange(event);

    }
    return (
        <div className="card card-custom gutter-b">
            <div className="card-header align-text-bottom">
                <div className="card-title">
                    <h3 className="card-label">
                        {`Educación ${name}`}
                    </h3>
                    <div className="d-flex text-capitalize">

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values[name]}
                                    onChange={(event: any) => changeCheckbox(event)}
                                    name={name}
                                />}
                            label=""
                        />
                    </div>
                </div>
            </div>
            <div className="card-body">
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <div className="d-flex text-center justify-content-center mb-10">
                            <div className="text-capitalize">
                                <div className="d-flex flex-column align-self-center">

                                    <FieldArray name={`trayectoria.${name}List`}>
                                        {({ push, remove, form }) => {
                                            const { values } = form;
                                            const { trayectoria } = values;
                                            return (
                                                <>
                                                    {
                                                        trayectoria[`${name}List`]?.map((item: IdatosTrayectoria, index: number) => (
                                                            <div key={index} className="d-flex justify-content-center">
                                                                <FormikControl
                                                                    control="select"
                                                                    label="Tipo de escuela"
                                                                    name={`trayectoria.${name}List[${index}].tipo`}
                                                                    onChange={formik.handleChange}
                                                                    disabled={!values[name]}
                                                                    options={SELECT_TREDUC_OPTIONS}
                                                                />
                                                                <FormikControl
                                                                    control="select"
                                                                    label="Estado"
                                                                    name={`trayectoria.${name}List[${index}].estado`}
                                                                    onChange={form.handleChange}
                                                                    disabled={!values[name]}
                                                                    options={SELECT_ESCCOM_OPTIONS}
                                                                />
                                                                {/* {formik!.touched!.trayectoria[`${name}List`] && formik!.errors!.trayectoria[`${name}List`] && <TextErrorFormik>{formik?.errors?.trayectoria[`${name}List`].toString()}</TextErrorFormik>} */}
                                                                {values[name] &&
                                                                    <div className="d-flex align-bottom">

                                                                        <button
                                                                            disabled={!values[name]}
                                                                            style={disabledStyle}
                                                                            type="button"

                                                                        >
                                                                            <span onClick={() => remove(index)}>
                                                                                <KTSVG path="/media/icons/duotune/general/gen040.svg" className={!values[name] ? "svg-icon-2x" : "svg-icon-3x svg-icon-danger"} />
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                    {values[name] &&
                                                        <button
                                                            type="button"
                                                            disabled={!values[name]}
                                                            style={disabledStyle}
                                                        >
                                                            <span onClick={() => push(INIT_STATE)}>
                                                                <KTSVG path="/media/icons/duotune/general/gen041.svg" className={!values[name] ? "svg-icon-2x" : "svg-icon-3x svg-icon-success"} />
                                                            </span>
                                                        </button>
                                                    }
                                                </>
                                            )
                                        }
                                        }
                                    </FieldArray>
                                </div>
                            </div>

                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default TrayectoriaEducativa