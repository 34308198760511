import {styled} from '@mui/material'
import React from 'react'
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip'
import {color} from '@mui/system'

export const BootstrapTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: `${color}`,
    color: `#919A9B`,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${color}`,
    color: `#FFFFFF`,
    boxShadow: theme.shadows[2],
    fontSize: 11,
    fontWeightBold: 700,
    disabled: false,
  },
}))