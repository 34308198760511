import * as Yup from 'yup'
import {ICreateModulo} from '../../../interfaces/ICreateModulo'

export const moduleInit: ICreateModulo = {
  nombre: '',
  icono: '',
  descripcion: '',
  paginaInicio: '',
  favorito: false,
  menu: [
    {
      // _id: String,//Schema.Types.ObjectId,
      name: '', //  Ej: link/dropDown/icon/separator/extLink,
      type: '', // Tipo de menú: Ej: link/dropDown/icon/separator/extLink,
      tooltip: '',
      icon: '',
      state: '', // Router Ej: 'gestor-usuarios/organizaciones'
      disabled: false, // true no se muestra en el menú
      level: 0,
      expandable: false,
      roleUs: {
        admin: false,
        crear: false,
        consulta: false,
        editor: false,
        exportar: false,
        indicadores: false,
      },
    },
  ],

  activo: true,
}

export const validModSchema = [
  Yup.object({
    nombre: Yup.string().required('El campo es requerido').label('Nombre'),
    descripcion: Yup.string().required('El campo es requerido').label('Info del Modulo'),
    paginaInicio: Yup.string().required('El campo es requerido').label('Pagina de Inicio'),
  }),
  Yup.object({
    menu: Yup.array().of(
      Yup.object({
        name: Yup.string().required('nombre es requerido'),
        type: Yup.string().required('El tipo es requerido'),
        tooltip: Yup.string().required('El tooltip es requerido'),
        state: Yup.string().required('La ruta es requerida'),
      })
    ),
  }),

  // }),
]
