import React from 'react'
import {useDispatch} from 'react-redux'
import {tablaDatosBengAction} from '../redux/TablaDatosAction'
import {getIndicadores} from '../services/Bens-Services'

type Props = {
  className: string
  mPrincipal?: []
  selectMenu(opcion: string, tablaDatos: {}): any
}
let tablaDatos = {} as any
const MenuBens: React.FC<Props> = ({className, mPrincipal, selectMenu}) => {
  const dispatch = useDispatch()
  const handelChange = async (opcion: string) => {
    switch (opcion) {
      case 'descargahacienda':
        tablaDatos = {
          className: 'card-xxl-stretch mb-5 mb-xl-8',
          tituloMenu: 'Descargar de Hacienda',
          headColumns: {
            col1: '',
            col2: 'ID',
            col3: 'Dni',
            col4: 'Apellido',
            col5: 'Nombre',
          },
        }
        dispatch(tablaDatosBengAction(tablaDatos))
        break

      case 'inscripciones':
        tablaDatos = {
          className: 'card-xxl-stretch mb-5 mb-xl-8 ',
          tituloMenu: 'Inscripciones',
          headColumns: {
            col1: '',
            col2: 'ID',
            col3: 'Dni',
            col4: 'Apellido',
            col5: 'Nombre',
          },
        }
        dispatch(tablaDatosBengAction(tablaDatos))
        break

      case 'validados':
        tablaDatos = {
          className: 'card-xxl-stretch mb-5 mb-xl-8',
          tituloMenu: 'Validados',
          headColumns: {
            col1: '',
            col2: 'ID',
            col3: 'Dni',
            col4: 'Apellido',
            col5: 'Nombre',
          },
        }
        dispatch(tablaDatosBengAction(tablaDatos))
        break

      case 'indicadores':
        getIndicadores().then((resp: any) => {
          tablaDatos = {
            className: 'card-xxl-stretch mb-5 mb-xl-8',
            tituloMenu: 'Indicadores',
            tablaDatos: resp.data.res,
            headColumns: {
              col1: '',
              col2: 'ID',
              col3: 'Dni',
              col4: 'Apellido',
              col5: 'Nombre',
            },
          }
          dispatch(tablaDatosBengAction(tablaDatos))
        })
        break
    }
  }

  return (
    <div className={`card sombras ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 '>
        <h3 className='card-title fw-bolder'>Menú de Opciones</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2 '>
        {/* begin::Item */}
        {mPrincipal?.map((item: any) => {
          return (
            <div className='d-flex align-items-center mb-8' key={item._id}>
              {/* begin::Bullet */}
              <span className='bullet bullet-vertical h-40px bg-success'></span>
              {/* end::Bullet */}

              {/* begin::Description */}
              <div className='flex-grow-1 mx-5'>
                <button
                  className='btn btn-xl btn-light-primary capital'
                  onClick={() => handelChange(item.name)}
                >
                  {item.name}
                </button>
              </div>
              {/* end::Description */}
            </div>
          )
        })}
        {/* end:Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}
export {MenuBens}
