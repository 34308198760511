/** React */
import { useState, useEffect } from 'react';
import {Link, useLocation, useHistory } from 'react-router-dom'; 
import { Toaster } from 'react-hot-toast';
/** Formik */
import {Field, Form, Formik} from 'formik'
import FormikControl from '../../../../components/Formik/FormikControl'
/** Material & Sass */
import {Button, Checkbox} from '@mui/material'
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp'
import '../../../../../../src/_metronic/assets/sass/cud.scss'
/** Services */
import {updateJunta} from '../services/cud-service'
import { localidadesNqn } from '../../../../services/gral/proLocService';
 /** Hook */
import {notify} from '../hooks/useModal'
 /** Interfaces */
import { validateSchema } from '../Interfaces/Ijunta';
import { MySelect } from '../components/Formik/MySelect';

export const JuntaEdit = () => {
  const [localidad, setLocalidad] = useState<any>([])
  const [junta, setJunta] = useState(false)
  const [loading, setLoading] = useState(false); 

  const location: any = useLocation(); 
  const history = useHistory();
  const [btnEdit, setBtnEdit] = useState(false); 
    
  useEffect(() => {
    localidadesNqn().then((res: any)=>{
      setLocalidad(res.data.data); 
    })
  }, [loading])

  const handleClick = () =>{
    setTimeout(() => {
      setLoading(true); 
    }, 10);
    setTimeout(() => {
      setJunta(!junta);       
      setLoading(false); 
    }, 700);
  }

  const handleBtn = ()=>{
    setBtnEdit(!btnEdit)
  }

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
      <Link to={{pathname: '/cud/Legajos', state:{
                activado: true,
                obj: location.state
              },}} className='alineacionb'>
          <ArrowBackSharpIcon />
          </Link>

      </div>
      <div className='card-body py-1'>
        <br />
        <div className='table-responsive'>
          <Formik
            initialValues={location.state}
            validationSchema={validateSchema}
            onSubmit={(values) => {
              updateJunta(values).then((res: any) => {
              notify()
              setTimeout(()=>{
                history.push('/cud/home');        
              }, 2501)})  
           }}>
            {({handleChange, handleBlur}) => (
              <div>
                <Form>
                  <div>
                    <div className='row'>
                      <div className='col-lg-3 test'>
                      <label className='form-label fw-bolder text-dark fs-6'>Nombre</label>
                        <Field
                          control='input'
                          type='text'
                          id='nombre'
                          name='nombre'
                          className='form-control form-control-solid capital'
                          disabled={!btnEdit}
                          onChange={handleChange}
                          onBlur={handleBlur}/>
                      </div>
                      <div className='col-lg-3'>
                      <label className='form-label fw-bolder text-dark fs-6'>Domicilio</label>
                        <Field
                          control='input'
                          type='text'
                          id='domicilio'
                          name='domicilio'
                          className='form-control form-control-solid capital'
                          disabled={!btnEdit}
                          onChange={handleChange}
                          onBlur={handleBlur}/>
                      </div>
                      <div className='col-lg-3'>    
                      <label className='form-label fw-bolder text-dark fs-6'>Localidad</label>
                     {junta === false ? (
                     <Field
                          control='input'
                          type='text'
                          id='localidad'
                          name='localidad'
                          className='form-control form-control-solid capital'

                          disabled={!btnEdit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onClick={handleClick}
                        />
                        ) : (   
                        <MySelect label="Localidad" name="localidad" className='form-control form-control-solid capital' disabled={!btnEdit}>
                             <option value=''>Seleccione localidad</option>
                                 {localidad.map((value: any)=>{
                                   return <option value={value.nombre}>{value.nombre}</option>
                                   })}
                        </MySelect>)}                  
                       {loading && <p>Cargando</p>}
                      </div>
                      <div className='col-lg-3'>
                      <label className='form-label fw-bolder'>Estado</label>
                      <br />
                        <Field
                          type='checkbox'
                          as={Checkbox}
                          id='activo'
                          name='activo'
                          label= 'Test'
                          className=''
                          disabled={!btnEdit}
                          onChange={handleChange}
                          onBlur={handleBlur}/>
                        <label className=''>Activo</label>
                      </div>
                    </div>
                    <br></br>
                  </div>
                  <p></p>{' '}
                  <div className='row card_align5'>
                      <div className='col-lg-8'>
                      <Button className='btn btn-primary' onClick={handleBtn}>
                          {btnEdit === false ? "Habilitar Edición" : "Desabilitar Edición"}
                        </Button>&nbsp;&nbsp;
                        {btnEdit === true ? (
                        <Button type='submit' className='btn btn-success' disabled={false}>
                          Guardar
                        </Button>
                          ): ""}
                          <Toaster
                              position="top-right"
                              reverseOrder={false}/>
                      </div>{' '}
                    </div>
               </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
