/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Field} from 'formik'

const Step3Org: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Datos para la administración del sistema</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Puede ingresar el logo del ministerio, el color de los menus y un nombre corto de
          referencia al ministerio.
        </div>
      </div>
      <div className='fv-row'>
        <div className='row justify-content-center'>
          <div className='col-lg-4'>
            <label className='form-label'>Color principal del Ministerio</label>
            <Field
              type='text'
              name='datosSistemas.colorMinisterioA'
              className='form-control form-control-solid'
              placeholder='Ingrese el codigo de colores'
            />
          </div>
          <div className='col-lg-4'>
            <label className='form-label'>Color Secundario del Ministerio</label>
            <Field
              type='text'
              name='datosSistemas.colorMinisterioB'
              className='form-control form-control-solid'
              placeholder='Ingrese el codigo de colores'
            />
          </div>
        </div>
        <hr />
        <div className='row  justify-content-center'>
          <div className='col-lg-4'>
            <label className='form-label'>Color de la letra</label>
            <Field
              type='text'
              name='datosSistemas.colorLetra'
              className='form-control form-control-solid'
              placeholder='Ingrese el codigo de colores'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step3Org}
