import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import clsx from 'clsx'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { ISTATE } from '../../../../../../setup/redux/interface/reduxState.interface'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { eventosSetAccion, evSetFormulario } from '../../Redux/actions/eventos'
import { actualizarEvento } from '../../Redux/services/Agenda_Service'
import './BotonAgenda.scss'

type Props = {
  imagen?: string
  pag_inicio?: string
  nombre?: string
  colorFondo?: string
  colorDisable?: string
  sizeBoton?: string
  sizeIcon?: string
  accion: string
  habilitado: boolean
  data: any
}
const ButtonMarginClass = 'ms-1 ms-lg-3'

const BotonAccion: React.FC<Props> = ({
  imagen,
  pag_inicio,
  nombre,
  colorFondo,
  colorDisable,
  sizeBoton,
  sizeIcon,
  accion,
  habilitado,
  data,
}) => {
  const dispatch = useDispatch()
  const selStore: any = useSelector((state: ISTATE) => state.menuBoton)
  const { seleccion } = selStore
  const eventosStore: any = useSelector((state: ISTATE) => state.eventos)
  const { acciones } = eventosStore
  const { formulario } = eventosStore
  // ////console.log(formulario)
  let color: any = ''
  let ButtonIconSizeClass = sizeIcon !== undefined ? sizeIcon : 'svg-icon-1'
  let ButtonHeightClass = sizeBoton !== undefined ? sizeBoton : 'w-50px h-40px w-md-60px h-md-50px'
  useEffect(() => {
    // ////console.log('Formulario en Boton', formulario)
  }, [formulario])

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement='top' />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${color}`,
      color: `#FFFFFF`,
      boxShadow: theme.shadows[1],
      fontSize: 11,
      fontWeightBold: 700,
      disabled: false,
    },
  }))

  return (
    <>
      <LightTooltip
        className='negrita'
        children={
          <div className={clsx('d-flex align-items-center', ButtonMarginClass)}>
            <button
              disabled={habilitado && data}
              className={clsx(
                `btn btn-icon  ${colorFondo} btn-custom ${colorDisable}`,
                ButtonHeightClass
              )}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
              onClick={() => {
                let accionesTemp: any
                switch (accion) {
                  case 'editar':
                    accionesTemp = {
                      ...acciones,
                      guardar: true,
                      editar: false,
                      cancelar: false,
                      conclusion: false,
                      imprimir: false,
                    }
                    dispatch(eventosSetAccion(accionesTemp))
                    break

                  case 'guardar':
                    if (data) {
                      accionesTemp = {
                        ...acciones,
                        guardar: false,
                        editar: !acciones.editar,
                        cancelar: !acciones.cancelar,
                        conclusion: !acciones.conclusion,
                        imprimir: !acciones.imprimir,
                        enviarForm: !acciones.enviarForm,
                      }
                      dispatch(eventosSetAccion(accionesTemp))
                      Swal.fire({
                        position: 'center',
                        icon: 'info',
                        title: 'Enviando el evento para Guardar',
                        showConfirmButton: false,
                        timer: 2500,
                      })
                      //console.log('formulario', formulario.isValid)

                      actualizarEvento(formulario, 'upd').then((res: any) => {
                        //console.log('formulario', formulario)
                        //console.log('res', res)
                        Swal.close()
                        if (res.data.ok) {
                          Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'El evento fue actualizado con Éxito',
                            showConfirmButton: false,
                            timer: 4500,
                          })
                        } else {
                          Swal.fire({
                            position: 'center',
                            title: 'Error!',
                            text: 'Por favor reintente nuevamente',
                            icon: 'error',
                            confirmButtonText: 'Volver',
                          })
                        }
                      })
                    }
                    break
                  case 'cancelarEdicion':
                    accionesTemp = {
                      ...acciones,
                      guardar: false,
                      editar: !acciones.editar,
                      cancelar: !acciones.cancelar,
                      conclusion: !acciones.conclusion,
                      imprimir: !acciones.imprimir,
                      enviarForm: !acciones.enviarForm,
                    }
                    dispatch(eventosSetAccion(accionesTemp))
                    // ////console.log('formulario', formulario)

                    break
                  case 'conclusion':
                    accionesTemp = {
                      ...acciones,
                      guardar: false,
                      editar: false,
                      conclusion: false,
                      imprimir: false,
                      enviarForm: false,
                    }
                    dispatch(eventosSetAccion(accionesTemp))
                    break
                  case 'cancelar':
                    Swal.fire({
                      title: 'Por favor, complete el motivo de la cancelación del Evento',
                      input: 'text',
                      inputAttributes: {
                        autocapitalize: 'off',
                      },
                      showCancelButton: true,
                      confirmButtonText: 'Guardar Motivo',
                      showLoaderOnConfirm: true,
                      preConfirm: (conclusion) => {
                        let eventoTemp = formulario
                        eventoTemp = {
                          ...eventoTemp,
                          estado: 'cancelado',
                          conclusion: conclusion,
                        }
                        return actualizarEvento(eventoTemp, 'cancelar')
                          .then((response: any) => {
                            if (!response.data.ok) {
                              throw new Error(response.data.err)
                            }
                            accionesTemp = {
                              ...acciones,
                              editar: false,
                              guardar: false,
                              cancelar: false,
                              conclusion: false,
                              imprimir: true,
                              enviarForm: false,
                            }

                            // ////console.log('CANCELAR - eventoSeleccionado', eventoTemp)
                            dispatch(evSetFormulario([eventoTemp]))
                            dispatch(eventosSetAccion(accionesTemp))
                          })
                          .catch((error) => {
                            Swal.showValidationMessage(`Error en el envío de Datos: ${error}`)
                          })
                      },
                      allowOutsideClick: () => !Swal.isLoading(),
                    }).then((result) => {
                      // ////console.log('result', result)
                      if (result.isConfirmed) {
                        Swal.fire({
                          title: `Se guardo la Cancelación del Evento`,
                        })
                      }
                    })
                    break
                  default:
                    break
                }
                // ////console.log('Se hizo Click', accion, accionesTemp)
              }}
            >
              <div className='row d-flex'>
                <KTSVG path={`/media/icons/duotune${imagen}`} className={ButtonIconSizeClass} />
                <label className='fs-8'>{nombre}</label>
              </div>
            </button>
          </div>
        }
        title={
          <Typography color='inherit' style={{ fontWeight: 600 }}>
            {nombre}
          </Typography>
        }
      ></LightTooltip>
    </>
  )
}
export { BotonAccion }
