import moment from 'moment'
import React, {useRef, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useReactToPrint} from 'react-to-print'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ExportPDF} from '../Exports/ExportPDF'
type props = {
  datos?: any
  show: boolean
  handleCloseModal: () => void
}

type MyProps = {
  data: any
  ref: any
}

export const ModalDetalles: React.FC<props> = ({show, handleCloseModal, datos}) => {

  let componentRef: any = useRef()
  const handelprint = useReactToPrint({
    content: () => componentRef.current,
  })
  return (
    <Modal
      className='modal fade config-modal'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      size='sm'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Detalles</h5>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleCloseModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <div>
          <b>Origenes</b>
          <p></p>
          <p></p>
          {datos.problemas_origenes.motor === true ? ("Motor ✔"):(null)} 
          &nbsp;&nbsp;           
          {datos.problemas_origenes.mental === true ? ("Mental ✔"):(null)}            
          {datos.problemas_origenes.auditivo === true ? ("Auditivo ✔"):(null)}            
          {datos.problemas_origenes.visual === true ? ("Visual ✔"):(null)}            
          {datos.problemas_origenes.visceral === true ? ("Visceral ✔"):(null)}            
          {datos.problemas_origenes.intelectual === true ? ("Intelectual ✔"):(null)}            
          </div>
<br></br>
<hr></hr>
<br></br>
          <div>
          
          <b>CIE10</b>
          <p></p>
          <p></p>
          <p>{datos?.cie10.map((valu:any)=>{
            return(
              
              <div>{'• '+ valu}.</div>
            
            )
            
          })}</p>


          </div>
          </div>
      </div>
    </Modal>
  )
}
