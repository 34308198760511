/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { register } from '../Services/AuthService'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { cambiarPasswordPorEmail } from '../../apps/Sis-admin/services/UsuarioService'
import Spinner from '../../../components/spinner/Spinner';
import { Idatos } from '../../apps/Cud/Interfaces/Datos-interface'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { logoutDashboard } from '../../../../setup/redux/ReduxActions/DashActions'

const initialValues = {
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(12, 'Maximo 12 caracteres')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\-\/\,\;])(?=.{12,})/,
      'Debe contener 12 caracteres, una Mayuscula, una Minuscula, un Número y un caracter especial.'
    )
    .required('El campo es requerido'),

  changepassword: Yup.string()
    .required('¡Debe aceptar los cambios!')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'La contraseña y la confirmación de la misma no son iguales.'
      ),
    }),
})

export function ResetPaswoord(props: any) {
  const [loading, setLoading] = useState(false)
  const primerLogin = useSelector<any>(({ dashBoard }) => dashBoard.primerLogin, shallowEqual);
  const dispatch = useDispatch();
  const location: any = useLocation();
  const history = useHistory();
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)

      //Se busca el dni del usuario en la propiedad location.pathname
      let usuarioLogged: string = "";
      if (props.usuario) {
        usuarioLogged = props.usuario;
      }
      else {
        usuarioLogged = location.pathname.split("/")[3];
      }

      const datos = {
        usuario: usuarioLogged,
        password: values.password,
        passwordNuevo: values.changepassword,
      };
      console.log("Datos: ", datos);

      cambiarPasswordPorEmail(datos, 'cambiarPass')
        .then((response) => {
          if (response.data && response.data.ok) {
            console.log("Password cambiado correctamente");
            dispatch(logoutDashboard())
            history.push("/");

          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setSubmitting(false);
        });

      /*       setTimeout(() => {
              cambiarPassword(values.password)
                .then(({data: {accessToken}}) => {
                  setLoading(false)
                })
                .catch(() => {
                  setLoading(false)
                  setSubmitting(false)
                  setStatus('Registration process has broken')
                })
            }, 1000) */
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        { primerLogin &&

        <h2 className='text-danger mb-3'>Para el primer ingreso debe cambiar su contraseña</h2>
        }
        { !primerLogin && <h1 className='text-dark mb-3'>Puede ingresar su nueva contraseña</h1> }
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'></span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Contraseña</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Contraseña'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirmar Contraseña</label>
        <input
          type='password'
          placeholder='Confirmación de contraseña'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            Acepto los cambios.{' '}
            {/*  <Link to='/auth/terms' className='ms-1 link-primary'>
              
            </Link> */}
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
     <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Aceptar</span>}
          {loading && <span className='indicator-label'>Enviando...</span>}
        </button>
          {loading && (
            <>
              <Spinner />
              <span className='indicator-progress' style={{ display: 'block' }}>
                Por favor espere...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </>
          )}
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancelar
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
