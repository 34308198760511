import {useSelector} from 'react-redux'
import {Switch, Route, useRouteMatch, Redirect} from 'react-router-dom'
import {ISTATE} from '../../../../setup/redux/interface/reduxState.interface'
import {ICreateModulo} from './interfaces/ICreateModulo'

import OrganimosSettings from './pages/OrganimosSettings'
import SelectModulosPerfil from './pages/SelectModulosPerfil'

import {SisAdminPage} from './pages/SisAdminPage'
import StepHorizontalArea from './pages/StepHorizontalArea'
import StepHorizontalModulos from './pages/StepHorizontalModulos'
import StepHorizontalOrganismos from './pages/StepHorizontalOrganismos'
import StepHorizontalUsuario from './pages/StepHorizontalUsuario'
import UsuarioAccountPage from './pages/UsuarioAccountPage'

function SisAdminRoute() {
  const {url} = useRouteMatch()
  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)
  let {perfil} = dashState
  const modulo: ICreateModulo = perfil.find((value: any) => value.nombre === 'administracion')
  return (
    <Switch>
      <Route exact path={`${url}/servicios`} component={SisAdminPage} />
      <Route
        exact
        path={`${url}/organismos`}
        render={() => {
          const menuAct = modulo.menu.find((value) => value.name === 'organismos')

          return menuAct?.roleUs?.crear ? (
            <StepHorizontalOrganismos />
          ) : (
            <Redirect to={`${url}/servicios`} />
          )
        }}
      />
      <Route
        exact
        path={`${url}/organismos/dependencia`}
        render={() => {
          const menuAct = modulo.menu.find((value) => value.name === 'organismos')

          return menuAct?.roleUs?.crear ? (
            <StepHorizontalArea />
          ) : (
            <Redirect to={`${url}/servicios`} />
          )
        }}
      />
      <Route
        exact
        path={`${url}/organismos/settings`}
        render={() => {
          const menuAct = modulo.menu.find((value) => value.name === 'organismos')

          return menuAct?.roleUs?.crear || menuAct?.roleUs?.editor || menuAct?.roleUs?.consulta ? (
            <OrganimosSettings />
          ) : (
            <Redirect to={`${url}/servicios`} />
          )
        }}
      />
      <Route
        exact
        path={`${url}/usuario`}
        render={() => {
          const menuAct = modulo.menu.find((value) => value.name === 'usuarios')

          return menuAct?.roleUs?.crear ? (
            <StepHorizontalUsuario />
          ) : (
            <Redirect to={`${url}/servicios`} />
          )
        }}
      />
      <Route exact path={`${url}/usuario/account`} component={UsuarioAccountPage} />
      <Route
        exact
        path={`${url}/usuario/account/perfil`}
        render={() => {
          const menuAct = modulo.menu.find((value) => value.name === 'usuarios')

          return menuAct?.roleUs?.crear ? (
            <SelectModulosPerfil />
          ) : (
            <Redirect to={`${url}/servicios`} />
          )
        }}
      />
      <Route
        exact
        path={`${url}/modulos`}
        render={() => {
          const menuAct = modulo.menu.find((value) => value.name === 'modulos')

          return menuAct?.roleUs?.crear ? (
            <StepHorizontalModulos />
          ) : (
            <Redirect to={`${url}/servicios`} />
          )
        }}
      ></Route>
      <Route path='*'>
        <Redirect to='/page404' />
      </Route>
    </Switch>
  )
}

export default SisAdminRoute
