import { KTSVG } from '../../../../../../_metronic/helpers'
/** React */
import React from 'react'
import { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
/**  Icons Material UI */
import InfoIcon from '@mui/icons-material/Info'
import { Box, Collapse, styled } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
/** Interfaces */
import { Itramites, Itramitess } from '../../Interfaces/Itramite'
/** Moment */
import moment from 'moment'
import { BootstrapTooltip } from '../../helpers/bootstrapToolTip'
import { crearlegajo } from '../../helpers/SweetAlerts'

import { ModalPrevPDF } from '../Modal/ModalPrevPDF'
import { Pagination } from '../../helpers/pagination'
import { useLocation } from 'react-router'

moment.locale('es-mx')
type Props = {
  tramite: Itramitess | null;
  activePage: any;
  totalPages: any;
  setActivePage: any;
  vista: boolean;
  activando?: boolean;
  objeto?: any;
  setObjeto?: any;
}

export const DataTramites: React.FC<Props> = ({
  tramite,
  setActivePage,
  activePage,
  totalPages,
  vista,
  activando,
  objeto,
  setObjeto,
}) => {
  let locationBtnPrincipal = useLocation()

  // const [tramites, setTramites] = useState<Itramitess>([])
  const [dataTramite, setDataTramite] = useState<any>([])

  const [show, setShow] = useState<boolean>(false)
  const handleCloseModal = () => setShow(false)
  let history = useHistory()

  //ESTE METODO DEBE ESTAR PARA QUE FUNCIONE MOSTRAR TODOS
  useEffect(() => {
    dataTramite.length > 1 && setDataTramite([])
  }, [tramite, dataTramite])

  useEffect(() => {
    setDataTramite([])
    return () => {}
  }, [locationBtnPrincipal])

  const mfuncion = (tram: any) => {
    history.push({pathname: '/cud/MesaEntrada', state: null})
    setDataTramite((data: any) => [...data, tram])
  }

  const calculoanios = (fechaNacimiento: any) => {
    let nacimiento: any = moment(fechaNacimiento, 'DD/MM/YYYY')
    let hoy1: any = moment().format('DD/MM/YYYY')
    let hoy2 = moment(hoy1, 'DD/MM/YYYY')
    let anios: any = hoy2.diff(nacimiento, 'year')
    return anios
  }
  return (
    <>
      <table className='table align-middle gs-0 gy-4'>
        {dataTramite.length === 0 ? (
          <thead className='border-tabla-right'>
            <tr className='fw-bolder bg-light'>
              <th className='min-w-4px'></th>
              <th className='min-w-30px'>Fecha</th>
              <th className='min-w-120px'>Apellido y Nombre</th>
              <th className='min-w-50px'>Dni</th>
              <th className='min-w-100px'>Localidad</th>
              <th className='min-w-50px'>Edad</th>
              <th className='min-w-50px'>Género</th>
              <th className='min-w-80px'>Estado</th>
              <th className='min-w-20px'>Acciones</th>
            </tr>
          </thead>
        ) : (
          <thead className='border-tabla-right'>
            <tr>
              <th colSpan={11}></th>
            </tr>
          </thead>
        )}
        <tbody className='border-tabla-top'>
          {dataTramite.length === 0
            ? tramite?.map((tram: any, index: any) => {
              let anios: any = '';
              if (tram && tram.datosPersonales) {
                let nacimiento: any = moment(tram.datosPersonales.fechaNacimiento, 'DD-MM-YYYY')
                let hoy1: any = moment().format('DD/MM/YYYY')
                let hoy2 = moment(hoy1, 'DD/MM/YYYY')
                anios = hoy2.diff(nacimiento, 'year')
              }

              return (
                <>
                  <tr className='table_font_normal' key={index}>
                    <td></td>
                    <td className='table_cap'>{tram?.fecha}</td>
                    <td className='table_cap'>
                      {tram.datosPersonales.apellido} {tram?.datosPersonales.nombre}
                    </td>
                    <td className='table_cap'>{tram?.datosPersonales.dni}</td>
                    <td className='table_cap'>{tram?.datosPersonales.localidad}</td>
                    {anios >= 18 ? (
                      <td className=''>{anios} años</td>
                    ) : (
                      <td className='menosdeedad'>{anios} años</td>
                    )}
                    <td className='table_cap'>
                      {tram?.datosPersonales.sexo === 'f'
                        ? 'F'
                        : tram?.datosPersonales.sexo === 'm'
                          ? 'M'
                          : tram?.datosPersonales.sexo === 'x'
                            ? 'X'
                            : null}
                    </td>
                    {tram?.estado === 'activo' ? (
                      <td className='table_cap'>
                        <span className='badge badge-light-success'>{tram?.estado}</span>
                      </td>
                    ) : (
                      <td className='table_cap'>
                        <span className='badge badge-light-warning'>{tram?.estado}</span>
                      </td>
                    )}

                    {activando !== true ? (
                      <td>
                        <BootstrapTooltip title='Detalles' arrow followCursor placement='top'>
                          <button
                            className='btn btn-icon btn-bg-primary btn-color-light btn-active-light btn-active-color-primary btn-sm me-1'
                            onClick={() => setDataTramite([tram])}
                            name='Detalles'
                          >
                            <InfoIcon />
                          </button>
                        </BootstrapTooltip>
                      </td>
                    ) : (
                      setDataTramite([tram])
                    )}
                  </tr>
                </>
              )
            })
            : dataTramite?.map((tram: any, index: any) => {
              return (
                <>
                  <tr key={index}>
                    <td className='col-lg-11'>
                      <label>Fecha Tramite:</label>
                      <label className='form-label m-0 '>
                        <div className="mx-2">
                          {tram?.fecha.includes('-') ?
                            moment(tram?.fecha).format('DD/MM/YYYY') :
                            tram?.fecha
                          }
                        </div>
                      </label>
                    </td>
                    <td colSpan={10}>
                      <BootstrapTooltip title='Descargar PDF' arrow followCursor placement='top'>
                        <button
                          className='btn btn-icon btn-bg-primary btn-color-light btn-active-light btn-active-color-primary btn-sm me-1'
                          onClick={() => setShow(true)}
                          name='Descargar PDF'
                        >
                          <PictureAsPdfIcon />
                        </button>
                      </BootstrapTooltip>

                      {activando !== true ? (
                        <BootstrapTooltip
                          title='Mostrar Todos'
                          arrow
                          followCursor
                          placement='top'
                        >
                          <button
                            className='btn btn-icon btn-bg-primary btn-color-light btn-active-light btn-active-color-primary btn-sm me-1'
                            onClick={() => setDataTramite((data: any) => [...data, tram])}
                            name='Ver todos'
                          >
                            <CancelIcon />
                          </button>
                        </BootstrapTooltip>
                      ) : (
                        <BootstrapTooltip
                          title='Mostrar Todos'
                          arrow
                          followCursor
                          placement='top'
                        >
                          <button
                            className='btn btn-icon btn-bg-primary btn-color-light btn-active-light btn-active-color-primary btn-sm me-1'
                            onClick={() => {
                              mfuncion(tram)
                            }}
                            name='Ver todos'
                          >
                            <CancelIcon />
                          </button>
                        </BootstrapTooltip>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Collapse in={true} timeout='auto' unmountOnExit>
                        <Box sx={{ margin: 1 }} className='no-margin-top'>
                          <div className='mt-5 fv-row'>
                            <label className='label_grid sub-text-datos fw-bolder'>
                              DATOS PERSONALES SOLICITANTE
                            </label>
                            <br></br>
                            <br></br>
                            <div className='row'>
                              <div className='col-lg-3'>
                                <label className=''>Dni:</label>
                                <label className='form-label m-0 table_cap '>
                                  &nbsp;{tram?.datosPersonales.dni}
                                </label>
                              </div>
                              <div className='col-lg-3'>
                                <label className=''>Apellido:</label>
                                <label className='form-label m-0 table_cap '>
                                  &nbsp;{tram?.datosPersonales.apellido}
                                </label>
                              </div>
                              <div className='col-lg-3'>
                                <label className=''>Nombre:</label>
                                <label className='form-label m-0 table_cap'>
                                  &nbsp;{tram.datosPersonales.nombre}
                                </label>
                              </div>
                              <div className='col-lg-3'>
                                <label className=''>Fecha nacimiento:</label>
                                <label className='form-label m-0 '>
                                  &nbsp;
                                  {tram.datosPersonales.fechaNacimiento} (
                                  {calculoanios(tram.datosPersonales.fechaNacimiento)} años)
                                </label>
                              </div>
                            </div>
                            <br></br>
                            <div className='row'>
                              <div className='col-lg-3'>
                                <label className=''>Domicilio:</label>
                                <label className='form-label m-0 table_cap '>
                                  &nbsp;{tram.datosPersonales.domicilio}
                                </label>
                              </div>
                              <div className='col-lg-3'>
                                <label className=''>Localidad:</label>
                                <label className='form-label m-0 table_cap '>
                                  &nbsp;{tram.datosPersonales.localidad}
                                </label>
                              </div>
                              <div className='col-lg-3'>
                                <label className=''>Telefono:</label>
                                <label className='form-label m-0 table_cap'>
                                  &nbsp;{tram.datosPersonales.telefono}
                                </label>
                              </div>
                              <div className='col-lg-3'>
                                <label className=''>Email:</label>
                                <label className='form-label m-0 '>
                                  &nbsp;{tram.datosPersonales.mail}
                                </label>
                              </div>
                            </div>
                            {tram.datosTutor && tram.datosTutor?.dni !== '' ? (
                              <>
                                <hr></hr>
                                <label className='label_grid sub-text-datos fw-bolder'>
                                  DATOS PERSONALES TUTOR
                                </label>
                                <br></br>
                                <br></br>
                                <div className='row'>
                                  <div className='col-lg-3'>
                                    <label className=''>Dni:</label>
                                    <label className='form-label m-0 table_cap '>
                                      &nbsp;{tram.datosTutor.dni}
                                    </label>
                                  </div>
                                  <div className='col-lg-3'>
                                    <label className=''>Apellido:</label>
                                    <label className='form-label m-0 table_cap '>
                                      &nbsp;{tram.datosTutor.apellido}
                                    </label>
                                  </div>
                                  <div className='col-lg-3'>
                                    <label className=''>Nombre:</label>
                                    <label className='form-label m-0 table_cap '>
                                      &nbsp;{tram.datosTutor.nombre}
                                    </label>
                                  </div>
                                  <div className='col-lg-3'>
                                    <label className=''>Fecha N:</label>
                                    <label className='form-label m-0 table_cap '>
                                      &nbsp;
                                      {tram.datosTutor.fechaNacimiento}
                                    </label>
                                  </div>
                                </div>
                                <br></br>
                                <div className='row'>
                                  <div className='col-lg-3'>
                                    <label className=''>Domicilio:</label>
                                    <label className='form-label m-0 table_cap '>
                                      &nbsp;{tram.datosTutor.domicilio}
                                    </label>
                                  </div>
                                  <div className='col-lg-3'>
                                    <label className=''>Localidad:</label>
                                    <label className='form-label m-0 table_cap '>
                                      &nbsp;{tram.datosTutor.localidad}
                                    </label>
                                  </div>

                                  <div className='col-lg-3'>
                                    <label className=''>Telefono:</label>
                                    <label className='form-label m-0 table_cap '>
                                      &nbsp;{tram.datosTutor.telefono}
                                    </label>
                                  </div>
                                  <div className='col-lg-3'>
                                    <label className=''>Email:</label>
                                    <label className='form-label m-0  '>
                                      &nbsp;{tram.datosTutor.mail}
                                    </label>
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <hr></hr>
                            {tram.estado === 'activo' ? (
                              <>
                                <Link to={{ pathname: 'EditarTramite/', state: tram }}>
                                  <button
                                    className='btn btn-primary btn-sm'
                                    title='Editar Tramite'
                                  >
                                    EDITAR
                                  </button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;
                                {/*  <Link to={{pathname: 'NuevoLegajo/', state: tram}}> */}
                                <button
                                  className='btn btn-primary btn-sm'
                                  title='Crear Legajo'
                                  onClick={() => crearlegajo(tram, history, objeto)}
                                >
                                  CREAR LEGAJO
                                </button>
                                {/* </Link> */}
                              </>
                            ) : null}
                          </div>
                        </Box>
                      </Collapse>
                    </td>
                  </tr>
                </>
              )
            })}
        </tbody>
      </table>

      {vista === true ? null : dataTramite.length === 0 ? (
        <Pagination setActivePage={setActivePage} activePage={activePage} totalPages={totalPages} />
      ) : null}
      <>
        <ModalPrevPDF
          show={show}
          dataTramite={dataTramite}
          handleCloseModal={handleCloseModal}
        />
      </>
    </>
  )
}
