/* eslint-disable react-hooks/exhaustive-deps */
import styled from '@emotion/styled'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Header } from './Header'
import { DefaultTitle } from './page-title/DefaultTitle'
import { Topbar } from './Topbar'

export function HeaderWrapper() {
  const { config, classes, attributes } = useLayout()
  const { header, aside } = config
  let fuente: any = ''
  const LightTooltip = styled(({ className, ...props }: TooltipProps) =>
    (<Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: `${fuente}`,
        color: `#FFFFFF`,
        fontSize: 12,
        fontWeightBold: 700,
        maxWidth: 200
      },
    }));
  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      data-kt-sticky='true'
      data-kt-sticky-name='header'
      data-kt-sticky-offset="{default: '200px', lg: '300px'}"
      {...attributes.headerMenu}
    >
      <div className={clsx(classes.headerContainer.join(' '), 'd-flex align-items-center')}>
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}

        <div
          className='d-flex topbar align-items-center d-lg-none ms-n2 me-3'
          title='Show aside menu'
        >
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x' />
          </div>
        </div>

        <LightTooltip className='negrita' children={
          <div className='header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0'>
            <Link to='/'>
              <>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/apex.svg')}
                  className='logo-default h-40px'
                />
                <img
                  alt='Logo'


                  src={toAbsoluteUrl('/media/logos/apex_gris.svg')}

                  className='logo-sticky h-35px'
                />
              </>
            </Link>
          </div>
        } title={<Typography color="inherit" style={{ fontWeight: 600, textTransform: 'capitalize', textAlign: 'center' }}>Menu Principal</Typography>} ></LightTooltip>
        <div className='d-flex justify-content-center flex-lg-grow-1'>
          <LightTooltip className='negrita' children={
            <div className='header-logo'>

              <>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/marcablanca.png')}
                  className='logo-default h-45px'
                />
                <img
                  alt='Logo'


                  src={toAbsoluteUrl('/media/logos/marcagris.png')}

                  className='logo-sticky h-45px'
                />
              </>

            </div>
          } title={<Typography color="inherit" style={{ fontWeight: 600, textTransform: 'capitalize', textAlign: 'center' }}>neuquen.gov.ar</Typography>} ></LightTooltip>
        </div>

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-end '>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
          <span className="badge">{process.env.REACT_APP_VERSION}</span>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
