import 'semantic-ui-css/semantic.min.css'
import React from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDroplet, faWind, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { toAbsoluteUrl } from '../../_metronic/helpers'

type Props = {
  className: string
  data: any
  icono: string
}
const Clima: React.FC<Props> = ({ className, data, icono }) => {
  return (
    <div>
      {typeof data.main != 'undefined' ? (
        <div className={`card sombras ${className}`}>
          <div className={`card-header border-0 py-1 `} style={{ backgroundColor: '#708090' }}>
            <h3 className='card-title align-items-start flex-column'>
              <span className=' mb-2 text-white fw-bolder mt-2'>Clima</span>
              <span className='text-white fw-bold fs-7'>Datos del Clima</span>
            </h3>
          </div>
          <div className='card-body p-2 pt-4'>
            <div className=''>
              <div className='col texto-centrado'>
                <FontAwesomeIcon className='pr-3' icon={faLocationDot} size='2x' />
                <span className='fs-2'>{data.name}</span>
              </div>
              <div className='row texto-centrado'>
                <span className=' mb-2 mt-2'>{moment().format('LL')} </span>
              </div>
            </div>
            <div className='col texto-centrado'>
              <div className='texto-centrado'>
                <div className='card align-items-center'>
                  <div
                    className='bgi-no-repeat bgi-position-center bgi-size-cover h-100px w-100px'
                    style={{
                      backgroundImage: `url('${toAbsoluteUrl(`${icono}`)}')`,
                    }}
                  ></div>
                </div>
                <span className='fuente-dia mb-2 mt-2'>{data.main.temp} &deg;C</span>
              </div>
            </div>
            <div className=' d-flex mb-8'>
              <div className='col texto-centrado'>
                <span className='fs-4 capital'>{data.weather[0].description}</span>
              </div>
            </div>
            <div className='  d-flex mb-8 '>
              <div className='col-2 texto-centrado'>
                <FontAwesomeIcon icon={faDroplet} size='1x' />
              </div>
              <div className='col-5 texto-izquierda'>
                <span className=' mb-2 mt-2 fs-7'>Humedad: </span>
              </div>
              <div className='col-5 texto-derecha pr-3'>
                <span className=' mb-2 mt-2'>{data.main.humidity} %</span>
              </div>
            </div>
            <div className=' d-flex mb-8 mb-4'>
              <div className='col-2 texto-centrado'>
                <FontAwesomeIcon icon={faWind} size='1x' />
              </div>
              <div className='col-4 texto-izquierda'>
                <span className=' mb-2 mt-2 fs-7'>Viento: </span>
              </div>
              <div className='col-6 texto-derecha pr-3'>
                <span className=' mb-2 mt-2'>{data.wind.speed} KM/H</span>
              </div>
            </div>

            {/* <Card.Header className="header">Ciudad: {data.name}</Card.Header> */}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}
export { Clima }
