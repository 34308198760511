import React from 'react'
import BotoneraCalendario from '../Botones/BotoneraCalendario'
type Props = {
  area: any
  modulo: any
}
const HeaderMisEventos: React.FC<Props> = ({ area, modulo }) => {
  ////////console.log('area', area)

  return (
    <div>
      <div className='card-header align-items-center border-1 bg-light-gray-500'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-blanco capital'>
            Listado de Actividades del Área: {area.nombreArea}
          </span>
        </h3>
        <BotoneraCalendario compActivo={'miagenda'} />
      </div>
      {/* <div className='card-header align-items-center border-0 pt-6'>
        <div className='d-flex align-items-center text-gray-400 text-hover-primary mb-4 '>

          <BotonAreas nombre={`${area.tipoArea} de ${area.nombreArea}`} nombreCorto={area.detalleSistema.nombreCorto} colorFondo={area.detalleSistema.colorFondo} compActivo={modulo} />

        </div>
        <div className='card-toolbar'>
          <BotonAgenda pag_inicio={'nuevoevento'} nombre='Nuevo evento' imagen='/general/gen035.svg' colorActivo='btn-success' colorFondo='btn-light-success' />
          <BotonAgenda pag_inicio={'indicadores'} nombre='Indicadores' imagen='/general/gen032.svg' colorActivo='btn-warning' colorFondo='btn-light-warning' />
        </div>
      </div> */}
    </div>
  )
}

export default HeaderMisEventos
