import {ITablaBens} from '../interfaces/ITablaBens'
import {BengAdminTypes} from './Bens-types'

export const tablaDatosBengAction = (tablaDatosBeng: ITablaBens) => ({
  type: BengAdminTypes.SetTabla,
  payload: {
    tablaDatosBeng,
  },
})

export const remTablaDatosBeng = () => ({
  type: BengAdminTypes.RemTabla,
})
