
import { calTypes } from "../types/types"

export const eventoActivo = (eventos: any) => ({
  type: calTypes.SetEventoActivo,
  payload: eventos,
});
export const eventoAgregar = (eventos: any) => ({
  type: calTypes.AddEventoNuevo,
  payload: eventos,
});
export const eventoCargar = (eventos: any) => ({
  type: calTypes.GetEventos,
  payload: eventos,
});
export const eventoCargarFull = (eventos: any) => ({
  type: calTypes.GetEventosFull,
  payload: eventos,
});
export const setEvFull = (eventos: any) => ({
  type: calTypes.SetEvFull,
  payload: eventos,
});
export const setEvPub = (eventos: any) => ({
  type: calTypes.SetEvPub,
  payload: eventos,
});
export const setEvFiltro = (eventos: any) => ({
  type: calTypes.SetEvFiltro,
  payload: eventos,
});
export const evCargarFiltro = (eventos: any) => ({
  type: calTypes.SetEvFiltro,
  payload: eventos,
});
export const evCargarPublicos = (eventos: any) => ({
  type: calTypes.SetEvPub,
  payload: eventos,
});
export const eventosCargarFecha = (eventos: any) => ({
  type: calTypes.SetFecha,
  payload: eventos,
});
export const eventosSetComponente = (eventos: any) => ({
  type: calTypes.SetComponente,
  payload: eventos,
});
export const evSetDias = (eventos: any) => ({
  type: calTypes.SetDias,
  payload: eventos,
});
export const eventosSetSeleccionado = (eventos: any) => ({
  type: calTypes.SetEventoSeleccionado,
  payload: eventos,
});
export const eventosSetAccion = (eventos: any) => ({
  type: calTypes.SetAcciones,
  payload: eventos,
});
export const evSetFormulario = (eventos: any) => ({
  type: calTypes.SetFormulario,
  payload: eventos,
});
export const setFiltros = (eventos: any) => ({
  type: calTypes.SetFiltros,
  payload: eventos,
});