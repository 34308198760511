import React from 'react'
import { useLoadScript } from '@react-google-maps/api'
import { MapaInd } from './MapaInd'
import { libraries } from '../../../../../../../services/gral/GeoLoc_Service'

const MapaGoogleIndicador = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    [libraries]: libraries,
  })

  if (!isLoaded) return <div>Cargando...</div>
  return (
    <MapaInd />
  )
}

export default MapaGoogleIndicador