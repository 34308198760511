import {ITematicaCivis} from '../interface/civis.interface'
import {Civistypes} from './Civis-types'

export const tematicaAction = (tematica: ITematicaCivis) => ({
  type: Civistypes.SetTematica,
  payload: {
    tematica,
  },
})

export const RemTematicaAction = () => ({
  type: Civistypes.RemTematica,
})

export const rsApiAction = (resData: any) => ({
  type: Civistypes.SetResApi,
  payload: {
    resData,
  },
})
export const remApiAction = () => ({
  type: Civistypes.RemResApi,
})

//  Estos son archivos de acción de Redux, que se utilizan para despachar acciones a un store Redux. Las acciones son objetos simples que describen lo que sucedió en la aplicación.

// Aca hay tres acciones definidas:

//     - tematicaAction: Esta acción toma un objeto ITematicaCivis y devuelve un objeto de acción que tiene una propiedad type con un valor Civistypes.SetTematica. Esta acción se utiliza para establecer la temática en el store de Redux.

//     - RemTematicaAction: Esta acción devuelve un objeto de acción que tiene una propiedad type con un valor Civistypes.RemTematica. Se utiliza para eliminar la temática del store de Redux.

//     - rsApiAction: Esta acción toma un objeto resData y devuelve un objeto de acción que tiene una propiedad type con un valor Civistypes.SetResApi. Esta acción se utiliza para establecer la respuesta de la API en el store de Redux.

//     - remApiAction: Esta acción devuelve un objeto de acción que tiene una propiedad type con un valor Civistypes.RemResApi. Se utiliza para eliminar la respuesta de la API del store de Redux.

// En general, estas acciones se utilizan para actualizar el estado de la aplicación en el store Redux. Una vez que se despacha una acción, el store Redux se actualiza y cualquier componente conectado al store que dependa de ese estado actualiza su renderizado. 