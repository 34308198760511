/** React */
import { useState } from 'react'
import {Link, useHistory, useLocation, useRouteMatch} from 'react-router-dom'
/** Formik */
import {Form, Formik} from 'formik'
/** Material & Sass */
import {Button} from '@mui/material'
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp'
import '../../../../../../src/_metronic/assets/sass/cud.scss'
/** Services */
import {updateLegajo} from '../services/cud-service'
/** Hook */
import {CreateModal2} from '../hooks/useModal'
/** Tables */
import { Documentacion} from '../components/Tablas/index'

export const DocumEdit = () => {
  const [btnEdit, setBtnEdit] = useState(false); 
  const location: any = useLocation(); 
  const history = useHistory();
  const handleBtn = ()=>{
    setBtnEdit(!btnEdit)
  }
  
  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
      <Link to={{pathname: '/cud/Legajos', state:{
                activado: true,
                obj: location.state
              },}} className='alineacionb'>
          <ArrowBackSharpIcon />
          </Link>
      </div>
      <div className='card-body py-1'>
        <div className='table-responsive'>
          <Formik
            initialValues={location.state}
            onSubmit={(values) => {
              updateLegajo(values).then((res: any) => {
                CreateModal2(history)
                window.history.back()
              })
            }}>
            {({handleChange, handleBlur}) => (
              <div>
                <Form>
                  <div>
                    <div className='col-8 text-gray-600 fw-bold mt-2 fs-6'>Documentacion Recibida</div>
                    <div className='card bg-ligth border mb-6 card_align5 '>
                      <Documentacion handleChange={handleChange} handleBlur={handleBlur} btnEdit={btnEdit} />
                    </div>
                    <div className='row card_align5'>
                      <div className='col-lg-8'>
                      <Button className='btn btn-primary' onClick={handleBtn}>
                          {btnEdit === false ? "Habilitar Edición" : "Desabilitar Edición"}
                        </Button>&nbsp;&nbsp;
                        {btnEdit === true ? (
                        <Button type='submit' className='btn btn-success' disabled={false}>
                          Guardar
                        </Button>
                          ): ""}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
