/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { useSelector } from 'react-redux'
import { ISTATE } from '../../../../../../../setup/redux/interface/reduxState.interface'
import { getCSS, getCSSVariableValue } from '../../../../../../../_metronic/assets/ts/_utils'
import { KTSVG } from '../../../../../../../_metronic/helpers/components/KTSVG'
import {
  ComposedChart,
  ResponsiveContainer,
  Line,
  Label,
  LabelList,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import moment from 'moment'

type Props = {
  className: string,
}

const IndCalculos: React.FC<Props> = ({ className }) => {
  const menuState: any = useSelector((state: ISTATE) => state.menuBoton)
  const { seleccion } = menuState
  const eventoState: any = useSelector((state: ISTATE) => state.eventos)
  const { evFiltro, filtros } = eventoState
  let datos: any = []
  let [areasTit, SetAreasTit] = useState<any>([])
  let [fechas, setFechas] = useState<any>()
  let serieRealizada: any = []
  let serieCancelada: any = []
  let serieEnCurso: any = []
  let seriePlanificada: any = []
  let serieTotal: any = []
  let titulos: any = []
  let inc = 0
  let planificadas = 0
  let encurso = 0
  let realizadas = 0
  let canceladas = 0
  let mayor = 0
  let totales = 0

  for (let a = 0; a < seleccion.length; a++) {
    const sel = seleccion[a];
    areasTit = []
    if (sel.selec) {
      serieRealizada[a] = 0
      serieCancelada[a] = 0
      serieEnCurso[a] = 0
      seriePlanificada[a] = 0
      serieTotal[a] = 0
      areasTit.push(sel.nombreCorto)
      for (let e = 0; e < evFiltro.length; e++) {
        const evento = evFiltro[e];
        if (evento.idArea === sel.id) {
          switch (evento.estado) {
            case 'realizado':
              serieRealizada[a] = serieRealizada[a] + 1
              realizadas = realizadas + 1
              break;
            case 'cancelado':
              serieCancelada[a] = serieCancelada[a] + 1
              canceladas = canceladas + 1
              break;
            case 'planificado':
              seriePlanificada[a] = seriePlanificada[a] + 1
              planificadas = planificadas + 1
              break;
            case 'en curso':
              serieEnCurso[a] = serieEnCurso[a] + 1
              encurso = encurso + 1
              break;
            default:
              break;
          }
          totales = totales + 1
        }
      }
      serieTotal[a] = serieRealizada[a] + serieCancelada[a] + serieEnCurso[a] + seriePlanificada[a]
      inc = inc + 1
    }
    for (let t = 0; t < serieTotal.length; t++) {
      const tot = serieTotal[t];
      if (tot >= mayor) { mayor = tot }
    }


  }

  const Calculos = () => {
    inc = 0
    areasTit = []
    for (let a = 0; a < seleccion.length; a++) {
      const sel = seleccion[a];
      serieRealizada[a] = 0
      serieCancelada[a] = 0
      serieEnCurso[a] = 0
      seriePlanificada[a] = 0
      serieTotal[a] = 0

      if (sel.selec) {

        areasTit.push(sel.nombreCorto)
        for (let e = 0; e < evFiltro.length; e++) {
          const evento = evFiltro[e];
          if (evento.idArea === sel.id) {
            switch (evento.estado) {
              case 'realizado':
                serieRealizada[a] = serieRealizada[a] + 1
                realizadas = realizadas + 1
                break;
              case 'cancelado':
                serieCancelada[a] = serieCancelada[a] + 1
                canceladas = canceladas + 1
                break;
              case 'planificado':
                seriePlanificada[a] = seriePlanificada[a] + 1
                planificadas = planificadas + 1
                break;
              case 'en curso':
                serieEnCurso[a] = serieEnCurso[a] + 1
                encurso = encurso + 1
                break;
              default:
                break;
            }
          }
        }
        serieTotal[a] = serieRealizada[a] + serieCancelada[a] + serieEnCurso[a] + seriePlanificada[a]
        let datosChart = {
          name: sel.nombreCorto,
          Realizados: serieRealizada[a],
          Cancelados: serieCancelada[a],
          "En Curso": serieEnCurso[a],
          Planificados: seriePlanificada[a],
          Totales: serieTotal[a],
        }
        datos.push(datosChart)
        inc = inc + 1
      }
      for (let t = 0; t < serieTotal.length; t++) {
        const tot = serieTotal[t];
        if (tot >= mayor) { mayor = tot }
      }


    }
    return datos
  }



  useEffect(() => {
    Calculos()
  }, [])

  useEffect(() => {
    if (filtros.fechaInicio === '') {
      //console.log('iguales')
      setFechas(`Comprendidos desde el 01/05/2022 hasta hoy ${moment(new Date()).format('DD/MM/YYYY')}`)
    } else {
      if (moment(filtros.fechaInicio).format('DD/MM/YYYY') === moment(filtros.fechaFin).format('DD/MM/YYYY')) {
        //console.log('Entro')
        setFechas(`Hoy ${moment(new Date()).format('DD/MM/YYYY')}`)
      }
      if (moment(filtros.fechaInicio).format('DD/MM/YYYY') !== moment(filtros.fechaFin).format('DD/MM/YYYY')) {
        //console.log('Entro Diferentes')
        setFechas(`Desde el ${moment(filtros.fechaInicio).format('DD/MM/YYYY')} - Hasta el ${moment(filtros.fechaFin).format('DD/MM/YYYY')}`)
      }
    }


  }, [filtros])
  useEffect(() => {

    //console.log('Entro Fechas')

  }, [fechas])


  return (<>
    {/* begin::Body */}
    <div className='card-body'>
      <div className='d-flex-i pt-0 mb-6' style={{ height: '80px' }}>
        {/* begin::Item */}
        <div className="box pr-1">
          <div className=' d-flex align-items-center bg-light-primary rounded p-3' style={{ height: '80px' }}>
            {/* begin::Icon */}
            <span className='svg-icon svg-icon-primary me-5'>
              <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-indicador' />
            </span>
            {/* end::Icon */}
            {/* begin::Title */}
            <div className='flex-grow-1 me-2'>
              <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                Totales
              </a>
            </div>
            {/* end::Title */}
            {/* begin::Lable */}
            <span className='fw-bolder fuenteIndicador text-primary py-1'>{totales}</span>
            {/* end::Lable */}
          </div>
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className="box align-items-center pr-1">

          <div className='d-flex align-items-center bg-light-success rounded p-3' style={{ height: '80px' }}>
            {/* begin::Icon */}
            <span className='svg-icon svg-icon-success me-5'>
              <KTSVG path='/media/icons/duotune/general/gen037.svg' className='svg-icon-indicador' />
            </span>
            {/* end::Icon */}
            {/* begin::Title */}
            <div className='flex-grow-1 me-2'>
              <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                Realizadas
              </a>
            </div>
            {/* end::Title */}
            {/* begin::Lable */}
            <span className='fw-bolder fuenteIndicador text-success py-1'>{realizadas}</span>
            {/* end::Lable */}
          </div>
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className="box pr-1">

          <div className='d-flex align-items-center bg-light-info rounded p-3' style={{ height: '80px' }}>
            {/* begin::Icon */}
            <span className='svg-icon svg-icon-info me-5'>
              <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-indicador' />
            </span>
            {/* end::Icon */}
            {/* begin::Title */}
            <div className='flex-grow-1 me-2'>
              <a href='#' className='fw-bolder text-gray-800 text-hover-info fs-6'>
                En Curso
              </a>
            </div>
            {/* end::Title */}
            {/* begin::Lable */}
            <span className='fw-bolder fuenteIndicador text-info py-1'>{encurso}</span>
            {/* end::Lable */}
          </div>
        </div>
        <div className="box pr-1">

          <div className='d-flex align-items-center bg-light-warning rounded p-3' style={{ height: '80px' }}>
            {/* begin::Icon */}
            <span className='svg-icon svg-icon-warning me-5'>
              <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-indicador' />
            </span>
            {/* end::Icon */}
            {/* begin::Title */}
            <div className='flex-grow-1 me-2'>
              <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                Planificadas
              </a>
            </div>
            {/* end::Title */}
            {/* begin::Lable */}
            <span className='fw-bolder fuenteIndicador text-warning py-1'>{planificadas}</span>
            {/* end::Lable */}
          </div>
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className="box">

          <div className='d-flex align-items-center bg-light-danger rounded p-3' style={{ height: '80px' }}>
            {/* begin::Icon */}
            <span className='svg-icon  svg-icon-danger me-5'>
              <KTSVG path='/media/icons/duotune/general/gen034.svg' className='svg-icon-indicador' />
            </span>
            {/* end::Icon */}
            {/* begin::Title */}
            <div className='flex-grow-1 me-2'>
              <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                Canceladas
              </a>
            </div>
            {/* end::Title */}
            {/* begin::Lable */}
            <span className='fw-bolder fuenteIndicador text-danger py-1'>{canceladas}</span>
            {/* end::Lable */}
          </div>
        </div>
        {/* end::Item */}
      </div>
      {/* begin::Chart */}
      {/* end::Chart */}
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          data={Calculos()}
          margin={{
            top: 60,
            right: 20,
            bottom: 10,
            left: 10
          }}
        >
          <CartesianGrid stroke="#e1e2e3" strokeDasharray="5 5" />

          <XAxis dataKey="name" scale="band">
            <Label value={`Filtro de todos los Eventos de ${areasTit}`} offset={360} position="insideBottom" />
            <Label value={`${fechas}`} offset={340} position="insideBottom" />
          </XAxis>
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Realizados" barSize={30} fill="#50cd89" fillOpacity="50%" stroke="#50cd89" strokeWidth={2} strokeOpacity="100%" />
          <Bar dataKey="Planificados" barSize={30} fill="#FFC700" fillOpacity="50%" stroke="#FFC700" strokeWidth={2} strokeOpacity="100%" />
          <Bar dataKey="En Curso" barSize={30} fill="#7239EA" fillOpacity="50%" stroke="#7239EA" strokeWidth={2} strokeOpacity="100%" />
          <Bar dataKey="Cancelados" barSize={30} fill="#f1436e" fillOpacity="50%" stroke="#f1436e" strokeWidth={2} strokeOpacity="100%" />
          <Line type="monotone" dataKey="Totales" stroke="#000000" strokeWidth={2}><LabelList dataKey="Totales" position="top" /></Line>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
    {/* end::Body */}
  </>
  )
}

export { IndCalculos }


