import React, {FC, useState} from 'react'
import {Formik, Form, FormikValues, Field} from 'formik'
import {KTSVG} from '../../../../../_metronic/helpers/components/KTSVG'
import {useHistory, useLocation} from 'react-router'
import {Ibens} from '../interfaces/Ibens'
import {
  aprobarBensHacienda,
  getImgins,
  getpdfIns,
  patchAprobarBens,
  UpdateBens,
} from '../services/Bens-Services'
import clsx from 'clsx'
import {ICreateAccount} from '../interfaces/EditInscripcionHeper'
import {getLayout, ILayout, LayoutSetup, useLayout} from '../../../../../_metronic/layout/core'
import Swal from 'sweetalert2'
import moment from 'moment'
import {Link} from 'react-router-dom'
import styled from '@emotion/styled'
import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip'
import {Typography} from '@mui/material'

const Horizontal: FC = () => {
  const {setLayout} = useLayout()
  let data: any = useLocation()

  let dataVuelta: any = []
  dataVuelta.push(data.state.objet)
  let titulo = data.state.tituloMenu
  let tituloTabla = data.state.tituloTabla
  const [disabled, setDisable] = useState<boolean>(true)
  const [objetPreview] = useState<ICreateAccount>(data.state.objet)

  const [tab, setTab] = useState('DatosPersonales')
  const [config, setConfig] = useState<ILayout>(getLayout())
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  const tokenBens: any = sessionStorage.getItem('BensToken')
  //Calculo años alumno
  let anios = moment().diff(moment(objetPreview.fechanac, 'DD/MM/YYYY'), 'years')
  //Loading config
  const updateConfig = () => {
    setConfigLoading(true)
    try {
      LayoutSetup.setConfig(config)
    } catch (error) {
      setConfig(getLayout())
    }
    setTimeout(() => {
      setLayout(config)
      setConfigLoading(false)
    }, 1000)
  }
  //Aprobar Inscripcion (bens)
  const AprobarBens = (bens: string, id_tramite: string) => {
    const sendInsBens = {
      dni: bens,
      mod: 'modAprobBens',
      estado: 'Aprobado',
    }
    //Send inscripcion API
    patchAprobarBens(sendInsBens).then(async (res: any) => {
      if (res.data.ok === true) {
        aprobarBensHacienda(id_tramite, tokenBens).then((res: any) => {
          console.log('aprobarrr', res)
        })
        await Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Aprobado',
          showConfirmButton: false,
          timer: 2500,
        })
      }
    })
  }
  //Aletr pregunta Inscripcion
  const AlertAprobarBens = (id_tramite: string) => {
    Swal.fire({
      title: '¿Aprobar la Inscripcion?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Aprobar!',
    }).then((result) => {
      if (result.isConfirmed) {
        AprobarBens(objetPreview!.dni, id_tramite)
      }
    })
  }

  //Download PDF inscripcion
  const vewPdf = (bens: any) => {
    let nroTramiteBens = bens
    getpdfIns(nroTramiteBens, tokenBens).then((res) => {
      //console.log('🚀 ~ file: VerInscripcion.tsx:88 ~ getpdfIns ~ res:', res)
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      //  link.setAttribute('download', `${bens}.pdf`) =>Descargar a la pc
      link.setAttribute('download', `${bens}.pdf`)
      document.body.appendChild(link)
      link.click()
    })
  }
  //Download cert escolaridad
  const vewImg = (bens: any) => {
    let nroTramiteBens = bens
    getImgins(nroTramiteBens).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${bens}.png`)
      document.body.appendChild(link)
      link.click()
    })
  }

  const submitStep = (values: Ibens, actions: FormikValues) => {
    UpdateBens(values)
  }
  let fuente: any = ''
  const LightTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} placement='right' />
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${fuente}`,
      color: `#FFFFFF`,
      fontSize: 12,
      fontWeightBold: 700,
      maxWidth: 200,
    },
  }))

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          {/* Nav top bar */}
          <ul
            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link`, {active: tab === 'DatosPersonales'})}
                onClick={() => setTab('DatosPersonales')}
                role='tab'
              >
                Datos Personales
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link`, {active: tab === 'LocalidadDomicilio'})}
                onClick={() => setTab('LocalidadDomicilio')}
                role='tab'
              >
                Localidad y domicilio
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link`, {active: tab === 'Inscripcion'})}
                onClick={() => setTab('Inscripcion')}
                role='tab'
              >
                Inscripcion
              </a>
            </li>
          </ul>
        </div>
        <Formik initialValues={objetPreview} onSubmit={submitStep}>
          {(Formik) => {
            return (
              <Form>
                <div className='card-body'>
                  <div className='tab-content pt-3'>
                    {/* TAB Datos personales */}
                    <div className={clsx('tab-pane', {active: tab === 'DatosPersonales'})}>
                      <div className='row mb-10'>
                        <label className='col-lg-3 col-form-label text-lg-end'>
                          Nombre completo:
                        </label>
                        <div className='col-lg-9 col-xl-3'>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              disabled={disabled}
                              type='text'
                              className='form-control form-control-solid'
                              name='nombre'
                            />
                          </label>
                        </div>
                        <label className='col-lg-1 col-form-label text-lg-end'>Apellido:</label>
                        <div className='col-lg-9 col-xl-3'>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              disabled={disabled}
                              type='text'
                              name='apellido'
                              className='form-control form-control-solid'
                            />
                          </label>
                        </div>
                      </div>
                      <div className='row mb-10'>
                        <label className='col-lg-3 col-form-label text-lg-end'>DNI:</label>
                        <div className='col-lg-9 col-xl-3'>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              disabled={disabled}
                              name='dni'
                              type='text'
                              className='form-control form-control-solid'
                            />
                          </label>
                        </div>
                        <label className='col-lg-1 col-form-label text-lg-end'>Teléfono:</label>
                        <div className='col-lg-9 col-xl-3'>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              disabled={disabled}
                              type='text'
                              name='telefono'
                              className='form-control form-control-solid'
                            />
                          </label>
                        </div>
                      </div>
                      <div className='row mb-10'>
                        <label className='col-lg-3 col-form-label text-lg-end'>Correo:</label>
                        <div className='col-lg-9 col-xl-3'>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              disabled={disabled}
                              type='text'
                              className='form-control form-control-solid'
                              name='correo'
                            />
                          </label>
                        </div>
                        <label className='col-lg-1 col-form-label text-lg-end'>Género:</label>
                        <div className='col-lg-9 col-xl-3'>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              disabled={disabled}
                              type='text'
                              name='sexo'
                              className='form-control form-control-solid'
                            />
                          </label>
                        </div>
                      </div>
                      <div className='row mb-10'>
                        <label className='col-lg-3 col-form-label text-lg-end'>
                          Fecha de Nacimiento:
                        </label>
                        <div className='col-lg-9 col-xl-3'>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              disabled={disabled}
                              type='text'
                              name='fechanac'
                              className='form-control form-control-solid'
                            />
                          </label>
                        </div>
                        <label className='col-lg-1 col-form-label text-lg-end'>Edad:</label>
                        <div className='col-lg-9 col-xl-3'>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              disabled={disabled}
                              type='text'
                              value={`${anios} años`}
                              className='form-control form-control-solid'
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* TAB datos de Vivienda */}
                    <div className={clsx('tab-pane', {active: tab === 'LocalidadDomicilio'})}>
                      <div className='row mb-10'>
                        <label className='col-lg-3 col-form-label text-lg-end'>Localidad</label>
                        <div className='col-lg-9 col-xl-3'>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              disabled={disabled}
                              value={objetPreview.localidad}
                              type='text'
                              className='form-control form-control-solid'
                            />
                          </label>
                        </div>
                        <label className='col-lg-1 col-form-label text-lg-end'>
                          Localidad actual:
                        </label>
                        <div className='col-lg-9 col-xl-3'>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              type='text'
                              name='localidadActual'
                              className='form-control form-control-solid'
                            />
                          </label>
                        </div>
                      </div>
                      <div className='row mb-10'>
                        <label className='col-lg-3 col-form-label text-lg-end'>
                          Domicilio legal
                        </label>
                        <div className='col-lg-9 col-xl-3'>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              disabled={disabled}
                              name='domicilio'
                              type='text'
                              className='form-control form-control-solid'
                            />
                          </label>
                        </div>
                        <label className='col-lg-1 col-form-label text-lg-end'>
                          Domicilio actual
                        </label>
                        <div className='col-lg-9 col-xl-3'>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              disabled={disabled}
                              type='text'
                              className='form-control form-control-solid'
                              name='domicilioActual'
                            />
                          </label>
                        </div>
                      </div>
                      <div className='row mb-10'>
                        <label className='col-lg-3 col-form-label text-lg-end'>Monoblock</label>
                        <div className='col-lg-9 col-xl-3'>
                          <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                            <Field
                              disabled={disabled}
                              name='monoblock'
                              type='text'
                              className='form-control form-control-solid'
                            />
                          </label>
                        </div>

                        <div className='col-lg-9 col-xl-4 text-lg-center'>
                          <button
                            className='btn btn-primary me-2'
                            /*  onClick={() => vewImg(objetPreview.institucion.idarchivo)} */
                          >
                            Certificado de alumno Regular
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr044.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* TAB datos inscripcion  */}
                    <div className={clsx('tab-pane', {active: tab === 'Inscripcion'})}>
                      <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                          {/* begin::Table head */}
                          <thead>
                            <tr className='fw-bolder text-muted'>
                              <th className='w-25px'>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value='1'
                                    data-kt-check='true'
                                    data-kt-check-target='.widget-13-check'
                                  />
                                </div>
                              </th>
                              <th className='min-w-150px'>Institución</th>
                              {/* <th className='min-w-140px'>Localidad</th> */}
                              <th className='min-w-120px'>Transporte</th>
                              <th className='min-w-120px'>Horarios</th>
                              <th className='min-w-100px text-end'>Actions</th>
                            </tr>
                          </thead>
                          {/* end::Table head */}
                          {/* begin::Table body */}
                          <tbody>
                            {objetPreview.institucion.map((inst: any, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                      <input
                                        className='form-check-input widget-13-check'
                                        type='checkbox'
                                        value='1'
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className='text-dark fw-bolder text-hover-primary fs-6'>
                                      {inst.institucion}
                                    </div>
                                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                                      {inst.localidad}
                                    </span>
                                  </td>
                                  {/*  <td>
                                    <div className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                                      {inst.localidad}
                                    </div>
                                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                                      Code: PH
                                    </span>
                                  </td> */}
                                  <td>
                                    <div className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                                      {inst.tranporte}
                                    </div>
                                  </td>
                                  <td>
                                    <div className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                                      Mañana
                                    </div>

                                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                                      {`${inst.horarios?.manana[0] || ''} 
                                        ${inst.horarios?.manana[1] || ''} 
                                        ${inst.horarios?.manana[2] || ''} 
                                        ${inst.horarios?.manana[3] || ''} 
                                        ${inst.horarios?.manana[4] || ''} 
                                        ${inst.horarios?.manana[5] || ''} 
                                        ${inst.horarios?.manana[6] || ''} 
                                        ${inst.horarios?.manana[7] || ''}
                                      
                                      `}
                                    </span>
                                    <div className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                                      Tarde
                                    </div>
                                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                                      {`${inst.horarios?.tarde[0] || ''} 
                                        ${inst.horarios?.tarde[1] || ''} 
                                        ${inst.horarios?.tarde[2] || ''} 
                                        ${inst.horarios?.tarde[3] || ''} 
                                        ${inst.horarios?.tarde[4] || ''} 
                                        ${inst.horarios?.tarde[5] || ''} 
                                        ${inst.horarios?.tarde[6] || ''} 
                                        ${inst.horarios?.tarde[7] || ''}
                                      
                                      `}
                                    </span>
                                    <div className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                                      Noche
                                    </div>
                                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                                      {`${inst.horarios?.noche[0] || ''} 
                                      ${inst.horarios?.noche[1] || ''} 
                                      ${inst.horarios?.noche[2] || ''} 
                                      ${inst.horarios?.noche[3] || ''} 
                                      ${inst.horarios?.noche[4] || ''} 
                                      ${inst.horarios?.noche[5] || ''} 
                                      ${inst.horarios?.noche[6] || ''} 
                                      ${inst.horarios?.noche[7] || ''}
                                      
                                      `}
                                    </span>

                                    <div className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                                      Todo
                                    </div>
                                    <span className='text-muted fw-bold text-muted d-block fs-7'>
                                      {`${inst.horarios?.todo[0] || ''} 
                                      ${inst.horarios?.todo[1] || ''} 
                                      ${inst.horarios?.todo[2] || ''} 
                                      ${inst.horarios?.todo[3] || ''} 
                                      ${inst.horarios?.todo[4] || ''} 
                                      ${inst.horarios?.todo[5] || ''} 
                                      ${inst.horarios?.todo[6] || ''} 
                                      ${inst.horarios?.todo[7] || ''}
                                      
                                      `}
                                    </span>
                                  </td>
                                  <td className='text-end'>
                                    <LightTooltip
                                      className='negrita'
                                      children={
                                        <div
                                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                          onClick={() => vewPdf(objetPreview.id_tramite.toString())}
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/arrows/arr044.svg'
                                            className='svg-icon-3'
                                          />
                                        </div>
                                      }
                                      title={
                                        <Typography
                                          color='inherit'
                                          style={{
                                            fontWeight: 600,
                                            textTransform: 'capitalize',
                                            textAlign: 'center',
                                          }}
                                        >
                                          Descargar PDF
                                        </Typography>
                                      }
                                    ></LightTooltip>

                                    {/*  <div
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      onClick={() => vewImg(inst.id_archivo)}
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/arrows/arr044.svg'
                                        className='svg-icon-3'
                                      />
                                    </div> */}
                                    {/* Boton para Aprobar!!! */}
                                    {/*  <LightTooltip
                                      className='negrita'
                                      children={
                                        <div
                                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                          onClick={() =>
                                            AlertAprobarBens(objetPreview.id_tramite.toString())
                                          }
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen026.svg'
                                            className='svg-icon-3'
                                          />
                                        </div>
                                      }
                                      title={
                                        <Typography
                                          color='inherit'
                                          style={{
                                            fontWeight: 600,
                                            textTransform: 'capitalize',
                                            textAlign: 'center',
                                          }}
                                        >
                                          Aprobar Solicitud
                                        </Typography>
                                      }
                                    ></LightTooltip> */}
                                  </td>
                                </tr>
                              )

                              /*{' '}
                                <div className='row mb-10'>
                                  <label className='col-lg-3 col-form-label text-lg-end'>
                                    Transporte:
                                  </label>
                                  <div className='col-lg-9 col-xl-7'>
                                    <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                                      <Field
                                        disabled={disabled}
                                        name='inst.tranporte'
                                        value={inst.tranporte}
                                        type='text'
                                        className='form-control form-control-solid'
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className='row mb-10'>
                                  <label className='col-lg-3 col-form-label text-lg-end'>
                                    Institución:
                                  </label>
                                  <div className='col-lg-9 col-xl-7'>
                                    <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                                      <Field
                                        disabled={disabled}
                                        name='inst.institucion'
                                        type='text'
                                        className='form-control form-control-solid'
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className='row mb-10'>
                                  <label className='col-lg-3 col-form-label text-lg-end'>
                                    Fecha inscripción:
                                  </label>
                                  <div className='col-lg-9 col-xl-2'>
                                    <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                                      <Field
                                        disabled={disabled}
                                        name='inst.fecha_inscripcion'
                                        type='text'
                                        className='form-control form-control-solid'
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className='row mb-10'>
                                  <label className='col-lg-3 col-form-label text-lg-end'>
                                    Horarios:
                                  </label>
                                  <div className='col-lg-9 col-xl-2'>
                                    <label className='text-lg-end'>Mañana:</label>

                                    <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                                      <Field
                                        value={inst.horarios.manana}
                                        type='text'
                                        className='form-control form-control-solid'
                                      />
                                    </label>
                                  </div>
                                  <div className='col-lg-9 col-xl-2'>
                                    <label htmlFor=''>Tarde:</label>
                                    <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                                      <Field
                                        value={inst.horarios.tarde}
                                        type='text'
                                        className='form-control form-control-solid'
                                      />
                                    </label>
                                  </div>
                                  <div className='col-lg-9 col-xl-2'>
                                    <label htmlFor=''>Noche:</label>

                                    <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                                      <Field
                                        value={inst.horarios.noche}
                                        type='text'
                                        className='form-control form-control-solid'
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className='row mb-10'>
                                  <div className='col-4  center-btn-pdf'>
                                    <label className='form-check form-check-custom form-check-solid form-switch mb-5'>
                                      <button
                                        className='btn btn-primary me-2'
                                        onClick={() => vewPdf(objetPreview.id_tramite)}
                                      >
                                        PDF
                                        <KTSVG
                                          path='/media/icons/duotune/arrows/arr044.svg'
                                          className='svg-icon-3 ms-2 me-0'
                                        />
                                      </button>
                                    </label>
                                  </div>
                                  <div className='col-4  center-btn-pdf'>
                                    {objetPreview.estado === 'Aprobado' ? null : (
                                      <button
                                        onClick={() => AlertAprobarBens()}
                                        className='btn btn-success me-2'
                                      >
                                        Aprobar
                                      </button>
                                    )}
                                  </div>
                                </div>{' '}
                                */
                            })}
                          </tbody>
                          {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card-footer py-6'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <Link
                        to={{
                          pathname: '/bens/bens',
                          state: {titulo, data: dataVuelta, tituloTabla},
                        }}
                      >
                        <button className='btn btn-primary me-2'>Volver</button>
                      </Link>
                    </div>
                    {titulo === 'Aprobados' ? null : (
                      <div className='col-lg-2 btn-center-left'>
                        {disabled ? (
                          <button
                            className='btn btn-primary me-2'
                            onClick={() => setDisable(false)}
                          >
                            ¿Editar?
                          </button>
                        ) : (
                          <button
                            type='submit'
                            onClick={updateConfig}
                            className='btn btn-primary me-2'
                          >
                            {!configLoading && <span className='indicator-label'>Actualizar</span>}
                            {configLoading && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                Espere por favor...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export {Horizontal}
