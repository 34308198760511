import styled from '@emotion/styled'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { ISTATE } from '../../../../../../setup/redux/interface/reduxState.interface'
import { seleccionMenu } from '../../Redux/actions/menuActions'
import './BotonAgenda.scss'

type Props = {
  nombre?: string
  nombreCorto?: string
  colorFondo?: string
  id?: string
  compActivo?: string
}
const ButtonMarginClass = 'ms-1 ms-lg-3',
  ButtonHeightClass = 'w-80px h-30px w-md-80px h-md-40px'

const BotonAreas: React.FC<Props> = ({ nombre, nombreCorto, colorFondo, id, compActivo }) => {
  const dispatch = useDispatch()
  const menuBotonState: any = useSelector((state: ISTATE) => state.menuBoton)
  const { seleccion } = menuBotonState
  ////////console.log('id', id)

  const seleccionar = () => {
    let temp: any = []
    seleccion.map((sel: any) => {
      if (nombre === 'Todas') {
        sel.selec = true
      }
      if (nombre === 'Ninguna') {
        sel.selec = false
      }
      if (id === sel.id) {
        sel.selec = !sel.selec
      }
      temp.push(sel)
    })
    dispatch(seleccionMenu(temp))
  }

  const botonMenu = (col: any) => {
    ////////console.log('id', id)
    seleccion.map((sel: any) => {
      if (sel.id === id) {
        col.selec = sel.selec
      } /* else {
        col.selec = true
      } */
    })
    if (col.modulo === 'miseventos') {
      return {
        backgroundColor: `${col.colorFondo}`,
        color: '#4e4e4e',
      }
    } else {
      if (col.selec) {
        return {
          backgroundColor: `${col.colorFondo}`,
          color: '#4e4e4e',
        }
      } else {
        return {
          backgroundColor: `#f5f5f5`,
          color: '#4e4e4e',
        }
      }
    }
  }
  let fuente: any = ''
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement='top' />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${fuente}`,
      color: `#FFFFFF`,
      fontSize: 12,
      fontWeightBold: 700,
      maxWidth: 200,
    },
  }))
  return (
    <div className={``}>
      {/*  <LightTooltip
        className='negrita'
        children={ */}
      <div className={clsx('d-flex align-items-center', ButtonMarginClass)}>
        <div
          className={clsx(`btn btn-icon  position-relative fs-8`, ButtonHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          onClick={() => {
            seleccionar()
          }}
          style={botonMenu({ colorFondo: colorFondo, modulo: compActivo })}
        >
          <p className='p-1'>{nombreCorto}</p>
        </div>
      </div>
      {/* }
        title={
          <Typography
            color='inherit'
            style={{ fontWeight: 600, textTransform: 'capitalize', textAlign: 'center' }}
          >
            {nombre}
          </Typography>
        }
      ></LightTooltip> */}
    </div>
  )
}
export { BotonAreas }
