import Swal from 'sweetalert2'
import {ICreateModulo} from '../../interfaces/ICreateModulo'
import {postModulo, updateModulo} from '../../services/ModuleService'

export const callApi = async (update: boolean, value: ICreateModulo) => {
  if (update) {
    return Swal.fire({
      title: 'Esta por actualizar el Modulo.',
      text: '¿Desea continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar!',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        return updateModulo(value).then((resp: any) => {
          return resp
        })
      }
    })
  } else {
    return postModulo(value).then((resp: any) => {
      return resp
    })
  }
}
