/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Field, ErrorMessage} from 'formik'
import TextErrorFormik from '../../../../../../components/Formik/TextErrorFormik'
import FormikControl from '../../../../../../components/Formik/FormikControl'
import {localidadesNqn} from '../../../../../../services/gral/proLocService'

const Step1Org: FC = () => {
  const [locNqn, setlocNqn] = useState([])

  useEffect(() => {
    localidadesNqn().then((resp: any) => {
      setlocNqn(resp.data.data)
    })

    return () => {}
  }, [])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Complete los datos.
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Es necesario contar con la fecha de creacion del ministerio antes de continuar.
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Nombre del Ministerio</label>
            <Field
              type='text'
              name='nombre'
              placeholder='Nombre'
              className='form-control form-control-solid'
            />
            <ErrorMessage name='nombre' component={TextErrorFormik} />
          </div>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Nombre del Ministro/a</label>
            <Field
              type='text'
              name='nombreMinistro'
              className='form-control form-control-solid'
              placeholder='Nombre Completo'
            />

            <ErrorMessage name='nombreMinistro' component={TextErrorFormik} />
          </div>
          <div className='col-lg-3'>
            <label className='form-label fw-bolder text-dark fs-f'>Fecha de Alta</label>
            <FormikControl
              control='date'
              name='fechaAlta'
              className='form-control form-control-solid'
            />
            <ErrorMessage name='fechaAlta' component={TextErrorFormik} />
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-lg-4'>
            <FormikControl
              control='select'
              label='Localidad'
              name='direccion.localidad'
              options={locNqn}
            />
          </div>

          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Calle</label>
            <Field
              name='direccion.calle'
              type='text'
              className='form-control form-control-solid'
              placeholder='Calle'
            ></Field>
            <ErrorMessage name='direccion.calle' component={TextErrorFormik} />
          </div>

          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>Número</label>
            <Field
              name='direccion.numero'
              type='text'
              className='form-control form-control-solid'
              placeholder='Nro..'
            ></Field>
            <ErrorMessage name='direccion.numero' component={TextErrorFormik} />
          </div>

          <div className='col-lg-2'>
            <label className='form-label fw-bolder text-dark fs-6'>C.Postal</label>
            <Field
              name='direccion.cp'
              type='text'
              className='form-control form-control-solid'
              placeholder='Nro..'
            ></Field>
            <ErrorMessage name='direccion.cp' component={TextErrorFormik} />
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-lg-6'>
            <label className='form-label fw-bolder text-dark fs-f'>Correo electrónico</label>
            <Field
              type='email'
              name='email'
              className='form-control form-control-solid'
              placeholder='email'
            />
            <ErrorMessage name='email' component={TextErrorFormik} />
          </div>
          <div className='col-lg-6'>
            <label className='form-label fw-bolder text-dark fs-f'>Número de Teléfono</label>
            <Field
              type='text'
              name='telefono'
              placeholder='Número de telefono'
              className='form-control form-control-solid'
            />
            <ErrorMessage name='telefono' component={TextErrorFormik} />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1Org}
