/** React */
import React, {useState, useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
/** Interfaces */
import {Idatos} from '../../../Cud/Interfaces/Datos-interface'
// import {Ijuntas} from '../../../Cud/Interfaces/Datos-interface'
/** Tabla */
import {DataJuntas} from '../../../Cud/components/Tablas/DataJuntas'
/** Services */
import {getJuntas} from '../../../Cud/services/cud-service'
import Spinner from '../../../../../components/spinner/Spinner'
import { Ijuntas } from '../../../Cud/Interfaces'

export const Legajos: React.FC<Idatos> = ({className, setRouteTitle}) => {
  let titlePage = 'Juntas'

  useEffect(() => {
    setRouteTitle('')
  }, [])

  const [juntas, setJuntas] = useState<Ijuntas>([])
  const [activePage, setActivePage] = useState(1)
  const rowsPerPage = 4
  const count = juntas.length
  const totalPages = Math.ceil(count / rowsPerPage)
  const calculatedRows = juntas.slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)
  const [loading, setLoading] = useState<any>(true)


  useEffect(() => {
    getJuntas().then((res: any) => {
      res.data && setJuntas(res.data.juntas)
      setTimeout(()=>{
        setLoading(false)
      },2300)
    
    })
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className='card-header'>
        <h5 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-5 mb-1 capital'>Legajos</span>
        </h5>
        <div className='card-toolbar'>
          {/* <Link to={'/cud/Juntas'} className='alineacionb'>
            <button className='btn btn-sm btn-light-primary menu-btn-cud'>Crear Junta</button>
          </Link> */}
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
        
{loading === true ? (
            <div className='col-lg-12 elemento_centro'>
            <Spinner></Spinner>
            <p><span><b>Cargando Información...</b></span></p>
            </div>


):(

          <DataJuntas
            junta={calculatedRows}
            activePage={activePage}
            totalPages={totalPages}
            setActivePage={setActivePage}
          />
)}
        </div>
      </div>
    </div>
  )
}
