import React from 'react'
import MapaGoogleInd from './MapaGoogle/MapaGoogleInd'

export const MapaIndicadores = () => {
  return (
    <div className='mb-5 fv-row align-items-center '>
      <div className='googlemap-container'>
        <MapaGoogleInd />
      </div>
    </div>
  )
}
