import { FC, useEffect, useRef, useState } from 'react'
import { Form, Formik, FormikValues } from 'formik'
import Swal from 'sweetalert2'

import { KTSVG } from '../../../../../_metronic/helpers'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { guardarEvento } from '../Redux/services/Agenda_Service'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { eventoSchemas, evInit, IEventos } from './CrearEventoPage/Steps/EventoData'
import HeaderCargarEvento from '../components/Headers/HeaderCargarEvento'
import { Step1 } from './CrearEventoPage/Steps/CargarEvento/Step1'
import { Step2 } from './CrearEventoPage/Steps/CargarEvento/Step2'
import { Step3 } from './CrearEventoPage/Steps/CargarEvento/Step3'
import { Step4 } from './CrearEventoPage/Steps/CargarEvento/Step4'
import { Step5 } from './CrearEventoPage/Steps/CargarEvento/Step5'
import { evSetFormulario } from '../Redux/actions/eventos'
import { useDispatch, useSelector } from 'react-redux'
import { ISTATE } from '../../../../../setup/redux/interface/reduxState.interface'


const CargarEventoPage: FC = () => {
  const dispatch = useDispatch()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(eventoSchemas[0])
  const [initValues] = useState<any>(evInit)
  const [formFormik, setFormFormik] = useState<IEventos>()
  const evState: any = useSelector((state: ISTATE) => state.eventos)
  const { formulario } = evState
  useEffect(() => {
    dispatch(evSetFormulario(formFormik))
  }, [formFormik])



  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(eventoSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: IEventos, actions: FormikValues) => {
    //////console.log('values', values)
    //////console.log('actions', actions)
    if (!stepper.current) {
      return
    }

    setCurrentSchema(eventoSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      //////console.log('Entro a Guardar')

      await Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'Enviando el evento para Guardar',
        showConfirmButton: false,
        timer: 2500,
      })
      await guardarEvento(values).then((res: any) => {
        Swal.close()
        if (res.data.ok) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'El evento fue guardado con Éxito',
            showConfirmButton: false,
            timer: 4500,
          })
          stepper.current?.goto(1)
          actions.resetForm()
        } else {
          Swal.fire({
            position: 'center',
            title: 'Error!',
            text: 'Por favor reintente nuevamente',
            icon: 'error',
            confirmButtonText: 'Volver',
          })
        }
      })
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])


  return (
    <div className={'card sombras'}>
      <PageTitle breadcrumbs={[]}>Agenda</PageTitle>
      <HeaderCargarEvento />
      <div className='card-body pt-3'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column '
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Datos Básicos</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Duración</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Participantes</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Ubicación</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Finalizado</h3>
            </div>
          </div>

          <Formik
            validationSchema={currentSchema}
            initialValues={initValues}
            onSubmit={submitStep}
            enableReinitialize
          >
            {(formik) => {
              setFormFormik(formik.values)
              //console.log('formulario', formulario)
              return (
                <Form
                  className='mx-auto mw-1000px w-100 pt-8 pb-2'
                  noValidate
                  id='kt_create_account_form'
                >
                  <div className='current' data-kt-stepper-element='content'>
                    <Step1 data={formik.values} />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step2 data={formik.values} />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step3 data={formik.values} />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step4 data={formik.values} />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step5 evento={formik.values} />
                  </div>

                  <div className='d-flex flex-stack pt-15'>
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Volver
                      </button>
                    </div>

                    <div>
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {stepper.current?.currentStepIndex !==
                            stepper.current?.totatStepsNumber! - 1 && 'Continuar'}
                          {stepper.current?.currentStepIndex ===
                            stepper.current?.totatStepsNumber! - 1 && 'Guardar Evento'}
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default CargarEventoPage