import { calTypes } from '../types/types'


const initialState = {
  evFull: [

    {
      _id: '',
      idEvento: 0,
      title: '',
      descripcion: '',
      objetivo: '',
      conclusion: '',
      idUsuarioCarga: '',
      usuarioCarga: '',
      actividad: '',
      presMinistro: false,
      eje: '',
      eje_detalle: '',
      eje_tipo: '',
      color: '',
      idUsuarioAsignado: '',
      usuarioAsignado: '',
      area: '',
      idArea: '',
      organizacion: '',
      colaboradores: [
        {
          nombre: '',
          area: '',
          organizacion: ''
        }
      ],
      tipo: '',
      duracion: '',
      horariosDiferentes: false,
      start: new Date(),
      end: new Date(),
      estado: '',
      lugar: '',
      calle: '',
      numero: '',
      localidad: '',
      provincia: '',
      pais: '',
      geom: {
        latitud: -31.65,
        longitud: -68.45
      },
      destinatarios: [
        {
          destinatario: '',
          idDestinatario: ''
        }
      ],
      programa: '',
      idPrograma: '',
      orgParticipantes: [
        {
          nombre: '',
          direccion: '',
          cuit: '',
          tipo: ''
        }
      ],
      ver: false,
    }
  ],
  evFiltro: [
    {
      _id: '',
      idEvento: 0,
      title: '',
      descripcion: '',
      objetivo: '',
      conclusion: '',
      idUsuarioCarga: '',
      usuarioCarga: '',
      actividad: '',
      presMinistro: false,
      eje: '',
      eje_detalle: '',
      eje_tipo: '',
      color: '',
      idUsuarioAsignado: '',
      usuarioAsignado: '',
      area: '',
      idArea: '',
      organizacion: '',
      colaboradores: [
        {
          nombre: '',
          area: '',
          organizacion: ''
        }
      ],
      tipo: '',
      duracion: '',
      horariosDiferentes: false,
      start: new Date(),
      end: new Date(),
      estado: '',
      lugar: '',
      calle: '',
      numero: '',
      localidad: '',
      provincia: '',
      pais: '',
      geom: {
        latitud: -31.65,
        longitud: -68.45
      },
      destinatarios: [
        {
          destinatario: '',
          idDestinatario: ''
        }
      ],
      programa: '',
      idPrograma: '',
      orgParticipantes: [
        {
          nombre: '',
          direccion: '',
          cuit: '',
          tipo: ''
        }
      ],
      ver: false,
    }
  ],
  evPub: [
    {
      _id: '',
      idEvento: 0,
      title: '',
      descripcion: '',
      objetivo: '',
      conclusion: '',
      idUsuarioCarga: '',
      usuarioCarga: '',
      actividad: '',
      presMinistro: false,
      eje: '',
      eje_detalle: '',
      eje_tipo: '',
      color: '',
      idUsuarioAsignado: '',
      usuarioAsignado: '',
      area: '',
      idArea: '',
      organizacion: '',
      colaboradores: [
        {
          nombre: '',
          area: '',
          organizacion: ''
        }
      ],
      tipo: '',
      duracion: '',
      horariosDiferentes: false,
      start: new Date(),
      end: new Date(),
      estado: '',
      lugar: '',
      calle: '',
      numero: '',
      localidad: '',
      provincia: '',
      pais: '',
      geom: {
        latitud: -31.65,
        longitud: -68.45
      },
      destinatarios: [
        {
          destinatario: '',
          idDestinatario: ''
        }
      ],
      programa: '',
      idPrograma: '',
      orgParticipantes: [
        {
          nombre: '',
          direccion: '',
          cuit: '',
          tipo: ''
        }
      ],
      ver: false,
    }
  ],
  componente: 'calendario',
  eventos: [],
  filtros:
  {
    areas: [],
    fechaInicio: '',
    fechaFin: '',
  },

  eventoActivo: [],
  formulario: {
    _id: '',
    idEvento: 0,
    title: '',
    descripcion: '',
    objetivo: '',
    conclusion: '',
    idUsuarioCarga: '',
    usuarioCarga: '',
    actividad: '',
    presMinistro: false,
    eje: '',
    eje_detalle: '',
    eje_tipo: '',
    color: '',
    idUsuarioAsignado: '',
    usuarioAsignado: '',
    area: '',
    idArea: '',
    organizacion: '',
    colaboradores: [
      {
        nombre: '',
        area: '',
        organizacion: ''
      }
    ],
    tipo: '',
    duracion: '',
    horariosDiferentes: false,
    start: new Date(),
    end: new Date(),
    estado: '',
    lugar: '',
    calle: '',
    numero: '',
    localidad: '',
    provincia: '',
    pais: '',
    geom: {
      latitud: -31.65,
      longitud: -68.45
    },
    destinatarios: [
      {
        destinatario: '',
        idDestinatario: ''
      }
    ],
    programa: '',
    idPrograma: '',
    orgParticipantes: [
      {
        nombre: '',
        direccion: '',
        cuit: '',
        tipo: ''
      }
    ],
    ver: false,
  },
  eventoSeleccionado: {
    _id: '',
    titulo: '',
    descripcion: '',
    objetivo: '',
    conclusion: '',
    idUsuarioCarga: '',
    usuarioCarga: '',
    actividad: '',
    presMinistro: false,
    eje: {
      nombre: '',
      detalle: '',
      tipo: '',
    },
    areaResponsable: {
      color: '',
      idUsuarioAsignado: '',
      usuarioAsignado: '',
      area: '',
      idArea: '',
      organizacion: '',
    },
    tipoevento: '',
    colaboradores: [
      {
        nombre: '',
        area: '',
        organizacion: '',
      },
    ],
    duracion: {
      tipo: '',
      horariosDif: '',
      tiempo: [
        {
          inicio: '',
          fin: '',
        },
      ],
    },
    estado: '',
    ubicacion: {
      lugar: '',
      calle: '',
      numero: '',
      localidad: '',
      provincia: '',
      pais: '',
      geom: {
        latitud: -38.951478,
        longitud: -68.0591339,
      },
    },
    destinatarios: [
      {
        destinatario: '',
        idDestinatario: ''
      },
    ],
    programa:
    {
      descripcion: '',
      idPrograma: '',
    },
    orgParticipantes: [
      {
        nombre: '',
        direccion: '',
        cuit: '',
        tipo: '',
      },
    ],
  },
  dias: {},
  acciones: {
    borrar: false,
    editar: false,
    cancelar: false,
    guardar: false,
    enviarForm: false,
    imprimir: false,
    conclusion: false,
  },
}

export const eventoReducer = (state = initialState, action: any) => {

  switch (action.type) {
    case calTypes.SetEvFull:
      return {
        ...state,
        evFull: action.payload,
      }
    case calTypes.SetEvPub:
      return {
        ...state,
        evPub: action.payload,
      }
    case calTypes.SetEvFiltro:
      return {
        ...state,
        evFiltro: action.payload,
      }
    case calTypes.SetEventoActivo:
      return {
        ...state,
        eventoActivo: action.payload,
      }
    case calTypes.SetEventoSeleccionado:
      return {
        ...state,
        eventoSeleccionado: action.payload,
      }
    case calTypes.GetEventos:
      return {
        ...state,
        eventos: action.payload,
      }
    case calTypes.SetFecha:
      return {
        ...state,
        fechas: action.payload,
      }
    case calTypes.SetComponente:
      return {
        ...state,
        componente: action.payload
      }
    case calTypes.SetDias:
      return {
        ...state,
        dias: action.payload
      }
    case calTypes.SetAcciones:
      return {
        ...state,
        acciones: action.payload
      }
    case calTypes.SetFormulario:
      return {
        ...state,
        formulario: action.payload
      }
    case calTypes.SetFiltros:
      return {
        ...state,
        filtros: action.payload
      }
    default:
      return state
  }
}
