import BotoneraCalendario from "../Botones/BotoneraCalendario"

const HeaderCargarEvento = () => {


  return (
    <div>
      <div className='card-header align-items-center border-1 bg-light-gray-500'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-blanco'>Nuevo Evento</span>
        </h3>
        <BotoneraCalendario compActivo={'nuevoevento'} />
      </div>
      {/* <div className='card-header align-items-center border-0'>
        <div className='card-toolbar'>
          <BotonAgenda pag_inicio={'calendario/nuevoevento'} nombre='Nuevo evento' imagen='/general/gen035.svg' colorActivo='btn-success' colorFondo='btn-light-success' />
          <BotonAgenda pag_inicio={'calendario/indicadores'} nombre='Indicadores' imagen='/general/gen032.svg' colorActivo='btn-warning' colorFondo='btn-light-warning' />
        </div>
      </div> */}

    </div >
  )
}

export default HeaderCargarEvento