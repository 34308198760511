// reducers.js

import { IdocMensual } from "../interfaces/IdocMeses";

const initialState: IdocMensual = {
  cuit: '',
  anio: '',
  mes : '',
  modificated: false,
};
  
  export const ejReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'SET_ESTADOS_DOCUMENTOS':
        const { cuit, anio, mes, modificated } = action.payload;
        return { ...state, cuit, anio, mes, modificated };
      default:
        return state;
    }
  };