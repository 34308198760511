/** React */
import {useState, useEffect} from 'react'
import { Toaster } from 'react-hot-toast';
import {Link, useHistory} from 'react-router-dom'
/** Formik */
import {Field, Form, Formik} from 'formik'
import FormikControl from '../../../../components/Formik/FormikControl'
/** Custom Component */
import { MySelect } from '../components/Formik/MySelect'
/** Material & Sass */
import {Button, Checkbox} from '@mui/material'
import '../../../../../../src/_metronic/assets/sass/cud.scss'
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp'
/** Services */
import {postJunta} from '../services/cud-service'
import { localidadesNqn } from '../../../../services/gral/proLocService';
 /** Hook */
import {notify} from '../hooks/useModal'
/** Interfaces */
import {initJunta} from '../Interfaces/inits'
import {Ijunta, validateSchema} from '../Interfaces/Ijunta'

export const Juntas = () => {
   const [initValues, setInitValues] = useState<Ijunta>(initJunta); 
   const [localidad, setLocalidad] = useState<any>([])
   const history = useHistory();

   useEffect(() => {
    localidadesNqn().then((res: any)=>{
      setLocalidad(res.data.data)
    })
  }, [])
   return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <Link to={'/cud/home'} className='alineacionb'>
          <ArrowBackSharpIcon />
        </Link>
      </div> 
      <div className='card-body py-1'>
      <hr></hr>
        <div className=''>
          <Formik
            initialValues={initValues}
            validationSchema={validateSchema}
            onSubmit={(values) => {
              postJunta(values).then((res: any) => {   
                notify()
                setTimeout(()=>{
                  history.push('/cud/home');        
                }, 2501)
              }) 
            }}>
            {({handleChange, handleBlur}) => (
              <div>
                <Form>
                  <div>
                    <div className='row'>
                        <div className='col-lg-3'>
                          <FormikControl
                            control='input'
                            placeholder='Nombre Junta'
                            type='text'
                            id='nombre'
                            name='nombre'
                            label='Nombre'
                            onChange={handleChange}
                            onBlur={handleBlur}/>
                        </div>
                        <div className='col-lg-4'>
                          <FormikControl
                            control='input'
                            placeholder='Domicilio'
                            type='text'
                            id='domicilio'
                            name='domicilio'
                            label='Domicilio'
                            onChange={handleChange}
                            onBlur={handleBlur}/>
                        </div>
                        <div className='col-lg-3'>
                          <label className='form-label fw-bolder text-dark fs-6' htmlFor="localidad">Localidad</label>
                          <MySelect label="" name="localidad" className='form-control form-control-solid capital'>
                            <option value=''>Seleccione uno</option>
                                {localidad.map((value: any)=>{
                                  return <option value={value.nombre}>{value.nombre}</option>})}
                          </MySelect>
                        </div>
                      <div className='col-lg-2'>
                      <label className='form-label fw-bolder text-dark fs-6'>Estado</label> <br />
                        <Field
                          type='checkbox'
                          as={Checkbox}
                          id='activo'
                          name='activo'
                          className=''
                          onChange={handleChange}
                          onBlur={handleBlur}/>
                        <label className=''>Activo</label>
                      </div>
                    </div>
                  </div>
                    <br></br>
                  <div className='row'>
                    <div>                     
                    <br/> 
                      <Button type='submit' className='btn btn-sm btn-primary'>
                        Guardar
                      </Button>
                      <Toaster
                          position="top-right"
                          reverseOrder={false}/>
                     </div>
                  </div>
                  <br/><br/>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
