import {FC, useEffect, useState} from 'react'
import {Field} from 'formik'
import {getOrgDep} from '../../../services/OrganizacionService'
import {ICreateOrg} from '../../../interfaces/ICreateOrg'
import {ICreateArea} from '../../../interfaces/ICreateArea'

import {ISubArea} from '../../../interfaces/ICreateSubArea'

interface Iorg {
  pasarOrg: any
  pasarArea: any
  pasarSubArea: any
}
/* interface orgSelect {
  areasOrg?: []
  datosSistemas: {}
  fechaAlta: {}
  nombre: string
  nombreMinistro: string
  _id?: string
} */

const Step3: FC<Iorg> = ({pasarOrg, pasarArea, pasarSubArea}) => {
  const [org, setOrg] = useState<any>([])
  const [area, setArea] = useState<any>([])
  const [areaSub, setSubArea] = useState<any>([])

  const [dep, setDep] = useState<any>()
  const [depArea, setDepArea] = useState<any>()
  const [depSubArea, setDepSubArea] = useState<any>()

  const cargarArea = () => {
    let organismo: any = org.find((element: ICreateOrg) => element.nombre === dep)
    pasarOrg(organismo)
    setArea(organismo?.areasOrg)
  }

  const cargarSubArea = () => {
    let organismo: any = area?.find((element: ICreateArea) => element.nombreArea === depArea)
    if (organismo) {
      pasarArea(organismo)
      setSubArea(organismo?.subAreas)
    }
  }
  const cargarAreaPropia = () => {
    let organismo: any = areaSub?.find((element: ICreateArea) => element.nombreArea === depSubArea)
    pasarSubArea(organismo)
  }

  useEffect(() => {
    getOrgDep().then((res: any) => {
      setOrg(res.data.data)
    })
    return () => {}
  }, [])

  useEffect(() => {
    cargarArea()
    cargarSubArea()
    cargarAreaPropia()
    return () => {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dep, depArea, depSubArea])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Datos laborales</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Info. del Minsterio y dependencia a la cual pertenece.
        </div>
      </div>
      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark'>Número de Legajo</label>
            <Field
              type='text'
              name='datosLaborales.legajo'
              className='form-control form-control-solid'
              disabled={true}
            />
          </div>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark'>Fecha de alta</label>
            <Field
              type='text'
              name='datosLaborales.fechaAlta'
              className='form-control form-control-solid'
              disabled={true}
            />
          </div>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark'>Relación laboral</label>
            <Field
              type='text'
              name='datosLaborales.tipoRelacionLaboral'
              className='form-control form-control-solid capital'
              disabled={true}
            />
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-lg-12'>
            <label className='form-label fw-bolder text-dark fs-6'>Seleccione Ministerio</label>
            <Field
              as='select'
              className='form-select form-select-solid capital'
              name='datosLaborales.datosOrganismo.nombre'
              id='datosLaborales.datosOrganismo.nombre'
              onChange={(e: any) => setDep(e.target.value)}
            >
              <option value=''>Seleccione</option>
              {org.map((data: ICreateOrg) => {
                return (
                  <option key={data._id} value={data.nombre}>
                    {data.nombre}
                  </option>
                )
              })}
            </Field>
          </div>
          <div className='col-lg-12'>
            <label className='form-label fw-bolder text-dark fs-6'>Seleccione Area</label>
            <Field
              as='select'
              className='form-select form-select-solid capital'
              name='datosLaborales.datosOrganismo.areaPrincipal.nombreArea'
              id='datosLaborales.datosOrganismo.areaPrincipal.nombreArea'
              onChange={(e: any) => setDepArea(e.target.value)}
            >
              <option value=''>Seleccione</option>
              {area?.map((data: ICreateArea) => {
                return (
                  <option key={data._id} value={data.nombreArea}>
                    {data.nombreArea}
                  </option>
                )
              })}
            </Field>
          </div>
          <div className='col-lg-12'>
            <label className='form-label fw-bolder text-dark fs-6'>Seleccione Sub Area</label>
            <Field
              as='select'
              className='form-select form-select-solid capital'
              name='datosLaborales.datosOrganismo.areaPropia.nombreArea'
              id='datosLaborales.datosOrganismo.areaPropia.nombreArea'
              onChange={(e: any) => setDepSubArea(e.target.value)}
            >
              <option value=''>Seleccione</option>
              {areaSub?.map((data: ISubArea) => {
                return (
                  <option key={data._id} value={data.nombreArea}>
                    {data.nombreArea}
                  </option>
                )
              })}
            </Field>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step3}
