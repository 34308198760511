/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'

import {Field, ErrorMessage} from 'formik'
import TextErrorFormik from '../../../../../../components/Formik/TextErrorFormik'
import FormikControl from '../../../../../../components/Formik/FormikControl'

const Step2Org: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Datos fiscales correspondiente al ministerio.
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>.</div>
      </div>
      <div className='fv-row'>
        <div className='row justify-content-center'>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Número de CUIT</label>
            <Field
              type='text'
              name='datosFiscales.nroCuit'
              placeholder='Nro...'
              className='form-control form-control-solid'
            />
            <ErrorMessage name='datosFiscales.nroCuit' component={TextErrorFormik} />
          </div>
          <div className='col-lg-4'>
            <FormikControl
              control='date'
              label='Fecha de creación'
              name='datosFiscales.fechaAlta'
            />
          </div>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Fecha de Baja</label>
            <Field
              type='date'
              name='datosFiscales.fechaBaja'
              className='form-control form-control-solid'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step2Org}
