import {request} from '../../../../../setup/axios/axios.util'
import {ICreateOrg} from '../interfaces/ICreateOrg'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const URL = `${API_URL}/modules/sis-admin`

export const postOrg = (obj: ICreateOrg) => {
  let data = {data: {}}
  data.data = obj
  let postUrl = `${URL}/organizaciones`
  return request().post(postUrl, data)
}

export const getAllOrg = () => {
  let getUrl = `${URL}/organizaciones`
  return request().get(getUrl)
}
export const getOrgDep = () => {
  let getUrl = `${URL}/orgdep`
  return request().get(getUrl)
}

export const putSistemOrg = (id: string, estado: boolean) => {
  let opApi = ''
  estado ? (opApi = 'activarOrganizacion') : (opApi = 'desactivarOrganizacion')

  let patchUrl = `${URL}/organizaciones/${id}`
  let data = {op: opApi}
  return request().patch(patchUrl, data)
}

export const putSistemArea = (id: string, estado: boolean, nombreArea: string) => {
  let opApi = ''
  estado ? (opApi = 'activarArea') : (opApi = 'desactivarArea')

  let patchUrl = `${URL}/organizaciones/${id}`
  let data = {op: opApi, nombreArea: nombreArea}
  return request().patch(patchUrl, data)
}

export const putImgArea = (id: string, img: string, nombreArea: string) => {
  let opApi = 'modImg'
  let patchUrl = `${URL}/organizaciones/${id}`
  let data = {op: opApi, nombreArea: nombreArea, imgMod: img}
  return request().patch(patchUrl, data)
}
