interface ITipoArea {
  _id: number
  nombre: String
}
export interface ITipoAreas extends Array<ITipoArea> { }

interface ICategoriaArea {
  _id: number
  nombre: String
}
export interface ICategoriaAreas extends Array<ICategoriaArea> { }

export const tipoArea: ITipoAreas = [
  { _id: 0, nombre: 'Direccion' },
  { _id: 1, nombre: 'Direccion Provincial' },
  { _id: 3, nombre: 'Direccion General' },
  { _id: 2, nombre: 'Secretaria' },
  { _id: 4, nombre: 'SubSecreatria' },
  { _id: 5, nombre: 'Coordinación' },
]

export const categoriasAreas: ICategoriaAreas = [
  {
    _id: 1,
    nombre: 'A',
  },
  {
    _id: 2,
    nombre: 'B',
  },
  {
    _id: 3,
    nombre: 'C',
  },
  {
    _id: 4,
    nombre: 'D',
  },
  {
    _id: 5,
    nombre: 'E',
  },
  {
    _id: 6,
    nombre: 'F',
  },
]
