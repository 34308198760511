import React from 'react'
import {PageLink} from '../../../../../../_metronic/layout/core'
import {FC} from 'react'
interface Props {
  description?: string
  breadcrumbs?: Array<PageLink>
}
const PageTitleTelefonia: FC<Props> = ({children, description, breadcrumbs}) => {
  return <div className='text-dark text-center'>TELEFONIA COORPORATIVA NAJC</div>
}

export default PageTitleTelefonia
