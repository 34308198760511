import {ErrorMessage, Field} from 'formik'
import React from 'react'
import TextErrorFormik from './TextErrorFormik'

const SelectFormik: React.FC = (props: any) => {
  console.log(props)
  const {label, name, options, ...rest} = props

  return (
    <div>
      <label className='form-label fw-bolder text-dark fs-6'>{label}</label>
      <Field
        as='select'
        id={name}
        name={name}
        {...rest}
        className='form-control form-control-solid capital'
      >
        <option value=''>Seleccione</option>
        {options?.map((option: any) => {
          console.log(option.mes)
          if (option.nombre) {
            return (
              <option key={option?.nombre} value={option?.nombre}>
                {option?.nombre}
              </option>
            )
          }
          if (option.mes) {
            console.log(option.mes)
            return (
              <option key={option?._id} value={option?.mes}>
                {option?.mes}
              </option>
            )
          } else {
            return (
              <option key={option?._id} value={option?.nombreArea}>
                {option?.nombreArea}
              </option>
            )
          }
        })}
      </Field>
      <ErrorMessage name={name} component={TextErrorFormik} />
    </div>
  )
}

export default SelectFormik
