import React, { useState } from 'react'

import { Formik, FormikValues } from 'formik'
import { toAbsoluteUrl } from '../../../../../../../../../../_metronic/helpers'
import { ICreateUsr } from '../../../../../../interfaces/ICreateUsr'
import FormikControl from '../../../../../../../../../components/Formik/FormikControl'
import { IconButton } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useHistory } from 'react-router'
import { updateDatosPer } from '../../../../../../services/UsuarioService'
import Swal from 'sweetalert2'
interface props {
  usuario: ICreateUsr
}

const ProfileDetails: React.FC<props> = ({ usuario }) => {
  const [perfilUs, setPerfilUS] = useState<ICreateUsr>(usuario)
  const [perfilUp, setPerfilUp] = useState<ICreateUsr>()

  const [update, setUpdata] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const guardarCambios = (value: ICreateUsr) => {
    updateDatosPer(value.datosPersonales, value.datosContacto, value.usuario).then(
      async (res: any) => {
        if (res.data.ok) {
          setPerfilUp(res.data.data)
          await Swal.fire({
            icon: 'success',
            title: 'El cambio fue registrado, correctamente',
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Comunicarse con el soporte técnico de Apex!',
          })
        }
      }
    )
  }
  let history: any = useHistory()

  const handelUpdate = (e: any) => {
    setUpdata(!update)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => {
              setOpen(!open)
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <h3 className='fw-bolder text-blanco m-0'>Detalles del Perfil</h3>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button
            type='button'
            className='btn btn-sm btn-light-info me-3'
            onClick={() => {
              history.location.state.op === 'BtnPerfil'
                ? history.push({ pathname: '/dashboard' })
                : history.push({ pathname: '/admin/servicios' })
            }}
          >
            {' '}
            Volver
          </button>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <Formik initialValues={perfilUp || perfilUs} onSubmit={guardarCambios} enableReinitialize>
          {(formik: any) => {
            //////console.log(formik)
            return (
              <form noValidate className='form'>
                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Foto de Perfil</label>
                    <div className='col-lg-8'>
                      <div
                        className='image-input image-input-outline'
                        data-kt-image-input='true'
                        style={{
                          backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                        }}
                      >
                        <div
                          className='image-input-wrapper w-125px h-125px'
                          style={{
                            backgroundImage: `url(${toAbsoluteUrl(perfilUs.datosPersonales.foto)})`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Nombre Completo
                    </label>

                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row'>
                          <FormikControl
                            control='input'
                            name='datosPersonales.nombres'
                            placeholder='Nombre del usuario'
                            className=' form-control form-control-lg form-control-solid'
                            disabled={update}
                          />
                          {!update && <div className='modificar'>Puede modificar este campo</div>}
                        </div>

                        <div className='col-lg-6 fv-row'>
                          <FormikControl
                            control='input'
                            name='datosPersonales.apellido'
                            placeholder='Apellido del usuario'
                            className=' form-control form-control-lg form-control-solid'
                            disabled={update}
                          />
                          {!update && <div className='modificar'>Puede modificar este campo</div>}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Ministerio
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <FormikControl
                        control='input'
                        name='datosLaborales.datosOrganismo.nombre'
                        placeholder='Ministerio'
                        className=' form-control form-control-lg form-control-solid'
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Dependencia
                    </label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row'>
                          <FormikControl
                            control='input'
                            name='datosLaborales.datosOrganismo.areaPrincipal.nombreArea'
                            placeholder='Area Principal'
                            className=' form-control form-control-lg form-control-solid'
                            disabled={true}
                          />
                        </div>
                        <div className='col-lg-6 fv-row'>
                          <FormikControl
                            control='input'
                            name='datosLaborales.datosOrganismo.areaPropia.nombreArea'
                            placeholder='Area propia'
                            className=' form-control form-control-lg form-control-solid'
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Datos laborales
                    </label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-4 fv-row'>
                          <FormikControl
                            control='input'
                            name='datosLaborales.legajo'
                            placeholder='Nro de legajo'
                            className=' form-control form-control-lg form-control-solid'
                            disabled={true}
                          />
                        </div>
                        <div className='col-lg-4 fv-row'>
                          <FormikControl
                            control='input'
                            name='datosLaborales.fechaAlta'
                            placeholder='Fecha de Alta'
                            className=' form-control form-control-lg form-control-solid'
                            disabled={true}
                          />
                        </div>
                        <div className='col-lg-4 fv-row'>
                          <FormikControl
                            control='input'
                            name='datosLaborales.tipoRelacionLaboral'
                            placeholder='Tipo de relación laboral'
                            className=' form-control form-control-lg form-control-solid'
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required'>Datos de contacto</span>
                    </label>

                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row'>
                          <FormikControl
                            control='input'
                            name='datosContacto.email'
                            placeholder='Correo electronico'
                            className='form-control form-control-lg form-control-solid'
                            disabled={update}
                          />
                        </div>
                        {!update && <div className='modificar'>Puede modificar este campo</div>}
                        <div className='col-lg-6 fv-row'>
                          <FormikControl
                            control='input'
                            name='datosContacto.telefono'
                            placeholder='Telefono'
                            className='form-control form-control-lg form-control-solid'
                            disabled={update}
                          />
                        </div>
                        {!update && <div className='modificar'>Puede modificar este campo</div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <div className='row mb-0'>
                    <label className='col-form-label fw-bold fs-6'>Editar</label>

                    <div className='col-lg-8'>
                      <div className='form-check form-check-solid form-switch fv-row'>
                        <input
                          className='form-check-input w-45px h-30px'
                          type='checkbox'
                          id='allowmarketing'
                          onChange={(e) => handelUpdate(e)}
                        />
                        <label className='form-check-label'></label>
                      </div>
                    </div>
                  </div>

                  <button
                    type='button'
                    className='btn btn-primary'
                    disabled={update}
                    onClick={() => {
                      guardarCambios(formik.values)
                    }}
                  >
                    {!loading && 'Guardar Cambios'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export { ProfileDetails }
