import { request } from '../../../../../setup/axios/axios.util'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const URL = `${API_URL}/modules/dav`
//----- ADMISIONES -----------------------------------
export const getAdmisiones = (activePage?: any, activando?: any, dnib?: any) => {
  return request().get(`${URL}/admisiones`)
}
//----- FIN ADMISIONES -------------------------------

/** Metodo para todas los tipos de patologias */
export const getCie10 = () => {
  return request().get(`${URL}/cie10`)
}


export const getJuntas = () => {
  return request().get(`${URL}/juntas`)
}

export const postJunta = (junta: any) => {
  return request().post(`${URL}/nuevaJunta`, junta)
}

export const postTramite = (tramite: any) => {
  return request().post(`${URL}/nuevoTramite`, tramite)
}

export const getTramiteDni = (data: any) => {
  let datos = {
    data,
  }
  return request().post(`${URL}/tramitedni`, datos)
}

export const getLegajoDni = (data: any) => {
  let dato = {
    data,
  }
  return request().post(`${URL}/legajodni`, dato)
}

/** Metodo para traer todos los Tramites guardados en DB */
export const getTramites = (activePage?: any, activando?: any, dnib?: any) => {
  return request().get(`${URL}/mesae?page=${activePage}&limit=4&activando=${activando}&dni=${dnib}`)
}

export const updateTramite = (values: any) => {
  return request().patch(`${URL}/editando`, values)
}

export const updateJunta = (values: any) => {
  return request().patch(`${URL}/editarJunta`, values)
}

/** Metodo para crear / guardar Legajos en DB */
export const postLegajo = (legajo: any) => {
  return request().post(`${URL}/nuevoLegajo`, legajo)
}

/** Metodo para crear / guardar Cud Legajos en DB */
export const postCud = (values: any, infoTipo: {}) => {
  let data: any = {}
  data.infoTipo = infoTipo
  data.values = values
  return request().post(`${URL}/cudLegajo`, data)
}

/** Metodo para editar un Legajo guardado en DB */
export const updateLegajo = (values: any) => {
  return request().patch(`${URL}/editarLegajo`, values)
}
export const searchByDni = (dni: any, limit: number) => {
  const query = {
    dni,
    limit,
  }
  return request().post(`${URL}/searchdni`, query)
}
export const searchByLegajo = (legajo: any, limit: number) => {
  const query = {
    legajo,
    limit,
  }
  return request().post(`${URL}/searchByLegajo`, query)
}

export const searchByLocalidad = (loc: any) => {
  return request().post(`${URL}/searchlocalidad`, loc)
}
export const searchByFecha = (fecha: any) => {
  return request().post(`${URL}/searchfecha`, fecha)
}
export const getTotalTramites = () => {
  return request().get(`${URL}/indicadorTramites`);
}
export const getTotalLegajos = () => {
  return request().get(`${URL}/indicadorLegajos`);
}

export const getIndicadoresEdades = () => {
  return request().get(`${URL}/indicadorEdades`)
}

export const saveDatosEncuesta = (data: any) => {
  return request().post(`${URL}/crearEncuesta`, data)
}

export const getIndicadorPorLocalidad = (loc: any) => {
  return request().get(`${URL}/indicadorLocalidad?localidad=${loc}`);
}



