/** React */
import React, {useEffect, useState} from 'react'
/** Material */
import {Radio} from '@mui/material'
/** Formik */
import {ErrorMessage, Field} from 'formik'
import FormikControl from '../../../../../components/Formik/FormikControl'
/** Services */
import {localidadesNqn} from '../../../../../services/gral/proLocService'
import moment from 'moment'

type Props = {
  handleChange: any
  handleBlur: any
  btnEdit?: any
  formikValue?: any
}

export const DatosTutor: React.FC<Props> = ({handleChange, handleBlur, btnEdit, formikValue}) => {
  const [localidad, setLocalidad] = useState<any>([])

  useEffect(() => {
    localidadesNqn().then((res: any) => {
      setLocalidad(res.data.data)
    })
  }, [])
  function changedateformat(val: any) {
    console.log(val)
    let myArray = val.split('-')

    let year = myArray[0]
    let month = myArray[1]
    let day = myArray[2]

    let formatteddate = day + '-' + month + '-' + year

    return formatteddate
  }
  return (
    <>
      <br />
      <div className='row alineacion_inputs'>
        <div className='col-lg-2'>
          <FormikControl
            control='input'
            type='text'
            id='datosTutor.dni'
            name='datosTutor.dni'
            value={formikValue?.datosTutor?.dni}
            label='Dni'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className='col-lg-2'>
          <label className='form-label fw-bolder text-dark fs-6'>Tipo Doc</label>
          <Field
            as='select'
            id='datosTutor.tipoDoc'
            name='datosTutor.tipoDoc'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
          >
            <option value=''>Seleccione Uno</option>
            <option value='DNI F'>DNI F</option>
            <option value='DNI M'>DNI M</option>
            <option value='LC'>LC</option>
            <option value='LE'>LE</option>
            <option value='PAS'>Pas</option>
            <option value='CI'>CI</option>
          </Field>
          <ErrorMessage name='datosTutor.tipoDoc' component='span' className='error' />
        </div>
        <div className='col-lg-3'>
          <label className='form-label fw-bolder text-dark fs-6'>Apellido/s</label>
          <Field
            control='input'
            type='text'
            id='datosTutor.apellido'
            name='datosTutor.apellido'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name='datosTutor.apellido' component='span' className='error' />
        </div>
        <div className='col-lg-3'>
          <label className='form-label fw-bolder text-dark fs-6'>Nombre/s</label>
          <Field
            control='input'
            type='text'
            id='datosTutor.nombre'
            name='datosTutor.nombre'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name='datosTutor.nombre' component='span' className='error' />
        </div>
        <div className='col-lg-2'>
          <label className='form-label fw-bolder text-dark fs-6'>Genero</label>
          <Field
            as='select'
            id='datosTutor.sexo'
            name='datosTutor.sexo'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
          >
            <option value=''>Seleccione</option>
            <option value='m'>Masculino</option>
            <option value='f'>Femenino</option>
            <option value='t'>Transexual</option>
            <option value='o'>Otro</option>
          </Field>
          <ErrorMessage name='datosTutor.sexo' component='span' className='error' />
        </div>
      </div>
      <br /> <br /> <br />
      <div className='row alineacion_inputs'>
        <div className='col-lg-2'>
          <label className='form-label fw-bolder text-dark fs-6'>Fecha de Nacimiento.</label>
          <Field
            type='date'
            id='datosTutor.fechaNacimiento'
            name='datosTutor.fechaNacimiento'
            value={formikValue?.datosTutor?moment(formikValue.datosTutor.fechaNacimiento).format('YYYY-MM-DD'):null}
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className='col-lg-4'>
          <label className='form-label fw-bolder text-dark fs-6'>Localidad</label>
          <Field
            as='select'
            id='datosTutor.localidad'
            name='datosTutor.localidad'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
          >
            <option value=''>Seleccionar</option>
            {localidad.map((value: any, index: any) => {
              return (
                <option key={index} value={value.nombre.toLowerCase()}>
                  {value.nombre}
                </option>
              )
            })}
          </Field>
          <ErrorMessage name='datosTutor.localidad' component='span' className='error' />
        </div>
        <div className='col-lg-2'>
          <label className='form-label fw-bolder text-dark fs-6'>Domicilio</label>
          <Field
            control='input'
            type='text'
            id='datosTutor.domicilio'
            name='datosTutor.domicilio'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name='datosTutor.domicilio' component='span' className='error' />
        </div>
        <div className='col-lg-2'>
          <label className='form-label fw-bolder text-dark fs-6'>Código Postal</label>
          <Field
            as='input'
            id='datosTutor.codigoPostal'
            name='datosTutor.codigoPostal'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
          />
          <ErrorMessage name='datosTutor.codigoPostal' component='span' className='error' />
        </div>
        <div className='col-lg-2'>
          <FormikControl
            control='input'
            type='text'
            id='datosTutor.telefono'
            name='datosTutor.telefono'
            label='Telefono'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <br /> <br /> <br />
      <div className='row alineacion_inputs'>
        <div className='col-lg-2'>
          <label className='form-label fw-bolder text-dark fs-6'>Nacionalidad</label>
          <Field
            as='input'
            id='datosTutor.nacionalidad'
            name='datosTutor.nacionalidad'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
          />
          <ErrorMessage name='datosTutor.nacionalidad' component='span' className='error' />
        </div>
        <div className='col-lg-4'>
          <FormikControl
            control='input'
            type='text'
            id='datosTutor.mail'
            name='datosTutor.mail'
            label='Email'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <br /> <br /> <br />
      <div className='row alineacion_inputs'>
        <label className='form-label fw-bolder text-dark fs-6'>
          Para el caso de personas a evaluar menores de edad
        </label>
        <div className='col-lg-4'>
          <Field
            type='radio'
            as={Radio}
            id='datosTutor.tipoPariente'
            name='datosTutor.tipoPariente'
            value='padre'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Padre</label>
        </div>
        <div className='col-lg-4'>
          <Field
            type='radio'
            as={Radio}
            id='datosTutor.tipoPariente'
            name='datosTutor.tipoPariente'
            value='madre'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Madre</label>
        </div>
        <div className='col-lg-4'>
          <Field
            type='radio'
            as={Radio}
            id='datosTutor.tipoPariente'
            name='datosTutor.tipoPariente'
            value='tutor designado judicialmente'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Tutor</label>
        </div>
      </div>
      <br /> <br />
      <div className='row alineacion_inputs'>
        <label className='form-label fw-bolder text-dark fs-6'>
          En caso de Tutor/a, Guardador/a, Curador/a o Apoyo completar:
        </label>
        <div className='col-lg-4'>
          <Field
            type='radio'
            as={Radio}
            id='datosTutor.tipoTutor'
            name='datosTutor.tipoTutor'
            value='definitivo'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Definitivo</label>
        </div>
        <div className='col-lg-4'>
          <Field
            type='radio'
            as={Radio}
            id='datosTutor.tipoTutor'
            name='datosTutor.tipoTutor'
            value='provisorio'
            className=''
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className=''>Permanente</label>
        </div>
        <div className='col-lg-2'>
          <label className='form-label fw-bolder text-dark fs-6'>Fecha de Designación</label>
          <Field
            control='input'
            type='date'
            id='datosTutor.fechaDesignacionTutor'
            name='datosTutor.fechaDesignacionTutor'
            className='form-control form-control-solid '
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <br />
      <div className='row alineacion_inputs'>
        <div className='col-lg-12'>
          <label className='form-label fw-bolder text-dark fs-6'>Juzgado</label>
          <Field
            control='input'
            type='text'
            id='datosTutor.juzgado'
            name='datosTutor.juzgado'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name='datosTutor.juzgado' component='span' className='error' />
        </div>
      </div>
      <br /> <br />
      <div className='row alineacion_inputs'>
        <div className='col-lg-4'>
          <label className='form-label fw-bolder text-dark fs-6'>Secretaria</label>
          <Field
            control='input'
            type='text'
            id='datosTutor.secretaria'
            name='datosTutor.secretaria'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name='datosTutor.secretaria' component='span' className='error' />
        </div>
        <div className='col-lg-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Depto. Judicial</label>
          <Field
            control='input'
            type='text'
            id='datosTutor.dptoJudicial'
            name='datosTutor.dptoJudicial'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name='datosTutor.dptoJudicial' component='span' className='error' />
        </div>
      </div>
      <br /> <br />
      <div className='row alineacion_inputs'>
        <div className='col-lg-12'>
          <label className='form-label fw-bolder text-dark fs-6'>Fiscalia</label>
          <Field
            control='input'
            type='text'
            id='datosTutor.fiscalia'
            name='datosTutor.fiscalia'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name='datosTutor.fiscalia' component='span' className='error' />
        </div>
      </div>
      <br /> <br />
      <div className='row alineacion_inputs'>
        <div className='col-lg-12'>
          <label className='form-label fw-bolder text-dark fs-6'>Defensoria</label>
          <Field
            control='input'
            type='text'
            id='datosTutor.defensoria'
            name='datosTutor.defensoria'
            className='form-control form-control-solid capital'
            disabled={!btnEdit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorMessage name='datosTutor.defensoria' component='span' className='error' />
        </div>
      </div>
      <br />
    </>
  )
}
export default DatosTutor
