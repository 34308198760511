import {KTSVG} from '../../../../../../../_metronic/helpers'

import {Link} from 'react-router-dom'
import {ICreateOrg} from '../../../interfaces/ICreateOrg'
import {putSistemOrg} from '../../../services/OrganizacionService'
import Swal from 'sweetalert2'
import {ISTATE} from '../../../../../../../setup/redux/interface/reduxState.interface'
import {useSelector} from 'react-redux'
import {ICreateModulo} from '../../../interfaces/ICreateModulo'

type Props = {
  org: ICreateOrg
}

const HeaderOrgnismo: React.FC<Props> = ({org}) => {
  let dashState: any = useSelector((state: ISTATE) => state.dashBoard)
  let {perfil} = dashState
  let modulo: ICreateModulo = perfil.find((value: any) => value.nombre === 'administracion')
  let menu = modulo.menu.find((value) => value.name === 'organismos')

  const handleEstado = () => {
    Swal.fire({
      title:
        'Esta por modificar el estado del Organismo, si realiza esta operación, todos los usuarios vinculados, no podran acceder.',
      text: '¿Desea continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar!',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        putSistemOrg(org?._id || '', !org?.activo).then((res: any) => {
          if (res.data.ok) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Los cambios se guardaron correctamente!',
              showConfirmButton: false,
              timer: 1500,
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ooops, verificar conexion!',
              showConfirmButton: false,
              timer: 1500,
            })
          }
        })
      }
    })
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          {/* <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={org?.datosSistemas.logo} alt='Metronic' />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div> */}

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 capital'>
                    {org?.nombre}
                  </div>

                  {org?.activo ? (
                    <div
                      className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_upgrade_plan'
                    >
                      Activo
                    </div>
                  ) : (
                    <div
                      className='btn btn-sm btn-light-danger fw-bolder ms-2 fs-8 py-1 px-3'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_upgrade_plan'
                    >
                      Desactivado
                    </div>
                  )}
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 capital'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com006.svg'
                      className='svg-icon-4 me-1 '
                    />
                    {org?.nombreMinistro}
                  </div>
                  <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 capital'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen018.svg'
                      className='svg-icon-4 me-1'
                    />
                    {org?.direccion.localidad}
                  </div>
                  <div className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-4 me-1'
                    />
                    {org?.email}
                  </div>
                </div>
              </div>

              <div className='d-flex my-4'>
                <Link
                  to={{pathname: '/admin/servicios', state: {}}}
                  className='btn btn-sm btn-light-info me-3'
                >
                  Volver
                </Link>
                {(menu?.roleUs?.editor || menu?.roleUs?.crear) && (
                  <>
                    <div className='btn btn-sm btn-light-success me-2' id='kt_user_follow_button'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr012.svg'
                        className='svg-icon-3 d-none'
                      />

                      <span className='indicator-label'>Editar</span>
                      <span className='indicator-progress'>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    </div>

                    <Link
                      to={{pathname: 'dependencia', state: org}}
                      className='btn btn-sm btn-light-primary me-3'
                    >
                      Agregar Área
                    </Link>

                    <div className='me-0'>
                      {org?.activo ? (
                        <button
                          onClick={() => handleEstado()}
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-danger'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                        >
                          <i className='bi bi-radioactive fs-3'></i>
                        </button>
                      ) : (
                        <button
                          onClick={() => handleEstado()}
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-success'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                        >
                          <i className='bi bi-radioactive fs-3'></i>
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen001.svg'
                        className='svg-icon-3 svg-icon-primary me-2'
                      />
                      <div className='fs-2 fw-bolder'>{org?.areasOrg?.length}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Áreas</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-3 svg-icon-primary me-2'
                      />
                      <div className='fs-2 fw-bolder'>{org?.usuariosTotal}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Usuarios</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>{org?.usuariosActivos}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Usuarios Activos</div>
                  </div>

                  {/*  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen025.svg'
                        className='svg-icon-3 svg-icon-primary me-2'
                      />
                      <div className='fs-2 fw-bolder'>{org?.modulosTotal}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Modulos</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen025.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>{org?.modulosTotal}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Modulos Activos</div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              {/*  <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname === '/crafted/account/overview' && 'active')
            }
            to='/crafted/account/overview'
          >
            Overview
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname === '/crafted/account/settings' && 'active')
            }
            to='/crafted/account/settings'
          >
            Settings
          </Link> */}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default HeaderOrgnismo
