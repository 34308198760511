import { Children, ReactElement, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Form, Formik, FormikConfig, FormikHelpers, FormikValues } from 'formik';

const stepsLabels = [
  'Datos personales',
  'Situación Habitacional',
  'Educación',
  'Actividad Laboral',
  'Situación previsional',
  'Cobertura de Salud'];

interface Props extends FormikConfig<FormikValues> {
  children: React.ReactNode;
  className: string;
}

const EncuestaStepper: React.FC<Props> = ({ children, initialValues, onSubmit, className }: Props) => {
  const [activeStep, setActiveStep] = useState(0);

  const [snapshot, setSnapshot] = useState(initialValues);

  const steps = Children.toArray(children) as ReactElement[];

  const stepForm = steps[activeStep];

  const totalSteps = steps.length;

  const isLastStep = activeStep === totalSteps - 1;


  const handleNext = (values: FormikValues) => {
    setSnapshot(values);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (values: FormikValues) => {
    setSnapshot(values);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
    console.log(values);
    if (stepForm.props.onSubmit) {
      console.log("Se submitió");
      await stepForm.props.onSubmit(values);
    }

    if (isLastStep) {
      return onSubmit(values, actions);
    }
    else {
      actions.setTouched({});
      handleNext(values);

    }

  }


  return (
    <div className={`card ${className} p-10`}>
      <h5 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bolder fs-5 mb-1 capital'>datos encuesta</span>
      </h5>
      <div className='card-body p-30'>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {stepsLabels.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button>Reset</Button>
              </Box>
            </>
          ) : (
            <>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
              <div>
                <Formik
                  initialValues={snapshot}
                  validationSchema={stepForm.props.validationSchema}
                  onSubmit={handleSubmit}
                >
                  {(formik: any) => (
                    <>
                      <Form>
                        {stepForm}
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                          <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                          >
                            Atrás
                          </Button>
                          <Box sx={{ flex: '1 1 auto' }} />

                          <Button type="submit" >
                            {activeStep === steps.length - 1 ? 'Finalizar' : 'Continuar'}
                          </Button>
                        </Box>
                      </Form>
                      <div>
                        {/* <pre>{JSON.stringify([formik.values, formik.errors, formik.touched], null, 4)}</pre> */}
                      </div>
                    </>
                  )}
                </Formik>
              </div>
            </>
          )}
        </Box>
      </div>
    </div>
  );
}

export default EncuestaStepper;

export const FormStep = ({ stepName = '', children }: any) => children;