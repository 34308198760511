import {request} from '../../../../../setup/axios/axios.util'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const URL = `${API_URL}/modules/ej`

/* Incio de Servicios para las empresas */
//TODO: Endpoint para traer todas las empresas con paginación.
export const getAllEmpresas = (limit: number, page: number) => {
  return request().get(`${URL}/empresas?limit=${limit}&page=${page}`)
}
//TODO: Endpoint para traer una empresa en base al cuit de la misma.

export const getOneEmpresa = (cuit: string) => {
  return request().get(`${URL}/empresa?cuit=${cuit}`)
}

//fileuploadMesxMes

export const postFileMesxMes = (values: any, cuit: string) => {
  let formData = new FormData()
  formData.append('form931', values.form931)
  formData.append('nominaDDJJPagoEmp', values.nominaDDJJPagoEmp)
  formData.append('vepPagoElectronicotaAfip', values.vepPagoElectronico)
  formData.append('cuit', cuit)

  return request().post(`${URL}/uplMesXMes`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

////save doc mesxmes
export const saveDocMesxMes = (values: any, cuit: string) => {
  let data: any = {}
  data.cuit = cuit
  data.doc = values

  return request().post(`${URL}/saveDocMesXmes`, data)
}

export const getDocMesxMes = (id_file?: string) => {
  return request().get(`${URL}/getMesXMes?idFile=${id_file}`, {responseType: 'blob'})
}

/* Fin de Servicios para las empresas */

/* /////////////////////////////////////////////////////////////// */
/* Incio de Servicios para las Emprendedores */

//TODO: Endpoint para traer todas las Emprendedores con paginación.
export const getAllEmprendedores = (limit: number, page: number) => {
  return request().get(`${URL}/emprendedores?limit=${limit}&page=${page}`)
}
//TODO: Endpoint para traer una emprendedor en base al cuit de la misma.

export const getOneEmprendedor = (cuit: string) => {
  return request().get(`${URL}/emprendedor?cuit=${cuit}`)
}

/* Fin de Servicios para las   Emprendedores */

/* Servicios para modificar los estados de los Inscriptos */

//TODO:Endpoint para ver los datos analiticos de los inscriptos
export const analitcaMenuHeaderUsuario = (usuario: string) => {
  return request().get(`${URL}/analiticDada?usuario=${usuario}`)
}

//TODO: Endpoint para cambiar el estado de los Inscriptos

export const modInscripcion = (estado: string, cuit?: string, cuil?: string, mensaje?: any) => {
  let data = {
    cuit: cuit,
    cuil: cuil,
    estado: estado,
    mensaje,
  }

  return request().post(`${URL}/apbInscripcion`, data)
}

//TODO:End point for ask data of de pdf file,

export const getPdf = (tipo: string, cuit: string, archivo: string) => {
  let data = {
    tipo: tipo,
    cuit: cuit,
    archivo: archivo,
  }
  return request().post(`${URL}/getPdf`, data, {responseType: 'blob'})
}

//TODO: end point para descargar pdf con informacion

export const getPdfDocInfo = (opcion: string) => {
  return request().get(`${URL}/docEmpleoJoven?opcion=${opcion}`, {responseType: 'blob'})
}

//TODO:end Point para enviar info Ractificando

export const ratificarArchivos = (
  values: any,
  archivo?: any,
  oldId?: any,
  empresaCuit?: string
) => {
  //  console.log(values)
  let formData = new FormData()
  formData.append(`${archivo}`, values)
  formData.append(`arc`, archivo)
  oldId && formData.append(`oldId`, oldId)
  empresaCuit && formData.append('cuit', empresaCuit)
  return request().post(`${URL}/ratificarFile`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

//TODO: end Point modEstado de los archivos en las empresasfiles
export const modEstadoArchivo = (empresa: string, estado: string, archivo: any, msg?: any) => {
  let data = {
    cuit: empresa,
    archivo,
    estado,
    msg,
  }

  return request().post(`${URL}/modEstArchivo`, data)
}

export const eliminarEmpresa = (id: string) => {
  return request().delete(`${URL}/empresas/${id}`)
}

export const eliminarEmprendedor = (id: string) => {
  return request().delete(`${URL}/emprendedores/${id}`)
}

export const eliminarEmpleado = (id: string) => {
  return request().delete(`${URL}/empleados/${id}`)
}

export const getMovimientosEmpresas = (data: any, currentPage: number) => {
  return request().get(`${URL}/getMovimientosEmpresas/${data.anio}?page=${currentPage}&area=${data.area}&cuit=${data.cuit}&estado=${data.estado}`)
}

export const getSelectEmpresas = () => {
  return request().get(`${URL}/getSelectEmpresas`)
}


export const modEstadoDocMensualEmpresa = (data: any) => {
  return request().post(`${URL}/modEstadoDocMensualEmpresa`, data);
}
