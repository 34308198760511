import React, {useState} from 'react'
import {ISTATE} from '../../../../../../setup/redux/interface/reduxState.interface'
import {useSelector} from 'react-redux'
interface Iprops {
  empleado?: any
}
export const CargasSociales: React.FC<Iprops> = ({empleado}) => {
  const authState: any = useSelector((state: ISTATE) => state.auth)
  let {usuario} = authState

  const [mes, setMes] = useState<string>()
  const [docSelect, setDocSelect] = useState<[]>()

  function filtrarMes(mes: string) {
    let mesFiltrado = empleado.docPeriodos.filter((elemento: any) => elemento.mes === mes)
    setDocSelect(mesFiltrado)
  }

  return <div>CargasSociales</div>
}
