import { postArea, postOficina, postSubArea } from '../../services/AreaService'
import { ICreateArea } from '../../interfaces/ICreateArea'
import { IIds } from '../../interfaces/IIds'

export const areasPost = async (values: ICreateArea, idOrg: IIds) => {
  //////console.log('values', values)

  if (idOrg._idOrg && idOrg._idArea && idOrg._idSubArea) {
    ////console.log('estoy por grabar oficina')
    return postOficina(
      values,
      idOrg._idOrg,
      idOrg._idArea,
      idOrg._idSubArea,
      idOrg.nombreArea,
      idOrg.nombreSubArea
    ).then(async (resp) => {
      return resp
    })
  } else if (idOrg._idOrg && idOrg._idArea) {
    return postSubArea(values, idOrg._idOrg, idOrg._idArea, idOrg.nombreArea).then(
      async (resp: any) => {
        return resp
      }
    )
  } else {
    return postArea(values, idOrg.orgID).then(async (resp: any) => {
      return resp
    })
  }
}
