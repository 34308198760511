import React, { useEffect, useRef, useState } from 'react'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import { useReactToPrint } from 'react-to-print'
import { buildEventRangeKey } from '@fullcalendar/react'
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Ilegajos } from '../../Interfaces'
import { bg } from 'date-fns/locale'
import { textAlign } from '@mui/system'

type props = {
  data: any
}
export const ExportPDFLegajo: React.FC<props> = ({ data }) => {

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
      heigth: 'auto',

    },
  }))

  let genero = ''
  switch (data.datosPersonales.sexo) {
    case 'f':
      genero = 'Femenino'
      break
    case 'm':
      genero = 'Masculino'
      break
    case 'x':
      genero = 'Otros'
      break
    default:
      break
  }

  const chequeos = (ladata: any) => {
    let variable
    switch (ladata) {
      case true:
        variable = '(✔)'
        break
      case false:
        variable = '(x)'
        break
      default:
        break
    }
    return variable
  }

  return (
    <>
      <div
        style={{
          width: '95%',
          marginTop: '1px',
          marginLeft: '25px',
        }}
      >
        <b><u>Datos Personales del solicitante </u></b>
      </div>

      <table style={{
        width: '95%',
        textAlign: 'center',
        marginTop: '1px',
        marginLeft: '25px',

      }}
        className='table-striped '>
        <thead style={{
          height: '35px',
          color: 'white',
          backgroundColor: '#454545'
        }}>
          <tr  >
            <th style={{ width: '30%' }} scope="col">Apellido y Nombre</th>
            <th style={{ width: '10%' }} scope="col">Dni</th>
            <th style={{ width: '20%' }} scope="col">Género</th>
            <th style={{ width: '20%' }} scope="col">Fecha Nacimiento</th>
            <th style={{ width: '20%' }} scope="col">Nacionalidad</th>
          </tr>
        </thead>
        <tbody style={{
          height: '40px',
          color: 'black',


        }} >

          <td align='center' className='capital' >
            {data.datosPersonales.apellido} {data.datosPersonales.nombre}
          </td>
          <td align='center' >
            {data.datosPersonales.dni}
          </td>
          <td align='center' >
            {genero}
          </td>
          <td align='center' className='capital' >
            {moment(data.datosPersonales.fechaNacimiento).format('L')}
          </td>
          <td align='center' className='capital' >
            {data.datosPersonales.nacionalidad} ({data.datosPersonales.tipoNacionalidad})
          </td>
        </tbody>
      </table>

      <table style={{
        width: '95%',
        textAlign: 'center',
        marginTop: '1px',
        marginLeft: '25px',

      }}
        className='table-striped '>
        <thead style={{
          height: '35px',
          color: 'white',
          backgroundColor: '#454545'
        }}>

          <tr style={{ color: 'white', }}>
            <th align='center' style={{ color: 'white', height: 0, width: '30%' }}>
              Domicilio
            </th>

            <th align='center' style={{ color: 'white', height: 0, width: '10%', }}>
              Telefono
            </th>
            <th align='center' style={{ color: 'white', height: 0, width: '20%', }}>
              Email
            </th>
            <th align='center' style={{ color: 'white', width: '20%', }}>
              Localidad (Dpto)
            </th>
            <th align='center' style={{ color: 'white', width: '20%', }}>
              CP
            </th>
          </tr>
        </thead>

        <tbody style={{
          height: '40px',
          color: 'black',
        }} >
          <td align='center'
            className='capital'
            style={{ height: 0 }}
          >
            {data.datosPersonales.domicilio}
          </td>

          <td>
            {data.datosPersonales.telefono}
          </td>
          <td align='center' className='' >
            {data.datosPersonales.mail ? data.datosPersonales.mail : '-'}
          </td>
          <td align='center' className='capital' >
            {data.datosPersonales.localidad} ({data.datosPersonales.departamento})
          </td>
          <td align='center' className='capital' >
            {data.datosPersonales.codigoPostal}
          </td>
        </tbody>
      </table>

      {data.datosTutor && data.datosTutor.dni !== '' ? (
        <div className=''
          style={{
            width: '95%',
            marginTop: '1px',
            marginLeft: '25px',
            textTransform: 'capitalize'
          }}
        >
          <br></br>
          <b>Tutor:</b> {data.datosTutor.apellido} {data.datosTutor.nombre} - DNI:{data.datosTutor.dni}
        </div>
      ) : null}


      <br></br>
      <div
        style={{
          width: '95%',
          marginTop: '1px',
          marginLeft: '25px',
        }}
      >
        <b><u>Documentacion Presentada</u></b>
      </div>

      <TableContainer
        component={Paper}
        key={'Exportar3'}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >

        

        <Table
          style={{
            width: '95%',
            marginTop: '1px',
          }}
          aria-label='customized table'
        >
          <TableHead>
            <TableRow style={{ backgroundColor: 'white', color: 'black' }}>
              <TableCell align='center' style={{ color: 'black', height: 0, width: '14.28%' }}>
                Certificado Medico&nbsp;&nbsp;{chequeos(data.documentacion.certificadoMedico)}
              </TableCell>
              <TableCell align='center' style={{ color: 'black', height: 0, width: '14.28%' }}>
                Estudios Complementarios&nbsp;&nbsp;{chequeos(data.documentacion.estudiosComplem)}
              </TableCell>
              <TableCell align='center' style={{ color: 'black', height: 0, width: '14.28%' }}>
                Partida de Nacimiento&nbsp;&nbsp;{chequeos(data.documentacion.partidaNac)}
              </TableCell>
              <TableCell align='center' style={{ color: 'black', width: '14.28%' }}>
                Extravio Cud&nbsp;&nbsp;{chequeos(data.documentacion.extravioCud)}
              </TableCell>
              <TableCell align='center' style={{ color: 'black', width: '14.28%' }}>
                Historia Clinica&nbsp;&nbsp;{chequeos(data.documentacion.historiaClinica)}
              </TableCell>
              <TableCell align='center' style={{ color: 'black', width: '14.28%' }}>
                Copia DNI&nbsp;&nbsp;{chequeos(data.documentacion.copiaDni)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow style={{ backgroundColor: 'white', color: 'black' }}>
              <TableCell align='center' style={{ color: 'black', height: 0, width: '14.28%' }}>
                Certificado Supervivencia&nbsp;&nbsp;{chequeos(data.documentacion.supervivencia)}
              </TableCell>
              <TableCell align='center' style={{ color: 'black', height: 0, width: '14.28%' }}>
                Informes Clinicos&nbsp;&nbsp;{chequeos(data.documentacion.informes)}
              </TableCell>
              <TableCell align='center' style={{ color: 'black', height: 0, width: '14.28%' }}>
                Planilla Medica&nbsp;&nbsp;{chequeos(data.documentacion.planillaMedica)}
              </TableCell>
              <TableCell align='center' style={{ color: 'black', width: '14.28%' }}>
                Copia Obra Social&nbsp;&nbsp;{chequeos(data.documentacion.copiaOSocial)}
              </TableCell>
              <TableCell align='center' style={{ color: 'black', width: '14.28%' }}>
                Recibo Pension&nbsp;&nbsp;{chequeos(data.documentacion.reciboPension)}
              </TableCell>
              <TableCell align='center' style={{ color: 'black', width: '14.28%' }}>
                Curatela/Apoyo Juridico&nbsp;&nbsp;{chequeos(data.documentacion.copiaDniTutor)}
              </TableCell>
              
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow style={{ backgroundColor: 'white', color: 'black', borderColor:'white' }}>
              <TableCell align='center' style={{ color: 'black', height: 0, width: '14.28%' }}>
                CUD anterior&nbsp;&nbsp;{chequeos(data.documentacion.cudAnterior)}
              </TableCell>
              <TableCell align='center' style={{ color: 'black', height: 0, width: '14.28%' }}>
              Copia Dni Tutor&nbsp;&nbsp;{chequeos(data.documentacion.copiaDniTutor)}
              </TableCell>
              <TableCell align='center' style={{ color: 'black', height: 0, width: '14.28%' }}>
              </TableCell>
              <TableCell align='center' style={{ color: 'black', height: 0, width: '14.28%' }}>
              </TableCell>
              <TableCell align='center' style={{ color: 'black', height: 0, width: '14.28%' }}>
              </TableCell>
              <TableCell align='center' style={{ color: 'black', height: 0, width: '14.28%' }}>
              </TableCell>
              
            </TableRow>
          </TableHead>

        </Table>
      </TableContainer>
      <br></br>


      <div className='tamano_footer'
        style={{
          width: '95%',
          marginTop: '1px',
          marginLeft: '25px',
        }}
      >
        <u><b>REQUISITOS DE LA EVALUACION</b></u><p></p>
        <p><li>Cuando concurra al turno, será evaluado por una Junta integrada por profesionales medicos/as, psicólogos/as y trabajador/a Social  y trabajador/a social.</li></p>
        <p><li>Se evaluará de manera presencial a la persona que requiere la solicitud y en caso que lo demande, concurrir acompañado de madre, padre, tutor o apoyo jurídico.</li></p>
        <p><li>Se contempla una vía de excepción para aquellos casos que la persona no pueda acudir a la Junta.</li></p>
        <p><li>DNI original</li></p>
        <p><li>En caso de tener CUD anterior, debe traerlo.</li></p>
        <b><u>Contacto Junta Interdisciplinaria</u>: </b> <b>Telefono: 299 5818608</b>  |  <b>Email: juntaevaluadoraneuquen@gmail.com</b>
        <p><hr></hr></p>

      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div className='row'>
        <div className='col-lg-6'>
          <div className='centrado'>
            <label>_____________________________________</label>
            <p>
              <label>Firma del solicitante</label>
            </p>
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='centrado'>
            <label>_____________________________________</label>
            <p>
              <label>Firma de Agente Provincial</label>
            </p>
          </div>
        </div>

      </div>



    </>

  )
}
