import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {ISTATE} from '../../../../../../setup/redux/interface/reduxState.interface'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ICreateModulo} from '../../interfaces/ICreateModulo'
import {ITablaDatos} from '../../interfaces/TablaDatos'

let tablaInicio: ITablaDatos = {
  className: 'card-xxl-stretch mb-5 mb-xl-8',
  tituloMenu: 'Administracion de sistemas',
  botonPrincipal: 'Crear',
  tablaDatos: [],
  link: '#',
  linkEdit: '#',
  headColumns: {
    col1: '',
    col2: '',
    col3: '',
    col4: '',
  },
}
const TablaOrganismo: React.FC<ITablaDatos> = ({
  className,
  tituloMenu,
  botonPrincipal,
  tablaDatos,
}) => {
  const [InfoMenu, setInfoMenu] = useState<ITablaDatos>(tablaInicio)
  const infoPrinc: any = useSelector((state: ISTATE) => state.tablaDatos)

  const dashState: any = useSelector((state: ISTATE) => state.dashBoard)
  let {perfil} = dashState
  const modulo: ICreateModulo = perfil.find((value: any) => value.nombre === 'administracion')
  const menu: any = modulo.menu.find((value) => value.name === 'organismos')

  useEffect(() => {
    infoPrinc.tablaInicial ? setInfoMenu(tablaInicio) : setInfoMenu(infoPrinc)
  }, [infoPrinc])

  return (
    <div className={`card ${InfoMenu.className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder text-blanco fs-3 mb-1'>{InfoMenu.tituloMenu}</span>
        </h3>

        {InfoMenu.botonPrincipal !== 'Crear' && menu?.roleUs?.crear && (
          <div className='card-toolbar'>
            <Link
              to={infoPrinc.link}
              className='btn btn-sm btn-light-primary'
              // data-bs-toggle='modal'
              // data-bs-target='#kt_modal_invite_friends'
            >
              <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              {InfoMenu.botonPrincipal}
            </Link>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            {InfoMenu.tablaDatos && (
              <thead>
                <tr className='fw-bolder text-muted bg-light'>
                  <th className='min-w-150px rounded-start'>{InfoMenu.headColumns?.col1}</th>
                  <th className='min-w-140px'>{InfoMenu.headColumns?.col2}</th>
                  <th className='min-w-120px'>{InfoMenu.headColumns?.col3}</th>
                  <th className='min-w-100px text-end rounded-end'>{InfoMenu.headColumns?.col4}</th>
                </tr>
              </thead>
            )}
            {/* end::Table head */}
            {/* begin::Table body */}
            {InfoMenu.tablaDatos?.length ? (
              <tbody>
                {InfoMenu.tablaDatos?.map((item: any) => (
                  <tr key={item._id}>
                    {/*  <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                        />
                      </div>
                    </td> */}
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img src={item.datosSistemas.logo} alt='' />
                        </div>

                        <div className='d-flex justify-content-start flex-column'>
                          <a className='text-dark fw-bolder uppercase text-hover-primary fs-6 capital'>
                            {item.nombre}
                          </a>
                        </div>
                      </div>
                    </td>

                    <td>
                      <a className='text-dark fw-bolder text-hover-primary d-block fs-6 capital'>
                        {item.nombreMinistro}
                      </a>
                    </td>

                    <td className='text-end'>
                      {item.activo ? (
                        <span className='badge badge-light-success fs-8 fw-bolder my-2'>
                          Activo
                        </span>
                      ) : (
                        <span className='badge badge-light-danger fs-8 fw-bolder my-2'>
                          Desactivado
                        </span>
                      )}
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <Link
                          to={{pathname: infoPrinc.linkEdit, state: item}}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody></tbody>
            )}
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default TablaOrganismo
