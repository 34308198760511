import { request } from "../../../setup/axios/axios.util"



const API_URL = process.env.REACT_APP_API_URL || 'api'


export const getProvLocArg = () => {
  const url = `${API_URL}/core/provLoc`
  return request().get(url)
}
export const getLocProv = (nombre: any) => {
  const url = `${API_URL}/core/locprov`
  let dato: any = {
    nombre_prov: nombre
  }
  return request().post(url, dato)
}

export const localidadesNqn = () => {
  const url = `${API_URL}/core/locNqn`
  return request().get(url)
}


export const getProvincias = () => {
  const url = `${API_URL}/core/provincias`
  return request().get(url)
}

