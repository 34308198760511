import { useSelector } from 'react-redux';
import { ISTATE } from '../../setup/redux/interface/reduxState.interface';

// Custom hook para obtener el usuario y su área desde Redux
const useUsuarioYArea = () => {
  const authState: any = useSelector((state: ISTATE) => state.auth);
  const { usuario } = authState;
  const areaUsr = usuario.organizacion.datosOrganismo.areaPrincipal.nombreArea;

  return { usuario, areaUsr };
};

export default useUsuarioYArea;