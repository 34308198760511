import React, {useState, useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
//import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {IEmpresa} from '../../interfaces/Iempresas'
import {apEmpresa, apEmprendedor} from '../APEmpresas'
import {IEmprendedor} from '../../interfaces/Iemprendedor'
import Swal from 'sweetalert2'
//import {modInscripcion} from '../../services/EJ.Services'

interface Iprops {
  empresa?: IEmpresa
  emprendedor?: IEmprendedor
  tipoUsuario?: string
}

const DatosEmpCard: React.FC<Iprops> = ({empresa, emprendedor, tipoUsuario}) => {
  let history = useHistory()
  const [btnApb, setBtnApb] = useState(true)
  const [loading, setLoading] = useState(false)
  const [usrDocu, setUsrDocu] = useState<string>('')

  const ocultarBtn = () => {
    setBtnApb(false)
  }
  const sendMailEstado = async (estado: string, doc: any) => {
    Swal.fire({
      input: 'textarea',
      inputLabel: 'Mensaje para el destinatario del email.',
      inputPlaceholder: 'Escriba su argumentación en este espacio.',
      inputAttributes: {
        'aria-label': 'Escriba su mensaje aquí.',
      },
      width: '800px',
      heightAuto: true,
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cerrar',
      showCancelButton: true,
    }).then(async (resp: any) => {
      if (resp.isConfirmed && Object.entries(resp.value).length) {
        apEmpresa(doc, estado, resp.value).then(async (res: any) => {
          await Swal.fire('Información procesada correctamente', '', 'warning')
        })
      }
      if (resp.isConfirmed && Object.entries(resp.value).length === 0) {
        await Swal.fire({toast: true, text: `Es necesario completar este formulario.`, timer: 2000})
        sendMailEstado(estado, doc)
      }
    })
  }

  const selectOpBtn = async (doc: any) => {
    await Swal.fire({
      title: 'Seleccione una opción',
      input: 'select',
      inputOptions: !btnApb
        ? {
            rechazado: 'Rechazar',
            ratificar: 'Subir doc nuevamente',
          }
        : {aprobado: 'Aprobado', rechazado: 'Rechazar', ratificar: 'Subir doc nuevamente'},
      inputPlaceholder: 'Seleccione una opción',
      confirmButtonText: 'Cambiar',
      cancelButtonText: 'Cerrar',
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === 'rechazado') {
            sendMailEstado(value, doc)
          }
          if (value === 'aprobado') {
            if (empresa?.cuit) {
              apEmpresa(empresa, 'aprobado', '').then((res: any) => {
                history.push('/ej/empresas')
              })
            } else {
              apEmprendedor(doc).then((res: any) => {
                history.push('/ej/emprendedores')
              })
            }
          }
          if (value === 'ratificar') {
            sendMailEstado(value, doc)
          } else {
            resolve('Es necesario seleccionar una opción')
          }
        })
      },
    })
  }

  useEffect(() => {
    empresa?.estado && ocultarBtn()
    emprendedor?.estado && ocultarBtn()
    empresa && setUsrDocu('empresas')
    emprendedor && setUsrDocu('emprendedores')

    return () => {}
  }, [empresa, emprendedor])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Datos de la empresa.</h3>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button
            type='button'
            className='btn btn-sm btn-light-info me-3'
            onClick={() => {
              history.goBack()
            }}
          >
            {' '}
            Volver
          </button>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label required fw-bold fs-6'>Razon Social</label>
              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Razon Social'
                      defaultValue={
                        empresa?.razon_social || `${emprendedor?.apellido} ${emprendedor?.nombres}`
                      }
                    />
                  </div>
                  <label className='col-lg-2 fv-row col-form-label required fw-bold fs-6'>
                    CUIT/CUL
                  </label>
                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Nro. CUIT / CUIL'
                      defaultValue={empresa?.cuit || emprendedor?.cuil}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label required fw-bold fs-6'>Docimilio:</label>

              <div className='col-lg-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Domicilio'
                  defaultValue={
                    empresa?.domicilio_legal?.calle ||
                    `${emprendedor?.calle}, ${emprendedor?.numero}`
                  }
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label required fw-bold fs-6'>Localidad</label>
              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Localidad'
                      defaultValue={empresa?.localidad || emprendedor?.localidad}
                    />
                  </div>
                  <label className='col-lg-2 fv-row col-form-label required fw-bold fs-6'>
                    Provincia
                  </label>
                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Provincia'
                      defaultValue={empresa?.provincia || emprendedor?.provincia}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-6'></div>
            <hr />
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Domicilio Legal.</h3>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label required fw-bold fs-6'>Docimilio:</label>

              <div className='col-lg-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Domicilio'
                  defaultValue={
                    empresa?.domicilio_legal?.calle ||
                    `${emprendedor?.calle}, ${emprendedor?.numero}`
                  }
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label required fw-bold fs-6'>Localidad</label>
              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Localidad'
                      defaultValue={empresa?.localidad || emprendedor?.localidad}
                    />
                  </div>
                  <label className='col-lg-2 fv-row col-form-label required fw-bold fs-6'>
                    Provincia
                  </label>
                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Provincia'
                      defaultValue={empresa?.provincia || emprendedor?.provincia}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Datos de contacto.</h3>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label required fw-bold fs-6'>Telefono</label>
              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Telefono'
                      defaultValue={empresa?.telefono || emprendedor?.telefono}
                    />
                  </div>
                  <label className='col-lg-2 fv-row col-form-label required fw-bold fs-6'>
                    Correo electrónico.
                  </label>
                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Correo Electrónico.'
                      defaultValue={empresa?.email || emprendedor?.email}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            {tipoUsuario === 'Empleo Joven' && (
              <button
                type='button'
                className='btn btn-success me-2 px-6'
                onClick={() => {
                  if (empresa?.cuit) {
                    selectOpBtn(empresa)
                  } else {
                    selectOpBtn(emprendedor)
                  }
                }}
              >
                Cambiar estado
              </button>
            )}

            <Link
              to={{
                pathname: 'misdocumentos',
                state: {
                  empresa: empresa?.cuit,
                  archivos: empresa?.archivos,
                  documentos: emprendedor?.documentos || empresa?.documentos,
                  tipoUs: usrDocu,
                } /* state */,
              }}
            >
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Documentación Presentada'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DatosEmpCard
