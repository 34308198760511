import React, { useEffect, useState } from 'react'

import { OverlayTrigger, Tooltip } from 'react-bootstrap-v5'
import Swal from 'sweetalert2'
import { ModDocPeridoEmpleado, PutDocPeridoEmpleado } from '../../services/AdminEmpleados'
import { getDocMesxMes, getOneEmpresa } from '../../services/EJ.Services'
import { IDocumentos, Imes } from '../../interfaces/Iempresas'
import useAreaUser from '../../../../../Hooks/useAreaUser'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { ISTATE } from '../../../../../../setup/redux/interface/reduxState.interface'
interface Iprops {
  empleado?: any
  infoValues?: any
}

interface IEstadosDocumentos {
  [key: string]: string;
}

export const TablaPeriodos: React.FC<Iprops> = ({ empleado, infoValues }) => {
  const { usuario } = useAreaUser();

  const [anio, setAnio] = useState<string>(infoValues?.anio ? infoValues.anio : '');
  const [mes, setMes] = useState<string>(infoValues?.mes ? infoValues.mes : '');
  const [meses, setMeses] = useState<any>()
  const [docSelect, setDocSelect] = useState<[]>()
  const [mesInicioCarga, setMesInicioCarga] = useState<number>(0);
  const [estadosDocumentos, setEstadosDocumentos] = useState<IEstadosDocumentos>();

  const modificated = useSelector((state: ISTATE) => state.empleoJoven.modificated);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchEmpresa = async () => {
      try {
        const dataEmpresa = await getOneEmpresa(usuario.usuario);
        if (dataEmpresa.data && dataEmpresa.data.data) {
          const mesInicioEmpresa = moment(dataEmpresa.data.data.createdAt).month() + 1;
          const mesInicioEmpleado = new Date(empleado.datosLaborales.fechaAlta).getMonth() + 1;
          mesInicioEmpleado > mesInicioEmpresa - 6 ? setMesInicioCarga(mesInicioEmpleado) : setMesInicioCarga(mesInicioEmpresa - 6)
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchEmpresa();
  }, [usuario.usuario, empleado.datosLaborales.fechaAlta]);

  useEffect(() => {
    if (empleado.anio) {
      const estadosIniciales: IEstadosDocumentos = {};
      empleado.anio.forEach((year: any) => {
        if (year.meses && year.meses.length > 0) {
          year.meses.forEach((mes: any) => {
            if (mes.documentos && mes.documentos.length > 0) {
              mes.documentos.forEach((docItem: any) => {

                estadosIniciales[docItem.id_db_fileEj] = docItem.estado;

              });
            }
          });
        }
      });

      setEstadosDocumentos(estadosIniciales);
    }
  }, [empleado.anio, empleado.anio.meses]);

  useEffect(() => {
    if (infoValues) {
      filtYearAct(infoValues.anio);
      filtDocMes(infoValues.mes);
    }
  });

  const filtYearAct = (year: string) => {
    setAnio(year)
    setMeses(empleado.anio.find((elemt: any) => elemt.anio === year)?.meses)
  }
  const filtDocMes = (mes: string) => {
    if (meses) {
      setMes(mes)
      setDocSelect(meses.find((elemt: any) => elemt.mes === mes)?.documentos)

    }
  }

  const verPDF = (doc: any) => {
    getDocMesxMes(doc).then((res: any) => {
      const url = window.URL.createObjectURL(res.data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
    })
  }

  const opcRechazo = async (doc: any) => {
    let value: any = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Mensaje para el empleador.',
      inputPlaceholder: 'Escriba su argumentación en este espacio.',
      inputAttributes: {
        'aria-label': 'Escriba su mensaje aquí.',
      },
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cerrar',
      showCancelButton: true,
    })
    if (value.value === '') {
      await Swal.fire({ toast: true, text: `Es necesario completar este formulario.`, timer: 2000 })
      opcRechazo(doc)
    } else {
      ModDocPeridoEmpleado(
        empleado.documento,
        'rechazado',
        doc.id_db_fileEj,
        anio,
        mes,
        value.value
      ).then(async (res: any) => {
        const response = await Swal.fire('Documento rechazado', 'El dato fue correctamente modificado.', 'warning');
        if (response) {
          setEstadosDocumentos(prevEstados => ({
            ...prevEstados,
            [doc.id_db_fileEj]: 'rechazado', // o cualquier otro estado que necesites
          }));
          if (infoValues) {
            dispatch({
              type: 'SET_ESTADOS_DOCUMENTOS',
              payload: {
                //[doc.id_db_fileEj]: 'rechazado', // o cualquier otro estado que necesites
                cuit: infoValues.cuit,
                anio: infoValues.anio,
                mes : infoValues.mes,
  
                modificated: true,
              }
            });
          }
        }
      })
    }
  }

  const selectOpBtn = (doc: any) => {
    Swal.fire({
      title: 'Seleccione una opción',
      input: 'select',
      inputOptions: {
        validado: 'Validado',
        rechazado: 'Rechazado',
      },
      inputPlaceholder: 'Seleccione una opción',
      confirmButtonText: 'Cambiar',
      cancelButtonText: 'Cerrar',
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === 'rechazado') {
            opcRechazo(doc)
            resolve('')
          }
          if (value === 'validado') {
            ModDocPeridoEmpleado(empleado.documento, 'validado', doc.id_db_fileEj, anio, mes).then(
              async (res: any) => {
                setEstadosDocumentos(prevEstados => ({
                  ...prevEstados,
                  [doc.id_db_fileEj]: 'validado', // o cualquier otro estado que necesites
                }));
                if (infoValues) {
                  dispatch({
                    type: 'SET_ESTADOS_DOCUMENTOS',
                    payload: {
                      //[doc.id_db_fileEj]: 'validado', // o cualquier otro estado que necesites
                      cuit: infoValues.cuit,
                      anio: infoValues.anio,
                      mes : infoValues.mes,
                      modificated: true,
                    }
                  });
                }
                await Swal.fire(
                  'Documento  Validado.',
                  'La modificación del estado fue correcta',
                  'success'
                )
                resolve('')
              }
            )
          } else {
            resolve('Es necesario seleccionar una opción')
          }
        })
      },
    })
  }

  const reenviarFile = async (doc?: any) => {
    const { value: formValues } = await Swal.fire({
      title: 'Seleccione el archivo que desea reenviar.',
      html: '<input type="file" accept=".pdf" id="pdfUpload" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">',

      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cerrar',
      showCancelButton: true,
      width: '600px',
      preConfirm: async (e: any) => {
        if ((document.getElementById('pdfUpload') as HTMLInputElement)!.value === '') {
          await Swal.fire({
            toast: true,
            text: `Es necesario seleccionar un archivo.`,
            timer: 2000,
            showConfirmButton: false,
          })
          reenviarFile(doc)
          return ''
        } else {
          return (document.getElementById('pdfUpload') as HTMLInputElement)!.files
        }
      },
    })

    if (formValues) {
      if (formValues[0]!.size > 2000000) {
        await Swal.fire({
          icon: 'info',
          title: 'El tamaño del archivo excede el limite permitido, 2 MB.',
          timer: 3000,
        })
        reenviarFile(doc)
      } else {
        PutDocPeridoEmpleado(
          formValues[0],
          doc.tipo_doc,
          doc.id_db_fileEj,
          empleado.documento,
          anio,
          mes,
          empleado.cuit
        ).then(async (res: any) => {
          if (res.data.ok) {
            if (infoValues) {
              dispatch({
                type: 'SET_ESTADOS_DOCUMENTOS',
                payload: {
                  //[doc.id_db_fileEj]: 'validado', // o cualquier otro estado que necesites
                  cuit: infoValues.cuit,
                  anio: infoValues.anio,
                  mes : infoValues.mes,
                  modificated: true,
                }
              });
            }
            await Swal.fire(
              'Documento Modificado',
              'El documento fue enviado correctamente, un administrador validara su información.',
              'warning'
            )
          }
        })
      }
    }
  }

  const tooltip = (titulo: string) => {
    return (
      <Tooltip id='tooltip'>
        <strong>{titulo}</strong>
      </Tooltip>
    )
  }

  const getOptions = (mes: Imes) => {
    return (mes.estado === 'validado' || mes.estado === 'presentado' || mes.estado === 'pendiente' || mes.estado === 'rechazado') &&
      mes.documentos.some((item: IDocumentos) => item.estado === 'pendiente' || item.estado === 'validado');
  }
  /**
   * Función para obtener meses habilitados para carga de documentación de empleados
   * @param mes cada mes del año del arreglo anio del empleado
   * @param index el índice del arreglo de meses
   *
   */
  const isMonthAvailable = (mes: Imes, index: number) => {
    let available = false;

    if (mes.estado === 'pendiente') {
      available = true;
    }
    else {

      if (index + 1 >= mesInicioCarga) {
        available = true;
      }
    }
    return available;
  }



  return (
    <>
      <div className='card-body border-top p-9'>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Seleccione el periodo.</span>
          </label>
          <div className='col-lg-8 fv-row'>
            <select
              className='form-select form-select-solid capital'
              value={anio ? anio : ''}
              onChange={(e) => {
                filtYearAct(e.target.value)
              }}
            >
              <option value=''>seleccione un Periodo</option>
              {empleado.anio.map((anio: any) => {
                return ['pendiente', 'validado', 'rechazado'].includes(anio.estado) && <option value={anio.anio}>{anio.anio}</option>
              })}
            </select>
          </div>
        </div>
        {meses && usuario?.usuario.length <= 8 && (
          <>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Seleccione el Mes.</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid capital'
                  value={mes ? mes : ''}
                  onChange={(e) => {
                    filtDocMes(e.target.value)
                  }}
                >
                  <option value=''>seleccione un Mes</option>
                  {meses.map((mes: Imes) => {
                    return getOptions(mes) && <option value={mes.mes}>{mes.mes.toUpperCase()}</option>
                  }
                  )
                  }
                </select>
              </div>
            </div>
          </>
        )}
        {meses && usuario?.usuario.length > 8 && (
          <>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Seleccione el Mes.</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid capital'
                  onChange={(e) => {
                    filtDocMes(e.target.value)
                  }}
                >
                  <option value=''>seleccione un Mes</option>
                  {meses.map((mes: any, index: number) => {
                    console.log(mes);
                    if (isMonthAvailable(mes, index)) {
                      return <option value={mes.mes}>{mes.mes.toUpperCase()}</option>
                    }
                    return null;
                  })}
                </select>
              </div>
            </div>
          </>
        )}
        <br />

        {docSelect && (
          <div className='tab-content'>
            {/* begin::Tap pane */}
            <div className='tab-pane fade show active' id='kt_table_widget_8_tab_1'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr>
                      <th className='p-0 w-50px'></th>
                      <th className='p-0 min-w-100px'></th>
                      <th className='p-0 min-w-50px'></th>
                      <th className='p-0 min-w-50px'></th>
                      <th className='p-0 min-w-50px'></th>
                      <th className='p-0 min-w-120px'></th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {docSelect?.length > 0 ? (
                      docSelect.map((doc: any, index: number) => (
                        <tr key={index}>
                          <td>
                            <div className='symbol symbol-50px me-2'>
                              <span className='symbol-label bg-light-info'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='16'
                                  height='16'
                                  fill='currentColor'
                                  className='bi bi-filetype-pdf'
                                  viewBox='0 0 16 16'
                                >
                                  <path
                                    fill-rule='evenodd'
                                    d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z'
                                  />
                                </svg>
                              </span>
                            </div>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
                            >
                              {doc.tipo_doc === 'recibosueldo' && 'Recibo de Sueldo'}

                              {doc.tipo_doc === 'cercapc' && 'Certificado de Capacitación'}
                            </a>
                            {/*  <span className='text-muted fw-bold d-block fs-7 capital'>
                                  {doc.tipo_doc} / {meses.mes}
                                </span> */}
                          </td>
                          <td className='text-end'>
                            {estadosDocumentos && estadosDocumentos[doc.id_db_fileEj] === 'pendiente' && (
                              <span className='badge badge-light-warning'>Pendiente</span>
                            )}
                            {estadosDocumentos && estadosDocumentos[doc.id_db_fileEj] === 'rectificado' && (
                              <span className='badge badge-light-warning'>Rectificado</span>
                            )}
                            {estadosDocumentos && estadosDocumentos[doc.id_db_fileEj] === 'validado' && (
                              <span className='badge badge-light-success'>Validado</span>
                            )}
                            {estadosDocumentos && estadosDocumentos[doc.id_db_fileEj] === 'rechazado' && (
                              <span className='badge badge-light-danger'>Rechazado</span>
                            )}
                          </td>
                          <td className='text-end'>
                            <span className='text-dark fw-bolder d-block fs-7'>
                              {doc.fecha_carga}
                            </span>
                            <span className='text-muted fw-bold d-block fs-8'>
                              Fecha de presentación
                            </span>
                          </td>
                          <td className='text-end'>
                            <span className='text-dark fw-bolder d-block fs-7'>
                              {doc.fecha_modf}
                            </span>
                            <span className='text-muted fw-bold d-block fs-8'>
                              Fecha de Modificación
                            </span>
                          </td>
                          <td className='text-end'>
                            {doc.estado === 'rechazado' && (
                              <OverlayTrigger
                                placement='top'
                                overlay={tooltip('Ver observación')}
                              >
                                <div
                                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  onClick={() => {
                                    Swal.fire({ html: `${doc.observacion}` })
                                  }}
                                >
                                  <i className='bi bi-info fs-5'></i>
                                </div>
                              </OverlayTrigger>
                            )}
                            <OverlayTrigger placement='top' overlay={tooltip('Ver Documento')}>
                              <div
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                onClick={() => {
                                  verPDF(doc.id_db_fileEj)
                                }}
                              >
                                <i className='bi bi-eye fs-5'></i>
                              </div>
                            </OverlayTrigger>
                            {usuario.usuario.length <= 8 && (
                              <OverlayTrigger placement='top' overlay={tooltip('Mod.Estado')}>
                                <div
                                  className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                                  onClick={() => {
                                    selectOpBtn(doc)
                                  }}
                                >
                                  <i className='bi bi-pen fs-5'></i>
                                </div>
                              </OverlayTrigger>
                            )}
                            {doc.estado === 'rechazado' && (
                              <OverlayTrigger
                                placement='top'
                                overlay={tooltip('Reenviar documentación')}
                              >
                                <div
                                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                  onClick={() => {
                                    reenviarFile(doc)
                                  }}
                                >
                                  <i className='bi bi-upload fs-5'></i>
                                </div>
                              </OverlayTrigger>
                            )}

                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6}>
                          {/*                           <div className='alert-warning text-center shadow fw-bolder p-2 fs-6'>
                            <i className="far fs-3 fa-bell mx-2">

                            </i>
                            Sin registros para el periodo seleccionado
                          </div> */}
                          <div className="alert alert-warning d-flex align-items-center p-2 mb-10">
                            <span className="svg-icon svg-icon-2hx svg-icon-primary me-3"><i className="far fs-2 fa-bell mx-2"></i></span>
                            <div className="d-flex flex-column">
                              <h5 className="mb-1">Sin registros para el período</h5>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* end::Table */}
            </div>
          </div>
        )}
      </div >
    </>
  )
}
